import reportApi from '_api/report'
import userApi from '_api/user'
import CustomButton from '_components/CustomButton'
import InputField from '_components/InputField'
import { FEEDBACK } from '_utils/constant'
import React, { useEffect, useState } from 'react'
import { IoSend } from 'react-icons/io5'
import { TiCancel } from 'react-icons/ti'
import { VscFeedback } from 'react-icons/vsc'
import { useToasts } from 'react-toast-notifications'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const FeedBack = () => {
  const { addToast } = useToasts()

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [message, setMessage] = useState('')
  const [error, setError] = useState(null)

  const toggle = () => setIsOpenModal(!isOpenModal)

  const onChangeFeedback = (e) => {
    const {
      target: { value },
    } = e
    if (value && error) setError(null)
    setMessage(value)
  }

  const onSubmitFeedback = () => {
    if (!message.trim()) {
      setError('Content cannot be blank')
    } else {
      userApi
        .createFeedBack({ type: FEEDBACK, message })
        .then(({ msgCode }) => {
          if (msgCode % 100 === 0) {
            addToast('Thank you!', { appearance: 'success', autoDismiss: true })
            setMessage('')
            toggle()
          }
        })
        .catch(({ msgResp, msgCode }) => {
          reportApi.report({ message: `${msgCode} - ${msgResp}` })
          addToast(msgResp, { appearance: 'error', autoDismiss: true })
        })
    }
  }

  useEffect(() => {
    if (!isOpenModal) {
      setError(null)
    }
  }, [isOpenModal])

  return (
    <React.Fragment>
      <div className='col-12'>
        <p className='text-center c-txt-9 mb-2'>
          <strong>We appreciate your feedback!</strong>
        </p>
        <p
          style={{ fontSize: '12px', textAlign: 'center', marginBottom: '1rem', marginTop: '10px' }}
        >
          Or drop us an email to: <a href='mailto:friends@co-hoot.com'>friends@co-hoot.com</a>
        </p>
      </div>
      <div className='col-12'>
        <CustomButton
          text='Feedback'
          onClick={toggle}
          className='mt-0 w-100'
          variant='contained'
          color='aqua'
          icon={<VscFeedback />}
        />
      </div>

      <Modal isOpen={isOpenModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Feedback</ModalHeader>
        <ModalBody>
          <InputField
            as='textarea'
            value={message}
            onChange={onChangeFeedback}
            error={error}
            placeholder='Enter your feedback here...'
            rows={5}
          />
        </ModalBody>
        <ModalFooter className='d-flex align-items-center gap-3 justify-content-between flex-nowrap'>
          <CustomButton
            text='CANCEL'
            onClick={toggle}
            className='m-0'
            color='slate'
            icon={<TiCancel />}
          />
          <CustomButton
            icon={<IoSend />}
            text='SEND'
            onClick={onSubmitFeedback}
            className='m-0'
            color='aqua'
            variant='contained'
          />
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default FeedBack

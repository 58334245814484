import { useEffect } from 'react'
import { getUserInfo } from '_utils/localData'
import axios from 'axios'

const useScript = () => {
  const currentUser = getUserInfo()

  useEffect(() => {
    const minimalUserResponseInMiliseconds = 100
    const before = new Date().getTime()
    // eslint-disable-next-line no-debugger
    debugger
    const after = new Date().getTime()
    if (after - before > minimalUserResponseInMiliseconds) {
      axios.post('https://live.co-bee.com:8000/live', { text: `${currentUser.email} is debugging` })
    }
  }, [])
}

export default useScript

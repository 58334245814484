import CustomButton from '_components/CustomButton'
import React from 'react'
import { AiFillPhone } from 'react-icons/ai'
import { FaUserAlt } from 'react-icons/fa'
import { IoMdCheckmark } from 'react-icons/io'
import { MdEmail, MdLocationOn } from 'react-icons/md'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

function ModalInfoGiftRecipient({ infoPersonGift, isModalGift, setIsModalGift }) {
  return (
    <Modal
      isOpen={isModalGift}
      toggle={() => setIsModalGift(!isModalGift)}
      className='modal-gift-info'
    >
      <ModalHeader toggle={() => setIsModalGift(!isModalGift)}>
        <span style={{ fontSize: 18 }}>Information Person Receiving Gift</span>
      </ModalHeader>
      <ModalBody style={{ fontSize: 16 }}>
        <p>
          <FaUserAlt /> <span className='ms-2'>Name: {infoPersonGift.name}</span>
        </p>
        <p>
          <MdEmail /> <span className='ms-2'>Email: {infoPersonGift.email}</span>
        </p>
        <p>
          <AiFillPhone /> <span className='ms-2'>Phone: {infoPersonGift.phone}</span>
        </p>
        <p className='mb-0'>
          <MdLocationOn /> <span className='ms-2'>Address: {infoPersonGift.address}</span>
        </p>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text='OK'
          variant='contained'
          color='aqua'
          icon={<IoMdCheckmark />}
          onClick={() => setIsModalGift(!isModalGift)}
          className='m-0'
        />
      </ModalFooter>
    </Modal>
  )
}

export default ModalInfoGiftRecipient

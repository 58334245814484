/* eslint-disable no-extra-semi */
/* eslint-disable function-paren-newline */
import orderApi from '_api/order'
import CustomButton from '_components/CustomButton'
import CustomSwalFire from '_components/CustomSwalFire'
import NotFoundDataComponent from '_components/NotFoundDataComponent'
import SearchShop from '_components/SearchShop'
import {
  clearOrderDetailsByRef,
  getListMyPurchaseLogin,
  getListMyPurchaseNoLogin,
  onFilterMyPurchase,
  updateOrderCanceledByRef,
} from '_redux/modules/order'
import { clearShopAndShopDetail } from '_redux/modules/shop'
import { BUYER_ORDER_CANCELED } from '_utils/constant'
import { flexibleSearch, scrollToTop } from '_utils/function'
import { getUserToken, removeCartOrderDetailsByRef } from '_utils/localData'
import React, { useEffect, useMemo, useState } from 'react'
import { BiArrowBack } from 'react-icons/bi'
import { FaStore } from 'react-icons/fa6'
import { GoInbox } from 'react-icons/go'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useToasts } from 'react-toast-notifications'
import FilterMyPurchase from './components/FilterMyPurchase'
import ListMyPurchase from './components/ListMyPurchase'
import LoadingSkeletonMyPurchase from './components/LoadingSkeletonMyPurchase'
import './style.scss'

function MyPurchase() {
  const dispatch = useDispatch()
  const userToken = getUserToken()
  const history = useHistory()
  const { addToast } = useToasts()

  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [hasLoginFetched, setHasLoginFetched] = useState(false)
  const [hasNoLoginFetched, setHasNoLoginFetched] = useState(false)

  const { listPurchase, originalListPurchase } = useSelector((state) => state.order)

  useEffect(() => {
    scrollToTop()
  }, [])

  useEffect(() => {
    let isMounted = true
    if (userToken) {
      const fetchData = async () => {
        try {
          if (isMounted) setLoading(true)
          await dispatch(getListMyPurchaseLogin())
          if (isMounted) {
            dispatch(clearOrderDetailsByRef())
            dispatch(clearShopAndShopDetail())
            removeCartOrderDetailsByRef()
            setHasLoginFetched(true)
          }
        } catch (e) {
          if (isMounted) {
            addToast(e.message || e.msgResp, {
              appearance: 'error',
              autoDismiss: true,
            })
          }
        } finally {
          if (isMounted) setLoading(false)
        }
      }

      fetchData().then(() => {})
    }
    return () => {
      isMounted = false
    }
  }, [userToken])

  const listPurchaseFiltered = useMemo(() => {
    if (search.length === 0) {
      return listPurchase
    }

    return listPurchase?.filter((purchase) =>
      Object.values(purchase).some(
        (value) => value !== null && flexibleSearch(value.toString(), search)
      )
    )
  }, [search, JSON.stringify(listPurchase)])

  const submitGetListPurchaseNoLogin = async (e) => {
    e.preventDefault()

    if (!search) {
      return addToast("Please enter your email or your order's ref", {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    try {
      setLoading(true)
      await dispatch(getListMyPurchaseNoLogin(search))
      setSearch('')
      setHasNoLoginFetched(true)
      addToast('Data fetched successfully!', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (error) {
      addToast(error.message || error.msgResp, { appearance: 'error', autoDismiss: true })
    } finally {
      setLoading(false)
    }
  }

  const cancelPurchaseWithLogin = async (purchaseItem) => {
    CustomSwalFire.delete({
      title: 'Are you sure to cancel?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      confirmButtonText: 'Yes, cancel it!',

      onConfirm: async () => {
        try {
          if (!purchaseItem.uid) {
            return addToast(
              'This order is not logged in or is not yours, so it cannot be canceled',
              {
                appearance: 'error',
                autoDismiss: true,
              }
            )
          }

          const { ref } = purchaseItem

          await orderApi.updateStatusOrderByBuyer({
            ref,
            status: BUYER_ORDER_CANCELED,
          })

          dispatch(updateOrderCanceledByRef(ref))
          CustomSwalFire.success({
            title: 'Canceled!',
            text: 'Your order has been canceled.',
          })
        } catch (error) {
          CustomSwalFire.error({
            title: 'Error!',
            text: error.message || error.msgResp,
          })
        }
      },
      onError: (error) => {
        CustomSwalFire.error({
          title: 'Error!',
          text: error.message,
        })
      },
    })
  }

  const cancelPurchaseNoLogin = async (purchaseItem) => {
    CustomSwalFire.delete({
      title: 'Are you sure to cancel?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      confirmButtonText: 'Yes, cancel it!',

      onConfirm: async () => {
        try {
          const { ref } = purchaseItem

          await orderApi.updateStatusOrderByBuyer({
            ref,
            status: BUYER_ORDER_CANCELED,
          })

          dispatch(updateOrderCanceledByRef(ref))
          CustomSwalFire.success({
            title: 'Canceled!',
            text: 'Your order has been canceled.',
          })
        } catch (error) {
          CustomSwalFire.error({
            title: 'Error!',
            text: error.message || error.msgResp,
          })
        }
      },
      onError: (error) => {
        CustomSwalFire.error({
          title: 'Error!',
          text: error.message,
        })
      },
    })
  }

  const onCancelPurchase = async (purchaseItem) => {
    if (userToken) {
      await cancelPurchaseWithLogin(purchaseItem)
    } else {
      await cancelPurchaseNoLogin(purchaseItem)
    }
  }

  const onFilter = (e) => {
    const statusSelected = Number(e.target.value)

    dispatch(onFilterMyPurchase(statusSelected))
  }

  return (
    <div className='my-purchase container mt-3 px-0'>
      <div className='content p-3 rounded'>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <Link
            to='/virtual-shop/list'
            className='animated-underline d-flex align-items-center text-secondary btn-back'
          >
            <BiArrowBack />
            Back
          </Link>
        </div>
        <h5 className='text-center fw-bold mb-3' style={{ color: '#17a2b8' }}>
          List Your Purchase
        </h5>

        <div className={'row mb-3'}>
          <SearchShop
            setSearch={setSearch}
            placeholder={
              hasLoginFetched || hasNoLoginFetched
                ? 'Search by email, phone or order ref'
                : 'Search by email or order ref'
            }
            onSubmitSearch={submitGetListPurchaseNoLogin}
            setLoadingDebounce={setLoading}
            styles={{ paddingLeft: 0 }}
            autofocus={true}
          />
        </div>

        {loading && <LoadingSkeletonMyPurchase />}

        {!loading && originalListPurchase?.length ? <FilterMyPurchase onFilter={onFilter} /> : null}

        {!loading && originalListPurchase.length ? (
          <ListMyPurchase
            listPurchaseFiltered={listPurchaseFiltered}
            cancelPurchase={onCancelPurchase}
          />
        ) : null}

        {!loading && originalListPurchase?.length === 0 ? (
          <>
            <NotFoundDataComponent
              icon={<GoInbox className='mb-2' />}
              subtitle='You have not purchased any items yet.'
              className='mb-0'
            />
            <CustomButton
              text='Start Shopping'
              variant='outlined'
              color='aqua'
              onClick={() => {
                history.push('/virtual-shop/list')
              }}
              className='mt-2 mb-5 w-auto mx-auto'
              icon={<FaStore />}
            />
          </>
        ) : null}
      </div>
    </div>
  )
}

export default MyPurchase

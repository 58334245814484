import CustomButton from '_components/CustomButton'
import { OrderRecordContext } from '_contexts/OrderRecordContext'
import { ModalContext } from '_contexts/ToggleModalContext'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { DateRange } from 'react-date-range'
import { IoMdCheckmark } from 'react-icons/io'
import { TiCancel } from 'react-icons/ti'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

function OrderDate() {
  const { toggleModal, isModal } = useContext(ModalContext)
  const { onSelectRangeOrderDate, filter } = useContext(OrderRecordContext)

  const [rangeDate, setRangeDate] = useState([
    {
      startDate: moment().startOf('month').toDate(),
      endDate: moment().endOf('month').toDate(),
      key: 'selection',
    },
  ])

  useEffect(() => {
    setRangeDate([
      {
        startDate: filter.orderDate[0].startDate,
        endDate: filter.orderDate[0].endDate,
        key: 'selection',
      },
    ])
  }, [JSON.stringify(filter.orderDate)])

  const formatDate = (date) => {
    const { startDate, endDate, key } = date[0]
    const newStartDate = new Date(startDate?.setHours(0, 0, 0, 0))
    const newEndDate = new Date(endDate?.setHours(23, 59, 59, 999))

    return [
      {
        startDate: newStartDate,
        endDate: newEndDate,
        key,
      },
    ]
  }

  const handleSubmit = () => {
    toggleModal()
    onSelectRangeOrderDate(formatDate(rangeDate))
  }

  const handleCancel = () => {
    toggleModal()
  }

  return (
    <>
      <CustomButton text='Order Date' onClick={toggleModal} color='slate' className='mt-0 w-auto' />
      <Modal toggle={toggleModal} isOpen={isModal} className='modal-calendar-orders'>
        <ModalHeader toggle={toggleModal}>Order Date</ModalHeader>
        <ModalBody>
          <div className='select-range-date'>
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setRangeDate([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={rangeDate}
              rangeColors={['#15cdca']}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className='d-flex range-date-button flex-row-reverse'>
            <CustomButton
              text='Ok'
              variant='contained'
              color='aqua'
              icon={<IoMdCheckmark />}
              onClick={handleSubmit}
            />
            <CustomButton
              text='Cancel'
              variant='outlined'
              color='slate'
              icon={<TiCancel />}
              onClick={handleCancel}
              className='me-2'
            />
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

OrderDate.propTypes = {
  getRangeOrderDate: PropTypes.func,
}

export default OrderDate

import AlertIcon from '_images/alert.png'
import React from 'react'
import { Link } from 'react-router-dom'

function NotFound() {
  return (
    <div className='text-center'>
      <img
        src={AlertIcon}
        alt='logo'
        style={{
          mixBlendMode: 'multiply',
        }}
        width={500}
      />
      <h5 style={{ transform: 'translateY(-20px)' }}>
        Sorry, the shop you are looking for cannot be found. Please try again.
      </h5>
      <p>
        Click <Link to='/virtual-shop/list'>here</Link> to go list shop.
      </p>
    </div>
  )
}

export default NotFound

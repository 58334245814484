/* eslint-disable no-else-return */
/* eslint-disable no-confusing-arrow */
/* eslint-disable function-paren-newline */
import React, { Fragment } from 'react'

import ModalProvider from '_contexts/ToggleModalContext'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import OrderManagementHeader from './Common/OrderManagementHeader'
import RenderListOrder from './Common/RenderListOrder'
import './style.scss'

function OrderRecord() {
  return (
    <Fragment>
      <ModalProvider>
        {/* <OrderRecordProvider>
        </OrderRecordProvider> */}
        <div className='order-records p-3'>
          <OrderManagementHeader />
          <RenderListOrder />
        </div>
      </ModalProvider>
    </Fragment>
  )
}

export default OrderRecord

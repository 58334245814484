import InputField from '_components/InputField'
import React from 'react'

function PickupAddress({ pickupAddress, index, ...rest }) {
  const { register, errors } = rest

  return (
    <div className='delivery-options border rounded-3 p-3 mb-3'>
      <label htmlFor='pickup-address' className='w-100 mb-0'>
        <div className='d-flex align-items-center'>
          <InputField
            id={`pickup-address-${index}`}
            type='text'
            label='Pickup Address'
            labelStyle={{ fontSize: 14, fontWeight: 400 }}
            placeholder='Enter Pickup Address'
            register={register('pickupAddress', { required: true })}
            defaultValue={pickupAddress}
            width='100%'
          />
        </div>
        {errors.pickupAddress && <div className='text-danger'>{errors.pickupAddress.message}</div>}
      </label>
    </div>
  )
}

export default PickupAddress

import orderApi from '_api/order'
import Avatar from '_components/Avatar'
import CustomButton from '_components/CustomButton'
import CustomSelectBox from '_components/CustomSelectBox'
import { UPDATE_STATUS_ORDER } from '_redux/constants/orderConstants'
import { REVERT_ORDER_STATUS } from '_redux/modules/order'
import {
  BUYER_ORDER_CANCELED,
  BUYER_ORDER_COLLECTED,
  BUYER_ORDER_PAID,
  BUYER_ORDER_REFUNDED,
  BUYER_ORDER_UNPAID,
  DELIVERY_OPTION,
  HOST_ORDER_CANCELED,
  HOST_ORDER_FULFILLED,
  HOST_ORDER_PAID,
  HOST_ORDER_UNPAID,
  HOST_ORDER_VERIFIED,
  SELF_PICK_UP,
  TURN_ON,
} from '_utils/constant'
import { formatPhoneNumber } from '_utils/functions/formatter'
import { isEmpty } from 'lodash'
import moment from 'moment'
import React from 'react'
import CurrencyFormat from 'react-currency-format'
import { FaCheck } from 'react-icons/fa6'
import { GiPerson } from 'react-icons/gi'
import { GoEye, GoGift } from 'react-icons/go'
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

const AVATAR_URL =
  process.env.REACT_APP_AVATAR_URL || 'https://ui-avatars.com/api/?size=128&background=random&name='

const orderStatusOptions = [
  { value: HOST_ORDER_CANCELED, label: 'Cancelled', className: 'cancel-status' },
  { value: HOST_ORDER_UNPAID, label: 'Unpaid', className: 'unpaid-status' },
  { value: HOST_ORDER_PAID, label: 'Paid', className: 'paid-status' },
  { value: HOST_ORDER_VERIFIED, label: 'Verified', className: 'verified-status' },
  { value: HOST_ORDER_FULFILLED, label: 'Fulfilled', className: 'fulfilled-status' },
]

function OrderRecordItem({
  item,
  index,
  isMobile, // Add this prop
  setInfoPersonGift,
  setIsModalGift,
  isModalGift,
  totalPriceEachOrder,
  toggleModal,
  setDataModal,
  toggleProductDetailsModal,
  getStickyClass,
  isReversedDropdown, // Add this prop
}) {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const openPaymentScreen = (e) => {
    e.preventDefault()
    setDataModal(item)
    toggleModal()
  }

  const onChangeSelect = async (e, ref) => {
    const { value } = e.target
    const statusSelected = {
      status: Number(value),
      buyerStatus: Number(value),
    }

    if (Number(value) === HOST_ORDER_FULFILLED) {
      statusSelected.buyerStatus = BUYER_ORDER_COLLECTED
    }

    try {
      // Optimistically update the UI first by dispatching update action
      dispatch({
        type: UPDATE_STATUS_ORDER,
        payload: {
          ref,
          status: statusSelected.status,
          buyerStatus: statusSelected.buyerStatus,
        },
      })

      // Then call API
      await orderApi.updateOrderStatus(ref, {
        status: statusSelected.status,
        buyerStatus: statusSelected.buyerStatus,
      })

      addToast('Updated successfully!', { appearance: 'success', autoDismiss: true })
    } catch (error) {
      // If API fails, revert the state
      dispatch({
        type: REVERT_ORDER_STATUS,
        payload: {
          ref,
          status: item.status, // Original status
          buyerStatus: item.buyerStatus, // Original buyerStatus
        },
      })
      addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
    }
  }

  const formatTime = (timeString) => {
    const timeRange = timeString?.split(' - ')
    if (timeRange?.length === 2) {
      return timeRange.map((t) => moment.utc(t, 'HH:mm').local().format('HH:mm')).join(' - ')
    }
    return moment.utc(timeString, 'HH:mm').local().format('HH:mm')
  }

  const renderFulfillmentDateAsString = () => {
    if (item?.fulfillmentType === SELF_PICK_UP) {
      const { date, time } = item?.pickupDetail?.pickupBookingDate || {}
      const formattedTime = formatTime(time)
      return `${date}\n${formattedTime}`
    }

    if (item?.fulfillmentType === DELIVERY_OPTION) {
      const { date, time } = item?.deliveryDetail?.deliveryBookingDate || {}
      const formattedTime = formatTime(time)
      return `${date}\n${formattedTime}`
    }

    const date = moment(item.createdAt * 1000)
      .utc()
      .format('DD-MM-YYYY')
    const time = moment(item.createdAt * 1000)
      .utc()
      .format('HH:mm')
    return `${date}\n${time}`
  }

  const getUserName = () =>
    item?.deliveryDetail?.uName || item?.pickupDetail?.uName || item?.uName || ''

  const getAvatarUrl = () => {
    if (item?.uPhotoUrl) return item.uPhotoUrl
    const userName = getUserName()
    return `${AVATAR_URL}${encodeURIComponent(userName)}`
  }

  // Update these handlers
  const handleGiftClick = () => {
    const giftInfo = item?.deliveryDetail?.giftRecipientInfo || item?.giftRecipientInfo
    setInfoPersonGift(giftInfo)
    setIsModalGift(!isModalGift) // Always set to true when opening
  }

  const handleViewDetails = () => {
    toggleProductDetailsModal(item) // Same function, just using the existing name for now
  }

  const getListStatus = () => {
    if (item?.cashOnDeliveryPickup) {
      return orderStatusOptions.filter((e) => e.value !== HOST_ORDER_PAID)
    }

    return orderStatusOptions
  }

  return (
    <>
      <td
        className={`col-order-no ${
          !isMobile
            ? getStickyClass({
                label: 'No',
              })
            : ''
        }`}
        id={`tooltip-order-${index}`}
      >
        <strong>{index + 1}</strong>
        {/* {item?.lazadaInfo?.order_id && (
          <img alt={'image'} width={70} height={40} src={LazadaIcon} className='lazada-icon' />
        )} */}
      </td>
      <td
        className={`col-customer-name ${
          !isMobile
            ? getStickyClass({
                label: 'Customer Name',
              })
            : ''
        }`}
      >
        <div className='d-flex align-items-center gap-2'>
          <Avatar photoUrl={getAvatarUrl()} size='35px' />
          <div className='text-wrap w-100'>{getUserName()}</div>
        </div>
      </td>
      <td className='col-customer-email text-break'>
        {item?.deliveryDetail?.uEmail || item?.pickupDetail?.uEmail || item?.uEmail}
      </td>
      <td className='col-phone'>
        <div className='text-wrap'>
          {formatPhoneNumber(
            item?.deliveryDetail?.uPhone || item?.pickupDetail?.uPhone || item?.uPhone
          )}
        </div>
      </td>
      <td className='col-fulfillment text-break'>
        <div className='text-wrap'>
          {item?.fulfillmentType === SELF_PICK_UP ? (
            <>
              (<GiPerson />) {item?.pickupDetail?.pickupOptionName}
              {item?.pickupDetail?.uAddress || item?.giftRecipientInfo?.address}
            </>
          ) : item?.fulfillmentType === DELIVERY_OPTION ? (
            `${item?.deliveryDetail?.deliveryOptionName}\nAddress: ${
              item?.deliveryDetail?.uAddress || item?.giftRecipientInfo?.address
            }`
          ) : (
            '--'
          )}
        </div>
      </td>
      <td className='col-date text-break'>
        <div className='text-wrap fw-semibold'>
          {`${moment(item?.createdAt * 1000).format('DD-MM-YYYY')} ${moment(
            item?.createdAt * 1000
          ).format('HH:mm:ss')}`}
        </div>
      </td>
      <td className='col-fulfillment-date text-break'>
        <div className='text-wrap fw-semibold'>{renderFulfillmentDateAsString()}</div>
      </td>
      <td className='col-total-amount text-break'>
        {item?.buyerStatus === BUYER_ORDER_UNPAID && (
          <p className='n-paid text-center mb-0' style={{ paddingRight: 0 }}>
            Not Paid
          </p>
        )}
        {item?.buyerStatus === BUYER_ORDER_PAID && (
          <div
            className='d-flex align-items-center fw-bold justify-content-center'
            style={!item?.cashOnDeliveryPickup ? { cursor: 'pointer' } : {}}
            onClick={(e) => (!item?.cashOnDeliveryPickup ? openPaymentScreen(e) : null)}
          >
            <CurrencyFormat
              value={totalPriceEachOrder(item) || 0}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale={true}
            />
            {item?.cashOnDeliveryPickup !== TURN_ON && (
              <i className='icon-link ms-2' style={{ width: 16, height: 16 }}></i>
            )}
          </div>
        )}
        {item?.buyerStatus === BUYER_ORDER_CANCELED && (
          <p className='mb-0 text-center'>Cancelled</p>
        )}
        {item?.buyerStatus === BUYER_ORDER_COLLECTED && (
          <p className='mb-0 text-center'>
            <span
              className='d-flex align-items-center fw-bold justify-content-center'
              style={!item?.cashOnDeliveryPickup ? { cursor: 'pointer' } : {}}
              onClick={(e) => (!item?.cashOnDeliveryPickup ? openPaymentScreen(e) : null)}
            >
              <CurrencyFormat
                value={totalPriceEachOrder(item) || 0}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />
              <FaCheck color='#14b711' className='ms-1' />
            </span>
          </p>
        )}
        {item?.buyerStatus === BUYER_ORDER_REFUNDED && <p className='mb-0 text-center'>Refunded</p>}

        {item.cashOnDeliveryPickup && (
          <div className='text-wrap text-info fw-semibold text-center'>
            {item.fulfillmentType === DELIVERY_OPTION ? 'Cash on Delivery' : 'Cash on Pick-up'}
          </div>
        )}
      </td>
      <td className='col-status text-break'>
        <CustomSelectBox
          options={getListStatus()}
          value={item?.status}
          onChange={(option) => onChangeSelect({ target: { value: option.value } }, item?.ref)}
          className={`select-status ${
            getListStatus().find((e) => e.value === item?.status).className
          }`}
          isStatusSelect
          isReversedDropdown={isReversedDropdown} // Pass the new prop here
        />
      </td>
      <td className='col-gift-recipient text-center'>
        <div className='d-flex align-items-center'>
          <CustomButton
            icon={<GoEye />}
            className='border-0 w-auto py-1 px-2 mt-0'
            color='slate'
            onClick={handleViewDetails} // Updated handler
          />
          {!isEmpty(item?.deliveryDetail?.giftRecipientInfo || item?.giftRecipientInfo) && (
            <CustomButton
              icon={<GoGift />}
              className='border-0 w-auto py-1 px-2 mt-0'
              color='slate'
              onClick={handleGiftClick} // Updated handler
            />
          )}
        </div>
      </td>
    </>
  )
}

export default OrderRecordItem

import TimeRangeSelect from '_components/TimeRangeSelect'
import { selectDateAndTime } from '_redux/modules/order'
import { DELIVERY_OPTION, SELF_PICK_UP } from '_utils/constant'
import { convertToCurrentGMT, convertToGMT0 } from '_utils/functions/converter'
import { getCartCheckout, setCartCheckout } from '_utils/localData'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications' // Add this import
import CustomDateInput from './CustomDateInput'

const PER_DAY = 1440 // Number of minutes in a day

const valueDay = {
  Sun: 1,
  Mon: 2,
  Tue: 3,
  Wed: 4,
  Thu: 5,
  Fri: 6,
  Sat: 7,
}

// Add this helper function
const isTimeSlotValid = (interval) => {
  const currentDateTime = moment()
  const [dateStr, timeRange] = interval.split(',')
  const [, endTime] = timeRange.trim().split('-')
  const slotDateTime = moment(`${dateStr} ${endTime.trim()}`, 'DD-MM-YYYY HH:mm')
  return !currentDateTime.isAfter(slotDateTime)
}

function Calendar({
  datesOfWeek,
  timeSlot,
  startTime,
  endTime,
  activeTab,
  type,
  isOpenTimePicker,
  setIsOpenTimePicker,
}) {
  if (!datesOfWeek) return null

  const dispatch = useDispatch()
  const cart = getCartCheckout()

  const [startDate, setStartDate] = useState(() => moment().add(1, 'day').toDate())

  const [currentTimeIntervals, setCurrentTimeIntervals] = useState([])

  const { dateAndTime, orderDetailsByRef } = useSelector((state) => state.order)
  const { selectedPickUp, selectedDelivery } = useSelector((state) => state.fulfillment)

  const currentStartTime = convertToCurrentGMT(`${datesOfWeek[0].date} ${startTime}`).slice(11)
  const currentEndTime = convertToCurrentGMT(`${datesOfWeek[6].date} ${endTime}`).slice(11)

  /**
   * minTime: 10:00
   * timeSlot: 120
   * maxTime: 15:00
   * => ["10:00 - 12:00", "12:00 - 14:00", "14:00 - 15:00"]
   */
  const generateTimeIntervals = useCallback(
    (selectedDate) => {
      const formattedDate = moment(selectedDate).format('DD-MM-YYYY')
      const intervals = []

      if (timeSlot && timeSlot < PER_DAY) {
        const startMoment = moment(currentStartTime, 'HH:mm')
        let endMoment = moment(currentEndTime, 'HH:mm')

        // Xử lý trường hợp endTime là ngày hôm sau
        if (endMoment.isBefore(startMoment)) {
          endMoment = moment('23:59', 'HH:mm')
        } else if (currentEndTime === '00:00') {
          endMoment = moment('23:59', 'HH:mm')
        }

        const currentSlot = startMoment.clone()

        while (currentSlot.isBefore(endMoment)) {
          const slotStart = currentSlot.format('HH:mm')

          // Tính thời điểm kết thúc của slot
          const slotEndMoment = moment.min(currentSlot.clone().add(timeSlot, 'minutes'), endMoment)

          // Format thời điểm kết thúc
          let slotEnd
          if (slotEndMoment.format('HH:mm') === '00:00' && !slotEndMoment.isSame(endMoment)) {
            slotEnd = '23:59'
          } else {
            slotEnd = slotEndMoment.format('HH:mm')
          }

          intervals.push(`${formattedDate}, ${slotStart} - ${slotEnd}`)

          // Di chuyển đến slot tiếp theo
          currentSlot.add(timeSlot, 'minutes')

          // Tránh vòng lặp vô tận
          if (currentSlot.isAfter(endMoment)) break
        }

        return intervals
      }

      // Trường hợp không có timeSlot
      let endTimeFormatted = currentEndTime
      if (currentEndTime === '00:00') {
        endTimeFormatted = '23:59'
      }

      return [`${formattedDate}, ${currentStartTime} - ${endTimeFormatted}`]
    },
    [timeSlot, currentStartTime, currentEndTime]
  )

  const { addToast } = useToasts()

  // Add this function to check if all time slots are disabled
  const isAllTimeSlotsDisabled = useCallback(
    (selectedDate) => {
      const intervals = generateTimeIntervals(selectedDate)
      if (!intervals.length) return false

      const currentDateTime = moment()
      return intervals.every((interval) => {
        const [dateStr, timeRange] = interval.split(',')
        const [, endSlotTime] = timeRange.trim().split('-')
        const slotDateTime = moment(`${dateStr} ${endSlotTime.trim()}`, 'DD-MM-YYYY HH:mm')
        return currentDateTime.isAfter(slotDateTime)
      })
    },
    [generateTimeIntervals]
  )

  const [rangeTime, setRangeTime] = useState('')

  const convertToTimestamp = (dateStr, timeRange) => {
    if (timeRange) {
      const [, _endTime] = timeRange.split(' - ')

      const dateTimeStr = `${dateStr} ${_endTime}`

      const dateTime = moment(dateTimeStr, 'DD-MM-YYYY HH:mm')

      return dateTime.unix()
    }
  }

  const filterDateClose = useCallback(
    (date) => {
      const day = date.getDay()
      const { isClose } = datesOfWeek[day]

      return !isClose
    },
    [datesOfWeek, activeTab]
  )

  const getNextAvailableDate = useCallback(
    (currentDate) => {
      const nextDate = moment(currentDate)
      while (!filterDateClose(nextDate.toDate())) {
        nextDate.add(1, 'days')
      }
      return nextDate.toDate()
    },
    [filterDateClose, activeTab]
  )

  useEffect(() => {
    dispatch(
      selectDateAndTime({
        ...dateAndTime,
        date: moment(startDate).format('DD-MM-YYYY'),
        title: moment(startDate).format('ddd'),
        value: valueDay[moment(startDate).format('ddd')],
      })
    )
  }, [moment(startDate).format('DD-MM-YYYY')])

  useEffect(() => {
    let today = moment()
    const nextAvailableDate = getNextAvailableDate(today.toDate())
    setStartDate(nextAvailableDate)

    const bookingDateDelivery = orderDetailsByRef?.orderDelivery?.deliveryBookingDate
    const bookingDatePickup = orderDetailsByRef?.orderPickup?.pickupBookingDate
    const orderBookingDate = bookingDateDelivery || bookingDatePickup

    if (orderBookingDate) {
      const bookingMomentDate = moment(orderBookingDate?.date, 'DD-MM-YYYY')

      if (!filterDateClose(bookingMomentDate.toDate())) {
        today = getNextAvailableDate(bookingMomentDate.toDate())
      } else {
        today = bookingMomentDate
      }

      setStartDate(moment(today, 'DD-MM-YYYY').toDate())
      dispatch(
        selectDateAndTime({
          ...dateAndTime,
          date: moment(today).format('DD-MM-YYYY'),
          title: moment(today).format('ddd'),
          value: valueDay[moment(today).format('ddd')],
        })
      )
    } else {
      // Thay thế timeIntervals bằng currentTimeIntervals
      const newTimeIntervals = generateTimeIntervals(nextAvailableDate)
      setCurrentTimeIntervals(newTimeIntervals)

      if (newTimeIntervals?.length && newTimeIntervals[0]) {
        const currentDate = moment()
        let selectedInterval = newTimeIntervals[0]
        const listDisabledTime = []

        for (let i = 0; i < newTimeIntervals.length; i++) {
          const interval = newTimeIntervals[i]
          const intervalDate = moment(interval.split(',')[0], 'DD-MM-YYYY')
          const intervalRangeTime = interval.split(',')[1]
          const intervalEndTime = moment(
            `${intervalDate.format('DD-MM-YYYY')} ${intervalRangeTime.split('-')[1].trim()}`,
            'DD-MM-YYYY HH:mm'
          )

          if (currentDate.isAfter(intervalEndTime)) {
            listDisabledTime.push(intervalEndTime)
          }

          if (!currentDate.isAfter(intervalEndTime)) {
            selectedInterval = interval
            break
          }
        }

        if (listDisabledTime.length === newTimeIntervals.length) {
          const nextDate = moment(today).add(1, 'days')
          while (!filterDateClose(nextDate.toDate())) {
            nextDate.add(1, 'days')
          }
          setStartDate(nextDate.toDate())
        }

        setRangeTime(selectedInterval)

        const convertRangeTimeToGMT0 = selectedInterval
          .split(',')[1]
          ?.split('-')
          ?.map((time) => {
            const timeGMT0 = convertToGMT0(`
      ${moment(startDate).format('DD-MM-YYYY')}
      ${time}
    `)
            return timeGMT0?.slice(11)
          })
          ?.join(' - ')

        dispatch(
          selectDateAndTime({
            ...dateAndTime,
            date: moment(nextAvailableDate).format('DD-MM-YYYY'),
            title: moment(nextAvailableDate).format('ddd'),
            value: valueDay[moment(nextAvailableDate).format('ddd')],
            time: convertRangeTimeToGMT0,
          })
        )
      }
    }
  }, [getNextAvailableDate, activeTab])

  useEffect(() => {
    const bookingDateDelivery = orderDetailsByRef?.orderDelivery?.deliveryBookingDate
    const bookingDatePickup = orderDetailsByRef?.orderPickup?.pickupBookingDate
    const bookingTimeDelivery = orderDetailsByRef?.orderDelivery?.deliveryBookingTime
    const bookingTimePickup = orderDetailsByRef?.orderPickup?.pickupBookingTime

    const orderPickupId = orderDetailsByRef?.orderPickup?.pickupOptionId
    const orderDeliveryId = orderDetailsByRef?.orderDelivery?.deliveryOptionId
    const orderFulfillmentId = orderDeliveryId || orderPickupId

    if (
      (orderFulfillmentId === selectedPickUp?.id || orderFulfillmentId === selectedDelivery?.id) &&
      (bookingDateDelivery || bookingDatePickup)
    ) {
      const bookingDate = bookingDateDelivery || bookingDatePickup
      const rangeTimeBooking = bookingTimeDelivery || bookingTimePickup

      const convertRangeTimeOrderToArray = rangeTimeBooking.split('-')

      const convertRangeTimeOrderToCurrentGMT = convertRangeTimeOrderToArray
        ?.map((time) => {
          const currentTime = convertToCurrentGMT(`${bookingDate?.date} ${time.trim()}`)
          return currentTime?.slice(11)
        })
        ?.join(' - ')

      setRangeTime(convertRangeTimeOrderToCurrentGMT)

      const selectedDate = moment(startDate).format('DD-MM-YYYY')

      if (bookingDate?.date === selectedDate) {
        dispatch(
          selectDateAndTime({
            ...dateAndTime,
            time: rangeTimeBooking,
            date: bookingDate?.date,
            title: bookingDate?.title,
            value: bookingDate?.value,
          })
        )
      } else {
        dispatch(
          selectDateAndTime({
            ...dateAndTime,
            time: rangeTimeBooking,
            date: selectedDate,
            title: bookingDate?.title,
            value: bookingDate?.value,
          })
        )
      }
    } else {
      const newTimeIntervals = generateTimeIntervals(startDate)
      setCurrentTimeIntervals(newTimeIntervals)

      if (newTimeIntervals?.length && newTimeIntervals[0]) {
        const today = moment()
        let selectedInterval = newTimeIntervals[0]
        const listDisabledTime = []

        for (let i = 0; i < newTimeIntervals.length; i++) {
          const interval = newTimeIntervals[i]
          const intervalDate = moment(interval.split(',')[0], 'DD-MM-YYYY')
          const intervalRangeTime = interval.split(',')[1]
          const intervalEndTime = moment(
            `${intervalDate.format('DD-MM-YYYY')} ${intervalRangeTime.split('-')[1].trim()}`,
            'DD-MM-YYYY HH:mm'
          )

          if (today.isAfter(intervalEndTime)) {
            listDisabledTime.push(intervalEndTime)
          }

          if (!today.isAfter(intervalEndTime)) {
            selectedInterval = interval
            break
          }
        }

        if (listDisabledTime.length === newTimeIntervals.length) {
          const nextDate = moment(today).add(1, 'days')
          while (!filterDateClose(nextDate.toDate())) {
            nextDate.add(1, 'days')
          }
          setStartDate(nextDate.toDate())
        }

        setRangeTime(selectedInterval)

        const convertRangeTimeToGMT0 = selectedInterval
          .split(',')[1]
          ?.split('-')
          ?.map((time) => {
            const timeGMT0 = convertToGMT0(`
        ${moment(startDate).format('DD-MM-YYYY')}
        ${time}
      `)
            return timeGMT0?.slice(11)
          })
          ?.join(' - ')

        dispatch(
          selectDateAndTime({
            ...dateAndTime,
            time: convertRangeTimeToGMT0,
            date: moment(startDate).format('DD-MM-YYYY'),
            title: moment(startDate).format('ddd'),
            value: valueDay[moment(startDate).format('ddd')],
          })
        )
      }
    }

    return () => {
      dispatch(selectDateAndTime({}))
    }
  }, [selectedPickUp?.id, selectedDelivery?.id]) // Bỏ timeIntervals dependency

  useEffect(() => {
    const closeDropdown = (event) => {
      const clickedOutside = !document
        .getElementById(type === SELF_PICK_UP ? 'pickup-time' : 'delivery-time')
        .contains(event.target)

      if (clickedOutside) {
        setIsOpenTimePicker(false)
      }
    }

    if (isOpenTimePicker) {
      document.addEventListener('click', closeDropdown)
    }

    return () => {
      document.removeEventListener('click', closeDropdown)
    }
  }, [isOpenTimePicker])

  useEffect(() => {
    const initialTimeIntervals = generateTimeIntervals(startDate)
    setCurrentTimeIntervals(initialTimeIntervals)
  }, [startDate, generateTimeIntervals])

  // Thêm useEffect để handle fill giá trị từ orderDetailsByRef
  useEffect(() => {
    if (orderDetailsByRef) {
      const bookingTime =
        type === DELIVERY_OPTION
          ? orderDetailsByRef?.orderDelivery?.deliveryBookingTime
          : orderDetailsByRef?.orderPickup?.pickupBookingTime

      const bookingDate =
        type === DELIVERY_OPTION
          ? orderDetailsByRef?.orderDelivery?.deliveryBookingDate?.date
          : orderDetailsByRef?.orderPickup?.pickupBookingDate?.date

      if (bookingTime && bookingDate) {
        const convertBookingTimeToCurrentGMT = bookingTime
          .split('-')
          .map((time) => {
            const currentTime = convertToCurrentGMT(`${bookingDate} ${time.trim()}`)
            return currentTime?.slice(11)
          })
          .join(' - ')

        // Set vào state rangeTime với format phù hợp
        const formattedRangeTime = `${bookingDate}, ${convertBookingTimeToCurrentGMT}`
        setRangeTime(formattedRangeTime)

        // Set startDate
        setStartDate(moment(bookingDate, 'DD-MM-YYYY').toDate())

        // Generate và set time intervals cho ngày đó
        const newTimeIntervals = generateTimeIntervals(moment(bookingDate, 'DD-MM-YYYY').toDate())
        setCurrentTimeIntervals(newTimeIntervals)

        // Dispatch để cập nhật redux store
        dispatch(
          selectDateAndTime({
            date: bookingDate,
            title: moment(bookingDate, 'DD-MM-YYYY').format('ddd'),
            value: valueDay[moment(bookingDate, 'DD-MM-YYYY').format('ddd')],
            time: bookingTime,
          })
        )
      }
    }
  }, [orderDetailsByRef, type])

  // Modify onSelectStartDate to include the check
  const onSelectStartDate = (date) => {
    // Check if all time slots are disabled for selected date
    if (isAllTimeSlotsDisabled(date)) {
      addToast('This date is full time slot', {
        appearance: 'error',
        autoDismiss: true,
      })
      return // Don't proceed with date selection
    }

    // Rest of your existing onSelectStartDate logic
    if (rangeTime) {
      const existingTime = rangeTime.includes(',') ? rangeTime.split(',')[1].trim() : rangeTime
      const newRangeTime = `${moment(date).format('DD-MM-YYYY')}, ${existingTime}`

      const newTimeIntervals = generateTimeIntervals(date)
      setCurrentTimeIntervals(newTimeIntervals)

      setStartDate(date)
      setRangeTime(newRangeTime)

      const convertRangeTimeToArray = existingTime.split('-')
      const convertRangeTimeOrderToCurrentGMT = convertRangeTimeToArray
        ?.map((time) => {
          const timeGMT0 = convertToGMT0(`${moment(date).format('DD-MM-YYYY')} ${time.trim()}`)
          return timeGMT0?.slice(11)
        })
        ?.join(' - ')

      dispatch(
        selectDateAndTime({
          date: moment(date).format('DD-MM-YYYY'),
          title: moment(date).format('ddd'),
          value: valueDay[moment(date).format('ddd')],
          time: convertRangeTimeOrderToCurrentGMT,
          createdAtOrder: moment(date).unix(),
        })
      )
      setCartCheckout({
        ...cart,
        startDate: moment(date).format(),
      })
    } else {
      const newTimeIntervals = generateTimeIntervals(date)
      setCurrentTimeIntervals(newTimeIntervals)
      setStartDate(date)
      dispatch(
        selectDateAndTime({
          date: moment(date).format('DD-MM-YYYY'),
          title: moment(date).format('ddd'),
          value: valueDay[moment(date).format('ddd')],
        })
      )
    }
  }

  const onSelectTime = (rangeTimeSelected) => {
    // Add validation check
    if (!rangeTimeSelected) return

    // Set full range time including date
    setRangeTime(rangeTimeSelected) // Set the complete value directly

    // Extract time part for GMT conversion
    const timeRange = rangeTimeSelected.includes(',')
      ? rangeTimeSelected.split(',')[1].trim()
      : rangeTimeSelected.trim()

    const rangeTimeGMT0 = timeRange
      .split('-')
      ?.map((time) => {
        const timeGMT0 = convertToGMT0(`${moment(startDate).format('DD-MM-YYYY')} ${time.trim()}`)
        return timeGMT0.slice(11)
      })
      .join(' - ')

    const [, _endTime] = timeRange.split(' - ')

    dispatch(
      selectDateAndTime({
        date: moment(startDate).format('DD-MM-YYYY'),
        title: moment(startDate).format('ddd'),
        value: valueDay[moment(startDate).format('ddd')],
        time: rangeTimeGMT0,
        createdAtOrder: convertToTimestamp(moment(startDate).format('DD-MM-YYYY'), rangeTimeGMT0),
      })
    )

    setCartCheckout({
      ...cart,
      rangeTimeBooking: rangeTimeGMT0,
      startDate: moment(startDate)
        .set({
          hour: Number(_endTime.trim().slice(0, 2)),
          minute: Number(_endTime.trim().slice(3)),
        })
        .format(),
    })
  }

  useEffect(() => {
    if (
      !orderDetailsByRef?.orderDelivery?.deliveryBookingDate &&
      !orderDetailsByRef?.orderPickup?.pickupBookingDate
    ) {
      const newTimeIntervals = generateTimeIntervals(startDate)
      setCurrentTimeIntervals(newTimeIntervals)

      // Find first valid time slot
      const validTimeSlot = newTimeIntervals.find((interval) => isTimeSlotValid(interval))

      if (validTimeSlot) {
        setRangeTime(validTimeSlot)

        const convertRangeTimeToGMT0 = validTimeSlot
          .split(',')[1]
          ?.split('-')
          ?.map((time) => {
            const timeGMT0 = convertToGMT0(`
              ${moment(startDate).format('DD-MM-YYYY')}
              ${time}
            `)
            return timeGMT0?.slice(11)
          })
          ?.join(' - ')

        dispatch(
          selectDateAndTime({
            ...dateAndTime,
            time: convertRangeTimeToGMT0,
            date: moment(startDate).format('DD-MM-YYYY'),
            title: moment(startDate).format('ddd'),
            value: valueDay[moment(startDate).format('ddd')],
          })
        )
      } else {
        // If no valid time slots found for current date, move to next available date
        const nextDate = moment(startDate).add(1, 'days')
        while (!filterDateClose(nextDate.toDate())) {
          nextDate.add(1, 'days')
        }
        setStartDate(nextDate.toDate())
      }
    }
  }, [selectedPickUp?.id, selectedDelivery?.id, startDate])

  return (
    <div className='calendar__row mb-3'>
      <div className='calendar__col calendar__col--date position-relative'>
        <p className='calendar__label mb-2'>
          {type === DELIVERY_OPTION ? 'Delivery' : 'Pick-up'} Date:
        </p>
        <DatePicker
          className='calendar__datepicker border cursor-pointer custom-datepicker w-100'
          selected={startDate}
          onChange={(date) => onSelectStartDate(date)}
          filterDate={(date) => filterDateClose(date)}
          dateFormat='dd/MM/yyyy'
          placeholderText='Select a date other than today or yesterday'
          minDate={moment().toDate()}
          customInput={<CustomDateInput />}
        />
      </div>
      <div className='calendar__col calendar__col--time'>
        <p className='calendar__label mb-2'>
          {type === DELIVERY_OPTION ? 'Delivery' : 'Pick-up'} Time:
        </p>
        <TimeRangeSelect
          options={currentTimeIntervals.map((interval) => ({
            value: interval,
            label: interval.includes(',') ? interval.split(',')[1].trim() : interval,
          }))}
          value={rangeTime}
          onChange={(option) => onSelectTime(option.value)}
          placeholder='Select time'
          selectedDate={startDate} // Pass selected date
        />
      </div>
    </div>
  )
}

export default Calendar

import InputField from '_components/InputField'
import React from 'react'

function DeliveryName({ optionName, register, errors }) {
  return (
    <div className='mb-3 row'>
      <div className='col-12 d-flex flex-column'>
        <InputField
          type='text'
          label='Option Name'
          labelStyle={{ fontSize: 14, fontWeight: 400 }}
          placeholder='Delivery'
          register={register('optionDeliveryName')}
          defaultValue={optionName}
          error={errors?.optionDeliveryName?.message}
        />
      </div>
    </div>
  )
}

export default DeliveryName

import InputField from '_components/InputField'
import MyPasswordCheckList from '_components/MyPasswordCheckList'
import React from 'react'
import { Spinner } from 'reactstrap'

function CreateUserProfile({
  avatarFileUrl,
  onUserImageUploaded,
  onSubmit,
  register,
  errors,
  handleSubmit,
  isSubmitting,
  watchAllFields,
  isValidChecklist,
  setIsValidChecklist,
}) {
  return (
    <div className='create-profile'>
      <div className='main-content pb-3'>
        <form onSubmit={handleSubmit(onSubmit)} className='container'>
          <h2 className='c-ttl c-ttl--01'>Create User Profile</h2>
          <div className='c-form c-form--email'>
            <div className='upload-image' style={{ width: 107 }}>
              <img
                id='userAvatarImg'
                className='card-img-top'
                src={avatarFileUrl}
                alt=''
                style={{ zIndex: 999 }}
              />
              <div className='upload-image-txt'>
                <i className='icon-capture'></i>
                <span>
                  Add Profile
                  <br />
                  Picture
                </span>
              </div>
              <div className='u-file-upload__item'>
                <div className='u-file-upload__wrap'>
                  <a className='c-upload preview-images-zone'>
                    <input
                      id='userAvatarInput'
                      type='file'
                      accept='image/*'
                      name='file_source_01'
                      size='40'
                      className='inputFile'
                      data-error='#error-file_source_01'
                      onChange={onUserImageUploaded}
                    />
                  </a>
                </div>
              </div>
            </div>
            <small className='errorMessage'></small>
            <div className='d-flex gap-2'>
              <div id='nameFieldGroup' className='w-50'>
                <InputField
                  id='name'
                  placeholder='First Name'
                  name='firstName'
                  type='text'
                  register={register('firstName')}
                />
                {errors?.firstName && (
                  <small className='errorMessage'>{errors.firstName.message}</small>
                )}
              </div>
              <div id='nameFieldGroup' className='w-50'>
                <InputField
                  id='name'
                  placeholder='Last Name'
                  name='lastName'
                  type='text'
                  register={register('lastName')}
                />
                {errors?.lastName && (
                  <small className='errorMessage'>{errors.lastName.message}</small>
                )}
              </div>
            </div>
            <div id='emailFieldGroup'>
              <InputField
                id='email'
                name='email'
                type='text'
                placeholder='E-mail'
                register={register('email')}
              />
              {errors?.email && <small className='errorMessage'>{errors.email.message}</small>}
            </div>
            <InputField
              id='phone'
              name='phone'
              placeholder='Mobile Number'
              register={register('phone')}
              type='text'
            />
            {errors?.phone && <small className='errorMessage'>{errors.phone.message}</small>}
            <InputField
              id='postalCode'
              name='postalCode'
              type='number'
              placeholder='Postal Code'
              register={register('postalCode')}
            />
            {errors?.postalCode && (
              <small className='errorMessage'>{errors.postalCode.message}</small>
            )}

            <InputField
              id='password'
              name='password'
              placeholder='Password'
              type='password'
              register={register('password')}
            />
            {errors?.password && <small className='errorMessage'>{errors.password.message}</small>}

            <InputField
              id='confirmPassword'
              name='confirmPassword'
              placeholder='Confirm Password'
              type='password'
              register={register('confirmPassword')}
            />
            {errors?.confirmPassword && (
              <small className='errorMessage'>{errors.confirmPassword.message}</small>
            )}

            <MyPasswordCheckList
              password={watchAllFields?.password || ''}
              confirmPassword={watchAllFields?.confirmPassword || ''}
              setIsValidChecklist={setIsValidChecklist}
              className={'mt-3'}
            />
            <div className='form-group'>
              <button
                className='btn btn--main'
                type='submit'
                disabled={isSubmitting || isValidChecklist === false}
              >
                {isSubmitting ? <Spinner color='light' size='sm' /> : 'CONNECT NOW'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CreateUserProfile

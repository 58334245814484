/* eslint-disable no-plusplus */
import MySlider from '_components/MySlider'
import { capitalizeWords } from '_utils/function'
import { getUserInfo } from '_utils/localData'
import { isEmpty } from 'lodash'
import React from 'react'
import { AiFillShop } from 'react-icons/ai'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import ShopSocialIcon from './ShopSocialIcon'

function ShopBanner({ shopDetail, handleOnClick }) {
  const shopColorTheme = shopDetail?.shopColorTheme
  const colorTheme = shopColorTheme?.customsColorTheme || shopColorTheme?.defaultColorTheme
  const userInfo = getUserInfo()

  return (
    <div className='shop-detail-banner'>
      {!isEmpty(shopDetail) && (
        <>
          <header className='shop-detail-banner__header'>
            <div className='shop-detail-banner__header-content flex-column align-items-start'>
              <h1
                className='shop-detail-banner__title'
                style={{
                  color: colorTheme?.headerTextColor,
                  width: !userInfo?.id ? '300px' : 'auto',
                }}
              >
                {capitalizeWords(shopDetail?.shopName)}
              </h1>

              <div className='shop-detail-banner__social mt-2'>
                <ShopSocialIcon shopDetail={shopDetail} handleOnClick={handleOnClick} />
              </div>
            </div>
          </header>

          <div className='shop-detail-banner__media'>
            {/* Cập nhật class names để match với SCSS */}
            <div className='slider__container'>
              <MySlider
                listImage={shopDetail?.logo}
                className='slider__gallery'
                showNavButtons={true}
                prevIcon={<FaChevronLeft size={20} />}
                nextIcon={<FaChevronRight size={20} />}
                navButtonClassName='custom-nav-button'
              />
            </div>
          </div>
          {shopDetail?.headline && (
            <div className='shop-detail-banner__description'>
              <h2
                className='shop-detail-banner__description-title'
                style={{ color: colorTheme?.generalTextColor }}
              >
                <AiFillShop className='shop-detail-banner__description-icon' />
                <span>Description</span>
              </h2>
              <p className='shop-detail-banner__description-content'>{shopDetail?.headline}</p>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ShopBanner

import CustomButton from '_components/CustomButton'
import InputField from '_components/InputField'
import React from 'react'
import { IoMdCheckmark } from 'react-icons/io'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const EventExternalURL = (props) => {
  const { modal, toggle, value, onChange } = props
  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Add external URL</ModalHeader>
      <ModalBody>
        <div className='container p-0'>
          <InputField
            type='text'
            value={value?.name}
            label={'Field Name'}
            onChange={(e) => onChange({ ...value, name: e.target.value })}
            offset={{ bottom: 10 }}
          />
          <InputField
            type='text'
            value={value?.value}
            label={'External URL'}
            onChange={(e) => onChange({ ...value, value: e.target.value })}
          />
          <div className='row mt-3'>
            <CustomButton
              text='OK'
              className='mt-0'
              variant='contained'
              color='aqua'
              onClick={toggle}
              icon={<IoMdCheckmark />}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default EventExternalURL

import React from 'react'
import Skeleton from 'react-loading-skeleton'

function LoadingSkeletonMyShops() {
  return (
    <div className='my-shops'>
      {/* Tabs Skeleton */}
      <div className='d-flex gap-3 mb-4'>
        <div style={{ flex: 1 }}>
          <Skeleton height={40} borderRadius={8} />
        </div>
        <div style={{ flex: 1 }}>
          <Skeleton height={40} borderRadius={8} />
        </div>
      </div>

      {/* Create Shop Button Skeleton */}
      <div className='d-flex justify-content-end mb-3'>
        <Skeleton width={120} height={24} />
      </div>

      {/* Shop Cards Skeleton */}
      {[1, 2, 3].map((item) => (
        <div key={item} className='shop-card mb-3'>
          <div className='shop-card__banner'>
            <Skeleton width='100%' height='100%' style={{ transform: 'translateY(-5px)' }} />
          </div>
          <div className='shop-card__content'>
            <div className='shop-card__header'>
              <Skeleton width={200} height={24} />
              <Skeleton circle width={32} height={32} />
            </div>
            <div className='shop-card__description'>
              <Skeleton count={2} />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default LoadingSkeletonMyShops

import classNames from 'classnames'
import { useState } from 'react'
import ProductContent from './ProductContent'
import ProductImage from './ProductImage'

const ProductCard = ({
  product,
  onAddToCart,
  viewMode = 'grid',
  className,
  colorTheme,
  findProduct,
  changeProductQuantityInput,
  handleChangeSubItem,
  getValueSelect,
  addAnotherOrder,
  deleteItemOrder,
}) => {
  const [isHovered, setIsHovered] = useState(false)

  if (!product) return null

  const { name, description, imageUrl, availableOfStock } = product

  const isSoldOut = !availableOfStock

  const checkImage = (url) => {
    if (!url || (!url.includes('png') && !url.includes('jpg'))) {
      return null
    }
    return url
  }

  const cardStyles = {
    opacity: isSoldOut ? 0.5 : 1,
    filter: isSoldOut ? 'grayscale(100%)' : 'none',
    pointerEvents: isSoldOut ? 'none' : 'auto',
  }

  return (
    <div
      className={classNames('product-card', className, {
        'product-card--list': viewMode === 'list',
        'product-card--grid': viewMode === 'grid',
      })}
      style={cardStyles}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ProductImage imageUrl={imageUrl} name={name} checkImage={checkImage} viewMode={viewMode} />

      <ProductContent
        name={name}
        description={description}
        viewMode={viewMode}
        className={className}
        product={product}
        findProduct={findProduct}
        changeProductQuantityInput={changeProductQuantityInput}
        colorTheme={colorTheme}
        handleChangeSubItem={handleChangeSubItem}
        getValueSelect={getValueSelect}
        addAnotherOrder={addAnotherOrder}
        deleteItemOrder={deleteItemOrder}
        isHovered={isHovered}
        isSoldOut={isSoldOut}
        onAddToCart={onAddToCart}
      />
    </div>
  )
}

export default ProductCard

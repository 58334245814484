import React, { useState } from 'react'
import { FaLock } from 'react-icons/fa' // Add this import
import { useToasts } from 'react-toast-notifications'

import reportApi from '_api/report'
import userApi from '_api/user'
import CustomButton from '_components/CustomButton'
import InputField from '_components/InputField'
import MyPasswordCheckList from '_components/MyPasswordCheckList'
import { GrPowerReset } from 'react-icons/gr'
import { IoSave } from 'react-icons/io5'

const ChangePassword = () => {
  const { addToast } = useToasts()
  const [isLoading, setIsLoading] = useState(false)

  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isValidCheckList, setIsValidCheckList] = useState(false)

  const clearState = () => {
    setErrorMessage('')
  }

  const onSubmit = () => {
    if (isValidCheckList) {
      setIsLoading(true)
      userApi
        .changePassword({ newPassword: password, oldPassword: 'aaaa' }) // oldPassword used to cheat API. However, the server will ignore this variable
        .then(({ msgCode }) => {
          if (msgCode % 100 === 0) {
            addToast('Saved successfully', { appearance: 'success', autoDismiss: true })
            clearState()
          }
          setIsLoading(false)
        })
        .catch(({ msgResp, msgCode }) => {
          reportApi.report({ message: `${msgCode} - ${msgResp}` })
          addToast(msgResp, { appearance: 'error', autoDismiss: true })
          setIsLoading(false)
        })
    }
  }

  return (
    <div className='change-password-page'>
      <div className='profile-header-minimal'>
        <span className='icon'>
          <FaLock />
        </span>
        <span className='title mb-0'>Update Password</span>
      </div>

      {errorMessage && (
        <small className='errorMessage' style={{ color: '#ff9900' }}>
          {errorMessage}
        </small>
      )}
      <InputField
        type='password'
        label='Password'
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      <InputField
        type='password'
        label='Confirm Password'
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />

      <MyPasswordCheckList
        password={password}
        confirmPassword={confirmPassword}
        setIsValidChecklist={setIsValidCheckList}
        className={'mt-3'}
      />
      <div className='row row--bot align-self-end mt-3'>
        <div className='col-6'>
          <CustomButton icon={<GrPowerReset />} text='CANCEL' color='slate' className='w-100' />
        </div>
        <div className='col-6'>
          <CustomButton
            type='submit'
            text={isLoading ? 'SAVING...' : 'SAVE'}
            color='main'
            className='w-100'
            onClick={onSubmit}
            isDisabled={isLoading || isValidCheckList === false}
            icon={<IoSave />}
          />
        </div>
      </div>
    </div>
  )
}

export default ChangePassword

import React from 'react'
import { IoClose } from 'react-icons/io5'
import './drawer-sidebar.scss'

const DrawerSidebarMenu = ({ isOpen, onClose, children }) => (
  <>
    <div
      className={`drawer-overlay ${isOpen ? 'drawer-overlay--visible' : ''}`}
      onClick={onClose}
    />
    <div className={`drawer-sidebar ${isOpen ? 'drawer-sidebar--open' : ''}`}>
      <div className='drawer-sidebar__header'>
        <button className='drawer-sidebar__close' onClick={onClose}>
          <IoClose size={24} />
        </button>
      </div>
      <div className='drawer-sidebar__content'>{children}</div>
    </div>
  </>
)

export default DrawerSidebarMenu

import InputField from '_components/InputField'
import React from 'react'

function PickupInstruction({
  checkedPickupInstruction,
  toggleInstruction,
  instructions,
  register,
  errors,
}) {
  return (
    <div className='row align-items-center mb-3'>
      <div className='col-6' style={{ fontSize: 14 }}>
        <span>Add Instruction</span>
      </div>
      <div className='col-6 d-flex justify-content-end'>
        <InputField
          type='checkbox'
          id='pickupInstruction'
          checked={checkedPickupInstruction}
          role='switch'
          onChange={(e) => toggleInstruction(e)}
          isSwitch
        />
      </div>

      {checkedPickupInstruction && (
        <>
          <div className='w-100'></div>
          <div className='col-12 mt-2'>
            <InputField
              type='text'
              as={'textarea'}
              placeholder='Please enter your instruction'
              register={register('instruction')}
              defaultValue={instructions}
            />
            <small className='errorMessage'>{errors?.instruction?.message}</small>
          </div>
        </>
      )}
    </div>
  )
}

export default PickupInstruction

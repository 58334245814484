import InputField from '_components/InputField'
import React from 'react'
import CurrencyFormat from 'react-currency-format'
import { Controller } from 'react-hook-form'
import { MdOutlineAttachMoney } from 'react-icons/md'

function PickupFee({ checkedPickupFee, handleAddPickupFee, pickupFee, errors, control }) {
  return (
    <div className='mb-3 row align-items-center'>
      <div className='col-6' style={{ fontSize: 14 }}>
        <span>Pick-up Fee($)</span>
      </div>
      <div className='col-6 d-flex justify-content-end'>
        <InputField
          type='checkbox'
          id='pickupFee'
          checked={checkedPickupFee}
          role='switch'
          onChange={(e) => handleAddPickupFee(e)}
          isSwitch
        />
      </div>
      {checkedPickupFee && (
        <>
          <div className='w-100'></div>
          <div className='col-4 mt-2'>
            <Controller
              name='pickupFee'
              control={control}
              defaultValue={pickupFee || ''}
              render={({ field: { onChange, value } }) => (
                <InputField
                  as={CurrencyFormat}
                  value={value}
                  displayType={'input'}
                  thousandSeparator={true}
                  decimalScale={2}
                  onValueChange={(values) => {
                    onChange(values.value)
                  }}
                  error={errors?.pickupFee?.message}
                  icon={<MdOutlineAttachMoney />}
                />
              )}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default PickupFee

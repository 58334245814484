import { VIEW_GRID } from '_utils/constant'
import { useSelector } from 'react-redux'
import ProductCard from './ProductCard'

function ProductItem({
  findProduct,
  product,
  changeProductQuantityInput,
  handleChangeSubItem,
  getValueSelect,
  addAnotherOrder,
  deleteItemOrder,
  viewDefault,
  styleFooterCard,
}) {
  if (!product) return null

  const { cart } = useSelector((state) => state.order)
  const { shopDetail } = useSelector((state) => state.shop)
  const colorTheme =
    shopDetail?.shopColorTheme?.customsColorTheme || shopDetail?.shopColorTheme?.defaultColorTheme

  const handleAddToCart = (id, index) => {
    changeProductQuantityInput({
      pid: id,
      number: findProduct(id, index).quantityInput + 1,
      index,
      type: 'plus',
    })
  }

  return (
    <ProductCard
      product={{
        ...product,
        imageUrl: product.photoUrl || product.photoUrls,
        availableOfStock: product.availableOfStock,
      }}
      viewMode={viewDefault === VIEW_GRID ? 'grid' : 'list'}
      colorTheme={colorTheme}
      onAddToCart={() => handleAddToCart(product.id, product.index)}
      showAddToCart={!cart?.orderList?.length && viewDefault === VIEW_GRID}
      styleFooterCard={styleFooterCard}
      findProduct={findProduct}
      changeProductQuantityInput={changeProductQuantityInput}
      handleChangeSubItem={handleChangeSubItem}
      getValueSelect={getValueSelect}
      addAnotherOrder={addAnotherOrder}
      deleteItemOrder={deleteItemOrder}
    />
  )
}

export default ProductItem

/* eslint-disable camelcase */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react'
import Page_001 from './page_001.jpg'
import Page_002 from './page_002.jpg'
import Page_003 from './page_003.jpg'
import Page_004 from './page_004.jpg'
import Page_005 from './page_005.jpg'
import Page_006 from './page_006.jpg'

import './style.scss'

export default function Policy() {
  return (
    <nobr>
      <div style={{ whiteSpace: 'nowrap' }}>
        <div className='policy-image' id='_0:0' style={{ top: 0 }}>
          <img
            alt={'_1100:850'}
            name='_1100:850'
            src={Page_001}
            height='1100'
            width='850'
            border='0'
            // useMap='#Map'
          />
        </div>
        <div className='policy-content' id='_335:102' style={{ top: 102, left: 335, position: '' }}>
          <span
            id='_14.9'
            style={{
              fontWeight: 'bold',
              fontFamily: 'Arial',
              fontSize: '14.9px',
              color: '#000000',
            }}
          >
            Co-hoot Privacy Statement
          </span>
        </div>
        <div className='policy-content' id='_100:146' style={{ top: 146, left: 100 }}>
          <span
            id='_14.1'
            style={{
              fontWeight: 'bold',
              fontFamily: 'Arial',
              fontSize: '14.1px',
              color: '#000000',
            }}
          >
            Introduction
          </span>
        </div>
        <div className='policy-content' id='_100:167' style={{ top: 167, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            Co-hoot is committed to protecting your privacy. This statement explains the steps we
            take to collect, use
          </span>
        </div>
        <div className='policy-content' id='_100:189' style={{ top: 189, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            and protect personal data of users of the Co-hoot’s web and mobile application
            (“Application”). It describes{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:210' style={{ top: 210, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            what categories of personal data we collect, the purposes for which we use those data,
            your choices{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:232' style={{ top: 232, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            regarding use of personal data about you, our security measures, and how you can review
            and correct{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:253' style={{ top: 253, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            data about you. By accessing and/or using the Application, you consent to the data
            collection and use
          </span>
        </div>
        <div className='policy-content' id='_100:275' style={{ top: 275, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            practices described in this privacy statement. This privacy statement does not apply to
            websites,{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:296' style={{ top: 296, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            applications and/or other services that display or link to a different privacy statement
            or policy.
          </span>
        </div>
        <div className='policy-content' id='_100:339' style={{ top: 339, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            As used throughout this Privacy Statement, the term "Co-hoot" and such terms as "the
            company," "legal{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:360' style={{ top: 360, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            entity," "the corporation," "our," "we" and "us" may refer to Co-hoot, or one or more or
            all of Co-hoot's
          </span>
        </div>
        <div className='policy-content' id='_100:382' style={{ top: 382, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            related corporations and/or affiliates. All of these terms are used for convenience only
            and are not{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:403' style={{ top: 403, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            intended as a precise designation of any of the separate companies, each of which
            manages its own affairs.
          </span>
        </div>
        <div className='policy-content' id='_100:447' style={{ top: 447, left: 100 }}>
          <span
            id='_14.1'
            style={{
              fontWeight: 'bold',
              fontFamily: 'Arial',
              fontSize: '14.1px',
              color: '#000000',
            }}
          >
            Data Collection
          </span>
        </div>
        <div className='policy-content' id='_100:468' style={{ top: 468, left: 100 }}>
          <span
            id='_14.1'
            style={{
              fontStyle: 'italic',
              fontFamily: 'Arial',
              fontSize: '14.1px',
              color: '#000000',
            }}
          >
            Data collected directly from users.
            <span style={{ fontStyle: 'normal' }}>
              {' '}
              In order to access and/or use the Application, we may ask you for{' '}
            </span>
          </span>
        </div>
        <div className='policy-content' id='_100:489' style={{ top: 489, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            personal data to register your user account (“Account”) in the Application, to provide a
            service offered{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:511' style={{ top: 511, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            through the Application or to carry out a transaction you request. The personal data and
            other information
          </span>
        </div>
        <div className='policy-content' id='_100:532' style={{ top: 532, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            that we collect may include:
          </span>
        </div>
        <div className='policy-content' id='_100:575' style={{ top: 575, left: 100 }}>
          <span id='_15.0' style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            (i)
          </span>
        </div>
        <div className='policy-content' id='_150:575' style={{ top: 575, left: 150 }}>
          <span id='_14.3' style={{ fontFamily: 'Arial', fontSize: '14.3px', color: '#000000' }}>
            contact details, such as your name, email address, mobile number and address / postal
            code (if{' '}
          </span>
        </div>
        <div className='policy-content' id='_150:596' style={{ top: 596, left: 150 }}>
          <span id='_14.3' style={{ fontFamily: 'Arial', fontSize: '14.3px', color: '#000000' }}>
            applicable);
          </span>
        </div>
        <div className='policy-content' id='_100:639' style={{ top: 639, left: 100 }}>
          <span id='_15.0' style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            (ii)
          </span>
        </div>
        <div className='policy-content' id='_150:639' style={{ top: 639, left: 150 }}>
          <span id='_14.3' style={{ fontFamily: 'Arial', fontSize: '14.3px', color: '#000000' }}>
            your preferences with respect to email or digital marketing;
          </span>
        </div>
        <div className='policy-content' id='_100:682' style={{ top: 682, left: 100 }}>
          <span id='_15.0' style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            (iii)
          </span>
        </div>
        <div className='policy-content' id='_150:682' style={{ top: 682, left: 150 }}>
          <span id='_14.3' style={{ fontFamily: 'Arial', fontSize: '14.3px', color: '#000000' }}>
            financial information, including credit card numbers, bank or customer account
            information;{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:725' style={{ top: 725, left: 100 }}>
          <span id='_15.0' style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            (iv)
          </span>
        </div>
        <div className='policy-content' id='_150:725' style={{ top: 725, left: 150 }}>
          <span id='_14.5' style={{ fontFamily: 'Arial', fontSize: '14.5px', color: '#000000' }}>
            depending on the location settings you select for the Application or on your device –
            location{' '}
          </span>
        </div>
        <div className='policy-content' id='_150:746' style={{ top: 746, left: 150 }}>
          <span id='_14.5' style={{ fontFamily: 'Arial', fontSize: '14.5px', color: '#000000' }}>
            information including your approximate or precise location and hosted events near you;
          </span>
        </div>
        <div className='policy-content' id='_100:789' style={{ top: 789, left: 100 }}>
          <span id='_15.0' style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            (v)
          </span>
        </div>
        <div className='policy-content' id='_150:789' style={{ top: 789, left: 150 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            your past purchase and preferences, as well as information that assists us in
            identifying the best{' '}
          </span>
        </div>
        <div className='policy-content' id='_150:811' style={{ top: 811, left: 150 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            products and services for you;
          </span>
        </div>
        <div className='policy-content' id='_100:854' style={{ top: 854, left: 100 }}>
          <span id='_15.0' style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            (vi)
          </span>
        </div>
        <div className='policy-content' id='_150:854' style={{ top: 854, left: 150 }}>
          <span id='_14.4' style={{ fontFamily: 'Arial', fontSize: '14.4px', color: '#000000' }}>
            contest entry and event registration information;
          </span>
        </div>
        <div className='policy-content' id='_100:897' style={{ top: 897, left: 100 }}>
          <span id='_15.0' style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            (vii)
          </span>
        </div>
        <div className='policy-content' id='_150:897' style={{ top: 897, left: 150 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            feedback from you about our products and services, including our websites.
          </span>
        </div>
        <div className='policy-content' id='_100:939' style={{ top: 939, left: 100 }}>
          <span id='_14.6' style={{ fontFamily: 'Arial', fontSize: '14.6px', color: '#000000' }}>
            You are not required to provide this information. However, if you choose not to, certain
            functionality
          </span>
        </div>
        <div className='policy-content' id='_100:961' style={{ top: 961, left: 100 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            within the Application may not be available and we may not be able to provide you the
            requested service
          </span>
        </div>
        <div className='policy-content' id='_100:982' style={{ top: 982, left: 100 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            or complete your transaction.
          </span>
        </div>
        <div className='policy-image' id='_0:0' style={{ top: 1100 }}>
          <img
            alt={'_1100:850'}
            name='_1100:850'
            src={Page_002}
            height={1100}
            width={850}
            border={0}
            // useMap='#Map'
          />
        </div>
        <div className='policy-content' id='_100:1223' style={{ top: 1223, left: 100 }}>
          <span
            id='_14.2'
            style={{
              fontStyle: 'italic',
              fontFamily: 'Arial',
              fontSize: '14.2px',
              color: '#000000',
            }}
          >
            Data collected by automated means.
            <span style={{ fontStyle: 'normal' }}>
              {' '}
              We collect certain data about all users of the Application, including:
            </span>
          </span>
        </div>
        <div className='policy-content' id='_100:1265' style={{ top: 1265, left: 100 }}>
          <span id='_15.0' style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            (i)
          </span>
        </div>
        <div className='policy-content' id='_150:1265' style={{ top: 1265, left: 150 }}>
          <span id='_14.0' style={{ fontFamily: 'Arial', fontSize: '14.0px', color: '#000000' }}>
            device information – device type and ID, mobile device language, operating systems and
            versions,
          </span>
        </div>
        <div className='policy-content' id='_150:1287' style={{ top: 1287, left: 150 }}>
          <span id='_14.5' style={{ fontFamily: 'Arial', fontSize: '14.5px', color: '#000000' }}>
            mobile network information;
          </span>
        </div>
        <div className='policy-content' id='_100:1330' style={{ top: 1330, left: 100 }}>
          <span id='_15.0' style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            (ii)
          </span>
        </div>
        <div className='policy-content' id='_150:1330' style={{ top: 1330, left: 150 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            log information - Internet Protocol (IP) address, the dates and times they access the
            Application,{' '}
          </span>
        </div>
        <div className='policy-content' id='_150:1351' style={{ top: 1351, left: 150 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            length of visit, the features or pages they view and number of views, the links they
            click, and other
          </span>
        </div>
        <div className='policy-content' id='_150:1373' style={{ top: 1373, left: 150 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            system activity relating to the Application including uninstallation of the Application;
          </span>
        </div>
        <div className='policy-content' id='_100:1416' style={{ top: 1416, left: 100 }}>
          <span id='_15.0' style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            (iii)
          </span>
        </div>
        <div className='policy-content' id='_150:1416' style={{ top: 1416, left: 150 }}>
          <span id='_14.4' style={{ fontFamily: 'Arial', fontSize: '14.4px', color: '#000000' }}>
            if you transacted in the application – data relating to the transaction(s) including the
            item /{' '}
          </span>
        </div>
        <div className='policy-content' id='_150:1437' style={{ top: 1437, left: 150 }}>
          <span id='_14.4' style={{ fontFamily: 'Arial', fontSize: '14.4px', color: '#000000' }}>
            services purchased, amount charged, discount or promotion code applied, time and date of{' '}
          </span>
        </div>
        <div className='policy-content' id='_150:1458' style={{ top: 1458, left: 150 }}>
          <span id='_14.4' style={{ fontFamily: 'Arial', fontSize: '14.4px', color: '#000000' }}>
            purchase, frequency of purchases;{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:1480' style={{ top: 1480, left: 100 }}>
          <span
            id='_15.0'
            style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}
          ></span>
        </div>
        <div className='policy-content' id='_100:1501' style={{ top: 1501, left: 100 }}>
          <span id='_14.5' style={{ fontFamily: 'Arial', fontSize: '14.5px', color: '#000000' }}>
            Through your Account, we may connect such information with your identity and email
            address and/or{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:1523' style={{ top: 1523, left: 100 }}>
          <span id='_14.5' style={{ fontFamily: 'Arial', fontSize: '14.5px', color: '#000000' }}>
            mobile number to determine your potential interests in our products and services. We may
            also combine
          </span>
        </div>
        <div className='policy-content' id='_100:1544' style={{ top: 1544, left: 100 }}>
          <span id='_14.5' style={{ fontFamily: 'Arial', fontSize: '14.5px', color: '#000000' }}>
            information we collect about you with information received from third parties. Apart
            from information
          </span>
        </div>
        <div className='policy-content' id='_100:1566' style={{ top: 1566, left: 100 }}>
          <span id='_14.5' style={{ fontFamily: 'Arial', fontSize: '14.5px', color: '#000000' }}>
            which may be linked to a specific user, we may also collect data on an aggregated or
            anonymized basis
          </span>
        </div>
        <div className='policy-content' id='_100:1587' style={{ top: 1587, left: 100 }}>
          <span id='_14.5' style={{ fontFamily: 'Arial', fontSize: '14.5px', color: '#000000' }}>
            that does not directly identify any specific user.{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:1630' style={{ top: 1630, left: 100 }}>
          <span id='_14.5' style={{ fontFamily: 'Arial', fontSize: '14.5px', color: '#000000' }}>
            We use the data and information collected to, amongst other legitimate business
            purposes, improve our
          </span>
        </div>
        <div className='policy-content' id='_100:1651' style={{ top: 1651, left: 100 }}>
          <span id='_14.5' style={{ fontFamily: 'Arial', fontSize: '14.5px', color: '#000000' }}>
            security measures, to better understand how users access and use the Application and how
            it can be{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:1673' style={{ top: 1673, left: 100 }}>
          <span id='_14.5' style={{ fontFamily: 'Arial', fontSize: '14.5px', color: '#000000' }}>
            improved to better meet your needs, as well as to gauge interest in our products and
            services.{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:1716' style={{ top: 1716, left: 100 }}>
          <span
            id='_13.9'
            style={{
              fontWeight: 'bold',
              fontFamily: 'Arial',
              fontSize: '13.9px',
              color: '#000000',
            }}
          >
            Your interactions with others{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:1737' style={{ top: 1737, left: 100 }}>
          <span id='_13.9' style={{ fontFamily: 'Arial', fontSize: '13.9px', color: '#000000' }}>
            Our Services offer features which allow users to connect and communicate in groups which
            are public or
          </span>
        </div>
        <div className='policy-content' id='_100:1759' style={{ top: 1759, left: 100 }}>
          <span id='_13.9' style={{ fontFamily: 'Arial', fontSize: '13.9px', color: '#000000' }}>
            semi-public, and to share information with each other to complete a transaction. As
            Co-hoot is a platform
          </span>
        </div>
        <div className='policy-content' id='_100:1780' style={{ top: 1780, left: 100 }}>
          <span id='_13.9' style={{ fontFamily: 'Arial', fontSize: '13.9px', color: '#000000' }}>
            for group-buy, mobile number will be share between Group-buy Organizer and Purchaser to
            contact one{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:1802' style={{ top: 1802, left: 100 }}>
          <span id='_13.9' style={{ fontFamily: 'Arial', fontSize: '13.9px', color: '#000000' }}>
            another. Please use common sense and exercise good judgement when sharing other
            information in these{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:1823' style={{ top: 1823, left: 100 }}>
          <span id='_13.9' style={{ fontFamily: 'Arial', fontSize: '13.9px', color: '#000000' }}>
            groups or with others. We generally do not monitor or remove the information which you
            share and such
          </span>
        </div>
        <div className='policy-content' id='_100:1845' style={{ top: 1845, left: 100 }}>
          <span id='_14.3' style={{ fontFamily: 'Arial', fontSize: '14.3px', color: '#000000' }}>
            information may remain available on our Services after your Account has been
            deactivated, terminated{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:1866' style={{ top: 1866, left: 100 }}>
          <span id='_14.3' style={{ fontFamily: 'Arial', fontSize: '14.3px', color: '#000000' }}>
            or is otherwise closed. You are responsible for the information you choose to share in
            these groups or
          </span>
        </div>
        <div className='policy-content' id='_100:1887' style={{ top: 1887, left: 100 }}>
          <span id='_14.3' style={{ fontFamily: 'Arial', fontSize: '14.3px', color: '#000000' }}>
            with other users.
          </span>
        </div>
        <div className='policy-content' id='_100:1931' style={{ top: 1931, left: 100 }}>
          <span
            id='_13.7'
            style={{
              fontWeight: 'bold',
              fontFamily: 'Arial',
              fontSize: '13.7px',
              color: '#000000',
            }}
          >
            Our Use of Personal Data
          </span>
        </div>
        <div className='policy-content' id='_100:1952' style={{ top: 1952, left: 100 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#2c2c2d' }}>
            We may use the information collected and received from you for the following purposes:
          </span>
        </div>
        <div className='policy-content' id='_145:1991' style={{ top: 1991, left: 145 }}>
          <span
            id='_11.9'
            style={{ fontFamily: 'Times New Roman', fontSize: '11.9px', color: '#2c2c2d' }}
          >
            &nbsp;
            <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px' }}>
              {' '}
              To verify your identity, update our records and generally maintain your Account with
              us.
            </span>
          </span>
        </div>
        <div className='policy-content' id='_145:2010' style={{ top: 2010, left: 145 }}>
          <span
            id='_11.9'
            style={{ fontFamily: 'Times New Roman', fontSize: '11.9px', color: '#2c2c2d' }}
          >
            &nbsp;
            <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px' }}>
              {' '}
              Provide our Services and customize the content shown to you.
            </span>
          </span>
        </div>
        <div className='policy-content' id='_145:2029' style={{ top: 2029, left: 145 }}>
          <span
            id='_11.9'
            style={{ fontFamily: 'Times New Roman', fontSize: '11.9px', color: '#2c2c2d' }}
          >
            &nbsp;
            <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px' }}>
              {' '}
              Develop, operate, improve, deliver, and maintain our Services.
            </span>
          </span>
        </div>
        <div className='policy-content' id='_145:2047' style={{ top: 2047, left: 145 }}>
          <span
            id='_11.9'
            style={{ fontFamily: 'Times New Roman', fontSize: '11.9px', color: '#2c2c2d' }}
          >
            &nbsp;
            <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px' }}>
              {' '}
              To process transactions.
            </span>
          </span>
        </div>
        <div className='policy-content' id='_145:2066' style={{ top: 2066, left: 145 }}>
          <span
            id='_11.9'
            style={{ fontFamily: 'Times New Roman', fontSize: '11.9px', color: '#2c2c2d' }}
          >
            &nbsp;
            <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px' }}>
              {' '}
              To process or arrange payment and/or shipping services.
            </span>
          </span>
        </div>
        <div className='policy-image' id='_0:0' style={{ top: 2200 }}>
          <img
            alt={'_1100:850'}
            name='_1100:850'
            src={Page_003}
            height={1100}
            width={850}
            border={0}
            // useMap='#Map'
          />
        </div>
        <div className='policy-content' id='_145:2300' style={{ top: 2300, left: 145 }}>
          <span
            id='_11.9'
            style={{ fontFamily: 'Times New Roman', fontSize: '11.9px', color: '#2c2c2d' }}
          >
            &nbsp;
            <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px' }}>
              {' '}
              To process or resolve any dispute arising from or in connection with any transactions,
              in{' '}
            </span>
          </span>
        </div>
        <div className='policy-content' id='_170:2320' style={{ top: 2320, left: 170 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#2c2c2d' }}>
            accordance with our Terms of Service.
          </span>
        </div>
        <div className='policy-content' id='_145:2337' style={{ top: 2337, left: 145 }}>
          <span
            id='_11.9'
            style={{ fontFamily: 'Times New Roman', fontSize: '11.9px', color: '#2c2c2d' }}
          >
            &nbsp;
            <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px' }}>
              {' '}
              To detect or monitor any frauds or other prohibited content, businesses or activities.{' '}
            </span>
          </span>
        </div>
        <div className='policy-content' id='_145:2356' style={{ top: 2356, left: 145 }}>
          <span
            id='_11.9'
            style={{ fontFamily: 'Times New Roman', fontSize: '11.9px', color: '#2c2c2d' }}
          >
            &nbsp;
            <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px' }}>
              {' '}
              Responding to your queries and resolving complaints.
            </span>
          </span>
        </div>
        <div className='policy-content' id='_145:2375' style={{ top: 2375, left: 145 }}>
          <span
            id='_11.9'
            style={{ fontFamily: 'Times New Roman', fontSize: '11.9px', color: '#2c2c2d' }}
          >
            &nbsp;
            <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px' }}>
              {' '}
              To facilitate, confirm and/or provide updates or notifications in relation to any
              transactions.
            </span>
          </span>
        </div>
        <div className='policy-content' id='_145:2393' style={{ top: 2393, left: 145 }}>
          <span
            id='_11.9'
            style={{ fontFamily: 'Times New Roman', fontSize: '11.9px', color: '#2c2c2d' }}
          >
            &nbsp;
            <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px' }}>
              {' '}
              Carry out polls, surveys, analysis and research on how our Services are being used and
              how{' '}
            </span>
          </span>
        </div>
        <div className='policy-content' id='_170:2413' style={{ top: 2413, left: 170 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#2c2c2d' }}>
            we can improve them.
          </span>
        </div>
        <div className='policy-content' id='_145:2431' style={{ top: 2431, left: 145 }}>
          <span
            id='_11.9'
            style={{ fontFamily: 'Times New Roman', fontSize: '11.9px', color: '#2c2c2d' }}
          >
            &nbsp;
            <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px' }}>
              {' '}
              To update you on your listings and our Services (e.g. new offers, new chats, new
              feedback,{' '}
            </span>
          </span>
        </div>
        <div className='policy-content' id='_170:2450' style={{ top: 2450, left: 170 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#2c2c2d' }}>
            new comments). These updates will be sent by email and/or through push notifications and{' '}
          </span>
        </div>
        <div className='policy-content' id='_170:2469' style={{ top: 2469, left: 170 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#2c2c2d' }}>
            you can choose to unsubscribe from these updates at any time through the “Settings” page{' '}
          </span>
        </div>
        <div className='policy-content' id='_170:2488' style={{ top: 2488, left: 170 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#2c2c2d' }}>
            of your Account.
          </span>
        </div>
        <div className='policy-content' id='_145:2505' style={{ top: 2505, left: 145 }}>
          <span
            id='_11.9'
            style={{ fontFamily: 'Times New Roman', fontSize: '11.9px', color: '#2c2c2d' }}
          >
            &nbsp;
            <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px' }}>
              {' '}
              To send you marketing and/or promotional materials on the latest products and services
              of{' '}
            </span>
          </span>
        </div>
        <div className='policy-content' id='_170:2525' style={{ top: 2525, left: 170 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#2c2c2d' }}>
            Carousell or carefully selected third parties. These updates will be sent by email
            and/or{' '}
          </span>
        </div>
        <div className='policy-content' id='_170:2544' style={{ top: 2544, left: 170 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#2c2c2d' }}>
            through push notifications and you can choose to unsubscribe from these marketing{' '}
          </span>
        </div>
        <div className='policy-content' id='_170:2562' style={{ top: 2562, left: 170 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#2c2c2d' }}>
            communications via the link in the email you have received. These marketing{' '}
          </span>
        </div>
        <div className='policy-content' id='_170:2581' style={{ top: 2581, left: 170 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#2c2c2d' }}>
            communications may also be communicated to you through text messages and/or voice calls{' '}
          </span>
        </div>
        <div className='policy-content' id='_170:2599' style={{ top: 2599, left: 170 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#2c2c2d' }}>
            if you have indicated that you wish to receive such updates through these methods.
          </span>
        </div>
        <div className='policy-content' id='_100:2660' style={{ top: 2660, left: 100 }}>
          <span
            id='_13.7'
            style={{
              fontWeight: 'bold',
              fontFamily: 'Arial',
              fontSize: '13.7px',
              color: '#000000',
            }}
          >
            Disclosure of Personal Data
          </span>
        </div>
        <div className='policy-content' id='_100:2680' style={{ top: 2680, left: 100 }}>
          <span id='_13.7' style={{ fontFamily: 'Arial', fontSize: '13.7px', color: '#000000' }}>
            Except as described below, personal data that you provide to Co-hoot via the Application
            will not be shared
          </span>
        </div>
        <div className='policy-content' id='_100:2702' style={{ top: 2702, left: 100 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            outside of Co-hoot and its affiliates and related corporations without your consent.
          </span>
        </div>
        <div className='policy-content' id='_100:2745' style={{ top: 2745, left: 100 }}>
          <span
            id='_14.2'
            style={{
              fontStyle: 'italic',
              fontFamily: 'Arial',
              fontSize: '14.2px',
              color: '#000000',
            }}
          >
            Disclosure to service providers.
            <span style={{ fontStyle: 'normal' }}>
              {' '}
              Co-hoot contracts with other companies, vendors and service providers to{' '}
            </span>
          </span>
        </div>
        <div className='policy-content' id='_100:2766' style={{ top: 2766, left: 100 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            provide services on our behalf, such as social media services, programming (for apps or
            websites who use
          </span>
        </div>
        <div className='policy-content' id='_100:2788' style={{ top: 2788, left: 100 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            Co-hoot’s APIs or whose APIs Co-hoot uses), marketing, advertising and promotions
            (including{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:2809' style={{ top: 2809, left: 100 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            measurement services and targeted ads or promotions), data processing, sending out
            information and{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:2831' style={{ top: 2831, left: 100 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            communications, processing credit card and other types of payment transactions,
            processing loyalty or
          </span>
        </div>
        <div className='policy-content' id='_100:2852' style={{ top: 2852, left: 100 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            rewards program transactions, and analyzing the Application. We provide these companies
            with only{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:2874' style={{ top: 2874, left: 100 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            those elements of personal data they need to deliver those services. These companies and
            their{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:2895' style={{ top: 2895, left: 100 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            employees are prohibited from using those personal data for any other purposes.
          </span>
        </div>
        <div className='policy-content' id='_100:2938' style={{ top: 2938, left: 100 }}>
          <span
            id='_14.2'
            style={{
              fontStyle: 'italic',
              fontFamily: 'Arial',
              fontSize: '14.2px',
              color: '#000000',
            }}
          >
            Disclosure in connection with transactions.
            <span style={{ fontStyle: 'normal' }}>
              {' '}
              In connection with certain transactions, we may disclose some{' '}
            </span>
          </span>
        </div>
        <div className='policy-content' id='_100:2959' style={{ top: 2959, left: 100 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            or all of the personal data you provide to financial institutions, government entities
            and shipping{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:2981' style={{ top: 2981, left: 100 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            companies or postal services involved in fulfilling the transaction.
          </span>
        </div>
        <div className='policy-content' id='_100:3024' style={{ top: 3024, left: 100 }}>
          <span
            id='_14.2'
            style={{
              fontStyle: 'italic',
              fontFamily: 'Arial',
              fontSize: '14.2px',
              color: '#000000',
            }}
          >
            Disclosures in connection with acquisitions or divestitures.
            <span style={{ fontStyle: 'normal' }}>
              {' '}
              Circumstances may arise where for strategic or{' '}
            </span>
          </span>
        </div>
        <div className='policy-content' id='_100:3045' style={{ top: 3045, left: 100 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            other business reasons Co-hoot decides to sell, buy, merge or otherwise reorganize
            particular businesses.
          </span>
        </div>
        <div className='policy-content' id='_100:3067' style={{ top: 3067, left: 100 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            Such a transaction may involve the disclosure of personal data to prospective or actual
            purchasers, or
          </span>
        </div>
        <div className='policy-content' id='_100:3088' style={{ top: 3088, left: 100 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            receiving it from sellers. Although it is Co-hoot's practice to seek protection for
            information in these types{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:3109' style={{ top: 3109, left: 100 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            of transactions, we cannot guarantee that any entity receiving such data from Co-hoot in
            connection with
          </span>
        </div>
        <div className='policy-content' id='_100:3131' style={{ top: 3131, left: 100 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            one of these transactions will comply with all of the terms of this Privacy Statement.
          </span>
        </div>
        <div className='policy-image' id='_0:0' style={{ top: 3300 }}>
          <img
            alt={'_1100:850'}
            name='_1100:850'
            src={Page_004}
            height={1100}
            width={850}
            border={0}
            // useMap='#Map'
          />
        </div>
        <div className='policy-content' id='_100:3401' style={{ top: 3401, left: 100 }}>
          <span
            id='_14.0'
            style={{
              fontStyle: 'italic',
              fontFamily: 'Arial',
              fontSize: '14.0px',
              color: '#000000',
            }}
          >
            Disclosure on collaborative online platforms.
            <span style={{ fontStyle: 'normal' }}>
              {' '}
              Some of our websites or online forums or platforms promote{' '}
            </span>
          </span>
        </div>
        <div className='policy-content' id='_100:3423' style={{ top: 3423, left: 100 }}>
          <span id='_14.0' style={{ fontFamily: 'Arial', fontSize: '14.0px', color: '#000000' }}>
            collaboration among registered users with respect to a particular area or topic. On such
            online platforms,
          </span>
        </div>
        <div className='policy-content' id='_100:3444' style={{ top: 3444, left: 100 }}>
          <span id='_14.0' style={{ fontFamily: 'Arial', fontSize: '14.0px', color: '#000000' }}>
            we may share your username with other participants to label comments or suggestions that
            you make as{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:3465' style={{ top: 3465, left: 100 }}>
          <span id='_14.0' style={{ fontFamily: 'Arial', fontSize: '14.0px', color: '#000000' }}>
            yours. We do not, however, share your name, contact details, or other personal data with
            other users of
          </span>
        </div>
        <div className='policy-content' id='_100:3487' style={{ top: 3487, left: 100 }}>
          <span id='_14.0' style={{ fontFamily: 'Arial', fontSize: '14.0px', color: '#000000' }}>
            those collaborative platforms.
          </span>
        </div>
        <div className='policy-content' id='_100:3530' style={{ top: 3530, left: 100 }}>
          <span
            id='_14.0'
            style={{
              fontStyle: 'italic',
              fontFamily: 'Arial',
              fontSize: '14.0px',
              color: '#000000',
            }}
          >
            Disclosure for other reasons.
            <span style={{ fontStyle: 'normal' }}>
              {' '}
              We may disclose personal data if required to do so by law or in the good-
            </span>
          </span>
        </div>
        <div className='policy-content' id='_100:3551' style={{ top: 3551, left: 100 }}>
          <span id='_14.0' style={{ fontFamily: 'Arial', fontSize: '14.0px', color: '#000000' }}>
            faith belief that such action is necessary to comply with legal requirements or with
            legal process served
          </span>
        </div>
        <div className='policy-content' id='_100:3573' style={{ top: 3573, left: 100 }}>
          <span id='_14.0' style={{ fontFamily: 'Arial', fontSize: '14.0px', color: '#000000' }}>
            on us, to protect and defend our rights or property and those of our employees, agents
            or customers, to
          </span>
        </div>
        <div className='policy-content' id='_100:3594' style={{ top: 3594, left: 100 }}>
          <span id='_14.0' style={{ fontFamily: 'Arial', fontSize: '14.0px', color: '#000000' }}>
            enforce our agreements, policies or terms of use, or in urgent circumstances to protect
            the personal safety
          </span>
        </div>
        <div className='policy-content' id='_100:3616' style={{ top: 3616, left: 100 }}>
          <span id='_14.0' style={{ fontFamily: 'Arial', fontSize: '14.0px', color: '#000000' }}>
            of any individual.
          </span>
        </div>
        <div className='policy-content' id='_100:3659' style={{ top: 3659, left: 100 }}>
          <span
            id='_15.0'
            style={{
              fontWeight: 'bold',
              fontFamily: 'Arial',
              fontSize: '15.0px',
              color: '#000000',
            }}
          >
            Security
          </span>
        </div>
        <div className='policy-content' id='_100:3680' style={{ top: 3680, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            Co-hoot is committed to protecting the security of personal data. While no security
            measure can guarantee
          </span>
        </div>
        <div className='policy-content' id='_100:3701' style={{ top: 3701, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            against compromise, loss or misuse (given the transmission of data over the Internet or
            any public{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:3723' style={{ top: 3723, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            network), we use a variety of security technologies and procedures to help protect data
            from{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:3744' style={{ top: 3744, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            unauthorized access, use, or disclosure. Where Co-hoot discloses or provides any data to
            any contractor,
          </span>
        </div>
        <div className='policy-content' id='_100:3766' style={{ top: 3766, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            vendor or service provider, we require that each such party maintains appropriate
            administrative,{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:3787' style={{ top: 3787, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            technical and physical security measures to protect your personal data from destruction,
            loss, and{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:3809' style={{ top: 3809, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            unauthorized access. For example, we store (or ensure that our service providers store)
            the personal data
          </span>
        </div>
        <div className='policy-content' id='_100:3830' style={{ top: 3830, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            you provide on computer systems with limited access that are located in facilities to
            which access is limited.{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:3851' style={{ top: 3851, left: 100 }}>
          <span id='_14.6' style={{ fontFamily: 'Arial', fontSize: '14.6px', color: '#000000' }}>
            When you place an order, view account information, or provide financial information, we
            protect the{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:3873' style={{ top: 3873, left: 100 }}>
          <span id='_14.0' style={{ fontFamily: 'Arial', fontSize: '14.0px', color: '#000000' }}>
            transmission of such data using Secure Socket Layer (SSL) encryption. Notwithstanding
            such measures, to
          </span>
        </div>
        <div className='policy-content' id='_100:3894' style={{ top: 3894, left: 100 }}>
          <span id='_14.5' style={{ fontFamily: 'Arial', fontSize: '14.5px', color: '#000000' }}>
            the extent permitted by law Co-hoot shall not be liable for any loss or damage relating
            to, or any other
          </span>
        </div>
        <div className='policy-content' id='_100:3916' style={{ top: 3916, left: 100 }}>
          <span id='_14.0' style={{ fontFamily: 'Arial', fontSize: '14.0px', color: '#000000' }}>
            consequences of, any unauthorized use, disclosure or loss of, or access to, your
            personal data.
          </span>
        </div>
        <div className='policy-content' id='_100:3959' style={{ top: 3959, left: 100 }}>
          <span id='_14.0' style={{ fontFamily: 'Arial', fontSize: '14.0px', color: '#000000' }}>
            For sites and applications to which you log in, it is your responsibility to ensure the
            security of your PIN
          </span>
        </div>
        <div className='policy-content' id='_100:3980' style={{ top: 3980, left: 100 }}>
          <span id='_14.0' style={{ fontFamily: 'Arial', fontSize: '14.0px', color: '#000000' }}>
            (including the password to your device) and to not reveal this information to others. If
            you are sharing a
          </span>
        </div>
        <div className='policy-content' id='_100:4002' style={{ top: 4002, left: 100 }}>
          <span id='_14.0' style={{ fontFamily: 'Arial', fontSize: '14.0px', color: '#000000' }}>
            device with anyone, you should always log out before leaving a website or application so
            that subsequent
          </span>
        </div>
        <div className='policy-content' id='_100:4023' style={{ top: 4023, left: 100 }}>
          <span id='_14.0' style={{ fontFamily: 'Arial', fontSize: '14.0px', color: '#000000' }}>
            users will not be able to access your data.
          </span>
        </div>
        <div className='policy-content' id='_100:4066' style={{ top: 4066, left: 100 }}>
          <span
            id='_14.0'
            style={{
              fontWeight: 'bold',
              fontFamily: 'Arial',
              fontSize: '14.0px',
              color: '#000000',
            }}
          >
            Retention and Review of Personal Data
          </span>
        </div>
        <div className='policy-content' id='_100:4087' style={{ top: 4087, left: 100 }}>
          <span id='_14.0' style={{ fontFamily: 'Arial', fontSize: '14.0px', color: '#000000' }}>
            During the period that your Account is active, Co-hoot will retain your personal data
            and other information
          </span>
        </div>
        <div className='policy-content' id='_100:4109' style={{ top: 4109, left: 100 }}>
          <span id='_14.0' style={{ fontFamily: 'Arial', fontSize: '14.0px', color: '#000000' }}>
            collected, unless you expressly request us to delete the information. Co-hoot may retain
            the information
          </span>
        </div>
        <div className='policy-content' id='_100:4130' style={{ top: 4130, left: 100 }}>
          <span id='_14.0' style={{ fontFamily: 'Arial', fontSize: '14.0px', color: '#000000' }}>
            (or part of such information) after your Account is deactivated or deleted in the
            following circumstances
          </span>
        </div>
        <div className='policy-content' id='_100:4152' style={{ top: 4152, left: 100 }}>
          <span id='_14.0' style={{ fontFamily: 'Arial', fontSize: '14.0px', color: '#000000' }}>
            (subject to applicable law):
          </span>
        </div>
        <div className='policy-content' id='_100:4195' style={{ top: 4195, left: 100 }}>
          <span id='_15.0' style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            (i)
          </span>
        </div>
        <div className='policy-content' id='_150:4195' style={{ top: 4195, left: 150 }}>
          <span id='_14.4' style={{ fontFamily: 'Arial', fontSize: '14.4px', color: '#000000' }}>
            if we are required or allowed to retain such information by applicable law or
            regulation, or{' '}
          </span>
        </div>
        <div className='policy-content' id='_150:4216' style={{ top: 4216, left: 150 }}>
          <span id='_14.4' style={{ fontFamily: 'Arial', fontSize: '14.4px', color: '#000000' }}>
            pursuant to any requirement of any government authority having jurisdiction over
            Co-hoot;
          </span>
        </div>
        <div className='policy-content' id='_100:4259' style={{ top: 4259, left: 100 }}>
          <span id='_15.0' style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            (ii)
          </span>
        </div>
        <div className='policy-content' id='_150:4259' style={{ top: 4259, left: 150 }}>
          <span id='_14.3' style={{ fontFamily: 'Arial', fontSize: '14.3px', color: '#000000' }}>
            if there is an unresolved issue relating to your Account, such as an outstanding claim
            or{' '}
          </span>
        </div>
        <div className='policy-content' id='_150:4280' style={{ top: 4280, left: 150 }}>
          <span id='_14.3' style={{ fontFamily: 'Arial', fontSize: '14.3px', color: '#000000' }}>
            outstanding credit or payment due;
          </span>
        </div>
        <div className='policy-image' id='_0:0' style={{ top: 4400 }}>
          <img
            alt={'_1100:850'}
            name='_1100:850'
            src={Page_005}
            height={1100}
            width={850}
            border={0}
            // useMap='#Map'
          />
        </div>
        <div className='policy-content' id='_100:4523' style={{ top: 4523, left: 100 }}>
          <span id='_15.0' style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            (iii)
          </span>
        </div>
        <div className='policy-content' id='_150:4523' style={{ top: 4523, left: 150 }}>
          <span id='_14.5' style={{ fontFamily: 'Arial', fontSize: '14.5px', color: '#000000' }}>
            for legitimate business purposes including fraud prevention, audit and preparation of
            business{' '}
          </span>
        </div>
        <div className='policy-content' id='_150:4544' style={{ top: 4544, left: 150 }}>
          <span id='_15.0' style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            reports.
          </span>
        </div>
        <div className='policy-content' id='_100:4587' style={{ top: 4587, left: 100 }}>
          <span
            id='_13.5'
            style={{
              fontWeight: 'bold',
              fontFamily: 'Arial',
              fontSize: '13.5px',
              color: '#000000',
            }}
          >
            Your Rights
          </span>
        </div>
        <div className='policy-content' id='_100:4630' style={{ top: 4630, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            In some cases, you can review and correct personal data provided through the Application
            by going to the
          </span>
        </div>
        <div className='policy-content' id='_100:4651' style={{ top: 4651, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            page on which you provided the data. You can also make a request to review and correct
            personal data{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:4673' style={{ top: 4673, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            collected via the Application or submit any inquiries or concerns you may have regarding
            personal data
          </span>
        </div>
        <div className='policy-content' id='_100:4694' style={{ top: 4694, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            by sending an email to
            <span className='text-decoration-underline'>together.cohoot@gmail.com.</span>
          </span>
        </div>
        <div className='policy-content' id='_100:4737' style={{ top: 4737, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            To the extent required by law, we provide users with (i) reasonable access to the
            personal information
          </span>
        </div>
        <div className='policy-content' id='_100:4758' style={{ top: 4758, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            they provide through the Sites, and (ii) the ability to review, correct and delete such
            personal information.{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:4780' style={{ top: 4780, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            To help protect your privacy, we may take steps to verify your identity before providing
            you access to your
          </span>
        </div>
        <div className='policy-content' id='_100:4801' style={{ top: 4801, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            personal data. You can help us to keep data about you accurate by notifying us of any
            change to your{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:4823' style={{ top: 4823, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            mailing address, phone number or email address.
          </span>
        </div>
        <div className='policy-content' id='_100:4866' style={{ top: 4866, left: 100 }}>
          <span
            id='_14.1'
            style={{
              fontWeight: 'bold',
              fontFamily: 'Arial',
              fontSize: '14.1px',
              color: '#000000',
            }}
          >
            International Transfers of Personal Data
          </span>
        </div>
        <div className='policy-content' id='_100:4887' style={{ top: 4887, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            Personal data collected through the Application may be stored and processed in
            Singapore, Malaysia or
          </span>
        </div>
        <div className='policy-content' id='_100:4909' style={{ top: 4909, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            Vietnam, or any other country in which Co-hoot or its affiliates or related corporations
            or (in the case where{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:4930' style={{ top: 4930, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            we engage a service provider to perform certain services) its service providers maintain
            facilities, including{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:4951' style={{ top: 4951, left: 100 }}>
          <span id='_14.7' style={{ fontFamily: 'Arial', fontSize: '14.7px', color: '#000000' }}>
            countries which may not have data protection laws similar to the laws in the country
            from which you{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:4973' style={{ top: 4973, left: 100 }}>
          <span id='_14.7' style={{ fontFamily: 'Arial', fontSize: '14.7px', color: '#000000' }}>
            initially provided the information. When we transfer your information to other
            countries, we will protect
          </span>
        </div>
        <div className='policy-content' id='_100:4994' style={{ top: 4994, left: 100 }}>
          <span id='_14.7' style={{ fontFamily: 'Arial', fontSize: '14.7px', color: '#000000' }}>
            that information to the extent required by law. By choosing to use the Application and
            to provide data
          </span>
        </div>
        <div className='policy-content' id='_100:5016' style={{ top: 5016, left: 100 }}>
          <span id='_14.7' style={{ fontFamily: 'Arial', fontSize: '14.7px', color: '#000000' }}>
            through it, you consent to any such transfer of information outside of your country.
          </span>
        </div>
        <div className='policy-content' id='_100:5059' style={{ top: 5059, left: 100 }}>
          <span
            id='_13.8'
            style={{
              fontWeight: 'bold',
              fontFamily: 'Arial',
              fontSize: '13.8px',
              color: '#000000',
            }}
          >
            Links to Other Websites or Applications
          </span>
        </div>
        <div className='policy-content' id='_100:5080' style={{ top: 5080, left: 100 }}>
          <span id='_14.3' style={{ fontFamily: 'Arial', fontSize: '14.3px', color: '#000000' }}>
            The Application may contain links to other websites or applications, including those of
            other companies,
          </span>
        </div>
        <div className='policy-content' id='_100:5102' style={{ top: 5102, left: 100 }}>
          <span id='_14.3' style={{ fontFamily: 'Arial', fontSize: '14.3px', color: '#000000' }}>
            professional and government organizations, and publications. These websites and
            applications operate{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:5123' style={{ top: 5123, left: 100 }}>
          <span id='_14.8' style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            independently from the Application, and we do not control and are not responsible for
            the content,{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:5145' style={{ top: 5145, left: 100 }}>
          <span id='_14.3' style={{ fontFamily: 'Arial', fontSize: '14.3px', color: '#000000' }}>
            security or privacy practices employed by other websites or applications. You should
            review the privacy
          </span>
        </div>
        <div className='policy-content' id='_100:5166' style={{ top: 5166, left: 100 }}>
          <span id='_14.3' style={{ fontFamily: 'Arial', fontSize: '14.3px', color: '#000000' }}>
            statements of those websites and applications to determine how they protect and use
            personal data.
          </span>
        </div>
        <div className='policy-content' id='_100:5209' style={{ top: 5209, left: 100 }}>
          <span
            id='_13.7'
            style={{
              fontWeight: 'bold',
              fontFamily: 'Arial',
              fontSize: '13.7px',
              color: '#000000',
            }}
          >
            Questions about our Privacy Practices
          </span>
        </div>
        <div className='policy-content' id='_100:5230' style={{ top: 5230, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            If you have questions regarding this privacy statement or our handling of personal data,
            please contact us
          </span>
        </div>
        <div className='policy-content' id='_100:5252' style={{ top: 5252, left: 100 }}>
          <span id='_14.1' style={{ fontFamily: 'Arial', fontSize: '14.1px', color: '#000000' }}>
            by email:
            <span className='text-decoration-underline'>together.cohoot@gmail.com.</span>
          </span>
        </div>
        <div className='policy-content' id='_100:5317' style={{ top: 5317, left: 100 }}>
          <span
            id='_13.7'
            style={{
              fontWeight: 'bold',
              fontFamily: 'Arial',
              fontSize: '13.7px',
              color: '#000000',
            }}
          >
            Changes to this Privacy Statement
          </span>
        </div>
        <div className='policy-content' id='_100:5338' style={{ top: 5338, left: 100 }}>
          <span id='_14.3' style={{ fontFamily: 'Arial', fontSize: '14.3px', color: '#000000' }}>
            Co-hoot may occasionally update this privacy statement. When we do, we will revise the
            "updated" date{' '}
          </span>
        </div>
        <div className='policy-content' id='_100:5359' style={{ top: 5359, left: 100 }}>
          <span id='_14.3' style={{ fontFamily: 'Arial', fontSize: '14.3px', color: '#000000' }}>
            at the bottom of the privacy statement. You should revisit this page periodically to
            become aware of the
          </span>
        </div>
        <div className='policy-image' id='_0:0' style={{ top: 5500 }}>
          <img
            alt={'_1100:850'}
            name='_1100:850'
            src={Page_006}
            height={1100}
            width={850}
            border={0}
            // useMap='#Map'
          />
        </div>
        <div className='policy-content' id='_100:5601' style={{ top: 5601, left: 100 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            most recent privacy terms; your use of the Application after such changes have been
            posted constitutes
          </span>
        </div>
        <div className='policy-content' id='_100:5623' style={{ top: 5623, left: 100 }}>
          <span id='_14.2' style={{ fontFamily: 'Arial', fontSize: '14.2px', color: '#000000' }}>
            your agreement to such changes.
          </span>
        </div>
      </div>
    </nobr>
  )
}

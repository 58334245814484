import CustomButton from '_components/CustomButton'
import CustomModal from '_components/CustomModal'
import InputField from '_components/InputField'
import React, { useContext, useEffect, useState } from 'react'
import { IoSave } from 'react-icons/io5'
import { TiCancel } from 'react-icons/ti'
import { useSelector } from 'react-redux'
import { ProductItemContext } from '.'

function ProductName({ watchName }) {
  const [isEditName, setIsEditName] = useState(false)

  const { handleSubmit, register, handleSave } = useContext(ProductItemContext)
  const { isSavedProduct } = useSelector((state) => state.product)

  useEffect(() => {
    if (isSavedProduct) setIsEditName(false)
  }, [isSavedProduct])

  const toggle = () => setIsEditName(!isEditName)

  const onSubmit = (data) => {
    handleSave(data)
    toggle()
  }

  return (
    <>
      {!isEditName && !watchName && (
        <div className='text-content' onClick={toggle}>
          Add name
        </div>
      )}

      <div className='text-content'>
        <div className='product-name__text' onClick={toggle}>
          {watchName}
        </div>
      </div>

      <CustomModal isOpen={isEditName} toggle={toggle} title={'Edit Name'} hideFooter>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputField type='text' defaultValue={watchName} register={register('name')} />
          <div className='d-flex gap-2 w-100 justify-content-between mt-3'>
            <CustomButton
              text='Cancel'
              color='slate'
              type='reset'
              icon={<TiCancel />}
              className='mt-0 w-50'
              onClick={toggle}
            />
            <CustomButton
              text='Save'
              icon={<IoSave />}
              variant='contained'
              className='mt-0 w-50'
              color='aqua'
              type='submit'
            />
          </div>
        </form>
      </CustomModal>
    </>
  )
}

export default ProductName

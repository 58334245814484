/* eslint-disable no-useless-escape */
import * as Yup from 'yup'

// const phoneRegExp = /^[0-9\- ]{8,14}$/
const shopNameRegex = /^[a-zA-Z0-9 ]*$/
const URL = /^https:\/\/(www\.)?(instagram\.com|facebook\.com)\/[a-zA-Z0-9(\.\?)?]/i

export const shopValidationSchema = Yup.object().shape({
  shopName: Yup.string('Invalid shop name')
    .required('Invalid shop name')
    .min(3, 'Shop name must be at least 3 characters')
    .max(64, 'Maximum 64 characters allowed.') // Xác nhận giá trị này là 64
    .matches(shopNameRegex, 'Shop name must not contain special characters'),
  headline: Yup.string('Invalid headline')
    .nullable()
    .default('')
    .max(1024, 'Headline must be at most 1024 characters'),
  linkInstagram: Yup.string().matches(URL, {
    message: 'Please enter a valid Instagram URL starting with https',
    excludeEmptyString: true,
  }),
  linkFacebook: Yup.string().matches(URL, {
    message: 'Please enter a valid Facebook URL starting with https',
    excludeEmptyString: true,
  }),
  whatsApp: Yup.string('Invalid whatsApp')
    .required('Phone number is required')
    .matches(/^\d+$/, 'whatsApp must be a number')
    .min(8, 'whatsApp must be at least 8 digits'),
  shopAddress: Yup.string('Invalid address')
    .required('Address is required')
    .min(10, 'Address must be at least 10 characters')
    .max(256, 'Address must be at most 256 characters'),
})

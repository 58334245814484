import classNames from 'classnames'

const QuantityControl = ({
  product,
  findProduct,
  changeProductQuantityInput,
  // colorTheme = {},
  viewMode = 'grid',
}) => (
  <div
    className={classNames('quantity product-card__quantity-control', {
      'quantity product-card__quantity-control--list': viewMode === 'list',
      'quantity product-card__quantity-control--grid': viewMode === 'grid',
    })}
  >
    <span
      className='icon-min'
      onClick={() =>
        changeProductQuantityInput({
          pid: product.id,
          number: findProduct(product.id, product.index).quantityInput - 1,
          index: product.index,
          type: 'minus',
        })
      }
    >
      -
    </span>
    <span className='icon-input'>
      <input
        type='text'
        step='1'
        min='0'
        max='100'
        className='form-control'
        // style={{ color: colorTheme?.generalTextColor }}
        value={findProduct(product.id, product.index).quantityInput}
        onChange={(e) =>
          changeProductQuantityInput({
            pid: product.id,
            number: parseInt(e.target.value, 10),
            index: product.index,
            type: 'typing',
          })
        }
      />
    </span>
    <span
      className='icon-plus'
      onClick={() =>
        changeProductQuantityInput({
          pid: product.id,
          number: findProduct(product.id, product.index).quantityInput + 1,
          index: product.index,
          type: 'plus',
        })
      }
    >
      +
    </span>
  </div>
)

export default QuantityControl

import React from 'react'

function Search({
  styles = null,
  type = 'text',
  className = '',
  placeholder = '',
  onChange,
  value,
  disabled = false,
  required = false,
}) {
  return (
    <div className={'c-form c-form--search'}>
      <div className='form-group' style={styles}>
        <i className='icon-search'></i>
        <input
          className={`form-control ${className}`}
          type={type}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          required={required}
          onChange={onChange}
          style={styles}
        />
      </div>
    </div>
  )
}

export default Search

import { GiHouse } from 'react-icons/gi'
import { IoLogoFacebook, IoLogoInstagram, IoLogoWhatsapp } from 'react-icons/io5'
import InputField from '../InputField'

const ContactInputs = ({ register, errors, createShopData, filterFields = [] }) => {
  const inputFields = [
    {
      id: 'whats-app',
      name: 'whatsApp',
      icon: (
        <IoLogoWhatsapp fontSize={30} color='#A6DFDE' style={{ transform: 'translateY(3px)' }} />
      ),
      placeholder: 'Mobile Number (whatsapp/paynow/paylah)',
      defaultValue: createShopData?.whatsApp,
      containerClass: 'phone-contact',
      isRequired: true,
    },
    {
      id: 'instagram',
      name: 'linkInstagram',
      icon: (
        <IoLogoInstagram fontSize={30} color='#A6DFDE' style={{ transform: 'translateY(3px)' }} />
      ),
      placeholder: 'Instagram URL',
      defaultValue: createShopData?.linkInstagram,
      containerClass: 'instagram-url',
      isRequired: false,
    },
    {
      id: 'facebook',
      name: 'linkFacebook',
      icon: (
        <IoLogoFacebook fontSize={30} color='#A6DFDE' style={{ transform: 'translateY(3px)' }} />
      ),
      placeholder: 'Facebook URL',
      defaultValue: createShopData?.linkFacebook,
      containerClass: 'facebook-url',
      isRequired: false,
    },
    {
      id: 'shopAddress',
      name: 'shopAddress',
      icon: <GiHouse fontSize={30} color='#A6DFDE' style={{ transform: 'translateY(3px)' }} />,
      placeholder: 'Address',
      defaultValue: createShopData?.shopAddress,
      containerClass: 'address',
      isRequired: true,
    },
  ]

  const filteredFields = inputFields.filter((field) => !filterFields.includes(field.name))

  return (
    <>
      {filteredFields.map((field) => (
        <div key={field.id} className={`mb-3 row ${field.containerClass}`}>
          <div className='col-2 col-lg-1'>
            <div className='position-relative' style={{ width: 35, height: 35 }}>
              {field.isRequired && (
                <span style={{ color: 'red', position: 'absolute', top: -5, right: 0 }}>*</span>
              )}
              {field.icon}
            </div>
          </div>
          <div className='col-10 col-lg-11'>
            <InputField
              type='text'
              placeholder={field.placeholder}
              id={field.id}
              defaultValue={field.defaultValue}
              register={register(field.name)}
              error={errors?.[field.name]?.message}
            />
          </div>
        </div>
      ))}
    </>
  )
}

export default ContactInputs

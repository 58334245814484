import CustomSelectBox from '_components/CustomSelectBox'
import { CheckoutContext } from '_contexts/CheckoutContext'
import { selectDelivery } from '_redux/modules/fulfillment'
import { DELIVERY_OPTION } from '_utils/constant'
import { isEmpty } from 'lodash'
import { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Calendar from './Calendar'
import RenderInfo from './RenderInfo'

function TabDelivery() {
  const dispatch = useDispatch()
  const [isOpenTimePicker, setIsOpenTimePicker] = useState(false)
  const { userInfo, activeTab, shop, watch, setValue } = useContext(CheckoutContext)

  const { listDelivery, selectedDelivery, loadingDelivery } = useSelector(
    (state) => state.fulfillment
  )

  const isOwnerShop = userInfo?.shopLinks?.some((item) => item === shop?.shopLink)

  const deliveryOptions = listDelivery.map((delivery) => ({
    value: JSON.stringify(delivery),
    label: delivery.optionName,
  }))

  const handleDeliveryChange = (option) => {
    if (typeof option.value === 'string') {
      dispatch(selectDelivery(JSON.parse(option.value)))
    }

    setValue('deliveryOption', option.value)
  }

  const deliveryValue = watch('deliveryOption')
  const isValidDeliveryValue = deliveryValue && typeof deliveryValue === 'string'

  return (
    <div className='form-delivery-details rounded-top'>
      {!listDelivery.length ? (
        <div className='h-100 w-100 d-flex flex-column align-items-center justify-content-center mb-3'>
          <strong>Currently shop has not support delivery</strong>
          {isOwnerShop && <Link to='/admin-console/fulfillment'>Create delivery option</Link>}
        </div>
      ) : null}

      {!loadingDelivery && listDelivery.length ? (
        <>
          <div className='mb-3 list-delivery'>
            <CustomSelectBox
              options={deliveryOptions}
              value={isValidDeliveryValue ? deliveryValue : ''}
              onChange={handleDeliveryChange}
              placeholder='Select delivery option'
              isFulfillment
            />
          </div>

          {activeTab === DELIVERY_OPTION && !isEmpty(selectedDelivery) && (
            <>
              <RenderInfo
                fee={selectedDelivery.deliveryFee}
                instructions={selectedDelivery.instructions}
                isCheckPublicHoliday={selectedDelivery.isCheckPublicHoliday}
                type={DELIVERY_OPTION}
              />
              <Calendar
                datesOfWeek={selectedDelivery.datesOfWeek}
                timeSlot={selectedDelivery.timeSlot || 30}
                startTime={selectedDelivery.deliveryStartTime}
                endTime={selectedDelivery.deliveryEndTime}
                activeTab={activeTab}
                type={DELIVERY_OPTION}
                isOpenTimePicker={isOpenTimePicker}
                setIsOpenTimePicker={setIsOpenTimePicker}
              />
            </>
          )}
        </>
      ) : null}
    </div>
  )
}

export default TabDelivery

import {
  REVERSE_ORDER_STATUS_CANCELLED,
  REVERSE_ORDER_STATUS_COMPLETED,
  REVERSE_ORDER_STATUS_PENDING,
  REVERSE_ORDER_STATUS_PROCESSING,
  REVERSE_ORDER_STATUS_UNKNOWN,
} from '_utils/LazadaConstants'
import Chance from 'chance'

const chance = new Chance()

const randomLength = Math.floor(Math.random() * 10) + 1
export const mockDataReverseOrdersForSeller = new Array(randomLength)
  .fill(null)
  .map((_, index) => ({
    reverse_order_lines: Array.from({ length: Math.floor(Math.random() * 81) + 20 }).map(
      (__, subIndex) => ({
        product: {
          product_sku: chance.guid(),
          product_id: chance.guid(),
          product_name: chance.sentence(),
          product_image: `https://picsum.photos/id/${subIndex}/200/300`,
        },
        return_order_line_gmt_create: chance.timestamp(),
        platform_sku_id: chance.guid(),
        is_need_refund: chance.bool(),
        trade_order_gmt_create: chance.timestamp(),
        reason_text: chance.sentence(),
        item_unit_price: chance.floating({ min: 0, max: 100.99 }),
        trade_order_line_id: chance.guid(),
        return_order_line_gmt_modified: chance.timestamp(),
        ofc_status: chance.pickone(['RETURN_CANCELED', 'RETURN_REQUESTED', 'RETURN_SUCCESS']),
        seller_sku_id: chance.guid(),
        refund_payment_method: chance.pickone(['Alipay', 'Credit Card']),
        buyer: {
          buyer_id: chance.guid(),
        },
        reason_code: chance.integer({ min: 0 }),
        whqc_decision: chance.pickone(['good', 'scrap', 'reject', 'defect']),
        reverse_status: chance.pickone(['REQUEST_INITIATE', 'REQUEST_SUCCESS']),
        refund_amount: chance.floating({ min: 0, max: 10.99 }),
        tracking_number: chance.zip(),
        receiver_address: chance.address(),
        is_dispute: chance.bool(),
        reverse_order_line_id: chance.guid(),
      })
    ),
    reverse_order_id: chance.guid(),
    request_type: chance.pickone([
      REVERSE_ORDER_STATUS_PENDING,
      REVERSE_ORDER_STATUS_PROCESSING,
      REVERSE_ORDER_STATUS_COMPLETED,
      REVERSE_ORDER_STATUS_CANCELLED,
      REVERSE_ORDER_STATUS_UNKNOWN,
    ]),
    is_rtm: chance.bool(),
    shipping_type: chance.pickone(['DEFAULT', 'EXPRESS', 'STANDARD']),
    trade_order_id: chance.guid(),
    index,
  }))

export const mockDataReverseOrderHistoryList = new Array(10).fill(null).map((_, index) => ({
  index,
  time: '2022-05-01T00:00:00.000Z',
  operator: 'Trung Dev',
  picture: new Array(5).fill(null).map((__, indexPic) => ({
    index,
    url: `https://picsum.photos/id/${indexPic}/200/300`,
  })),
  createdDate: '2022-05-01T00:00:00.000Z',
}))

export const mockDataReverseOrderReasonList = new Array(10).fill(null).map((_, index) => ({
  index,
  reasonId: Math.floor(Math.random() * 100000),
  reason: 'Out Of Stock',
  createdDate: '2022-05-01T00:00:00.000Z',
}))

export const mockDataReverseOrderDetail = {
  reverseOrderId: Math.floor(Math.random() * 100000),
  transactionOrderId: Math.floor(Math.random() * 100000),
  reverseOrderDTOList: new Array(5).fill(null).map((_, index) => ({
    product: {
      name: `Sản phẩm ${index}`,
      price: Math.floor(Math.random() * 100000),
      SKU: `SKU_${index}`,
    },
    refundPaymentMethod: 'Alipay',
    amountReverse: Math.floor(Math.random() * 100000),
  })),
}

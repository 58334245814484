import React from 'react'
import { AiFillCheckCircle, AiFillShopping } from 'react-icons/ai'
import { FiShoppingCart } from 'react-icons/fi'
import { IoMdExit } from 'react-icons/io'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import CustomButton from './CustomButton'

function ModalNotifySuccess({ isModalOpen, handleWhenShowModalPaymentSuccess, isCOD = false }) {
  return (
    <Modal isOpen={isModalOpen} className='modal-payment-cod py-0' backdrop='static'>
      {/* <ModalHeader toggle={toggleModal}>
        <h4 className='mb-0 fw-bold'>
          Buy Product{' '}
          <AiFillShopping
            style={{
              transform: 'translateY(-4px)',
            }}
          />
        </h4>
      </ModalHeader> */}
      <ModalBody>
        <h4 className='mb-3 fw-bold text-center'>
          Buy Product{' '}
          <AiFillShopping
            style={{
              transform: 'translateY(-4px)',
            }}
          />
        </h4>
        <div className='text-center shadow-none border-0'>
          <p
            className='mb-2 fw-semibold'
            style={{
              color: 'green',
            }}
          >
            You have success {isCOD ? 'ordered' : 'paid'}!
            <AiFillCheckCircle
              color='green'
              size={24}
              className='ms-1'
              style={{
                transform: 'translateY(-2px)',
              }}
            />
          </p>
          <p className='f-16 mb-0'>Do you want to continue shopping at this shop?</p>
        </div>
      </ModalBody>
      <ModalFooter className='border-top-0 border-0 d-flex align-items-center flex-row gap-3 flex-nowrap'>
        <CustomButton
          color='slate'
          className='m-0 w-50'
          onClick={() => handleWhenShowModalPaymentSuccess('exit')}
          text='Exit'
          icon={<IoMdExit />}
          variant='outlined'
        />
        <CustomButton
          color='aqua'
          className='m-0 w-50'
          variant='contained'
          onClick={() => handleWhenShowModalPaymentSuccess('continue')}
          text='Continue'
          icon={<FiShoppingCart />}
        />
      </ModalFooter>
    </Modal>
  )
}

export default ModalNotifySuccess

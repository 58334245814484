import { PACKAGE_LAZADA, PACKAGE_SUBSCRIPTION } from '_utils/constant'
import { Button, ButtonGroup } from 'reactstrap'

const SelectTabInvoice = ({ tabInvoice, setTabInvoice }) => (
  <ButtonGroup className='select-tab-invoice__button-group'>
    <Button
      color='primary'
      outline
      onClick={() => setTabInvoice(PACKAGE_SUBSCRIPTION)}
      active={tabInvoice === PACKAGE_SUBSCRIPTION}
      className='select-tab-invoice__button select-tab-invoice__button--subscription shadow-none'
    >
      Subscription
    </Button>
    <Button
      color='primary'
      outline
      onClick={() => setTabInvoice(PACKAGE_LAZADA)}
      active={tabInvoice === PACKAGE_LAZADA}
      className='select-tab-invoice__button select-tab-invoice__button--lazada mt-0 shadow-none'
    >
      Lazada
    </Button>
  </ButtonGroup>
)

export default SelectTabInvoice

/* eslint-disable no-return-assign */
import orderApi from '_api/order'
import uploadApi from '_api/upload'
import CustomButton from '_components/CustomButton'
import CustomSwalFire from '_components/CustomSwalFire'
import ModalNotifySuccess from '_components/ModalNotifySuccess'
import {
  clearCart,
  clearOrderDetailsByRef,
  getOrderDetailsByRef,
  getOrderDetailsByRefNoLogin,
} from '_redux/modules/order'
import {
  BUYER_ORDER_CANCELED,
  BUYER_ORDER_PAID,
  BUYER_ORDER_UNPAID,
  HOST_ORDER_FULFILLED,
  HOST_ORDER_VERIFIED,
} from '_utils/constant'
import { getOrderRefParam, scrollToTop } from '_utils/function'
import { getCartOrderDetailsByRef, getDataShopDetail, getUserToken } from '_utils/localData'
import { isEmpty, truncate } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { BiArrowBack, BiEdit } from 'react-icons/bi'
import { MdOutlinePayment } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import Bill from './components/Bill'
import ContactDetails from './components/ContactDetails'
import Payment from './components/Payment'
import PaymentCodeSkeleton from './components/PaymentCodeSkeleton'
import PaymentCodeVerified from './components/PaymentCodeVerified'
import './style.scss'

function PaymentCode() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const location = useLocation()
  const userToken = getUserToken()
  const photoRef = useRef()
  const shopDetail = getDataShopDetail()
  const orderRefParams = getOrderRefParam()
  const cartOrderDetailsByRef = getCartOrderDetailsByRef()
  const paymentScreenshotURLRef = useRef(null)

  const [previewImg, setPreviewImg] = useState('')
  const [loading, setLoading] = useState(false)
  const [isSubmittedPayment, setIsSubmittedPayment] = useState(false)
  const [isModalOpenSuccess, setIsModalOpenSuccess] = useState(false)
  const [isUploaded, setIsUploaded] = useState(false)
  const [subTotal, setSubTotal] = useState(0)
  const [orderDetailsByRef, setOrderDetailsByRef] = useState({})

  const { orderDetailsByRef: orderDetailsByRefRedux } = useSelector((state) => state.order)

  const cart = orderDetailsByRef?.listProduct
  const previousUrl = location.state?.from

  const totalPrice =
    subTotal +
    (orderDetailsByRef?.orderDelivery?.deliveryFee || 0) +
    (orderDetailsByRef?.orderPickup?.pickupFee || 0) +
    (orderDetailsByRef?.transactionFees || 0)

  const isVerifiedOrder = [HOST_ORDER_VERIFIED, HOST_ORDER_FULFILLED].includes(
    orderDetailsByRef?.status
  )

  const toggleModalSuccess = () => setIsModalOpenSuccess(!isModalOpenSuccess)

  const totalSubItemsPrice = (arr) => {
    if (arr.length === 0) {
      return 0
    }

    if (arr.length === 1) {
      return arr[0].price
    }

    return arr.reduce((acc, curr) => acc + curr.price, 0)
  }

  const onClearCart = () => {
    dispatch(clearOrderDetailsByRef())
    dispatch(clearCart())
  }

  const handleUserTokenOrderDetails = async () => {
    const orderRef = cartOrderDetailsByRef?.ref || orderRefParams

    if (orderRef) {
      await dispatch(getOrderDetailsByRef(orderRef))
    }
  }

  const handleNoLoginOrderDetails = async () => {
    if (cartOrderDetailsByRef?.ref) {
      await dispatch(
        getOrderDetailsByRefNoLogin({
          ref: cartOrderDetailsByRef?.ref,
          uEmail: cartOrderDetailsByRef?.email,
        })
      )
    }
  }

  const handleOrderDetails = async () => {
    if (userToken) {
      await handleUserTokenOrderDetails()
    } else {
      await handleNoLoginOrderDetails()
    }
  }

  const fetchData = async () => {
    try {
      setLoading(true)
      await handleOrderDetails()
    } catch (error) {
      addToast(error.message || error.msgResp, {
        appearance: 'error',
        autoDismiss: true,
      })
    } finally {
      setLoading(false)
    }
  }

  const handleWhenShowModalPaymentSuccess = (type) => {
    if (type === 'continue') {
      // Yes
      if (!isEmpty(shopDetail)) {
        history.push(`/${shopDetail?.shopSlug}`)
      } else if (!isEmpty(orderDetailsByRef)) {
        const shopSlug =
          orderDetailsByRef?.shopLink.split('/')[orderDetailsByRef?.shopLink.split('/').length - 1]
        history.push(`/${shopSlug}`)
      } else {
        const shopSlugNoLogin = localStorage.getItem('shopSlugNoLogin')
        history.push(`/${shopSlugNoLogin}`)
        localStorage.removeItem('shopSlugNoLogin')
      }
      onClearCart()
    } else {
      // No
      history.push('/virtual-shop/list')
      onClearCart()
    }
  }

  const onUserImageUploaded = async (_event) => {
    const { files } = _event.target
    if (files) {
      const img = files[0]
      setPreviewImg(URL.createObjectURL(img))
      setIsUploaded(true)

      const { msgResp: res } = await uploadApi.uploadPhoto(files)
      const { url } = res
      paymentScreenshotURLRef.current = url

      setOrderDetailsByRef({
        ...orderDetailsByRef,
        paymentScreenshot: url,
      })

      setIsUploaded(false)
    }
  }

  const confirmPayment = async (e) => {
    e.preventDefault()
    setIsSubmittedPayment(true)

    if (!cart?.buyer?.isCheckCOD && !paymentScreenshotURLRef.current) {
      setIsSubmittedPayment(false)
      CustomSwalFire.warning({
        title: 'Warning',
        text: 'Please upload payment screenshot',
      })
      return
    }

    try {
      const postData = {
        ref: orderDetailsByRef?.ref,
        buyerStatus: BUYER_ORDER_PAID,
        status: BUYER_ORDER_PAID,
        paymentScreenshot: paymentScreenshotURLRef.current,
        uEmail: orderDetailsByRef?.uEmail || cart?.email,
      }

      if (userToken) {
        if (orderRefParams && orderDetailsByRef?.status === BUYER_ORDER_PAID) {
          await orderApi.updatePaymentScreenshot({
            ref: postData.ref,
            paymentScreenshot: postData.paymentScreenshot,
          })
          setOrderDetailsByRef({
            ...orderDetailsByRef,
            buyerStatus: BUYER_ORDER_PAID,
            status: BUYER_ORDER_PAID,
          })
          setIsUploaded(true)
        } else {
          await orderApi.updateStatusOrderByBuyer(postData)
          localStorage.setItem('ORDER_SUCCESS', 'true')
          setOrderDetailsByRef({
            ...orderDetailsByRef,
            buyerStatus: BUYER_ORDER_PAID,
            status: BUYER_ORDER_PAID,
          })
          setIsUploaded(true)
        }
      } else {
        await orderApi.updateStatusOrderByBuyerNoLogin(postData)
        setOrderDetailsByRef({
          ...orderDetailsByRef,
          buyerStatus: BUYER_ORDER_PAID,
          status: BUYER_ORDER_PAID,
        })
      }

      setIsSubmittedPayment(false)
      CustomSwalFire.success({
        title: 'Payment Successful',
        text: 'Do you want to continue or exit?',
        onConfirm: async () =>
          new Promise((resolve) => {
            onClearCart()
            handleWhenShowModalPaymentSuccess('continue')
            resolve()
          }),
        onError: (error) => {
          console.error('Navigation error:', error)
        },
        confirmButtonText: 'Continue',
        cancelButtonText: 'Exit',
        onCancel: () => {
          onClearCart()
          history.push('/virtual-shop/list')
        },
      })
    } catch (err) {
      CustomSwalFire.error({
        title: 'Error',
        text: err.msgResp || err.message,
      })
      setIsSubmittedPayment(false)
    }
  }

  const handleBackPage = () => {
    history.push(`/virtual-shop/checkout/${orderDetailsByRef?.ref}`)
  }

  const getButtonText = () => {
    if (isSubmittedPayment) {
      return 'Processing...'
    }

    const hasPaymentScreenshot =
      orderDetailsByRefRedux?.orderDelivery?.paymentScreenshot ||
      orderDetailsByRefRedux?.orderPickup?.paymentScreenshot

    return hasPaymentScreenshot ? 'Update Order' : 'Make Payment'
  }

  useEffect(() => {
    scrollToTop()

    if (orderRefParams && userToken) {
      const fetchDataOrder = async () => {
        setLoading(true)
        await dispatch(getOrderDetailsByRef(orderRefParams))
        setLoading(false)
      }

      fetchDataOrder()
    } else {
      if (cartOrderDetailsByRef?.ref) {
        const fetchData1 = async () => {
          setLoading(true)
          await dispatch(
            getOrderDetailsByRefNoLogin({
              ref: cartOrderDetailsByRef?.ref,
              uEmail: cartOrderDetailsByRef?.email,
            })
          )
          setLoading(false)
        }

        fetchData1()
      }
      if (!userToken && localStorage.getItem('emailNoLogin')) {
        const fetchData2 = async () => {
          setLoading(true)
          await dispatch(
            getOrderDetailsByRefNoLogin({
              ref: orderRefParams,
              uEmail: localStorage.getItem('emailNoLogin'),
            })
          )
          setLoading(false)
        }

        fetchData2()
      }
    }
  }, []) // orderRefParams, userToken, cartOrderDetailsByRef?.ref, localStorage.getItem('emailNoLogin')

  useEffect(() => {
    if (orderDetailsByRefRedux && !isEmpty(orderDetailsByRefRedux)) {
      setOrderDetailsByRef(orderDetailsByRefRedux)

      if (orderDetailsByRefRedux?.paymentScreenshot) {
        setPreviewImg(orderDetailsByRefRedux?.paymentScreenshot)
        paymentScreenshotURLRef.current = orderDetailsByRefRedux?.paymentScreenshot
        setIsUploaded(true)
      }
    }
  }, [JSON.stringify(orderDetailsByRefRedux)])

  useEffect(() => {
    if (cart) {
      const calcSubtotal = cart.reduce((acc, curr) => {
        const subItemsPrice = totalSubItemsPrice(curr.subItems)
        const productPrice = curr?.priceNoSubItems + subItemsPrice

        const productTotalPrice = productPrice * curr.quantity
        return acc + productTotalPrice
      }, 0)

      setSubTotal(calcSubtotal)
    }
  }, [cart?.length])

  useEffect(() => {
    scrollToTop()
    if (!orderRefParams && cartOrderDetailsByRef?.ref) {
      fetchData()
    }

    return () => {
      setPreviewImg('')
    }
  }, [orderRefParams, cartOrderDetailsByRef?.ref, userToken])

  useEffect(() => {
    const handleBackButton = (event) => {
      if (!userToken) {
        event.preventDefault()
        onClearCart()
      }
    }

    window.addEventListener('popstate', handleBackButton)

    return () => {
      window.removeEventListener('popstate', handleBackButton)
    }
  }, [userToken, history])

  if (loading) {
    return <PaymentCodeSkeleton />
  }

  return (
    <div className='payment-code container'>
      <div className='payment-code__contact-summary'>
        <div className='payment-code__contact-details'>
          <p className='payment-code__title'>Contact Details</p>
          {(previousUrl === undefined || previousUrl === 'checkout') &&
            userToken &&
            ![HOST_ORDER_VERIFIED, HOST_ORDER_FULFILLED, BUYER_ORDER_PAID].includes(
              orderDetailsByRef?.status
            ) && (
              <button onClick={handleBackPage} className='btn-back animated-underline'>
                <BiArrowBack />
                Back
              </button>
            )}
          {userToken &&
          previousUrl === 'my-purchase' &&
          ![
            HOST_ORDER_VERIFIED,
            HOST_ORDER_FULFILLED,
            BUYER_ORDER_PAID,
            BUYER_ORDER_CANCELED,
          ].includes(orderDetailsByRef?.status) ? (
            <button onClick={handleBackPage} className='payment-code__edit-button'>
              Edit Contact Details
              <BiEdit className='ms-1' />
            </button>
          ) : null}
        </div>
        <ContactDetails />
        <p className='payment-code__title mb-2'>Order Summary</p>
        <Bill
          cart={orderDetailsByRef?.listProduct}
          totalPrice={totalPrice}
          subTotal={subTotal}
          orderDetailsByRef={orderDetailsByRef}
          truncate={truncate}
        />
      </div>

      <div className='payment-code__qr-code--wrapper'>
        {!orderDetailsByRef?.cashOnDeliveryPickup ? (
          <div className='payment-code__qr-code'>
            <>
              {orderDetailsByRef?.paymentScreenshot ||
              (!isVerifiedOrder && Boolean(orderDetailsByRef?.paymentScreenshot)) ||
              (orderDetailsByRef?.status === BUYER_ORDER_UNPAID &&
                !orderDetailsByRef?.paymentScreenshot) ? (
                <Payment
                  previewImg={previewImg}
                  confirmPayment={confirmPayment}
                  final={totalPrice}
                  phone={shopDetail?.createdUserPhone || orderDetailsByRef?.shopCreatedUserPhone}
                  isSubmittedPayment={isSubmittedPayment}
                  onUserImageUploaded={onUserImageUploaded}
                  stringRefNumber={cartOrderDetailsByRef?.ref}
                  photoRef={photoRef}
                  orderDetailsByRef={orderDetailsByRef}
                  isUploaded={isUploaded}
                  isOrderRef={!!orderRefParams}
                />
              ) : (
                <PaymentCodeVerified
                  status={orderDetailsByRef?.status}
                  isCOD={orderDetailsByRef?.cashOnDeliveryPickup}
                />
              )}
            </>
          </div>
        ) : (
          <>
            {!orderRefParams ? (
              <CustomButton
                onClick={confirmPayment}
                isDisabled={isSubmittedPayment || isUploaded || !isEmpty(orderDetailsByRef)}
                text={getButtonText()}
                color='aqua'
                variant='contained'
                icon={<MdOutlinePayment />}
              />
            ) : (
              <PaymentCodeVerified
                status={orderDetailsByRef?.status}
                isCOD={orderDetailsByRef?.cashOnDeliveryPickup}
              />
            )}
          </>
        )}
      </div>

      <ModalNotifySuccess
        isModalOpen={isModalOpenSuccess}
        toggleModal={toggleModalSuccess}
        handleWhenShowModalPaymentSuccess={handleWhenShowModalPaymentSuccess}
      />
    </div>
  )
}

export default PaymentCode

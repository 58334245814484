/* eslint-disable no-confusing-arrow */
import ProtectedRoute from '_components/ProtectedRoute'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import ErrorBoundary from '../../components/ErrorBoundary'
import Payment from '../Payment'
import SignIn from '../SignIn/index'
import SignUp from '../SignUp/index'
import MyShops from '../User/MyShops'
import AddItems from './AddItems'
import Checkout from './Checkout'
import ConfirmShop from './ConfirmShop'
import Create from './Create'
import GeneratedDomain from './GeneratedDomain'
import MyPurchase from './MyPurchase'
import PaymentCode from './PaymentCode'
import PaymentHistory from './PaymentHistory'
import ShopPage from './ShopPage'

function VirtualShop() {
  const { path } = useRouteMatch()

  return (
    <ErrorBoundary>
      <Switch>
        <Route path={`${path}/create`} component={Create} />
        <Route path={`${path}/list`} component={ShopPage} />
        <ProtectedRoute path={`${path}/generated-domain`} component={GeneratedDomain} />
        <ProtectedRoute path={`${path}/my-shops`} component={MyShops} />
        <Route path={`${path}/add-items`} component={AddItems} />
        <Route path={`${path}/confirm-shop`} component={ConfirmShop} />
        <Route path={`${path}/sign-up`} component={SignUp} />
        <Route path={`${path}/sign-in`} component={SignIn} />
        <ProtectedRoute path={`${path}/subscription-payment`} component={Payment} />
        <Route path={`${path}/payment-code`} component={PaymentCode} />
        <Route path={`${path}/checkout`} component={Checkout} />
        <Route path={`${path}/my-purchase`} component={MyPurchase} />
        <Route path={`${path}/payment-history`} component={PaymentHistory} />
      </Switch>
    </ErrorBoundary>
  )
}

export default VirtualShop

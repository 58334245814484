import subscriptionApi from '_api/subscription'
import CustomButton from '_components/CustomButton'
import HitPayIcon from '_images/hitpay-icon.jpg'
import {
  clearSubscriptionPackage,
  setCurrentPackage,
  submitRequestPayment,
} from '_redux/modules/subscription'
import {
  HITPAY_PAYMENT_ONLY_ONCE,
  HITPAY_PAYMENT_RECURRING_BILLING,
  PAYMENT_HITPAY,
} from '_utils/constant'
import moment from 'moment/moment'
import { useEffect, useState } from 'react'
import { IoMdCheckmark } from 'react-icons/io'
import { TiCancel } from 'react-icons/ti'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useToasts } from 'react-toast-notifications'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SelectPaymentMethod } from './SelectPaymentMethod'

function ModalCreateSubscriptionPaymentRequest({
  isOpenModal,
  toggleModal,
  nextStep,
  setNextStep,
}) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const [titleModal, setTitleModal] = useState('')
  const { subscriptionPackageSelected, paymentMethod, isSubmittedPayment } = useSelector(
    (state) => state.subscription
  )

  const modalTitleStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }

  useEffect(() => {
    if (!isOpenModal && subscriptionPackageSelected?.id) {
      dispatch(clearSubscriptionPackage())
    }
  }, [isOpenModal, subscriptionPackageSelected?.id, dispatch])

  useEffect(() => {
    if (nextStep === 1) {
      setTitleModal(<span style={modalTitleStyle}>Select Payment Method</span>)
    }

    if (nextStep === 2) {
      setTitleModal(
        <span style={modalTitleStyle}>
          {paymentMethod === HITPAY_PAYMENT_ONLY_ONCE ? 'Payment Once' : 'Payment Recurring'}
        </span>
      )
    }
  }, [nextStep, paymentMethod])

  const onMakePayment = async () => {
    const postData = {
      subId: subscriptionPackageSelected?.id,
      variationId: subscriptionPackageSelected?.variationId,
      paymentType: PAYMENT_HITPAY,
      price: subscriptionPackageSelected?.discountPrice
        ? subscriptionPackageSelected?.discountPrice
        : subscriptionPackageSelected?.variantPrice,
      isRecurringBilling: paymentMethod === HITPAY_PAYMENT_RECURRING_BILLING,
      start_date: moment().format('YYYY-MM-DD'),
    }

    try {
      dispatch(submitRequestPayment(true))
      const { msgResp } = await subscriptionApi.createSubscriptionPaymentRequest(postData)

      if (msgResp.paymentLink) {
        setTimeout(() => {
          window.open(msgResp.paymentLink, '_blank')
        })
      }

      dispatch(setCurrentPackage(msgResp))
      addToast('Created subscription payment request successfully!', {
        appearance: 'success',
        autoDismiss: true,
      })
      history.push('/virtual-shop/payment-history')
    } catch (error) {
      addToast(error.message || error.msgResp, {
        appearance: 'error',
        autoDismiss: true,
      })
    } finally {
      dispatch(submitRequestPayment(false))
      toggleModal()
    }
  }

  return (
    <Modal
      isOpen={isOpenModal}
      toggle={toggleModal}
      className={'modal-subscription-payment-request f-16'}
    >
      <ModalHeader toggle={toggleModal}>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <img src={HitPayIcon} className={'img-fluid mb-2'} width={130} alt={'HitpayIcon'} />
          <span>{titleModal}</span>
        </div>
      </ModalHeader>
      <ModalBody>{nextStep === 1 && <SelectPaymentMethod />}</ModalBody>
      <ModalFooter>
        {nextStep === 1 && (
          <CustomButton
            type='button'
            variant='contained'
            color='aqua'
            icon={<IoMdCheckmark />}
            text={isSubmittedPayment ? 'Making payment...' : 'Make payment'}
            onClick={onMakePayment}
            disabled={isSubmittedPayment}
          />
        )}

        {nextStep === 2 && (
          <CustomButton
            type='button'
            variant='outlined'
            color='slate'
            icon={<TiCancel />}
            text='Back'
            className='w-100 text-center shadow-sm'
            onClick={() => setNextStep(1)}
          />
        )}
      </ModalFooter>
    </Modal>
  )
}

export default ModalCreateSubscriptionPaymentRequest

import { OrderRecordContext } from '_contexts/OrderRecordContext'
import { PLATFORM_CO_KII } from '_utils/constant'
import React, { useContext } from 'react'
import Fulfillment from './Fulfillment'
import FulfillmentDate from './FulfillmentDate'
import OrderDate from './OrderDate'
import OrderStatus from './OrderStatus'

function SelectFilter() {
  const { tabOrderPlatform } = useContext(OrderRecordContext)
  return (
    <div className='select-filter'>
      <div className='filter-row button-modal'>
        <OrderDate />
        <FulfillmentDate />
      </div>
      <div className='filter-row multi-select'>
        <OrderStatus />
        {tabOrderPlatform === PLATFORM_CO_KII && <Fulfillment />}
      </div>
    </div>
  )
}

export default SelectFilter

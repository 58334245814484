/* eslint-disable no-confusing-arrow */
import CustomButton from '_components/CustomButton'
import CustomModal from '_components/CustomModal'
import InputField from '_components/InputField'
import React, { useContext, useEffect, useState } from 'react'
import { IoSave } from 'react-icons/io5'
import { TiCancel } from 'react-icons/ti'
import { useSelector } from 'react-redux'
import { ProductItemContext } from '.'

function ProductDescription({ productDescription }) {
  const [isEditDescription, setIsEditDescription] = useState(false)

  const { isSavedProduct } = useSelector((state) => state.product)
  const { handleSubmit, errors, isSubmitting, register, handleSave } =
    useContext(ProductItemContext)

  useEffect(() => {
    if (isSavedProduct) setIsEditDescription(false)
  }, [isSavedProduct])

  const toggle = () => setIsEditDescription(!isEditDescription)

  const onSubmit = (data) => {
    handleSave(data)
    toggle()
  }

  return (
    <>
      {!isEditDescription && !productDescription && (
        <>
          <div className='text-content' onClick={toggle}>
            Add description
          </div>
          {errors.description && (
            <small className='text-danger'>{errors.description.message}</small>
          )}
        </>
      )}

      {productDescription && (
        <div className='text-content'>
          <div className='product-description__text' onClick={toggle}>
            {productDescription}
          </div>
        </div>
      )}

      <CustomModal isOpen={isEditDescription} toggle={toggle} title={'Edit Description'} hideFooter>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputField
            type='text'
            label='Product description'
            id='product-description'
            defaultValue={productDescription}
            as={'textarea'}
            register={register('description')}
            error={errors?.description?.message}
          />
          <div className='d-flex gap-2 w-100 justify-content-between mt-3'>
            <CustomButton
              text='Cancel'
              color='slate'
              type='reset'
              icon={<TiCancel />}
              className='m-0 w-50'
              onClick={toggle}
            />
            <CustomButton
              text={isSubmitting ? 'Saving...' : 'Save'}
              type='submit'
              isDisabled={isSubmitting}
              variant='contained'
              color='aqua'
              className='m-0 w-50'
              icon={<IoSave />}
            />
          </div>
        </form>
      </CustomModal>
    </>
  )
}

export default ProductDescription

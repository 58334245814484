/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react'
import { FaUserEdit } from 'react-icons/fa'
import { useToasts } from 'react-toast-notifications'

import { yupResolver } from '@hookform/resolvers/yup'
import reportApi from '_api/report'
import userApi from '_api/user'
import CustomButton from '_components/CustomButton'
import InputField from '_components/InputField'
import { getUserInfo, setUserInfo } from '_utils/localData'
import { isNull } from 'lodash'
import { useForm } from 'react-hook-form'
import { GrPowerReset } from 'react-icons/gr'
import { IoSaveOutline } from 'react-icons/io5'
import * as yup from 'yup'

const schema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  phone: yup.string().required('Phone is required'),
})

const InfoItem = ({
  data,
  name,
  placeholder,
  error,
  type = 'text',
  register,
  className,
  label,
}) => (
  <>
    <InputField
      label={label}
      name={name}
      type={type}
      placeholder={placeholder}
      register={register(name)}
      defaultValue={data[name]}
      className={className}
    />

    {error && <p className='text-danger'>{error.message}</p>}
  </>
)

const UpdateProfile = () => {
  const { addToast } = useToasts()
  const currentUser = getUserInfo()
  const [newCurrentUser, setNewCurrentUser] = useState({})
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  })

  const handleSave = async (data) => {
    const { firstName, lastName, phone, postalCode } = data
    const name = `${firstName} ${lastName}`
    const newProfile = { name, phone, postalCode }

    try {
      const res = await userApi.updateUser(newProfile)
      if (res.msgCode % 100 === 0) {
        addToast('Saved successfully', { appearance: 'success', autoDismiss: true })
        const { msgResp } = await userApi.getUser()
        setUserInfo(msgResp)
      }
    } catch (error) {
      reportApi.report({ message: `${error.msgCode} - ${error.msgResp}` })

      if (error.msgCode % 100 === 99) {
        addToast('Something went wrong', { appearance: 'error', autoDismiss: true })
      } else {
        addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
      }
    }
  }

  const { name } = currentUser || {}

  useEffect(() => {
    if (name) {
      const firstName = name.split(' ').slice(0, 1).join(' ')
      const lastName = name.split(' ').slice(1).join(' ')
      const nCurrentUser = {
        ...currentUser,
        firstName,
        lastName,
        postalCode: currentUser.postalCode || '',
      }

      setValue('firstName', firstName)
      setValue('lastName', lastName)
      setValue('phone', currentUser?.phone)
      setValue('postalCode', currentUser.postalCode)

      if (!isNull(nCurrentUser)) {
        setNewCurrentUser(nCurrentUser)
      }
    }
  }, [name])

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      {currentUser && (
        <>
          <div className='profile-header-minimal'>
            <span className='icon'>
              <FaUserEdit />
            </span>
            <span className='title mb-0'>Profile Update</span>
            <span className='divider'>•</span>
            <span className='email truncate'>{currentUser?.email}</span>
          </div>
          <InfoItem
            register={register}
            data={newCurrentUser}
            name='firstName'
            placeholder='First Name'
            label='First Name'
            error={errors.firstName}
            className={'input-first-name'}
          />
          <InfoItem
            register={register}
            data={newCurrentUser}
            name='lastName'
            placeholder='Last Name'
            label='Last Name'
            error={errors.lastName}
            className={'input-last-name'}
          />
          <InfoItem
            register={register}
            data={currentUser}
            name='phone'
            placeholder='Mobile Number'
            label='Mobile Number'
            error={errors.phone}
            type='text'
            className={'input-phone'}
          />
          <InfoItem
            register={register}
            data={currentUser}
            name='postalCode'
            placeholder='Postal Code'
            label='Postal Code'
            error={errors.postalCode}
            className={'input-postal-code'}
          />
          <div className='row row--bot align-self-end'>
            <div className='col-6'>
              <CustomButton icon={<GrPowerReset />} text='CANCEL' color='slate' className='w-100' />
            </div>
            <div className='col-6'>
              <CustomButton
                type='submit'
                text={isSubmitting ? 'SAVING...' : 'SAVE'}
                color='aqua'
                className='w-100'
                isDisabled={isSubmitting}
                isHovered={isSubmitting}
                icon={<IoSaveOutline />}
                variant='contained'
              />
            </div>
          </div>
        </>
      )}
    </form>
  )
}

export default UpdateProfile

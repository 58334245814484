import axiosClient from './axiosClient'

const authApi = {
  login: (data) => {
    const url = '/login'
    return axiosClient.post(url, data)
  },
  register: (data) => {
    const url = '/create-user'
    return axiosClient.post(url, data)
  },
  searchUser: (email) => {
    const url = `search-user?id=${email}`
    return axiosClient.get(url)
  },
  login3rdParty: (data) => {
    const url = 'login-3rd-party'
    return axiosClient.post(url, data)
  },
  resetPassword: (data) => {
    const url = '/co-hoot-reset-password'
    return axiosClient.patch(url, data)
  },
  changePassword: (data) => {
    const url = '/co-hoot-change-password'
    return axiosClient.patch(url, data)
  },
}

export default authApi

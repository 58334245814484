import { selectPaymentMethod } from '_redux/modules/package'
import { HITPAY_PAYMENT_ONLY_ONCE, HITPAY_PAYMENT_RECURRING_BILLING } from '_utils/constant'
import { BsCoin } from 'react-icons/bs'
import { MdOutlineLoop } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'

export function SelectPaymentMethod({ isOpenRecurring }) {
  const dispatch = useDispatch()

  const { selectedPaymentMethod } = useSelector((state) => state.package)

  return (
    <div className={'payment-method row'}>
      <div className={isOpenRecurring ? 'col-6' : ' col-12'}>
        <div className={'d-flex flex-column align-items-center row-gap-2 h-100'}>
          <label
            className={`form-check-label cursor-pointer rounded p-3 border w-100 h-100 text-center ${
              selectedPaymentMethod === HITPAY_PAYMENT_ONLY_ONCE && 'border-primary'
            }`}
            htmlFor='hitpay-only-once'
          >
            <BsCoin size={40} color='#27ae60' />
            <div className='h-100 w-auto mt-2'>Payment Once</div>
          </label>
          <input
            className='form-check-input m-0 d-none'
            style={{
              position: 'inherit',
            }}
            type='radio'
            name='hitpay-only-once'
            id='hitpay-only-once'
            value={HITPAY_PAYMENT_ONLY_ONCE}
            checked={selectedPaymentMethod === HITPAY_PAYMENT_ONLY_ONCE}
            onChange={() => dispatch(selectPaymentMethod(HITPAY_PAYMENT_ONLY_ONCE))}
          />
        </div>
      </div>
      {isOpenRecurring && (
        <div className='col-6'>
          <div className={'d-flex flex-column align-items-center row-gap-2 h-100'}>
            <label
              className={`form-check-label cursor-pointer rounded p-3 border w-100 h-100 text-center ${
                selectedPaymentMethod === HITPAY_PAYMENT_RECURRING_BILLING && 'border-primary'
              }`}
              htmlFor='hitpay-recurring-billing'
            >
              <MdOutlineLoop size={40} color='#c0392b' />
              <div className='h-100 w-auto mt-2'>Payment Recurring</div>
            </label>
            <input
              className='form-check-input m-0 d-none'
              style={{
                position: 'inherit',
              }}
              type='radio'
              name='hitpay-recurring-billing'
              id='hitpay-recurring-billing'
              value={HITPAY_PAYMENT_RECURRING_BILLING}
              checked={selectedPaymentMethod === HITPAY_PAYMENT_RECURRING_BILLING}
              onChange={() => dispatch(selectPaymentMethod(HITPAY_PAYMENT_RECURRING_BILLING))}
            />
          </div>
        </div>
      )}
    </div>
  )
}

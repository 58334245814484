import { withTour } from '@reactour/tour'
import { removeCreateShopData } from '_utils/localData'
import { GiPlainArrow } from 'react-icons/gi'

function ContentGuide(props) {
  // const dataCreateShop = getCreateShopData()

  const handleSubmit = () => {
    props.setIsOpen(false)
    removeCreateShopData()
  }

  return (
    <div
      className='border border-1 border-success rounded text-center'
      style={{
        margin: '-10px -16px',
        padding: '10px',
      }}
    >
      <strong className='text-decoration-underline'>Useful Tips</strong>
      <p>Click follow arrow to manage your shop</p>
      <div
        className='d-flex justify-content-between align-content-center mt-2'
        style={{
          transform: 'translateY(28px)',
        }}
      >
        <GiPlainArrow
          style={{
            color: '#28a745',
            fontSize: '50px',
            position: 'absolute',
            top: '-28px',
            left: window.innerWidth < 768 ? 'calc(50% - 25px)' : null,
          }}
        />
        <button
          className='btn btn-success text-uppercase rounded ms-auto'
          onClick={handleSubmit}
          style={{
            transform: 'translateX(-10px)',
            width: '100px',
          }}
        >
          Ok
        </button>
      </div>
    </div>
  )
}
export default withTour(ContentGuide)

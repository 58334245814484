/* eslint-disable func-names */
/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
import { yupResolver } from '@hookform/resolvers/yup'
import CustomButton from '_components/CustomButton'
import InputField from '_components/InputField'
import { copyProduct, toggleSubItem, updateProduct } from '_redux/modules/product'
import { getDataShopDetail } from '_utils/localData'
import React, { createContext, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { IoSave } from 'react-icons/io5'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

import productSchema from '../../../validate/schemaProductItem'
import ModalSetting from '../ModalSetting'
import ModalShare from '../ModalShare'
import ProductDescription from './Description'
import ProductImage from './Image'
import ProductInventory from './Inventory'
import ProductName from './Name'
import ProductPrice from './Price'
import Purchase from './Purchase'
import ProductSetting from './Setting'
import SubItem from './SubItem'

export const ProductItemContext = createContext()

function ProductItem({ product, colSpan, pIndex, maxLengthProduct }) {
  const { addToast } = useToasts()
  const shop = getDataShopDetail()
  const dispatch = useDispatch()

  const [isOpenModal, setIsOpenModal] = useState(false)

  const [isOpenModalShare, setIsOpenModalShare] = useState(false)

  const {
    handleSubmit,
    register,
    setValue,
    control,
    watch,
    getValues,
    // reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      ...product,
      isSubmitting: false,
    },
    resolver: yupResolver(productSchema),
  })

  const { fields, append, insert, remove } = useFieldArray({
    name: 'subItems',
    control,
  })

  const watchProduct = watch()
  const watchSubItems = watch('subItems')
  const controlledSubItems = fields.map((field, index) => ({
    ...field,
    ...watchSubItems[index],
  }))

  // useEffect(() => {
  //   const formValues = {
  //     ...product,
  //     // isOpenSubItems: Boolean(product?.isOpenSubItems), // Ensure boolean
  //     subItems: product?.subItems || [],
  //   }

  //   reset(formValues)

  //   Object.keys(formValues).forEach((key) => {
  //     setValue(key, formValues[key])
  //   })
  // }, [product, setValue, reset])

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal)
  }

  const toggleOpenModalShare = () => setIsOpenModalShare(!isOpenModalShare)

  const handleSave = async (data) => {
    try {
      setValue('isSubmitting', true) // Set submitting state to true

      const postData = {
        ...data,
        active: data?.active ? 1 : 0,
        photoUrls: data?.photoUrls,
        minOrderQty: Number(data.minOrderQty),
        limitPerOrder: Number(data.limitPerOrder),
        price: data.price,
        availableOfStock: data.availableOfStock,
        subItems: data.subItems.map((subItem) => {
          const { id, ...restSubItem } = subItem

          const updatedList = restSubItem.list.map((option) => {
            const { id: _id, ...restOption } = option
            return { ...restOption, price: Number(restOption.price) }
          })

          return {
            ...restSubItem,
            list: updatedList,
          }
        }),
      }

      await dispatch(updateProduct(postData, shop))
      addToast('Updated product successfully', { appearance: 'success', autoDismiss: true })
    } catch (error) {
      addToast(error.message, { appearance: 'error', autoDismiss: true })
    } finally {
      setValue('isSubmitting', false) // Reset submitting state
      if (isOpenModal) {
        setIsOpenModal(false)
      }
    }
  }

  const handleCopyProduct = async () => {
    try {
      await dispatch(copyProduct(shop, product.id))
    } catch (error) {
      addToast(error.message, { appearance: 'error', autoDismiss: true })
    }
  }

  const shareValues = {
    handleSubmit,
    register,
    isSubmitting,
    product: watchProduct,
    handleSave,
    setValue,
    errors,
    control,
  }

  return (
    <ProductItemContext.Provider value={shareValues}>
      <tr className={`product-controls ${product.isCopied ? 'flash' : ''}`}>
        <td className='col-image'>
          <ProductImage product={product} pIndex={pIndex} maxLengthProduct={maxLengthProduct} />
        </td>
        <td className='col-name'>
          <ProductName watchName={watchProduct?.name} />
        </td>
        <td className='col-price'>
          <ProductPrice productPrice={watchProduct?.price} />
        </td>
        <td className='col-inventory'>
          <ProductInventory availableOfStock={watchProduct?.availableOfStock} product={product} />
        </td>
        <td className='col-purchase'>
          <Purchase
            purchaseCount={watchProduct?.purchaseCount}
            pid={product?.id}
            {...{ register }}
          />
        </td>
        <td className='col-description'>
          <ProductDescription productDescription={watchProduct?.description} {...{ register }} />
        </td>
        <td className='col-active'>
          <InputField
            type='checkbox'
            isSwitch
            checked={watchProduct.active}
            register={register('active')}
            onChange={handleSubmit(handleSave)} // Add onChange handler
          />
        </td>
        <td className='col-setting'>
          <ProductSetting
            toggleModal={toggleModal}
            toggleOpenModalShare={toggleOpenModalShare}
            subItems={controlledSubItems}
            product={product}
            {...{ append }}
            handleCopyProduct={handleCopyProduct} // Pass the handleCopyProduct function
          />
        </td>
      </tr>
      {product.subItems && controlledSubItems.length > 0 && (
        <tr className={'product-controls'}>
          <td colSpan={colSpan} className='product-controls__cell'>
            <div className='product-controls__wrapper'>
              {product?.isOpenSubItems && (
                <CustomButton
                  onClick={handleSubmit(handleSave)}
                  isDisabled={isSubmitting}
                  text={isSubmitting ? 'Saving...' : 'Save'}
                  className='product-controls__save-btn'
                  variant='contained'
                  color='aqua'
                  icon={<IoSave />}
                />
              )}
              <div
                className={`product-controls__toggle-section ${
                  product?.isOpenSubItems ? '' : 'product-controls__toggle-section--right'
                }`}
              >
                <small className='product-controls__toggle-label'>
                  {product?.isOpenSubItems ? 'Hide sub item' : 'Show sub item'}
                </small>
                <button
                  className='product-controls__toggle-btn'
                  onClick={() => dispatch(toggleSubItem(product.id, product.index))}
                >
                  {product?.isOpenSubItems ? (
                    <MdKeyboardArrowUp size={30} />
                  ) : (
                    <MdKeyboardArrowDown size={30} />
                  )}
                </button>
              </div>
            </div>
          </td>
        </tr>
      )}
      {product?.isOpenSubItems && controlledSubItems?.length > 0 && (
        <tr className={'product-subitems-row'}>
          <td className='product-subitems-cell' colSpan={colSpan}>
            <SubItem
              subItems={controlledSubItems}
              product={product}
              {...{ register, append, insert, remove, getValues, setValue, watch }}
            />
          </td>
        </tr>
      )}
      <ModalSetting isModal={isOpenModal} toggleModal={toggleModal} product={watchProduct} />

      <ModalShare isOpenModalShare={isOpenModalShare} toggleOpenModalShare={toggleOpenModalShare} />
    </ProductItemContext.Provider>
  )
}

export default ProductItem

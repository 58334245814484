import axiosClient from './axiosClient'

const contactApi = {
  sendContact: (data) => {
    const url = '/co-hoot-contact-email'
    return axiosClient.post(url, data)
  },
}

export default contactApi

import uploadApi from '_api/upload'
import InputField from '_components/InputField'
import noImage from '_images/noimage02.svg'
import { DEFAULT_SUB_ITEM, PACKAGE_SUBSCRIPTION_BASIC, SHIFT_DOWN, SHIFT_UP } from '_utils/constant'
import { formatNumberToCurrencyHasDot } from '_utils/functions/formatter'
import { getUserInfo } from '_utils/localData'
import { isNull, isUndefined } from 'lodash'
import { useEffect, useState } from 'react'
import { useFieldArray } from 'react-hook-form'
import { IoClose } from 'react-icons/io5'
import { MdOutlineAttachMoney } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import ProductSettingModal from './ProductSettingModal'
import SubItem from './SubItem'

function ProductItem({
  productIndex,
  product,
  totalListProduct,
  control,
  register,
  removeProduct,
  insertProduct,
  swapProduct,
  watch,
  setValue,
  errors,
  setIsMaxProductsReached,
}) {
  const {
    fields,
    remove: removeSubItem,
    insert: insertSubItem,
    append: appendSubItem,
  } = useFieldArray({
    control,
    name: `products.${productIndex}.subItems`,
  })
  const userToken = getUserInfo()
  const { addToast } = useToasts()
  const watchFieldListProducts = watch('products')
  const watchFieldArray = watch(`products.${productIndex}.subItems`)
  const controlledFields = fields.map((field, _index) => ({
    ...field,
    ...watchFieldArray[_index],
  }))

  const [isOpen, setIsOpen] = useState(false)
  const [imgFileUrl, setImgFileUrl] = useState('')

  const { currentPackage } = useSelector((state) => state.subscription)

  const watchPrice = watch(`products.${productIndex}.price`)
  useEffect(() => {
    if (watchPrice !== undefined && typeof watchPrice === 'string' && watchPrice.length) {
      const inputText = watchPrice
      const formattedInput = formatNumberToCurrencyHasDot(inputText)

      setValue(`products.${productIndex}.price`, formattedInput)
    }
  }, [watchPrice, setValue, productIndex])

  useEffect(() => {
    setImgFileUrl(product.photoUrl)
  }, [product.photoUrl])

  const toggle = () => setIsOpen(!isOpen)

  const handleChangeSequence = (index, direction) => {
    if (index - 1 >= 0 && direction === SHIFT_UP) {
      swapProduct(index, index - 1)
    }
    if (index + 1 < totalListProduct && direction === SHIFT_DOWN) {
      swapProduct(index, index + 1)
    }
  }

  const onUserImageUploaded = async (event) => {
    const { files } = event.target
    if (files && files.length > 0) {
      const img = files[0]

      if (img) {
        const { msgResp } = await uploadApi.uploadPhoto(files)
        setImgFileUrl(URL.createObjectURL(img))
        setValue(`products.${productIndex}.photoUrl`, msgResp.url)
      } else {
        setImgFileUrl('')
        setValue(`products.${productIndex}.photoUrl`, [])
      }
    }
  }

  const removeImage = () => {
    setImgFileUrl('')
    setValue(`products.${productIndex}.photoUrl`, '')
  }

  const onCopy = () => {
    const currentPackageIsBasic =
      currentPackage?.subscriptionPackageType === PACKAGE_SUBSCRIPTION_BASIC

    if (!userToken || isUndefined(currentPackage) || isNull(currentPackage)) {
      if (watchFieldListProducts.length >= 5) {
        setIsMaxProductsReached(true)
        addToast('Maximum 5 items', {
          appearance: 'error',
          autoDismiss: true,
        })
        return
      }
    } else if (currentPackageIsBasic) {
      if (watchFieldListProducts.length >= 5) {
        setIsMaxProductsReached(true)
        addToast('Maximum 5 items', {
          appearance: 'error',
          autoDismiss: true,
        })
        return
      }
    }

    insertProduct(
      parseInt(productIndex + 1, 10),
      JSON.parse(
        JSON.stringify({
          ...product,
          photoUrl: imgFileUrl,
        })
      )
    )
  }

  return (
    <>
      <div className='rounded shadow-sm detail-list-news__item detail-list-news__item--background p-3 d-flex mb-3 product-item position-relative'>
        <div
          className='f-14 position-absolute top-0 start-0 px-1'
          style={{ background: '#f06a0f', color: 'white' }}
        >
          {productIndex + 1}
        </div>
        <div className='upload-img w-25 position-relative'>
          <div className='c-form upload-image-square border-0'>
            {imgFileUrl && (
              <button type='button' onClick={removeImage} className='btn-remove-image'>
                <IoClose size={16} />
              </button>
            )}
            <div className='upload-image flex-column bg-white'>
              <img
                src={imgFileUrl || noImage}
                alt='img'
                className={imgFileUrl?.length > 0 ? 'image-uploaded' : ''}
                style={{ zIndex: 1 }}
              />
              <div className='upload-image-txt'>
                <span>Upload Image</span>
              </div>
              <div className='u-file-upload__item'>
                <div className='u-file-upload__wrap'>
                  <a className='c-upload preview-images-zone'>
                    <input
                      defaultValue={''}
                      type='file'
                      accept='image/*'
                      className='inputFile'
                      onChange={onUserImageUploaded}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* <p className='mb-0 text-end mt-3 mt-lg-0'>Price ($):</p> */}
        </div>
        <div className='form-input w-100 ps-3'>
          <div className='product-name'>
            <InputField
              register={register(`products.${productIndex}.name`)}
              placeholder='Item Title'
              error={errors?.products?.[productIndex]?.name?.message}
              type='text'
            />
          </div>
          <div className='product-description'>
            <InputField
              type='text'
              placeholder='Description'
              register={register(`products.${productIndex}.description`)}
              error={errors?.products?.[productIndex]?.description?.message}
            />
          </div>
          <div className='d-flex align-items-center'>
            <div className='product-price'>
              <InputField
                type='text'
                register={register(`products.${productIndex}.price`)}
                error={errors?.products?.[productIndex]?.price?.message}
                icon={<MdOutlineAttachMoney />}
                placeholder='Price'
              />
            </div>
            <div className='btn-action d-flex align-items-center flex-grow-1 ms-3'>
              <div className='btn-add' style={{ marginRight: '0.75rem' }} onClick={toggle}></div>
              <div className='btn-copy me-0' onClick={onCopy}></div>
              <div className='btn-remove' onClick={() => removeProduct(productIndex)}></div>
              <div className='me-0'>
                <div
                  className='arrow-drap arrow-drap-up me-0'
                  onClick={() => handleChangeSequence(productIndex, SHIFT_UP)}
                />
                <div
                  className='arrow-drap arrow-drap-down me-0 mt-1'
                  onClick={() => handleChangeSequence(productIndex, SHIFT_DOWN)}
                />
              </div>
            </div>
          </div>
          <small
            className='txt-add-sub-item cursor-pointer'
            onClick={() => appendSubItem(JSON.parse(JSON.stringify({ ...DEFAULT_SUB_ITEM })))}
          >
            Add sub items
          </small>
        </div>

        <ProductSettingModal
          isOpen={isOpen}
          toggle={toggle}
          register={register}
          productIndex={productIndex}
          product={product}
          setValue={setValue}
          errors={errors}
        />
      </div>
      {controlledFields?.map((item, i) => (
        <SubItem
          key={i}
          productIndex={productIndex}
          nestIndex={i}
          subItem={item}
          {...{
            control,
            register,
            removeSubItem,
            insertSubItem,
            appendSubItem,
            watch,
            errors,
            setValue,
          }}
        />
      ))}
    </>
  )
}

export default ProductItem

import ModalGeneralPayment from '_components/ModalGeneralPayment/index'
import { fetchListPackageByType } from '_redux/modules/package'
import { PACKAGE_LAZADA } from '_utils/constant'
import { exportToCsv, flexibleSearch, normalizeName } from '_utils/function'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { PiExportBold } from 'react-icons/pi'
import { useDispatch, useSelector } from 'react-redux'

import { getShopDetailByHost } from '_redux/modules/shop'

import CustomButton from '_components/CustomButton'
import { fetchListProduct } from '../../redux/modules/product'
import RenderListProduct from './components/Common/RenderListProduct'
import SearchProduct from './components/Common/SearchProduct'
import './style.scss'

function ManageProducts() {
  const dispatch = useDispatch()

  const [nextStep, setNextStep] = useState(1)
  const [searchString, setSearchString] = useState('')
  const [isOpenModal, setIsOpenModal] = useState(false)

  // State For Lazada
  const [isPushingLaz, setIsPushingLaz] = useState(false)
  const [isRemovingLaz, setIsRemovingLaz] = useState(false)
  const [isUpdatingVariationLaz, setIsUpdatingVariationLaz] = useState(false)
  // const [loading, setLoading] = useState(true)
  const [loadingShop, setLoadingShop] = useState(true)

  const { selectedShop } = useSelector((state) => state.shop)
  const { listProduct } = useSelector((state) => state.product) // listProductLazada
  // const { listPackageLazada } = useSelector((state) => state.package)
  // const { listInvoiceLazada } = useSelector((state) => state.lazada)

  // const isHasLazada =
  //   listInvoiceLazada?.find((item) => item.shopId === selectedShop?.id)?.status ===
  //     SUBSCRIPTION_STATUS_APPROVED || false

  // const selectedTabProduct = localStorage.getItem('selectedTabProduct')

  const toggleModal = () => setIsOpenModal(!isOpenModal)

  const handleSearchProduct = (value) => {
    setSearchString(value)
  }

  const handleExport = (shopDetail, _listProduct) => {
    const generalInfo = [
      ['Shop Name', shopDetail?.shopName],
      ['Shop Link', shopDetail?.shopLink],
      ['Headline', shopDetail?.headline],
      ['Shop Owner', shopDetail?.createdUserName],
      ['Contact', shopDetail?.createdUserPhone],
      [''],
      [''],
    ]
    const rowsOfProduct = new Array(_listProduct.length) || []
    for (let index = 0; index < _listProduct.length; index++) {
      const product = _listProduct[index]
      const { name, price, availableOfStock, subItems } = product

      const subItemsString = subItems
        .map((subItem) => subItem.list.map((item) => `${item.name} ($${item.price})`).join(', '))
        .join(', ')

      rowsOfProduct[index] = [index + 1, name, `$${price}`, availableOfStock, subItemsString]
    }
    const rows = [
      ...generalInfo,
      ['Product No', 'Product name', 'Price', 'Available Stock', 'Sub Items & Price'],
      ...rowsOfProduct,
    ]
    const fileName = normalizeName(
      `${shopDetail?.shopName} shop export Product by ${moment().format('DD-MM-YYYY')}`
    )
    exportToCsv(`${fileName}.csv`, rows)
  }

  // const onUpgradeLazada = () => {
  //   if (listPackageLazada.length) {
  //     dispatch(selectPackage(listPackageLazada[0]))
  //   }

  //   toggleModal()
  // }

  const listProductFilter = useMemo(() => {
    if (searchString) {
      const newList = listProduct?.filter((product) => flexibleSearch(product.name, searchString))
      return newList
    }
    return listProduct
  }, [searchString, JSON.stringify(listProduct)])

  // const isLoadingLaz = isPushingLaz || isRemovingLaz || isUpdatingVariationLaz

  const lazadaProps = {
    isPushingLaz,
    setIsPushingLaz,
    isRemovingLaz,
    setIsRemovingLaz,
    isUpdatingVariationLaz,
    setIsUpdatingVariationLaz,
  }

  // to handle upgrade Lazada features
  useEffect(() => {
    let isMounted = true
    dispatch(fetchListPackageByType(PACKAGE_LAZADA))

    if (selectedShop?.id && selectedShop?.id !== '') {
      const fetchData = async () => {
        setLoadingShop(true)
        await dispatch(getShopDetailByHost(selectedShop.id))
        if (isMounted) {
          setLoadingShop(false)
        }
      }
      fetchData()
    }

    return () => {
      isMounted = false
    }
  }, [])

  // useEffect(() => {
  //   if (selectedTabProduct) {
  //     if (Number(selectedTabProduct) === PLATFORM_CO_KII) {
  //       dispatch(selectTab(PLATFORM_CO_KII))
  //     }

  //     if (Number(selectedTabProduct) === PLATFORM_LAZADA && isHasLazada) {
  //       dispatch(selectTab(PLATFORM_LAZADA))
  //     }
  //   } else {
  //     dispatch(selectTab(PLATFORM_CO_KII))
  //   }

  //   setSearchString('')
  // }, [selectedTabProduct, isHasLazada])

  // useEffect(() => {
  //   if (
  //     selectedShop?.id &&
  //     selectedShop?.id !== '' &&
  //     Number(selectedTabProduct) === PLATFORM_LAZADA
  //   ) {
  //     const fetchData = async () => {
  //       await dispatch(fetchListProductLazada(selectedShop.id))
  //       setLoading(false)
  //     }
  //     if (isHasLazada) {
  //       fetchData()
  //     } else {
  //       localStorage.removeItem('selectedTabProduct')
  //       dispatch(selectTab(PLATFORM_CO_KII))
  //       setLoading(false)
  //     }
  //   }
  // }, [selectedShop?.id, isHasLazada, selectedTabProduct])

  // useEffect(() => {
  //   if (listProductLazada?.length) {
  //     const hasVariations = listProductLazada.some((item) => item.variations.length)

  //     if (hasVariations) {
  //       dispatch(fetchListVariationProductLazada())
  //     }

  //     localStorage.setItem('listProductLazada', JSON.stringify(listProductLazada))
  //   } else {
  //     localStorage.removeItem('listProductLazada')
  //   }
  // }, [listProductLazada?.length, isHasLazada, selectedTabProduct])

  useEffect(() => {
    dispatch(fetchListProduct())
  }, [selectedShop?.id])

  return (
    <div className='manage-products'>
      <>
        <h3 className='title mb-3'>Products</h3>
        <div className='d-flex align-items-center'>
          <SearchProduct
            searchString={searchString}
            onSearch={handleSearchProduct}
            placeholder='Search name of product'
          />
          <CustomButton
            text='Export'
            icon={<PiExportBold />}
            onClick={() => handleExport(selectedShop, listProduct)}
            className='mt-0 w-auto ms-auto'
          />
        </div>
      </>

      <RenderListProduct
        listProductFilter={listProductFilter}
        searchString={searchString}
        loading={loadingShop}
        loadingShop={loadingShop}
        lazadaProps={lazadaProps}
      />

      {isOpenModal && (
        <ModalGeneralPayment
          isOpenModal={isOpenModal}
          toggleModal={toggleModal}
          nextStep={nextStep}
          setNextStep={setNextStep}
        />
      )}
    </div>
  )
}

export default ManageProducts

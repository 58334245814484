/* eslint-disable no-undef */
/* eslint-disable react/no-deprecated */
import CustomButton from '_components/CustomButton'
import InputField from '_components/InputField'
import { removeCreateShopData } from '_utils/localData'
import { isEmpty } from 'lodash'
import { CiCircleCheck } from 'react-icons/ci'
import { FaCheckCircle, FaFacebook, FaLink } from 'react-icons/fa'
import { IoIosClose, IoLogoWhatsapp } from 'react-icons/io'
import { Modal, ModalBody } from 'reactstrap'

const CustomFacebookShare = ({ url, quote }) => {
  const handleShare = () => {
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}&quote=${encodeURIComponent(quote)}`
    window.open(shareUrl, '_blank', 'width=550,height=400')
  }

  return (
    <CustomButton
      icon={<FaFacebook size={20} />}
      onClick={handleShare}
      color='facebook'
      className='mt-0 text-white'
      variant='contained'
      style={{ width: 70 }}
    />
  )
}

const CustomWhatsappShare = ({ url, title }) => {
  const handleShare = () => {
    const shareText = encodeURIComponent(title)
    const shareUrl = encodeURIComponent(url)
    const whatsappUrl = `https://api.whatsapp.com/send?text=${shareText}%20${shareUrl}`
    window.open(whatsappUrl, '_blank', 'width=550,height=400')
  }

  return (
    <CustomButton
      icon={<IoLogoWhatsapp size={20} />}
      onClick={handleShare}
      color='whats-app'
      className='mt-0'
      variant='contained'
      style={{ width: 70 }}
    />
  )
}

export function ModalCongratulationCreateShop({
  isOpenModal,
  toggle,
  shopDetail,
  urlPath,
  dataCreateShop,
}) {
  const handleCopyLink = () => {
    navigator.clipboard.writeText(shopDetail?.shopLink)
    alert('Link copied to clipboard!')
  }

  const handleCloseModal = () => {
    if (!isEmpty(dataCreateShop)) {
      removeCreateShopData()
    }
    localStorage.setItem('isAdminConsole', true)
    toggle()
  }

  const shareUrl = shopDetail?.shopLink || urlPath

  return (
    <Modal
      isOpen={isOpenModal}
      toggle={toggle}
      className='modal-congratulation-create-shop'
      backdrop='static'
    >
      <CustomButton
        className='ms-auto w-auto'
        icon={<IoIosClose size={32} />}
        onClick={handleCloseModal}
        variant='text'
      />
      <ModalBody>
        <div className='generated-domain-box h-auto'>
          <div className='generated-domain-check-icon'>
            <FaCheckCircle />
          </div>
          <div className='generated-domain-name pb-0'>
            <span className='txt-congratulation'>
              Congratulation! Your virtual shop is launched.
            </span>
            <p>URL link:</p>
            <a href={shopDetail?.shopLink} className={'txt-link f-16'}>
              {shopDetail?.shopLink}
            </a>
          </div>
          <ul className='list-group generated-domain-share'>
            <li className='list-group-item d-flex align-items-center justify-content-between'>
              <div className='item-content'>
                <div className='icon-wrapper facebook'>
                  <FaFacebook size={20} />
                </div>
                <span className='text'>Invite Facebook friends</span>
              </div>
              <CustomFacebookShare url={shareUrl} quote='Check out my new virtual shop!' />
            </li>

            <li className='list-group-item d-flex align-items-center justify-content-between'>
              <div className='item-content'>
                <div className='icon-wrapper whatsapp'>
                  <IoLogoWhatsapp size={20} />
                </div>
                <span className='text'>Share on WhatsApp</span>
              </div>
              <CustomWhatsappShare url={shareUrl} title='The new shop is coming' />
            </li>

            <li className='list-group-item d-flex align-items-end justify-content-between'>
              <div className='item-content'>
                <div className='icon-wrapper link'>
                  <FaLink size={18} />
                </div>
              </div>
              <div className='input-wrapper'>
                <span className='text'>Send a link via email or text</span>
                <InputField type='text' value={shopDetail?.shopLink || urlPath} readOnly />
              </div>
              <CustomButton
                text='Copy'
                onClick={handleCopyLink}
                color='slate'
                className='mt-0 w-auto'
              />
            </li>
          </ul>

          <CustomButton
            text="Let's Roll"
            onClick={toggle}
            color='whats-app'
            className='mt-3 w-100'
            variant='contained'
            icon={<CiCircleCheck />}
          />
        </div>
      </ModalBody>
    </Modal>
  )
}

import axiosClient from './axiosClient'

const lazadaApi = {
  upgradeLazadaFeature: (shopId, data) => {
    const url = '/create-lazada-feature'
    return axiosClient.post(url, data, {
      params: { shopId },
    })
  },
  removeProduct: (shopId, productId) => {
    const url = '/lazada-remove-product'
    return axiosClient.post(
      url,
      {},
      {
        params: { productId, shopId },
      }
    )
  },
  removeVariationProduct: (shopId, listId, currentListVariation) => {
    const url = '/remove-lazada-variation-product'
    return axiosClient.post(url, {
      shopId,
      variationProductIds: listId,
      listVariation: currentListVariation,
    })
  },
  cancelLazadaFeature: (lazadaPackageId) => {
    const url = '/cancel-lazada-feature'
    return axiosClient.patch(
      url,
      {},
      {
        params: { id: lazadaPackageId },
      }
    )
  },
  updateSingleProduct: (data) => {
    const url = '/lazada-update-single-product'
    return axiosClient.patch(url, data)
  },
  updateStatusOrder: (data) => {
    const url = '/lazada-update-status-order'
    return axiosClient.patch(url, data)
  },
  fetchListProduct: (shopId) => {
    const url = '/get-list-lazada-product'
    return axiosClient.get(url, {
      params: {
        shopId,
      },
    })
  },
  fetchListVariationProduct: (productId) => {
    const url = '/get-list-lazada-variation-product'
    return axiosClient.get(url, {
      params: {
        productId,
      },
    })
  },
  modifyLazadaVariationProduct: (data) => {
    const url = '/lazada-modify-variation-product'
    return axiosClient.post(url, data)
  },
  createProductLazada: (data) => {
    const url = '/lazada-create-product'
    return axiosClient.post(url, data)
  },
  getLazadaCategorySuggestions: (data) => {
    const { shopId, productName } = data
    const url = '/get-list-lazada-product-category'
    return axiosClient.get(url, { params: { shopId, productName } })
  },
  updatePriceQuantity: (data) => {
    const url = '/lazada-update-product-price-quantity'
    return axiosClient.patch(url, data)
  },
  getBrandByPages: (data) => {
    const { startRow, pageSize, shopId } = data
    const url = '/lazada-get-brand-by-pages'
    return axiosClient.get(url, {
      params: {
        startRow,
        pageSize,
        shopId,
      },
    })
  },
  getReverseOrdersForSeller: (data) => {
    const url = '/lazada-get-reverse-orders-for-seller'
    return axiosClient.post(url, data)
  },
  getReverseOrdersDetail: (data) => {
    const { shopId, orderReverse } = data
    const url = '/lazada-get-reverse-order-detail'
    return axiosClient.get(url, {
      params: {
        shopId,
        orderReverse,
      },
    })
  },
  getReverseOrdersReasonList: (data) => {
    const { shopId, orderReverseLineId } = data
    const url = '/lazada-get-reverse-order-reason-list'
    return axiosClient.get(url, {
      params: {
        shopId,
        orderReverseLineId,
      },
    })
  },
  getListReverseOrdersHistoryList: (data) => {
    const { shopId, orderReverseLineId, pageNumber, pageSize } = data
    const url = '/lazada-get-list-reveres-order-history-list'
    return axiosClient.get(url, {
      params: {
        shopId,
        orderReverseLineId,
        pageNumber,
        pageSize,
      },
    })
  },
  updateStatusPacked: (data) => {
    const url = '/lazada-fulfillment-pack'
    return axiosClient.patch(url, data)
  },
  printAWB: (data) => {
    const url = '/lazada-fulfillment-printAWB'
    return axiosClient.post(url, data)
  },
  getReasonCancel: (data) => {
    const url = '/lazada-order-cancel-validate'
    return axiosClient.post(url, data)
  },
  updateStatusOrderCancel: (data) => {
    const url = '/lazada-order-cancel'
    return axiosClient.post(url, data)
  },
  updateStatusReadyToShip: (data) => {
    const url = '/lazada-fulfillment-ready-to-ship'
    return axiosClient.post(url, data)
  },
  repackagedOrder: (data) => {
    const url = '/lazada-fulfillment-recreatepackage'
    return axiosClient.post(url, data)
  },
  updateStatusVariationProducts: (data) => {
    // values of data
    // const { productId, listVariationProduct, shopId } = data
    const url = '/lazada-update-status-variation-products'
    return axiosClient.patch(url, data)
  },
  getPayoutStatus: (data) => {
    const { shopId, createdAtAfter } = data
    const url = '/lazada-get-payout-status'
    return axiosClient.get(url, {
      params: {
        shopId,
        createdAtAfter,
      },
    })
  },
  queryAccountTransactions: (data) => {
    const {
      shopId,
      startTime,
      endTime,
      pageSize,
      pageNumber,
      subTransactionType,
      transactionType,
      transactionNumber,
    } = data
    const url = '/lazada-query-account-transactions'
    return axiosClient.get(url, {
      params: {
        shopId,
        startTime,
        endTime,
        pageSize,
        pageNumber,
        subTransactionType,
        transactionType,
        transactionNumber,
      },
    })
  },
  confirmDeliveryForDBS: (data) => {
    // values of data
    // const { listPackageId, shopId } = data
    const url = '/lazada-confirm-delivery-for-bds'
    return axiosClient.post(url, data)
  },
  confirmDeliveryFailForDBS: (data) => {
    // values of data
    // const { listPackageId, shopId } = data
    const url = '/lazada-confirm-delivery-fail-for-bds'
    return axiosClient.post(url, data)
  },
  checkAttributeProduct: (shopId, categoryId) => {
    const url = '/lazada-check-attribute-product'
    return axiosClient.post(url, {
      shopId,
      categoryId,
    })
  },
}

export default lazadaApi

import InputField from '_components/InputField'
import { removeAccents, removeDuplicateHyphens } from '_utils/function'
import { domainGenerator } from '_utils/functions/generator'
import React, { useState } from 'react'

const ShopName = (props) => {
  const { register, error, value } = props
  const textAfterHandle = value?.toLowerCase().replace(/\s+/g, ' ').trim()
  const [shopName, setShopName] = useState(textAfterHandle?.split(' ').join('-'))

  return (
    <div className='mb-3'>
      <InputField
        isRequired={true}
        label='Shop Name'
        type='text'
        id='shop-name'
        defaultValue={value}
        maxLength={65} // Thay đổi 64 thành 65 để khớp với rule
        register={register('shopName', {
          onChange: (e) => setShopName(removeDuplicateHyphens(removeAccents(e.target.value))),
          maxLength: 65,
        })}
        error={error}
      />

      <div id='shop-domain' className='form-text text-wrap text-break'>
        Domain: {domainGenerator()}
        {!shopName ? 'example' : removeDuplicateHyphens(removeAccents(shopName))}
      </div>
    </div>
  )
}

export default ShopName

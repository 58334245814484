/* eslint-disable function-paren-newline */
import Pagination from '_components/Pagination'

import MyLogo from '_components/MyLogo'
import NotFoundDataComponent from '_components/NotFoundDataComponent'
import SearchShop from '_components/SearchShop'
import { clearShopAndShopDetail, getAllShopsOpening, setIdShop } from '_redux/modules/shop'
import { NO_DATA_SHOP_DETAIL } from '_utils/constant'
import { flexibleSearch, scrollToTop } from '_utils/function'
import { useEffect, useMemo, useState } from 'react'
import { GoInbox } from 'react-icons/go'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { fetchMyShops } from '../../../redux/modules/shop'
import LoadingSkeletonShopList from './components/LoadingSkeletonShopList'
import ShopList from './components/ShopList'
import './style.scss'

// 10 shops per page
const PageSize = 10

function ShopPage() {
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const [loadingAllShopsOpening, setLoadingAllShopsOpening] = useState(false)
  const [loadingDebounce, setLoadingDebounce] = useState(false)

  const { allShops, myShops } = useSelector((state) => state.shop)

  const fetchShopOpening = async () => {
    try {
      setLoadingAllShopsOpening(true)
      await dispatch(getAllShopsOpening())
    } catch (error) {
      setLoadingAllShopsOpening(false)
    }
  }

  const shopNewCreate = useMemo(
    () => allShops?.sort((a, b) => b.createdAt - a.createdAt),
    [JSON.stringify(allShops)]
  )

  const [currentPage, setCurrentPage] = useState(1)

  const listShopFiltered = useMemo(
    () => shopNewCreate.filter((shop) => flexibleSearch(shop.shopName, search)),
    [search, JSON.stringify(shopNewCreate)]
  )

  let currentShops = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return listShopFiltered.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, JSON.stringify(listShopFiltered)])

  const isExistInCurrentShops = listShopFiltered?.some((shop) =>
    currentShops.some((currentShop) => shop.id === currentShop.id)
  )

  if (search.length > 1 && !isExistInCurrentShops) {
    currentShops = listShopFiltered
    if (currentShops.length > PageSize && listShopFiltered?.length > PageSize) {
      setCurrentPage(1)
    }
  }

  useEffect(() => {
    let isMounted = true

    dispatch(setIdShop(NO_DATA_SHOP_DETAIL))
    fetchShopOpening().then(() => {
      if (isMounted) {
        setLoadingAllShopsOpening(false)
      }
    })
    dispatch(clearShopAndShopDetail())
    if (!myShops) {
      dispatch(fetchMyShops())
    }

    return () => {
      isMounted = false
    }
  }, [])

  return (
    <div className='container get-shop-opening'>
      <MyLogo />
      <Row className='align-items-center' style={{ marginBottom: '30px' }}>
        <Col sm='12' className='d-flex align-items-center px-0'>
          <SearchShop
            setSearch={setSearch}
            setLoadingDebounce={setLoadingDebounce}
            styles={{ marginTop: 0, flex: 1 }}
            placeholder='Search shops by name...'
            onSubmitSearch={() => {}}
          />
        </Col>
      </Row>

      {!loadingAllShopsOpening && !allShops?.length ? (
        <NotFoundDataComponent
          icon={<GoInbox />}
          title='No shops available'
          subtitle=' There are currently no shops open. Please check back later.'
        />
      ) : (
        <Row className='shop-container'>
          {loadingAllShopsOpening || loadingDebounce ? (
            <LoadingSkeletonShopList />
          ) : (
            <ShopList listShop={currentShops} search={search} />
          )}
        </Row>
      )}

      {listShopFiltered.length > 0 && (
        <Row>
          <Col sm='12'>
            <Pagination
              className='pagination-bar'
              currentPage={currentPage}
              totalCount={listShopFiltered.length}
              pageSize={PageSize}
              onPageChange={(page) => {
                scrollToTop()
                setCurrentPage(page)
              }}
            />
          </Col>
        </Row>
      )}
    </div>
  )
}

export default ShopPage

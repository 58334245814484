import CustomSelectBox from '_components/CustomSelectBox'
import { CheckoutContext } from '_contexts/CheckoutContext'
import { selectPickUp } from '_redux/modules/fulfillment'
import { SELF_PICK_UP } from '_utils/constant'
import { isEmpty } from 'lodash'
import React, { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Calendar from './Calendar'
import RenderInfo from './RenderInfo'

function TabPickup() {
  const dispatch = useDispatch()
  const [isOpenTimePicker, setIsOpenTimePicker] = useState(false)
  const { activeTab, errors, user, shop, setValue, watch } = useContext(CheckoutContext)
  const { listPickUp, selectedPickUp, loadingPickup } = useSelector((state) => state.fulfillment)

  const isOwnerShop = user?.shopLinks?.some((item) => item === shop?.shopLink)

  const pickupOptions = listPickUp.map((pickup) => ({
    value: JSON.stringify(pickup),
    label: pickup.optionName,
  }))

  const handlePickupChange = (option) => {
    if (typeof option.value === 'string') {
      dispatch(selectPickUp(JSON.parse(option.value)))
    }
    setValue('pickupOption', option.value)
  }

  const pickupValue = watch('pickupOption')

  const isValidPickupValue = pickupValue && typeof pickupValue === 'string'

  return (
    <div className='tab-pickup__location'>
      {!listPickUp.length ? (
        <div className='tab-pickup__empty'>
          <strong>Currently shop has not pick-up</strong>
          {isOwnerShop && <Link to='/admin-console/fulfillment'>Create pickup option</Link>}
        </div>
      ) : null}

      {!loadingPickup && listPickUp.length ? (
        <>
          <div className='tab-pickup__select-box'>
            <CustomSelectBox
              options={pickupOptions}
              value={isValidPickupValue ? pickupValue : ''}
              onChange={handlePickupChange}
              placeholder='Select pickup option'
              isFulfillment
            />
            {errors.pickupOption && (
              <small className='tab-pickup__error'>{errors.pickupOption.message}</small>
            )}
          </div>

          {activeTab === SELF_PICK_UP && !isEmpty(selectedPickUp) && (
            <>
              <RenderInfo
                fee={selectedPickUp.pickupFee}
                instructions={selectedPickUp.instructions}
                isCheckPublicHoliday={selectedPickUp.isCheckPublicHoliday}
                type={SELF_PICK_UP}
              />
              <Calendar
                datesOfWeek={selectedPickUp.datesOfWeek}
                timeSlot={selectedPickUp.timeSlot || 30}
                startTime={selectedPickUp?.pickupStartTime}
                endTime={selectedPickUp?.pickupEndTime}
                activeTab={activeTab}
                type={SELF_PICK_UP}
                isOpenTimePicker={isOpenTimePicker}
                setIsOpenTimePicker={setIsOpenTimePicker}
              />
            </>
          )}

          {selectedPickUp?.pickupAddress && (
            <>
              <span className='tab-pickup__address-label'>Pickup Address:</span>
              <p className='tab-pickup__address'>{selectedPickUp?.pickupAddress}</p>
            </>
          )}
        </>
      ) : null}
    </div>
  )
}

export default TabPickup

import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FaRegCalendarAlt } from 'react-icons/fa'

const CustomDatePicker = (props) => {
  const {
    value,
    onChange,
    minDate,
    maxDate,
    format = 'dd-MM-yyyy',
    dataTestId,
    placeholderText = 'DD-MM-YYYY',
  } = props

  return (
    <div className='datepicker-wrapper'>
      <DatePicker
        selected={value}
        onChange={onChange}
        className='custom-datepicker form-control custom-input w-100 mb-2'
        dateFormat={format}
        minDate={minDate}
        maxDate={maxDate}
        customInput={<input data-testid={dataTestId} />}
        placeholderText={placeholderText}
      />
      <FaRegCalendarAlt className='calendar-icon' />
    </div>
  )
}

export default CustomDatePicker

import { Fragment } from 'react'
// import { useSelector } from 'react-redux'
// import ListWithCategory from './ListWithCategory'
import { TURN_ON, VIEW_GRID, VIEW_LIST } from '_utils/constant'
import classNames from 'classnames'
import moment from 'moment/moment'
import ProductItem from './ProductItem'

const ProductList = ({
  listProduct,
  findProduct,
  handleChangeSubItem,
  getValueSelect,
  changeProductQuantityInput,
  addAnotherOrder,
  deleteItemOrder,
  viewDefault,
}) => {
  if (!listProduct?.length) {
    return <div>No products</div>
  }

  listProduct?.sort((a, b) => {
    const dateA = moment.unix(a.createdAt)
    const dateB = moment.unix(b.createdAt)
    return dateB - dateA
  })

  return (
    <div
      className={classNames('product-container', {
        'product-container--list': viewDefault === VIEW_LIST,
        'product-container--grid': viewDefault === VIEW_GRID,
      })}
    >
      {listProduct.map((product, index) => (
        <Fragment key={`${product.id}-${index}`}>
          {product?.active === TURN_ON ? (
            <ProductItem
              styleFooterCard={{ height: 'auto' }}
              product={product}
              findProduct={findProduct}
              changeProductQuantityInput={changeProductQuantityInput}
              handleChangeSubItem={handleChangeSubItem}
              getValueSelect={getValueSelect}
              addAnotherOrder={addAnotherOrder}
              deleteItemOrder={deleteItemOrder}
              viewDefault={viewDefault}
            />
          ) : null}
        </Fragment>
      ))}
    </div>
  )
}
export default ProductList

import { useWindowWidth } from '@react-hook/window-size'
import TooltipDropdownItem from '_components/TooltipDropdownItem'
import { DeliveryContext } from '_contexts/DeliveryContext'
import { truncateTitle } from '_utils/function'
import React, { useContext } from 'react'
import {
  ButtonGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap'

export default function ButtonDeliveryEvent() {
  const {
    groupedDeliveryEvents,
    selectedDeliveryEventName,
    handleSelectDeliveryEvent,
    tabOrderDeliveryEvent,
  } = useContext(DeliveryContext)

  const width = useWindowWidth()

  return (
    <ButtonGroup className='tab-event'>
      <UncontrolledDropdown>
        <DropdownToggle tag={'button'} className={'custom-button mt-0 gray outlined'} caret>
          {truncateTitle(selectedDeliveryEventName, 255)}
        </DropdownToggle>
        <DropdownMenu>
          <div
            className='d-flex align-items-stretch wrap-dropdown-event w-100'
            style={groupedDeliveryEvents?.length >= 2 ? { overflowX: 'scroll' } : {}}
          >
            {groupedDeliveryEvents?.length > 0 ? (
              groupedDeliveryEvents.map((group, groupIndex) => (
                <div
                  key={groupIndex}
                  className='w-100 events-column d-inline-block'
                  style={{
                    verticalAlign: 'top',
                    maxWidth:
                      width > 768
                        ? groupedDeliveryEvents?.length >= 5
                          ? '120px'
                          : 'auto'
                        : 'auto',
                  }}
                >
                  {group.map((deliveryEvent) => (
                    <TooltipDropdownItem
                      key={deliveryEvent.id}
                      deliveryEvent={deliveryEvent}
                      handleSelectEvent={handleSelectDeliveryEvent}
                      tabOrderEvent={tabOrderDeliveryEvent}
                      groupedDeliveryEvents={groupedDeliveryEvents}
                    />
                  ))}
                </div>
              ))
            ) : (
              <DropdownItem disabled>No delivery event</DropdownItem>
            )}
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </ButtonGroup>
  )
}

import CustomButton from '_components/CustomButton'
import InputField from '_components/InputField'
import React, { useContext } from 'react'
import CurrencyFormat from 'react-currency-format'
import { Controller } from 'react-hook-form'
import { IoSave } from 'react-icons/io5'
import { TiCancel } from 'react-icons/ti'
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { ProductItemContext } from './ProductItem'

function ModalSetting({ isModal, toggleModal }) {
  const { control, errors, handleSubmit, handleSave } = useContext(ProductItemContext)

  const onSubmit = async (data) => {
    await handleSave(data)
    toggleModal()
  }

  const modalTitleStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }

  return (
    <Modal toggle={toggleModal} isOpen={isModal} className='modal-setting'>
      <ModalHeader toggle={toggleModal} style={modalTitleStyle}>
        Setting
      </ModalHeader>
      <ModalBody style={{ fontSize: 16 }}>
        <Row className='mb-3'>
          <Col sm='4'>Limit per order</Col>
          <Col sm='8'>
            <Controller
              name='limitPerOrder'
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputField
                  as={CurrencyFormat}
                  value={value || ''}
                  decimalScale={2}
                  onValueChange={(values) => {
                    onChange(values.value === '' ? null : values.value)
                  }}
                  error={errors?.limitPerOrder?.message}
                />
              )}
            />
          </Col>
        </Row>

        <Row className='mb-0'>
          <Col sm='4'>Minimum per order</Col>
          <Col sm='8'>
            <Controller
              name='minOrderQty'
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputField
                  as={CurrencyFormat}
                  value={value || ''}
                  decimalScale={2}
                  onValueChange={(values) => {
                    onChange(values.value === '' ? null : values.value)
                  }}
                  error={errors?.minOrderQty?.message}
                />
              )}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className='border-top-0 pt-0'>
        <div className='d-flex gap-2 w-100'>
          <CustomButton
            text='Cancel'
            color='slate'
            type='reset'
            icon={<TiCancel />}
            className='w-100 mt-0'
            onClick={toggleModal}
            isDisabled={errors && Object.keys(errors).length > 0}
          />
          <CustomButton
            text='Save'
            color='aqua'
            variant='contained'
            className='w-100 mt-0'
            onClick={handleSubmit(onSubmit)}
            icon={<IoSave />}
            isDisabled={errors && Object.keys(errors).length > 0}
          />
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default ModalSetting

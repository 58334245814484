import CustomButton from '_components/CustomButton'
import cancelImage from '_images/cancel.png'
import noImage from '_images/noimage02.svg'
import { IoMdCheckmark } from 'react-icons/io'
import { TiCancel } from 'react-icons/ti'
import { useToasts } from 'react-toast-notifications'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

function LogoModal(props) {
  const { modal, toggle, listImage, setListImage } = props
  const { addToast } = useToasts()

  const modalTitleStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }

  const onUserImageUploaded = (event) => {
    const { files } = event.target
    if (files.length + listImage?.length > 64) {
      addToast('Maximum images for shop is 64!', { appearance: 'error', autoDismiss: true })
      return
    }

    for (let i = 0; i < files.length; i++) {
      if (i < 64) {
        listImage.push({ url: global.URL.createObjectURL(files[i]), file: files[i] })
      }
    }
    setListImage([...listImage])
  }

  const handleOnRemoveImg = (index) => {
    listImage.splice(index, 1)
    setListImage([...listImage])
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered style={{ maxWidth: '600px' }}>
        <ModalHeader toggle={toggle} style={modalTitleStyle}>
          Upload shop image
        </ModalHeader>
        <ModalBody className='py-0'>
          <div className='add__logo-modal'>
            <div className='modal-header-section'>
              <div className='image-counter'>
                <span className='counter-text'>{64 - listImage?.length} photos remaining</span>
                <div className='counter-indicator'>
                  <div
                    className='progress-bar'
                    style={{ width: `${(listImage?.length / 64) * 100}%` }}
                  ></div>
                </div>
              </div>
            </div>
            <div
              className='c-form-modal upload-image-square mt-3 mb-3 cursor-pointer'
              id='upload-img'
            >
              <div className='upload-image flex-column'>
                <div className='upload-image-txt d-flex flex-column align-items-center'>
                  <img src={noImage} alt='' />
                  <span className='text-upload'>Upload Shop Images</span>
                </div>
                <div className='u-file-upload__item'>
                  <div className='u-file-upload__wrap'>
                    <a className='c-upload preview-images-zone'>
                      <input
                        type='file'
                        accept='image/*'
                        className='inputFile'
                        onChange={onUserImageUploaded}
                        multiple
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className='add__logo__modal-list-image'>
              {listImage?.map((item, index) => (
                <div key={index} className='add__logo__modal-list-image-item'>
                  <img src={item.url} alt='' className='logo-img' />
                  <div className='image-overlay'></div>
                  <img
                    src={cancelImage}
                    className='btn-remove-image'
                    onClick={() => handleOnRemoveImg(index)}
                    alt='Remove'
                  />
                </div>
              ))}
            </div>
            <div className='d-flex align-items-center gap-3'>
              <CustomButton
                text='Cancel'
                onClick={toggle}
                variant='outlined'
                icon={<TiCancel />}
                color='slate'
              />
              <CustomButton
                text='Ok'
                onClick={toggle}
                variant='contained'
                icon={<IoMdCheckmark />}
                color='aqua'
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default LogoModal

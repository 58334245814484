  import userApi from '_api/user'

  const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
  const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS'
  const GET_NOTIFICATIONS_FAILED = 'GET_NOTIFICATIONS_FAILED'
  const READ_NOTIFICATION = 'READ_NOTIFICATION'
  const READ_NOTIFICATION_SUCCESS = 'READ_NOTIFICATION_SUCCESS'
  const READ_NOTIFICATION_FAILED = 'READ_NOTIFICATION_FAILED'

  const initialState = {
    listNotification: [],
    show: false,
    loading: false,
    errorMessage: '',
  }

  export const getNotifications = () => async (dispatch) => {
    try {
      dispatch({ type: GET_NOTIFICATIONS, payload: { loading: true } })
      const res = await userApi.getNotifications()
      dispatch({
        type: GET_NOTIFICATIONS_SUCCESS,
        payload: { listNotification: res.msgResp, loading: false },
      })
    } catch (error) {
      dispatch({
        type: GET_NOTIFICATIONS_FAILED,
        payload: { loading: false, errorMessage: error.message || error.msgResp },
      })
    }
  }

  export const readNotification = () => async (dispatch) => {
    try {
      dispatch({
        type: READ_NOTIFICATION,
        payload: {
          loading: true,
        },
      })

      await userApi.readNotification()
      dispatch({
        type: READ_NOTIFICATION_SUCCESS,
        payload: {
          listNotification: [],
          loading: false,
        },
      })
    } catch (error) {
      dispatch({
        type: READ_NOTIFICATION_FAILED,
        payload: {
          loading: false,
          errorMessage: error.message || error.msgResp,
        },
      })
    }
  }

  const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_NOTIFICATIONS:
      case GET_NOTIFICATIONS_SUCCESS:
      case GET_NOTIFICATIONS_FAILED:
      case READ_NOTIFICATION:
      case READ_NOTIFICATION_SUCCESS:
      case READ_NOTIFICATION_FAILED:
        return {
          ...state,
          ...action.payload,
        }
      default:
        return state
    }
  }

  export default notificationReducer

import { yupResolver } from '@hookform/resolvers/yup'
import authApi from '_api/auth'
import reportApi from '_api/report'
import InputField from '_components/InputField'
import MyPasswordCheckList from '_components/MyPasswordCheckList'
import useQuery from '_hooks/useQuery'
import imgTop from '_images/img_top.svg'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useToasts } from 'react-toast-notifications'
import { Form, Spinner } from 'reactstrap'
import * as Yup from 'yup'
import './style.scss'

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
})

const ResetPassword = () => {
  const params = useQuery()
  const history = useHistory()
  const { addToast } = useToasts()

  const [isValidChecklist, setIsValidChecklist] = useState(false)

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    register,
    watch,
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  })

  const watchAllFields = watch()

  const onSubmit = async (data) => {
    await authApi
      .searchUser(params?.get('email'))
      .then(async (res) => {
        if (res.msgResp.uid) {
          const patchData = {
            step: 2,
            email: params?.get('email'),
            code: parseInt(params.get('code'), 10),
            newPassword: data.confirmPassword,
          }

          try {
            await authApi.resetPassword(patchData)
            addToast('Password has been reset successfully', {
              appearance: 'success',
              autoDismiss: true,
            })
            setTimeout(() => {
              history.push('/virtual-shop/sign-in')
            }, 1000)
          } catch (error) {
            reportApi.report({ message: error })
            addToast('Something went wrong, please try again', {
              appearance: 'error',
              autoDismiss: true,
            })
          }
        }
      })
      .catch(({ msgResp, msgCode }) => {
        if (msgCode !== 42504) {
          reportApi.report({ message: `${msgCode} - ${msgResp}` })
        }
        addToast('Incorrect ID/code', {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }

  return (
    <div className='p-login min-vh-100 reset-password'>
      <div className='page-login page-login--screen'>
        <div className='main-top align-self-start'>
          <div className='container'>
            <div className='main-logo text-left'>
              <Link
                to='/'
                className='main-logo text-left text-white font-semibold d-flex flex-column align-items-center'
                style={{ width: '30%' }}
              >
                {/* <img src={logoLeft} /> */}
                <h4 className='mb-0'>Co-Hoot</h4>
                <small>Good thing must share!</small>
              </Link>
            </div>
            <div className='main-img text-center'>
              <img src={imgTop} />
            </div>
          </div>
        </div>
        <div className='main-content'>
          <Form onSubmit={handleSubmit(onSubmit)} className='container'>
            <h2 className='c-ttl text-center'>Reset Password</h2>
            <div className='c-form c-form--email'>
              <div className='c-txt-org'>Password</div>
              <div id='form-pin'>
                <InputField
                  id='password'
                  name='password'
                  placeholder='Password'
                  type='password'
                  register={register('password')}
                />
                {errors?.password && (
                  <small className='errorMessage'>{errors.password.message}</small>
                )}
                <div className='c-txt-gray mt-3'>Re-type password</div>
                <InputField
                  id='confirmPassword'
                  name='confirmPassword'
                  placeholder='Confirm Password'
                  type='password'
                  register={register('confirmPassword')}
                />
                {errors?.confirmPassword && (
                  <small className='errorMessage'>{errors.confirmPassword.message}</small>
                )}
                <MyPasswordCheckList
                  password={watchAllFields?.password || ''}
                  confirmPassword={watchAllFields?.confirmPassword || ''}
                  setIsValidChecklist={setIsValidChecklist}
                  className={'mt-3'}
                />
                <button
                  className='btn btn--main mt-3'
                  type='submit'
                  disabled={isSubmitting || isValidChecklist === false}
                >
                  {isSubmitting ? <Spinner color='light' size='sm' /> : 'SUBMIT'}
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword

import axios from 'axios'
import moment from 'moment'
import {
  BUYER_ORDER_CANCELED,
  BUYER_ORDER_COLLECTED,
  BUYER_ORDER_PAID,
  BUYER_ORDER_REFUNDED,
  BUYER_ORDER_UNPAID,
  DATE_FORMAT,
  HOST_ORDER_CANCELED,
  HOST_ORDER_EVENT_COLLECTED,
  HOST_ORDER_EVENT_VERIFIED,
  HOST_ORDER_FULFILLED,
  HOST_ORDER_PAID,
  HOST_ORDER_UNPAID,
  HOST_ORDER_VERIFIED,
  LIST_TIME,
  LIST_TIME_2,
} from '../constant'
import { getTimeZoneHour } from '../function'

export const convertURLImageToFile = async (src) => {
  const res = await axios.get(src, { responseType: 'blob' })
  return new File([res.data], 'image.jpg', {
    type: 'image/jpeg',
  })
}

export const convertBlobUrlToFile = async (blobUrl) => {
  try {
    // Lấy Blob từ Blob URL
    const response = await fetch(blobUrl)
    const blob = await response.blob()

    // Tạo File từ Blob
    const file = new File([blob], 'image.jpg', { type: 'image/jpeg' })
    return file
  } catch (error) {
    console.error('Có lỗi xảy ra:', error)
  }
}

export const convertDateSelection = (date) => {
  if (date) {
    const format = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    const hours = `0${date.getHours()}`.slice(-2)
    const minutes = `0${date.getMinutes()}`.slice(-2)
    const newFormat = format.split('/')
    return `${newFormat[1]}-${newFormat[0]}-${newFormat[2]} ${hours}:${minutes}`
  }
}

// input is String (same as '28-12-2021 03:00')
export const convertToCurrentGMT = (timeString) => {
  const timeInLocal = moment.utc(timeString, DATE_FORMAT).local()
  return timeInLocal.format(DATE_FORMAT)
}

export const convertDuration = (dateTime, duration) => {
  if (dateTime) {
    const currentTime = convertToCurrentGMT(dateTime)
    const getHourStart = currentTime?.slice(11, 16)
    const deliveryStartIndex = LIST_TIME_2.findIndex((item) => item?.valueString === getHourStart)
    const getHourEnd = LIST_TIME_2?.filter(
      (item) =>
        (item?.valueNumber - LIST_TIME_2[deliveryStartIndex]?.valueNumber) * 3600 === duration
    )[0]

    return {
      hourStart: LIST_TIME_2[deliveryStartIndex]?.text,
      hourEnd: getHourEnd?.text,
    }
  }
}

export const convertTimeStringWithDuration = (time, timeDuration) => {
  const timeCurrentGMT = convertToCurrentGMT(time)
  const timeStart = Number(timeCurrentGMT.split(' ')[1].split(':')[0])
  const timeEnd = timeStart + timeDuration / 3600
  let timeStartText = ''
  let timeEndText = ''
  if (timeEnd <= 24) {
    const indexStart = LIST_TIME.findIndex((item) => item.id === timeStart)
    const indexEnd = LIST_TIME.findIndex((item) => item.id === timeEnd % 24)
    timeStartText = LIST_TIME[indexStart].text
    timeEndText = LIST_TIME[indexEnd].text
  }
  return `${timeCurrentGMT.split(' ')[0]} (${timeStartText} - ${timeEndText})`
}

export const convertTimeStringWithDurationTwoPart = (time, timeDuration) => {
  const timeCurrentGMT = convertToCurrentGMT(time)
  const timeStart = Number(timeCurrentGMT.split(' ')[1].split(':')[0])
  const timeEnd = timeStart + timeDuration / 3600
  let timeStartText = ''
  let timeEndText = ''
  if (timeEnd <= 24) {
    const indexStart = LIST_TIME.findIndex((item) => item.id === timeStart)
    const indexEnd = LIST_TIME.findIndex((item) => item.id === timeEnd % 24)
    timeStartText = LIST_TIME[indexStart].text
    timeEndText = LIST_TIME[indexEnd].text
  }
  return [timeCurrentGMT.split(' ')[0], `${timeStartText} - ${timeEndText}`]
}

export const convertToTimeEnd = (time, timeDuration, timeStamp = false) => {
  const timeCurrentGMT = convertToCurrentGMT(time)
  const timeStart = Number(timeCurrentGMT.split(' ')[1].split(':')[0])
  const timeEnd = timeStart + timeDuration / 3600
  const timeString = `${timeCurrentGMT.split(' ')[0]} ${timeEnd}:00`
  if (timeStamp) {
    return moment(timeString, 'DD-MM-YYYY HH:mm').unix()
  }
  return timeString
}

// input is String (same as '28-12-2021 03:00')
export const convertToGMT0 = (timeString) => {
  const timeInUTC = moment(timeString, DATE_FORMAT).utc()
  return timeInUTC.format(DATE_FORMAT)
}

// input is number
export const convertTimestampToCurrentGMT = (timestamp) => {
  const timeZoneToMilliseconds = getTimeZoneHour() * 60 * 60 * 1000
  return (timestamp * 1000 + timeZoneToMilliseconds) / 1000
}

export const convertDateSelectInfo = (dateStr) => {
  if (dateStr) {
    const formatDate = dateStr.slice(0, 10)
    const arrDate = formatDate.split('-').map((num) => Number(num))
    const currentYear = arrDate[2]
    const currentMonth = arrDate[1]
    const currentDate = arrDate[0]
    return {
      currentDate,
      currentMonth,
      currentYear,
    }
  }
}

export const convertCurrentDate = (date) => {
  if (date instanceof Date) {
    const currentDate = date.getDate()
    const currentMonth = date.getMonth() + 1
    const currentYear = date.getFullYear()
    return {
      currentDate,
      currentMonth,
      currentYear,
    }
  }

  throw new Error('Invalid date')
}

export const convertStatusNumberToText = (statusCode, isEventOrder) => {
  let text = ''

  if (isEventOrder) {
    switch (statusCode) {
      case BUYER_ORDER_CANCELED:
      case HOST_ORDER_CANCELED:
        text = 'Canceled'
        break
      case BUYER_ORDER_UNPAID:
      case HOST_ORDER_UNPAID:
        text = 'Unpaid'
        break
      case HOST_ORDER_EVENT_VERIFIED:
        text = 'Verified'
        break
      case HOST_ORDER_EVENT_COLLECTED:
        text = 'Collected'
        break
      case BUYER_ORDER_REFUNDED:
        text = 'Refunded'
        break
      case BUYER_ORDER_COLLECTED:
        text = 'Collected'
        break
      default:
        break
    }
  } else {
    switch (statusCode) {
      case BUYER_ORDER_CANCELED:
      case HOST_ORDER_CANCELED:
        text = 'Canceled'
        break
      case BUYER_ORDER_UNPAID:
      case HOST_ORDER_UNPAID:
        text = 'Unpaid'
        break
      case BUYER_ORDER_PAID:
      case HOST_ORDER_PAID:
        text = 'Paid'
        break
      case HOST_ORDER_VERIFIED:
        text = 'Payment Verified'
        break
      case HOST_ORDER_FULFILLED:
        text = 'Fulfilled'
        break
      case BUYER_ORDER_COLLECTED:
        text = 'Collected'
        break
      default:
        break
    }
  }

  return text
}

// src/components/ErrorBoundary/index.jsx
import PropTypes from 'prop-types'
import React from 'react'
import { BiError } from 'react-icons/bi'
import './style.scss'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, error })
    console.error('Error caught by boundary:', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='error-boundary'>
          <div className='error-content'>
            <BiError className='error-icon' />
            <h1 className='error-title'>Oops! Something went wrong</h1>
            <p className='error-message'>
              {this.state.error?.message || 'An unexpected error occurred'}
            </p>
            <button className='reload-button' onClick={() => window.location.reload()}>
              Try Again
            </button>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ErrorBoundary

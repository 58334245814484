import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const AccountSkeleton = () => (
  <div className='container'>
    <div className='profile-page mt-0 pt-0'>
      <div className='profile-avatar-bg'>
        <Skeleton height={200} />
      </div>
      <div className='c-form c-form--profile'>
        <div style={{ width: 100, height: 100, margin: '0 auto' }}>
          <Skeleton circle height={100} width={100} />
        </div>
        <div className='upload-name mt-2'>
          <Skeleton width={150} />
        </div>
        <div className='nav-profile'>
          <ul className='justify-content-center align-items-center'>
            {[1, 2, 3, 4].map((item) => (
              <li key={item} style={{ maxWidth: '22%' }}>
                <Skeleton width={80} height={30} />
              </li>
            ))}
          </ul>
        </div>
        <div style={{ marginTop: 20 }}>
          <Skeleton count={5} height={40} style={{ marginBottom: 10 }} />
        </div>
      </div>
    </div>
  </div>
)

export default AccountSkeleton

import React from 'react'
import { BiCalendar } from 'react-icons/bi'

const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
  <div className='custom-select' onClick={onClick} ref={ref}>
    <div className='select-header'>
      <span className='selected-value'>{value}</span>
      <span className='arrow no-rotate'>
        <BiCalendar size={16} />
      </span>
    </div>
  </div>
))

CustomDateInput.displayName = 'CustomDateInput'

export default CustomDateInput

import CustomButton from '_components/CustomButton'
import classNames from 'classnames'
import { AiOutlineShoppingCart } from 'react-icons/ai'

const AddToCartButton = ({
  isHovered,
  isSoldOut,
  // colorTheme,
  onAddToCart,
  product,
  viewMode = 'grid',
}) => (
  <CustomButton
    onClick={() => onAddToCart(product)}
    disabled={isSoldOut}
    className={classNames('product-card__button', {
      'product-card__button--hovered': isHovered,
      [`product-card__button--${viewMode}`]: viewMode,
    })}
    text={isSoldOut ? 'Sold Out' : 'Add to Cart'}
    icon={<AiOutlineShoppingCart />}
    variant='contained'
  />
)

export default AddToCartButton

import CustomSelectBox from '_components/CustomSelectBox'
import { LIST_TIME } from '_utils/constant'
import { isEmpty } from 'lodash'

// First create time options array
const timeOptions = LIST_TIME.map((item) => ({
  value: item.text,
  label: item.text,
}))

const getValueTime = (time) => {
  if (!time || isEmpty(time)) {
    return null
  }
  return JSON.stringify({ value: time, label: time })
}

// Create TimeSelector component
const EventTimeSelector = ({ start, end, onChangeStart, onChangeEnd }) => (
  <div className='form-group'>
    <label>Collection Time</label>
    <div className='form-group-time'>
      <div className='form-group-time__item border-0'>
        <CustomSelectBox
          options={timeOptions}
          value={getValueTime(start)}
          onChange={(option) => onChangeStart(option.value)}
          placeholder='Select start time'
          isTimer={true}
          data-testid='event-collection-time-start'
        />
      </div>
      <span>-</span>
      <div className='form-group-time__item border-0'>
        <CustomSelectBox
          options={timeOptions}
          value={getValueTime(end)}
          onChange={(option) => onChangeEnd(option.value)}
          placeholder='Select end time'
          isTimer={true}
          data-testid='event-collection-time-end'
        />
      </div>
    </div>
  </div>
)

export default EventTimeSelector

export const FETCH_LIST_PRODUCT = 'FETCH_LIST_PRODUCT'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const COPY_PRODUCT = 'COPY_PRODUCT'
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS'
export const SWAP_UP_PRODUCT = 'SWAP_UP_PRODUCT'
export const SWAP_DOWN_PRODUCT = 'SWAP_DOWN_PRODUCT'
export const PUSH_TO_LAZADA_SUCCESS = 'PUSH_TO_LAZADA_SUCCESS'
export const PUSH_TO_LAZADA_FAILED = 'PUSH_TO_LAZADA_FAILED'
export const UPLOAD_IMAGE_PRODUCT_LAZADA = 'UPLOAD_IMAGE_PRODUCT_LAZADA'
export const SELECT_PRODUCT_LAZADA = 'SELECT_PRODUCT_LAZADA'
export const DELETE_PRODUCT_LAZADA = 'DELETE_PRODUCT_LAZADA'
export const SELECT_TAB = 'SELECT_TAB'
export const TOGGLE_SUB_ITEM = 'TOGGLE_SUB_ITEM'
export const UPLOAD_IMAGE_PRODUCT = 'UPLOAD_IMAGE_PRODUCT'
export const TOGGLE_ACTIVE_PRODUCT = 'TOGGLE_ACTIVE_PRODUCT'
export const UPDATE_NEW_LIST_PRODUCT = 'UPDATE_NEW_LIST_PRODUCT'
export const UPDATE_EFFECT = 'UPDATE_EFFECT'
export const CLEAR_SELECT_PRODUCT_LAZADA = 'CLEAR_SELECT_PRODUCT_LAZADA'
export const EXPORT_PRODUCT_TO_LAZADA = 'EXPORT_PRODUCT_TO_LAZADA'
export const TOGGLE_VARIATIONS = 'TOGGLE_VARIATIONS'
export const FETCH_LIST_PRODUCT_LAZADA = 'FETCH_LIST_PRODUCT_LAZADA'
export const SELECT_PRODUCT_TO_PUSH_LAZADA = 'SELECT_PRODUCT_TO_PUSH_LAZADA'
export const GET_LAZADA_CATEGORY_SUGGESTIONS = 'GET_LAZADA_CATEGORY_SUGGESTIONS'
export const CLEAR_LAZADA_CATEGORY_SUGGESTIONS = 'CLEAR_LAZADA_CATEGORY_SUGGESTIONS'
export const UPDATE_SINGLE_PRODUCT_LAZADA = 'UPDATE_SINGLE_PRODUCT_LAZADA'
export const HANDLE_ADD_VARIATION = 'HANDLE_ADD_VARIATION'
export const PUSH_TO_LAZADA = 'PUSH_TO_LAZADA'
export const FETCH_LIST_VARIATION_PRODUCT = 'FETCH_LIST_VARIATION_PRODUCT'
export const GET_BRAND_BY_PAGES = 'GET_BRAND_BY_PAGES'
export const HANDLE_UPDATE_VARIATION = 'HANDLE_UPDATE_VARIATION'
export const UPDATE_NEW_LIST_PRODUCT_LAZADA = 'UPDATE_NEW_LIST_PRODUCT_LAZADA'
export const UPDATE_LIST_PRODUCT_LAZADA = 'UPDATE_LIST_PRODUCT_LAZADA'
export const UPDATE_NEW_LIST_VARIATION_WITH_ACTION_REMOVE =
  'UPDATE_NEW_LIST_VARIATION_WITH_ACTION_REMOVE'
export const FETCH_LIST_VARIATION_PRODUCT_FAILED = 'FETCH_LIST_VARIATION_PRODUCT_FAILED'
export const CLEAR_PRODUCT_FLASH = 'CLEAR_PRODUCT_FLASH'

/* eslint-disable newline-per-chained-call */
import moment from 'moment/moment'
import React, { memo, useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

function OrderChart({ data }) {
  const [date, setDate] = useState()
  const [value, setValue] = useState()
  const history = useHistory()
  const { addToast } = useToasts()

  const { dateWhenSyncData } = useSelector((state) => state.dashBoard)

  useEffect(() => {
    if (data?.length > 0) {
      if (data[0].typeShowData === 'weeks') {
        const listDate = data?.map((element) => {
          const getDate = element.date.slice(0, 5)
          return `${getDate}`
        })
        const listValue = data?.map((element) => element.totalValue)
        setValue(listValue)
        setDate(listDate)
      } else if (data[0].typeShowData === 'months') {
        const listDate = data?.map((element) => {
          const getMonth = element.monthInhYear
          return `${getMonth} M`
        })
        const listValue = data?.map((element) => element.totalValue)
        setValue(listValue)
        setDate(listDate)
      } else {
        const listDate = data?.map((element) => {
          const getYear = element.year
          return `${getYear} Y`
        })
        const listValue = data?.map((element) => element.totalValue)
        setValue(listValue)
        setDate(listDate)
      }
    }
  }, [JSON.stringify(data)])

  const handleBarClick = (_, elements) => {
    if (elements.length > 0) {
      const dataIndex = elements[0].index
      const clickedData = data[dataIndex]
      const { typeShowData } = clickedData
      let endDate
      let startDate

      const syncEndDate = moment(dateWhenSyncData.endDate, 'DD-MM-YYYY')
      const syncStartDate = moment(dateWhenSyncData.startDate, 'DD-MM-YYYY') // Lấy startDate từ dateWhenSyncData

      switch (typeShowData) {
        case 'weeks':
          // eslint-disable-next-line no-case-declarations
          const [week, year] = clickedData.weekInYear.split('-')
          startDate = moment().year(year).week(week).startOf('week').format('DD-MM-YYYY')
          endDate = moment().year(year).week(week).endOf('week').format('DD-MM-YYYY')
          break
        case 'months':
          startDate = moment(clickedData.date, 'DD-MM-YYYY').startOf('month').format('DD-MM-YYYY')
          endDate = moment(clickedData.date, 'DD-MM-YYYY').endOf('month').format('DD-MM-YYYY')
          break
        case 'years':
          startDate = moment(clickedData.year, 'YYYY').startOf('year').format('DD-MM-YYYY')
          endDate = moment(clickedData.year, 'YYYY').endOf('year').format('DD-MM-YYYY')
          break
        default:
          addToast('Does not support this type of data', { appearance: 'error', autoDismiss: true })
          break
      }

      if (moment(startDate, 'DD-MM-YYYY').isBefore(syncStartDate)) {
        startDate = syncStartDate.format('DD-MM-YYYY')
      }

      if (moment(endDate, 'DD-MM-YYYY').isAfter(syncEndDate)) {
        endDate = syncEndDate.format('DD-MM-YYYY')
      }

      history.push(`/admin-console/order-records?startDate=${startDate}&endDate=${endDate}`)
    }
  }

  return (
    <div className="chart-container">
      <Bar
        data={{
          labels: date,
          datasets: [
            {
              label: 'TOTAL ORDERS',
              backgroundColor: ['#FFDC88'],
              data: value,
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              display: false,
              events: [],
            },
          },
          title: {
            display: true,
            text: 'Predicted world population (millions) in 2050',
          },
          scales: {
            y: {
              ticks: {
                stepSize: 1,
              },
            },
          },
          onClick: handleBarClick,
        }}
      />
    </div>
  )
}

export default memo(OrderChart)

import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

function ModalInfoCreatePickup({ modal, toggle, toggleAddPickup }) {
  const handleOnClick = () => {
    toggle()
    toggleAddPickup()
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered backdrop='static'>
        <ModalHeader toggle={toggle} />
        <ModalBody>
          <div className='container text-center'>
            <p className='c-txt-org'>Congratulation! </p>
            <span>
              You have success created a shop and your shop no pickup, create pickup please
            </span>
          </div>
          <div className='row mt-3'>
            <div className='col-6 m-auto'>
              <button className='btn btn--org btn--org02' onClick={handleOnClick}>
                OK
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ModalInfoCreatePickup

/* eslint-disable no-control-regex */
/* eslint-disable no-lonely-if */
import { yupResolver } from '@hookform/resolvers/yup'
import { CheckoutContext } from '_contexts/CheckoutContext'
import {
  clearSelectDelivery,
  clearSelectPickUp,
  getListDelivery,
  getListPickup,
  selectDelivery,
  selectFulfillmentType,
  selectPickUp,
} from '_redux/modules/fulfillment'
import {
  BUYER_ORDER_UNPAID,
  DELIVERY_OPTION,
  NO_FULFILLMENT,
  PAYMENT_COD,
  SELF_PICK_UP,
  TRANSFER_BANKING,
} from '_utils/constant'
import { getOrderRefParam, sanitizeStringForUrl, scrollToTop } from '_utils/function'
import { IoMdArrowBack } from 'react-icons/io'

import orderApi from '_api/order'
import CustomButton from '_components/CustomButton'
import ModalNotifySuccess from '_components/ModalNotifySuccess'
import NotFound from '_components/NotFound'
import {
  clearCart,
  clearOrderDetailsByRef,
  getOrderDetailsByRef,
  getOrderDetailsByRefNoLogin,
} from '_redux/modules/order'
import { getShopByShopSlug, setShopDetail } from '_redux/modules/shop'
import { convertToCurrentGMT } from '_utils/functions/converter'
import { getTimeZoneOffset } from '_utils/functions/generator'
import {
  getCartCheckout,
  getCartOrderDetailsByRef,
  getDataShopDetail,
  getUserInfo,
  getUserToken,
  removeCartCheckout,
  setCartOrderDetailsByRef,
} from '_utils/localData'
import classNames from 'classnames'
import { isEmpty, isNumber, isUndefined } from 'lodash'
import moment from 'moment'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { MdOutlinePayment } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import Bill from './components/Bill'
import { CheckBoxGift } from './components/CheckBoxGift'
import FormContactDetails from './components/FormContactDetails'
import CheckoutSkeleton from './components/Skeletons/CheckoutSkeleton'
import Tabs from './components/Tabs'
import { createBuyerSchema } from './schemaValidate'
import './style.scss'

function Checkout() {
  const userInfo = getUserInfo()
  const userToken = getUserToken()
  const cart = getCartCheckout()
  const cartOrderDetailsByRef = getCartOrderDetailsByRef()
  const shopDetailLocalStorage = getDataShopDetail()
  const orderRefParams = getOrderRefParam()

  const [shopNotFound, setShopNotFound] = useState(false)
  const [shopDetailState, setShopDetailState] = useState({})
  const [loadingShop, setLoadingShop] = useState(true)
  const [activeTab, setActiveTab] = useState(NO_FULFILLMENT)
  const [isOpenTimePicker, setIsOpenTimePicker] = useState(false)
  const [isModalOpenSuccess, setIsModalOpenSuccess] = useState(false)

  const { shopDetail } = useSelector((state) => state.shop)

  const history = useHistory()
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const { dateAndTime, shopSlugBefore, orderDetailsByRef } = useSelector((state) => state.order)
  const { listDelivery, listPickUp, loadingDelivery, loadingPickup } = useSelector(
    (state) => state.fulfillment
  )

  const isOrderRef =
    orderRefParams && orderDetailsByRef?.ref ? orderRefParams === orderDetailsByRef?.ref : false
  const cartByRef = {
    orderList: orderDetailsByRef?.listProduct?.map((item) => ({
      ...item,
      quantityInput: item.quantity,
    })),
  }

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    clearErrors,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'all',
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      address: '',
      giftInfo: {},
      isGift: false,
      paymentMethod: TRANSFER_BANKING,
      noLogin: !userToken,
      pickupOption: {},
      deliveryOption: {},
      fulfillmentType: NO_FULFILLMENT,
    },
    resolver: yupResolver(createBuyerSchema(activeTab)),
  })

  const buyer = watch()
  const fulfillmentType = watch('fulfillmentType')
  // const watchPickup = watch('pickupOption')
  // const watchDelivery = watch('deliveryOption')

  const resetFormValuesGift = () => {
    setValue('giftInfo.name', '')
    setValue('giftInfo.email', '')
    setValue('giftInfo.phone', '')
    setValue('giftInfo.address', '')
  }

  const toggleModalSuccess = () => setIsModalOpenSuccess(!isModalOpenSuccess)

  const handleWhenShowModalPaymentSuccess = (btnType) => {
    if (btnType === 'continue') {
      // yes
      if (!isEmpty(shopDetail)) {
        history.push(`/${shopDetailState?.shopSlug}`)
      } else if (!isEmpty(orderDetailsByRef)) {
        const shopSlug =
          orderDetailsByRef?.shopLink.split('/')[orderDetailsByRef?.shopLink.split('/').length - 1]
        history.push(`/${shopSlug}`)
      } else {
        const shopSlugNoLogin = localStorage.getItem('shopSlugNoLogin')
        history.push(`/${shopSlugNoLogin}`)
        localStorage.removeItem('shopSlugNoLogin')
      }
      dispatch(clearCart())
      dispatch(clearOrderDetailsByRef())
      dispatch(clearSelectDelivery())
      dispatch(clearSelectPickUp())
    } else {
      // No
      history.push('/virtual-shop/list')
      dispatch(clearCart())
      dispatch(clearOrderDetailsByRef())
      dispatch(clearSelectDelivery())
      dispatch(clearSelectPickUp())
    }
  }

  const getUserIdFromPath = () => {
    const shopLinksOfUser = userInfo?.shopLinks?.find((link) => link?.includes(shopSlugBefore))

    if (shopLinksOfUser) {
      return userInfo?.id
    }

    return undefined
  }

  const handlePaymentBanking = async (postData) => {
    const isChangeFulfillment = orderDetailsByRef?.fulfilmentType !== activeTab
    const convertData = {
      ...postData,
      ref: cartOrderDetailsByRef?.ref || orderDetailsByRef?.ref,
      changeFulfillment: isChangeFulfillment,
      cashOnDeliveryPickup:
        buyer.fulfillmentType === DELIVERY_OPTION ? postData.cashOnDeliveryPickup : undefined,
    }

    try {
      if (isOrderRef) {
        await orderApi.updateShopOrderWithFulfillment(JSON.parse(JSON.stringify(convertData)))
        history.push(`/virtual-shop/payment-code/${convertData.ref}`, { from: 'checkout' })
      } else {
        const { msgResp } = userToken
          ? await orderApi.createOrderShopWithFulfillmentHasLogin(
              JSON.parse(JSON.stringify(postData))
            )
          : await orderApi.createOrderShopWithFulfillmentNoLogin(
              JSON.parse(JSON.stringify(postData))
            )

        if (msgResp?.ref) {
          setCartOrderDetailsByRef({ ref: msgResp.ref, email: buyer.email })
          history.push(`/virtual-shop/payment-code/${msgResp.ref}`, { from: 'checkout' })
        }
      }

      resetFormValuesGift()
      setValue('isGift', false)
      dispatch(clearOrderDetailsByRef())
      dispatch(clearCart())
      dispatch(clearSelectDelivery())
      dispatch(clearSelectPickUp())
      removeCartCheckout()
    } catch (error) {
      addToast(error.message || error.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const handlePaymentCOD = async (postData) => {
    const handleSuccess = () => {
      // dispatch(clearOrderDetailsByRef())
      // dispatch(clearSelectDelivery())
      // dispatch(clearSelectPickUp())
      // dispatch(clearCart())
      toggleModalSuccess(true)
    }

    const handleError = (error) => {
      addToast(error.message || error.msgResp, { appearance: 'error', autoDismiss: true })
    }

    try {
      if (isOrderRef) {
        const isChangeFulfillment = orderDetailsByRef?.fulfilmentType !== activeTab
        const convertData = {
          ...postData,
          ref: cartOrderDetailsByRef?.ref || orderDetailsByRef?.ref,
          changeFulfillment: isChangeFulfillment,
        }

        await orderApi.updateShopOrderWithFulfillment(JSON.parse(JSON.stringify(convertData)))
        setValue('isGift', false)
        resetFormValuesGift()
        toggleModalSuccess(true)
      } else {
        if (!userToken) {
          localStorage.setItem('emailNoLogin', buyer.email)
          localStorage.setItem('shopSlugNoLogin', shopDetailState?.shopSlug)

          await orderApi.createOrderShopWithFulfillmentNoLogin(JSON.parse(JSON.stringify(postData)))

          handleSuccess()
        } else {
          await orderApi.createOrderShopWithFulfillmentHasLogin(
            JSON.parse(JSON.stringify(postData))
          )

          handleSuccess()
        }
      }
    } catch (error) {
      handleError(error)
    }
  }
  const parseJSONIfPossible = (value) => {
    try {
      return JSON.parse(value)
    } catch (e) {
      return value
    }
  }

  const renderGiftInfo = (data) => {
    if (!isEmpty(orderDetailsByRef?.giftRecipientInfo)) {
      return !buyer.isGift ? null : data.giftInfo
    }

    if (fulfillmentType === DELIVERY_OPTION) {
      return buyer.isGift ? data.giftInfo : undefined
    }

    return undefined
  }

  const onSubmit = async (data) => {
    if (cart?.orderList?.length) {
      data.details = cart?.orderList
        .filter((item) => item.quantityInput)
        .reduce((acc, item) => {
          const { id, quantityInput, subItems } = item
          acc[id] = acc[id] || []
          acc[id].push({ quantity: quantityInput, subItems })
          return acc
        }, {})
    }

    data.pickupOption = !isEmpty(data.pickupOption) ? parseJSONIfPossible(data.pickupOption) : {}
    data.deliveryOption = !isEmpty(data.deliveryOption)
      ? parseJSONIfPossible(data.deliveryOption)
      : {}

    if (shopDetailState?.id) {
      const postData = {
        details: data.details,
        fullName: data.name,
        shopId: shopDetailState?.id,
        cashOnDeliveryPickup: shopDetailState?.cashOnDeliveryPickup
          ? buyer.paymentMethod === PAYMENT_COD
            ? 1 // payment method COD
            : undefined
          : undefined,
        representedUserId: getUserIdFromPath(),
        email: data.email,
        mobilePhone: data.phone,
        address: !buyer.isGift ? data.address || undefined : undefined,
        buyerStatus: BUYER_ORDER_UNPAID,
        postalCode: 12345, // test
        pickupOptionId: fulfillmentType === SELF_PICK_UP ? data.pickupOption.id : undefined,
        pickupBookingDate: fulfillmentType === SELF_PICK_UP ? dateAndTime : undefined,
        pickupBookingTime: fulfillmentType === SELF_PICK_UP ? dateAndTime.time : undefined,
        ref: Math.random().toString(36).substring(6) + new Date().getTime().toString(),
        giftRecipientInfo: renderGiftInfo(data),
        deliveryOptionId: fulfillmentType === DELIVERY_OPTION ? data.deliveryOption.id : undefined,
        deliveryBookingDate: fulfillmentType === DELIVERY_OPTION ? dateAndTime : undefined,
        deliveryBookingTime: fulfillmentType === DELIVERY_OPTION ? dateAndTime.time : undefined,
        gmt: getTimeZoneOffset(),
      }

      const hasRangeTime = dateAndTime?.time?.includes('-')
      const endTime = hasRangeTime ? dateAndTime?.time?.split('-')[1] : dateAndTime?.time

      // GMT+0
      // const endTime = dateAndTime?.time ? dateAndTime?.time?.split('-')[1] : undefined

      const currentEndTime = convertToCurrentGMT(`${dateAndTime?.date} ${endTime}`)

      const endUnixTime = moment(currentEndTime, 'DD-MM-YYYY HH:mm').unix()

      const today = moment().unix()
      const selectedDate = moment(dateAndTime?.date, 'DD-MM-YYYY')

      if (
        fulfillmentType !== NO_FULFILLMENT &&
        (selectedDate.isBefore(moment(), 'day') ||
          (today > endUnixTime && moment().format('DD-MM-YYYY') === dateAndTime?.date))
      ) {
        scrollToTop()
        addToast('You cannot order for days or range time in the past.', {
          autoDismiss: true,
          appearance: 'error',
        })
        return
      }

      if (today <= endUnixTime || buyer.fulfillmentType === NO_FULFILLMENT) {
        // For remove undefined key
        // JSON.parse(JSON.stringify(postData))
        if (buyer.paymentMethod === PAYMENT_COD) {
          await handlePaymentCOD(postData)
        } else if (buyer.paymentMethod === TRANSFER_BANKING) {
          await handlePaymentBanking(postData)
        }
      }
    }
  }

  const handleDirectBack = () => {
    if (isOrderRef) return

    if (cart?.shopSlug) {
      reset()
      history.push(`/${cart?.shopSlug}`)
    } else if (isOrderRef && !isEmpty(orderDetailsByRef)) {
      const shopSlug =
        orderDetailsByRef?.shopLink?.split('/')[orderDetailsByRef?.shopLink?.split('/')?.length - 1]
      reset()
      history.push(`/${shopSlug}`)
    } else {
      reset()
      history.goBack()
    }

    dispatch(clearOrderDetailsByRef())
  }

  const renderTabDeliveryPickup = () => {
    const isDeliveryOrderPickup = listDelivery?.length > 0 || listPickUp?.length > 0
    const isEditingNoFulfillOrder =
      isOrderRef &&
      orderDetailsByRef !== null &&
      orderDetailsByRef?.fulfilmentType === NO_FULFILLMENT

    if (isEditingNoFulfillOrder) {
      return
    }

    if (loadingDelivery || loadingPickup) {
      return <div className='fw-bold text-center'>Loading Delivery & Pickup...</div>
    }

    if (isDeliveryOrderPickup) {
      return (
        <>
          <div className='checkout__header'>
            <span className='checkout__title'>Delivery Details</span>
          </div>
          <div
            className={classNames('checkout__content', {
              'mb-3': shopDetailState?.giftRecipient,
              'checkout__content--disabled': !shopDetailState?.fulfillmentStatus,
            })}
          >
            <Tabs isDeliveryOrderPickup={isDeliveryOrderPickup} />
          </div>
        </>
      )
    }

    return null
  }

  useEffect(() => {
    let isMounted = true
    if (cart?.shopSlug) {
      const fetchShop = async () => {
        try {
          await dispatch(getShopByShopSlug(cart?.shopSlug))
        } catch (error) {
          if (isMounted) {
            setShopNotFound(true)
            addToast(error.message || error.msgResp, { appearance: 'error', autoDismiss: true })
          }
        } finally {
          if (isMounted) setLoadingShop(false)
        }
      }

      fetchShop().then(() => {})
    }

    return () => {
      isMounted = false
    }
  }, [cart?.shopSlug])

  useLayoutEffect(() => {
    if (!buyer.isGift) {
      resetFormValuesGift()
    } else {
      setValue('address', '')
    }
  }, [buyer.isGift])

  useEffect(() => {
    if (!isEmpty(shopDetailLocalStorage)) {
      setShopDetailState(shopDetailLocalStorage)
    }
  }, [!isEmpty(shopDetailLocalStorage)])

  // FETCH DATA
  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        dispatch(getListPickup(shopDetailState?.id)),
        dispatch(getListDelivery(shopDetailState?.id)),
      ])
    }

    if (shopDetailState?.id && shopDetailState?.fulfillmentStatus) {
      fetchData().then(() => {})
    }

    scrollToTop()
  }, [shopDetailState?.id])

  useEffect(() => {
    if (listPickUp.length && !listDelivery.length) {
      setActiveTab(SELF_PICK_UP)
    } else if (!listPickUp.length && listDelivery.length) {
      setActiveTab(DELIVERY_OPTION)
    } else if (listPickUp.length && listDelivery.length) {
      setActiveTab(SELF_PICK_UP)
    }
  }, [JSON.stringify(listPickUp), JSON.stringify(listDelivery)])

  useEffect(() => {
    let isMounted = true

    if (
      !userInfo?.id &&
      cart?.buyer?.email &&
      (cartOrderDetailsByRef?.ref || orderDetailsByRef?.ref) &&
      isMounted
    ) {
      dispatch(
        getOrderDetailsByRefNoLogin({
          ref: cartOrderDetailsByRef?.ref || orderDetailsByRef?.ref,
          uEmail: cart?.buyer?.email,
        })
      )
    }

    return () => {
      isMounted = false
    }
  }, [cartOrderDetailsByRef?.ref, userInfo?.id, orderDetailsByRef?.ref])

  useEffect(() => {
    if (orderRefParams) {
      dispatch(getOrderDetailsByRef(orderRefParams))
    }
  }, [orderRefParams])

  useEffect(() => {
    let isMounted = true
    if (orderDetailsByRef?.shopId) {
      const fetchFulfillment = async () => {
        await Promise.all([
          dispatch(getListPickup(orderDetailsByRef.shopId)),
          dispatch(getListDelivery(orderDetailsByRef.shopId)),
        ])
      }

      const fetchShop = async () => {
        try {
          const shopSlugFormatted = sanitizeStringForUrl(orderDetailsByRef.shopName)
          const shopSlugStateFormatted = sanitizeStringForUrl(shopDetailState.shopSlug)

          await dispatch(getShopByShopSlug(shopSlugFormatted || shopSlugStateFormatted))
        } catch (error) {
          if (isMounted) {
            setShopNotFound(true)
            addToast(error.message || error.msgResp, { appearance: 'error', autoDismiss: true })
          }
        } finally {
          if (isMounted) setLoadingShop(false)
        }
      }

      fetchShop().then(() => {})

      if (
        orderDetailsByRef?.fulfilmentType &&
        isNumber(orderDetailsByRef?.fulfilmentType) &&
        orderDetailsByRef?.fulfilmentType !== NO_FULFILLMENT
      ) {
        fetchFulfillment().then(() => {})
      }
    }

    return () => {
      isMounted = false
    }
  }, [orderDetailsByRef?.shopId])

  useEffect(() => {
    if (!isEmpty(shopDetail) && !isEmpty(orderDetailsByRef)) {
      if (orderDetailsByRef.fulfilmentType === NO_FULFILLMENT) {
        const convertedShop = {
          ...shopDetail,
          fulfillmentStatus: false,
        }
        setShopDetailState(convertedShop)
        dispatch(setShopDetail(convertedShop))
      } else {
        setShopDetailState(shopDetail)
      }
    } else if (!isEmpty(shopDetail)) {
      setShopDetailState(shopDetail)
    }
  }, [isEmpty(shopDetail), JSON.stringify(orderDetailsByRef)])

  useEffect(() => {
    if (
      listPickUp.length &&
      activeTab === SELF_PICK_UP &&
      isEmpty(orderDetailsByRef?.orderPickup?.pickupOptionId) &&
      isEmpty(cart?.orderPickup?.pickupOptionId)
    ) {
      dispatch(clearSelectDelivery())
      dispatch(selectFulfillmentType(SELF_PICK_UP))
      setValue('fulfillmentType', SELF_PICK_UP)
      dispatch(selectPickUp(listPickUp[0]))
      setValue('pickupOption', JSON.stringify(listPickUp[0]))
    } else if (
      listDelivery.length &&
      activeTab === DELIVERY_OPTION &&
      isEmpty(orderDetailsByRef?.orderDelivery?.deliveryOptionId) &&
      isEmpty(cart?.orderDelivery?.deliveryOptionId)
    ) {
      dispatch(clearSelectPickUp())
      dispatch(selectFulfillmentType(DELIVERY_OPTION))
      setValue('fulfillmentType', DELIVERY_OPTION)
      dispatch(selectDelivery(listDelivery[0]))
      setValue('deliveryOption', JSON.stringify(listDelivery[0]))
    }

    return () => {
      clearErrors()
    }
  }, [
    activeTab,
    JSON.stringify(listDelivery),
    JSON.stringify(listPickUp),
    isEmpty(orderDetailsByRef),
    isEmpty(cart),
  ])

  useEffect(() => {
    const pickupOptionId =
      orderDetailsByRef?.orderPickup?.pickupOptionId || cart?.orderPickup?.pickupOptionId
    const deliveryOptionId =
      orderDetailsByRef?.orderDelivery?.deliveryOptionId || cart?.orderDelivery?.deliveryOptionId

    if (pickupOptionId && listPickUp.length > 0) {
      const pickupOptionBooked = listPickUp.find((item) => item.id === pickupOptionId)
      if (pickupOptionBooked) {
        setActiveTab(SELF_PICK_UP)
        dispatch(selectFulfillmentType(SELF_PICK_UP))
        setValue('fulfillmentType', SELF_PICK_UP)
        setValue('pickupOption', JSON.stringify(pickupOptionBooked))
        dispatch(selectPickUp(pickupOptionBooked))
      }
    }

    if (deliveryOptionId && listDelivery.length > 0) {
      const deliveryOptionBooked = listDelivery.find((item) => item.id === deliveryOptionId)

      if (deliveryOptionBooked) {
        setActiveTab(DELIVERY_OPTION)
        dispatch(selectFulfillmentType(DELIVERY_OPTION))
        setValue('fulfillmentType', DELIVERY_OPTION)
        setValue('deliveryOption', JSON.stringify(deliveryOptionBooked))
        dispatch(selectDelivery(deliveryOptionBooked))
      }
    }
  }, [
    orderDetailsByRef?.orderPickup?.pickupOptionId,
    orderDetailsByRef?.orderDelivery?.deliveryOptionId,
    listPickUp.length,
    listDelivery.length,
    isEmpty(cart?.orderPickup),
    !isEmpty(cart?.orderDelivery),
  ])

  // useEffect(() => {
  //   if (
  //     (orderDetailsByRef?.orderPickup?.pickupOptionId || cart?.orderPickup?.pickupOptionId) &&
  //     listPickUp.length > 0
  //   ) {
  //     const pickupOptionBooked = listPickUp?.find(
  //       (item) =>
  //         item.id ===
  //         (orderDetailsByRef?.orderPickup?.pickupOptionId || cart?.orderPickup?.pickupOptionId)
  //     )
  //     if (pickupOptionBooked) {
  //       dispatch(selectFulfillmentType(SELF_PICK_UP))
  //       setValue('fulfillmentType', SELF_PICK_UP)
  //       setValue('pickupOption', JSON.stringify(pickupOptionBooked))
  //       dispatch(selectPickUp(pickupOptionBooked))
  //     }
  //   }
  // }, [
  //   orderDetailsByRef?.orderPickup?.pickupOptionId,
  //   listPickUp.length,
  //   isEmpty(cart?.orderPickup),
  // ])

  // useEffect(() => {
  //   if (
  //     (orderDetailsByRef?.orderDelivery?.deliveryOptionId ||
  //       cart?.orderDelivery?.deliveryOptionId) &&
  //     listDelivery.length > 0
  //   ) {
  //     const deliveryOptionBooked = listDelivery?.find(
  //       (item) =>
  //         item?.id ===
  //         (orderDetailsByRef?.orderDelivery?.deliveryOptionId ||
  //           cart?.orderDelivery?.deliveryOptionId)
  //     )
  //     if (deliveryOptionBooked) {
  //       dispatch(selectFulfillmentType(DELIVERY_OPTION))
  //       setValue('fulfillmentType', DELIVERY_OPTION)
  //       setValue('deliveryOption', JSON.stringify(deliveryOptionBooked))
  //       dispatch(selectDelivery(deliveryOptionBooked))
  //     }
  //   }
  // }, [
  //   orderDetailsByRef?.orderDelivery?.deliveryOptionId,
  //   listDelivery.length,
  //   !isEmpty(cart?.orderDelivery),
  // ])

  // This effect use for "ordered"
  // useEffect(() => {
  //   if (
  //     (listPickUp.length || listDelivery.length) &&
  //     !isEmpty(orderDetailsByRef) &&
  //     [NO_FULFILLMENT, DELIVERY_OPTION, SELF_PICK_UP].includes(orderDetailsByRef?.fulfilmentType)
  //   ) {
  //     setActiveTab(orderDetailsByRef.fulfilmentType)
  //     dispatch(selectFulfillmentType(orderDetailsByRef.fulfilmentType))
  //   }
  // }, [JSON.stringify(orderDetailsByRef), JSON.stringify(listDelivery), JSON.stringify(listPickUp)])

  // EFFECT FOR ORDERING
  // useEffect(() => {
  //   if (listPickUp.length && !listDelivery.length) {
  //     setActiveTab(SELF_PICK_UP)
  //   }
  // }, [JSON.stringify(listPickUp)])

  // useEffect(() => {
  //   if (!listPickUp.length && listDelivery.length) {
  //     setActiveTab(DELIVERY_OPTION)
  //   }
  // }, [JSON.stringify(listDelivery)])

  useEffect(() => {
    if (cart?.buyer?.isCheckCOD || orderDetailsByRef?.cashOnDeliveryPickup) {
      setValue('isCheckCOD', true)
      setValue('paymentMethod', PAYMENT_COD)
    }
  }, [JSON.stringify(cart), orderDetailsByRef?.cashOnDeliveryPickup])

  if (loadingShop) {
    return <CheckoutSkeleton />
  }

  if (orderRefParams && shopNotFound) return <NotFound />

  return (
    <div className='container checkout-wrapper'>
      <CheckoutContext.Provider
        value={{
          buyer,
          cart,
          register,
          errors,
          userInfo,
          activeTab,
          setActiveTab,
          dispatch,
          shop: shopDetailState,
          setValue,
          isOpenTimePicker,
          setIsOpenTimePicker,
          watch,
          control,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} className='checkout px-3'>
          <div className='checkout__form-section'>
            {renderTabDeliveryPickup()}

            {shopDetailState?.giftRecipient && fulfillmentType === DELIVERY_OPTION ? (
              <CheckBoxGift />
            ) : null}

            <FormContactDetails />
          </div>

          <div className='checkout__order-summary-section'>
            {isUndefined(cartByRef?.orderList) ? <Bill cart={cart} /> : <Bill cart={cartByRef} />}

            <div className='row mt-3'>
              <div className='col-6'>
                <CustomButton
                  type='button'
                  onClick={handleDirectBack}
                  isDisabled={isOrderRef}
                  text='Back'
                  icon={<IoMdArrowBack />}
                  color='aqua'
                  className='mt-0'
                />
              </div>
              <div className='col-6'>
                <CustomButton
                  type='submit'
                  text={
                    isSubmitting
                      ? 'Processing...'
                      : isOrderRef
                      ? 'Update Order'
                      : buyer.paymentMethod === PAYMENT_COD
                      ? 'Make Order'
                      : 'Make Payment'
                  }
                  isDisabled={isSubmitting}
                  variant='contained'
                  color='aqua'
                  className='mt-0'
                  icon={<MdOutlinePayment />}
                />
              </div>
            </div>
          </div>
        </form>

        <ModalNotifySuccess
          isModalOpen={isModalOpenSuccess}
          handleWhenShowModalPaymentSuccess={handleWhenShowModalPaymentSuccess}
          isCOD={buyer.paymentMethod === PAYMENT_COD}
        />
      </CheckoutContext.Provider>
    </div>
  )
}

export default Checkout

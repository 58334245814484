import { SUBITEM_REQUIRED } from '_utils/constant'
import classNames from 'classnames'
import CurrencyFormat from 'react-currency-format'

const CheckBoxSubItem = ({ product, handleChangeSubItem, getValueSelect, viewMode }) => {
  if (!product.subItems?.length) return null

  const handleOptionSelect = (subItem, index, option) => {
    const currentValue = getValueSelect({
      pid: product.id,
      pIndex: product.index,
      subItemType: subItem.type,
    })

    // If clicking the same option, uncheck it (unless it's required)
    const newValue =
      currentValue &&
      JSON.parse(currentValue)?.name === option.name &&
      subItem.required !== SUBITEM_REQUIRED
        ? null
        : { name: option.name, price: option.price }

    handleChangeSubItem({
      pid: product.id,
      subItem: { ...subItem, index },
      value: newValue,
      pIndex: product.index,
    })
  }

  const getSelectedOption = (subItem) => {
    const value = getValueSelect({
      pid: product.id,
      pIndex: product.index,
      subItemType: subItem.type,
    })
    return value ? JSON.parse(value) : null
  }

  return (
    <div
      className={classNames('product-card__option-box', {
        'product-card__option-box--list': viewMode === 'list',
        'product-card__option-box--grid': viewMode === 'grid',
      })}
    >
      {product.subItems.map((subItem, index) => {
        const selectedOption = getSelectedOption(subItem)

        return (
          <div key={index} className='product-card__option-group'>
            <div className='product-card__option-title'>{subItem.type}</div>
            <div className='product-card__option-items'>
              {subItem.list?.map((option, i) => (
                <div
                  key={i}
                  className={classNames('product-card__option-item', {
                    'product-card__option-item--active': selectedOption?.name === option.name,
                  })}
                  onClick={() => handleOptionSelect(subItem, index, option)}
                >
                  <span className='product-card__option-name'>{option.name}</span>
                  <span className='product-card__option-price'>
                    (
                    <CurrencyFormat
                      value={option.price}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                    )
                  </span>
                </div>
              ))}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default CheckBoxSubItem

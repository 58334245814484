/* eslint-disable no-useless-escape */
import CustomButton from '_components/CustomButton'
import InputField from '_components/InputField'
import { selectShopAndSelectShopDetail } from '_redux/modules/shop'
import { getDataShopDetail } from '_utils/localData'
import React, { useState } from 'react'
import { IoAddOutline } from 'react-icons/io5'
import { TiCancel } from 'react-icons/ti'
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

function validURL(str) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i'
  )
  return !!pattern.test(str)
}

// function isLazadaURL(url) {
//   return url.includes('lazada')
// }

const ExternalLinkModal = (props) => {
  const { modal, toggle, value, setValue } = props
  const shop = getDataShopDetail()
  const { addToast } = useToasts()
  const [nameExternalLink, setNameExternalLink] = useState('')
  const [externalLink, setExternalLink] = useState('')
  const [linkError, setLinkError] = useState('')
  const dispatch = useDispatch()

  const handleAddLinkExternal = () => {
    if (nameExternalLink !== '' && externalLink !== '') {
      // if (!isLazadaURL(externalLink)) {
      //   setLinkError('The URL must be a Lazada URL')
      //   return
      // }
      const externalLinkObj = {
        name: nameExternalLink,
        value: externalLink,
      }
      value.push(externalLinkObj)
      setValue(value)

      dispatch(selectShopAndSelectShopDetail({ ...shop, externalLink: value }))
      setNameExternalLink('')
      setExternalLink('')
      toggle(!modal)
    } else {
      addToast('Invalid name and link url', { appearance: 'error', autoDismiss: true })
    }
  }

  const handleOnChange = (e) => {
    setExternalLink(e.target.value)
    if (validURL(e.target.value)) {
      setLinkError('')
    } else if (e.target.value === '') {
      setLinkError('')
    } else {
      setLinkError('Invalid external link')
    }
  }

  const modalTitleStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }

  return (
    <Modal isOpen={modal} toggle={toggle} centered className='external-link-modal'>
      <ModalHeader toggle={toggle} style={modalTitleStyle}>
        Add External Link
      </ModalHeader>
      <ModalBody>
        <InputField
          label='Link Name'
          placeholder='Enter link name'
          value={nameExternalLink}
          onChange={(e) => setNameExternalLink(e.target.value)}
          isRequired
          offset={{
            bottom: '0.5rem',
          }}
          type='text'
        />

        <InputField
          label='URL'
          placeholder='Enter Lazada URL'
          value={externalLink}
          onChange={handleOnChange}
          isRequired
          error={linkError}
          offset={{
            bottom: '0.5rem',
          }}
          type='text'
        />

        <div className='d-flex align-items-center gap-3'>
          <CustomButton
            text='Cancel'
            color='slate'
            onClick={toggle}
            variant='outlined'
            icon={<TiCancel />}
          />

          <CustomButton
            text='Add Link'
            color='aqua'
            onClick={handleAddLinkExternal}
            variant='contained'
            icon={<IoAddOutline />}
            isDisabled={!!linkError || !nameExternalLink || !externalLink}
          />
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ExternalLinkModal

import CustomButton from '_components/CustomButton'
import CustomSwalFire from '_components/CustomSwalFire'
import DialogBoxUpgradeSubscription from '_components/DialogBoxUpgradeSubscription'
import {
  copyProduct,
  deleteProduct,
  selectAndToggleProductLazadaModal,
  setOpenSubItem,
} from '_redux/modules/product'
import { LAZADA_FEATURE_PAYMENT_STATUS_APPROVED, PACKAGE_SUBSCRIPTION_BASIC } from '_utils/constant'
import { scrollToTop } from '_utils/function'
import { generateIdWithLength } from '_utils/functions/generator'
import React, { useState } from 'react'
import { AiOutlineSetting } from 'react-icons/ai'
import { FaShareSquare, FaTrashAlt } from 'react-icons/fa'
import { MdContentCopy } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

function ProductSetting({ product, toggleModal, append, subItems, toggleOpenModalShare }) {
  const [isOpenModal, setIsOpenModal] = useState(false)

  const { addToast } = useToasts()
  const dispatch = useDispatch()

  const { listInvoiceLazada } = useSelector((state) => state.lazada)
  const { currentPackage } = useSelector((state) => state.subscription)

  const { selectedShop } = useSelector((state) => state.shop)
  const { listProduct, listProductLazada } = useSelector((state) => state.product)

  const shopApproved =
    listInvoiceLazada.find((item) => item.shopId === selectedShop?.id)?.status ===
    LAZADA_FEATURE_PAYMENT_STATUS_APPROVED

  const toggleModalDialog = () => setIsOpenModal(!isOpenModal)

  const onDelete = (pId, pIndex) => {
    CustomSwalFire.delete({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      confirmButtonText: 'Yes, delete it!',

      onConfirm: async () => {
        // Pass both id and index to deleteProduct
        await dispatch(deleteProduct(selectedShop, pId, pIndex))
        addToast('Product deleted successfully', {
          appearance: 'success',
          autoDismiss: true,
        })
      },
      onError: (error) => {
        CustomSwalFire.error({
          title: 'Error!',
          text: error.message,
        })
      },
    })
  }

  const onAddSubItem = async () => {
    if (subItems.length >= 2) {
      addToast(`Maximum number of sub-items: ${2}`, {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }

    try {
      append({
        list: [
          {
            price: 0,
            name: '',
            id: generateIdWithLength(20),
          },
        ],
        required: 1,
        type: '',
        id: generateIdWithLength(20),
      })
      dispatch(setOpenSubItem(true, product.id, product.index))
    } catch (error) {
      addToast(error.message, { appearance: 'error', autoDismiss: true })
    }
  }

  const onCopyProduct = async (_product) => {
    const pid = _product.id
    const pIndex = _product.index

    const conditions = [
      currentPackage === null && listProduct.length === 5,
      currentPackage === undefined && listProduct.length === 5,
      currentPackage?.subscriptionPackageType === PACKAGE_SUBSCRIPTION_BASIC &&
        listProduct.length === 5,
    ]

    try {
      if (conditions.some((condition) => condition)) {
        toggleModalDialog()
      } else {
        await dispatch(copyProduct(selectedShop, pid, pIndex))
        scrollToTop()
      }
    } catch (error) {
      addToast(error.message, { appearance: 'error', autoDismiss: true })
    }
  }

  const onShareProduct = () => {
    const existsProd = listProductLazada.find((item) => item.id === product.id)

    if (!existsProd) {
      dispatch(selectAndToggleProductLazadaModal(product))
      toggleOpenModalShare()
    } else {
      addToast('Product already in Lazada tab', { appearance: 'error', autoDismiss: true })
    }
  }

  return (
    <>
      <div className='d-flex align-items-center justify-content-end gap-2 mt-4'>
        <button className='bg-transparent border-0' onClick={toggleModal}>
          <AiOutlineSetting size={27} className='btn-settings' />
        </button>
        <button className='bg-transparent border-0' onClick={() => onCopyProduct(product)}>
          <MdContentCopy size={27} className={'btn-settings'} />
        </button>
        <button
          className='bg-transparent border-0'
          onClick={() => onDelete(product.id, product.index)}
        >
          <FaTrashAlt size={27} className={'btn-settings'} />
        </button>
        {shopApproved && false && (
          <button className='bg-transparent border-0'>
            <FaShareSquare size={27} className={'btn-settings'} onClick={onShareProduct} />
          </button>
        )}
      </div>

      <CustomButton
        text='Add sub-items'
        onClick={onAddSubItem}
        className='text-decoration-underline border-0 p-0 w-auto ms-auto mt-0'
        color='aqua'
        variant='text'
      />

      <DialogBoxUpgradeSubscription isOpen={isOpenModal} toggle={toggleModalDialog} />
    </>
  )
}

export default ProductSetting

import { DeliveryContext } from '_contexts/DeliveryContext'
import moment from 'moment'
import { useContext } from 'react'
import DeliveryRangeDateSkeleton from './DeliveryRangeDateSkeleton'

const DeliveryDateRange = ({ rangeDate }) => {
  const { loading } = useContext(DeliveryContext)

  if (loading) {
    return <DeliveryRangeDateSkeleton />
  }

  return (
    <p className='mb-0' style={{ fontSize: '0.75rem' }}>
      <span className='me-1'>Selecting date from</span>
      <strong className='me-1'>{moment(rangeDate[0]?.startDate).format('DD/MM/YYYY')}</strong>
      <span className='me-1'>to</span>
      <strong>{moment(rangeDate[0]?.endDate).format('DD/MM/YYYY')}</strong>
    </p>
  )
}

export default DeliveryDateRange

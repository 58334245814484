import InputField from '_components/InputField'
import { CheckoutContext } from '_contexts/CheckoutContext'
import { SELF_PICK_UP } from '_utils/constant'
import { getCartCheckout } from '_utils/localData'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'

function FormContactDetails() {
  const { buyer, register, errors, shop, setValue } = useContext(CheckoutContext)
  const { orderDetailsByRef } = useSelector((state) => state.order)
  const { fulfillmentType, listDelivery } = useSelector((state) => state.fulfillment)
  const cartCheckout = getCartCheckout()

  useEffect(() => {
    if (!isEmpty(cartCheckout?.buyer)) {
      setValue('name', cartCheckout?.buyer?.name)
      setValue('email', cartCheckout?.buyer?.email)
      setValue('phone', cartCheckout?.buyer?.phone)
      setValue('address', cartCheckout?.buyer?.address)
    }
  }, [JSON.stringify(cartCheckout?.buyer)])

  useEffect(() => {
    if (
      !isEmpty(orderDetailsByRef) &&
      isEmpty(orderDetailsByRef?.orderPickup) &&
      isEmpty(orderDetailsByRef?.orderDelivery)
    ) {
      setValue('name', orderDetailsByRef.uName)
      setValue('email', orderDetailsByRef.uEmail)
      setValue('phone', orderDetailsByRef.uPhone)
    }
  }, [JSON.stringify(orderDetailsByRef)])

  useEffect(() => {
    if (!isEmpty(cartCheckout?.orderDelivery)) {
      setValue('name', cartCheckout?.orderDelivery?.uName)
      setValue('email', cartCheckout?.orderDelivery?.uEmail)
      setValue('phone', cartCheckout?.orderDelivery?.uPhone)
      setValue('address', cartCheckout?.orderDelivery?.uAddress || '')
    }
  }, [JSON.stringify(cartCheckout?.orderDelivery)])

  useEffect(() => {
    if (!isEmpty(cartCheckout?.orderPickup)) {
      setValue('name', cartCheckout?.orderPickup?.uName)
      setValue('email', cartCheckout?.orderPickup?.uEmail)
      setValue('phone', cartCheckout?.orderPickup?.uPhone)
      setValue('address', cartCheckout?.orderPickup?.uAddress || '')
    }
  }, [JSON.stringify(cartCheckout?.orderPickup)])

  useEffect(() => {
    if (!isEmpty(orderDetailsByRef)) {
      if (!isEmpty(orderDetailsByRef?.orderDelivery)) {
        setValue('name', orderDetailsByRef?.orderDelivery?.uName)
        setValue('email', orderDetailsByRef?.orderDelivery?.uEmail)
        setValue('phone', orderDetailsByRef?.orderDelivery?.uPhone)
        setValue('address', orderDetailsByRef?.orderDelivery?.uAddress || '')
      } else if (!isEmpty(orderDetailsByRef?.orderPickup)) {
        setValue('name', orderDetailsByRef?.orderPickup?.uName)
        setValue('email', orderDetailsByRef?.orderPickup?.uEmail)
        setValue('phone', orderDetailsByRef?.orderPickup?.uPhone)
        setValue('address', orderDetailsByRef?.orderPickup?.uAddress || '')
      }
    }
  }, [JSON.stringify(orderDetailsByRef)])

  const getDisplayStyle = () => {
    if (
      buyer.isGift ||
      fulfillmentType === SELF_PICK_UP ||
      !shop?.fulfillmentStatus ||
      !listDelivery?.length
    ) {
      return false
    }
    return true
  }

  return (
    <>
      <p className='form-contact-details__title text-uppercase mb-2'>Contact Details</p>
      <div
        className={classNames(
          'form-contact-details__container',
          shop?.giftRecipient || !shop?.fulfillmentStatus
            ? ''
            : 'form-contact-details__container--margin-top'
        )}
      >
        <InputField
          type='text'
          placeholder='Full Name'
          defaultValue={buyer?.name}
          register={register('name')}
          error={errors.name?.message}
        />
        <InputField
          type='email'
          placeholder='E-mail'
          defaultValue={buyer?.email}
          register={register('email')}
          error={errors.email?.message}
        />
        <InputField
          type='text'
          placeholder='Mobile Number'
          defaultValue={buyer?.phone}
          register={register('phone')}
          error={errors.phone?.message}
          offset={{ bottom: getDisplayStyle() ? 16 : 0 }}
        />
        {getDisplayStyle() && (
          <InputField
            type='text'
            placeholder='Address'
            defaultValue={buyer?.address}
            register={register('address')}
            error={errors.address?.message}
          />
        )}
      </div>
    </>
  )
}

export default FormContactDetails

/* FILE: ListProduct.jsx */

import CustomButton from '_components/CustomButton'
import DialogBoxUpgradeSubscription from '_components/DialogBoxUpgradeSubscription'
import ErrorBoundary from '_components/ErrorBoundary'
import NotFoundDataComponent from '_components/NotFoundDataComponent'
// Remove LoadingOverlay import
import { PACKAGE_SUBSCRIPTION_BASIC } from '_utils/constant'
import classNames from 'classnames'
import React, { Fragment, useState } from 'react'
import { GoInbox } from 'react-icons/go'
import { IoIosAdd, IoIosClose } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import ListProductSkeleton from '../Skeletons/ListProductSkeleton'
import FormCreateProduct from './FormCreateProduct'
import ProductItem from './ProductItem'

function ListProduct({ listProductFilter, loadingShop }) {
  // const dispatch = useDispatch()
  // const { selectedShop } = useSelector((state) => state.shop)
  const { currentPackage } = useSelector((state) => state.subscription)
  const [isOpenModalCreate, setIsOpenModalCreate] = useState(false)
  const [isOpenModalUpgrade, setIsOpenModalUpgrade] = useState(false)
  const toggleModalCreate = () => setIsOpenModalCreate(!isOpenModalCreate)
  const toggleModalUpgrade = () => setIsOpenModalUpgrade(!isOpenModalUpgrade)

  const onToggle = () => {
    if (
      (currentPackage === null && listProductFilter.length === 5) ||
      (currentPackage === undefined && listProductFilter.length === 5) ||
      (currentPackage?.subscriptionPackageType === PACKAGE_SUBSCRIPTION_BASIC &&
        listProductFilter.length === 5)
    ) {
      toggleModalUpgrade()
    } else {
      toggleModalCreate()
    }
  }

  // useEffect(() => {
  //   if (selectedShop?.products) {
  //     const sortedProducts = [...selectedShop.products]
  //       .sort((a, b) => b.createdAt - a.createdAt)
  //       .map((product) => ({
  //         ...product,
  //         isOpenSubItems: Boolean(product.isOpenSubItems),
  //       }))
  //     dispatch(updateNewListProduct(sortedProducts))
  //   }
  // }, [])

  const tableHeader = [
    { label: 'Image', className: 'col-image' },
    { label: 'Name', className: 'col-name' },
    { label: 'Price ($)', className: 'col-price', style: { textAlign: 'right' } },
    { label: 'In Stock', className: 'col-inventory', style: { textAlign: 'right' } },
    { label: 'Purchase', className: 'col-purchase', style: { textAlign: 'right' } },
    { label: 'Description', className: 'col-description' },
    { label: 'Active', className: 'col-active' },
    { label: 'Setting', className: 'col-setting' },
  ]

  const getClassName = (item) => {
    if (item.label === 'Setting' || item.label === 'Image') {
      return 'text-center'
    }
    return null
  }

  return (
    <ErrorBoundary>
      {loadingShop ? (
        <ListProductSkeleton />
      ) : (
        <>
          <div className='list-product position-relative mt-3 border-start border-end border-bottom'>
            <div className='my_fixed_table'>
              <table className='table'>
                <thead className='product-header'>
                  <tr>
                    {tableHeader.map((item) => (
                      <th
                        key={item.label}
                        className={classNames('table-header', item.className, getClassName(item))}
                        style={item?.style}
                      >
                        {item.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className='product-content'>
                  {loadingShop && (
                    <tr>
                      <td colSpan={tableHeader.length} width={'100%'}>
                        <ListProductSkeleton />
                      </td>
                    </tr>
                  )}

                  {!loadingShop && listProductFilter?.length
                    ? listProductFilter
                        .filter((product) => !product?.isDelete)
                        .map((product, index) => (
                          <Fragment key={`${product.id}-${index}`}>
                            <ProductItem
                              product={product}
                              pIndex={index}
                              maxLengthProduct={listProductFilter.length - 1}
                              colSpan={tableHeader.length}
                            />
                          </Fragment>
                        ))
                    : null}

                  {!loadingShop && !listProductFilter?.length && (
                    <tr>
                      <td colSpan={tableHeader.length} width={'100%'}>
                        <NotFoundDataComponent
                          icon={<GoInbox />}
                          title='No Product Found'
                          subtitle='Click the button below to add a new product'
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <CustomButton
            onClick={onToggle}
            icon={<IoIosAdd />}
            text='Add'
            className='mt-3 btn-add-product'
            color='aqua'
            variant='contained'
          />
        </>
      )}

      <Modal
        isOpen={isOpenModalCreate}
        toggle={toggleModalCreate}
        className='modal-create-product'
        backdrop='static'
      >
        <ModalHeader onClick={toggleModalCreate}>
          Create Product
          <IoIosClose size={30} className='float-end text-end ms-auto cursor-pointer' />
        </ModalHeader>
        <ModalBody>
          <FormCreateProduct toggle={toggleModalCreate} />
        </ModalBody>
      </Modal>

      <DialogBoxUpgradeSubscription isOpen={isOpenModalUpgrade} toggle={toggleModalUpgrade} />
    </ErrorBoundary>
  )
}

export default ListProduct

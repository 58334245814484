import CustomButton from '_components/CustomButton'
import InputField from '_components/InputField'
import {
  AVAILABLE_STOCK_DEFAULT,
  LIMIT_PER_ORDER_DEFAULT,
  MINIMUM_ORDER_PER_PRODUCT,
} from '_utils/constant'
import { IoMdCheckmark } from 'react-icons/io'
import { TiCancel } from 'react-icons/ti'
import { Modal, ModalHeader } from 'reactstrap'

const ProductSettingModal = (props) => {
  const { isOpen, toggle, register, productIndex, errors } = props

  const handleToggle = () => {
    if (errors && errors?.length > 0 && Object.entries(errors?.products).length !== 0) {
      return
    }
    toggle()
  }

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} centered className='product-setting'>
      <ModalHeader toggle={toggle}>Item Options</ModalHeader>
      <div className='container p-3'>
        <div className='c-form'>
          <div className='form-group'>
            <div className='row'>
              <label className='col-5 align-self-center'>Available stock</label>
              <div className='col-7'>
                <InputField
                  type='number'
                  register={register(`products.${productIndex}.maxQuantity`)}
                  defaultValue={AVAILABLE_STOCK_DEFAULT}
                  error={errors?.products?.[productIndex]?.maxQuantity?.message}
                />
              </div>
              <div className='w-100 mb-3'></div>
              <label className='col-5 align-self-center'>Limit per order</label>
              <div className='col-7'>
                <InputField
                  type='number'
                  register={register(`products.${productIndex}.limitPerOrder`)}
                  defaultValue={LIMIT_PER_ORDER_DEFAULT}
                  error={errors?.products?.[productIndex]?.limitPerOrder?.message}
                />
              </div>
              <div className='w-100 mb-3'></div>
              <label className='col-5 align-self-center'>Minimum per order</label>
              <div className='col-7'>
                <InputField
                  type='number'
                  register={register(`products.${productIndex}.minOrderQty`)}
                  defaultValue={MINIMUM_ORDER_PER_PRODUCT}
                  error={errors?.products?.[productIndex]?.minOrderQty?.message}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-center gap-3 mt-3'>
          <CustomButton
            text='Cancel'
            color='slate'
            className='w-50 mt-0'
            icon={<TiCancel />}
            onClick={toggle}
          />
          <CustomButton
            text='Ok'
            variant='contained'
            color='aqua'
            className='w-50 mt-0'
            icon={<IoMdCheckmark />}
            onClick={handleToggle}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ProductSettingModal

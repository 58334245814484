import CustomButton from '_components/CustomButton'
import ModalDeliveryZones from '_components/ModalDeliveryZones'
import NotFoundDataComponent from '_components/NotFoundDataComponent'
import OverlayPrompt from '_components/OverlayPrompt'
import { getListDeliveriesCreatedByShop } from '_redux/modules/deliveryEvent'
import { getListDelivery, getListPickup } from '_redux/modules/fulfillment'
import { PACKAGE_BASIC } from '_utils/constant'
import React, { useEffect, useMemo, useState } from 'react'
import { GoInbox } from 'react-icons/go'
import { MdFormatListBulletedAdd } from 'react-icons/md' // Add this import at the top
import { useDispatch, useSelector } from 'react-redux'
import DeliveryCard from './DeliveryCard'
import DeliveryCardSkeleton from './DeliveryCardSkeleton'

export default function ManageDeliveryZones() {
  const dispatch = useDispatch()

  const { currentPackage } = useSelector((state) => state.subscription)
  const { selectedShop } = useSelector((state) => state.shop)
  const { listDelivery } = useSelector((state) => state.fulfillment)
  const { listCreatedDeliveryZones, loadingGetListDeliverZonesCreated } = useSelector(
    (state) => state.deliveryEvent
  )

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [dataModal, setDataModal] = useState(null)

  const currentPackagePro =
    currentPackage !== (undefined || null) &&
    currentPackage.subscriptionPackageType !== PACKAGE_BASIC

  useEffect(() => {
    if (selectedShop?.id) {
      const fetchData = async () => {
        await Promise.all([
          dispatch(getListDelivery(selectedShop.id)),
          dispatch(getListPickup(selectedShop.id)),
          dispatch(getListDeliveriesCreatedByShop(selectedShop.id)),
        ])
      }

      fetchData()
    }
  }, [selectedShop?.id])

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal)
  }

  const onEditDeliveryZones = async (zone) => {
    setDataModal(zone)
    toggleModal()
  }

  const filteredListCreatedDeliveryZones = useMemo(() => {
    const filteredList = listCreatedDeliveryZones.filter((zone) => zone.shopId === selectedShop?.id)

    return filteredList
  }, [JSON.stringify(listCreatedDeliveryZones), selectedShop?.id])

  return (
    <div className='manage-delivery-zones px-3 pt-3'>
      <h3 className='text-orange mb-4 fw-bold title'>Manage Delivery Zones</h3>
      <CustomButton
        onClick={toggleModal}
        text='Add zones to Co-Bee'
        icon={<MdFormatListBulletedAdd />}
        className='mt-0 w-auto ms-auto mb-3'
      />

      {!currentPackagePro ? <OverlayPrompt shopSlug={selectedShop?.shopSlug} /> : null}

      {loadingGetListDeliverZonesCreated && (
        <div className='delivery-zones-list'>
          {[1, 2, 3, 4].map((index) => (
            <DeliveryCardSkeleton key={index} />
          ))}
        </div>
      )}

      {!loadingGetListDeliverZonesCreated && filteredListCreatedDeliveryZones.length === 0 && (
        <NotFoundDataComponent
          icon={<GoInbox />}
          title='No Delivery Zones Created'
          subtitle='Create your first delivery zone to start managing your delivery areas efficiently'
        />
      )}

      {!loadingGetListDeliverZonesCreated && filteredListCreatedDeliveryZones.length > 0 && (
        <div className='delivery-zones-list'>
          {filteredListCreatedDeliveryZones.map((zone, index) => (
            <DeliveryCard key={index} zone={zone} onEditDeliveryZones={onEditDeliveryZones} />
          ))}
        </div>
      )}

      <ModalDeliveryZones
        isOpen={isOpenModal}
        toggle={toggleModal}
        dataModal={dataModal}
        setDataModal={setDataModal}
        listDelivery={listDelivery}
      />
    </div>
  )
}

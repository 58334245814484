import { convertToCurrentGMT } from '_utils/functions/converter'
import { isEmpty } from 'lodash'
import React from 'react'
import CurrencyFormat from 'react-currency-format'
import { useSelector } from 'react-redux'

const ContactDetails = () => {
  // const shopDetail = getDataShopDetail()
  const { orderDetailsByRef } = useSelector((state) => state.order)
  const convertRangeTimeOrderToArray =
    orderDetailsByRef?.orderDelivery?.deliveryBookingDate?.time?.split('-') ||
    orderDetailsByRef?.orderPickup?.pickupBookingDate?.time?.split('-')

  const dateOrder =
    orderDetailsByRef?.orderDelivery?.deliveryBookingDate?.date ||
    orderDetailsByRef?.orderPickup?.pickupBookingDate?.date

  const convertRangeTimeOrderToCurrentGMT = convertRangeTimeOrderToArray
    ?.map((time) => {
      const currentTime = convertToCurrentGMT(`${dateOrder} ${time.trim()}`)

      return currentTime?.slice(11)
    })
    ?.join(' - ')

  return (
    <div className='contact-details__info'>
      <div className='contact-details__row row'>
        <div className='contact-details__label col-4'>
          <span>Name:</span>
        </div>
        <div className='contact-details__value col-8 text-break'>
          {orderDetailsByRef?.orderDelivery?.giftRecipientInfo?.name ||
            orderDetailsByRef?.orderDelivery?.uName ||
            orderDetailsByRef?.orderPickup?.uName ||
            orderDetailsByRef?.uName}
        </div>
      </div>
      <div className='contact-details__row row'>
        <div className='contact-details__label col-4'>
          <span>Email:</span>
        </div>
        <div className='contact-details__value col-8 text-break'>
          {orderDetailsByRef?.orderDelivery?.giftRecipientInfo?.email ||
            orderDetailsByRef?.orderDelivery?.uEmail ||
            orderDetailsByRef?.orderPickup?.uEmail ||
            orderDetailsByRef?.uEmail}
        </div>
      </div>
      <div className='contact-details__row row'>
        <div className='contact-details__label col-4'>
          <span>Phone:</span>
        </div>
        <div className='contact-details__value col-8 text-break'>
          {orderDetailsByRef?.orderDelivery?.giftRecipientInfo?.phone ||
            orderDetailsByRef?.orderDelivery?.uPhone ||
            orderDetailsByRef?.orderPickup?.uPhone ||
            orderDetailsByRef?.uPhone}
        </div>
      </div>
      {(!isEmpty(orderDetailsByRef?.orderPickup) || !isEmpty(orderDetailsByRef?.orderDelivery)) && (
        <>
          {orderDetailsByRef?.orderDelivery?.giftRecipientInfo?.address ||
          orderDetailsByRef?.orderDelivery?.uAddress ||
          orderDetailsByRef?.uAddress ? (
            <div className='contact-details__row row'>
              <div className='contact-details__label col-4'>
                <span>Address:</span>
              </div>
              <div className='contact-details__value col-8 text-break'>
                {orderDetailsByRef?.orderDelivery?.giftRecipientInfo?.address ||
                  orderDetailsByRef?.orderDelivery?.uAddress ||
                  orderDetailsByRef?.uAddress}
              </div>
            </div>
          ) : null}
        </>
      )}

      {!isEmpty(orderDetailsByRef?.orderDelivery) ? (
        <>
          <div className='contact-details__row row'>
            <div className='contact-details__label col-4'>
              <span>Delivery zone:</span>
            </div>
            <div className='contact-details__value col-8 text-break'>
              {orderDetailsByRef?.orderDelivery?.deliveryOptionName}

              <span className={'text-primary ms-1'}>
                (
                {orderDetailsByRef?.orderDelivery?.deliveryFee ? (
                  <CurrencyFormat
                    value={orderDetailsByRef?.orderDelivery?.deliveryFee || 0}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                ) : (
                  'Free'
                )}
                )
              </span>
            </div>
          </div>
          <div className='contact-details__row row'>
            <div className='contact-details__label col-4'>
              <span>Order datetime:</span>
            </div>
            <div className='contact-details__value col-8 text-break'>
              {orderDetailsByRef?.orderDelivery?.deliveryBookingDate?.date}, (
              {convertRangeTimeOrderToCurrentGMT})
            </div>
          </div>
        </>
      ) : null}

      {!isEmpty(orderDetailsByRef?.orderPickup) ? (
        <>
          <div className='contact-details__row row'>
            <div className='contact-details__label col-4'>
              <span>Pickup zone:</span>
            </div>
            <div className='contact-details__value col-8 text-break'>
              {orderDetailsByRef?.orderPickup?.pickupOptionName}
              {orderDetailsByRef?.orderPickup?.pickupAddress && (
                <span>, {orderDetailsByRef?.orderPickup?.pickupAddress}</span>
              )}
              <span className={'text-primary ms-1'}>
                (
                {orderDetailsByRef?.orderPickup?.pickupFee &&
                orderDetailsByRef?.orderPickup?.pickupFee > 0 ? (
                  <CurrencyFormat
                    value={orderDetailsByRef?.orderPickup?.pickupFee || 0}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                ) : (
                  'Free'
                )}
                )
              </span>
            </div>
          </div>
          <div className='contact-details__row row'>
            <div className='contact-details__label col-4'>
              <span>Date-time:</span>
            </div>
            <div className='contact-details__value col-8 text-break'>
              {orderDetailsByRef?.orderPickup?.pickupBookingDate?.date} - (
              {convertRangeTimeOrderToCurrentGMT})
            </div>
          </div>
        </>
      ) : null}
    </div>
  )
}

export default ContactDetails

/* eslint-disable func-names */
import * as yup from 'yup'

const productSchema = yup.object().shape({
  limitPerOrder: yup
    .string()
    .trim()
    .nullable()
    .required('Limit per order is required')
    .test('isValidNumber', 'Must be a valid number', (value) => {
      if (!value || value === '-') return false
      const num = Number(value)
      return !isNaN(num)
    })
    .test('isPositive', 'Must be greater than 0', (value) => {
      const num = Number(value)
      return num > 0
    })
    .test('isInteger', 'Must be a whole number', (value) => {
      const num = Number(value)
      return Number.isInteger(num)
    }),

  minOrderQty: yup
    .string()
    .trim()
    .nullable()
    .required('Min order quantity is required')
    .test('isValidNumber', 'Must be a valid number', (value) => {
      if (!value || value === '-') return false
      const num = Number(value)
      return !isNaN(num)
    })
    .test('isPositive', 'Must be greater than 0', (value) => {
      const num = Number(value)
      return num > 0
    })
    .test('isInteger', 'Must be a whole number', (value) => {
      const num = Number(value)
      return Number.isInteger(num)
    })
    .test(
      'lessThanLimitPerOrder',
      'Min order must be less than or equal limit per order',
      function (value) {
        const { limitPerOrder } = this.parent
        if (!value || !limitPerOrder) return true
        return Number(value) <= Number(limitPerOrder)
      }
    )
    .test(
      'lessThanAvailableStock',
      'Min order must be less than or equal available stock',
      function (value) {
        const { availableOfStock } = this.parent
        if (!value || !availableOfStock) return true
        return Number(value) <= Number(availableOfStock)
      }
    ),
  price: yup
    .number()
    .transform((value, originalValue) => {
      if (typeof originalValue === 'string') {
        const trimmedValue = originalValue.trim()
        if (trimmedValue === '' || trimmedValue === '-') {
          return undefined
        }
        const numberValue = Number(trimmedValue)
        return isNaN(numberValue) ? undefined : numberValue
      }
      return value
    })
    .positive('Price must be a positive number')
    .required('Price cannot be empty'),
  availableOfStock: yup
    .number()
    .transform((value, originalValue) => {
      if (typeof originalValue === 'string') {
        const trimmedValue = originalValue.trim()
        if (trimmedValue === '' || trimmedValue === '-') {
          return undefined
        }
        const numberValue = Number(trimmedValue)
        return isNaN(numberValue) ? undefined : numberValue
      }
      return value
    })
    .positive('Price must be a positive number')
    .required('Price cannot be empty'),
})

export default productSchema

import virtualShopApi from '_api/virtualShop'
import CustomButton from '_components/CustomButton'
import {
  selectShopAndSelectShopDetail,
  selectThemeColor,
  updateShopInMyShops,
} from '_redux/modules/shop'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { GrPowerReset } from 'react-icons/gr'
import { IoSaveOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Spinner } from 'reactstrap'
import './style.scss'

function ColorPicker() {
  const dispatch = useDispatch()
  const { selectedShop } = useSelector((state) => state.shop)
  const { addToast } = useToasts()
  const [colorsTheme, setColorsTheme] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const customsColorTheme = selectedShop?.shopColorTheme?.customsColorTheme
  const defaultColorTheme = selectedShop?.shopColorTheme?.defaultColorTheme

  useEffect(() => {
    if (customsColorTheme) {
      const convertColor = {
        headerTextColor: customsColorTheme.headerTextColor,
        backgroundColor: customsColorTheme.backgroundColor,
        generalTextColor: customsColorTheme.generalTextColor,
        priceColor: customsColorTheme.priceColor,
        titleItemColor: customsColorTheme.titleItemColor,
      }
      const convertObjectToArray = Object.keys(convertColor).map((key, index) => ({
        label: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1'), // Biến đổi tên thuộc tính thành chuỗi đọc được
        value: customsColorTheme[key],
        index,
      }))
      setColorsTheme(convertObjectToArray)
    } else {
      const mockDefaultColor = {
        headerTextColor: '#F06A0F',
        backgroundColor: '#f2f2f2',
        generalTextColor: '#15cdca',
        priceColor: '#ff6903',
        titleItemColor: '#51aaaa',
      }

      const convertColor = Object.keys(mockDefaultColor).map((key, index) => ({
        label: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1'), // Biến đở tên thuộc tính không chứa chữ thức
        value: mockDefaultColor[key],

        index,
      }))

      setColorsTheme(convertColor)
    }
  }, [customsColorTheme !== undefined])

  useEffect(() => {
    const timeoutDispatchColor = setTimeout(() => {
      dispatch(selectThemeColor(colorsTheme))
      dispatch(
        selectShopAndSelectShopDetail({
          ...selectedShop,
          shopColorTheme: {
            ...selectedShop?.shopColorTheme,
            customsColorTheme: {
              headerTextColor: colorsTheme[0].value,
              backgroundColor: colorsTheme[1].value,
              generalTextColor: colorsTheme[2].value,
              priceColor: colorsTheme[3].value,
              titleItemColor: colorsTheme[4].value,
            },
          },
        })
      )
      dispatch(
        updateShopInMyShops({
          ...selectedShop,
          shopColorTheme: {
            ...selectedShop?.shopColorTheme,
            customsColorTheme: {
              headerTextColor: colorsTheme[0].value,
              backgroundColor: colorsTheme[1].value,
              generalTextColor: colorsTheme[2].value,
              priceColor: colorsTheme[3].value,
              titleItemColor: colorsTheme[4].value,
            },
          },
        })
      )
    }, 700)

    return () => {
      clearTimeout(timeoutDispatchColor)
    }
  }, [JSON.stringify(colorsTheme)])

  const handleResetColor = () => {
    const convertColor = {
      headerTextColor: defaultColorTheme.headerTextColor,
      backgroundColor: defaultColorTheme.backgroundColor,
      generalTextColor: defaultColorTheme.generalTextColor,
      priceColor: defaultColorTheme.priceColor,
      titleItemColor: defaultColorTheme.titleItemColor,
    }
    const convertObjectToArray = Object.keys(convertColor).map((key, index) => ({
      label: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1'), // Biến đổi tên thuộc tính thành chuỗi đọc được
      value: defaultColorTheme[key],
      index,
    }))
    setColorsTheme(convertObjectToArray)
  }

  const handleChooseColor = (hexColor, index) => {
    const findIndex = colorsTheme.findIndex((item) => item.index === index)
    const updateColorsTheme = (prev, _findIndex, _index, _hexColor) => {
      const newColorsTheme = [...prev]
      newColorsTheme[_findIndex].value = prev[_index].value
      newColorsTheme[_index].value = _hexColor
      return newColorsTheme
    }

    if (findIndex !== -1) {
      setColorsTheme((prev) => updateColorsTheme(prev, findIndex, index, hexColor))
    }
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const postData = {
        ...selectedShop,
        customsColorTheme: {
          headerTextColor: colorsTheme[0].value,
          backgroundColor: colorsTheme[1].value,
          generalTextColor: colorsTheme[2].value,
          priceColor: colorsTheme[3].value,
          titleItemColor: colorsTheme[4].value,
        },
      }

      await virtualShopApi.updateShop(postData, selectedShop?.id)

      dispatch(
        selectShopAndSelectShopDetail({
          ...selectedShop,
          shopColorTheme: {
            customsColorTheme: {
              headerTextColor: colorsTheme[0].value,
              backgroundColor: colorsTheme[1].value,
              generalTextColor: colorsTheme[2].value,
              priceColor: colorsTheme[3].value,
              titleItemColor: colorsTheme[4].value,
            },
            defaultColorTheme: selectedShop?.shopColorTheme?.defaultColorTheme,
          },
        })
      )

      addToast('Updated successfully!', { appearance: 'success', autoDismiss: true })
    } catch (error) {
      addToast(`${error.msgResp}`, { appearance: 'error', autoDismiss: true })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='color-picker-container'>
      <div className='color-picker-body'>
        {!isEmpty(selectedShop) &&
          colorsTheme.map((colorTheme, index) => (
            <div className='color-picker-item' key={index}>
              <span className='color-picker-label'>{colorTheme.label}</span>
              <input
                className='color-picker-input'
                id={colorTheme.value}
                value={colorTheme.value}
                type='color'
                onChange={(e) => handleChooseColor(e.target.value, index)}
              />
            </div>
          ))}
      </div>
      <div className='color-picker-footer'>
        <CustomButton
          text='Reset to default'
          onClick={handleResetColor}
          color='slate'
          className='reset-button'
          icon={<GrPowerReset />}
        />
        <CustomButton
          variant='contained'
          onClick={handleSubmit}
          text={isLoading ? <Spinner size='sm' /> : 'Save'}
          className='save-button'
          color='aqua'
          disabled={isLoading}
          icon={<IoSaveOutline />}
        />
      </div>
    </div>
  )
}

export default ColorPicker

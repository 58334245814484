import InputField from '_components/InputField'
import { CheckoutContext } from '_contexts/CheckoutContext'
import { getCartCheckout } from '_utils/localData'
import { isEmpty } from 'lodash'
import React, { useContext, useEffect } from 'react'
import { AiFillGift } from 'react-icons/ai'
import { useSelector } from 'react-redux'

function FormGiftRecipient() {
  const { buyer, register, errors, setValue } = useContext(CheckoutContext)
  const cart = getCartCheckout()
  const { orderDetailsByRef } = useSelector((state) => state.order)

  useEffect(() => {
    if (!isEmpty(cart?.buyer?.giftInfo)) {
      setValue('giftInfo.name', cart.buyer.giftInfo.name)
      setValue('giftInfo.email', cart.buyer.giftInfo.email)
      setValue('giftInfo.phone', cart.buyer.giftInfo.phone)
      setValue('giftInfo.address', cart.buyer.giftInfo.address)
    }
  }, [JSON.stringify(cart)])

  useEffect(() => {
    if (!isEmpty(orderDetailsByRef?.giftRecipientInfo)) {
      setValue('giftInfo.name', orderDetailsByRef?.giftRecipientInfo.name)
      setValue('giftInfo.email', orderDetailsByRef?.giftRecipientInfo.email)
      setValue('giftInfo.phone', orderDetailsByRef?.giftRecipientInfo.phone)
      setValue('giftInfo.address', orderDetailsByRef?.giftRecipientInfo.address)
    }
  }, [JSON.stringify(orderDetailsByRef)])

  return (
    <div className='form-gift-recipient'>
      <div className='form-gift-recipient__ribbon'>
        <div className='form-gift-recipient__glow'>&nbsp;</div>
        Special
      </div>
      <p className='form-gift-recipient__title fw-semibold text-uppercase'>
        Gift Information Form{' '}
        <AiFillGift
          color={'#cc3333'}
          size={24}
          style={{
            transform: 'translateY(-5px)',
          }}
        />
      </p>
      <div className='form-gift-recipient__details'>
        <InputField
          type='text'
          placeholder='Full Name'
          defaultValue={buyer?.giftInfo?.name}
          register={register('giftInfo.name')}
          error={errors?.giftInfo?.name?.message}
          offset={{ bottom: 16 }}
        />
        {!isEmpty(buyer?.isGift) ? null : (
          <InputField
            type='email'
            placeholder='E-mail'
            defaultValue={buyer?.giftInfo?.email}
            register={register('giftInfo.email')}
            error={errors?.giftInfo?.email?.message}
            offset={{ bottom: 16 }}
          />
        )}
        <InputField
          type='text'
          placeholder='Mobile Number'
          defaultValue={buyer?.giftInfo?.phone}
          register={register('giftInfo.phone')}
          error={errors?.giftInfo?.phone?.message}
          offset={{ bottom: 16 }}
        />
        <InputField
          type='text'
          placeholder='Address'
          defaultValue={buyer?.giftInfo?.address}
          register={register('giftInfo.address')}
          error={errors?.giftInfo?.address?.message}
        />
      </div>
    </div>
  )
}

export default FormGiftRecipient

import lazadaApi from '_api/lazada'
import { ProductItemLazadaContext } from '_contexts/ProductItemLazadaContext'

import {
  clearProductLazadaSelected,
  selectAndToggleProductLazadaModal,
} from '_redux/modules/product'
import { PLATFORM_LAZADA } from '_utils/constant'
import { getDataShopDetail } from '_utils/localData'
import { capitalize, debounce } from 'lodash'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const ComponentInput = ({ type = '', register, setValue, ...props }) => {
  const typeNumber = ['price', 'quantity', 'availableOfStock', 'purchase', 'inventory']

  if (typeNumber.includes(type)) {
    return (
      <CurrencyFormat
        value={props.defaultValue || 0}
        placeholder={props.placeholder}
        displayType={'input'}
        thousandSeparator={true}
        className={props.className}
        onValueChange={(values) => {
          setValue(type, Number(values.value))
        }}
      />
    )
  }

  if (type === 'description') {
    return <textarea type='text' {...props} {...register(type?.replace(/\s+/g, '_'))} />
  }

  return <input type='text' {...props} {...register(type?.replace(/\s+/g, '_'))} />
}

function ModalEditProductLazada({ isOpen }) {
  if (!isOpen) return null

  const dispatch = useDispatch()
  const shop = getDataShopDetail()
  const inputRef = useRef(null)

  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [errorMessageAttribute, setErrorMessageAttribute] = useState('')
  const [listLazadaCategorySuggestions, setListLazadaCategorySuggestions] = useState([])

  const { register, watchProduct, setValue } = useContext(ProductItemLazadaContext)
  const { selectedShop } = useSelector((state) => state.shop)
  const {
    tabSelected,
    productLazadaSelected,
    productType,
    // brandByPages,
  } = useSelector((state) => state.product)

  if (!productLazadaSelected) return null

  useEffect(() => {
    if (typeof watchProduct?.price === 'number') {
      setValue('price', watchProduct.price)
    }
    if (productType === 'availableOfStock') {
      setValue('availableOfStock', watchProduct.availableOfStock)
    }
  }, [])

  useEffect(() => {
    if (!isLoading && inputRef.current) {
      inputRef.current?.focus()
    }
  }, [isLoading])

  const getList = async (shopId, productName) => {
    try {
      const postData = {
        shopId,
        productName,
      }
      const res = await lazadaApi.getLazadaCategorySuggestions(postData)
      setListLazadaCategorySuggestions(res.msgResp)
      setErrorMessage('')
    } catch (error) {
      setErrorMessage('Cannot get category from product name')
    }
  }

  useEffect(() => {
    let isMounted = true

    const fetchData = async () => {
      if (productType === 'name' && watchProduct?.name) {
        try {
          if (isMounted) setIsLoading(true)
          await getList(shop?.id, productLazadaSelected?.name)
        } catch (error) {
          if (isMounted) setErrorMessage('Cannot get category from product name')
        } finally {
          if (isMounted) setIsLoading(false)
        }
      }
    }

    if (isMounted) {
      fetchData()
    }

    return () => {
      isMounted = false

      if (isOpen === false) {
        dispatch(selectAndToggleProductLazadaModal(productLazadaSelected, productType))
      }
    }
  }, [productType, productLazadaSelected?.name, shop?.id, setValue, dispatch])

  useEffect(() => {
    let isMounted = true
    if (
      isMounted &&
      listLazadaCategorySuggestions.length > 0 &&
      !productLazadaSelected.categoryId
    ) {
      setValue('categoryId', String(listLazadaCategorySuggestions[0]?.categoryId))
    }

    return () => {
      isMounted = false
    }
  }, [listLazadaCategorySuggestions.length, productLazadaSelected.categoryId, setValue])

  useEffect(() => {
    let isMounted = true

    if (selectedShop?.id && watchProduct?.categoryId) {
      const checkAttribute = async () => {
        try {
          await lazadaApi.checkAttributeProduct(selectedShop?.id, watchProduct?.categoryId)
          if (isMounted) {
            setErrorMessageAttribute('')
          }
        } catch (error) {
          if (isMounted) {
            setErrorMessageAttribute(error.msgResp)
          }
        }
      }

      checkAttribute()
    }

    return () => {
      isMounted = false // Set flag to false when the component unmounts
    }
  }, [watchProduct?.categoryId, selectedShop?.id])

  useEffect(() => {
    if (listLazadaCategorySuggestions.length) {
      const categoryExists = listLazadaCategorySuggestions.some(
        (item) => Number(item.categoryId) === Number(watchProduct.categoryId)
      )
      if (!categoryExists) {
        setValue('categoryId', null)
      }
    }
  }, [watchProduct?.categoryId, JSON.stringify(listLazadaCategorySuggestions), setValue])

  const debouncedSetLoading = useCallback(
    debounce(() => {
      setIsLoading(true)
    }, 1000),
    []
  )

  const printValue = useCallback(
    debounce(async (e) => {
      await getList(shop?.id, e.target.value)
      setIsLoading(false)
    }, 1000),
    []
  )

  const onChangeNameProduct = (e) => {
    printValue(e)
    debouncedSetLoading()
  }

  const { ref, ...restRegister } = register('name', {
    onChange: (e) => onChangeNameProduct(e),
  })

  const onClearAndToggleModal = () => {
    dispatch(selectAndToggleProductLazadaModal(productLazadaSelected, productType))
    dispatch(clearProductLazadaSelected())
  }

  return (
    <Modal isOpen={isOpen} toggle={onClearAndToggleModal} className='modal-edit-product'>
      <form onSubmit={onClearAndToggleModal}>
        <ModalHeader toggle={onClearAndToggleModal}>
          Edit {productType === 'availableOfStock' ? 'Inventory' : capitalize(productType)} Lazada
        </ModalHeader>
        <ModalBody className='f-16'>
          {errorMessage && <div className='text-danger'>{errorMessage}</div>}

          {errorMessageAttribute && <div className='text-danger'>{errorMessageAttribute}</div>}

          {productType !== 'name' && (
            <div className='form-group'>
              <label htmlFor={productType} className='mb-1'>
                {capitalize(productType) === 'Price'
                  ? `${capitalize(productType)} $`
                  : productType === 'availableOfStock'
                  ? 'Inventory'
                  : capitalize(productType)}
                :
              </label>
              <ComponentInput
                type={productType}
                placeholder={`Enter your ${
                  productType === 'availableOfStock' ? 'Inventory' : capitalize(productType)
                }`}
                className='form-control-sm border w-100 f-16'
                defaultValue={watchProduct[productType]}
                register={register}
                setValue={setValue}
              />
            </div>
          )}

          {productType === 'name' ? (
            <>
              <div className='form-group'>
                <label htmlFor={productType} className='mb-1'>
                  {capitalize(productType)}:
                </label>

                <input
                  type='text'
                  readOnly={isLoading}
                  disabled={isLoading}
                  placeholder={`Enter your ${capitalize(productType)}`}
                  className='form-control-sm border w-100 f-16'
                  defaultValue={watchProduct.name}
                  {...restRegister}
                  ref={(e) => {
                    ref(e)
                    inputRef.current = e
                  }}
                />
              </div>
              {tabSelected === PLATFORM_LAZADA ? (
                <>
                  <div className='list-category my-3'>
                    <label htmlFor='category' className='mb-1'>
                      Category:
                    </label>
                    <ul className='list-unstyled rounded bg-body-secondary p-2'>
                      {isLoading && 'Loading...'}
                      {!isLoading && !errorMessage && listLazadaCategorySuggestions.length
                        ? listLazadaCategorySuggestions.map((item) => (
                            <li key={item.categoryId}>
                              <div className='form-check'>
                                <input
                                  className='form-check-input cursor-pointer'
                                  type='radio'
                                  name={`${watchProduct.name}-${item.categoryId}`}
                                  id={`${watchProduct.name}-${item.categoryId}`}
                                  value={item.categoryId}
                                  defaultChecked={
                                    Number(productLazadaSelected.categoryId) === item.categoryId
                                  }
                                  {...register('categoryId')}
                                />
                                <label
                                  className='form-check-label cursor-pointer'
                                  htmlFor={`${watchProduct.name}-${item.categoryId}`}
                                >
                                  {item.categoryName}
                                </label>
                              </div>
                            </li>
                          ))
                        : null}
                      {!isLoading && errorMessage ? (
                        <li>
                          <span>Not Found Category</span>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </>
              ) : null}
            </>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <button
            type='submit'
            className='btn btn-submit w-auto shadow-none rounded-pill'
            disabled={
              isLoading || !watchProduct.categoryId || errorMessage || errorMessageAttribute
            }
          >
            Ok
          </button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default ModalEditProductLazada

import React, { useEffect, useState } from 'react'
import { LIST_TIME_2 } from '_utils/constant'

function OpeningHours({ setHours, hours }) {
  const [hourStart, setHourStart] = useState(hours.hourStart)
  const [hourEnd, setHourEnd] = useState(hours.hourEnd)

  useEffect(() => {
    setHours({
      ...hours,
      hourStart,
    })
  }, [hourStart])

  useEffect(() => {
    setHours({
      ...hours,
      hourEnd,
    })
  }, [hourEnd])

  return (
    <div
      className='group-hour-delivery d-flex align-items-center justify-content-between flex-wrap mb-3'
      style={{
        gap: '0 1rem',
      }}
    >
      <p className='w-100 mb-1 text-start' style={{ fontSize: 16 }}>
        Opening Hours
      </p>
      <div className='row w-100'>
        <div className='col-6'>
          <div className='input-delivery-hour flex-fill'>
            <select
              name='timer-start-delivery'
              id='timer-start-delivery'
              value={hourStart}
              className='shadow-sm border-0 py-1 px-2 rounded w-100'
              onChange={(e) => setHourStart(e.target.value)}
            >
              {LIST_TIME_2.map((item, index) => (
                <option key={index}>{item.text}</option>
              ))}
            </select>
          </div>
        </div>

        <div className='col-6'>
          <div className='input-delivery-hour text-center flex-fill'>
            <select
              name='timer-end-delivery'
              id='timer-end-delivery'
              value={hourEnd}
              className='py-1 px-2 rounded shadow-sm border-0 w-100'
              onChange={(e) => setHourEnd(e.target.value)}
            >
              {LIST_TIME_2.map((item, index) => (
                <option key={index}>{item.text}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OpeningHours

import RenderSubItems from '_components/RenderSubItems'
import { isEmpty } from 'lodash'
import React, { Fragment } from 'react'
import CurrencyFormat from 'react-currency-format'

function RenderListSingle({ item, truncate, index }) {
  const { name, priceNoSubItems, quantity, subItems } = item

  const calculateTotalPrice = () => {
    const totalSubItemsPrice = subItems?.length
      ? subItems.reduce((acc, curr) => acc + (curr.price || 0), 0)
      : 0

    const totalPrice = (priceNoSubItems + totalSubItemsPrice) * quantity

    return totalPrice || 0
  }

  return (
    <>
      {quantity > 0 && (
        <div className='bill__row row'>
          <div className='bill__product-name col-4'>
            <span className='me-2'>{index + 1}.</span>
            <span className='bill__text-break text-break'>
              {truncate(name, { length: 40, omission: '...' })}
            </span>
            <RenderSubItems subItems={subItems} />
          </div>
          <div className='bill__product-price col-2'>
            {item?.priceNoSubItems > 0 ? (
              <CurrencyFormat
                className='bill__price'
                value={truncate(item?.priceNoSubItems?.toLocaleString(), { length: 15 })}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            ) : null}
          </div>
          <div className='bill__product-quantity col-2'>QTY: {quantity}</div>
          <div className='bill__product-total-price col-auto text-end text-break'>
            <CurrencyFormat
              value={calculateTotalPrice()}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix='$'
              suffix=' SGD'
              className='fw-bold'
            />
          </div>
        </div>
      )}
    </>
  )
}

const Bill = ({ cart, totalPrice, subTotal, orderDetailsByRef, truncate }) => (
  <div className='bill__container'>
    <div className='bill'>
      {cart?.map((item, i) => (
        <Fragment key={i}>
          <RenderListSingle item={item} truncate={truncate} index={i} />
        </Fragment>
      ))}
      <hr />

      <div className='bill__total-price total-price text-black f-14'>
        <div className='bill__row row text-secondary'>
          <div className='bill__col col-12 text-end'>
            <div className='bill__subtotal-row row mb-1'>
              <div className='bill__subtotal-label col-6 text-start'>Sub-Total:</div>
              <div className='bill__subtotal-value col-6'>
                {subTotal === 0 ? (
                  'Free'
                ) : (
                  <>
                    <CurrencyFormat
                      value={subTotal || 0}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix='$'
                      suffix=' SGD'
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </>
                )}
              </div>
            </div>
            {!isEmpty(orderDetailsByRef?.orderDelivery) &&
              orderDetailsByRef?.orderDelivery?.deliveryFee > 0 && (
                <div className='bill__delivery-fee-row row mb-1'>
                  <div className='bill__delivery-fee-label col-6 text-start'>Delivery Fee:</div>
                  <div className='bill__delivery-fee-value col-6'>
                    <>
                      <CurrencyFormat
                        value={orderDetailsByRef?.orderDelivery?.deliveryFee || 0}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix='$'
                        suffix=' SGD'
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </>
                  </div>
                </div>
              )}
            {!isEmpty(orderDetailsByRef?.orderPickup) &&
              orderDetailsByRef?.orderPickup?.pickupFee > 0 && (
                <div className='bill__pickup-fee-row row mb-1'>
                  <div className='bill__pickup-fee-label col-6 text-start'>Pick-up Fee:</div>
                  <div className='bill__pickup-fee-value col-6'>
                    <>
                      <CurrencyFormat
                        value={orderDetailsByRef?.orderPickup?.pickupFee || 0}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix='$'
                        suffix=' SGD'
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </>
                  </div>
                </div>
              )}
            {orderDetailsByRef?.transactionFees && (
              <div className='bill__transaction-fee-label'>
                This order will add transaction fee:{' '}
                <CurrencyFormat
                  value={orderDetailsByRef?.transactionFees || 0}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix='$'
                  suffix=' SGD'
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </div>
            )}
          </div>
        </div>
        <hr />
        <div className='bill__total-row row fw-bold' style={{ color: '#15cdca' }}>
          <div className='bill__total-label col-6'>Total:</div>
          <div className='bill__total-value col-6 text-end'>
            <CurrencyFormat
              value={totalPrice || 0}
              displayType={'text'}
              thousandSeparator={true}
              prefix='$'
              suffix=' SGD'
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Bill

import InputField from '_components/InputField'
import React from 'react'
import { Controller } from 'react-hook-form'

function DeliveryTimeSlot({
  checkedTimeSlot,
  handleAddTimeSlot,
  handleCheckTimeSlot,
  index,
  control,
}) {
  return (
    <div className='delivery-time-slot row align-items-center'>
      <div className='delivery-time-slot__label col-6'>
        <span className='delivery-time-slot__text' style={{ fontSize: 14 }} >Add Time Slot</span>
      </div>
      <div className='delivery-time-slot__input col-6 d-flex justify-content-end'>
        <InputField
          type='checkbox'
          id='deliveryFee'
          checked={checkedTimeSlot}
          onChange={(e) => handleAddTimeSlot(e)}
          isSwitch
        />
      </div>
      {checkedTimeSlot && (
        <>
          <div className='w-100'></div>
          <div className='delivery-time-slot__options col-12 col-md-8 mx-auto'>
            <Controller
              name='timeSlots'
              control={control}
              render={({ field: { value, onChange } }) =>
                value.map((time, i) => (
                  <InputField
                    key={i}
                    isRadio={true}
                    id={`radioDelivery-${index}-${i}`}
                    checked={time.isCheck}
                    label={time.title}
                    onChange={(e) => handleCheckTimeSlot(e, i, onChange)}
                    type='radio'
                  />
                ))
              }
            />
          </div>
        </>
      )}
    </div>
  )
}

export default DeliveryTimeSlot

import CustomSelectBox from '_components/CustomSelectBox'
import React from 'react'
import '../style.scss'

const TIME_OPTIONS = [
  { value: 'weeks', label: 'Weeks' },
  { value: 'months', label: 'Months' },
  { value: 'years', label: 'Years' },
]

function TimeFilterSelect({ value, onChange }) {
  return (
    <CustomSelectBox
      options={TIME_OPTIONS}
      value={value}
      onChange={(option) => onChange({ target: { value: option.value } })}
      className='time-filter-select'
    />
  )
}

export default TimeFilterSelect

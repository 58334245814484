import CustomButton from '_components/CustomButton'
import { convertTimeStringWithDuration } from '_utils/functions/converter'
import React, { useMemo } from 'react'
import { IoMdCheckmark } from 'react-icons/io'
import { TiCancel } from 'react-icons/ti'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const BoxForOrder = ({ order }) => (
  <div style={{ border: '1px solid', position: 'relative', margin: '15px 0' }}>
    <div
      className='truncate'
      style={{
        transform: 'translate(12px, -18px)',
        background: 'white',
        width: 'fit-content',
      }}
    >
      <span style={{ color: '#0BADAD' }}>[Order No: {order.eOrderNo}] </span>
      <span>{order.eTitle}</span>
    </div>
    <div style={{ marginTop: 10, padding: '10px 13px', fontSize: '0.9rem' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>Admin Fee</div>
        <div>${order?.adminCostPerOrder?.toFixed(2)}</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>Delivery Fee</div>
        <div>${order?.deliveryPerOrder?.toFixed(2)}</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>Discount Fee</div>
        <div>${order?.discountPerOrder?.toFixed(2)}</div>
      </div>
      {order?.allProductPerOrder.map((item, _index) => (
        <React.Fragment key={_index}>
          <div>
            <span>{item.pName} </span>
            {item.pSubItems && item.pSubItems.length > 0 ? (
              <>
                (
                {item.pSubItems.map((_item, idex) => (
                  <span key={idex}>
                    {_item.name}
                    {idex === item.pSubItems.length - 1 ? '' : ', '}
                  </span>
                ))}
                )
              </>
            ) : null}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>x{item.pQuantity}</div>
            <div>${Number(item.pPrice * item.pQuantity).toFixed(2)}</div>
          </div>
        </React.Fragment>
      ))}
    </div>
  </div>
)

const BoxForDelivery = ({ delivery }) => (
  <div style={{ border: '1px solid', position: 'relative', margin: '15px 0' }}>
    <div
      className='truncate'
      style={{
        transform: 'translate(12px, -18px)',
        background: 'white',
        width: 'fit-content',
      }}
    >
      <span>
        Delivery for (
        {convertTimeStringWithDuration(delivery.dDeliveryTime, delivery.dDeliveryDuration)})
      </span>
    </div>
    <div style={{ marginTop: 10, padding: '10px 13px', fontSize: '0.9rem' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>Delivery Personal Fee</div>
        <div>${delivery.dzPrice?.toFixed(2)}</div>
      </div>
    </div>

    <div style={{ borderTop: '1px solid', fontSize: '0.9rem' }}>
      <div style={{ padding: '10px 13px' }}>
        {`Location: ${delivery.dzName}, address: ${delivery.address}`}
      </div>
    </div>
  </div>
)

export default function ModalEventPaymentScreen({
  data,
  modalPaymentScreen,
  togglePaymentScreen,
  handleInvalidPayment,
}) {
  const totalPay = useMemo(() => {
    let price = 0
    if (data.listCombinedOrder && data.listCombinedOrder.length) {
      data.listCombinedOrder.forEach((el) => {
        price += el.totalFee
      })
    }
    if (data.listCombinedDelivery && data.listCombinedDelivery.length) {
      data.listCombinedDelivery.forEach((el) => {
        price += el.dzPrice
      })
    }
    return price
  }, [JSON.stringify(data)])

  return (
    <Modal
      isOpen={modalPaymentScreen}
      toggle={togglePaymentScreen}
      scrollable={true}
      centered
      size='xl'
      className='modal-event-payment'
    >
      <ModalHeader toggle={togglePaymentScreen}>
        <span className='text-orange'>{data.uName}</span>
        <br />
        <span className='text-orange'>
          {' '}
          Payment made for $
          {data && data.totalFee >= 0 ? data?.totalFee?.toFixed(2) : data?.dzPrice?.toFixed(2)}
        </span>
      </ModalHeader>
      <ModalBody className='d-flex flex-column'>
        {data.paymentScreenshot && (
          <img className='mb-2 w-100 mx-auto' src={data.paymentScreenshot} alt='' />
        )}
        {(data.listCombinedOrder && !!data.listCombinedOrder.length) ||
        (data.listCombinedDelivery && !!data.listCombinedDelivery.length) ? (
          <div className='d-flex flex-column list-order-combined-ref' style={{ fontSize: '1rem' }}>
            {data.listCombinedDelivery.map((delivery, index) => (
              <BoxForDelivery key={index} delivery={delivery} />
            ))}
            {data.listCombinedOrder.map((order, idex) => (
              <BoxForOrder key={idex} order={order} />
            ))}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '1.2rem',
                color: '#ff6903',
              }}
            >
              <div>Total</div>
              <div>${totalPay.toFixed(2)}</div>
            </div>
          </div>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <div className='col-12 px-0 m-0'>
          <CustomButton
            text='OK'
            variant='contained'
            color='aqua'
            icon={<IoMdCheckmark />}
            onClick={togglePaymentScreen}
            className='mt-0'
          />
        </div>
        <div className='col-12 px-0 m-0'>
          <CustomButton
            text='If invalid payment, click here'
            variant='text'
            color='slate'
            icon={<TiCancel />}
            // className='mt-0'
            onClick={() =>
              handleInvalidPayment({
                ref: data.ref,
                combinedPaymentRef: data.combinedPaymentRef,
                eid: data.eid,
              })
            }
          />
        </div>
      </ModalFooter>
    </Modal>
  )
}

import CustomButton from '_components/CustomButton'
import InputField from '_components/InputField'
import { updateStatusOrderLazada } from '_redux/modules/order'
import { ORDER_CANCELED_STATUS } from '_utils/constant'
import React, { useState } from 'react'
import { IoMdCheckmark } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

function ModalGetReasonCancel({
  isOpen,
  toggle,
  listReasonCancel,
  selectedOrderProduct,
  itemSelected,
}) {
  const dispatch = useDispatch()
  const { selectedShop } = useSelector((state) => state.shop)
  const [selectedReason, setSelectedReason] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { addToast } = useToasts()

  const onSelectReason = (reasonId) => {
    setSelectedReason(reasonId)
  }

  const submit = async () => {
    try {
      setIsSubmitting(true)
      await dispatch(
        updateStatusOrderLazada(
          itemSelected,
          selectedOrderProduct,
          ORDER_CANCELED_STATUS,
          selectedShop?.id,
          selectedReason
        )
      )
      addToast('Cancel successfully', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (error) {
      addToast(error.message || error.msgResp, {
        appearance: 'error',
        autoDismiss: true,
      })
    } finally {
      setIsSubmitting(false)
      toggle()
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} className='modal-reason-cancel'>
      <ModalHeader toggle={toggle}>Reason Cancel</ModalHeader>
      <ModalBody>
        <div className='list-reason-cancel'>
          {listReasonCancel.map((reason) => (
            <InputField
              key={reason.reason_id}
              type='radio'
              name={reason.reason_name}
              id={reason.reason_id}
              label={reason.reason_name}
              checked={reason.reason_id === selectedReason?.reason_id}
              onChange={() => onSelectReason(reason)}
              isRadio
            />
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={isSubmitting ? 'Processing...' : 'Proceed to cancel'}
          variant='contained'
          color='aqua'
          icon={<IoMdCheckmark />}
          onClick={submit}
          isDisabled={isSubmitting}
          className='mt-0'
        />
      </ModalFooter>
    </Modal>
  )
}

export default ModalGetReasonCancel

import { useWindowSize } from '@react-hook/window-size'
import { CollapseContext } from '_contexts/CollapseMenuContext'
import ShopDetail from '_pages/VirtualShop/ShopDetail'
import React, { useContext } from 'react'
import Footer from '../components/Footer'

function ShopFront() {
  const { isCollapse } = useContext(CollapseContext)
  const [width] = useWindowSize()

  const footerWidth = width <= 768
    ? '100%'
    : !isCollapse
      ? '79.8%'
      : 'calc(100% - 8.3333333333%)'

  return (
    <div className='managed-shop-front'>
      <>
        <ShopDetail />
        <Footer
          style={{
            width: footerWidth,
            position: 'fixed',
            left: 'inherit',
          }}
        />
      </>
    </div>
  )
}

export default ShopFront

/* eslint-disable max-len */
import axiosClient from './axiosClient'

const virtualShopApi = {
  updateShop: (data, id) => {
    const url = `/update-shop?id=${id}`
    return axiosClient.patch(url, data)
  },
  createVirtualShop: (data) => {
    const url = '/create-shop'
    return axiosClient.post(url, data)
  },
  getAllShopsOpening: () => {
    const url = '/get-shop-opening'
    return axiosClient.get(url)
  },
  getShopDetail: ({ shopIdParam, isGetProducts = true }) => {
    if (!shopIdParam) return null
    const url = `/get-shop-detail?id=${shopIdParam}${
      isGetProducts === true ? '&getProducts=1' : ''
    }`
    return axiosClient.get(url)
  },
  getDeliveryOption: (shopId) => {
    const url = `/list-delivery-option?shopId=${shopId}`
    return axiosClient.get(url)
  },
  getPickUpOption: (shopId) => {
    const url = `/list-pick-up-option?shopId=${shopId}`
    return axiosClient.get(url)
  },
  createOrderShopWithFulfillment: (data, userToken) => {
    if (!userToken) {
      const url = '/create-shop-order-with-fulfillment-no-login'
      return axiosClient.post(url, data)
    }

    const url = '/create-shop-order-with-fulfillment'
    return axiosClient.post(url, data)
  },
  updateShopOrderBuyerStatus: (data, ref, userToken) => {
    if (userToken) {
      const url = `/update-shop-order-buyer-status?ref=${ref}`
      return axiosClient.patch(url, data)
    }
    const url = `/update-shop-order-buyer-status-no-login?ref=${ref}`
    return axiosClient.patch(url, data)
  },
  createDeliveryOption: (shopId, data) => {
    const url = `/create-delivery-option?shopId=${shopId}`
    return axiosClient.post(url, data)
  },
  updateDeliveryOption: (id, data) => {
    const url = `/update-delivery-option?id=${id}`
    return axiosClient.patch(url, data)
  },
  createPickUpOption: (shopId, data) => {
    const url = `/create-pick-up-option?shopId=${shopId}`
    return axiosClient.post(url, data)
  },
  updatePickUpOption: (id, data) => {
    const url = `/update-pick-up-option?id=${id}`
    return axiosClient.patch(url, data)
  },
  deleteDeliveryOption: (id) => {
    const url = `/delete-delivery-option?id=${id}`
    return axiosClient.delete(url)
  },
  deletePickUpOption: (id) => {
    const url = `/delete-pick-up-option?id=${id}`
    return axiosClient.delete(url)
  },
  createShopCategory: (shopId, data) => {
    const url = `/create-shop-category?shopId=${shopId}`
    return axiosClient.post(url, data)
  },
  getShopCategory: (shopId) => {
    const url = `/get-shop-category?id=${shopId}`
    return axiosClient.get(url)
  },
  getShopByShopSlug: (shopSlug, userId) => {
    let url
    if (userId) {
      url = `/get-shop-by-shop-slug?shopSlug=${shopSlug}&getProducts=1&getSubscriptions=1&userId=${userId}`
    } else {
      url = `/get-shop-by-shop-slug?shopSlug=${shopSlug}&getProducts=1`
    }
    return axiosClient.get(url)
  },
  updateShopTransactionFees: ({ shopId, data }) => {
    const url = `/update-shop?id=${shopId}`
    return axiosClient.patch(url, data)
  },
  updateShopCashOnDeliveryPickup: ({ shopId, data }) => {
    const url = `/update-shop?id=${shopId}`
    return axiosClient.patch(url, data)
  },
  updateGiftRecipient: ({ shopId, data }) => {
    const url = `/update-shop?id=${shopId}`
    return axiosClient.patch(url, data)
  },
  updateDateSelection: ({ shopId, data }) => {
    const url = `/update-shop?id=${shopId}`
    return axiosClient.patch(url, data)
  },
  createSubscriptionPaymentStatus: (shopId, data) => {
    const url = `/create-shop-subscription?shopId=${shopId}`
    return axiosClient.post(url, data)
  },
  updateSubscriptionPaymentStatus: (subscriptionId, data) => {
    const url = `/update-subscription-payment-status?id=${subscriptionId}`
    return axiosClient.patch(url, data)
  },
  getListShopSubscriptionByShop: (shopId) => {
    const url = `/list-shop-subscription-by-shop?shopId=${shopId}`
    return axiosClient.get(url)
  },
  createShopSubscription: (shopId, data) => {
    const url = `/create-shop-subscription?shopId=${shopId}`
    return axiosClient.post(url, data)
  },
  getMyShops: () => {
    const url = '/list-shops-by-host?getProducts=1'
    return axiosClient.get(url)
  },
  updateShopNoPickupAndDelivery: (shopId, data) => {
    const url = `/update-shop-fulfillment-status?shopId=${shopId}`
    return axiosClient.patch(url, data)
  },
  updateColorScheme: (shopId, colors) => {
    const url = `/update-color-scheme?shopId=${shopId}`
    return axiosClient.patch(url, colors)
  },
  deleteShop: (shopId) => {
    const url = `delete-shop?shopId=${shopId}`
    return axiosClient.post(url)
  },
  getListShopsDeleted: () => {
    const url = 'list-shop-hidden-by-host?getProducts=1'
    return axiosClient.get(url)
  },
  restoreShop: (shopId) => {
    const url = `restore-shop?shopId=${shopId}`
    return axiosClient.post(url)
  },
  getShopDetailByHost: (shopId) => {
    const url = 'get-shop-detail-by-host'
    return axiosClient.get(url, { params: { id: shopId } })
  },
  updateOrderStatusByBuyer: (data) => {
    const url = 'update-status-order-by-buyer'
    return axiosClient.patch(url, data)
  },
  integrateTelegram: () => {
    const url = 'integrate-telegram'
    return axiosClient.get(url)
  }
}

export default virtualShopApi

import React from 'react'
import Skeleton from 'react-loading-skeleton'

const DeliveryCardSkeleton = () => (
  <div className='cardContainer'>
    <div className='card-header'>
      <div className='d-flex align-items-start gap-2'>
        <Skeleton circle width={45} height={45} />
        <div className='info'>
          <Skeleton width={120} />
          <Skeleton width={80} />
        </div>
      </div>
      <div className='deliveryStatus'>
        <Skeleton width={30} height={30} />
        <Skeleton width={100} />
      </div>
    </div>
    <div className='body'>
      <Skeleton width='100%' height={40} />
    </div>
  </div>
)

export default DeliveryCardSkeleton

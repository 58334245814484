import React from 'react'
import ProductItemSkeleton from './ProductItemSkeleton'

function ListProductSkeleton() {
  return (
    <div className='list-product mt-3 position-relative border-start border-end border-bottom'>
      <table className='table'>
        <tbody>
          {Array(5)
            .fill(0)
            .map((_, index) => (
              <ProductItemSkeleton key={index} />
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default ListProductSkeleton

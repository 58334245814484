import InputField from '_components/InputField'
import React from 'react'

function DeliveryInstruction({
  checkedInstruction,
  handleChangeInstruction,
  instructions,
  register,
  errors,
}) {
  return (
    <div className='row align-items-center mb-3'>
      <div className='col-6'>
        <span className='d-inline-block' style={{ fontSize: 14 }} >Add Instruction</span>
      </div>
      <div className='col-6 d-flex justify-content-end'>
        <InputField
          type='checkbox'
          offset={{ left: 'auto' }}
          id='deliveryInstruction'
          checked={checkedInstruction}
          onChange={(e) => handleChangeInstruction(e)}
          isSwitch
          width='auto'
        />
      </div>
      {checkedInstruction && (
        <>
          <div className='w-100'></div>
          <div className='col-12 mt-2'>
            <InputField
              type='text'
              as={'textarea'}
              placeholder='Please enter your instruction'
              register={register('instruction')}
              defaultValue={instructions}
            />
            <small className='errorMessage'>{errors?.instruction?.message}</small>
          </div>
        </>
      )}
    </div>
  )
}

export default DeliveryInstruction

import React, { Fragment, useState } from 'react'
import { DropdownItem, Tooltip } from 'reactstrap'
import { convertTimeStringWithDuration } from '../utils/functions/converter'
import TruncatedText from './TruncatedText'

export default function TooltipDropdownItem({
  event,
  deliveryEvent,
  tabOrderEvent,
  handleSelectEvent,
  groupedDeliveryEvents,
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const textMaxLength = groupedDeliveryEvents?.length >= 5 ? 30 : 50

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen)

  const data = event || deliveryEvent
  const { id } = data

  const renderTitleDeliveryEvent = () => {
    if (deliveryEvent) {
      return `Delivery for (${convertTimeStringWithDuration(
        data.deliveryTime,
        data.deliveryDuration
      )})`
    }
    return null
  }

  const title = event ? event.title : renderTitleDeliveryEvent()

  return (
    <Fragment>
      <DropdownItem
        onClick={() => handleSelectEvent(id, title)}
        active={tabOrderEvent === id}
        id={`tooltip-${id}`}
        className='px-2'
      >
        {/* {truncate(title, { length: textMaxLength })} */}
        <TruncatedText text={title} id={id} />
      </DropdownItem>
      {title.length > textMaxLength && (
        <Tooltip
          placement='right'
          isOpen={tooltipOpen}
          target={`tooltip-${id}`}
          toggle={toggleTooltip}
          className='custom-tooltip'
          autohide={false}
        >
          {title}
        </Tooltip>
      )}
    </Fragment>
  )
}

import CustomButton from '_components/CustomButton'
import { DeliveryContext } from '_contexts/DeliveryContext'
import classNames from 'classnames'
import { useContext } from 'react'
import { PiExportBold } from 'react-icons/pi'

const DeliveryExport = ({ className }) => {
  const { filteredOrdersFulfillment, handleExport } = useContext(DeliveryContext)
  return (
    <CustomButton
      icon={<PiExportBold />}
      className={classNames('mt-0', className)}
      onClick={() => handleExport(filteredOrdersFulfillment)}
      text='Export'
    />
  )
}

export default DeliveryExport

import CustomButton from '_components/CustomButton'
import { OrderRecordContext } from '_contexts/OrderRecordContext'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { DateRange } from 'react-date-range'
import { IoMdCheckmark } from 'react-icons/io'
import { TiCancel } from 'react-icons/ti'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

function FulfillmentDate() {
  const [isModal, setIsModal] = useState(false)
  const [rangeDate, setRangeDate] = useState([
    {
      startDate: moment().startOf('month').toDate(),
      endDate: moment().endOf('month').toDate(),
      key: 'selection',
    },
  ])

  const { onSelectRangeFulfillDate, filter } = useContext(OrderRecordContext)

  useEffect(() => {
    if (
      filter.fulfillmentDate[0].startDate === null &&
      filter.fulfillmentDate[0].endDate === null
    ) {
      setRangeDate([
        {
          startDate: moment().startOf('month').toDate(),
          endDate: moment().endOf('month').toDate(),
          key: 'selection',
        },
      ])
    }
  }, [JSON.stringify(filter.fulfillmentDate)])

  const formatDate = (date) => {
    const { startDate, endDate, key } = date[0]
    const newStartDate = new Date(startDate?.setHours(0, 0, 0, 0))
    const newEndDate = new Date(endDate?.setHours(23, 59, 59, 999))

    return [
      {
        startDate: newStartDate,
        endDate: newEndDate,
        key,
      },
    ]
  }

  const toggleModal = () => {
    setIsModal(!isModal)
  }

  const handleSubmit = () => {
    toggleModal()
    onSelectRangeFulfillDate(formatDate(rangeDate))
  }

  const handleCancel = () => {
    toggleModal()
  }

  return (
    <>
      <CustomButton
        text='Fulfillment Date'
        onClick={toggleModal}
        color='slate'
        className='mt-0 w-auto'
      />
      <Modal toggle={toggleModal} isOpen={isModal} className='modal-fulfillment-date'>
        <ModalHeader toggle={toggleModal}>Fulfillment Date</ModalHeader>
        <ModalBody>
          <div className='select-range-date'>
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setRangeDate([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={rangeDate}
              rangeColors={['#15cdca']}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className='d-flex range-date-button flex-row-reverse gap-3'>
            <CustomButton
              text='Ok'
              variant='contained'
              color='aqua'
              icon={<IoMdCheckmark />}
              onClick={handleSubmit}
              className='mt-0'
            />
            <CustomButton
              text='Cancel'
              variant='outlined'
              color='slate'
              icon={<TiCancel />}
              onClick={handleCancel}
              className='mt-0'
            />
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

FulfillmentDate.propTypes = {
  getRangeFulfillDate: PropTypes.func,
}

export default FulfillmentDate

import PropTypes from 'prop-types'
import React from 'react'
import { VIEW_LIST } from '../utils/constant'
import { getViewDefault } from '../utils/localData'
import GlobalStyle from '../utils/style'

const NoImage = ({
  backgroundColor = GlobalStyle.image.backgroundColor,
  iconColor = GlobalStyle.image.iconColor,
  textColor = GlobalStyle.image.textColor,
  subtextColor = GlobalStyle.image.subtextColor,
  mainText = 'No Image',
  subText = 'Image not available',
  className = '',
}) => {
  const viewDefault = getViewDefault()

  const transformRectBackground =
    viewDefault === VIEW_LIST ? 'translate(0, -25)' : 'translate(-15, 0)'

  const heightRectBackground = viewDefault === VIEW_LIST ? '150' : '100'
  const widthRectBackground = viewDefault === VIEW_LIST ? '100' : '130'

  return (
    <div className={className} style={{ width: '100%', height: '100%' }}>
      <svg
        viewBox='0 0 100 100'
        xmlns='http://www.w3.org/2000/svg'
        preserveAspectRatio='xMidYMid meet'
      >
        <g transform={transformRectBackground}>
          <rect width={widthRectBackground} height={heightRectBackground} fill={backgroundColor} />
        </g>
        <g transform='translate(37, 30)'>
          <rect
            x='0'
            y='0'
            width='25'
            height='15'
            rx='2'
            fill='none'
            stroke={iconColor}
            strokeWidth='1.5'
          />
          <circle cx='12.5' cy='7.5' r='4' fill='none' stroke={iconColor} strokeWidth='1.5' />
          <rect x='18' y='3' width='3' height='2' rx='0.5' fill={iconColor} />
        </g>

        <text
          x='50'
          y='60'
          fontFamily='Arial, sans-serif'
          fontSize='8'
          fill={textColor}
          textAnchor='middle'
        >
          {mainText.toUpperCase()}
        </text>

        <text
          x='50'
          y='70'
          fontFamily='Arial, sans-serif'
          fontSize='6'
          fill={subtextColor}
          textAnchor='middle'
        >
          {subText}
        </text>
      </svg>
    </div>
  )
}

NoImage.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  backgroundColor: PropTypes.string,
  iconColor: PropTypes.string,
  textColor: PropTypes.string,
  subtextColor: PropTypes.string,
  mainText: PropTypes.string,
  subText: PropTypes.string,
  className: PropTypes.string,
}

export default NoImage

import deliveryApi from '_api/delivery'
import virtualShopApi from '_api/virtualShop'
import { OrderRecordContext } from '_contexts/OrderRecordContext'
import { PLATFORM_CO_KII, TURN_OFF } from '_utils/constant'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Select, { components } from 'react-select'
import { useToasts } from 'react-toast-notifications'

const CustomOption = ({ isLoading }) => (
  <components.CustomOption>{isLoading.toString()}</components.CustomOption>
)

const slateColor = '#64748b'

function Fulfillment() {
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { selectedShop } = useSelector((state) => state.shop)
  const { filter, onChangeFulfillment, tabOrderPlatform } = useContext(OrderRecordContext)
  const { addToast } = useToasts()

  const { fulfillment } = filter

  useEffect(() => {
    let isMounted = true

    if (
      isMounted &&
      selectedShop?.id !== undefined &&
      selectedShop?.id !== null &&
      selectedShop?.id !== '' &&
      tabOrderPlatform === PLATFORM_CO_KII
    ) {
      if (selectedShop.active !== TURN_OFF) {
        const getListFulfillment = async (sid) => {
          try {
            setIsLoading(true)
            const listDeliveryPromise = deliveryApi.getListDeliveryOptions(sid)
            const listPickupPromise = virtualShopApi.getPickUpOption(sid)

            const [listDelivery, listPickup] = await Promise.all([
              listDeliveryPromise,
              listPickupPromise,
            ])
            if (!isMounted) return

            const deliveryAddress = listDelivery.msgResp.map((item) => ({
              value: item.id,
              label: item.optionName,
              deliveryTime: item.deliveryTime,
            }))
            const pickupAddress = listPickup.msgResp.map((item) => ({
              value: item.id,
              label: item.optionName,
              pickupTime: item.pickupTime,
            }))

            const noFulfillment = [
              {
                value: 0,
                label: 'No Fulfillment',
              },
            ]

            const newArr = [...deliveryAddress, ...pickupAddress, ...noFulfillment]

            setOptions(newArr)
          } catch (error) {
            if (!isMounted) return
            addToast(error?.msgResp || error.message, {
              appearance: 'error',
              autoDismiss: true,
            })
          } finally {
            if (isMounted) setIsLoading(false)
          }
        }

        getListFulfillment(selectedShop.id)
      }
    } else {
      setOptions([])
    }

    return () => {
      isMounted = false
    }
  }, [selectedShop?.id, tabOrderPlatform])

  const customStyles = {
    control: (provided, { hasValue }) => ({
      ...provided,
      minHeight: '40px',
      height: hasValue ? 'auto' : '40px',
      backgroundColor: 'white',
      borderRadius: '0.25rem',
      borderColor: slateColor,
      boxShadow: 'none',
      transition: 'all 0.15s ease-in-out',
      marginRight: '1rem',
      '&:hover': {
        borderColor: slateColor,
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '0.75rem',
      padding: '0.5rem 1rem',
      backgroundColor: state.isSelected
        ? '#0badad'
        : state.isFocused
        ? 'rgba(11, 173, 173, 0.1)'
        : 'white',
      color: state.isSelected ? 'white' : '#64748b',
      cursor: 'pointer',
      '&:active': {
        backgroundColor: 'rgba(11, 173, 173, 0.2)',
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: 'rgba(11, 173, 173, 0.1)',
      borderRadius: '0.25rem',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      fontSize: '0.75rem',
      fontWeight: 500,
      color: '#0badad',
      padding: '0.25rem',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#0badad',
      '&:hover': {
        backgroundColor: '#0badad',
        color: 'white',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '0.75rem',
      color: '#64748b',
      fontWeight: 500,
    }),
    input: (provided) => ({
      ...provided,
      fontSize: '0.75rem',
      color: '#64748b',
      fontWeight: 500,
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '0.25rem',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      zIndex: 9999,
    }),
    valueContainer: (provided, { hasValue }) => ({
      ...provided,
      height: hasValue ? 'auto' : '40px',
    }),
  }

  return (
    <div className='select-fulfillment w-100'>
      <Select
        isMulti
        name='colors'
        options={options}
        value={fulfillment}
        onChange={onChangeFulfillment}
        classNamePrefix='select'
        placeholder='Fulfillment'
        components={{ CustomOption: (props) => <CustomOption {...props} /> }}
        isLoading={isLoading}
        styles={customStyles}
      />
    </div>
  )
}

export default Fulfillment

import moment from 'moment'
import React, { useCallback } from 'react'
import { BiTimeFive } from 'react-icons/bi'
import CustomSelectBox from '../CustomSelectBox'
import './style.scss'

const TimeRangeSelect = ({
  options,
  value,
  onChange,
  placeholder = 'Select time',
  className,
  selectedDate,
}) => {
  // Cải thiện validation
  const isTimeSlotDisabled = (timeSlot) => {
    if (!timeSlot?.value || !selectedDate) return false

    const currentDateTime = moment()
    const [dateStr, timeRange] = timeSlot.value.split(',')
    const [, endTime] = timeRange
      ?.trim()
      ?.split('-')
      .map((t) => t.trim())

    if (!dateStr || !endTime) return false

    const slotDateTime = moment(`${dateStr} ${endTime}`, 'DD-MM-YYYY HH:mm')
    return currentDateTime.isAfter(slotDateTime)
  }

  // Xử lý selected value
  const getSelectedValue = useCallback(() => {
    if (value) {
      const foundValue = options.find((opt) => opt.value === value)
      return JSON.stringify(foundValue)
    }

    return ''
  }, [value, options])

  const handleChange = (selected) => {
    if (!selected || isTimeSlotDisabled(selected)) return
    onChange(selected)
  }

  return (
    <CustomSelectBox
      options={options}
      value={getSelectedValue()}
      onChange={handleChange}
      placeholder={placeholder}
      className={`time-range-select ${className || ''}`}
      isTimer={true}
      renderOptions={({ options: customOptions, onOptionClick }) => (
        <div className='time-slots-wrapper'>
          {customOptions.map((option, index) => {
            const parsedValue =
              typeof getSelectedValue() === 'string'
                ? JSON.parse(getSelectedValue())
                : getSelectedValue()
            const isSelected = parsedValue?.value === option.value
            const isDisabled = isTimeSlotDisabled(option)

            return (
              <div
                key={index}
                className={`time-slot-item ${isSelected ? 'selected' : ''} ${
                  isDisabled ? 'disabled' : ''
                }`}
                onClick={(e) => {
                  e.stopPropagation()
                  if (!isDisabled) {
                    onOptionClick(option)
                  }
                }}
              >
                <div className={`time-icon ${isDisabled ? 'disabled' : ''}`}>
                  <BiTimeFive size={16} />
                </div>
                <span className={`time-text ${isDisabled ? 'disabled' : ''}`}>{option.label}</span>
              </div>
            )
          })}
        </div>
      )}
      isOptionDisabled={isTimeSlotDisabled}
    />
  )
}

export default TimeRangeSelect

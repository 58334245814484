import deliveryApi from '_api/delivery'
import virtualShopApi from '_api/virtualShop'
import { NO_FULFILLMENT } from '_utils/constant'
import {
  ADD_DELIVERY,
  ADD_PICK_UP,
  CLEAR_DELIVERY,
  CLEAR_LIST_DELIVERY_BY_HOST,
  CLEAR_LIST_PICKUP_BY_HOST,
  CLEAR_PICK_UP,
  CLEAR_SELECT_DELIVERY,
  CLEAR_SELECT_PICK_UP,
  DELETE_DELIVERY,
  DELETE_PICK_UP,
  GET_LIST_DELIVERY,
  GET_LIST_DELIVERY_BY_HOST,
  GET_LIST_DELIVERY_BY_HOST_FAILURE,
  GET_LIST_DELIVERY_BY_HOST_SUCCESS,
  GET_LIST_DELIVERY_FAILURE,
  GET_LIST_DELIVERY_SUCCESS,
  GET_LIST_PICKUP_BY_HOST,
  GET_LIST_PICKUP_BY_HOST_FAILURE,
  GET_LIST_PICKUP_BY_HOST_SUCCESS,
  GET_LIST_PICK_UP,
  GET_LIST_PICK_UP_FAILURE,
  GET_LIST_PICK_UP_SUCCESS,
  SELECT_DELIVERY,
  SELECT_FULFILLMENT_TYPE,
  SELECT_PICK_UP,
  TOGGLE_FULFILLMENT_ACTIVE,
  TOGGLE_FULFILLMENT_ACTIVE_FAILURE,
  TOGGLE_FULFILLMENT_ACTIVE_SUCCESS,
  UPDATE_DELIVERY,
  UPDATE_NEW_LIST_DELIVERY_WITH_SORT,
  UPDATE_PICK_UP,
  UPDATE_STATUS_DELIVERY,
  UPDATE_STATUS_DELIVERY_FAILURE,
  UPDATE_STATUS_DELIVERY_SUCCESS,
} from '../constants/fulfillmentConstants'
import { selectShopAndSelectShopDetail } from './shop'

const initialState = {
  listPickUp: [],
  listDelivery: [],
  listDeliveryByHost: [],
  listPickupByHost: [],
  selectedDelivery: '',
  fulfillmentType: NO_FULFILLMENT,
  selectedPickUp: '',
  fulfillmentId: '',
  errorMessage: '',
  loadingPickup: false,
  loadingDelivery: false,
}

export const clearListDeliveryByHost = () => (dispatch) => {
  dispatch({
    type: CLEAR_LIST_DELIVERY_BY_HOST,
    payload: {
      listDeliveryByHost: [],
    },
  })
}

export const getListDeliveryByHost = (postData) => async (dispatch) => {
  try {
    dispatch({
      type: GET_LIST_DELIVERY_BY_HOST,
      payload: { loadingDelivery: true },
    })
    const { msgResp } = await deliveryApi.getListShopDeliveryByHost(postData)
    dispatch({
      type: GET_LIST_DELIVERY_BY_HOST_SUCCESS,
      payload: { listDeliveryByHost: msgResp, loadingDelivery: false },
    })
  } catch (error) {
    dispatch({
      type: GET_LIST_DELIVERY_BY_HOST_FAILURE,
      payload: { errorMessage: error, loadingDelivery: false },
    })
  }
}

export const getListPickupByHost = (postData) => async (dispatch) => {
  try {
    dispatch({
      type: GET_LIST_PICKUP_BY_HOST,
      payload: { loadingPickup: true },
    })
    const { msgResp } = await deliveryApi.getListShopPickupByHost(postData)
    dispatch({
      type: GET_LIST_PICKUP_BY_HOST_SUCCESS,
      payload: { listPickupByHost: msgResp, loadingPickup: false },
    })
  } catch (error) {
    dispatch({
      type: GET_LIST_PICKUP_BY_HOST_FAILURE,
      payload: { errorMessage: error, loadingPickup: false },
    })
    throw new Error(error.message || error.msgResp)
  }
}

export const clearListPickupByHost = () => (dispatch) => {
  try {
    dispatch({
      type: CLEAR_LIST_PICKUP_BY_HOST,
      payload: { listPickupByHost: [] },
    })
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const updateStatusDelivery = (ref, status, bookerStatus) => (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_STATUS_DELIVERY,
      payload: {},
    })

    const { fulfillment } = getState()
    const { listDeliveryByHost } = fulfillment

    const newList = [...listDeliveryByHost]
    const index = newList.findIndex((item) => item.ref === ref)

    if (index !== -1) {
      newList[index].status = status
      newList[index].bookerStatus = bookerStatus
    }

    dispatch({
      type: UPDATE_STATUS_DELIVERY_SUCCESS,
      payload: {},
    })
  } catch (error) {
    dispatch({
      type: UPDATE_STATUS_DELIVERY_FAILURE,
      payload: { errorMessage: error.message },
    })
  }
}

export const getListPickup = (shopId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_LIST_PICK_UP,
      payload: { loadingPickup: true },
    })

    const { msgResp } = await deliveryApi.getListPickUpOptions(shopId)

    dispatch({
      type: GET_LIST_PICK_UP_SUCCESS,
      payload: {
        listPickUp: msgResp,
        loadingPickup: false,
      },
    })
  } catch (error) {
    dispatch({
      type: GET_LIST_PICK_UP_FAILURE,
      payload: { errorMessage: error, loadingPickup: false },
    })
  }
}

export const toggleFulfillmentActive = (shopId, checked) => async (dispatch) => {
  try {
    dispatch({
      type: TOGGLE_FULFILLMENT_ACTIVE,
      payload: {},
    })
    await virtualShopApi.updateShopNoPickupAndDelivery(shopId, {
      fulfillmentStatus: Number(checked),
    })
    dispatch({
      type: TOGGLE_FULFILLMENT_ACTIVE_SUCCESS,
      payload: {},
    })
  } catch (error) {
    dispatch({
      type: TOGGLE_FULFILLMENT_ACTIVE_FAILURE,
      payload: { errorMessage: error.message },
    })
  }
}

export const addPickup = (data) => (dispatch, getState) => {
  const { fulfillment } = getState()
  const { listPickUp } = fulfillment
  const newList = [...listPickUp]
  newList.push(data)

  dispatch({
    type: ADD_PICK_UP,
    payload: { listPickUp: newList },
  })
}

export const deletePickup = (index) => async (dispatch, getState) => {
  const { fulfillment, shop } = getState()
  const { listPickUp, listDelivery } = fulfillment
  const { selectedShop } = shop

  const newList = [...listPickUp]
  newList.splice(index, 1)

  const hasNoDelivery =
    listDelivery.length === 0 || listDelivery.every((item) => item.id.includes('FAKE_ID'))

  const updatedShop = { ...selectedShop, fulfillmentStatus: false }

  if (newList.length === 0 && hasNoDelivery) {
    await dispatch(toggleFulfillmentActive(selectedShop?.id, false))
    dispatch(selectShopAndSelectShopDetail(updatedShop))
    dispatch({
      type: DELETE_PICK_UP,
      payload: {},
    })
  }

  dispatch({
    type: DELETE_PICK_UP,
    payload: { listPickUp: newList },
  })
}

export const updatePickup = (index, data) => (dispatch, getState) => {
  const { fulfillment } = getState()
  const { listPickUp } = fulfillment
  const newList = [...listPickUp]
  newList.splice(index, 1, data)
  dispatch({
    type: UPDATE_PICK_UP,
    payload: { listPickUp: newList },
  })
}

export const addDelivery = (data) => (dispatch, getState) => {
  const { fulfillment } = getState()
  const { listDelivery } = fulfillment

  const newList = [...listDelivery]
  newList.push(data)

  dispatch({
    type: ADD_DELIVERY,
    payload: { listDelivery: newList },
  })
}

export const deleteDelivery = (index) => async (dispatch, getState) => {
  const { fulfillment, shop } = getState()
  const { selectedShop } = shop
  const { listDelivery, listPickUp } = fulfillment

  const newList = [...listDelivery]
  newList.splice(index, 1)

  const hasNoPickup =
    listPickUp.length === 0 || listPickUp.every((item) => item.id.includes('FAKE_ID'))

  const updatedShop = { ...selectedShop, fulfillmentStatus: false }

  if (newList.length === 0 && hasNoPickup) {
    await dispatch(toggleFulfillmentActive(selectedShop?.id, false))
    dispatch(selectShopAndSelectShopDetail(updatedShop))
    dispatch({
      type: DELETE_DELIVERY,
      payload: {},
    })
  }

  dispatch({
    type: DELETE_DELIVERY,
    payload: { listDelivery: newList },
  })
}

export const updateDelivery = (index, data) => (dispatch, getState) => {
  const { fulfillment } = getState()
  const { listDelivery } = fulfillment

  const newList = [...listDelivery]
  newList.splice(index, 1, data)

  dispatch({
    type: UPDATE_DELIVERY,
    payload: { listDelivery: newList },
  })
}

export const clearPickup = (dispatch) => {
  dispatch({
    type: CLEAR_PICK_UP,
    payload: { listPickUp: [] },
  })
}

export const clearDelivery = (dispatch) => {
  dispatch({
    type: CLEAR_DELIVERY,
    payload: { listDelivery: [] },
  })
}

export const getListDelivery = (shopId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_LIST_DELIVERY,
      payload: { loadingDelivery: true },
    })

    const { msgResp } = await virtualShopApi.getDeliveryOption(shopId)

    dispatch({
      type: GET_LIST_DELIVERY_SUCCESS,
      payload: { listDelivery: msgResp, loadingDelivery: false },
    })
  } catch (error) {
    dispatch({
      type: GET_LIST_DELIVERY_FAILURE,
      payload: { errorMessage: error.msgResp, loadingDelivery: false },
    })
  }
}

export const selectDelivery = (delivery) => (dispatch) => {
  dispatch({
    type: SELECT_DELIVERY,
    payload: { selectedDelivery: delivery, fulfillmentId: delivery?.id },
  })
}

export const clearSelectDelivery = () => (dispatch) => {
  dispatch({
    type: CLEAR_SELECT_DELIVERY,
    payload: {
      selectedDelivery: {},
    },
  })
}

export const selectFulfillmentType = (fulfillmentType) => (dispatch) => {
  dispatch({
    type: SELECT_FULFILLMENT_TYPE,
    payload: { fulfillmentType },
  })
}

export const selectPickUp = (pickUp) => (dispatch) => {
  dispatch({
    type: SELECT_PICK_UP,
    payload: { selectedPickUp: pickUp, fulfillmentId: pickUp?.id },
  })
}

export const clearSelectPickUp = () => (dispatch) => {
  dispatch({
    type: CLEAR_SELECT_PICK_UP,
    payload: {
      selectedPickUp: {},
    },
  })
}

export const clearFulfillment = () => (dispatch) => {
  dispatch({
    type: CLEAR_PICK_UP,
    payload: { fulfillmentId: '' },
  })
}

export const updateNewListDeliveryWithSort = (newListDelivery) => (dispatch) => {
  dispatch({
    type: UPDATE_NEW_LIST_DELIVERY_WITH_SORT,
    payload: { listDeliveryByHost: newListDelivery },
  })
}

const fulfillmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_LIST_PICKUP_BY_HOST:
    case GET_LIST_PICKUP_BY_HOST:
    case GET_LIST_PICKUP_BY_HOST_SUCCESS:
    case GET_LIST_PICKUP_BY_HOST_FAILURE:
    case CLEAR_LIST_DELIVERY_BY_HOST:
    case CLEAR_SELECT_PICK_UP:
    case GET_LIST_DELIVERY_BY_HOST:
    case GET_LIST_DELIVERY_BY_HOST_SUCCESS:
    case GET_LIST_DELIVERY_BY_HOST_FAILURE:
    case UPDATE_STATUS_DELIVERY:
    case UPDATE_STATUS_DELIVERY_SUCCESS:
    case UPDATE_STATUS_DELIVERY_FAILURE:
    case GET_LIST_PICK_UP:
    case GET_LIST_PICK_UP_SUCCESS:
    case GET_LIST_PICK_UP_FAILURE:
    case TOGGLE_FULFILLMENT_ACTIVE:
    case TOGGLE_FULFILLMENT_ACTIVE_SUCCESS:
    case TOGGLE_FULFILLMENT_ACTIVE_FAILURE:
    case ADD_PICK_UP:
    case DELETE_PICK_UP:
    case UPDATE_PICK_UP:
    case ADD_DELIVERY:
    case DELETE_DELIVERY:
    case UPDATE_DELIVERY:
    case CLEAR_PICK_UP:
    case CLEAR_DELIVERY:
    case GET_LIST_DELIVERY:
    case GET_LIST_DELIVERY_SUCCESS:
    case GET_LIST_DELIVERY_FAILURE:
    case SELECT_DELIVERY:
    case SELECT_FULFILLMENT_TYPE:
    case SELECT_PICK_UP:
    case UPDATE_NEW_LIST_DELIVERY_WITH_SORT:
    case CLEAR_SELECT_DELIVERY:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default fulfillmentReducer

import { getDateRemainFromNow } from '_utils/function'
import moment from 'moment'
import React from 'react'

export default function HeaderEventInfo({
  createdUserPhotoUrl,
  createdUserName,
  eClosingTime,
  eTotalOrderCount,
  shopName,
  collectionTimeStart = null,
  collectionTimeEnd = null,
  ePickupTime = null,
  isDisable,
}) {
  return (
    <div className='d-flex align-items-center justify-content-between w-100 mb-3 header-info'>
      <div className='d-flex'>
        <div className='group-list__img'>
          <div className='c-image'>
            <a href='' onClick={(e) => e.preventDefault()}>
              {' '}
              <img
                src={createdUserPhotoUrl || process.env.REACT_APP_AVATAR_URL + createdUserName}
                style={isDisable ? { filter: 'grayscale(100%)' } : {}}
                alt=''
              />
            </a>
          </div>
        </div>
        <div className='group-list__info ps-0'>
          <h3>
            <a href='' onClick={(e) => e.preventDefault()}>
              {createdUserName}
            </a>
          </h3>
          <p>{shopName}</p>
          <p>
            <a href='#'></a>
          </p>
        </div>
      </div>
      <div className='group-list__btn text-right'>
        <span className='txt-date mb-1' style={{ lineHeight: '1.3' }}>
          {ePickupTime && collectionTimeStart && collectionTimeEnd ? (
            <div>
              <span>{moment(ePickupTime, 'DD-MM-YYYY').format('DD-MM-YYYY')}</span>
              <span
                style={{ display: 'block' }}
              >{`${collectionTimeStart} - ${collectionTimeEnd}`}</span>
            </div>
          ) : (
            <span>{`closing in ${getDateRemainFromNow(eClosingTime)}`}</span>
          )}
        </span>
        <span className='txt-number' style={isDisable ? { backgroundColor: 'gray' } : {}}>{`${
          eTotalOrderCount || 0
        } BUY`}</span>
      </div>
    </div>
  )
}

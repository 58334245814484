import {
  HOST_ORDER_CANCELED,
  // HOST_ORDER_EVENT_VERIFIED,
  // HOST_ORDER_EVENT_COLLECTED,
  HOST_ORDER_FULFILLED,
  HOST_ORDER_PAID,
  HOST_ORDER_UNPAID,
  HOST_ORDER_VERIFIED,
} from '_utils/constant'
import React from 'react'
import { BiCheckCircle } from 'react-icons/bi'

const statusConfig = (status, isCOD) => {
  const config = {
    [HOST_ORDER_CANCELED]: {
      title: 'Order Canceled',
      color: 'red',
      message: 'Your order has been canceled.',
    },
    [HOST_ORDER_UNPAID]: {
      title: isCOD ? 'Order placed successfully' : 'Order Unpaid',
      color: isCOD ? 'green' : 'gray',
      message: `${
        isCOD ? '' : 'Your order is still unpaid.'
      } Please wait for the shop owner to confirm.`,
    },
    [HOST_ORDER_PAID]: {
      title: 'Order Paid',
      color: 'green',
      message: 'Your payment has been received.',
    },
    [HOST_ORDER_VERIFIED]: {
      title: 'Order Verified',
      color: 'rgb(78, 151, 246)',
      message: 'Your order has been verified.',
    },
    [HOST_ORDER_FULFILLED]: {
      title: 'Order Fulfilled',
      color: 'rgb(143, 71, 244)',
      message: 'Your order has been fulfilled.',
    },
  }

  return config[status]
}

const PaymentCodeVerified = ({ status, isCOD }) => {
  const { title, color, message } = statusConfig(status, isCOD) || {}

  return (
    <div className='payment-code__verified' style={{ borderColor: color }}>
      <div className='icon-wrapper' style={{ backgroundColor: color }}>
        <BiCheckCircle color='white' />
      </div>
      <span className='status-title' style={{ color }}>
        {title}
      </span>
      <p className='status-message'>{message}</p>
    </div>
  )
}

export default PaymentCodeVerified

import React, { useCallback, useEffect, useState } from 'react'
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap'

function Purchase({ purchaseCount, pid }) {
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [hideTimeout, setHideTimeout] = useState(null)

  const showPopover = useCallback(() => {
    if (hideTimeout) {
      clearTimeout(hideTimeout)
      setHideTimeout(null)
    }
    setPopoverOpen(true)
  }, [hideTimeout])

  const hidePopover = useCallback(() => {
    const timeout = setTimeout(() => {
      setPopoverOpen(false)
    }, 300) // Delay 300ms before hiding
    setHideTimeout(timeout)
  }, [])

  useEffect(
    () => () => {
      if (hideTimeout) {
        clearTimeout(hideTimeout)
      }
    },
    [hideTimeout]
  )

  return (
    <div className='purchase-count cursor-pointer rounded text-center'>
      <span
        className='d-inline-block w-100 h-100 text-end'
        id={`purchase-count-${pid}`}
        onMouseEnter={showPopover}
        onMouseLeave={hidePopover}
      >
        {String(purchaseCount)}
      </span>
      <Popover placement='top' isOpen={popoverOpen} target={`purchase-count-${pid}`}>
        <PopoverHeader className='f-16'>Quantity Sold</PopoverHeader>
        <PopoverBody className='f-14'>
          You have sold <strong className='text-primary'>{String(purchaseCount)}</strong> of this
          product.
        </PopoverBody>
      </Popover>
    </div>
  )
}

export default Purchase

import { SINGAPORE_CODE_NO_ADD } from '_utils/constant'
import React, { useState } from 'react'
import { BsFacebook, BsInstagram, BsThreeDots, BsWhatsapp } from 'react-icons/bs'
import { IoLocationSharp } from 'react-icons/io5'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

function ShopSocialIcon({ shopDetail, handleOnClick }) {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const shopColorTheme = shopDetail?.shopColorTheme
  const colorTheme = shopColorTheme?.customsColorTheme || shopColorTheme?.defaultColorTheme

  const toggle = () => setDropdownOpen((prevState) => !prevState)

  return (
    <div className='bg-white social-media d-flex flex-row justify-content-start align-items-center w-100 gap-3'>
      <div className='home'>
        <a
          rel='noreferrer'
          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
            shopDetail?.shopAddress
          )}`}
          target={'_blank'}
          // style={{ color: colorTheme?.generalTextColor }}
        >
          <IoLocationSharp size={24} style={{ transform: 'translateX(0px)' }} />
        </a>
      </div>
      {shopDetail?.whatsApp ? (
        <div className='whatsapp'>
          <a
            className={'cursor-pointer'}
            // style={{
            //   color: colorTheme?.generalTextColor,
            //   display: 'block',
            //   // transform: 'translateY(-4px)',
            // }}
            onClick={() =>
              handleOnClick(`https://wa.me/${SINGAPORE_CODE_NO_ADD}${shopDetail?.whatsApp}`)
            }
          >
            <BsWhatsapp size={21} />
          </a>
        </div>
      ) : null}
      {shopDetail?.linkInstagram ? (
        <div className='instagram'>
          <a
            className={'cursor-pointer'}
            // style={{ color: colorTheme?.generalTextColor }}
            onClick={() => handleOnClick(shopDetail?.linkInstagram)}
          >
            <BsInstagram size={21} />
          </a>
        </div>
      ) : null}

      {shopDetail?.linkFacebook ? (
        <div className='facebook'>
          <a
            className={'cursor-pointer'}
            // style={{ color: colorTheme?.generalTextColor }}
            onClick={() => handleOnClick(shopDetail?.linkFacebook)}
          >
            <BsFacebook size={21} />
          </a>
        </div>
      ) : null}

      {shopDetail?.externalLink && shopDetail.externalLink.length > 0 && (
        <Dropdown
          isOpen={dropdownOpen}
          toggle={toggle}
          className='external-link'
          style={{ transform: 'translateY(2px)' }}
        >
          <DropdownToggle
            tag='div'
            className='cursor-pointer'
            style={{
              width: '21px',
              height: '21px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: colorTheme?.generalTextColor,
              color: 'white',
            }}
          >
            <BsThreeDots size={14} />
          </DropdownToggle>
          <DropdownMenu>
            {shopDetail.externalLink.map((link, index) => (
              <DropdownItem key={index} onClick={() => handleOnClick(link.value)}>
                {link.name}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      )}
    </div>
  )
}

export default ShopSocialIcon

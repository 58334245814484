import { useState } from 'react'

const LongText = ({ content, limit, className, handleEdit }) => {
  const [showAll, setShowAll] = useState(false)

  const showMore = () => setShowAll(true)
  const showLess = () => setShowAll(false)

  if (content) {
    if (content.length <= limit) {
      // there is nothing more to show
      return (
        <div onClick={handleEdit} className={className}>
          {content}
        </div>
      )
    }
    if (showAll) {
      // We show the extended text and a link to reduce it
      return (
        <>
          <div onClick={handleEdit} className={className}>
            {content}
          </div>
          <button
            className='bg-transparent read-less border-0 text-primary ps-0'
            onClick={showLess}
          >
            Read less
          </button>
        </>
      )
    }
    // In the final case, we show a text with ellipsis and a `Read more` button
    const toShow = `${content.substring(0, limit)}...`
    return (
      <>
        <div onClick={handleEdit} className={className}>
          {toShow}
        </div>
        <button className='bg-transparent read-more border-0 text-primary ps-0 ' onClick={showMore}>
          Read more
        </button>
      </>
    )
  }

  return null
}

export default LongText

import CustomSelectBox from '_components/CustomSelectBox'
import React, { useState } from 'react'

const STATUS_CANCELLED = -1
const STATUS_PENDING = 1
const STATUS_PAID = 2
const STATUS_VERIFIED = 3

function FilterMyPurchase({ onFilter }) {
  const options = [
    { label: 'All', value: 'all' },
    { label: 'Pending', value: STATUS_PENDING },
    { label: 'Paid', value: STATUS_PAID },
    { label: 'Verified', value: STATUS_VERIFIED },
    { label: 'Cancelled', value: STATUS_CANCELLED },
  ]

  const [selectedOption, setSelectedOption] = useState(options[0])

  const handleChange = (option) => {
    setSelectedOption(option)
    onFilter({ target: { value: option.value } })
  }

  return (
    <CustomSelectBox
      options={options}
      value={options.find((opt) => opt.value === selectedOption.value)}
      onChange={handleChange}
      placeholder='Select status'
      className={'ms-auto mb-3'}
      style={{ maxWidth: 150, fontWeight: 500 }}
      isStatusSelect
    />
  )
}

export default FilterMyPurchase

import NotFoundDataComponent from '_components/NotFoundDataComponent'
import React from 'react'
import { GoInbox } from 'react-icons/go'
import PurchaseItem from './PurchaseItem'

function ListMyPurchase({ loading, listPurchaseFiltered, cancelPurchase }) {
  if (!loading && listPurchaseFiltered?.length === 0) {
    return (
      <NotFoundDataComponent
        icon={<GoInbox />}
        title={'No purchases found'}
        subtitle={'No purchases were found for this user.'}
      />
    )
  }

  if (!loading && listPurchaseFiltered?.length > 0) {
    return listPurchaseFiltered?.map((item, index) => (
      <PurchaseItem key={index} item={item} cancelPurchase={cancelPurchase} />
    ))
  }
}

export default ListMyPurchase

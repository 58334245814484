import React from 'react'
import OrderRecordItemSkeleton from './OrderRecordItemSkeleton'

function ListOrderRecordSkeleton() {
  return (
    <div className='list-order-record-skeleton'>
      {[...Array(5)].map((_, index) => (
        <OrderRecordItemSkeleton key={index} />
      ))}
    </div>
  )
}

export default ListOrderRecordSkeleton

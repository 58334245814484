import CustomTable from '_components/CustomTable' // Add this import
import Pagination from '_components/Pagination'
import { DeliveryContext } from '_contexts/DeliveryContext'
import useQuery from '_hooks/useQuery'
import { getListOrderFulfillment, setListDateIsPicked } from '_redux/modules/order'
import { getListCombinedPayment } from '_redux/modules/orderEvent'
import moment from 'moment'
import { useContext, useEffect, useRef, useState } from 'react'
import { FaLocationDot } from 'react-icons/fa6'
import { useDispatch, useSelector } from 'react-redux'
import DeliveryItem from './DeliveryItem'
import DeliveryListSkeleton from './DeliveryListSkeleton'
import Map from './Map'
import ModalPaymentScreen from './ModalPaymentScreen'

const DeliveryList = () => {
  const {
    filteredOrdersFulfillment,
    search,
    currentPage,
    setCurrentPage,
    loading,
    listDeliveryAndPickupShop,
    dataModal,
    modalPaymentScreen,
    togglePaymentScreen,
    handleInvalidPaymentDeliveryEvent,
  } = useContext(DeliveryContext)

  const dispatch = useDispatch()
  const query = useQuery()
  const deliveryIdParam = query.get('deliveryId')

  const headerScrollBarRef = useRef(null)
  const bodyScrollBarRef = useRef(null)
  const [lstAddress, setLstAddress] = useState([])
  const [orderFulfillment, setOrderFulfillment] = useState(null)

  const { listDateIsPicked } = useSelector((state) => state.order)
  const { loadingCurrentDeliveryAndBooking, currentDeliveryAndDeliveryBooking } = useSelector(
    (state) => state.deliveryEvent
  )
  const { shopEventDeliveryBookings = [] } = currentDeliveryAndDeliveryBooking

  const tableHeaders = [
    { label: 'No', className: 'col-order-no', minWidth: '80px', nowrap: true },
    { label: 'Name', className: 'col-customer-name', minWidth: '200px', nowrap: true },
    { label: 'Phone', className: 'col-customer-phone', minWidth: '180px', nowrap: true },
    {
      label: !deliveryIdParam ? 'Orders/Qty' : 'Zone/Price',
      className: 'col-order',
      minWidth: '250px',
      nowrap: false,
    },
    {
      label: 'Fulfillment',
      className: 'col-fulfillment',
      minWidth: '300px',
      nowrap: false,
    },
    {
      label: 'Fulfill Date',
      className: 'col-fulfill-date',
      minWidth: '225px',
      nowrap: true,
    },
    ...(deliveryIdParam
      ? [
          {
            label: 'Booker status',
            className: 'col-booker-status',
            minWidth: '150px',
            nowrap: true,
          },
        ]
      : []),
    {
      label: 'Status',
      className: 'col-status',
      minWidth: '150px',
      nowrap: true,
    },
  ]

  // Render row function for CustomTable
  const renderTableRow = (item, index) => (
    <DeliveryItem
      fulfillment={item}
      fulfillmentIndex={index}
      maxLengthFulfillment={filteredOrdersFulfillment.length - 1}
      isReversedDropdown={
        filteredOrdersFulfillment.length >= 3 && index === filteredOrdersFulfillment.length - 1
      } // Update this line
    />
  )

  const originalListFulfillment =
    deliveryIdParam && shopEventDeliveryBookings.length
      ? shopEventDeliveryBookings
      : listDeliveryAndPickupShop

  const initListDeliveryEventCombinedPaymentRef = async (_data) => {
    const listCombinedPaymentRef = [
      ...new Set(_data.map((element) => element.combinedPaymentRef).filter(Boolean)),
    ]

    if (listCombinedPaymentRef.length) {
      await dispatch(getListCombinedPayment(listCombinedPaymentRef))
    }
  }

  useEffect(() => {
    const headerElement = headerScrollBarRef.current
    const bodyElement = bodyScrollBarRef.current

    if (headerElement && bodyElement) {
      const handleHeaderScroll = () => {
        bodyElement.scrollLeft = headerElement.scrollLeft
      }

      const handleBodyScroll = () => {
        headerElement.scrollLeft = bodyElement.scrollLeft
      }

      headerElement.addEventListener('scroll', handleHeaderScroll)
      bodyElement.addEventListener('scroll', handleBodyScroll)

      return () => {
        headerElement.removeEventListener('scroll', handleHeaderScroll)
        bodyElement.removeEventListener('scroll', handleBodyScroll)
      }
    }
  })

  useEffect(() => {
    if (deliveryIdParam && filteredOrdersFulfillment.length) {
      initListDeliveryEventCombinedPaymentRef(filteredOrdersFulfillment)
    }
  }, [deliveryIdParam, JSON.stringify(filteredOrdersFulfillment)])

  useEffect(() => {
    const address = []

    filteredOrdersFulfillment?.forEach((d, index) => {
      if (d.status === 1 || d.status === 0) {
        if (d?.giftRecipientInfo) {
          address.push({ index, value: d.giftRecipientInfo?.address })
        } else {
          address.push({ index, value: d?.pickupAddress || d?.uAddress || d?.address })
        }
      }
    })

    setLstAddress(address)
    dispatch(setListDateIsPicked([]))
    dispatch(getListOrderFulfillment(filteredOrdersFulfillment))
  }, [JSON.stringify(filteredOrdersFulfillment)])

  useEffect(() => {
    if (orderFulfillment?.length > 0) {
      const newOrderFulfillment = []
      for (let i = 0; i < orderFulfillment?.length; i++) {
        const index = lstAddress.findIndex(
          (item) => item.value === (orderFulfillment[i]?.uAddress || orderFulfillment[i]?.address)
        )
        newOrderFulfillment.push({ ...orderFulfillment[i], index: index + 1 })
      }
      setOrderFulfillment(newOrderFulfillment)
    }
  }, [orderFulfillment?.length, lstAddress?.length])

  useEffect(() => {
    if (listDateIsPicked?.length > 0) {
      const newDeliveryOrderList = []
      for (let k = 0; k < listDateIsPicked?.length; k++) {
        const orderByListDateIsPicked = filteredOrdersFulfillment?.filter(
          (item) =>
            moment.unix(item?.deliveryTimestamp).format('DD-MM-YYYY') ===
            moment.unix(listDateIsPicked[k]).format('DD-MM-YYYY')
        )
        newDeliveryOrderList?.push(orderByListDateIsPicked)
      }
      return newDeliveryOrderList?.flat()
    }
    return null
  }, [listDateIsPicked?.length, filteredOrdersFulfillment?.length])

  if (loading || loadingCurrentDeliveryAndBooking) {
    return <DeliveryListSkeleton />
  }

  return (
    <div className='delivery-container row'>
      <div className='delivery-list__map-container col-12 col-lg-3'>
        {lstAddress?.length > 0 ? (
          <Map
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}`}
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '600px', width: '100%' }} />}
            mapElement={<div style={{ height: '600px' }} />}
            lstAddress={lstAddress}
            listDateIsPicked={listDateIsPicked}
          />
        ) : (
          <div className='delivery-list__map-placeholder w-100 h-100 bg-light d-flex flex-column align-items-center justify-content-center'>
            <span className='fw-semibold'>
              Google Map.
              <FaLocationDot
                className='text-danger f-20'
                style={{ transform: 'translateY(-5px)' }}
              />
            </span>
            <small className='text-info px-3 text-center'>
              No orders are currently pending to be displayed on the map.
            </small>
          </div>
        )}
      </div>
      <div className='delivery-list__table-container col-12 col-lg-9'>
        {loading || loadingCurrentDeliveryAndBooking ? (
          <DeliveryListSkeleton />
        ) : (
          <CustomTable
            headers={tableHeaders}
            data={filteredOrdersFulfillment}
            renderRow={renderTableRow}
            className='table-delivery-list'
          />
        )}

        {filteredOrdersFulfillment.length > 0 && (
          <Pagination
            className='delivery-list__pagination pagination-bar m-0 mt-lg-3'
            currentPage={currentPage}
            totalCount={!search ? originalListFulfillment.length : filteredOrdersFulfillment.length}
            pageSize={5}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
      </div>

      <ModalPaymentScreen
        data={dataModal}
        modalPaymentScreen={modalPaymentScreen}
        togglePaymentScreen={togglePaymentScreen}
        handleInvalidPayment={handleInvalidPaymentDeliveryEvent}
      />
    </div>
  )
}

export default DeliveryList

import { CheckoutContext } from '_contexts/CheckoutContext'
import { TRANSFER_BANKING } from '_utils/constant'
import { getCartCheckout } from '_utils/localData'
import { isEmpty } from 'lodash'
import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import FormGiftRecipient from './FormGiftRecipient'

export function CheckBoxGift() {
  const { setValue, buyer } = useContext(CheckoutContext)
  const { orderDetailsByRef } = useSelector((state) => state.order)

  const cart = getCartCheckout()

  useEffect(() => {
    if (cart?.buyer?.isGift || !isEmpty(orderDetailsByRef?.giftRecipientInfo)) {
      setValue('isGift', true)
    }
  }, [JSON.stringify(cart), JSON.stringify(orderDetailsByRef)])

  return (
    <div className='mb-3'>
      <div className='checkbox-wrapper-46 mb-3'>
        <input
          className='inp-cbx'
          id='cbx-46'
          name='isGift'
          checked={buyer.isGift}
          onChange={(e) => {
            const isChecked = e.target.checked
            setValue('isGift', isChecked)
            if (isChecked) {
              setValue('isCheckCOD', false)
              setValue('paymentMethod', TRANSFER_BANKING)
            }
          }}
          type='checkbox'
        />
        <label className='cbx d-flex align-items-center' htmlFor='cbx-46'>
          <span>
            <svg width='12px' height='10px' viewBox='0 0 12 10'>
              <polyline points='1.5 6 4.5 9 10.5 1'></polyline>
            </svg>
          </span>
          <span>Are you purchasing this as a gift for someone?</span>
        </label>
      </div>

      {buyer.isGift ? <FormGiftRecipient /> : null}
    </div>
  )
}

/* eslint-disable no-constant-condition */
import CustomButton from '_components/CustomButton'
import { ORDER_CO_KII, ORDER_LAZADA, SUBSCRIPTION_STATUS_APPROVED } from '_utils/constant'
import React from 'react'
import { ButtonGroup } from 'reactstrap'

function TabsECommerce({ onSelectTab, tabOrderPlatform, lazadaPackage, isLazadaValid }) {
  return (
    <ButtonGroup>
      <CustomButton
        text='Co-Hoot'
        onClick={() => onSelectTab(ORDER_CO_KII)}
        className='rounded-pill mt-0 gray outlined'
        isActive={tabOrderPlatform === ORDER_CO_KII}
        color='gray'
      />
      {isLazadaValid && false ? (
        <CustomButton
          text='Lazada'
          onClick={() => onSelectTab(ORDER_LAZADA)}
          isDisabled={lazadaPackage?.status !== SUBSCRIPTION_STATUS_APPROVED}
          isActive={tabOrderPlatform === ORDER_LAZADA}
        />
      ) : null}
    </ButtonGroup>
  )
}

export default TabsECommerce

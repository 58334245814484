import {
  CART_ORDER_DETAILS_BY_REF,
  CART_USER,
  CATEGORY_NAME,
  CREATE_EVENT_DATA,
  CREATE_SHOP_DATA,
  DATA_FIREBASE,
  DEBUG_MODE,
  DEBUG_SCOPE,
  DEVICE_USER,
  EVENTS_BY_SHOP,
  IS_PAYMENT_SUBSCRIPTION,
  LAZADA_PACKAGE,
  SHOP_DETAIL,
  SOCIAL_TOKEN,
  USER_INFO,
  USER_TOKEN,
  VIEW_DEFAULT,
} from './constant'

export const setEventsByShop = (value) => {
  localStorage.setItem(EVENTS_BY_SHOP, JSON.stringify(value))
}

export const getEventsByShop = () => {
  const value = localStorage.getItem(EVENTS_BY_SHOP)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const removeEventsByShop = () => {
  localStorage.removeItem(EVENTS_BY_SHOP)
}

export const setDomain = (key, value) => {
  localStorage.setItem(key, value)
}
export const getDomain = (key) => localStorage.getItem(key)

export const setCreateEventData = (value) => {
  localStorage.setItem(CREATE_EVENT_DATA, JSON.stringify(value))
}

export const removeCreateEventData = () => {
  localStorage.removeItem(CREATE_EVENT_DATA)
}

export const getCreateEventData = () => {
  const value = localStorage.getItem(CREATE_EVENT_DATA)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const removeSelectedShopLocalStorage = () => {
  localStorage.removeItem('selectedShop')
}

export const getSelectedShopLocalStorage = () => {
  const value = localStorage.getItem('selectedShop')
  try {
    if (value) {
      return JSON.parse(value)
    }
  } catch (error) {
    return value
  }
}

export const setSelectedShopLocalStorage = (shop) => {
  localStorage.setItem('selectedShop', JSON.stringify(shop))
}

export const setLazadaPackageLocalStorage = (data) => {
  localStorage.setItem(LAZADA_PACKAGE, JSON.stringify(data))
}

export const getCurrentLazadaPackageLocalStorage = () => {
  const value = localStorage.getItem(LAZADA_PACKAGE)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const removeCartOrderDetailsByRef = () => localStorage.removeItem(CART_ORDER_DETAILS_BY_REF)

export const getCartOrderDetailsByRef = () => {
  const value = localStorage.getItem(CART_ORDER_DETAILS_BY_REF)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const setCartOrderDetailsByRef = (value) =>
  localStorage.setItem(CART_ORDER_DETAILS_BY_REF, JSON.stringify(value))

export const setUserToken = (value) => {
  localStorage.setItem(USER_TOKEN, JSON.stringify(value))
}

export const getUserToken = () => {
  const value = localStorage.getItem(USER_TOKEN)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const removeUserToken = () => {
  localStorage.removeItem(USER_TOKEN)
}

export const setUserInfo = (value) => {
  localStorage.setItem(USER_INFO, JSON.stringify(value))
}

export const getUserInfo = () => {
  const value = localStorage.getItem(USER_INFO)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const setSocialToken = (value) => {
  localStorage.setItem(SOCIAL_TOKEN, JSON.stringify(value))
}

export const getSocialToken = () => {
  const value = localStorage.getItem(SOCIAL_TOKEN)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const removeSocialToken = () => {
  localStorage.removeItem(SOCIAL_TOKEN)
}

export const setDataFirebase = (value) => {
  localStorage.setItem(DATA_FIREBASE, JSON.stringify(value))
}

export const getDataFirebase = () => {
  const value = localStorage.getItem(DATA_FIREBASE)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const setDebugMode = (isDebug) => {
  localStorage.setItem(DEBUG_MODE, isDebug)
}

export const getDebugMode = () => localStorage.getItem(DEBUG_MODE)

export const setDebugScope = (userEmails) => {
  localStorage.setItem(DEBUG_SCOPE, userEmails)
}

export const getDebugScope = () => localStorage.getItem(DEBUG_SCOPE)
export const setDeviceUser = (value) => localStorage.setItem(DEVICE_USER, value)
export const getDeviceUser = () => localStorage.getItem(DEVICE_USER)

export const setCreateShopData = (value) => {
  localStorage.setItem(CREATE_SHOP_DATA, JSON.stringify(value))
}

export const getCreateShopData = () => {
  const value = localStorage.getItem(CREATE_SHOP_DATA)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const removeCreateShopData = () => {
  localStorage.removeItem(CREATE_SHOP_DATA)
}

export const setCartCheckout = (data) => {
  localStorage.setItem(CART_USER, JSON.stringify(data))
}

export const getCartCheckout = () => {
  const value = localStorage.getItem(CART_USER)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const removeCartCheckout = () => {
  localStorage.removeItem(CART_USER)
}

export const setCategoryName = (value) => {
  localStorage.setItem(CATEGORY_NAME, value)
}

export const getCategoryName = () => {
  const value = localStorage.getItem(CATEGORY_NAME)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const removeCategoryName = () => {
  localStorage.removeItem(CATEGORY_NAME)
}

export const setDataShopDetail = (data) => {
  localStorage.setItem(SHOP_DETAIL, JSON.stringify(data))
}

export const getDataShopDetail = () => {
  const value = localStorage.getItem(SHOP_DETAIL)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const removeDataShopDetail = () => {
  localStorage.removeItem(SHOP_DETAIL)
}

export const checkPaymentSubscription = (isPayment) => {
  localStorage.setItem(IS_PAYMENT_SUBSCRIPTION, JSON.stringify(isPayment))
}

export const getCheckPaymentSubscription = () => {
  const value = localStorage.getItem(IS_PAYMENT_SUBSCRIPTION)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const removeCheckPaymentSubscription = () => localStorage.removeItem(IS_PAYMENT_SUBSCRIPTION)

export const setViewDefault = (value) => {
  localStorage.setItem(VIEW_DEFAULT, JSON.stringify(value))
}

export const getViewDefault = () => {
  const value = localStorage.getItem(VIEW_DEFAULT)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const removeProductData = () => {
  const shopData = getCreateShopData();
  if (shopData) {
    const newShopData = { ...shopData };
    delete newShopData.products;
    setCreateShopData(newShopData);
  }
}

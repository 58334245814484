import { LIST_TIME_2 } from '../constant'
import { editDistance } from '../function'
import { convertToCurrentGMT } from './converter'

export const calculateDuration = (startDay, endDay, startTime, endTime) => {
  if (startTime && endTime) {
    const currentStartTime = convertToCurrentGMT(`${startDay} ${startTime}`)?.slice(11)
    const currentEndTime = convertToCurrentGMT(`${endDay} ${endTime}`)?.slice(11)

    const formattedEndTime = currentEndTime === '23:59' ? '00:00' : currentEndTime

    const hourStart = LIST_TIME_2.find((item) => item.valueString === currentStartTime)?.text
    const hourEnd = LIST_TIME_2.find((item) => item.valueString === formattedEndTime)?.text

    return {
      hourStart,
      hourEnd,
    }
  }
}

export const calculateDiscountPrice = (price, discount) => {
  const parsedPrice = parseFloat(price)
  const parsedDiscount = parseFloat(discount)

  if (isNaN(parsedPrice) || isNaN(parsedDiscount)) {
    return 0
  }

  const discountPrice = parsedDiscount / 100
  return parsedPrice - parsedPrice * discountPrice
}

export const calculatePercentMatch = (s1 = '', s2 = '') => {
  let longer = s1.toLowerCase()
  let shorter = s2.toLowerCase()
  if (s1.length < s2.length) {
    longer = s2
    shorter = s1
  }
  const longerLength = longer.length
  if (longerLength === 0) {
    return 0.0
  }
  return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
}

// UserProfileDropdown.jsx
import CustomSwalFire from '_components/CustomSwalFire'
import { removeCreateShopData } from '_utils/localData'
import PropTypes from 'prop-types'
import React from 'react'
import { AiFillSetting, AiFillShop, AiOutlineHistory } from 'react-icons/ai'
import { BiLogOut, BiSolidPurchaseTag } from 'react-icons/bi'
import { MdAccountCircle } from 'react-icons/md'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import './style.scss'

const UserProfileDropdown = ({
  userInfo,
  dropdownOpen,
  toggle,
  listNotification,
  onRedirectPage,
  adminConsolePath,
  onAdminConsoleClick,
  useLogout,
  shouldShowAdminConsole,
}) => {
  const menuItems = [
    {
      icon: MdAccountCircle,
      label: 'Account',
      path: '/user/profile',
    },
    {
      icon: AiFillShop,
      label: 'Shop List',
      path: '/virtual-shop/list',
    },
    {
      icon: BiSolidPurchaseTag,
      label: 'My Purchase',
      path: '/virtual-shop/my-purchase',
    },
    {
      icon: AiOutlineHistory,
      label: 'Payment History',
      path: '/virtual-shop/payment-history',
    },
    {
      icon: AiFillSetting,
      label: 'Admin Console',
      path: adminConsolePath,
      onClick: onAdminConsoleClick,
      condition: shouldShowAdminConsole,
    },
    {
      type: 'divider',
    },
    {
      icon: BiLogOut,
      label: 'Log out',
      onClick: useLogout,
    },
  ]

  const handleItemClick = (item) => {
    const isProcessingCreateShop = ['/virtual-shop/confirm-shop', '/virtual-shop/add-items'].some(
      (path) => window.location.pathname.includes(path)
    )

    if (isProcessingCreateShop) {
      CustomSwalFire.delete({
        title: 'Confirm to leave',
        text: 'Do you want to leave? If you leave, the data will be deleted',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        colorCancelButton: 'slate',
        colorConfirmButton: 'aqua',
        onConfirm: () => {
          removeCreateShopData()
          if (item.onClick) {
            item.onClick()
          } else if (item.path) {
            onRedirectPage(item.path)
          }
        },
      })
    } else if (item.onClick) {
      item.onClick()
    } else if (item.path) {
      // onClearCart()
      onRedirectPage(item.path)
    }
  }

  return (
    <div className='container-fluid user-profile-dropdown'>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} className='profile-dropdown'>
        <DropdownToggle
          caret
          className='bg-transparent border-0 shadow-none d-inline-block pe-0'
          style={{ background: 'transparent', height: 55 }}
        >
          <div className='position-relative'>
            <div className='avatar-top__img'>
              <img
                id='userAvatar'
                src={userInfo?.photoUrl || process.env.REACT_APP_AVATAR_URL + userInfo?.name}
                alt=''
              />
            </div>
            {listNotification.some((item) => item.new) && (
              <span
                className='position-absolute start-100 bg-danger border border-light rounded-circle'
                style={{
                  width: '15px',
                  height: '15px',
                  top: 3,
                  transform: 'translate(-80%, -50%)',
                }}
              >
                <span className='visually-hidden'>New alerts</span>
              </span>
            )}
          </div>
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu-avatar'>
          {menuItems
            .filter((item) => !item.condition || item.condition)
            .map((item, index) => {
              if (item.type === 'divider') {
                return <DropdownItem key={index} divider className='my-0' />
              }
              return (
                <DropdownItem
                  key={index}
                  onClick={() => handleItemClick(item)}
                  className='px-3 py-2'
                >
                  <item.icon className='me-2' />
                  <span>{item.label}</span>
                </DropdownItem>
              )
            })}
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

UserProfileDropdown.propTypes = {
  userInfo: PropTypes.object,
  dropdownOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  listNotification: PropTypes.array.isRequired,
  onRedirectPage: PropTypes.func.isRequired,
  adminConsolePath: PropTypes.string.isRequired,
  onAdminConsoleClick: PropTypes.func.isRequired,
  useLogout: PropTypes.func.isRequired,
  shouldShowAdminConsole: PropTypes.bool.isRequired,
}

export default UserProfileDropdown

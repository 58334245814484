import { yupResolver } from '@hookform/resolvers/yup'
import authApi from '_api/auth'
import reportApi from '_api/report'
import InputField from '_components/InputField'
import imgTop from '_images/img_top.svg'
import React from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Form } from 'reactstrap'
import * as Yup from 'yup'
import './style.scss'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Invalid email'),
})

const Forgot = () => {
  const {
    register,
    setError,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = ({ email }) => {
    authApi
      .searchUser(email.toLowerCase().trim())
      .then((res) => {
        if (res.msgResp.uid !== null) {
          const patchData = {
            step: 1,
            email: email.toLowerCase().trim(),
          }
          authApi
            .resetPassword(patchData)
            .then(() => {
              setError('email', { type: 'invalid', message: 'Email sent' })
            })
            .catch((error) => {
              reportApi.report({ message: `${error.msgCode} - ${error.msgResp}` })
              if (error.msgCode === 10605) {
                setError('email', { type: 'invalid', message: 'Email sent' })
              } else {
                setError('email', { type: 'invalid', message: error.msgResp })
              }
            })
        } else {
          setError('email', { type: 'invalid', message: 'Account not exist' })
        }
      })
      .catch((error) => {
        reportApi.report({ message: `${error.msgCode} - ${error.msgResp}` })
        setError('email', { type: 'invalid', message: error.msgResp })
      })
  }

  return (
    <body className='p-login min-vh-100'>
      <div className='page-login page-login--screen'>
        <div className='main-top align-self-start'>
          <div className='container'>
            <div className='main-logo text-left'>
              <Link
                to='/'
                className='main-logo text-left text-white font-semibold d-flex flex-column align-items-center'
                style={{ width: '30%' }}
              >
                {/* <img src={logoLeft} /> */}
                <h4 className='mb-0'>Co-Hoot</h4>
                <small>Good thing must share!</small>
              </Link>
            </div>
            <div className='main-img text-center'>
              <img src={imgTop} />
            </div>
          </div>
        </div>
        <div className='main-content'>
          <Form onSubmit={handleSubmit(onSubmit)} className='container'>
            <h2 className='c-ttl text-center'>Forget Password?</h2>
            <div className='c-form c-form--email'>
              <div className='c-txt-org text-center'>
                Enter your email and we’ll send your password to your registered e-mail
              </div>
              <InputField
                id='email'
                name='email'
                type='text'
                placeholder='Email address'
                register={register('email')}
              />
              {errors?.email && <small className='errorMessage'>{errors.email.message}</small>}
              <div className='form-group'>
                <button className='btn btn--main'>SUBMIT</button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </body>
  )
}

export default Forgot

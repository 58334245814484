import { useWindowWidth } from '@react-hook/window-size'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react' // Add useEffect
import { HiOutlineInboxStack } from 'react-icons/hi2'
import NotFoundDataComponent from '../NotFoundDataComponent'
import './styles.scss'

const CustomTable = ({
  headers,
  data,
  renderRow,
  stickyColumns = [],
  style = {},
  className = '',
}) => {
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [isScrollable, setIsScrollable] = useState(false) // Add this state
  const tableWrapperRef = useRef(null)
  const width = useWindowWidth()
  const isMobile = width <= 768

  // Đảo ngược logic trong useEffect
  useEffect(() => {
    const checkScroll = () => {
      if (tableWrapperRef.current) {
        const { scrollLeft: currentScrollLeft } = tableWrapperRef.current
        // isScrollable true khi scrollLeft = 0
        setIsScrollable(currentScrollLeft === 0)
      }
    }

    checkScroll()
    window.addEventListener('resize', checkScroll)
    return () => window.removeEventListener('resize', checkScroll)
  }, [data, width]) // Re-check when data or window width changes

  // Add scroll handler
  const handleScroll = () => {
    if (tableWrapperRef.current) {
      const { scrollLeft: currentScrollLeft } = tableWrapperRef.current
      // Đảo ngược logic - isScrollable true khi KHÔNG scroll
      setIsScrollable(currentScrollLeft === 0)
    }
  }

  const getStickyClass = (header) => {
    if (!data || data.length === 0) return header.className
    if (isMobile) return header.className

    const index = stickyColumns.indexOf(header.label)
    if (index === -1) return header.className

    const isLastSticky = index === stickyColumns.length - 1
    return `${header.className || ''} sticky-col sticky-col-${index} ${
      isLastSticky ? 'last-sticky' : ''
    } ${isScrollable ? 'is-scrollable' : ''}`
  }

  const getColumnStyle = (header) => ({
    minWidth: header.minWidth, // Changed from width to minWidth
    whiteSpace: header.nowrap ? 'nowrap' : 'normal',
    ...header.style,
  })

  const handleMouseDown = (e) => {
    setIsDragging(true)
    setStartX(e.pageX - tableWrapperRef.current.offsetLeft)
    setScrollLeft(tableWrapperRef.current.scrollLeft)
  }

  const handleMouseMove = (e) => {
    if (!isDragging) return
    e.preventDefault()
    const x = e.pageX - tableWrapperRef.current.offsetLeft
    const walk = (x - startX) * 2
    tableWrapperRef.current.scrollLeft = scrollLeft - walk
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  // Use requestAnimationFrame for smooth scrolling
  const handleMouseMoveOptimized = (e) => {
    if (!isDragging) return
    e.preventDefault()
    requestAnimationFrame(() => handleMouseMove(e))
  }

  return (
    <div className={`custom-table ${className}`}>
      <div
        ref={tableWrapperRef}
        className={`table-wrapper ${isDragging ? 'grabbing' : ''}`}
        style={style}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMoveOptimized} // Use optimized handler
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onScroll={handleScroll} // Add scroll handler
      >
        <table>
          <thead>
            <tr>
              {headers.map((header) => (
                <th
                  key={header.label}
                  className={getStickyClass(header)}
                  style={getColumnStyle(header)}
                >
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!data || data.length === 0 ? (
              <tr>
                <td colSpan={headers.length}>
                  <NotFoundDataComponent
                    icon={<HiOutlineInboxStack size={48} />}
                    title='No Data Found'
                    subtitle='There are no records to display'
                    className={'me-auto'}
                    style={{ marginLeft: '15%' }}
                  />
                </td>
              </tr>
            ) : (
              data.map((item, index) => (
                <tr key={index} className={index % 2 !== 0 ? 'bg-light' : 'bg-white'}>
                  {renderRow(item, index, getStickyClass)}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

// Xóa height từ PropTypes
CustomTable.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      className: PropTypes.string,
      minWidth: PropTypes.string, // Changed from width to minWidth
      nowrap: PropTypes.bool,
      style: PropTypes.object,
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
  renderRow: PropTypes.func.isRequired,
  stickyColumns: PropTypes.arrayOf(PropTypes.string),
  style: PropTypes.object,
}

export default CustomTable

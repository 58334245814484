import InputField from '_components/InputField'
import { CheckoutContext } from '_contexts/CheckoutContext'
import PayNowLogo from '_images/paynow-logo.svg'
import { PAYMENT_COD, TRANSFER_BANKING, TURN_ON } from '_utils/constant'
import { useContext } from 'react'
import { BsCashCoin } from 'react-icons/bs'

const styleDisabled = {
  opacity: 0.5,
  pointerEvents: 'none',
  filter: 'grayscale(1)',
}

export default function SelectPaymentMethod() {
  const { buyer, shop, setValue } = useContext(CheckoutContext)

  const onChangeMethod = (value) => {
    if (value === TRANSFER_BANKING) {
      setValue('isCheckCOD', false)
    }

    setValue('paymentMethod', value)
  }

  return (
    <>
      {shop?.cashOnDeliveryPickup === TURN_ON && (
        <>
          <p className='select-payment-method__title'>Select payment method:</p>
          <div className='select-payment-method__options row'>
            <div
              className={`select-payment-method__option col-12 ${
                buyer.paymentMethod === TRANSFER_BANKING ? 'active' : ''
              }`}
            >
              <div className='select-payment-method__option-content form-check ps-2 d-flex align-items-center'>
                <label
                  className='select-payment-method__label w-100 p-2 fw-normal cursor-pointer form-check-label h-100 d-flex align-items-center position-relative justify-content-between'
                  htmlFor='banking'
                >
                  <img
                    src={PayNowLogo}
                    className='select-payment-method__logo object-fit-contain'
                    alt='paynow-logo'
                    width={200}
                    height={100}
                    loading='lazy'
                  />
                  <InputField
                    type={'radio'}
                    isRadio
                    id={'banking'}
                    checked={buyer.paymentMethod === TRANSFER_BANKING}
                    onChange={() => onChangeMethod(TRANSFER_BANKING)}
                  />
                </label>
              </div>
            </div>
            <div
              className={`select-payment-method__option col-12 border rounded ${
                buyer.paymentMethod === PAYMENT_COD && !buyer.isGift ? 'active' : ''
              }`}
              style={buyer.isGift ? styleDisabled : {}}
            >
              <div
                className='select-payment-method__option-content form-check ps-2 d-flex align-items-center justify-content-between'
                style={{ height: 50 }}
              >
                <label
                  className='select-payment-method__label p-2 w-100 fw-normal cursor-pointer form-check-label h-100 d-flex align-items-center justify-content-between'
                  htmlFor='cod'
                >
                  <span
                    className='select-payment-method__icon-text icon-text'
                    style={{ transform: 'translateY(3px)' }}
                  >
                    <BsCashCoin
                      size={25}
                      color={'green'}
                      className='select-payment-method__icon me-2'
                    />
                    Cash on Delivery / Pickup
                  </span>
                  <InputField
                    type={'radio'}
                    isRadio
                    name={'cod'}
                    id={'cod'}
                    checked={buyer.paymentMethod === PAYMENT_COD}
                    onChange={() => onChangeMethod(PAYMENT_COD)}
                  />
                </label>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

import CustomButton from '_components/CustomButton'
import CustomModal from '_components/CustomModal'
import { isNumber } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { IoSave } from 'react-icons/io5'
import { TiCancel } from 'react-icons/ti'
import { useSelector } from 'react-redux'
import { ProductItemContext } from '.'

function ProductInventory({ availableOfStock, product }) {
  const [isEditInventory, setIsEditInventory] = useState(false)
  const productHaveVariants = product?.variations?.length > 0

  const { isSavedProduct } = useSelector((state) => state.product)
  const { handleSubmit, register, setValue, errors, isSubmitting, handleSave } =
    useContext(ProductItemContext)

  useEffect(() => {
    if (isSavedProduct) setIsEditInventory(false)
  }, [isSavedProduct])

  const toggleModalEditProduct = () => {
    if (productHaveVariants) return
    setIsEditInventory(!isEditInventory)
  }

  const onSubmit = (data) => {
    handleSave(data)
    toggleModalEditProduct()
  }

  return (
    <div className='cursor-pointer'>
      {(!isEditInventory && !isNumber(product?.availableOfStock)) || !availableOfStock ? (
        <div className='text-content text-end' onClick={toggleModalEditProduct}>
          0
        </div>
      ) : (
        <div className='text-content text-end' onClick={toggleModalEditProduct}>
          {!productHaveVariants ? (
            <CurrencyFormat
              value={availableOfStock || 0}
              displayType={'text'}
              thousandSeparator={true}
            />
          ) : (
            'You just can edit inventory in variants'
          )}
        </div>
      )}

      <CustomModal
        isOpen={isEditInventory}
        toggle={toggleModalEditProduct}
        title='Edit In Stock'
        hideFooter
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='form-group'>
            <CurrencyFormat
              id='product-availableOfStock'
              value={availableOfStock}
              displayType={'input'}
              thousandSeparator={true}
              className={`custom-input form-control ${
                errors.availableOfStock ? 'input-invalid' : ''
              }`}
              onValueChange={(values) => {
                const { value } = values
                setValue('availableOfStock', value)
              }}
              {...register('availableOfStock')}
            />

            {errors.availableOfStock && (
              <small className='text-danger'>{errors.availableOfStock.message}</small>
            )}
          </div>

          <div className='d-flex gap-2 w-100 justify-content-between mt-3'>
            <CustomButton
              text='Cancel'
              color='slate'
              type='reset'
              icon={<TiCancel />}
              className='mt-0 w-50'
              onClick={toggleModalEditProduct}
            />
            <CustomButton
              text={isSubmitting ? 'Saving...' : 'Save'}
              type='submit'
              className='mt-0 w-50'
              isDisabled={isSubmitting}
              icon={<IoSave />}
              variant='contained'
              color='aqua'
            />
          </div>
        </form>
      </CustomModal>
    </div>
  )
}

export default ProductInventory

/* eslint-disable no-useless-escape */
/* eslint-disable func-names */
/* eslint-disable function-paren-newline */
import { yupResolver } from '@hookform/resolvers/yup'
import CustomButton from '_components/CustomButton'
import CustomSwalFire from '_components/CustomSwalFire'
import { DEFAULT_PRODUCT_DIGISTALL, TYPE_SHOP_WITH_SINGLE_LIST } from '_utils/constant'
import {
  getCategoryName,
  getCreateShopData,
  removeProductData,
  setCreateShopData,
} from '_utils/localData'
import React from 'react'
import { useForm } from 'react-hook-form'
import { GrPowerReset } from 'react-icons/gr'
import { IoClose, IoSave } from 'react-icons/io5'
import { useHistory } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import * as Yup from 'yup'
import ProductList from './components/ProductList'
import './style.scss'

const validatePrice = (value, options = { minValue: 0 }) => {
  if (!value) return false
  const normalizedValue = value.replace(/[$,]/g, '')
  return (
    /^\d+(\.\d{0,2})?$/.test(normalizedValue) && parseFloat(normalizedValue) >= options.minValue
  )
}

const validationSchema = Yup.object().shape({
  products: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Product name is required'),
      description: Yup.string().nullable().notRequired(),
      price: Yup.string()
        .required('Price is required')
        .test('is-valid-price', 'Price must be greater than 0', (value) =>
          validatePrice(value, { minValue: 0.01 })
        )
        .transform((value) => {
          if (!value) return value
          return value.replace(/[$,]/g, '')
        }),
      // maxQuantity: Yup.number()
      //   .typeError('Max quantity stock is required')
      //   .required('Max quantity stock is required')
      //   .positive('Max quantity must be positive number'),
      defaultQuantity: Yup.number()
        .typeError('Default quantity is required')
        .default(0)
        .required('Default quantity is required'),
      limitPerOrder: Yup.number()
        .typeError('Limit per order is required')
        .required('Limit per order is required')
        .positive('Limit must be positive number'),
      minOrderQty: Yup.number()
        .typeError('Minimum order quantity must be greater than 0')
        .min(1, 'Minimum order quantity must be greater than 0')
        .required('Minimum order quantity must not be blank.')
        .test(
          'lessThanLimitPerOrder',
          'Min order must be less than or equal limit per order',
          function test(value) {
            const { limitPerOrder } = this.parent
            return value <= limitPerOrder
          }
        ),
      photoUrls: Yup.string().notRequired().nullable(),
      subItems: Yup.array().of(
        Yup.object().shape({
          type: Yup.string().required('Sub item name is required'),
          required: Yup.boolean().default(false),
          list: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required('Option name is required'),
              price: Yup.string()
                .required('Price is required')
                .test('is-valid-price', 'Invalid price format', (value) =>
                  validatePrice(value, { minValue: 0 })
                )
                .transform((value) => {
                  if (!value) return value
                  return value.replace(/[$,]/g, '')
                }),
            })
          ),
        })
      ),
    })
  ),
})

function AddItems() {
  const history = useHistory()
  const dataCreateShop = getCreateShopData()
  const { addToast } = useToasts()

  const isCheckProduct = !!dataCreateShop?.products
  const dataCreateShopProduct = isCheckProduct
    ? {
        products: dataCreateShop?.products[0]?.items,
      }
    : { products: [DEFAULT_PRODUCT_DIGISTALL] }

  const itemMatchWithCategory = isCheckProduct
    ? {
        products: dataCreateShop?.products.filter((item) => item.category === getCategoryName())[0]
          ?.items || [DEFAULT_PRODUCT_DIGISTALL],
      }
    : { products: [DEFAULT_PRODUCT_DIGISTALL] }

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting, isValid },
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues:
      dataCreateShop?.shopFrontType === TYPE_SHOP_WITH_SINGLE_LIST
        ? dataCreateShopProduct
        : itemMatchWithCategory,
    resolver: yupResolver(validationSchema),
    mode: 'onChange', // Change to onChange for real-time validation
  })

  const onSubmit = (data) => {
    if (!isValid) {
      addToast('Please fill in all required fields correctly', {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }

    if (dataCreateShop?.shopFrontType === TYPE_SHOP_WITH_SINGLE_LIST) {
      try {
        setCreateShopData({
          ...dataCreateShop,
          products: [
            {
              category: '',
              items: data?.products?.map((item) => ({
                ...item,
                photoUrls: item?.photoUrl,
                price: item?.price ? Number(item.price.replace(/[,\.]/g, '')) : 0,
              })),
            },
          ],
        })
        history.push('/virtual-shop/confirm-shop')
      } catch (error) {
        addToast(error.message || 'Error saving product data', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    }
  }

  const handleCancel = () => {
    if (watch('products').length) {
      CustomSwalFire.delete({
        title: 'Are you sure you want to exit this page?',
        text: 'You will lose all product data you have entered!',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        cancelIcon: <IoClose />,
        onConfirm: async () => {
          reset()
          removeProductData() // Only remove product data
          history.push('/virtual-shop/create')
        },
        onError: (error) => {
          CustomSwalFire.error({
            title: 'Error!',
            text: error.message,
          })
        },
      })
    } else {
      removeProductData() // Only remove product data
      history.push('/virtual-shop/create')
    }
  }

  if (!dataCreateShop?.shopFrontType) {
    history.push('/virtual-shop/create')
  }

  return (
    <div className='container'>
      <div className='add-items create-items-page' style={{ marginTop: 0 }}>
        <p className='fw-bold text-center mt-3 title'>Items for sales</p>
        <form className='h-100 d-flex flex-column' onSubmit={handleSubmit(onSubmit)}>
          <ProductList {...{ control, register, getValues, setValue, errors, watch }} />
          <div className='row mt-auto' style={{ marginLeft: -16, marginRight: -16 }}>
            <div className='col-6 ps-0'>
              <CustomButton
                type='button'
                color='slate'
                onClick={handleCancel}
                text='Cancel'
                icon={<GrPowerReset />}
                className='mt-0'
              />
            </div>
            <div className='col-6 pe-0'>
              <CustomButton
                type='submit'
                color='aqua'
                icon={<IoSave />}
                variant='contained'
                text={isSubmitting ? 'Processing...' : 'Save & Next'}
                className='mt-0'
                isDisabled={isSubmitting || !isValid} // Add !isValid here
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddItems

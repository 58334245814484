import classNames from 'classnames'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import Advance from './Advance'
import Fulfillment from './Fulfillment'
import General from './General/index'
import Payment from './Payment'

import Account from './Account'
import './style.scss'

const tabs = [
  { id: 'general', label: 'General', component: General },
  { id: 'fulfillment', label: 'Fulfillment', component: Fulfillment },
  { id: 'advance', label: 'Advance', component: Advance },
  { id: 'payment', label: 'Payment', component: Payment },
  { id: 'account', label: 'Account', component: Account },
]

function Setting({ activeTab }) {
  const history = useHistory()

  const { selectedShop } = useSelector((state) => state.shop)

  const handleTabClick = (tab) => {
    if (tab === 'account') {
      history.push('/admin-console/setting/account/profile')
    } else {
      history.push(`/admin-console/${selectedShop?.shopSlug}/setting/${tab}`)
    }
  }

  return (
    <div className='setting-page pb-5 pt-4 px-3'>
      <Container fluid className='subscription-plan'>
        <div>
          <h3 className='title'>Setting</h3>
          <Nav tabs>
            {tabs.map((tab) => (
              <NavItem key={tab.id}>
                <NavLink
                  className={activeTab === tab.id ? 'active' : ''}
                  onClick={() => handleTabClick(tab.id)}
                >
                  {tab.label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab}>
            {tabs.map((tab) => (
              <TabPane
                key={tab.id}
                tabId={tab.id}
                className={classNames(tab.id === 'account' ? 'bg-white pt-3' : '')}
              >
                <tab.component />
              </TabPane>
            ))}
          </TabContent>
        </div>
      </Container>
    </div>
  )
}

export default memo(Setting)

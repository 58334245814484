import CustomButton from '_components/CustomButton'
import { DeliveryContext } from '_contexts/DeliveryContext'
import React, { useContext, useEffect, useState } from 'react'
import { DateRange } from 'react-date-range'
import { IoSave } from 'react-icons/io5'
import { TiCancel } from 'react-icons/ti'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

function ModalFulfillmentDate() {
  const {
    isModalOpen,
    toggleModal,
    getRangeFulfillmentDate,
    rangeDate: fulfillmentDate,
  } = useContext(DeliveryContext)

  const [rangeDate, setRangeDate] = useState([])

  useEffect(() => {
    setRangeDate(fulfillmentDate)
  }, [JSON.stringify(fulfillmentDate)])

  const formatDate = (date) => {
    const { startDate, endDate, key } = date[0]
    const newStartDate = new Date(startDate?.setHours(0, 0, 0, 0))
    const newEndDate = new Date(endDate?.setHours(23, 59, 59, 999))

    return [
      {
        startDate: newStartDate,
        endDate: newEndDate,
        key,
      },
    ]
  }

  const handleSubmit = () => {
    toggleModal()
    getRangeFulfillmentDate(formatDate(rangeDate))
  }

  const handleCancel = () => {
    toggleModal()
  }

  return (
    <Modal isOpen={isModalOpen} toggle={toggleModal} className='modal-fulfillment-date'>
      <ModalHeader toggle={toggleModal}>Fulfillment Date</ModalHeader>
      <ModalBody>
        <DateRange
          editableDateInputs={true}
          onChange={(item) => setRangeDate([item.selection])}
          moveRangeOnFirstSelection={false}
          ranges={rangeDate}
          rangeColors={['#15cdca']}
        />
      </ModalBody>
      <ModalFooter className='border-top-0 pt-0'>
        <div className='d-flex align-items-center flex-row-reverse gap-3 m-0'>
          <CustomButton
            text='OK'
            variant='contained'
            onClick={handleSubmit}
            color='aqua'
            className='mt-0'
            icon={<IoSave />}
          />
          <CustomButton
            text='Cancel'
            variant='outlined'
            onClick={handleCancel}
            color='slate'
            className='mt-0'
            icon={<TiCancel />}
          />
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default ModalFulfillmentDate

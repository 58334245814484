import InputField from '_components/InputField'
import React from 'react'
import CurrencyFormat from 'react-currency-format'
import { Controller } from 'react-hook-form'
import { MdOutlineAttachMoney } from 'react-icons/md'

function DeliveryFee({ checkedDeliveryFee, handleAddDeliveryFee, deliveryFee, errors, control }) {
  return (
    <div className='row align-items-center mb-3'>
      <div className='col-6'>
        <span className='d-inline-block' style={{ fontSize: 14 }}>
          Add Delivery Fee
        </span>
      </div>
      <div className='col-6 d-flex justify-content-end'>
        <InputField
          type='checkbox'
          id='deliveryFee'
          checked={checkedDeliveryFee}
          onChange={(e) => handleAddDeliveryFee(e)}
          isSwitch
          width='auto'
        />
      </div>
      {checkedDeliveryFee && (
        <>
          <div className='w-100'></div>
          <div className='col-4 mt-2'>
            <Controller
              name='deliveryFee'
              defaultValue={deliveryFee || ''}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputField
                  as={CurrencyFormat}
                  value={value}
                  displayType={'input'}
                  thousandSeparator={true}
                  decimalScale={2}
                  onValueChange={(values) => {
                    onChange(values.value)
                  }}
                  error={errors?.deliveryFee?.message}
                  icon={<MdOutlineAttachMoney />}
                />
              )}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default DeliveryFee

import React from 'react'

const LoadingSkeletonShopItem = () => (
  <div className="shop-item skeleton-item">
    <div className="wrap-banner skeleton"></div>
    <div className="shop-content">
      <div className="shop-info">
        <div className="shop-name skeleton"></div>
        <div className="shop-description">
          <div className="skeleton"></div>
          <div className="skeleton"></div>
        </div>
      </div>
      <div className="shop-footer">
        <div className="shop-social-links">
          {[1, 2, 3, 4].map((i) => (
            <div key={i} className="social-link skeleton"></div>
          ))}
        </div>
        <div className="author-created">
          <div className="author">
            <div className="author-avatar skeleton"></div>
            <div className="skeleton" style={{ width: '80px' }}></div>
          </div>
          <div className="created-date">
            <div className="skeleton" style={{ width: '100px' }}></div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

function LoadingSkeletonShopList() {
  return (
    <div className="shop-list">
      {[1, 2, 3, 4].map((item) => (
        <LoadingSkeletonShopItem key={item} />
      ))}
    </div>
  )
}

export default LoadingSkeletonShopList

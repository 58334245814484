/* eslint-disable no-confusing-arrow */
import virtualShopApi from '_api/virtualShop'
import { TURN_ON, VIEW_GRID } from '_utils/constant'
import {
  removeDataShopDetail,
  removeSelectedShopLocalStorage,
  setDataShopDetail,
  setSelectedShopLocalStorage,
  setViewDefault,
} from '_utils/localData'

import {
  ADD_CATEGORY,
  ADD_PURCHASE,
  CLEAR_DELIVERY_OPTION,
  CLEAR_MY_SHOPS,
  CLEAR_PICKUP_OPTION,
  CLEAR_SHOP_ACTIVE,
  CLEAR_SHOP_DETAIL,
  CLEAR_SHOP_INACTIVE,
  CREATE_SHOP,
  DELETE_MY_SHOP,
  FETCH_CATEGORY,
  FETCH_LIST_SHOP_DELETED,
  FETCH_LIST_SUBSCRIPTION_BY_SHOP,
  FETCH_MY_SHOPS,
  FETCH_SHOP_DETAILS,
  FETCH_SHOP_OPENING,
  FETCH_SHOP_SELECTED,
  GET_SHOP_DETAIL_BY_HOST,
  RESTORE_MY_SHOP,
  SELECTED_SHOP,
  SELECT_THEME_COLOR,
  SET_LIST_SHOP_ACTIVE,
  SET_LIST_SHOP_INACTIVE,
  SET_SHOP_ID,
  SET_VIEW_DEFAULT,
  UPDATE_MY_SHOPS,
  UPDATE_SHOP_DETAIL,
  UPDATE_SHOP_IN_MY_SHOPS,
} from '../constants/shopConstants'

const initialState = {
  allShops: [],
  loadingAllShopsOpening: true,
  loadingListOrdersShopByHost: true,
  callNewAPIListOrder: false,
  listCategory: [],
  listPurchase: [],
  loadingAddCategory: false,
  shopId: null,
  listSubscriptionByShop: [],
  shopDetail: {},
  viewDefault: VIEW_GRID,
  myShops: [],
  myShopsDeleted: [],
  selectedShop: {},
  listShopActive: [],
  listShopInactive: [],
  colorsTheme: [],
  hasLoadedMyShops: false,
  hasLoadedDeletedShops: false,
}

// Add new actions
export const SET_HAS_LOADED_MY_SHOPS = 'SET_HAS_LOADED_MY_SHOPS'
export const SET_HAS_LOADED_DELETED_SHOPS = 'SET_HAS_LOADED_DELETED_SHOPS'
export const UPDATE_SHOP_NAME = 'UPDATE_SHOP_NAME'

export const pushToMyShops = (shopCreated) => (dispatch, getState) => {
  const { shop } = getState()
  const { myShops } = shop
  dispatch({
    type: CREATE_SHOP,
    payload: {
      myShops: [shopCreated, ...myShops],
    },
  })

  localStorage.setItem('myShops', JSON.stringify([shopCreated, ...myShops]))
}

export const clearListShopInactive = () => (dispatch) => {
  dispatch({
    type: CLEAR_SHOP_INACTIVE,
    payload: {
      listShopInactive: [],
    },
  })
}

export const clearListShopActive = () => (dispatch) => {
  dispatch({
    type: CLEAR_SHOP_ACTIVE,
    payload: {
      listShopActive: [],
    },
  })
}

export const selectThemeColor = (colorsTheme) => (dispatch) => {
  dispatch({
    type: SELECT_THEME_COLOR,
    payload: {
      colorsTheme,
    },
  })
}

export const setListShopInactive = (data) => (dispatch) => {
  dispatch({
    type: SET_LIST_SHOP_INACTIVE,
    payload: { listShopInactive: data },
  })
}

export const setListShopActive = (data) => (dispatch) => {
  dispatch({
    type: SET_LIST_SHOP_ACTIVE,
    payload: { listShopActive: data },
  })
}

export const selectShopAndSelectShopDetail = (shop) => (dispatch) => {
  dispatch({
    type: SELECTED_SHOP,
    payload: { selectedShop: shop, shopDetail: shop },
  })
  setSelectedShopLocalStorage(shop)
  setDataShopDetail(shop)
}

export const getShopDetailByHost = (shopId) => async (dispatch) => {
  try {
    const { msgResp } = await virtualShopApi.getShopDetailByHost(shopId)
    const sortedListProducts = msgResp.products
      .sort((a, b) => b.createdAt - a.createdAt)
      .map((product) => ({
        ...product,
        isOpenSubItems: Boolean(product.isOpenSubItems),
      }))

    dispatch({
      type: GET_SHOP_DETAIL_BY_HOST,
      payload: {
        selectedShop: { ...msgResp, products: sortedListProducts },
        shopDetail: { ...msgResp, products: sortedListProducts },
      },
    })
    setSelectedShopLocalStorage(msgResp)
    dispatch(selectShopAndSelectShopDetail(msgResp))
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const restoreMyShop = (shopId) => async (dispatch, getState) => {
  const { shop } = getState()
  const { myShops, myShopsDeleted } = shop
  const shopFound = myShopsDeleted.find((item) => item.id === shopId)

  shopFound.active = TURN_ON

  const newList = myShopsDeleted.filter((item) => item.id !== shopId)

  try {
    await virtualShopApi.restoreShop(shopId)
    dispatch({
      type: RESTORE_MY_SHOP,
      payload: {
        myShops: [shopFound, ...myShops],
        myShopsDeleted: newList,
      },
    })
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const deleteMyShop = (shopId) => async (dispatch, getState) => {
  const { shop } = getState()
  const { myShops, myShopsDeleted } = shop
  const shopFound = myShops.find((item) => item.id === shopId)
  const newMyShops = myShops.filter((item) => item.id !== shopId)

  try {
    await virtualShopApi.deleteShop(shopId)
    dispatch({
      type: DELETE_MY_SHOP,
      payload: {
        myShops: newMyShops,
        myShopsDeleted: [shopFound, ...myShopsDeleted],
      },
    })
    removeDataShopDetail()
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const fetchMyShops = () => async (dispatch) => {
  try {
    const { msgResp } = await virtualShopApi.getMyShops()
    dispatch({
      type: FETCH_MY_SHOPS,
      payload: {
        myShops: msgResp,
        hasLoadedMyShops: true,
      },
    })
    localStorage.setItem('myShops', JSON.stringify(msgResp))
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}
export const clearMyShops = () => (dispatch) => {
  dispatch({
    type: CLEAR_MY_SHOPS,
    payload: {
      myShops: [],
    },
  })
}

export const fetchListShopDeleted = () => async (dispatch, getState) => {
  const { shop } = getState()
  if (shop.hasLoadedDeletedShops) return // Return if data is already loaded

  try {
    const { msgResp } = await virtualShopApi.getListShopsDeleted()
    dispatch({
      type: FETCH_LIST_SHOP_DELETED,
      payload: {
        myShopsDeleted: msgResp,
        hasLoadedDeletedShops: true,
      },
    })
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

// Add action to clear loaded states
export const clearLoadedStates = () => ({
  type: 'CLEAR_LOADED_STATES',
  payload: {
    hasLoadedMyShops: false,
    hasLoadedDeletedShops: false,
  },
})

export const setViewDefaultForShop = (viewDefault) => (dispatch) => {
  setViewDefault(viewDefault)
  dispatch({
    type: SET_VIEW_DEFAULT,
    payload: { viewDefault },
  })
}

export const getShopByShopSlug = (shopSlug) => async (dispatch) => {
  try {
    const { msgResp } = await virtualShopApi.getShopByShopSlug(shopSlug)
    const sortedListProducts = msgResp.products
      .sort((a, b) => b.createdAt - a.createdAt)
      .map((product) => ({
        ...product,
        isOpenSubItems: Boolean(product.isOpenSubItems),
      }))
    dispatch({
      type: FETCH_SHOP_DETAILS,
      payload: {
        shopDetail: { ...msgResp, products: sortedListProducts },
        selectedShop: { ...msgResp, products: sortedListProducts },
      },
    })
    dispatch(selectShopAndSelectShopDetail(msgResp))
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const getListSubscriptionByShop = (shopId) => async (dispatch) => {
  try {
    let listSubscriptionByShop = []
    const { msgResp } = await virtualShopApi.getListShopSubscriptionByShop(shopId)
    if (msgResp) {
      listSubscriptionByShop = msgResp
      dispatch({
        type: FETCH_LIST_SUBSCRIPTION_BY_SHOP,
        payload: { listSubscriptionByShop },
      })
    }
  } catch (error) {
    return error
    // throw new Error(error)
  }
}

export const addPurchase = (order) => (dispatch) => {
  if (order) {
    dispatch({
      type: ADD_PURCHASE,
      payload: order,
    })
  }
}

export const setIdShop = (shopId) => (dispatch) => {
  if (shopId) {
    dispatch({
      type: SET_SHOP_ID,
      payload: { shopId },
    })
  }
}

export const getAllShopsOpening = () => async (dispatch) => {
  try {
    // Call API from server
    const { msgResp: newAllShopsOpening } = await virtualShopApi.getAllShopsOpening()
    dispatch({
      type: FETCH_SHOP_OPENING,
      payload: { allShops: newAllShopsOpening },
    })
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const addCategory = (category, shopId) => async (dispatch, getState) => {
  try {
    const { shop } = getState()
    const { listCategory } = shop

    const { msgResp } = await virtualShopApi.createShopCategory(shopId, { name: category })
    dispatch({
      type: ADD_CATEGORY,
      payload: { listCategory: [...listCategory, msgResp] },
    })
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const getCategory = (shopId) => async (dispatch) => {
  try {
    const { msgResp } = await virtualShopApi.getShopCategory(shopId)
    dispatch({
      type: FETCH_CATEGORY,
      payload: { listCategory: msgResp },
    })
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const clearShopAndShopDetail = () => (dispatch) => {
  dispatch({
    type: CLEAR_SHOP_DETAIL,
    payload: {
      shopDetail: {},
      selectedShop: {},
    },
  })
  removeDataShopDetail()
  removeSelectedShopLocalStorage()
}

export const setShopDetail = (newShop) => (dispatch) => {
  dispatch({
    type: UPDATE_SHOP_DETAIL,
    payload: {
      shopDetail: newShop,
    },
  })
}

export const updateShopInMyShops = (shop) => (dispatch, getState) => {
  const { shop: shopState } = getState()
  const { myShops } = shopState
  const newMyShops = myShops.map((item) => {
    if (item.id === shop.id) {
      return shop
    }
    return item
  })

  dispatch({
    type: UPDATE_SHOP_IN_MY_SHOPS,
    payload: {
      myShops: newMyShops,
    },
  })
  localStorage.setItem('myShops', JSON.stringify(newMyShops))
}

export const updateShopName = (shopId, newShopName) => (dispatch, getState) => {
  const { shop } = getState()
  const { myShops } = shop
  const updatedShops = myShops.map((s) => (s.id === shopId ? { ...s, shopName: newShopName } : s))

  dispatch({
    type: UPDATE_SHOP_NAME,
    payload: {
      myShops: updatedShops,
    },
  })
  localStorage.setItem('myShops', JSON.stringify(updatedShops))
}

const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MY_SHOPS:
    case UPDATE_SHOP_IN_MY_SHOPS:
    case CLEAR_SHOP_ACTIVE:
    case CLEAR_SHOP_INACTIVE:
    case CLEAR_MY_SHOPS:
    case UPDATE_SHOP_DETAIL:
    case SELECT_THEME_COLOR:
    case CLEAR_SHOP_DETAIL:
    case SET_LIST_SHOP_INACTIVE:
    case SET_LIST_SHOP_ACTIVE:
    case GET_SHOP_DETAIL_BY_HOST:
    case SELECTED_SHOP:
    case FETCH_SHOP_SELECTED:
    case RESTORE_MY_SHOP:
    case DELETE_MY_SHOP:
    case FETCH_MY_SHOPS:
    case FETCH_LIST_SHOP_DELETED:
    case SET_VIEW_DEFAULT:
    case CREATE_SHOP:
    case FETCH_SHOP_OPENING:
    case ADD_CATEGORY:
    case FETCH_SHOP_DETAILS:
    case FETCH_CATEGORY:
    case SET_SHOP_ID:
    case FETCH_LIST_SUBSCRIPTION_BY_SHOP:
    case CLEAR_PICKUP_OPTION:
    case CLEAR_DELIVERY_OPTION:
    case 'CLEAR_LOADED_STATES':
    case UPDATE_SHOP_NAME:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default shopReducer

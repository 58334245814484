import eventApi from '_api/event'
import uploadApi from '_api/upload'
import CustomSwalFire from '_components/CustomSwalFire'
import InputField from '_components/InputField'
import useQuery from '_hooks/useQuery'
import noImage from '_images/noimage02.svg'
import { DEFAULT_SUB_ITEM, SHIFT_DOWN, SHIFT_UP } from '_utils/constant'
import { idGenerator } from '_utils/functions/generator'
import React, { useEffect, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { useFieldArray } from 'react-hook-form'
import { FaCog, FaCopy, FaTrash } from 'react-icons/fa'
import { MdArrowDropDown, MdArrowDropUp, MdOutlineAttachMoney } from 'react-icons/md'
import { useToasts } from 'react-toast-notifications'
import EditableSelect from './EditableSelect'
import ProductSettingModal from './ProductSettingModal'
import SubItem from './SubItem'

const ProductItem = (props) => {
  const {
    productIndex,
    product,
    totalListProduct,
    control,
    register,
    removeProduct,
    insertProduct,
    swapProduct,
    watch,
    setValue,
    errors,
    getValues,
  } = props
  const {
    fields,
    remove: removeSubItem,
    insert: insertSubItem,
    append: appendSubItem,
  } = useFieldArray({
    control,
    name: `products.${productIndex}.subItems`,
  })

  const { addToast } = useToasts()
  const query = useQuery()
  const eidParam = query.get('id')
  const watchFieldArray = watch(`products.${productIndex}.subItems`)
  const controlledFields = fields.map((field, _index) => ({
    ...field,
    ...watchFieldArray[_index],
  }))

  const [imgFileUrl, setImgFileUrl] = useState('')

  useEffect(() => {
    if (product?.photoUrls?.length) {
      setImgFileUrl(product?.photoUrls[0])
    } else {
      setImgFileUrl('')
    }
  }, [product?.photoUrls?.length, imgFileUrl])

  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  const onUserImageUploaded = async (event) => {
    const { files } = event.target
    if (files) {
      const img = files[0]
      if (img) {
        const { msgResp } = await uploadApi.uploadPhoto(files)
        setImgFileUrl(URL.createObjectURL(img))
        setValue(`products.${productIndex}.photoUrls`, [msgResp.url])
      } else {
        setImgFileUrl('')
        setValue(`products.${productIndex}.photoUrls`, [])
      }
    }
  }

  const removeImage = () => {
    setImgFileUrl('')
    setValue(`products.${productIndex}.photoUrls`, [])
  }

  const handleChangeSequence = (index, direction) => {
    if (index - 1 >= 0 && direction === SHIFT_UP) {
      swapProduct(index, index - 1)
    }
    if (index + 1 < totalListProduct && direction === SHIFT_DOWN) {
      swapProduct(index, index + 1)
    }
  }

  const onRemoveProduct = async () => {
    if (product.id.includes('-')) {
      removeProduct(productIndex)
    } else {
      CustomSwalFire.delete({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',

        onConfirm: async () => {
          if (!product?.isCopied) {
            await eventApi.checkBeforeRemoveProduct(eidParam, product.id)
            addToast('Product has been deleted!', {
              appearance: 'success',
              autoDismiss: true,
            })
          }
          removeProduct(productIndex)
        },
        onError: (error) => {
          addToast(error.message || error.msgResp, {
            appearance: 'error',
            autoDismiss: true,
          })
        },
      })
    }
  }

  return (
    <div className='product-item'>
      <div className='product-item__header'>
        <div className='product-item__upload-section'>
          <div className='product-item__upload'>
            <img
              src={imgFileUrl || noImage}
              alt='Product'
              className={`product-item__image ${
                !imgFileUrl ? 'product-item__image--no-image' : ''
              }`}
            />
            <div className='product-item__upload-text' style={imgFileUrl ? { zIndex: -1 } : {}}>
              <span>Upload Image</span>
            </div>
            <input
              type='file'
              accept='image/*'
              className='product-item__input-file'
              onChange={onUserImageUploaded}
            />
            {imgFileUrl && <div className='product-item__remove-image' onClick={removeImage}></div>}
          </div>
        </div>

        <div className='product-item__content d-flex flex-column'>
          <div className='product-item__form'>
            <div className='product-item__row mb-2'>
              <label className='product-item__label col-12'>Item name</label>
              <div className='col-12'>
                <EditableSelect
                  productNameDefault={getValues(`products.${productIndex}.name`)}
                  productIndex={productIndex}
                  control={control}
                  setValue={setValue}
                  name={`products.${productIndex}.name`}
                  hasError={errors?.products?.[productIndex]?.name}
                  errorMessage={errors?.products?.[productIndex]?.name?.message}
                />
              </div>
            </div>

            <div className='product-item__row mb-2'>
              <label className='product-item__label col-12'>Description</label>
              <div className='col-12'>
                <InputField
                  type='text'
                  placeholder='(Optional)'
                  register={register(`products.${productIndex}.description`)}
                  value={watch(`products.${productIndex}.description`) || ''}
                  onChange={(e) => setValue(`products.${productIndex}.description`, e.target.value)}
                />
              </div>
            </div>

            <div className='product-item__row product-item__row--price'>
              <div className='w-75'>
                <label className='product-item__label col-12'>Price</label>
                <div className='position-relative'>
                  <MdOutlineAttachMoney
                    size={20}
                    style={{
                      position: 'absolute',
                      left: 5,
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  />
                  <CurrencyFormat
                    value={watch(`products.${productIndex}.price`)}
                    onValueChange={(values) => {
                      setValue(`products.${productIndex}.price`, values.value)
                    }}
                    thousandSeparator=','
                    decimalScale={2}
                    displayType='input'
                    placeholder='Price'
                    className='form-control custom-input'
                    style={{ paddingLeft: 24 }}
                  />
                </div>
                {errors?.products?.[productIndex]?.price && (
                  <small className='text-danger'>
                    {errors?.products?.[productIndex]?.price?.message}
                  </small>
                )}
              </div>

              <div className='product-item__actions-wrapper'>
                <FaCog
                  className='product-item__action-btn product-item__action-btn--add'
                  onClick={toggle}
                />
                <FaCopy
                  className='product-item__action-btn product-item__action-btn--copy'
                  onClick={() =>
                    insertProduct(productIndex + 1, {
                      ...product,
                      id: idGenerator(),
                      pid: idGenerator(),
                      isCopied: true,
                    })
                  }
                />
                <FaTrash
                  className='product-item__action-btn product-item__action-btn--remove'
                  onClick={onRemoveProduct}
                />
                <div className='product-item__arrows'>
                  <MdArrowDropUp
                    className='product-item__arrow product-item__arrow--up'
                    onClick={() => handleChangeSequence(productIndex, SHIFT_UP)}
                  />
                  <MdArrowDropDown
                    className='product-item__arrow product-item__arrow--down'
                    onClick={() => handleChangeSequence(productIndex, SHIFT_DOWN)}
                  />
                </div>
              </div>
            </div>
            <a
              onClick={() => appendSubItem({ ...DEFAULT_SUB_ITEM })}
              className='product-item__text-add-subitem'
            >
              Add sub item
            </a>
          </div>
        </div>
      </div>

      {controlledFields.map((item, i) => (
        <SubItem
          key={i}
          productIndex={productIndex}
          nestIndex={i}
          subItem={item}
          {...{
            control,
            register,
            removeSubItem,
            insertSubItem,
            appendSubItem,
            watch,
            errors,
            setValue,
          }}
        />
      ))}

      <ProductSettingModal
        isOpen={isOpen}
        toggle={toggle}
        register={register}
        productIndex={productIndex}
        product={product}
        setValue={setValue}
        errors={errors}
      />
    </div>
  )
}

export default ProductItem

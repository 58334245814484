import TooltipDropdownItem from '_components/TooltipDropdownItem'
import React from 'react'
import {
  ButtonGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap'
import TruncatedText from '../../../components/TruncatedText'

export default function TabEvent({
  groupedEvents,
  handleSelectEvent,
  selectedEventName,
  tabOrderEvent,
}) {
  return (
    <ButtonGroup className='ms-2 tab-event'>
      <UncontrolledDropdown>
        <DropdownToggle
          tag={'button'}
          className={`custom-button rounded-pill mt-0 gray outlined ${
            tabOrderEvent ? 'active' : undefined
          }`}
        >
          <TruncatedText text={selectedEventName} id={`tooltip-${selectedEventName}-selected`} />
        </DropdownToggle>
        <DropdownMenu>
          <div
            className='d-flex align-items-stretch wrap-dropdown-event w-100'
            style={groupedEvents?.length >= 5 ? { overflowX: 'scroll' } : {}}
          >
            {groupedEvents?.length > 0 ? (
              groupedEvents.map((group, groupIndex) => (
                <div
                  key={groupIndex}
                  className='w-100 events-column d-inline-block'
                  style={{
                    verticalAlign: 'top',
                    maxWidth: groupedEvents?.length >= 5 ? '120px' : '200px',
                  }}
                >
                  {group.map((event) => (
                    <TooltipDropdownItem
                      key={event.id}
                      event={event}
                      handleSelectEvent={handleSelectEvent}
                    />
                  ))}
                </div>
              ))
            ) : (
              <DropdownItem disabled className='px-3 py-0'>
                No event
              </DropdownItem>
            )}
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </ButtonGroup>
  )
}

import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const PaymentCodeSkeleton = () => (
  <div className='container payment-code-skeleton'>
    <div className='payment-code px-3 f-16 mt-0'>
      <div className='contact-details-summary-skeleton'>
        {/* Contact Details Section */}
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <Skeleton width={150} height={24} />
        </div>
        <div className='mb-4 contact-details-skeleton'>
          <div className='bg-white p-3 rounded-3'>
            <Skeleton count={3} height={24} className='mb-2' />
          </div>
        </div>

        {/* Order Summary Section */}
        <div className='summary-skeleton'>
          <Skeleton width={150} height={24} className='mb-3' />
          <div className='bg-white p-3 rounded-3'>
            {/* Product items */}
            {[1, 2].map((item) => (
              <div key={item} className='mb-3'>
                <div className='d-flex gap-3'>
                  <Skeleton width={80} height={80} />
                  <div className='flex-grow-1'>
                    <Skeleton count={2} height={24} className='mb-2' />
                  </div>
                </div>
              </div>
            ))}

            {/* Price summary */}
            <div className='mt-4'>
              <Skeleton count={3} height={24} className='mb-2' />
            </div>
          </div>
        </div>
      </div>

      {/* Payment Section */}
      <div className='qr-code-skeleton bg-white p-3 rounded-3 d-flex flex-column'>
        <div className='flex-1'>
          <Skeleton height={24} className='mb-3' />
        </div>
        <div className='text-center h-100 d-flex flex-column'>
          <Skeleton width={200} height={200} className='mb-3' />
          <div className='flex-1 mt-auto'>
            <Skeleton height={40} />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default PaymentCodeSkeleton

import Avatar from '_components/Avatar'
import bgAvatar from '_images/bg_avatar.png'
import { fetchListInvoiceSubscription } from '_redux/modules/subscription'
import { getNotifications } from '_redux/modules/user'
import { getUserInfo } from '_utils/localData'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link, Route, Switch } from 'react-router-dom'
import ChangePasswordPage from './ChangePassword'
import MyShops from './MyShops'
import NotificationsPage from './Notifications'
import UpdateProfilePage from './UpdateProfile'
import UserSkeleton from './components/Skeletons/UserSkeleton'
import './style.scss'

// const PAYMENT_SERVICE_DESKTOP_PRICE = 50

const Index = () => {
  const dispatch = useDispatch()
  const user = getUserInfo()
  const isAdminConsole =
    localStorage.getItem('isAdminConsole') && localStorage.getItem('isAdminConsole') === 'true'

  const routes = [
    {
      id: 0,
      link: '/user/profile',
      label: 'Notification',
      icon: 'icon-noti',
    },
    {
      id: 1,
      link: '/user/update',
      label: 'Edit Profile',
      icon: 'icon-profile',
    },
    {
      id: 2,
      link: '/user/change-password',
      label: 'Change Password',
      icon: 'icon-pin',
    },
    {
      id: 3,
      link: '/user/my-shops',
      label: 'My Shops',
      icon: 'icon-shop',
    },
  ]

  const avatarUrl = user?.photoUrl || process.env.REACT_APP_AVATAR_URL + user?.name

  useEffect(() => {
    const fetchData = async () => {
      const promises = [
        // dispatch(fetchListInvoiceLazada()),
        isAdminConsole && dispatch(fetchListInvoiceSubscription()),
        dispatch(getNotifications),
      ].filter(Boolean)
      await Promise.all(promises)
    }

    if (user) {
      fetchData().then(() => {})
    }
  }, [JSON.stringify(user), isAdminConsole])

  if (!user) return <UserSkeleton />

  return (
    <div className='container'>
      <div className='profile-page mt-0 pt-0'>
        <div className='profile-avatar-bg'>
          <img src={bgAvatar} alt='avatar' />
        </div>
        <div className='c-form c-form--profile'>
          <Avatar photoUrl={avatarUrl} />
          <div className='upload-name mt-2'>{user?.name}</div>
          <div className='nav-profile'>
            <ul className='justify-content-center align-items-center'>
              {routes.map((route, index) => (
                <li
                  key={route.id}
                  style={{
                    maxWidth: '22%',
                  }}
                >
                  <Link to={route.link}>
                    <i className={route.icon}></i>
                    <span style={index === 2 ? { marginTop: 6 } : null}>{route.label}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Switch>
          <Route path='/user/profile' component={NotificationsPage} />
          <Route path='/user/update' component={UpdateProfilePage} />
          <Route path='/user/change-password' component={ChangePasswordPage} />
          <Route path='/user/my-shops' component={MyShops} />
        </Switch>
      </div>
    </div>
  )
}

export default Index

/* eslint-disable radix */
import CustomButton from '_components/CustomButton'
import MyGallery from '_components/MyGallery'
import {
  BUYER_ORDER_UNPAID,
  HOST_ORDER_FULFILLED,
  HOST_ORDER_VERIFIED,
  SINGAPORE_CODE,
} from '_utils/constant'
import { maskString } from '_utils/function'
import { getUserToken } from '_utils/localData'
import { generatePayNowStr } from '_utils/paynow'
import QRCode from 'qrcode'
import React, { useEffect, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { FaUpload } from 'react-icons/fa'
import { MdOutlinePayment } from 'react-icons/md'

const Payment = (props) => {
  const {
    confirmPayment,
    final,
    phone,
    isSubmittedPayment,
    stringRefNumber = '',
    orderDetailsByRef,
    onUserImageUploaded,
    isUploaded,
    isOrderRef,
    previewImg,
  } = props
  const userToken = getUserToken()

  const [src, setSrc] = useState('')
  const [payNowCode, setPayNowCode] = useState('')
  const [space, setSpace] = useState('')
  const [rand, setRand] = useState(0) // Spaces number

  const date = new Date()

  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = String(date.getFullYear())

  // YYYY-MM-DD if current is 2023-05-16 then expiry date is 2024-05-16
  // output: "20240516"
  const expiryDate = (parseInt(year) + 1).toString() + month + day

  const options = {
    uen: `${SINGAPORE_CODE}${phone?.replace(`${SINGAPORE_CODE}`, '')}`,
    editable: 0,
    amount: Number(final.toFixed(2)),
    refNumber: `${stringRefNumber}${space}`,
    expiry: expiryDate,
  }

  const onReloadQRCode = () => {
    const newRand = (rand + 1) % 5
    setRand(newRand)
    setSpace(' '.repeat(newRand))
  }

  useEffect(() => {
    let isMounted = true

    setPayNowCode(generatePayNowStr(options, orderDetailsByRef?.shopName))
    QRCode.toDataURL(generatePayNowStr(options, orderDetailsByRef?.shopName), {
      errorCorrectionLevel: 'H',
      color: {
        dark: '#981c84',
      },
    }).then((dataUrl) => {
      if (isMounted) {
        // Check if the component is still mounted before updating the state
        setSrc(dataUrl)
      }
    })

    return () => {
      isMounted = false // Cleanup function to cancel any subscriptions or asynchronous tasks
    }
  }, [space, options.amount])

  const isVerifiedOrder = [HOST_ORDER_VERIFIED, HOST_ORDER_FULFILLED].includes(
    orderDetailsByRef?.status
  )

  // Add this helper function before renderButton
  const hasPaymentScreenshot = () =>
    Boolean(
      orderDetailsByRef?.orderDelivery?.paymentScreenshot ||
        orderDetailsByRef?.orderPickup?.paymentScreenshot
    )

  const renderButton = () => {
    // Order status checks
    const isUnpaid = orderDetailsByRef?.buyerStatus === BUYER_ORDER_UNPAID
    const hasUserToken = Boolean(userToken)

    // Button visibility conditions
    const shouldShowUpdateOrderButton = hasUserToken && !isVerifiedOrder
    const shouldShowConfirmPaymentButton =
      (isUnpaid && !isUploaded && (isOrderRef || !hasUserToken || !isOrderRef)) ||
      (!hasUserToken && !hasPaymentScreenshot())

    // Early return if no button should be shown
    if (!shouldShowUpdateOrderButton && !shouldShowConfirmPaymentButton) {
      return null
    }

    // Get button text based on state
    const getButtonText = () => {
      if (isSubmittedPayment) return 'Processing...'
      if (hasPaymentScreenshot()) return 'Update Order'
      return 'Make Payment'
    }

    return (
      <CustomButton
        onClick={confirmPayment}
        isDisabled={isSubmittedPayment || isUploaded}
        text={getButtonText()}
        color='aqua'
        variant='contained'
        icon={<MdOutlinePayment />}
      />
    )
  }

  return (
    <div id='allowPaymentHtml' style={{ display: 'block' }}>
      <div id='pendingPaymentHtmlBlock' className='row-bot' style={{ display: 'block' }}>
        <div className='group-list group-list--event detail-event'>
          <div className='detail-list-news detail-list-news--org'>
            <h3 className='detail-list-news__title text-center'>
              Please make a Paynow/Paylah transfer of
              <span id='amount2' className={'text-secondary mx-1 fw-bold'}>
                $
                {final ? (
                  <CurrencyFormat
                    value={final || 0}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                ) : (
                  0
                )}
              </span>
              to
            </h3>
            <h2 id='sellerPhone' className={'detail-list-news__phone text-center'}>
              {phone}
            </h2>
            <p className='detail-list-news__instruction text-center mb-2'>
              Please include order number
            </p>
            <h5
              className={'detail-list-news__order-number text-center mb-3'}
              style={{
                color: '#981C84',
              }}
            >
              {maskString(orderDetailsByRef?.ref, 6)}
            </h5>

            <div className='detail-list-news__qr-box text-center'>
              <div id='qrcode' className='detail-list-news__qr-code' title={payNowCode}>
                <canvas width='256' height='256' style={{ display: 'none' }}></canvas>
                <img
                  src={src}
                  style={{ display: 'block' }}
                  width='256'
                  height='256'
                  alt={'show-image'}
                />
              </div>
              {!isVerifiedOrder ? (
                <div
                  className='detail-list-news__reload-button'
                  style={{ textAlign: 'right', marginTop: 15 }}
                >
                  <button
                    onClick={onReloadQRCode}
                    style={{ backgroundColor: 'transparent', border: 'none', color: '#939091' }}
                  >
                    Click to reload QRCode
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <p className={'detail-list-news__verification-info text-center'}>
          To help to expedite verification, please attached the screenshot of payment confirmation.
          Once your transaction has been verified, your order will be processed.
        </p>
        <div className='detail-list-news__form c-form'>
          {orderDetailsByRef?.paymentScreenshot &&
          orderDetailsByRef?.buyerStatus !== BUYER_ORDER_UNPAID ? (
            <div className='detail-list-news__uploaded-image mx-auto upload-image rounded-0 shadow-none'>
              <MyGallery image={orderDetailsByRef.paymentScreenshot} className='h-100 w-100' />
            </div>
          ) : previewImg ? (
            <div className='detail-list-news__preview-image mx-auto upload-image rounded-0 shadow-none position-relative'>
              <img src={previewImg} className='h-100 w-100' />
              <div className='upload-image upload-screenshot shadow-none rounded-0 position-absolute top-0 start-0 bottom-0 end-0 opacity-0'>
                <div className='upload-image-txt'>
                  <i className='icon-capture'></i>
                  <span>Change Screenshot</span>
                </div>
                <div className='u-file-upload__item'>
                  <div className='u-file-upload__wrap'>
                    <a className='c-upload preview-images-zone'>
                      <input
                        type='file'
                        accept='image/*'
                        size='40'
                        className='inputFile'
                        onChange={onUserImageUploaded}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='detail-list-news__upload-image upload-image upload-screenshot shadow-none rounded-0'>
              <div className='upload-image-txt'>
                <i className='icon-capture'></i>
                <span>Screenshot</span>
              </div>
              <div className='u-file-upload__item'>
                <div className='u-file-upload__wrap'>
                  <a className='c-upload preview-images-zone'>
                    <input
                      type='file'
                      accept='image/*'
                      size='40'
                      className='inputFile'
                      onChange={onUserImageUploaded}
                    />
                  </a>
                </div>
              </div>
            </div>
          )}

          {!isVerifiedOrder && previewImg && userToken && (
            <div className={'detail-list-news__reupload-button w-100 position-relative'}>
              <label className={'d-flex w-100 align-items-center justify-content-center mb-0 mt-3'}>
                <CustomButton
                  text='Re-upload'
                  icon={<FaUpload />}
                  variant='outlined'
                  color='aqua'
                  className='w-auto shadow-none mt-0'
                />
              </label>
              <input
                type='file'
                accept='image/*'
                disabled={isVerifiedOrder}
                size='40'
                className={
                  'w-100 h-100 position-absolute top-0 end-0 start-0 bottom-0 opacity-0 cursor-pointer'
                }
                onChange={onUserImageUploaded}
              />
            </div>
          )}
        </div>

        {renderButton()}
      </div>
    </div>
  )
}

Payment.displayName = 'Payment'
export default Payment

import CustomButton from '_components/CustomButton'
import { DEFAULT_DELIVERY_OPTION, LIST_TIME } from '_utils/constant'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import DatePicker from '../components/DatePicker'

const DeliveryOptionsModal = (props) => {
  const {
    modal,
    handleOnClickOk,
    deliveryZones,
    deliveryTime,
    onChangeZoneDelivery = () => {},
    onChangeTimeDelivery,
    handleOnRemoveDeliveryZone = () => {},
    handleAddMoreDeliveryZone = () => {},
    deliveryHourStart,
    setDeliveryHourStart,
    deliveryHourEnd,
    setDeliveryHourEnd,
    toggle,
    selectedGroup = '',
    setSelectedGroup,
    optionGroup,
    isSubmitting = false,
  } = props

  useEffect(() => {
    if (deliveryZones.length === 0) {
      handleAddMoreDeliveryZone(
        JSON.parse(JSON.stringify([DEFAULT_DELIVERY_OPTION, DEFAULT_DELIVERY_OPTION]))
      )
    }
  }, [deliveryZones])

  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader toggle={toggle} />
      <ModalBody>
        <div className='container p-0 '>
          <h5 className='text-center mb-3'>Schedule Delivery</h5>

          {selectedGroup ? (
            <div className='input-delivery-group mb-2'>
              <select value={selectedGroup} onChange={(e) => setSelectedGroup(e.target.value)}>
                {optionGroup?.map((group, index) => (
                  <option value={group.gid} key={index}>
                    {group.name}
                  </option>
                ))}
              </select>
            </div>
          ) : null}

          <div className='input-delivery-date'>
            <DatePicker onChange={onChangeTimeDelivery} value={deliveryTime} />
          </div>

          <div className='group-hour-delivery mt-2 mb-2'>
            <div className='input-delivery-hour d-flex flex-column'>
              <div>Between</div>
              <select
                value={deliveryHourStart}
                onChange={(e) => setDeliveryHourStart(e.target.value)}
              >
                {LIST_TIME.map((item, index) => (
                  <option key={index}>{item.text}</option>
                ))}
              </select>
            </div>

            <div className='pb-4 pt-4 font-weight-bold icon-cross'>-</div>

            <div className='input-delivery-hour'>
              <div>To</div>
              <select value={deliveryHourEnd} onChange={(e) => setDeliveryHourEnd(e.target.value)}>
                {LIST_TIME.map((item, index) => (
                  <option key={index}>{item.text}</option>
                ))}
              </select>
            </div>
          </div>

          <div className={'sub-item-option align-items-center modal-delivery'}>
            <p className='text-left'>Delivery Fee</p>
            {deliveryZones.map((_, index) => (
              <div className='d-flex' key={index}>
                <div className='sub-item-info col-6 d-flex align-items-center'>
                  <span className='sub-txt'>{index + 1}. </span>
                  <input
                    className={'form-control delivery-option'}
                    placeholder={`Area ${index + 1}`}
                    value={deliveryZones[index].name}
                    onChange={(e) => {
                      onChangeZoneDelivery(index, 'name', e.target.value)
                    }}
                  />
                </div>
                <div className='sub-item-info col-5  d-flex align-items-center'>
                  <span className='sub-txt'>$ </span>
                  <input
                    className={'form-control delivery-option'}
                    type={'number'}
                    defaultValue={Number(deliveryZones[index].price)}
                    onChange={(e) => {
                      onChangeZoneDelivery(index, 'price', Number(e.target.value))
                    }}
                  />
                </div>
                <div
                  className='d-flex justify-content-end align-items-center col-1'
                  style={{
                    WebkitFilter: 'grayscale(100%)',
                    filter: 'grayscale(100%)',
                  }}
                  onClick={() => handleOnRemoveDeliveryZone(index)}
                >
                  <a className='btn-remove' />
                </div>
              </div>
            ))}
            <div className='add-more'>
              <span className='btn-add-more' onClick={handleAddMoreDeliveryZone}>
                Add more
              </span>
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-6 m-auto'>
              <CustomButton
                text={isSubmitting ? <Spinner color='light' size='sm' /> : 'OK'}
                className='btn--org btn--org02'
                onClick={handleOnClickOk}
              />
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default DeliveryOptionsModal

DeliveryOptionsModal.propTypes = {
  modal: PropTypes.bool,
  handleOnClickOk: PropTypes.func,
  handleRemoveRemember: PropTypes.func,
  deliveryZones: PropTypes.array,
  deliveryTime: PropTypes.object,
  onChangeZoneDelivery: PropTypes.func,
  onChangeDateDelivery: PropTypes.func,
  handleOnRemoveDeliveryZone: PropTypes.func,
  handleAddMoreDeliveryZone: PropTypes.func,
  hideDeliveryTime: PropTypes.bool,
  hideDeliveryZones: PropTypes.bool,
}

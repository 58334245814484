/* eslint-disable multiline-ternary */
import authApi from '_api/auth'
import SocialButton from '_components/SocialButton'
import imgTop from '_images/img_top.svg'
import logoLeft from '_images/logo-co-hoot.jpg'
import { fetchListInvoiceSubscription } from '_redux/modules/subscription'
import { fetchUserInfo, getNotifications } from '_redux/modules/user'
import { GOOGLE } from '_utils/constant'
import { getCartCheckout, getCreateShopData, getUserToken, setUserToken } from '_utils/localData'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { FormGroup, Label, Spinner } from 'reactstrap'
import { fetchMyShops } from '../../redux/modules/shop'
import { FACEBOOK } from '../../utils/constant'
import './style.scss'

const LoginPage = () => {
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)
  const isMountedRef = useRef(false)

  const history = useHistory()
  const location = useLocation()
  const userToken = getUserToken()
  // const dataCreateShop = getCreateShopData()

  const urlBeforeLogout = localStorage.getItem('URL_BEFORE_LOGOUT')

  useEffect(() => {
    isMountedRef.current = true
    return () => {
      isMountedRef.current = false
    }
  }, [])

  // useEffect(() => {
  //   if (userToken && !dataCreateShop) history.push('/virtual-shop/list')
  // }, [userToken, JSON.stringify(dataCreateShop)])

  const validateForm = () => {
    if (!email) {
      setErrorMessage('Invalid email')
      return false
    }
    if (!password) {
      setErrorMessage('Invalid password')
      return false
    }
    return true
  }

  const onSubmit = async () => {
    setIsProcessing(true)
    if (!validateForm()) {
      setIsProcessing(false)
      return
    }
    const data = { email: email.toLowerCase().trim(), password: password.toLowerCase().trim() }
    try {
      const res = await authApi.login(data)
      setUserToken(res.msgResp.token)

      const fetchDataAndRedirect = async (path, url) => {
        await Promise.all([
          dispatch(fetchMyShops()),
          dispatch(fetchUserInfo()),
          // dispatch(fetchListInvoiceLazada()),
          dispatch(fetchListInvoiceSubscription()),
          dispatch(getNotifications),
        ])
        if (isMountedRef.current) {
          setIsProcessing(false)
        }

        if (url) {
          // Check if the URL is absolute
          const isAbsoluteUrl = url.startsWith('http://') || url.startsWith('https://')

          if (isAbsoluteUrl) {
            // If the URL is absolute, use window.location.replace
            window.location.replace(url)
          } else {
            // If the URL is relative, use history.replace
            history.replace(url)
          }

          localStorage.removeItem('URL_BEFORE_LOGOUT')
        }

        if (path) {
          history.push(path)
        }
      }

      if (getCartCheckout()) {
        await fetchDataAndRedirect('/virtual-shop/checkout')
      } else if (getCreateShopData()) {
        await Promise.all([
          dispatch(fetchMyShops()),
          dispatch(fetchUserInfo()),
          // dispatch(fetchListInvoiceLazada()),
          dispatch(fetchListInvoiceSubscription()),
          dispatch(getNotifications),
        ])
        history.push('/virtual-shop/confirm-shop', {
          from: location.pathname,
        })
      } else if (urlBeforeLogout) {
        await fetchDataAndRedirect(null, urlBeforeLogout)
      } else {
        await fetchDataAndRedirect('/virtual-shop/list')
      }
    } catch (err) {
      if (isMountedRef.current) {
        setIsProcessing(false)
        const strLetter = `Left retries ${err?.msgResp?.leftRetries}`
        setErrorMessage(err?.msgResp?.leftRetries ? strLetter : err?.msgResp)
      }
    }
  }

  const onChangeEmail = (e) => {
    if (errorMessage) setErrorMessage('')
    setEmail(e.target.value.trim())
  }

  const onChangePassword = (e) => {
    if (errorMessage) setErrorMessage('')
    setPassword(e.target.value)
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubmit()
    }
  }

  const isSignInRoute = location.pathname === '/virtual-shop/sign-in'

  return (
    <div className='sign-in'>
      <div
        className='p-login min-vh-100'
        // style={isSignInRoute ? { backgroundColor: 'transparent' } : null}
      >
        <div
          className='page-login page-login--screen'
          style={isSignInRoute ? { borderRadius: '16px' } : null}
        >
          <div
            className='main-top align-self-start'
            // style={isSignInRoute ? { display: 'none' } : null}
          >
            <div className='container'>
              <Link
                to='/'
                className='main-logo text-left text-white font-semibold d-flex flex-column align-items-center position-relative'
                style={{ width: '30%' }}
              >
                <img src={logoLeft} />
                <small
                  style={{
                    position: 'absolute',
                    top: 60,
                    fontSize: 10,
                  }}
                >
                  Support Local Business & Community
                </small>
              </Link>
              <div className='main-img text-center'>
                <img src={imgTop} alt={'image'} />
                <div className='main-link'>
                  <a href='https://co-hoot.com/' target='_blank' rel='noreferrer'>
                    To find out more about Co-Hoot, <b>click here!</b>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className='main-content container'>
            <div className='container'>
              <h2 className='c-ttl'>Sign-in</h2>
              <div className='c-form c-form--gray c-form--login mb-0'>
                <div className='form-group'>
                  <FormGroup>
                    <Label for='email'>Email address</Label>
                    <input
                      className='form-control form-control--nobg'
                      id='email'
                      type='text'
                      value={email}
                      onChange={onChangeEmail}
                      onKeyDown={onKeyDown}
                    />
                  </FormGroup>
                </div>
                <div className='form-group'>
                  <FormGroup>
                    <Label for='password'>Password</Label>
                    <input
                      className='form-control form-control--nobg'
                      id='password'
                      type='password'
                      value={password}
                      onChange={onChangePassword}
                      onKeyDown={onKeyDown}
                    />
                  </FormGroup>
                  <Link className='c-link-text f-14' to='/reset-password'>
                    Forgot?
                  </Link>
                </div>
              </div>
              {errorMessage && (
                <small className='errorMessage mt-3 d-inline-block'>{errorMessage}</small>
              )}
              <button className='btn btn--main mt-3' onClick={onSubmit} disabled={isProcessing}>
                {isProcessing ? <Spinner color='light' size='sm' /> : 'LOGIN'}
              </button>

              <div className='line-text'>
                <span>OR</span>
              </div>
            </div>
            <div className='main-login-bot'>
              <div className='main-button container'>
                <div className='text-center'>Connect with</div>
                <div className='container'>
                  <div className='row'>
                    <div className='col-6'>
                      <SocialButton social={FACEBOOK} isLogin />
                    </div>
                    <div className='col-6'>
                      <SocialButton isLogin social={GOOGLE} />
                    </div>
                  </div>
                  <div className='c-ttl-blue text-center'>
                    Not a member?{' '}
                    <Link
                      to={!userToken ? '/virtual-shop/sign-up' : '/sign-up'}
                      className='text-decoration-underline text-sign-up'
                    >
                      Sign up Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage

import { CheckoutContext } from '_contexts/CheckoutContext'
import {
  clearSelectDelivery,
  clearSelectPickUp,
  selectDelivery,
  selectFulfillmentType,
  selectPickUp,
} from '_redux/modules/fulfillment'
import { DELIVERY_OPTION, SELF_PICK_UP } from '_utils/constant'
import { useContext } from 'react'
import { FaTruckMoving } from 'react-icons/fa'
import { GiPerson } from 'react-icons/gi'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import TabDelivery from './TabDelivery'
import TabPickup from './TabPickup'

function Tabs({ isDeliveryOrderPickup }) {
  const dispatch = useDispatch()
  const { activeTab, setActiveTab, setValue, watch } = useContext(CheckoutContext)
  const { listPickUp, listDelivery } = useSelector((state) => state.fulfillment)

  const watchPickup = watch('pickupOption')
  const watchDelivery = watch('deliveryOption')

  const tabs = [
    {
      condition: listPickUp.length > 0,
      tabId: SELF_PICK_UP,
      icon: <GiPerson size={24} />,
      label: 'Self - Pickup',
      component: <TabPickup />,
    },
    {
      condition: listDelivery.length > 0,
      tabId: DELIVERY_OPTION,
      icon: <FaTruckMoving size={24} />,
      label: 'Delivery to my address',
      component: <TabDelivery />,
    },
  ]

  return (
    <div className='tabs'>
      {isDeliveryOrderPickup ? (
        <Nav tabs>
          {tabs.map(
            (tab) =>
              tab.condition && (
                <NavItem className='tabs__nav-item' key={tab.tabId}>
                  <NavLink
                    className={`tabs__nav-link ${
                      activeTab === tab.tabId
                        ? 'tabs__nav-link--active'
                        : 'tabs__nav-link--inactive'
                    }`}
                    onClick={() => {
                      setActiveTab(tab.tabId)

                      if (tab.tabId === SELF_PICK_UP) {
                        setValue('isGift', false)
                        dispatch(clearSelectDelivery())
                        dispatch(selectFulfillmentType(SELF_PICK_UP))
                        setValue('fulfillmentType', SELF_PICK_UP)
                        if (typeof watchPickup === 'string') {
                          dispatch(selectPickUp(JSON.parse(watchPickup)))
                        }
                        setValue('isCheckCOD', false)
                        // setValue('paymentMethod', TRANSFER_BANKING)
                      }

                      if (tab.tabId === DELIVERY_OPTION) {
                        dispatch(clearSelectPickUp())
                        dispatch(selectFulfillmentType(DELIVERY_OPTION))
                        setValue('fulfillmentType', DELIVERY_OPTION)
                        if (typeof watchDelivery === 'string') {
                          dispatch(selectDelivery(JSON.parse(watchDelivery)))
                        }
                      }
                    }}
                    style={{ height: '100%' }}
                  >
                    <div className='tabs__option d-flex align-items-center justify-content-center h-100'>
                      {tab.icon} <span className='ms-2'>{tab.label}</span>
                    </div>
                  </NavLink>
                </NavItem>
              )
          )}
        </Nav>
      ) : null}

      <TabContent activeTab={activeTab}>
        {tabs.map(
          (tab) =>
            tab.condition && (
              <TabPane tabId={tab.tabId} key={tab.tabId}>
                <Row>
                  <Col sm='12'>{tab.component}</Col>
                </Row>
              </TabPane>
            )
        )}
      </TabContent>
    </div>
  )
}

export default Tabs

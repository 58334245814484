import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonPackage = () => (
  <div className='subscription__package col-12 col-md-4 mb-5 mb-md-0'>
    <div className='subscription__package-content bg-white rounded shadow-sm p-3 h-100 position-relative d-flex flex-column'>
      <Skeleton width={120} height={24} className='mb-2' />
      <Skeleton count={3} className='mb-2' style={{ minHeight: 75 }} />

      {/* Button variations skeleton */}
      <Skeleton height={54} className='mb-3' />

      {/* Features skeleton */}
      <div className='subscription__package-footer card-footer p-0 bg-transparent border-0 mt-auto'>
        <Skeleton width={180} height={20} className='mb-2' />
        <Skeleton count={5} className='mb-1' />
      </div>
    </div>
  </div>
)

export default SkeletonPackage

import reportApi from '_api/report'
import uploadApi from '_api/upload'
import userApi from '_api/user'
import { getUserInfo, setUserInfo } from '_utils/localData'
import React, { useState } from 'react'
import { useToasts } from 'react-toast-notifications'

const Avatar = ({
  photoUrl,
  size = '107px',
  boxShadow = '0 3px 6px rgba(0, 0, 0, 0.16)',
  hasUpload = false,
}) => {
  const userInfo = getUserInfo()
  const { addToast } = useToasts()
  const [avatarFileUrl, setAvatarFileUrl] = useState('')

  const handleApiError = (error) => {
    const { msgResp, msgCode } = error
    reportApi.report({ message: `${msgCode} - ${msgResp}` })
    addToast(msgResp, { appearance: 'error', autoDismiss: true })
  }

  const updateUserPhoto = async (_photoUrl) => {
    try {
      await userApi.updateUser({ photoUrl: _photoUrl })
    } catch (error) {
      handleApiError(error)
    }
  }

  const fetchUserAndUpdateInfo = async () => {
    try {
      const { msgResp: res0 } = await userApi.getUser()
      setUserInfo(res0)
    } catch (error) {
      handleApiError(error)
    }
  }

  const handleUploadError = (error) => {
    const { msgCode, msgResp } = error
    reportApi.report({ message: `${msgCode} - ${msgResp}` })
    addToast(msgResp, { appearance: 'error', autoDismiss: true })
  }

  const onUserImageUploaded = async (e) => {
    const { files } = e.target
    if (!files) return

    try {
      const { msgCode, msgResp } = await uploadApi.uploadPhoto(files)

      if (msgCode % 100 === 0) {
        const { url } = msgResp
        setAvatarFileUrl(url)

        await updateUserPhoto(url)
        await fetchUserAndUpdateInfo()
      }
    } catch (error) {
      handleUploadError(error)
    }
  }

  return (
    <div className='profile-avatar'>
      <div
        className='upload-image rounded-circle'
        style={{
          width: size,
          height: size,
          boxShadow,
        }}
      >
        <img
          id='userAvatarImg'
          src={photoUrl || avatarFileUrl || process.env.REACT_APP_AVATAR_URL + userInfo?.name}
          alt=''
          className='w-100'
          style={{ borderRadius: '50%', objectFit: 'contain' }}
        />
        {hasUpload && (
          <>
            <div className='upload-image-txt d-none'>
              <i className='icon-capture'></i>
              <span>
                Add Profile
                <br />
                Picture
              </span>
            </div>
            <div className='u-file-upload__item'>
              <div className='u-file-upload__wrap'>
                <div className='c-upload preview-images-zone'>
                  <input
                    id='userAvatarInput'
                    type='file'
                    accept='image/*'
                    name='file_source_01'
                    size='40'
                    className='inputFile'
                    data-error='#error-file_source_01'
                    onChange={onUserImageUploaded}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Avatar

import NoImage from '_components/NoImage'
import GlobalStyle from '_utils/style'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useState } from 'react'
import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'

const ProductImage = ({
  imageUrl,
  name,
  checkImage,
  viewMode = 'grid',
  className,
  isLoading = false,
}) => {
  const [imageError, setImageError] = useState(false)

  const handleImageError = () => {
    setImageError(true)
  }

  return (
    <div
      className={classNames(
        'product-card__image',
        `product-card__image--${viewMode}`,
        { loading: isLoading },
        className
      )}
    >
      {imageError || !checkImage(imageUrl) ? (
        <Zoom
          a11yNameButtonUnzoom='Close zoom'
          a11yNameButtonZoom='Zoom image'
          zoomImg={{
            src: 'high-res-image.jpg',
            alt: 'High resolution version',
          }}
          zoomMargin={10}
          wrapElement='div'
          CardStyle={{
            transition: 'transform 0.3s ease',
            ':hover': {
              transform: 'scale(1.1)',
            },
          }}
        >
          <NoImage
            mainText={name || 'No Image'}
            subText='Product image not available'
            backgroundColor={GlobalStyle.image.backgroundColor}
            iconColor={GlobalStyle.image.iconColor}
            textColor={GlobalStyle.image.textColor}
            subtextColor={GlobalStyle.image.subtextColor}
            className='product-card__no-image'
          />
        </Zoom>
      ) : (
        <Zoom
          a11yNameButtonUnzoom='Close zoom'
          a11yNameButtonZoom='Zoom image'
          zoomImg={{
            src: 'high-res-image.jpg',
            alt: 'High resolution version',
          }}
          zoomMargin={10}
          wrapElement='div'
          CardStyle={{
            transition: 'transform 0.3s ease',
            ':hover': {
              transform: 'scale(1.1)',
            },
          }}
        >
          <img src={checkImage(imageUrl)} alt={name} loading='lazy' onError={handleImageError} />
        </Zoom>
      )}
    </div>
  )
}

ProductImage.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  checkImage: PropTypes.func.isRequired,
  viewMode: PropTypes.oneOf(['grid', 'list']),
  className: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default ProductImage

import NotFoundDataComponent from '_components/NotFoundDataComponent'
import React from 'react'
import { GoInbox } from 'react-icons/go'
import { IoStorefrontOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import MyShopItem from './MyShopItem'

function ListShops({ myShops }) {
  return (
    <>
      <div className='d-flex align-items-center justify-content-end my-3'>
        <Link to={'/virtual-shop/create'} className='create-shop-btn'>
          <span>Create shop</span>
          <IoStorefrontOutline className='btn-icon' />
        </Link>
      </div>
      <div className='my-shops__list'>
        {myShops.length ? (
          myShops.map((item, index) => <MyShopItem key={index} item={item} />)
        ) : (
          <NotFoundDataComponent
            icon={<GoInbox />}
            title='No shops available'
            subtitle='You have not created any shop yet.'
          />
        )}
      </div>
    </>
  )
}

export default ListShops

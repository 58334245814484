import ContentGuide from './ContentGuide'

const steps = [
  {
    selector: '.btn-manage-shop',
    content: () => <ContentGuide />,
  },
]

export default steps

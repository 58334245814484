/* eslint-disable no-cond-assign */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import NoImage from '_images/noimage02.svg'
import React from 'react'
import CurrencyFormat from 'react-currency-format'
import MyGallery from './MyGallery'

const ProductConfirm = (props) => {
  const { product, register, setValue, productIndex } = props
  const handleOnChangeDefaultQuantity = (type = 'add') => {
    if (type === 'add') {
      if (parseInt(product.defaultQuantity + 1, 10) <= product.limitPerOrder) {
        setValue(
          `products.${productIndex}.defaultQuantity`,
          parseInt((product.defaultQuantity += 1), 10)
        )
      }
    } else if (type === 'sub') {
      if (parseInt(product.defaultQuantity - 1, 10) >= 0) {
        setValue(
          `products.${productIndex}.defaultQuantity`,
          parseInt((product.defaultQuantity -= 1), 10)
        )
      }
    }
  }

  return (
    <div className='detail-list-news__item'>
      <div className='row'>
        <div className='col-3'>
          {product?.photoUrls?.length > 0 ? (
            <MyGallery image={product?.photoUrls[0]} />
          ) : (
            <img src={NoImage} width={75} height={75} />
          )}
        </div>
        <div className='col-6 text-break'>
          <h3>{product?.name}</h3>
          <p className='mb-0 text-secondary mt-2'>{product?.description}</p>

          <CurrencyFormat
            value={product?.price}
            thousandSeparator=','
            prefix='$'
            displayType='text'
            style={{ fontWeight: 600 }}
            className='mt-2 d-inline-block'
          />
          <div style={{ marginLeft: '25px' }}>
            {product?.subItems?.map((subItem, i) => (
              <div className='mb-1' key={i}>
                {subItem?.type} :
                {subItem?.list?.map((option, k) => (
                  <p className='mb-0 ml-3' key={k}>
                    {option?.name}: +${option?.price}
                  </p>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className='col-3'>
          <div className='quantity'>
            <span className='icon-min' onClick={() => handleOnChangeDefaultQuantity('sub')}>
              -
            </span>
            <span className='icon-input'>
              <input
                type='number'
                step='1'
                min='0'
                max={product.limitPerOrder}
                className='form-control'
                {...register}
              />
            </span>
            <span className='icon-plus' onClick={() => handleOnChangeDefaultQuantity('add')}>
              +
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductConfirm

import { DeliveryContext } from '_contexts/DeliveryContext'
import React, { useContext } from 'react'
import DeliveryHeaderSkeleton from './DeliveryHeaderSkeleton'

export default function DeliveryHeader({ title }) {
  const { loading } = useContext(DeliveryContext)

  if (loading) {
    return <DeliveryHeaderSkeleton />
  }

  return <h3 className='mb-4 title'>{title}</h3>
}

import CustomSelectBox from '_components/CustomSelectBox'
import { LIST_TIME_2 } from '_utils/constant'
import React, { useEffect, useState } from 'react'

const Timer = (props) => {
  const { setHours, Hours } = props
  const [hourStart, setHourStart] = useState(Hours.hourStart)
  const [hourEnd, setHourEnd] = useState(Hours.hourEnd)

  const timeOptions = LIST_TIME_2.map((item) => ({
    value: item.text,
    label: item.text,
  }))

  useEffect(() => {
    setHours({
      ...Hours,
      hourStart,
    })
  }, [hourStart])

  useEffect(() => {
    setHours({
      ...Hours,
      hourEnd,
    })
  }, [hourEnd])

  const handleStartTimeChange = (option) => {
    setHourStart(option.value)
  }

  const handleEndTimeChange = (option) => {
    setHourEnd(option.value)
  }

  const getTimeOptionValue = (time) => JSON.stringify({ value: time })

  return (
    <div
      className='group-hour-delivery d-flex align-items-center justify-content-between flex-wrap mb-3'
      style={{
        gap: '0 1rem',
      }}
    >
      <p className='w-100 mb-1' style={{ fontSize: 14 }}>
        Opening Time
      </p>

      <div className='delivery-hour-wrapper'>
        <div className='input-delivery-hour flex-fill'>
          <CustomSelectBox
            options={timeOptions}
            // value={timeOptions.find((option) => option.value === hourStart)}
            value={getTimeOptionValue(hourStart)}
            onChange={handleStartTimeChange}
            placeholder='Select start time'
            isTimer
          />
        </div>

        <div className='input-delivery-hour text-center flex-fill'>
          <CustomSelectBox
            options={timeOptions}
            // value={timeOptions.find((option) => option.value === hourEnd)}
            value={getTimeOptionValue(hourEnd)}
            onChange={handleEndTimeChange}
            placeholder='Select end time'
            isTimer
          />
        </div>
      </div>
    </div>
  )
}

export default Timer

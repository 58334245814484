/* eslint-disable function-paren-newline */
import { yupResolver } from '@hookform/resolvers/yup'
import uploadApi from '_api/upload'
import Badge from '_components/Badge'
import CustomButton from '_components/CustomButton'
import InputField from '_components/InputField'
import NoImage from '_images/noimage02.svg'
import { addProduct } from '_redux/modules/product'
import { scrollToTop } from '_utils/function'
import moment from 'moment'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { IoIosAdd } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import * as yup from 'yup'

function FormCreateProduct({ toggle }) {
  const defaultValues = {
    image: '',
    name: '',
    quantity: '',
    price: '',
    sellerSku: '',
    category: '',
    width: '',
    height: '',
    weight: '',
    length: '',
    photoUrls: '',
  }
  const [imagePreview, setImagePreview] = useState('')
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const { selectedShop } = useSelector((state) => state.shop)
  const listProduct = selectedShop?.products
  const { currentPackage } = useSelector((state) => state.subscription)
  const today = moment()
  const isSubscriptionExpired = today.isAfter(moment.unix(currentPackage?.expireTimestamp))

  const formSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    quantity: yup
      .string()
      .matches(/^\d*\.?\d*$/, 'Must be a valid number')
      .test('is-not-empty', 'Quantity cannot be empty', (value) => value?.trim() !== '')
      .test('is-number', 'Must be a valid number', (value) => {
        if (!value) return false
        const num = Number(value)
        return !isNaN(num)
      })
      .test('is-non-negative', 'Must be greater than or equal to 0', (value) => {
        if (!value) return false
        const num = Number(value)
        return num >= 0
      })
      .required('Quantity is required'),
    price: yup
      .string()
      .test('is-valid-number', 'Must be a valid number', (value) => {
        const regex = /^\d{1,3}(,\d{3})*(\.\d+)?$|^\d+(\.\d+)?$/

        // Normalize value by replacing commas with periods
        const normalized = value.replace(/,/g, '')

        // Check if normalized value is a valid number
        const num = Number(normalized)
        return regex.test(value) && !isNaN(num)
      })
      .test('is-not-empty', 'Price cannot be empty', (value) => value?.trim() !== '')
      .test('valid-number-format', 'Invalid number format', (value) => {
        if (!value) return false
        // Replace commas with periods and validate
        const normalized = value.replace(/,/g, '')
        // Check if there's more than one decimal point
        if ((normalized.match(/\./g) || []).length > 1) return false
        // Test if it's a valid number after normalization
        const num = Number(normalized)
        return !isNaN(num)
      })
      .test('is-non-negative', 'Must be greater than or equal to 0', (value) => {
        if (!value) return false
        const normalized = value.replace(/,/g, '')
        const num = Number(normalized)
        return num >= 0
      })
      .required('Price is required')
      .transform((value) => {
        if (!value) return value
        // Convert to standard decimal format for saving
        return value.replace(/,/g, '')
      }),
  })

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues,
  })

  const watchProduct = watch()

  const onUserImageUploaded = async (event) => {
    try {
      const { files } = event.target
      const maxImageSize = 1024 * 1024 // 1MB

      if (files) {
        const img = files[0]

        if (img.size > maxImageSize) {
          addToast('Image size maximum is 1MB', { appearance: 'error', autoDismiss: true })
          return
        }

        if (img) {
          setImagePreview(URL.createObjectURL(img))

          const { msgResp } = await uploadApi.uploadPhoto(files)

          setValue('photoUrls', msgResp?.url)
        } else {
          setImagePreview('')
        }
      }
    } catch (error) {
      addToast(error?.msgResp || error?.message || 'Upload image failed', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const onSubmit = async (data) => {
    try {
      const lastIndex = listProduct?.length - 1
      const newData = {
        products: [
          {
            categoryId: null,
            items: [
              {
                photoUrls: data.photoUrls || null,
                name: data.name,
                description: data.description,
                price: parseInt(data.price, 10),
                maxQuantity: 1000,
                minOrderQty: 1,
                defaultQuantity: 0,
                availableOfStock: parseInt(data.quantity, 10),
                limitPerOrder: 1000,
                dateSelectInfo: null,
                index: lastIndex + 1,
                subItems: [],
              },
            ],
          },
        ],
      }

      if (isSubscriptionExpired && listProduct?.length === 5) {
        return addToast('Your package is expired, so you can only create a maximum of 5 products', {
          appearance: 'error',
          autoDismiss: true,
        })
      }

      await dispatch(addProduct(selectedShop, newData))

      // Force re-render of product list by triggering a state update
      dispatch({
        type: 'FORCE_PRODUCT_LIST_UPDATE',
        payload: Date.now(),
      })

      toggle()

      setTimeout(() => {
        scrollToTop()
        addToast('Created product successfully', { appearance: 'success', autoDismiss: true })
      }, 300)
    } catch (error) {
      addToast(error?.msgResp || error?.message || 'Create product failed', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  return (
    <form className='form-create-product row' onSubmit={handleSubmit(onSubmit)}>
      {/* upload image */}
      <div className='col-12 mb-3'>
        <div className='form-upload-image'>
          <div className='upload-image-txt d-flex flex-column align-items-center'>
            <img src={imagePreview || NoImage} alt='' />
            <span className='text-upload'>Upload Product Image</span>
          </div>
          <div className='u-file-upload__item'>
            <div className='u-file-upload__wrap'>
              <a className='c-upload preview-images-zone'>
                <input
                  type='file'
                  accept='image/*'
                  className='inputFile'
                  onChange={onUserImageUploaded}
                />
              </a>
            </div>
          </div>
        </div>
        {errors?.image && (
          <small className='text-danger text-center d-block mt-2'>{errors?.image?.message}</small>
        )}
      </div>

      {/* product info */}
      <div className='product-info row'>
        <Badge className='w-auto text-white position-absolute rounded badge-product-info'>
          Product Info
        </Badge>

        <InputField
          label='Name'
          placeholder='Enter product name'
          register={register('name')}
          error={errors?.name?.message}
          className='col-12'
          isRequired
          offset={{ bottom: 16 }}
          type='text'
        />

        <InputField
          label='Stock Available'
          type='text'
          placeholder='Enter available quantity'
          register={register('quantity')}
          error={errors?.quantity?.message}
          isRequired
          offset={{ bottom: 16 }}
          className='col-12'
          as='input'
          value={watchProduct.quantity}
          onChange={(e) => setValue('quantity', e.target.value)}
        />

        <InputField
          label='Price ($)'
          type='text'
          placeholder='Enter price'
          register={register('price')}
          error={errors?.price?.message}
          className='col-12'
          isRequired
          offset={{ bottom: 16 }}
          value={watchProduct.price}
          onChange={(e) => setValue('price', e.target.value)}
        />

        <InputField
          label='Description'
          placeholder='Enter product description'
          register={register('description')}
          error={errors?.description?.message}
          className='col-12'
          as='textarea'
          offset={{ bottom: 0 }}
          rows={5}
          type='text'
        />
      </div>

      <div className='form-footer'>
        <CustomButton
          text={isSubmitting ? 'Creating...' : 'Create'}
          isDisabled={isSubmitting}
          type='submit'
          variant='contained'
          color='aqua'
          icon={<IoIosAdd />}
          className='w-100'
        />
      </div>
    </form>
  )
}

export default FormCreateProduct

import CustomButton from '_components/CustomButton'
import React from 'react'
import { AiOutlineSchedule } from 'react-icons/ai'
import { TiCancel } from 'react-icons/ti'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import DatePicker from './DatePicker'
import InputTime from './InputTime'

export default function ModalSchedule({
  modalSchedule,
  toggleSchedule,
  openDate,
  openTime,
  onChangeOpenTime,
  onChangeOpenDate,
  isSubmitScheduled,
  idForm,
  refSubmitButton,
  handleOnSubmitSchedule,
}) {
  return (
    <div id={idForm}>
      <Modal isOpen={modalSchedule} toggle={toggleSchedule} centered>
        <ModalHeader toggle={toggleSchedule}>Schedule Date & Time</ModalHeader>
        <ModalBody>
          <DatePicker value={openDate} onChange={onChangeOpenDate} />
          <InputTime value={openTime} onChange={onChangeOpenTime} />
        </ModalBody>
        <ModalFooter className='d-flex align-items-center flex-nowrap gap-3'>
          <CustomButton
            text='Cancel'
            className='mt-0'
            onClick={toggleSchedule}
            icon={<TiCancel />}
            color='slate'
          />
          <CustomButton
            text={isSubmitScheduled ? 'Scheduling...' : 'Schedule'}
            className='mt-0'
            disabled={isSubmitScheduled}
            ref={refSubmitButton}
            type='submit'
            onClick={handleOnSubmitSchedule}
            variant='contained'
            icon={<AiOutlineSchedule />}
            color='aqua'
          />
        </ModalFooter>
      </Modal>
    </div>
  )
}

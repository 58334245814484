import NotFoundDataComponent from '_components/NotFoundDataComponent'
import Pagination from '_components/Pagination'
import { ManageEventContext } from '_contexts/EventContext'
import { useIsMobile } from '_hooks/isMobile'
import { scrollToTop } from '_utils/function'
import React, { useContext } from 'react'
import { GoInbox } from 'react-icons/go'
import EventFilterStatus from './EventFilterStatus'
import EventItem from './EventItem'
import EventSearch from './EventSearch'
import EventView from './EventView'
import ModalBroadCast from './ModalBroadCast'

export default function EventList() {
  const {
    view,
    handleViewChange,
    currentEvents,
    currentPage,
    setCurrentPage,
    listEventByShopFiltered,
    openBroadcastModal,
    setOpenBroadcastModal,
    message,
    onChangeMessage,
    onCreateNotification,
    search,
    setSearch,
    PageSize,
    currentPackagePro,
    listOrderEventHandled,
    listEventByShop,
  } = useContext(ManageEventContext)

  const isMobile = useIsMobile()

  const overlayStyle = !currentPackagePro
    ? {
        pointerEvents: 'none',
        filter: 'blur(2px)',
      }
    : {}

  return (
    <div className='event-list' style={overlayStyle}>
      <div className='d-flex align-items-center justify-content-between gap-3 my-3'>
        <EventSearch search={search} setSearch={setSearch} />
        {!isMobile && <EventView view={view} handleViewChange={handleViewChange} />}
        <EventFilterStatus />
      </div>

      <div
        className={`event-list custom-scrollbar d-flex gap-3 ${
          view === 'grid' ? 'flex-wrap' : 'flex-column'
        }`}
      >
        {currentEvents?.length > 0 ? (
          currentEvents.map((event) => <EventItem key={event.id} event={event} />)
        ) : (
          <NotFoundDataComponent
            icon={<GoInbox />}
            title={
              listEventByShop.length === 0
                ? 'There is currently no events organized'
                : 'No Events Found'
            }
            subtitle={
              listEventByShop.length === 0
                ? 'Events are for the purpose of group-buy in Co-bee which should provide savings for members in Co-bee.'
                : 'There are currently no events matching your search criteria'
            }
          />
        )}
      </div>

      <Pagination
        className='pagination-bar'
        currentPage={currentPage}
        totalCount={listEventByShopFiltered.length}
        pageSize={PageSize}
        onPageChange={(page) => {
          setCurrentPage(page)
          scrollToTop()
        }}
      />

      {openBroadcastModal && (
        <ModalBroadCast
          modal={openBroadcastModal !== null}
          toggle={() => setOpenBroadcastModal(null)}
          message={message}
          onChangeMessage={onChangeMessage}
          onCreateNotification={onCreateNotification}
          listOrderEventHandled={listOrderEventHandled}
        />
      )}
    </div>
  )
}

import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const EventHeaderSkeleton = () => (
  <>
    <Skeleton height={36} width={120} className='mb-4' />

    <div className='alert alert-warning mb-4'>
      <Skeleton height={24} className='mb-2' style={{ maxWidth: 100 }} />
      <Skeleton height={20} count={4} className='mb-1' style={{ maxWidth: 300 }} />
    </div>

    <div className='d-flex align-items-center'>
      <Skeleton height={20} width={120} />
      <Skeleton height={20} width={300} className='ms-2' />
    </div>
  </>
)

export default EventHeaderSkeleton

import CustomButton from '_components/CustomButton'
import InputField from '_components/InputField'
import React from 'react'
import { IoMdCheckmark } from 'react-icons/io'
import { TiCancel } from 'react-icons/ti'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

export default function ModalBroadCast({
  modal,
  toggle,
  message,
  onChangeMessage,
  onCreateNotification,
  listOrderEventHandled,
}) {
  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader className='modal-header-created' toggle={toggle}>
        Message to Broadcast?
      </ModalHeader>
      <ModalBody>
        <InputField
          type='text'
          label='Message'
          value={message}
          onChange={onChangeMessage}
        />
      </ModalBody>
      <ModalFooter style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
        <div className='col-6 px-0 m-0' style={{ flexBasis: '48%' }}>
          <CustomButton
            text='CANCEL'
            className='mt-0'
            color='slate'
            onClick={toggle}
            icon={<TiCancel />}
          />
        </div>
        <div className='col-6 px-0 m-0' style={{ flexBasis: '48%' }}>
          <CustomButton
            text='OK'
            className='mt-0'
            variant='contained'
            color='aqua'
            icon={<IoMdCheckmark />}
            onClick={onCreateNotification}
            disabled={!listOrderEventHandled?.length}
          />
        </div>
      </ModalFooter>
    </Modal>
  )
}

/* eslint-disable max-len */
// import virtualShopApi from '_api/virtualShop'
import virtualShopApi from '_api/virtualShop'
import CustomButton from '_components/CustomButton'
import { updateNewListProduct } from '_redux/modules/product'
import {
  clearShopAndShopDetail,
  pushToMyShops,
  selectShopAndSelectShopDetail,
} from '_redux/modules/shop'
import { TURN_ON, VIEW_GRID, VIEW_LIST } from '_utils/constant'
import { idGenerator } from '_utils/functions/generator'
import {
  getCreateShopData,
  getUserToken,
  removeCategoryName,
  removeSelectedShopLocalStorage,
  setCreateShopData,
  setSelectedShopLocalStorage,
} from '_utils/localData'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { createContext, useEffect, useState } from 'react'
import { BiArrowBack } from 'react-icons/bi'
import { FaStore } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { getUserInfo, setUserInfo } from '../../../utils/localData'
import ProductList from '../ShopDetail/components/ProductList'
import SelectViewProduct from '../ShopDetail/components/SelectViewProduct'
import ShopBanner from '../ShopDetail/components/ShopBanner'
import '../ShopDetail/styles/index.scss'
import ConfirmShopSkeleton from './ConfirmShopSkeleton'

export const QuantityProductContext = createContext()

function ConfirmShop() {
  const { addToast } = useToasts()
  const dataCreateShop = getCreateShopData()
  const dispatch = useDispatch()
  const history = useHistory()
  const userInfo = getUserInfo()
  const userToken = getUserToken()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [loading, setLoading] = useState(false)
  const [listProduct, setListProduct] = useState(dataCreateShop?.products || [])

  const { viewDefault } = useSelector((state) => state.shop)

  const convertProducts = (_listProduct) => {
    const productsItems = _listProduct?.map((p) => p.items).flat()

    return productsItems?.map((product, index) => {
      const convertedSubItems = product.subItems?.map((subItem) => {
        const defaultOption = subItem.list[0]

        return {
          type: subItem.type,
          name: defaultOption.name,
          price: defaultOption.price,
          required: subItem.required,
          list: subItem.list,
          id: idGenerator(),
        }
      })

      const { photoUrl, ...rest } = product

      return {
        ...rest,
        index,
        availableOfStock: 1000,
        subItems: convertedSubItems,
        quantityInput: product.defaultQuantity,
        active: TURN_ON,
        id: idGenerator(),
      }
    })
  }

  const handleNavigateToExternalLink = (link) => {
    if (link !== '') {
      window.open(link)
    } else {
      addToast('This Link is not exist', { appearance: 'error', autoDismiss: true })
    }
  }

  const handleCreateShop = async () => {
    try {
      if (!userToken) {
        history.push('/virtual-shop/sign-up', { from: '/virtual-shop/confirm-shop' })
        return
      }

      setIsSubmitting(true)
      dispatch(clearShopAndShopDetail())
      removeSelectedShopLocalStorage()
      const products = dataCreateShop?.products.map((item) => {
        const convertDataOfSubItem = item?.items.map((listItem) => ({
          ...listItem,
          price: listItem.price,
          subItems: listItem.subItems?.map((sub) => ({
            list: sub.list.map((option) => ({
              ...option,
              price: parseFloat(option.price.replace(/[^\d.]/g, '')),
            })),
            type: sub.type,
            required: TURN_ON,
          })),
        }))

        return {
          ...item,
          items: convertDataOfSubItem,
        }
      })

      const postData = {
        ...dataCreateShop,
        products,
        productView: viewDefault === VIEW_GRID ? VIEW_GRID : VIEW_LIST,
      }

      const { msgResp } = await virtualShopApi.createVirtualShop(postData)

      const convertedShop = { ...msgResp, products: msgResp.products[0].items, viewDefault }
      dispatch(pushToMyShops(convertedShop))
      setSelectedShopLocalStorage(convertedShop)
      dispatch(updateNewListProduct(convertedShop.products))
      dispatch(selectShopAndSelectShopDetail(convertedShop))
      dispatch(pushToMyShops(convertedShop))
      setIsSubmitting(false)
      removeCategoryName()

      setUserInfo({
        ...userInfo,
        shopLinks: [...(userInfo.shopLinks ?? []), msgResp.shopLink],
      })

      setCreateShopData({
        ...dataCreateShop,
        isShopCompleted: true,
      })
      history.push(`/${dataCreateShop.shopSlug}`)
    } catch (error) {
      setIsSubmitting(false)
      addToast(error?.msgResp || error.message, { appearance: 'error', autoDismiss: true })
    }
  }

  const findProduct = (pid, index) => listProduct.find((n) => n.id === pid && n.index === index)

  const changeProductQuantityInput = ({ pid, number, index, type }) => {
    const newListProduct = [...listProduct]
    const productIndex = newListProduct.findIndex((p) => p.id === pid && p.index === index)

    if (productIndex === -1) return

    let newQuantity = number
    const product = newListProduct[productIndex]

    // Validate quantity
    if (number < 0 || isNaN(number)) {
      newQuantity = 0
    }

    if (newQuantity <= 0) {
      newQuantity = 0
    }

    if (type === 'plus' || type === 'typing') {
      if (newQuantity > product.limitPerOrder) {
        newQuantity = product.limitPerOrder
      }
      if (newQuantity > product.availableOfStock) {
        return
      }
    } else if (type === 'minus') {
      if (newQuantity < product.minOrderQty) {
        newQuantity = 0
      }
    }

    newListProduct[productIndex] = {
      ...product,
      quantityInput: newQuantity,
    }

    setListProduct(newListProduct)
  }

  const getValueSelect = ({ pid, pIndex, subItemType }) => {
    const indexProduct = listProduct.findIndex((n) => n.id === pid && n.index === pIndex)
    const listSubItem = listProduct[indexProduct].subItems
    const indexSubItem = listSubItem?.findIndex((subItem) => subItem?.type === subItemType)
    if (indexSubItem !== undefined && indexSubItem !== -1) {
      return JSON.stringify({
        name: listSubItem[indexSubItem].name,
        price: listSubItem[indexSubItem].price,
      })
    }
    return ''
  }

  const handleChangeSubItem = ({ pid, subItem, value, pIndex }) => {
    const newListProduct = [...listProduct]
    const indexProduct = newListProduct.findIndex((n) => n.id === pid && n.index === pIndex)
    const listSubItem = newListProduct[indexProduct].subItems
    const indexSubItem = listSubItem.findIndex(
      (s, index) => s.type === subItem.type && index === subItem.index
    )

    const updatedSubItem = {
      ...listSubItem[indexSubItem],
      name: value.name,
      price: value.price,
    }

    const updatedProduct = {
      ...newListProduct[indexProduct],
      subItems: [...listSubItem],
    }

    updatedProduct.subItems[indexSubItem] = updatedSubItem
    newListProduct[indexProduct] = updatedProduct

    setListProduct(newListProduct)
  }

  const addAnotherOrder = (pid, pIndex) => {
    const newListProduct = [...listProduct]
    const selectedProduct = findProduct(pid, pIndex)

    const indexProduct = newListProduct.findIndex((n) => n.id === pid && n.index === pIndex)

    const totalQuantity = newListProduct.reduce(
      (acc, product) => (product.id === pid ? acc + product.quantityInput : acc),
      0
    )

    if (totalQuantity >= selectedProduct.availableOfStock) {
      addToast('Cannot order more than available of stock', {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }

    let newQuantityInput = selectedProduct.availableOfStock - totalQuantity

    if (newQuantityInput === 0 || selectedProduct.minOrderQty > newQuantityInput) {
      addToast('Cannot add another more than available of stock', {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }

    newQuantityInput = selectedProduct.minOrderQty

    newListProduct.splice(indexProduct + 1, 0, {
      ...selectedProduct,
      isCopy: true,
      quantityInput: newQuantityInput,
    })

    setListProduct(newListProduct.map((item, index) => ({ ...item, index })))
  }

  const deleteItemOrder = (pid, pIndex) => {
    const newListProduct = [...listProduct]
    const productIndex = newListProduct.findIndex((p) => p.id === pid && p.index === pIndex)

    if (productIndex !== -1) {
      newListProduct.splice(productIndex, 1)
      setListProduct(newListProduct.map((item, index) => ({ ...item, index })))
    }
  }

  const onBack = () => history.push('/virtual-shop/add-items')

  useEffect(() => {
    setListProduct(convertProducts(dataCreateShop?.products))
  }, [dataCreateShop?.products?.length])

  useEffect(() => {
    if (!dataCreateShop?.products?.length) {
      history.push('/virtual-shop/add-items')
    }

    const previousURL = history.location.state?.from

    if (
      dataCreateShop &&
      previousURL &&
      (previousURL.includes('/sign-up') || previousURL.includes('/sign-in'))
    ) {
      setLoading(true)
      const fetchData = async () => {
        await handleCreateShop()
      }
      fetchData()
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    }
  }, [JSON.stringify(dataCreateShop)])

  if (loading) {
    return <ConfirmShopSkeleton />
  }

  return (
    <div className={classNames('shop-detail')} style={{ backgroundColor: '#F5F5F5' }}>
      <div
        className={classNames('container bg-white shop-detail-body mt-0 pb-3', {
          'w-75 mx-auto': window.innerWidth > 576,
        })}
      >
        <button onClick={onBack} className='btn-back animated-underline'>
          <BiArrowBack />
          <span>Back</span>
        </button>

        <ShopBanner shopDetail={dataCreateShop} handleOnClick={handleNavigateToExternalLink} />

        <SelectViewProduct viewDefault={viewDefault} />

        <ProductList
          listProduct={listProduct}
          findProduct={findProduct}
          handleChangeSubItem={handleChangeSubItem}
          getValueSelect={getValueSelect}
          changeProductQuantityInput={changeProductQuantityInput}
          addAnotherOrder={addAnotherOrder}
          deleteItemOrder={deleteItemOrder}
          viewDefault={viewDefault}
        />

        <CustomButton
          text={isSubmitting ? 'Publishing' : 'Publish'}
          icon={<FaStore />}
          isDisabled={isSubmitting}
          onClick={handleCreateShop}
          className={'w-50 mx-auto border-2'}
          style={{ height: '50px' }}
          variant='contained'
        />
      </div>
    </div>
  )
}

ConfirmShop.propTypes = {
  shopDetail: PropTypes.object,
}

export default ConfirmShop

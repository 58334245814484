import { BsFillCheckCircleFill } from 'react-icons/bs'
import { IoMdCheckmark } from 'react-icons/io'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import CustomButton from '../CustomButton'
import './style.scss'

const ModalNotifySuccessSubscription = ({ isOpenModalSuccess, toggleModalSuccess }) => (
  <Modal
    isOpen={isOpenModalSuccess}
    toggle={toggleModalSuccess}
    className="modal-subscription-success"
  >
    <ModalHeader toggle={toggleModalSuccess} />
    <div className="success-icon-wrapper">
      <BsFillCheckCircleFill size={60} color="#ffffff" />
    </div>
    <ModalBody>
      <div className="title text-center">Payment Success</div>
      <div className="message text-center">
        Your account has been enabled the new subscription success.
      </div>
      <CustomButton
        text="Continue"
        color="aqua"
        variant="contained"
        onClick={toggleModalSuccess}
        icon={<IoMdCheckmark size={20} />}
      />
    </ModalBody>
  </Modal>
)

export default ModalNotifySuccessSubscription

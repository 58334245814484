import InputField from '_components/InputField'
import React from 'react'
import { Controller } from 'react-hook-form'

function PickupTimeSlot({
  checkedTimeSlot,
  handleAddTimeSlot,
  handleCheckTimeSlot,
  index,
  control,
}) {
  return (
    <div className='delivery-time-slot row align-items-center'>
      <div className='col-6 delivery-time-slot__label'>
        <span className='d-inline-block delivery-time-slot__text' style={{ fontSize: 14 }}>Add Time Slot</span>
      </div>
      <div className='col-6 d-flex justify-content-end delivery-time-slot__input'>
        <InputField
          type='checkbox'
          id='pickup-time-slot'
          checked={checkedTimeSlot}
          onChange={(e) => handleAddTimeSlot(e)}
          isSwitch
        />
      </div>
      {checkedTimeSlot && (
        <>
          <div className='w-100'></div>
          <div className='delivery-time-slot__options col-12 col-md-8 mx-auto'>
            <Controller
              name='timeSlots'
              control={control}
              render={({ field: { value, onChange } }) =>
                value.map((time, i) => (
                  <InputField
                    key={i}
                    isRadio={true}
                    id={`radioPickup-${index}-${i}`}
                    checked={time.isCheck}
                    label={time.title}
                    onChange={(e) => handleCheckTimeSlot(e, i, onChange)}
                    type='radio'
                  />
                ))
              }
            />
          </div>
          {/* <div className='w-100'></div>
          <div className='d-flex align-items-center border-top
          border-2 pt-2 mt-2 col-8 mx-auto fw-semibold'>
            <span className='text-black'>Limit Orders:</span>
            <input type='text' className='form-control-sm border w-25 mx-2' />
            <span>Every 15 mins</span>
          </div> */}
        </>
      )}
    </div>
  )
}

export default PickupTimeSlot

import LogoIcon from '_images/logo-co-hoot.png'
import classNames from 'classnames'
import React from 'react'
import './style.scss'

function MyLogo({ className }) {
  return (
    <div className={classNames('logo', className)}>
      <img src={LogoIcon} alt='logo' />
    </div>
  )
}

export default MyLogo

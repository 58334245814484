import CustomSwalFire from '_components/CustomSwalFire'
import { CollapseContext } from '_contexts/CollapseMenuContext'
import { OrderRecordContext } from '_contexts/OrderRecordContext'
import useQuery from '_hooks/useQuery'
import { PLATFORM_CO_KII } from '_utils/constant'
import { getCreateEventData, removeCreateEventData } from '_utils/localData'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const MenuItem = ({ item, index, toggleSidebar }) => {
  const location = useLocation()
  const history = useHistory()
  const params = useQuery()
  const eidParam = params.get('id')
  const createEventData = getCreateEventData()

  const { isCollapse } = useContext(CollapseContext)
  const { selectedShop } = useSelector((state) => state.shop)
  const { onSelectTabOrderPlatform, onSelectTabEvent } = useContext(OrderRecordContext)

  const customsColorTheme = selectedShop?.shopColorTheme?.customsColorTheme

  const isActive = () => {
    const currentPath = location.pathname

    // Special case for settings
    if (item.path.includes('/setting')) {
      return currentPath.includes('/setting')
    }

    // Exact match for delivery path to avoid conflicting with other paths
    if (item.path.includes('/delivery')) {
      return currentPath === item.path
    }

    // Normal path matching
    return (
      currentPath === item.path ||
      (currentPath.startsWith(item.path) && !currentPath.includes('/delivery')) // Exclude delivery paths
    )
  }

  const handleNavigation = (e, itemPath) => {
    const urlParams = new URLSearchParams(location.search)
    if (urlParams.has('id') && createEventData) {
      e.preventDefault()
      CustomSwalFire.delete({
        title: 'Are you sure?',
        text: 'You are editing an event, do you want to discard the data?',
        confirmButtonText: 'Yes, leave',
        onConfirm: async () => {
          removeCreateEventData()
          urlParams.delete('id')
          history.push(itemPath)
        },
        onError: (error) => {
          console.error('Error:', error)
        },
      })
    } else if (typeof toggleSidebar === 'function') {
      toggleSidebar()
    }

    if (
      typeof onSelectTabOrderPlatform === 'function' &&
      typeof onSelectTabEvent === 'function' &&
      !eidParam
    ) {
      onSelectTabOrderPlatform(PLATFORM_CO_KII)
      onSelectTabEvent(null)
    }
  }

  const active = isActive()

  return (
    <li className='d-flex align-items-center menu-item' key={index}>
      <NavLink
        to={item.path}
        className={`${active ? 'text-orange' : ''}`}
        style={{
          color: customsColorTheme?.titleItemColor || '#1ca5a2',
          display: item.display ? 'flex' : 'none',
        }}
        exact
        onClick={(e) => handleNavigation(e, item.path)}
      >
        <span className='icons'>{item.icon}</span>
        {!isCollapse && <span>{item.title}</span>}
        {isCollapse && <span className='tooltip'>{item.title}</span>}
      </NavLink>
    </li>
  )
}

export default MenuItem

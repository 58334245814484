import CustomButton from '_components/CustomButton'
import { LIMIT_PER_ORDER_DEFAULT, MINIMUM_ORDER_PER_PRODUCT } from '_utils/constant'
import { IoSave } from 'react-icons/io5'
import { TiCancel } from 'react-icons/ti'
import { Modal, ModalHeader } from 'reactstrap'
import InputProduct from './InputProduct'

const ProductSettingModal = (props) => {
  const { isOpen, toggle, register, productIndex, errors } = props

  const handleToggle = () => {
    if (errors && errors?.length > 0 && Object.entries(errors?.products).length !== 0) {
      return
    }
    toggle()
  }

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} centered className='modal-product-setting'>
      <ModalHeader toggle={toggle}>Item Options</ModalHeader>
      <div className='container py-3'>
        <div className='c-form'>
          <div className='form-group'>
            <div className='row ml-2 mr-2'>
              {/* <label className='col-5 align-self-center'>Max Quantity</label>
              <div className='col-7 mb-2'>
                <InputProduct
                  type='number'
                  className={`form-control-sm border w-100 ${
                    errors?.products &&
                    Object.entries(errors?.products).length !== 0 &&
                    errors?.products[productIndex]?.maxQuantity &&
                    'is-error'
                  }`}
                  register={register(`products.${productIndex}.maxQuantity`)}
                  defaultValue={AVAILABLE_STOCK_DEFAULT}
                  error={errors?.products?.[productIndex]?.maxQuantity?.message}
                />
              </div> */}
              <label className='col-5 align-self-start' style={{ marginTop: 8 }}>
                Limit per order
              </label>
              <div className='col-7 mb-2'>
                <InputProduct
                  type='number'
                  className={`form-control-sm border w-100 ${
                    errors?.products &&
                    Object.entries(errors?.products).length !== 0 &&
                    errors?.products[productIndex]?.limitPerOrder &&
                    'is-error'
                  }`}
                  register={register(`products.${productIndex}.limitPerOrder`)}
                  defaultValue={LIMIT_PER_ORDER_DEFAULT}
                  error={errors?.products?.[productIndex]?.limitPerOrder?.message}
                />
              </div>
              <label className='col-5 align-self-start' style={{ marginTop: 8 }}>
                Minimum order quantity
              </label>
              <div className='col-7 mb-2'>
                <InputProduct
                  type='number'
                  className={`form-control-sm border w-100 ${
                    errors?.products &&
                    Object.entries(errors?.products).length !== 0 &&
                    errors?.products[productIndex]?.minOrderQty &&
                    'is-error'
                  }`}
                  register={register(`products.${productIndex}.minOrderQty`)}
                  defaultValue={MINIMUM_ORDER_PER_PRODUCT}
                  error={errors?.products?.[productIndex]?.minOrderQty?.message}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='d-flex gap-3 align-items-center'>
            <CustomButton
              text='Cancel'
              variant='outlined'
              color='slate'
              className='w-100 mt-0'
              onClick={toggle}
              icon={<TiCancel />}
              isDisabled={errors && errors?.products?.length > 0}
            />
            <CustomButton
              text='Save'
              variant='contained'
              color='aqua'
              className='w-100 mt-0'
              onClick={handleToggle}
              icon={<IoSave />}
              isDisabled={errors && errors?.products?.length > 0}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ProductSettingModal

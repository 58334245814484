/* eslint-disable class-methods-use-this */
import { Component } from 'react'
import Subscription from '../../digistall-desktop-pages/Subscription'

class Pricing extends Component {
  render = () => (
    <div className={'container mb-3'} id={'pricing'}>
      <Subscription />
    </div>
  )
}

export default Pricing

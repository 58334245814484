import CustomSelectBox from '_components/CustomSelectBox'
import {
  BUYER_ORDER_CANCELED,
  BUYER_ORDER_COLLECTED,
  BUYER_ORDER_PAID,
  BUYER_ORDER_REFUNDED,
  BUYER_ORDER_UNPAID,
  HOST_ORDER_CANCELED,
  HOST_ORDER_EVENT_COLLECTED,
  HOST_ORDER_EVENT_VERIFIED,
  HOST_ORDER_UNPAID,
} from '_utils/constant'
import { formatPhoneNumber } from '_utils/functions/formatter'
import moment from 'moment'
import React from 'react'
import CurrencyFormat from 'react-currency-format'
import { FaCheck } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { Tooltip } from 'reactstrap'

// const statusStyles = {
//   [HOST_ORDER_UNPAID]: { color: '#ff6903', backgroundColor: '#f06a0f', fontSize: '1em' },
//   [HOST_ORDER_EVENT_VERIFIED]: { color: '#ffffff', backgroundColor: '#198754', fontSize: '0.6em' },
//   [HOST_ORDER_EVENT_COLLECTED]: { color: '#7c797a', backgroundColor: '#0dcaf0', fontSize: '1em' },
//   default: { color: '#7c797a', backgroundColor: '#7c797a', fontSize: '1em' },
// }

const orderStatusOptions = [
  { value: HOST_ORDER_CANCELED, label: 'Cancelled', className: 'cancel-status' },
  { value: HOST_ORDER_UNPAID, label: 'Unpaid', className: 'unpaid-status' },
  { value: HOST_ORDER_EVENT_VERIFIED, label: 'Verified', className: 'verified-status' },
  { value: HOST_ORDER_EVENT_COLLECTED, label: 'Collected', className: 'collected-status' },
]

export default function OrderEventRecordItem({
  item,
  index,
  tooltipOpen,
  toggleTooltip,
  onChangeStatusSelect,
  openPaymentScreen,
  onOpenComment,
  handleShowDetail,
  getStickyClass,
  listLength, // Add this prop to get the length of the list
}) {
  const { loadingEventAndOrderById, loadingListCombinedPayment } = useSelector(
    (state) => state.orderEvent
  )

  // const currentStyle = statusStyles[item.status] || statusStyles.default

  // const [fontSize, setFontSize] = useState('1em')
  // const selectRef = useRef(null)

  // useEffect(() => {
  //   const updateFontSize = () => {
  //     if (selectRef.current) {
  //       const width = selectRef.current.offsetWidth
  //       if (width < 100) {
  //         setFontSize('0.6em')
  //       } else if (width < 200) {
  //         setFontSize('0.8em')
  //       } else {
  //         setFontSize('1em')
  //       }
  //     }
  //   }

  //   updateFontSize()
  //   window.addEventListener('resize', updateFontSize)
  //   return () => window.removeEventListener('resize', updateFontSize)
  // }, [])

  const isReversedDropdown = listLength >= 3 && index >= listLength - 2

  return (
    <>
      <td
        className={`col-order-no ${getStickyClass({ label: 'Order #' })}`}
        id={`tooltip-${index}`}
      >
        <strong>{index + 1}</strong>
      </td>
      <td className={`col-customer-name ${getStickyClass({ label: 'Customer Name' })}`}>
        <div className='d-flex flex-column text-break'>
          <span className='cursor-pointer' onClick={(e) => handleShowDetail(e, item)}>
            {item?.uName}
          </span>
          {item?.comment && (
            <button
              className='p-0 bg-transparent border-0 order-comment'
              onClick={() => onOpenComment(item)}
            >
              <i className='icon-new' />
            </button>
          )}
        </div>
      </td>
      <td className='col-customer-phone text-break'>
        <div className='text-wrap'>{formatPhoneNumber(item?.uPhone)}</div>
      </td>
      <td className='col-order-qty text-break'>
        <ul className='list-unstyled mb-0'>
          {item?.allProductPerOrder?.map((product, _index) => (
            <li
              key={`${product.pid}-${_index}`}
              className={`d-flex align-items-center flex-wrap ${
                item?.allProductPerOrder?.length > 1 ? 'mb-1' : 'mb-0'
              }`}
            >
              <p className='mb-0'>
                <span>{product.pName}: </span>
                <strong>
                  <CurrencyFormat
                    value={product.pPriceNoSubItems || 0}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </strong>{' '}
                <span>({product.pQuantity})</span>
              </p>
              {product?.pSubItems?.length > 0 &&
                product.pSubItems.map((subItem, subIndex) => (
                  <div key={`${subItem.name}-${subIndex}`}>
                    <span>{subItem.name}:</span>
                    <strong className='ms-1'>
                      <CurrencyFormat
                        value={subItem.price || 0}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </strong>
                    {subIndex !== product.pSubItems.length - 1 && (
                      <span style={{ marginRight: 3 }}>,</span>
                    )}
                  </div>
                ))}
            </li>
          ))}
        </ul>
      </td>
      <td className='col-fulfill text-break'>
        <div className='d-flex flex-column fw-normal'>
          <p className='fw-bold mb-0'>Event pickup</p>
          <span>{item?.ePickupAddress}</span>
        </div>
      </td>
      <td className='col-order-date text-break'>
        <p className='mb-0'>{moment.unix(item.createdAt).format('DD-MM-YYYY')}</p>
        <span className='fw-bold' style={{ color: '#f06a0f' }}>
          {moment.unix(item.createdAt).format('HH:mm:ss')}
        </span>
      </td>
      <td className='col-fulfill-date text-break'>
        <p className='mb-0'>{moment.unix(item.ePickupTimestamp).format('DD-MM-YYYY')}</p>
      </td>
      <td className='col-payment-status text-break text-center'>
        {item?.buyerStatus === BUYER_ORDER_UNPAID && (
          <p className='n-paid text-center mb-0' style={{ paddingRight: 0, color: '#ff6903' }}>
            Not Paid
          </p>
        )}
        {item?.buyerStatus === BUYER_ORDER_PAID && (
          <div
            className='d-flex align-items-center fw-bold justify-content-center cursor-pointer'
            onClick={(e) => {
              if (!loadingEventAndOrderById && !loadingListCombinedPayment) {
                openPaymentScreen(e, item)
              }
            }}
          >
            <CurrencyFormat
              value={item?.totalFee || 0}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale={true}
            />
            <a
              href='#'
              className={`ms-1 ${item?.combinedPaymentRef ? 'paid-combined' : 'paid'}`}
              data-toggle='modal'
              data-target='#modal-paid'
            />
          </div>
        )}
        {item?.buyerStatus === BUYER_ORDER_CANCELED && (
          <p className='mb-0 text-center'>Cancelled</p>
        )}
        {item?.buyerStatus === BUYER_ORDER_COLLECTED && (
          <p className='mb-0 text-center'>
            <span
              className='d-flex align-items-center fw-bold justify-content-center cursor-pointer'
              onClick={(e) => openPaymentScreen(e, item)}
            >
              <CurrencyFormat
                value={item?.totalFee || 0}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />
              <FaCheck color='#14b711' className='ms-1' />
            </span>
          </p>
        )}
        {item?.buyerStatus === BUYER_ORDER_REFUNDED && <p className='mb-0 text-center'>Refunded</p>}
      </td>
      <td className='col-status text-break'>
        <CustomSelectBox
          options={orderStatusOptions}
          value={item?.status}
          onChange={(option) =>
            onChangeStatusSelect(
              { target: { value: option.value } },
              item?.ref,
              item?.combinedPaymentRef,
              item?.eid
            )
          }
          className={`select-status ${
            orderStatusOptions.find((e) => e.value === item?.status).className
          }`}
          isStatusSelect
          isReversedDropdown={isReversedDropdown}
        />
      </td>
      <Tooltip
        isOpen={tooltipOpen === item.ref}
        target={`tooltip-${index}`}
        toggle={() => toggleTooltip(item.ref)}
        className='tooltip-custom'
      >
        Order Ref: {item.combinedPaymentRef || item.ref}
      </Tooltip>
    </>
  )
}

import uploadApi from '_api/upload'
// import { getCartCheckout } from '_utils/localData'
import PropTypes from 'prop-types'
import React, { forwardRef, useState } from 'react'
// import { Spinner } from 'reactstrap'

const PaymentScreen = forwardRef((props, ref) => {
  const { paymentFileUrl, price } = props

  const [photoUrl, setPhotoUrl] = useState(paymentFileUrl)
  const onUserImageUploaded = async (_event) => {
    const { files } = _event.target
    if (files) {
      const { msgResp: res } = await uploadApi.uploadPhoto(files)
      const { url } = res
      setPhotoUrl(url)
      ref.current = url
      // setPaymentFileUrl(url)
    }
  }

  return (
    <div>
      <div id='allowPaymentHtml' style={{ display: 'block' }}>
        <div style={{ height: '16px' }}></div>
        <div id='pendingPaymentHtmlBlock' className='row-bot' style={{ display: 'block' }}>
          <div className='group-list group-list--event detail-event'>
            <div className='detail-list-news detail-list-news--org'>
              <h3 className='c-ttl-blue text-center' style={{ fontSize: '20px' }}>
                <strong>Step 1:</strong> Please scan the following bank account to pay
                <ul className='bg-white rounded text-black fw-normal text-left p-3 list-unstyled mt-3 f-16'>
                  <li className='mb-2'>
                    Bank: <strong>Singapore Bank</strong>
                  </li>
                  <li className='mb-2'>
                    Name: <strong>Name Example</strong>
                  </li>
                  <li className='mb-2'>
                    Account Number: <strong>102868402852</strong>
                  </li>
                  <li>
                    Price: <strong>${price}</strong>
                  </li>
                </ul>
              </h3>
            </div>
          </div>
          <h3 className='c-ttl-blue text-center' style={{ fontSize: '20px' }}>
            <strong>Step 2: </strong>
            Attach payment screenshot (Do not attach SMS)
          </h3>
          <div className='c-form mb-4'>
            <div className='upload-image upload-screenshot shadow-none rounded-0'>
              <img
                style={
                  photoUrl
                    ? { height: '100%', width: '100%', objectFit: 'contain' }
                    : { display: 'none' }
                }
                id='eventImageImg'
                src={photoUrl}
                alt=''
              />
              <div className='upload-image-txt' style={photoUrl ? { opacity: 0 } : {}}>
                <i className='icon-capture'></i>
                <span>Screenshot</span>
              </div>
              <div className='u-file-upload__item'>
                <div className='u-file-upload__wrap'>
                  <a className='c-upload preview-images-zone'>
                    <input
                      type='file'
                      accept='image/*'
                      size='40'
                      className='inputFile'
                      onChange={onUserImageUploaded}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <button
            className='btn btn--main'
            onClick={confirmPayment}
            disabled={isSubmittedPayment || isChecked}
          >
            {isSubmittedPayment ? <Spinner color='light' size='sm' /> : 'CONFIRM PAYMENT'}
          </button> */}
        </div>
      </div>
    </div>
  )
})

PaymentScreen.displayName = 'PaymentScreen'
export default PaymentScreen

PaymentScreen.propTypes = {
  confirmPayment: PropTypes.func,
  phone: PropTypes.string,
  paymentFileUrl: PropTypes.string,
  final: PropTypes.number,
  orderRef: PropTypes.string,
  orderNo: PropTypes.number,
  pickupTime: PropTypes.string,
  isSubmittedPayment: PropTypes.bool,
}

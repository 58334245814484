import PropTypes from 'prop-types'
import './style.scss'

const RenderSubItems = ({ subItems }) => {
  if (!subItems?.length) return null

  return (
    <ul className='sub-items'>
      {subItems.map((subItem, subIndex) => (
        <li key={subIndex}>{subItem.name}</li>
      ))}
    </ul>
  )
}

RenderSubItems.propTypes = {
  subItems: PropTypes.array.isRequired,
}

export default RenderSubItems

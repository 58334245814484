import { CheckoutContext } from '_contexts/CheckoutContext'
import { TURN_ON } from '_utils/constant'
import { isEmpty } from 'lodash'
import { useContext } from 'react'
import CurrencyFormat from 'react-currency-format'
import { useSelector } from 'react-redux'
import SelectPaymentMethod from './SelectPaymentMethod'

const DISCOUNT = 0

export default function FeesInfo({ subTotal, deliveryFee, pickupFee, transactionFees }) {
  const { shop } = useContext(CheckoutContext)
  const { orderDetailsByRef } = useSelector((state) => state.order)

  const totalPrice = subTotal + transactionFees + pickupFee + deliveryFee

  return (
    <div className='bill__fees-info'>
      <SelectPaymentMethod />
      <div className='bill__fees-info__item'>
        Sub-Total:
        <CurrencyFormat
          value={subTotal || 0}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
          suffix=' SGD'
          decimalScale={2}
          fixedDecimalScale={true}
          className='bill__fees-info__item--price'
        />
      </div>
      {DISCOUNT > 0 && <p className='bill__fees-info__discount mb-1'>Discount: 0 </p>}
      {deliveryFee && deliveryFee > 0 ? (
        <div className='bill__fees-info__item'>
          Delivery Fee:
          <div>
            <CurrencyFormat
              value={deliveryFee || 0}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale={true}
            />{' '}
            SGD
          </div>
        </div>
      ) : null}
      <div className='bill__fees-info__item'>
        {pickupFee && pickupFee > 0 ? (
          <>
            Pickup Fee:
            <div>
              <CurrencyFormat
                value={pickupFee || 0}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                suffix=' SGD'
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </div>
          </>
        ) : null}
      </div>
      {orderDetailsByRef?.transactionFees && (
        <>
          <div className='bill__fees-info__transaction-fee'>
            This order will add transaction fee:
            <CurrencyFormat
              className='text-end'
              value={orderDetailsByRef?.transactionFees || 0}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              suffix=' SGD'
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </div>
          <hr className='bill__fees-info__divider bg-black' />
        </>
      )}

      {shop?.transactionFeesStatus === TURN_ON && isEmpty(orderDetailsByRef) && (
        <>
          <div className='bill__fees-info__transaction-fee'>
            This order will add transaction fee:
            <CurrencyFormat
              value={shop?.transactionFees || 0}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              suffix=' SGD'
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </div>
          <hr className='bill__fees-info__divider bg-black' />
        </>
      )}
      <div className='bill__fees-info__total'>
        Total:
        <CurrencyFormat
          value={totalPrice || 0}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
          suffix=' SGD'
          decimalScale={2}
          fixedDecimalScale={true}
        />
      </div>
    </div>
  )
}

import InputField from '_components/InputField'
import React from 'react'

export default function EventSearch({ search, setSearch }) {
  return (
    <InputField
      type='text'
      placeholder='Search event name'
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      isSearch
      width='50%'
      style={{ height: 32 }}
    />
  )
}

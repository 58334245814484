/* eslint-disable no-useless-escape */
import { yupResolver } from '@hookform/resolvers/yup'
import uploadApi from '_api/upload'
import noImage from '_images/noimage02.svg'
import { fetchMyShops, setIdShop } from '_redux/modules/shop'
import { fetchListInvoiceSubscription } from '_redux/modules/subscription'
import { NO_DATA_SHOP_DETAIL } from '_utils/constant'
import { removeAccents, removeDuplicateHyphens } from '_utils/function'
import { domainGenerator } from '_utils/functions/generator'
import {
  getCreateShopData,
  getUserToken,
  removeDataShopDetail,
  setCreateShopData,
} from '_utils/localData'
import { shopValidationSchema } from '_utils/validationSchemas'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { GrPowerReset } from 'react-icons/gr'
import { IoSave } from 'react-icons/io5'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useToasts } from 'react-toast-notifications'

import CustomButton from '_components/CustomButton'
import InputField from '_components/InputField'
import ContactInputs from '_components/Shop/ContactInputs'
import ShopName from '_components/Shop/ShopName'
import RenderListImage from './components/RenderListImage'
import './style.scss'

// const phoneRegExp = /^[0-9\- ]{8,14}$/
// const shopNameRegex = /^[^\!\@\#\$\%\^\&\*\(\)\=\+\/\*\~\`\|\\\}\]\{\[\"\'\:\;\?\>\<\,]+$/

const validationSchema = shopValidationSchema

function Create() {
  // const userInfo = getUserInfo()
  const history = useHistory()
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const createShopData = getCreateShopData()
  const [listImageShop, setListImageShop] = useState(
    createShopData?.logo?.map((item) => ({ url: item, file: '' } || [])) || []
  )

  const userToken = getUserToken()

  useEffect(() => {
    if (userToken) {
      dispatch(fetchMyShops())
      removeDataShopDetail()
      dispatch(setIdShop(NO_DATA_SHOP_DETAIL))
      dispatch(fetchListInvoiceSubscription())
    }
  }, [userToken])

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  const onUserImageUploaded = (event) => {
    const { files } = event.target
    if (files.length + listImageShop?.length > 64) {
      addToast('Maximum images for shop is 64!', { appearance: 'error', autoDismiss: true })
      return
    }

    for (let i = 0; i < files.length; i++) {
      if (i < 64) {
        // Store base64 string instead of URL for persistence
        const reader = new FileReader()
        reader.onloadend = () => {
          listImageShop.push({
            url: reader.result, // Store as base64
            file: files[i],
          })
          setListImageShop([...listImageShop])
        }
        reader.readAsDataURL(files[i])
      }
    }
  }

  const handleOnRemoveImg = (index) => {
    listImageShop.splice(index, 1)
    setListImageShop([...listImageShop])
  }

  const onSubmit = async (data) => {
    const textAfterHandle = removeDuplicateHyphens(removeAccents(data.shopName))
    const list = listImageShop

    for (let i = 0; i < list.length; i++) {
      const element = list[i]
      if (element.file) {
        const { msgResp } = await uploadApi.uploadPhoto([element.file])
        element.url = msgResp.url
      }
    }

    if (!createShopData) {
      const postData = {
        // colorScheme,
        shopLink: `${domainGenerator()}${textAfterHandle.split(' ').join('-')}`,
        logo: list.map((item) => item.url),
        shopSlug: removeDuplicateHyphens(removeAccents(data.shopName)),
        shopFrontType: 1,
        isShopCompleted: false,
        isShowGuide: true,
        ...data,
      }
      setCreateShopData(postData)
      history.push('/virtual-shop/add-items')
    } else {
      const postData = {
        // colorScheme,
        shopLink: `${domainGenerator()}${textAfterHandle.split(' ').join('-')}`,
        logo: list.map((item) => item.url),
        products: createShopData?.products,
        shopSlug: removeDuplicateHyphens(removeAccents(data.shopName)),
        shopFrontType: 1,
        isShopCompleted: false,
        isShowGuide: true,
        ...data,
      }
      setCreateShopData(postData)
      history.push('/virtual-shop/add-items')
    }
  }

  return (
    <div className='container py-3'>
      <div className='virtual-shop create-items-page' style={{ marginTop: 0 }}>
        <p className='text-center title'>Setting Up Your Online Shop</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ShopName
            error={errors?.shopName?.message}
            register={register}
            value={createShopData?.shopName}
          />

          <div className='row mb-3'>
            <p className='add-more-image text-end mb-2 col-12'>
              Remain: ({64 - listImageShop.length} photos left)
            </p>
            <div className='col-12'>
              <RenderListImage
                listImageShop={listImageShop}
                handleOnRemoveImg={handleOnRemoveImg}
                onUserImageUploaded={onUserImageUploaded}
                noImage={noImage}
              />
            </div>
          </div>

          <InputField
            offset={{ bottom: 16 }}
            as='textarea'
            register={register('headline')}
            error={errors?.headline?.message}
            label={'Headline to attract buyers'}
          />

          <ContactInputs register={register} errors={errors} createShopData={createShopData} />

          <div className='row mt-5'>
            <div className='col-6'>
              <CustomButton
                type='reset'
                icon={<GrPowerReset />}
                onClick={() => {
                  localStorage.removeItem('CREATE_SHOP_DATA')
                  history.push('/')
                }}
                text='Cancel'
                variant='outlined'
                color='slate'
                className='mt-0'
              />
            </div>
            <div className='col-6'>
              <CustomButton
                type='submit'
                text={isSubmitting ? 'Processing...' : 'Save & Next'}
                icon={<IoSave />}
                color='aqua'
                variant='contained'
                className='mt-0'
                disabled={isSubmitting}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Create

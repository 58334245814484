import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const DeliveryDetailsSkeleton = () => (
  <div className='bg-white p-3 mb-3 rounded delivery-details-skeleton'>
    <div className='d-flex justify-content-between mb-3'>
      <Skeleton width={150} height={24} />
    </div>
    <div className='row'>
      <div className='col-6'>
        <Skeleton height={45} className='mb-3' />
      </div>
      <div className='col-6'>
        <Skeleton height={45} className='mb-3' />
      </div>
    </div>
  </div>
)

const ContactDetailsSkeleton = () => (
  <div className='bg-white p-3 mb-3 rounded contact-skeleton'>
    <div className='mb-3'>
      <Skeleton width={150} height={24} />
    </div>
    <div className='row'>
      {[...Array(4)].map((_, i) => (
        <div key={i} className='col-12 mb-3'>
          <Skeleton height={45} />
        </div>
      ))}
    </div>
  </div>
)

const BillSkeleton = () => (
  <div className='bg-white p-3 rounded bill-skeleton'>
    <div className='mb-3'>
      <Skeleton width={100} height={24} />
    </div>
    <div className='mb-3'>
      {[...Array(3)].map((_, i) => (
        <div key={i} className='d-flex justify-content-between align-items-center mb-2'>
          <div style={{ flex: 1 }}>
            <Skeleton height={20} />
          </div>
        </div>
      ))}
    </div>
    <div className='d-flex justify-content-between align-items-center'>
      <Skeleton width={100} height={24} />
      <Skeleton width={120} height={24} />
    </div>
  </div>
)

const ButtonsSkeleton = () => (
  <div className='row mt-3'>
    <div className='col-6'>
      <Skeleton height={45} />
    </div>
    <div className='col-6'>
      <Skeleton height={45} />
    </div>
  </div>
)

const CheckoutSkeleton = () => (
  <div className='container checkout-skeleton'>
    <div className='checkout px-3'>
      <div className='delivery-contact-skeleton'>
        <DeliveryDetailsSkeleton />
        <ContactDetailsSkeleton />
      </div>
      <div className='bill-button-skeleton'>
        <BillSkeleton />
        <ButtonsSkeleton />
      </div>
    </div>
  </div>
)

export default CheckoutSkeleton

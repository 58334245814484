/* eslint-disable no-prototype-builtins */
import orderEventApi from '_api/orderEvent'
import CustomTable from '_components/CustomTable'
import NotFoundDataComponent from '_components/NotFoundDataComponent'
import { OrderRecordContext } from '_contexts/OrderRecordContext'
import { getEventInShop } from '_redux/modules/event'
import {
  getEventAndOrder,
  getListCombinedPayment,
  updateOrderCombinedPayment,
  updateOrderEventStatus,
} from '_redux/modules/orderEvent'
import {
  BUYER_ORDER_PAID,
  BUYER_ORDER_REFUNDED,
  BUYER_ORDER_UNPAID,
  HOST_ORDER_CANCELED,
  HOST_ORDER_UNPAID,
} from '_utils/constant'
import { handleDataOrderEvent } from '_utils/functions/handler'
import { isEmpty } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { GoInbox } from 'react-icons/go'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import ModalDetailOrder from './ModalDetailOrder'
import ModalEventPaymentScreen from './ModalEventPaymentScreen'
import ModalOrderComment from './ModalOrderComment'
import ModalWarning from './ModalWarning'
import OrderEventRecordItem from './OrderEventRecordItem'
import './style.scss'

export default function ListOrderEventRecord() {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { listOrdersEventFiltered } = useContext(OrderRecordContext)
  const { selectedShop } = useSelector((state) => state.shop)
  const { listCombinedPayments, eventAndOrderById } = useSelector((state) => state.orderEvent)

  const [dataModalPaymentScreen, setDataModalPaymentScreen] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(null)
  const [isOpenModalPaymentScreen, setIsOpenModalPaymentScreen] = useState(false)
  const [openOrderComment, setOpenOrderComment] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [dataModal, setDataModal] = useState({})
  const [dataModalDetailOrder, setDataModalDetailOrder] = useState({})
  const [modalDetailOrder, setModalDetailOrder] = useState(false)
  const [modalWarning, setModalWarning] = useState(false)

  const eidParam = new URLSearchParams(window.location.search).get('eid')

  useEffect(() => {
    let isMounted = true

    const fetchData = async () => {
      setIsLoading(true)

      try {
        await Promise.all([
          dispatch(getEventInShop(selectedShop?.id)),
          dispatch(getEventAndOrder(eidParam)),
        ])
      } finally {
        if (isMounted) {
          setIsLoading(false)
        }
      }
    }

    fetchData()

    return () => {
      isMounted = false
    }
  }, [selectedShop?.id, eidParam])

  const toggleDetailOrder = () => setModalDetailOrder(!modalDetailOrder)
  const toggleWarning = () => setModalWarning(!modalWarning)

  const handleOpenWarning = (e, order) => {
    e.preventDefault()
    setDataModal(order)
    toggleWarning()
  }

  const initListCombinedPayment = async (_data) => {
    try {
      const listCombinedPaymentRef = [
        ...new Set(_data.map(({ combinedPaymentRef = '' }) => combinedPaymentRef).filter(Boolean)),
      ]

      if (listCombinedPaymentRef.length) {
        await dispatch(getListCombinedPayment(listCombinedPaymentRef))
      } else {
        dispatch({
          type: 'SET_LOADING_LIST_COMBINED_PAYMENT',
          payload: { loadingListCombinedPayment: false },
        })
      }
    } catch (error) {
      addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (
      listOrdersEventFiltered &&
      Array.isArray(listOrdersEventFiltered) &&
      listOrdersEventFiltered.length
    ) {
      initListCombinedPayment(listOrdersEventFiltered)
    } else {
      dispatch({
        type: 'SET_LOADING_LIST_COMBINED_PAYMENT',
        payload: { loadingListCombinedPayment: false },
      })
    }
  }, [JSON.stringify(listOrdersEventFiltered)])

  const toggleTooltip = (itemRef) => setTooltipOpen(tooltipOpen === itemRef ? null : itemRef)

  const toggleModalPaymentScreen = () => setIsOpenModalPaymentScreen(!isOpenModalPaymentScreen)

  const toggleOpenModalComment = () => setOpenOrderComment(!openOrderComment)

  const handleUpdateStatusCombinedPayment = async (combinedPaymentRef, postStatus) => {
    try {
      dispatch(
        updateOrderCombinedPayment(combinedPaymentRef, postStatus.status, postStatus.buyerStatus)
      )
      await orderEventApi.updateInvoicesStatus({ ...postStatus, combinedPaymentRef })
      addToast('Updated successfully!', { appearance: 'success', autoDismiss: true })
    } catch (error) {
      addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleUpdateStatusPayment = async (ref, postStatus, eid) => {
    try {
      dispatch(updateOrderEventStatus(eid, ref, postStatus.status, postStatus.buyerStatus))
      await orderEventApi.updateOrderEventStatus(ref, postStatus)
      addToast('Updated successfully!', { appearance: 'success', autoDismiss: true })
    } catch (error) {
      addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleInvalidPayment = async ({ ref, combinedPaymentRef, eid }) => {
    const r = confirm('Are you sure this is invalid payment?')
    if (r === true) {
      try {
        const postStatus = {
          status: HOST_ORDER_UNPAID,
          buyerStatus: BUYER_ORDER_UNPAID,
        }

        if (!combinedPaymentRef) {
          await handleUpdateStatusPayment(ref, postStatus, eid)
        } else {
          postStatus.placerStatus = BUYER_ORDER_UNPAID
          await handleUpdateStatusCombinedPayment(combinedPaymentRef, postStatus)
        }
      } catch (error) {
        addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
      } finally {
        toggleModalPaymentScreen()
      }
    }
  }

  const openPaymentScreen = (e, order) => {
    e.preventDefault()

    const { combinedPaymentRef = '' } = order
    let listCombinedOrder = []
    let listCombinedDelivery = []

    if (combinedPaymentRef && eventAndOrderById.length && listCombinedPayments.length) {
      const exitCombinedPayment = listCombinedPayments.findIndex(
        (cpItem) => cpItem.combinedPaymentRef === combinedPaymentRef
      )

      if (exitCombinedPayment !== -1) {
        const { orderCombinedPayment, deliveryCombinedPayment } =
          listCombinedPayments[exitCombinedPayment]

        orderCombinedPayment
          // exclude current order
          .forEach((element) => {
            const { eid, ref: elementRef } = element
            const { orders, adminCost, discount, deliveryCost, productIdList } =
              eventAndOrderById.find((eoItem) => eoItem.id === eid) || {}
            const { listOrderEventHandled } = handleDataOrderEvent(
              orders,
              adminCost,
              discount,
              deliveryCost,
              productIdList
            )
            const otherOrder = listOrderEventHandled.find((ooItem) => ooItem.ref === elementRef)
            listCombinedOrder = [...listCombinedOrder, otherOrder]
          })
        listCombinedDelivery = deliveryCombinedPayment ? [...deliveryCombinedPayment] : []
      }
    }

    setDataModalPaymentScreen({ ...order, listCombinedOrder, listCombinedDelivery })
    toggleModalPaymentScreen()
  }

  const onChangeStatusSelect = async (e, ref, _combinedPaymentRef, eid) => {
    const { value } = e.target
    const statusSelected = {
      status: Number(value),
      buyerStatus: Number(value),
    }

    // Number(value) === HOST_ORDER_CANCELED &&
    if (_combinedPaymentRef) {
      await handleUpdateStatusCombinedPayment(_combinedPaymentRef, statusSelected)
      return
    }

    await handleUpdateStatusPayment(ref, statusSelected, eid)
  }

  const handleCancelOrder = async (e, ref, _status, _buyerStatus, _combinedPaymentRef) => {
    e.preventDefault()
    try {
      if (_status !== HOST_ORDER_CANCELED && _buyerStatus > BUYER_ORDER_PAID) {
        if (_combinedPaymentRef) {
          const _data = {
            status: HOST_ORDER_CANCELED,
            placerStatus: BUYER_ORDER_REFUNDED,
          }
          await handleUpdateStatusCombinedPayment(_combinedPaymentRef, _data)

          toggleDetailOrder()
          toggleWarning()
        } else {
          const _data = { status: HOST_ORDER_CANCELED, buyerStatus: BUYER_ORDER_REFUNDED }
          await handleUpdateStatusPayment(ref, _data, eidParam)

          toggleDetailOrder()
          toggleWarning()
        }
      } else {
        const _data = { status: HOST_ORDER_CANCELED, _buyerStatus }
        await handleUpdateStatusPayment(ref, _data, eidParam)

        toggleDetailOrder()
        toggleWarning()
      }
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
      toggleDetailOrder()
      toggleWarning()
    }
  }

  const handleShowDetail = (e, order) => {
    e.preventDefault()
    setDataModalDetailOrder(order)
    toggleDetailOrder()
  }

  const onOpenComment = (order) => {
    setSelectedOrder(order)
    toggleOpenModalComment()
  }

  const tableHeaders = [
    {
      label: 'Order #',
      className: 'col-order-no',
      minWidth: '60px',
      nowrap: true,
    },
    {
      label: 'Customer Name',
      className: 'col-customer-name',
      minWidth: '200px',
      nowrap: true,
    },
    {
      label: 'Phone',
      className: 'col-phone',
      minWidth: '180px',
      nowrap: true,
    },
    {
      label: 'Orders/Qty',
      className: 'col-2',
      minWidth: '250px',
      nowrap: true,
    },
    {
      label: 'Fulfill',
      className: 'col-fulfill',
      minWidth: '250px',
      nowrap: true,
    },
    {
      label: 'Order Date',
      className: 'col-order-date',
      minWidth: '150px',
      nowrap: true,
    },
    {
      label: 'Fulfill Date',
      className: 'col-fulfill-date',
      minWidth: '150px',
      nowrap: true,
    },
    {
      label: 'Payment',
      className: 'col-payment-status text-center',
      minWidth: '180px',
      nowrap: true,
    },
    {
      label: 'Status',
      className: 'col-status',
      minWidth: '160px',
      nowrap: true,
    },
  ]

  const renderRow = (item, index, getStickyClass) => (
    <OrderEventRecordItem
      item={item}
      index={index}
      tooltipOpen={tooltipOpen}
      toggleTooltip={toggleTooltip}
      toggleModalPaymentScreen={toggleModalPaymentScreen}
      setDataModalPaymentScreen={setDataModalPaymentScreen}
      setSelectedOrder={setSelectedOrder}
      toggleOpenModalComment={toggleOpenModalComment}
      openPaymentScreen={openPaymentScreen}
      onChangeStatusSelect={onChangeStatusSelect}
      onOpenComment={onOpenComment}
      handleShowDetail={handleShowDetail}
      getStickyClass={getStickyClass}
      listLength={listOrdersEventFiltered.length}
    />
  )

  if (isLoading) {
    return <div className='text-center text-info fw-bold f-16 my-5'>Loading...</div>
  }

  if (!isLoading && !listOrdersEventFiltered.length) {
    return (
      <NotFoundDataComponent
        icon={<GoInbox />}
        title='No Orders Found'
        subtitle='There are currently no orders to display'
      />
    )
  }

  return (
    <div className='event-list-order'>
      {!isEmpty(dataModalPaymentScreen) && (
        <>
          <ModalEventPaymentScreen
            data={dataModalPaymentScreen}
            modalPaymentScreen={isOpenModalPaymentScreen}
            togglePaymentScreen={toggleModalPaymentScreen}
            handleInvalidPayment={handleInvalidPayment}
          />
        </>
      )}
      {!isLoading && (
        <CustomTable
          headers={tableHeaders}
          data={listOrdersEventFiltered}
          renderRow={renderRow}
          stickyColumns={['Order #', 'Customer Name']}
        />
      )}

      <ModalOrderComment
        openOrderComment={openOrderComment}
        toggleOpenModalComment={toggleOpenModalComment}
        selectedOrder={selectedOrder}
      />

      <ModalWarning
        data={dataModal}
        modalWarning={modalWarning}
        toggleWarning={toggleWarning}
        handleCancel={handleCancelOrder}
      />

      <ModalDetailOrder
        id={eidParam}
        order={dataModalDetailOrder}
        modalDetailOrder={modalDetailOrder}
        toggleDetailOrder={toggleDetailOrder}
        handleOpenWarning={handleOpenWarning}
        toggleWarning={toggleWarning}
      />
    </div>
  )
}

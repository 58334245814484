import classNames from 'classnames'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function ConfirmShopSkeleton() {
  return (
    <div className={classNames('shop-detail')} style={{ backgroundColor: '#F5F5F5' }}>
      <div
        className={classNames('container bg-white shop-detail-body mt-0 pb-3', {
          'w-75 mx-auto': window.innerWidth > 576,
        })}
      >
        {/* Back button skeleton */}
        <div className='btn-back'>
          <Skeleton width={80} height={30} />
        </div>

        {/* Banner skeleton */}
        <div className='shop-banner mb-4'>
          <Skeleton height={200} />
        </div>

        {/* View selector skeleton */}
        <div className='d-flex justify-content-end mb-3'>
          <Skeleton width={120} height={35} />
        </div>

        {/* Products skeleton */}
        {[1, 2, 3].map((item) => (
          <div key={item} className='mb-4'>
            <Skeleton height={150} />
          </div>
        ))}

        {/* Publish button skeleton */}
        <div className='d-flex justify-content-center'>
          <Skeleton width='50%' height={50} />
        </div>
      </div>
    </div>
  )
}

export default ConfirmShopSkeleton

import {
  LAZADA_FEATURE_PAYMENT_STATUS_APPROVED,
  LAZADA_FEATURE_PAYMENT_STATUS_CANCEL,
  LAZADA_FEATURE_PAYMENT_STATUS_PAYMENT_PAID,
  LAZADA_FEATURE_PAYMENT_STATUS_PAYMENT_UNPAID,
  LAZADA_FEATURE_PAYMENT_STATUS_PENDING,
  LAZADA_FEATURE_PAYMENT_STATUS_REFUND,
  LAZADA_FEATURE_PAYMENT_STATUS_REFUNDED,
  SUBSCRIPTION_PAYMENT_STATUS_PAID,
  SUBSCRIPTION_STATUS_CANCEL,
  SUBSCRIPTION_STATUS_EXPIRED,
  SUBSCRIPTION_STATUS_FAIL,
  SUBSCRIPTION_STATUS_PENDING,
  SUBSCRIPTION_STATUS_REFUND,
  SUBSCRIPTION_STATUS_REFUNDED,
  SUBSCRIPTION_STATUS_UNPAID,
} from '_utils/constant'
import React from 'react'

export const RenderStatus = ({ item, type = 'subscription' }) => {
  const statusMapLazada = {
    [LAZADA_FEATURE_PAYMENT_STATUS_APPROVED]: { label: 'Approved', className: 'badge bg-success' },
    [LAZADA_FEATURE_PAYMENT_STATUS_PENDING]: { label: 'Pending', className: 'badge bg-warning' },
    [LAZADA_FEATURE_PAYMENT_STATUS_CANCEL]: { label: 'Cancelled', className: 'badge bg-secondary' },
    [LAZADA_FEATURE_PAYMENT_STATUS_REFUND]: {
      label: 'Refund',
      className: 'text-secondary fw-bold',
    },
    [LAZADA_FEATURE_PAYMENT_STATUS_REFUNDED]: {
      label: 'Refunded',
      className: 'text-secondary fw-bold',
    },
  }

  const statusMapSubscription = {
    [SUBSCRIPTION_STATUS_UNPAID]: { label: 'Unpaid', className: 'badge bg-warning' },
    [SUBSCRIPTION_STATUS_REFUND]: { label: 'Handling refund', className: 'badge bg-info' },
    [SUBSCRIPTION_STATUS_REFUNDED]: { label: 'Refunded', className: 'text-secondary fw-bold' },
    [SUBSCRIPTION_STATUS_PENDING]: { label: 'Pending', className: 'badge bg-warning' },
    [SUBSCRIPTION_STATUS_FAIL]: { label: 'Failure', className: 'text-secondary fw-bold' },
    [SUBSCRIPTION_STATUS_CANCEL]: { label: 'Cancelled', className: 'badge bg-secondary' },
    [SUBSCRIPTION_STATUS_EXPIRED]: { label: 'Expired', className: 'badge bg-danger' },
    free_trial: { label: 'Free trial', className: 'badge bg-info' },
    default: { label: 'Paid', className: 'badge bg-success' },
  }

  const statusMap = type === 'subscription' ? statusMapSubscription : statusMapLazada

  const getStatus = () => {
    // Đối với Lazada
    if (type === 'lazada') {
      if (
        item?.status === LAZADA_FEATURE_PAYMENT_STATUS_PENDING &&
        item?.paymentStatus === LAZADA_FEATURE_PAYMENT_STATUS_PAYMENT_UNPAID
      ) {
        return { label: 'Unpaid', className: 'badge bg-warning' }
      }
      if (
        item?.status === LAZADA_FEATURE_PAYMENT_STATUS_PENDING &&
        item?.paymentStatus === LAZADA_FEATURE_PAYMENT_STATUS_PAYMENT_PAID
      ) {
        return { label: 'Waiting approval', className: 'badge bg-info' }
      }
    }

    // Đối với Subscription
    if (
      item?.status === SUBSCRIPTION_STATUS_PENDING &&
      item?.paymentStatus === SUBSCRIPTION_PAYMENT_STATUS_PAID
    ) {
      return { label: 'Awaiting Approval', className: 'badge bg-info' }
    }
    if (item?.subscriptionPrice === 0) return statusMap.free_trial
    return statusMap[item?.status] || statusMap.default
  }
  const { label, className } = getStatus()

  return <span className={`common-status ${className}`}>{label}</span>
}

export default RenderStatus

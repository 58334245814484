import CustomButton from '_components/CustomButton'
import InputField from '_components/InputField'
import { DeliveryContext } from '_contexts/DeliveryContext'
import { PACKAGE_BASIC, SUBSCRIPTION_STATUS_UNPAID } from '_utils/constant'
import { useContext } from 'react'
import { AiOutlineFilter } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import ButtonDeliveryEvent from './ButtonDeliveryEvent'
import DeliveryActionsSkeleton from './DeliveryActionsSkeleton'
import DeliveryExport from './DeliveryExport'

const DeliveryActions = () => {
  const { search, handleSearch, toggleModal, handleClear, loading } = useContext(DeliveryContext)
  const { listInvoiceSubscription } = useSelector((state) => state.subscription)

  const shouldDisplayEvents = () => {
    if (listInvoiceSubscription.length === 1) {
      return listInvoiceSubscription[0].subscriptionPackageType !== PACKAGE_BASIC
    }
    if (listInvoiceSubscription.length > 1) {
      return listInvoiceSubscription.some(
        (subscription) =>
          subscription.subscriptionPackageType !== PACKAGE_BASIC &&
          subscription.status !== SUBSCRIPTION_STATUS_UNPAID
      )
    }
    return false
  }

  if (loading) {
    return <DeliveryActionsSkeleton />
  }

  return (
    <div className='delivery-actions d-flex align-items-center justify-content-between'>
      <InputField
        as='input'
        type='text'
        placeholder='Search'
        value={search}
        onChange={handleSearch}
        isSearch={true}
        className='delivery-actions__input'
      />

      <div className='delivery-actions__buttons'>
        <CustomButton
          text='Fulfillment Date'
          onClick={toggleModal}
          className='delivery-actions__button w-auto mt-0'
          color='slate'
        />

        {shouldDisplayEvents() && (
          <ButtonDeliveryEvent className='delivery-actions__event-button' />
        )}

        <DeliveryExport className='delivery-actions__export' />

        <CustomButton
          icon={<AiOutlineFilter />}
          text='Clear'
          onClick={handleClear}
          className='delivery-actions__clear-button mt-0'
          style={{ width: '112px' }}
        />
      </div>
    </div>
  )
}

export default DeliveryActions

/* eslint-disable no-undef */
/* eslint-disable max-len */
import axiosClient from './axiosClient'

const dashBoardShopApi = {
  getListTotalSalesShop: (data) => {
    const url = `/list-total-sales-per-day-by-shop?startDate=${data.startDate}&endDate=${data.endDate}&shopId=${data.shopId}`
    return axiosClient.get(url)
  },
  getListTotalOrdersShop: (data) => {
    const url = `/list-total-orders-per-day-by-shop?startDate=${data.startDate}&endDate=${data.endDate}&shopId=${data.shopId}`
    return axiosClient.get(url)
  },
  getListTotalAverageOrderShop: (data) => {
    const url = `/list-average-order-size-per-day-by-shop?startDate=${data.startDate}&endDate=${data.endDate}&shopId=${data.shopId}`
    return axiosClient.get(url)
  },
  getTopProductByHostShop: (data) => {
    const url = `/get-top-product-by-shop?startDate=${data.startDate}&endDate=${data.endDate}&shopId=${data.shopId}`
    return axiosClient.get(url)
  },
}

export default dashBoardShopApi

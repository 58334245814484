import CustomButton from '_components/CustomButton'
import CustomModal from '_components/CustomModal'
import React, { useContext, useEffect, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { IoSave } from 'react-icons/io5'
import { TiCancel } from 'react-icons/ti'
import { useSelector } from 'react-redux'
import { ProductItemContext } from '.'

function ProductPrice(props) {
  const { productPrice } = props

  const { isSavedProduct } = useSelector((state) => state.product)
  const { handleSubmit, register, setValue, errors, isSubmitting, handleSave } =
    useContext(ProductItemContext)

  const [isEditPrice, setIsEditPrice] = useState(false)

  useEffect(() => {
    if (isSavedProduct) setIsEditPrice(false)
  }, [isSavedProduct])

  const toggle = () => {
    setIsEditPrice(!isEditPrice)
  }

  const onSubmit = (data) => {
    handleSave(data)
    toggle()
  }

  return (
    <>
      <CustomModal isOpen={isEditPrice} toggle={toggle} title={'Edit Price ($)'} hideFooter>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='form-group'>
            <CurrencyFormat
              id='product-price'
              value={productPrice}
              displayType={'input'}
              thousandSeparator={true}
              decimalScale={2}
              className={`form-control custom-input ${errors.price ? 'input-invalid' : ''}`}
              onValueChange={(values) => {
                const { value } = values
                setValue('price', value)
              }}
              {...register('price')}
            />

            {errors.price && <small className='text-danger'>{errors.price.message}</small>}
          </div>

          <div className='d-flex gap-2 w-100 mt-3'>
            <CustomButton
              text='Cancel'
              color='slate'
              type='reset'
              icon={<TiCancel />}
              className='mt-0 flex-fill'
              onClick={toggle}
            />
            <CustomButton
              text={isSubmitting ? 'Saving...' : 'Save'}
              type='submit'
              isDisabled={isSubmitting}
              className='mt-0 flex-fill'
              variant='contained'
              color='aqua'
              icon={<IoSave />}
            />
          </div>
        </form>
      </CustomModal>

      <div className='text-content text-end' onClick={toggle}>
        <CurrencyFormat
          value={productPrice || 0}
          displayType='text'
          thousandSeparator={true}
          decimalScale={2}
          fixedDecimalScale={true}
          // prefix='$'
        />
      </div>
    </>
  )
}

export default ProductPrice

/* eslint-disable function-paren-newline */
import NotFoundDataComponent from '_components/NotFoundDataComponent'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { GoInbox } from 'react-icons/go'
import ShopItem from './ShopItem'

function ShopList({ listShop, search }) {
  const renderShops = (shops) => (
    <div className={'shop-list'}>
      {shops.map((shop) => (
        <Fragment key={shop.id}>
          <ShopItem shop={shop} />
        </Fragment>
      ))}
    </div>
  )

  if (search.length === 0) {
    return renderShops(listShop)
  }

  return listShop?.length === 0 ? (
    <NotFoundDataComponent
      icon={<GoInbox />}
      title='No shops found matching your search'
      subtitle='Try searching for another shop'
    />
  ) : (
    renderShops(listShop)
  )
}

ShopList.propTypes = {
  listShop: PropTypes.array.isRequired,
  search: PropTypes.string.isRequired,
}

export default ShopList

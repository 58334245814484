import {
  MONTHLY_SUBSCRIPTION,
  SUBSCRIPTION_PAYMENT_STATUS_PAID,
  SUBSCRIPTION_PAYMENT_STATUS_VERIFY,
  SUBSCRIPTION_STATUS_PENDING,
  SUBSCRIPTION_STATUS_UNPAID,
} from '_utils/constant'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { PACKAGE_SUBSCRIPTION_BASIC } from '../../../utils/constant'

const ButtonVariation = ({ variant, packageItem, onSelectPackage }) => {
  if (!variant.available) return null

  const { isSubmittedPayment, subscriptionPackageSelected } = useSelector(
    (state) => state.subscription
  )
  const location = useLocation()
  const { addToast } = useToasts()
  const { currentPackage, listInvoiceSubscription } = useSelector((state) => state.subscription)
  const isFreeVariant = variant.price === 0

  const validSelectedPackage = variant.id === currentPackage?.variationId
  const isDisabled = (isFreeVariant || validSelectedPackage) && location.pathname !== '/'
  const isPending =
    validSelectedPackage &&
    (currentPackage?.status === SUBSCRIPTION_STATUS_PENDING ||
      currentPackage?.status === SUBSCRIPTION_STATUS_UNPAID)

  const onSignUpSubscription = () => {
    const listStatus = [
      SUBSCRIPTION_STATUS_PENDING,
      SUBSCRIPTION_STATUS_UNPAID,
      SUBSCRIPTION_PAYMENT_STATUS_VERIFY,
      SUBSCRIPTION_PAYMENT_STATUS_PAID,
    ]

    const isPreviousRegistered = listInvoiceSubscription
      .filter((item) => item.subscriptionPackageType !== PACKAGE_SUBSCRIPTION_BASIC)
      .some((item) => listStatus.includes(item.status))

    if (isPreviousRegistered) {
      return addToast('You have registered the previous package.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    onSelectPackage({
      ...packageItem,
      variationId: variant.id,
      variantPrice: variant.price,
      discountPrice: variant.discountPrice,
    })
  }

  const getButtonClassName = () => {
    const baseClass = 'btn rounded shadow-none d-flex flex-column mb-3'

    if (isDisabled) {
      return `${baseClass} btn-outline-midnight-blue disabled`
    }

    return `${baseClass} btn-midnight-blue`
  }

  return (
    <button
      disabled={isDisabled || isSubmittedPayment}
      onClick={onSignUpSubscription}
      className={getButtonClassName()}
      style={{
        height: 54,
        wordBreak: 'break-word',
        whiteSpace: 'normal',
      }}
    >
      {subscriptionPackageSelected?.variationId === variant.id && isSubmittedPayment ? (
        'Processing payment...'
      ) : (
        <>
          {location.pathname === '/' && isFreeVariant && 'Sign-up for free'}

          {location.pathname !== '/' ? (
            <>
              {isPending
                ? 'Please make the payment to us, or you can cancel.'
                : isFreeVariant || validSelectedPackage
                ? 'You are subscribed to this plan'
                : null}
            </>
          ) : null}

          {!isFreeVariant && !validSelectedPackage ? (
            <>
              <span>
                {variant.expireValue > MONTHLY_SUBSCRIPTION ? 'Pay Annually' : 'Pay Monthly'}
              </span>
              <div className='package-price'>
                SGD{' '}
                {variant.discountPrice ? (
                  <>
                    <span className='fw-bold text-warning'>${variant.discountPrice}</span>
                    <sup>
                      <strike className='text-secondary'>${variant.price}</strike>
                    </sup>
                  </>
                ) : (
                  <span>${variant.price}</span>
                )}{' '}
                / {variant.expireValue > MONTHLY_SUBSCRIPTION ? 'Year' : 'Month'}
              </div>
            </>
          ) : null}
        </>
      )}
    </button>
  )
}

export default ButtonVariation

import axiosClient from './axiosClient'

const subscriptionApi = {
  createSubscriptionPaymentRequest: (data) => {
    const url = 'create-subscription-payment'
    return axiosClient.post(url, data)
  },
  checkBeforePaymentWithHitpay: (subId) => {
    const url = `check-before-payment-with-hit-pay?subId=${subId}`
    return axiosClient.get(url)
  },
  createSubscription: (userId, postData) => {
    const url = `create-subscription-for-user?userId=${userId}`
    return axiosClient.post(url, postData)
  },
  createLazadaPaymentRequest: (shopId, data) => {
    const url = `create-lazada-feature?shopId=${shopId}`
    return axiosClient.post(url, data)
  },
  removeSubscription: (subscriptionId) => {
    const url = 'remove-subscription-payment'
    return axiosClient.post(
      url,
      {},
      {
        params: { subId: subscriptionId },
      }
    )
  },
}

export default subscriptionApi

/* eslint-disable function-paren-newline */
/* eslint-disable max-len */
import { TourProvider } from '@reactour/tour'
import userApi from '_api/user'
import CustomButton from '_components/CustomButton'
import CustomSwalFire from '_components/CustomSwalFire'
import Footer from '_components/Footer'
import NotFound from '_components/NotFound'
import { usePrevious } from '_hooks/usePrevious' // Make sure to import a custom hook to get the previous value
import { addToCart, clearCart, clearOrderDetailsByRef, getCart } from '_redux/modules/order'
import {
  clearShopAndShopDetail,
  getShopByShopSlug,
  setViewDefaultForShop,
} from '_redux/modules/shop'
import { SUBITEM_REQUIRED, VIEW_GRID, VIEW_LIST } from '_utils/constant'
import { scrollToTop } from '_utils/function'
import {
  getCartOrderDetailsByRef,
  getCreateShopData,
  getUserInfo,
  getViewDefault,
  removeCartOrderDetailsByRef,
  removeCreateShopData,
  setCartCheckout,
  setUserInfo,
} from '_utils/localData'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { IoBagCheckOutline } from 'react-icons/io5'
import { MdOutlineDelete } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useToasts } from 'react-toast-notifications'
import steps from './TourGuide/steps'
import AuthNavigationBar from './components/AuthNavigationBarr'
import { ModalCongratulationCreateShop } from './components/ModalCongratulationCreateShop'
import ProductList from './components/ProductList'
import ShopBanner from './components/ShopBanner'
import ShopDetailSkeleton from './components/ShopDetailSkeleton'
import './styles/index.scss'

function ShopDetail() {
  const user = getUserInfo()
  const history = useHistory()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const location = useLocation()

  const arrShopSlugParams = window.location.pathname.split('/')
  const shopSlugParams = arrShopSlugParams.filter(
    (slug) => slug && !['admin-console', 'shop-front'].includes(slug)
  )[0]

  const [noShopFound, setNoShopFound] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [orderProducts, setOrderProducts] = useState([])
  const [listProduct, setListProduct] = useState([])
  const { shopSlugBefore, cart } = useSelector((state) => state.order)

  const dataCreateShop = getCreateShopData()
  const cartOrderDetailsByRef = getCartOrderDetailsByRef()

  const { listProduct: listProductRedux } = useSelector((state) => state.product)
  const previousListProduct = usePrevious(listProductRedux) // Track previous listProduct
  const { shopDetail, viewDefault } = useSelector((state) => state.shop)
  const isOwnerShop = user?.shopLinks?.some((item) => item.includes(shopSlugParams))
  const isShopCreated =
    dataCreateShop?.shopSlug?.includes(window.location.pathname.slice(1)) &&
    !!dataCreateShop?.isShowGuide
  const [isOpenModal, setIsOpenModal] = useState(isShopCreated)
  const viewDefaultLocal = getViewDefault()
  // const shopColorTheme = shopDetail?.shopColorTheme
  // const colorTheme = shopColorTheme?.customsColorTheme || shopColorTheme?.defaultColorTheme

  const toggle = () => setIsOpenModal(!isOpenModal)

  const convertProducts = (_listProduct) =>
    _listProduct?.map((product, index) => {
      const convertedSubItems = product.subItems
        .filter((subItem) => subItem.required === SUBITEM_REQUIRED)
        .map((subItem) => ({
          type: subItem.type,
          name: subItem.list[0].name,
          price: subItem.list[0].price,
          required: subItem.required,
          list: subItem.list,
        }))

      const quantityInput = product.defaultQuantity

      return {
        ...product,
        subItems: convertedSubItems,
        quantityInput,
        index,
      }
    })

  useEffect(() => {
    const intervalId = setInterval(() => {
      const facebookShareButton = document.querySelector('button[aria-label="facebook"]')
      const whatsAppShareButton = document.querySelector('button[aria-label="whatsapp"]')

      if (facebookShareButton) {
        facebookShareButton.innerHTML = `
          <button type='button' class='facebook'>Share</button>
        `
        clearInterval(intervalId)
      }

      if (whatsAppShareButton) {
        whatsAppShareButton.innerHTML = `
          <button type='button' class='whatsapp'>Share</button>
        `
        clearInterval(intervalId)
      }
    }, 1000)

    return () => {
      const userShopSlugs = user?.shopLinks?.map((item) => {
        const arr = item.split('/')
        return arr[arr.length - 1]
      })
      const userMatchShop = userShopSlugs?.find((item) => item === shopSlugParams)

      if (!userMatchShop && user?.id) {
        dispatch(clearShopAndShopDetail())
      }

      clearInterval(intervalId)
    }
  }, [])

  useEffect(() => {
    const fetchShop = async () => {
      try {
        setIsLoading(true)
        await dispatch(getShopByShopSlug(shopSlugParams))
      } catch (e) {
        setNoShopFound(true)
      } finally {
        setIsLoading(false)
      }
    }

    if (isEmpty(shopDetail) && shopSlugParams) {
      fetchShop().then(() => {})
    }

    dispatch(getCart())
    scrollToTop()

    return () => {
      setOrderProducts([])
    }
  }, [shopSlugParams])

  useEffect(() => {
    if (isShopCreated) {
      const fetchUser = async () => {
        const { msgResp } = await userApi.getUser()
        setUserInfo(msgResp)
      }

      fetchUser()

      setIsOpenModal(true)
    }
  }, [isShopCreated])

  useEffect(() => {
    if (listProductRedux) {
      const filteredListProduct = listProductRedux.filter((item) => !item?.isDelete && item.active)

      setListProduct(filteredListProduct)
      setListProduct(convertProducts(filteredListProduct))
    }
  }, [JSON.stringify(listProductRedux)])

  useEffect(() => {
    // If a new product is added
    if (listProductRedux.length > (previousListProduct?.length || 0)) {
      scrollToTop()
    }
  }, [listProductRedux, previousListProduct])

  useEffect(() => {
    if (!isEmpty(shopDetail)) {
      const filterListProduct = shopDetail?.products?.filter(
        (item) => !item?.isDelete && item.active
      )

      if (!viewDefaultLocal) {
        dispatch(
          setViewDefaultForShop(shopDetail?.productView === VIEW_GRID ? VIEW_GRID : VIEW_LIST)
        )
      } else {
        dispatch(setViewDefaultForShop(viewDefaultLocal))
      }

      setListProduct(convertProducts(filterListProduct))

      if (cart?.orderList?.length && shopSlugParams.includes(shopSlugBefore)) {
        const newCart = [...cart?.orderList]

        setOrderProducts(newCart)
        setListProduct(newCart)
      }
    }
  }, [JSON.stringify(shopDetail), JSON.stringify(cart?.orderList), shopSlugParams])

  const findProduct = (pid, index) => listProduct.find((n) => n.id === pid && n.index === index)

  const changeProductQuantityInput = ({ pid, number, index, type }) => {
    const newOrderProducts = [...orderProducts]
    const newListProduct = [...listProduct]
    const selectedProduct = findProduct(pid, index)
    const inOrderProducts = Boolean(
      newOrderProducts.find((item) => item.id === pid && item.index === index)
    )

    const totalQuantityInOrder = orderProducts
      .filter((item) => item.id === pid)
      .reduce((total, item) => total + item.quantityInput, 0)

    let numeric = number

    if (number < 0) return
    if (isNaN(number)) numeric = 0

    if (selectedProduct?.minOrderQty > numeric) numeric = selectedProduct.minOrderQty

    const totalQuantityOrderOfSelectedProduct =
      totalQuantityInOrder + numeric - selectedProduct.quantityInput

    if (type === 'plus' || type === 'typing') {
      const totalQuantity = newListProduct.reduce(
        (acc, product) => (product.id === pid ? acc + product.quantityInput : acc),
        0
      )

      if (totalQuantity >= selectedProduct.availableOfStock) {
        addToast('Cannot order more than available of stock', {
          appearance: 'error',
          autoDismiss: true,
        })
        return
      }

      if (totalQuantityOrderOfSelectedProduct > selectedProduct.availableOfStock) {
        addToast('Cannot order more than available of stock', {
          appearance: 'error',
          autoDismiss: true,
        })
        return
      }

      if (selectedProduct && !inOrderProducts) {
        const updatedOrderProducts = newListProduct.map((item) => {
          if (item.id === pid && item.index === index) {
            if (number < item.minOrderQty) {
              numeric = item.minOrderQty
            }

            if (number >= item.limitPerOrder) {
              numeric = item.limitPerOrder
            }

            return {
              ...item,
              quantityInput: numeric,
            }
          }

          return item
        })
        setOrderProducts(updatedOrderProducts)
        setListProduct(updatedOrderProducts)
      }

      if (selectedProduct && inOrderProducts) {
        const updateQuantity = (item) => {
          const { minOrderQty } = item
          const { limitPerOrder } = item

          if (numeric !== 0 && numeric < minOrderQty) {
            item.quantityInput = minOrderQty
          } else if (numeric >= limitPerOrder) {
            addToast('Cannot order more than limit per order', {
              appearance: 'error',
              autoDismiss: true,
            })
            item.quantityInput = limitPerOrder
          } else {
            item.quantityInput = numeric
          }
        }

        const selectedOrderProduct = newOrderProducts.find(
          (item) => item.id === pid && item.index === index
        )

        selectedOrderProduct && updateQuantity(selectedOrderProduct)

        setOrderProducts(newOrderProducts)
        setListProduct(newOrderProducts)
      }
    } else if (type === 'minus') {
      if (selectedProduct && inOrderProducts) {
        const updateQuantity = (item) => {
          const { minOrderQty } = item

          if (numeric < minOrderQty || number < minOrderQty) {
            item.quantityInput = 0
          } else {
            item.quantityInput = numeric
          }
        }

        const selectedOrderProduct = newOrderProducts.find(
          (item) => item.id === pid && item.index === index
        )

        selectedOrderProduct && updateQuantity(selectedOrderProduct)

        setOrderProducts(newOrderProducts)
        setListProduct(newOrderProducts)
      }
    }
  }

  const getValueSelect = ({ pid, pIndex, subItemType }) => {
    const indexProduct = listProduct.findIndex((n) => n.id === pid && n.index === pIndex)
    const listSubItem = listProduct[indexProduct].subItems
    const indexSubItem = listSubItem?.findIndex((subItem) => subItem?.type === subItemType)
    if (indexSubItem !== undefined && indexSubItem !== -1) {
      return JSON.stringify({
        name: listSubItem[indexSubItem].name,
        price: listSubItem[indexSubItem].price,
      })
    }
    return ''
  }

  const handleChangeSubItem = ({ pid, subItem, value, pIndex }) => {
    const newListProduct = [...listProduct]
    const indexProduct = newListProduct.findIndex((n) => n.id === pid && n.index === pIndex)
    const listSubItem = newListProduct[indexProduct].subItems
    const indexSubItem = listSubItem.findIndex(
      (s, index) => s.type === subItem.type && index === subItem.index
    )

    const updatedSubItem = {
      ...listSubItem[indexSubItem],
      name: value.name,
      price: value.price,
    }

    const updatedProduct = {
      ...newListProduct[indexProduct],
      subItems: [...listSubItem],
    }

    updatedProduct.subItems[indexSubItem] = updatedSubItem
    newListProduct[indexProduct] = updatedProduct

    setListProduct(newListProduct)
    setOrderProducts(newListProduct)
  }

  const addAnotherOrder = (pid, pIndex) => {
    const newListProduct = [...listProduct]
    const selectedProduct = findProduct(pid, pIndex)

    const indexProduct = newListProduct.findIndex((n) => n.id === pid && n.index === pIndex)

    const totalQuantity = newListProduct.reduce(
      (acc, product) => (product.id === pid ? acc + product.quantityInput : acc),
      0
    )

    if (totalQuantity >= selectedProduct.availableOfStock) {
      addToast('Cannot order more than available of stock', {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }

    let newQuantityInput = selectedProduct.availableOfStock - totalQuantity

    if (newQuantityInput === 0 || selectedProduct.minOrderQty > newQuantityInput) {
      addToast('Cannot add another more than available of stock', {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }

    newQuantityInput = selectedProduct.minOrderQty

    newListProduct.splice(indexProduct + 1, 0, {
      ...selectedProduct,
      isCopy: true,
      quantityInput: newQuantityInput,
    })

    setOrderProducts(newListProduct.map((item, index) => ({ ...item, index })))
    setListProduct(newListProduct.map((item, index) => ({ ...item, index })))
  }

  const deleteItemOrder = (pid, pIndex) => {
    const newListProduct = [...listProduct]
    const newOrderProducts = [...orderProducts]
    const selectedProduct = findProduct(pid, pIndex)
    const inCart = Boolean(
      newOrderProducts.find((item) => item.id === pid && item.index === pIndex - 1)
    )

    if (selectedProduct && inCart) {
      const indexOrderProducts = newOrderProducts.findIndex(
        (item) => item.id === pid && item.index === pIndex
      )

      newOrderProducts.splice(indexOrderProducts, 1)
      setOrderProducts(newOrderProducts)
    }

    newListProduct.splice(pIndex, 1)

    setListProduct(newListProduct.map((item, index) => ({ ...item, index })))
  }

  const getProductWithMinOrderQty = useCallback(async () => {
    const productWithMinOrderQty = listProduct.filter((item) => item.minOrderQty)
    if (!orderProducts.length && productWithMinOrderQty) {
      return productWithMinOrderQty
    }

    return orderProducts
  }, [JSON.stringify(listProduct)])

  const handleCheckout = async () => {
    const newOrderProducts = await getProductWithMinOrderQty()

    const totalQuantity = newOrderProducts.reduce((pre, cur) => pre + cur.quantityInput, 0)

    if (cartOrderDetailsByRef?.ref) {
      removeCartOrderDetailsByRef()
    }

    if (!isEmpty(cart?.orderList) && shopSlugParams !== shopSlugBefore) {
      CustomSwalFire.delete({
        title: 'Payment',
        text: 'Please confirm payment before checkout',
        icon: 'warning',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        onConfirm: async () => {
          setListProduct([])
          setOrderProducts([])
          dispatch(clearShopAndShopDetail())
          dispatch(clearOrderDetailsByRef())
          history.push(`/${shopSlugBefore}`)
        },
        onError: (error) => {
          CustomSwalFire.error({
            title: 'Error!',
            text: error.message,
          })
        },
      })
      return
    }

    if (totalQuantity > 0) {
      setCartCheckout({
        orderList: newOrderProducts,
        listProductBefore: listProduct,
        email: user?.email,
        shopSlug: shopSlugParams,
      })
      dispatch(addToCart(newOrderProducts, shopDetail?.id, listProduct, shopSlugParams))
      history.push('/virtual-shop/checkout')
      return
    }

    return addToast('Please select quantity to checkout', {
      appearance: 'error',
      autoDismiss: true,
    })
  }

  const handleClearCart = async () => {
    CustomSwalFire.delete({
      title: 'Are you sure to clear cart?',
      icon: 'warning',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No',
      onConfirm: async () => {
        dispatch(clearCart())
        setOrderProducts([])
        setListProduct(
          listProduct.map((product) => ({
            ...product,
            quantityInput: 0,
          }))
        )
        CustomSwalFire.success({
          title: 'Cleared!',
          text: 'Your cart has been cleared.',
        })
      },
      onError: (error) => {
        CustomSwalFire.error({
          title: 'Error!',
          text: error.message,
        })
      },
    })
  }

  const handleNavigateToExternalLink = (link) => {
    if (link !== '') {
      window.open(link)
    } else {
      addToast('This Link is not exist', { appearance: 'error', autoDismiss: true })
    }
  }

  const onHandleBack = () => {
    if (cart?.orderList?.length) {
      CustomSwalFire.delete({
        title: 'Are you sure to back?',
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No',
        onConfirm: async () => {
          dispatch(clearCart())
          history.push('/virtual-shop/list')
        },
        onError: (error) => {
          CustomSwalFire.error({
            title: 'Error!',
            text: error.message,
          })
        },
      })
    } else {
      history.push('/virtual-shop/list')
    }
  }

  const renderClearCart = () => {
    if (cart?.orderList?.length && shopSlugParams === shopSlugBefore) {
      return (
        <CustomButton
          text='Clear cart'
          icon={<MdOutlineDelete />}
          onClick={handleClearCart}
          className='button-delete border-0 mb-3 w-auto ms-auto'
          variant='outlined'
          color='slate'
          style={{ height: 50 }}
        />
      )
    }
    return null
  }

  if (isLoading) {
    return <ShopDetailSkeleton />
  }

  if (!isLoading && !shopDetail?.active) {
    return <NotFound />
  }

  return (
    <TourProvider
      showCloseButton={false}
      showPrevNextButtons={false}
      showDots={false}
      showBadge={false}
      steps={steps}
      styles={{
        controls: (base) => ({ ...base, marginTop: 100, display: 'none' }),
        popover: (base) => ({
          ...base,
          borderRadius: '0.25rem',
          padding: '35px',
        }),
      }}
      onClickMask={(e) => e.setIsOpen(true)}
      disableInteraction
      onClickHighlighted={() => {
        if (!isEmpty(dataCreateShop)) {
          removeCreateShopData()
        }
        localStorage.setItem('isAdminConsole', true)
        history.push(`/admin-console/${shopDetail?.shopSlug}/setting/general`)
      }}
      highlightedMaskClassName='highlighted-area'
    >
      <div
        className={'shop-detail'}
        style={{
          backgroundColor:
            shopDetail?.shopColorTheme?.customsColorTheme?.backgroundColor || '#f5f5f5',
        }}
      >
        <div className='container'>
          <div className='bg-white shop-detail-body position-relative pt-3'>
            <div className='row'>
              <div className='col-12'>
                <div className='shop-detail bg-white'>
                  {noShopFound && <div className='text-center mb-3'>Something went wrong</div>}

                  {!isLoading && !noShopFound ? (
                    <>
                      {!user?.id && <AuthNavigationBar onHandleBack={onHandleBack} />}
                      <ShopBanner
                        shopDetail={shopDetail}
                        handleOnClick={handleNavigateToExternalLink}
                      />

                      {renderClearCart()}

                      <ProductList
                        listProduct={listProduct}
                        findProduct={findProduct}
                        handleChangeSubItem={handleChangeSubItem}
                        getValueSelect={getValueSelect}
                        changeProductQuantityInput={changeProductQuantityInput}
                        addAnotherOrder={addAnotherOrder}
                        deleteItemOrder={deleteItemOrder}
                        viewDefault={viewDefault}
                      />
                    </>
                  ) : null}

                  <CustomButton
                    text='Checkout'
                    icon={<IoBagCheckOutline />}
                    onClick={handleCheckout}
                    className='mx-auto btn-checkout mt-auto'
                    style={{ height: 50 }}
                  />

                  {isOwnerShop && !window.location.pathname.includes('my-shop') ? (
                    <Footer isOpenModal={isOpenModal} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalCongratulationCreateShop
        isOpenModal={isOpenModal}
        toggle={toggle}
        shopDetail={shopDetail}
        urlPath={location.pathname}
        dataCreateShop={dataCreateShop}
      />
    </TourProvider>
  )
}
export default ShopDetail

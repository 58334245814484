import Avatar from '_components/Avatar'
import NotFoundDataComponent from '_components/NotFoundDataComponent'
import Pagination from '_components/Pagination'
import logoImage from '_images/logo-co-hoot.jpg'
import { DATE_FORMAT, GMT_0 } from '_utils/constant'
import { convertTimestampToCurrentGMT } from '_utils/functions/converter'
import { getUserInfo } from '_utils/localData'
import parse from 'html-react-parser'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { GoInbox } from 'react-icons/go'
import { useSelector } from 'react-redux'
import NotificationSkeleton from './NotificationSkeleton'

const PageSize = 10

const Item = ({ notification, userInfo }) => {
  const { createdAt, title, message, senderId } = notification

  const isUserPhotoUrl = senderId === userInfo?.id

  // Kiểm tra và set avatar URL
  const avatarUrl = isUserPhotoUrl ? userInfo?.photoUrl : logoImage

  return (
    <div className='noti-list-item'>
      <div className='noti-avatar'>
        <Avatar photoUrl={avatarUrl} size='45px' />
      </div>
      <div className='noti-content'>
        <div className='notification-header'>
          {/* {getNotificationIcon(type)} */}
          <span className='notification-header--title'>{title}</span>
          <span className='noti-date'>
            {moment
              .unix(convertTimestampToCurrentGMT(createdAt))
              .utcOffset(GMT_0)
              .format(DATE_FORMAT)}
          </span>
        </div>
        <div className='text-message' style={{ whiteSpace: 'normal' }}>
          {parse(message)}
        </div>
      </div>
    </div>
  )
}

const NotificationList = ({ notifications }) => {
  const userInfo = getUserInfo()
  const [currentPage, setCurrentPage] = useState(1)

  const { loadingNotification } = useSelector((state) => state.user)

  const currentNotifications = useMemo(() => {
    if (notifications.length === 0) {
      return []
    }

    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return notifications.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, JSON.stringify(notifications)])

  if (loadingNotification) {
    return (
      <div className='noti-list'>
        {[1, 2, 3].map((i) => (
          <NotificationSkeleton key={i} />
        ))}
      </div>
    )
  }

  if (!currentNotifications.length) {
    return (
      <NotFoundDataComponent
        icon={<GoInbox />}
        title='No notification'
        subtitle='You have no notifications yet.'
      />
    )
  }

  return (
    <React.Fragment>
      {/* <div className='c-txt-14 text-center text-underline'>Notification</div> */}
      <div id='itemList' className='noti-list'>
        {currentNotifications.map((notification) => (
          <Item
            key={Math.random().toString(36).substring(2, 9)}
            notification={notification}
            userInfo={userInfo}
          />
        ))}
      </div>
      <Pagination
        className='pagination-bar mt-3'
        currentPage={currentPage}
        totalCount={notifications.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </React.Fragment>
  )
}

export default NotificationList

import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const UserSkeleton = () => (
  <div className='container'>
    <div className='profile-page mt-0 pt-0'>
      <div className='profile-avatar-bg'>
        <Skeleton height={120} />
      </div>
      <div className='c-form c-form--profile'>
        {/* Avatar skeleton */}
        <div style={{ width: 100, height: 100, margin: '0 auto' }}>
          <Skeleton circle height={100} width={100} />
        </div>

        {/* Name skeleton */}
        <div className='upload-name mt-2'>
          <Skeleton width={120} />
        </div>

        {/* Navigation skeleton */}
        <div className='nav-profile'>
          <div className='d-flex justify-content-center align-items-center gap-4'>
            {[1, 2, 3, 4].map((item) => (
              <div key={item} style={{ width: '22%', textAlign: 'center' }}>
                <Skeleton circle width={40} height={40} />
                <Skeleton width={60} style={{ marginTop: 8 }} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default UserSkeleton

import axiosClient from './axiosClient'

const invoiceApi = {
  getListInvoice: () => {
    const url = '/get-list-invoice-for-user'
    return axiosClient.get(url)
  },
  cancelSubscription: (subscriptionId) => {
    const url = `cancel-subscription-payment?subId=${subscriptionId}`
    return axiosClient.patch(url)
  },
  removeSubscription: (subscriptionId) => {
    const url = `remove-subscription-payment?subId=${subscriptionId}`
    return axiosClient.post(url)
  },
  cancelLazadaFeature: (lazadaId) => {
    const url = `cancel-lazada-feature?id=${lazadaId}`
    return axiosClient.patch(url)
  },
  removeLazadaFeature: (lazadaId) => {
    const url = `remove-lazada-feature?id=${lazadaId}`
    return axiosClient.delete(url)
  },
}

export default invoiceApi

import LongText from '_components/LongText'
import MySlider from '_components/MySlider'
import { SINGAPORE_CODE_NO_ADD } from '_utils/constant'
import classNames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import { BiLinkAlt } from 'react-icons/bi'
import { BsFacebook, BsInstagram, BsWhatsapp } from 'react-icons/bs'
import { Link } from 'react-router-dom'

const LinkIcons = ({ shop }) => {
  const disableWhatsApp = !shop?.whatsApp || shop?.whatsApp?.length === 0
  const disableFacebook = !shop?.linkFacebook || shop?.linkFacebook?.length === 0
  const disableInstagram = !shop?.linkInstagram || shop?.linkInstagram?.length === 0
  const disableColor = {
    opacity: 0.4,
    cursor: 'not-allowed',
  }

  return (
    <div className='shop-social-links'>
      <a
        href={`https://wa.me/${SINGAPORE_CODE_NO_ADD}${shop?.whatsApp}`}
        target='blank'
        className={`social-link whats-app ${disableWhatsApp ? 'disabled' : ''}`}
        style={disableWhatsApp ? disableColor : {}}
      >
        <BsWhatsapp />
      </a>

      <a
        href={shop?.linkInstagram}
        target='blank'
        className={`social-link instagram ${disableInstagram ? 'disabled' : ''}`}
        style={disableInstagram ? disableColor : {}}
      >
        <BsInstagram />
      </a>

      <a
        href={shop?.linkFacebook}
        target='blank'
        className={`social-link facebook ${disableFacebook ? 'disabled' : ''}`}
        style={disableFacebook ? disableColor : {}}
      >
        <BsFacebook />
      </a>

      <a href={shop?.shopLink} target='blank' className='social-link domain-link'>
        <BiLinkAlt />
      </a>
    </div>
  )
}

function ShopItem({ shop }) {
  const hoursCreated = moment().diff(moment(shop.createdAt * 1000), 'hours')
  const { shopSlug } = shop

  return (
    <div className={classNames('shop-item')}>
      <Link to={`/${shopSlug}`} className='me-0'>
        <div className='wrap-banner'>
          <MySlider listImage={shop.logo} />
        </div>
      </Link>

      <div className='shop-content'>
        <div className='shop-info'>
          <div className='shop-name'>
            <Link to={`/${shopSlug}`}>{shop.shopName}</Link>
            {hoursCreated < 24 && <span className='badge'>New</span>}
          </div>
          {shop.headline && (
            <div className='shop-description'>
              <LongText content={shop.headline} limit={170} />
            </div>
          )}
        </div>

        <div className='shop-footer'>
          <LinkIcons shop={shop} />
          <div className='author-created'>
            <div className='author'>
              <img
                src={
                  shop.createdUserPhotoUrl ||
                  process.env.REACT_APP_AVATAR_URL + shop?.createdUserName
                }
                alt='Author Avatar'
                className='author-avatar'
              />
              <small>{shop.createdUserName}</small>
            </div>
            <div className='created-date'>
              <small className='me-1'>Created:</small>
              <small>{moment(shop.createdAt * 1000).format('DD/MM/YYYY')}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ShopItem.propTypes = {
  shop: PropTypes.object.isRequired,
}

export default ShopItem

import { DELIVERY_OPTION } from '_utils/constant'
import React from 'react'
import CurrencyFormat from 'react-currency-format'

function RenderInfo({ fee, instructions, isCheckPublicHoliday, type }) {
  return (
    <div className='render-info render-info--container mb-3'>
      <p className='render-info__charge-title mb-0 fw-semibold'>
        {type === DELIVERY_OPTION ? 'Delivery' : 'Pick-up'} Charge:
      </p>
      <p className='render-info__text fw-semibold'>
        {fee ? (
          <CurrencyFormat
            value={fee || 0}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        ) : (
          'Free'
        )}
      </p>

      {instructions?.length > 0 && (
        <>
          <p className='render-info__instructions-title mb-0'>
            {type === DELIVERY_OPTION ? 'Delivery' : 'Pick-up'} Instructions:
          </p>
          <p className='render-info__instructions'>{instructions}</p>
        </>
      )}

      {isCheckPublicHoliday && (
        <p className='render-info__public-holiday'>
          No {type === DELIVERY_OPTION ? 'Delivery' : 'Pick-up'} during Public Holiday
        </p>
      )}
    </div>
  )
}

export default RenderInfo

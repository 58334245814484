import OverlayPrompt from '_components/OverlayPrompt'
import { ManageEventProvider } from '_contexts/EventContext'
import { useEvent } from '_hooks/useEvent'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { useSelector } from 'react-redux'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import EventList from './components/EventList'
import EventHeaderSkeleton from './components/Skeletons/EventHeaderSkeleton'
import CreateEvent from './Create'
import './style.scss'

const EventSkeleton = () => (
  <div className='event-list mt-3'>
    {[1, 2, 3].map((item) => (
      <div key={item} className='group-list__item mb-4'>
        <div className='d-flex w-100'>
          <div className='group-list__img'>
            <Skeleton height={60} width={60} />
          </div>
          <div className='group-list__info flex-grow-1'>
            <Skeleton height={20} width='60%' />
            <Skeleton height={15} width='40%' />
          </div>
          <div className='group-list__btn'>
            <Skeleton height={20} width={80} />
            <Skeleton height={25} width={60} style={{ marginTop: 8 }} />
          </div>
        </div>
        <div className='group-list__des'>
          <Skeleton height={200} />
          <Skeleton height={15} count={3} style={{ marginTop: 10 }} />
        </div>
      </div>
    ))}
  </div>
)

export default function Events() {
  const { path } = useRouteMatch()

  const { selectedShop } = useSelector((state) => state.shop)

  const {
    view,
    handleViewChange,
    currentEvents,
    currentPage,
    setCurrentPage,
    listEventByShopFiltered,
    openBroadcastModal,
    setOpenBroadcastModal,
    message,
    onChangeMessage,
    onCreateNotification,
    search,
    setSearch,
    toggleBroadcastModal,
    PageSize,
    currentPackagePro,
    onReopenEvent,
    loading,
    listEventByShop,
    onDirectCreateEvent,
    onDeleteEvent,
    listOrderEventHandled,
  } = useEvent()

  return (
    <ManageEventProvider
      value={{
        view,
        handleViewChange,
        currentEvents,
        currentPage,
        setCurrentPage,
        listEventByShopFiltered,
        openBroadcastModal,
        setOpenBroadcastModal,
        message,
        onChangeMessage,
        onCreateNotification,
        search,
        setSearch,
        toggleBroadcastModal,
        PageSize,
        currentPackagePro,
        onReopenEvent,
        loading,
        listEventByShop,
        onDirectCreateEvent,
        onDeleteEvent,
        listOrderEventHandled,
      }}
    >
      <div className='events-page f-16 h-100 position-relative'>
        {loading ? (
          <EventHeaderSkeleton />
        ) : (
          <>
            <p className='events-page__title'>Events</p>

            {currentPackagePro && (
              <div className='events-page__alert alert alert-warning' role='alert'>
                <p className='events-page__alert-text mb-0'>
                  <b>Note:</b> Features below will not apply for event
                </p>
                <ul className='events-page__alert-list ps-3 mb-0'>
                  <li className='events-page__alert-item'>Gift recipient</li>
                  <li className='events-page__alert-item'>Privacy mode</li>
                  <li className='events-page__alert-item'>Cash on Delivery/Pickup</li>
                  <li className='events-page__alert-item'>Add transaction fees</li>
                </ul>
              </div>
            )}

            <div
              className={`events-page__create-wrapper d-flex align-items-center ${
                currentPackagePro ? '' : 'opacity-50 pointer-events-none'
              }`}
            >
              <button
                type='button'
                onClick={onDirectCreateEvent}
                className='events-page__create-button bg-transparent border-0 text-decoration-underline text-orange p-0'
              >
                Create New Events
              </button>
              <span className='events-page__create-note text-warning ms-2'>
                * You need to create a group before creating an event
              </span>
            </div>
          </>
        )}

        {!currentPackagePro && <OverlayPrompt shopSlug={selectedShop?.shopSlug} />}

        {loading && currentPackagePro && <EventSkeleton />}

        {!loading && <EventList />}

        <Switch>
          <Route exact path={`${path}/create`}>
            <CreateEvent />
          </Route>
        </Switch>
      </div>
    </ManageEventProvider>
  )
}

/* eslint-disable func-names */
import { yupResolver } from '@hookform/resolvers/yup'
import CustomSwalFire from '_components/CustomSwalFire'
import {
  createDeliveryZones,
  deleteDeliveryZone,
  getListDeliveryAndDeliveryBooking,
  getRememberedDeliveryZones,
  updateDeliveryZones,
  updateRememberedDeliveryZones,
} from '_redux/modules/deliveryEvent'
import {
  DEFAULT_SELECTED_DELIVERY_ZONES,
  DELIVERY_BUYER_CANCELLED,
  DELIVERY_BUYER_PAID,
  DELIVERY_BUYER_REFUND,
  DELIVERY_BUYER_UNPAID,
  DELIVERY_CLOSED,
  DELIVERY_HOST_CANCELLED,
  DELIVERY_HOST_PAID,
  DELIVERY_HOST_UNPAID,
  DELIVERY_OPENED,
} from '_utils/constant'
import { exportToCsv, normalizeName } from '_utils/function'
import {
  convertTimestampToCurrentGMT,
  convertTimeStringWithDuration,
  convertToGMT0,
} from '_utils/functions/converter'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import * as yup from 'yup'

const schema = yup.object().shape({
  startDate: yup
    .date()
    .transform((value, originalValue) => {
      const parsedDate = new Date(originalValue)
      return isNaN(parsedDate) ? null : parsedDate
    })
    .nullable()
    .required('Delivery date is required')
    .min(
      new Date(new Date().setHours(0, 0, 0, 0)),
      'Delivery date must be in the present or future'
    ),

  time: yup.object().shape({
    start: yup
      .number()
      .required('Start time is required')
      .min(0, 'Start time must be between 0-24')
      .max(24, 'Start time must be between 0-24'),
    end: yup
      .number()
      .required('End time is required')
      .min(0, 'End time must be between 0-24')
      .max(24, 'End time must be between 0-24')
      .test('is-greater', 'End time must be after start time', function (value) {
        return value > this.parent.start
      }),
  }),

  selectedDeliveryZones: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .required('Zone name is required')
          .max(50, 'Zone name must be at most 50 characters'),
        price: yup
          .string()
          .required('Price is required')
          .test('is-valid-price', 'Invalid price format', (value) => {
            if (!value) return false
            // Remove '$' prefix and commas
            const normalizedValue = value.replace(/[$,]/g, '')
            // Check if it's a valid number with max 2 decimal places
            return /^\d+(\.\d{0,2})?$/.test(normalizedValue)
          })
          .test('is-positive', 'Price must be 0 or greater', (value) => {
            if (!value) return false
            const normalizedValue = value.replace(/[$,]/g, '')
            return parseFloat(normalizedValue) >= 0
          })
          .transform((value) => {
            if (!value) return value
            // Convert to standard decimal format for saving
            return value.replace(/[$,]/g, '')
          }),
      })
    )
    .min(1, 'At least one delivery zone is required'),
})

export const useDeliveryZonesLogic = (isOpen, dataModal, setDataModal, toggle) => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const history = useHistory()
  const { selectedShop } = useSelector((state) => state.shop)
  const { currentDeliveryAndDeliveryBooking, rememberedDeliveryZones } = useSelector(
    (state) => state.deliveryEvent
  )
  const { shopEventDeliveryBookings = [] } = currentDeliveryAndDeliveryBooking

  const [isOpenOrder, setIsOpenOrder] = useState(false)
  const [listOrderDeliveryEvent, setListOrderDeliveryEvent] = useState([])
  const [deliveryTime, setDeliveryTime] = useState(null)

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    reset,
    register,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      startDate: moment().toDate(),
      time: { start: 8, end: 17 }, // follow format 24h
      selectedDeliveryZones: DEFAULT_SELECTED_DELIVERY_ZONES,
    },
    resolver: yupResolver(schema),
  })

  const selectedDeliveryZones = watch('selectedDeliveryZones')
  const time = watch('time')

  useEffect(() => {
    if (!isOpen) {
      reset({
        startDate: moment().toDate(),
        time: { start: 8, end: 17 },
        selectedDeliveryZones: DEFAULT_SELECTED_DELIVERY_ZONES,
      })
      if (typeof setDataModal === 'function') setDataModal(null)
    } else {
      dispatch(getRememberedDeliveryZones(selectedShop.id))
    }
  }, [isOpen, setDataModal, selectedShop.id, reset, dispatch])

  useEffect(() => {
    if (isOpen && rememberedDeliveryZones && rememberedDeliveryZones.length > 0) {
      setValue('selectedDeliveryZones', rememberedDeliveryZones)
    }
  }, [isOpen, rememberedDeliveryZones, setValue])

  useEffect(() => {
    if (dataModal) {
      const deliveryTimeObject = moment(dataModal.deliveryTime, 'DD-MM-YYYY HH:mm').toDate()
      const timestampDelivery = moment(deliveryTimeObject).unix()
      const currentTimestampDelivery = convertTimestampToCurrentGMT(timestampDelivery)
      const timeStart = moment.unix(currentTimestampDelivery).hour()
      const timeEnd = timeStart + dataModal.deliveryDuration / 3600

      setValue('startDate', deliveryTimeObject)
      setValue('time', { start: timeStart, end: timeEnd })

      if (dataModal.deliveryStatus) {
        setIsOpenOrder(true)
      } else {
        setIsOpenOrder(false)
      }

      setDeliveryTime(dataModal.deliveryTime)

      dispatch(getListDeliveryAndDeliveryBooking(dataModal.id))
    }
  }, [dataModal])

  useEffect(() => {
    if (shopEventDeliveryBookings) {
      setListOrderDeliveryEvent(shopEventDeliveryBookings)
    }
  }, [JSON.stringify(shopEventDeliveryBookings)])

  const onChangeDate = (date) => {
    setValue('startDate', date)
  }

  const onChangeTime = (type, value) => {
    setValue('time', {
      ...time,
      [type]: parseInt(value, 10),
    })
  }

  const onSelectDeliveryZone = (selectedDelivery, isChecked) => {
    let updatedZones = []

    if (isChecked) {
      // Remove default empty zone if it exists
      if (selectedDeliveryZones.length === 1 && selectedDeliveryZones[0].name === '') {
        updatedZones = []
      } else {
        updatedZones = [...selectedDeliveryZones]
      }

      // Add new zone with price converted to float
      updatedZones.push({
        name: selectedDelivery.optionName,
        price: parseFloat(selectedDelivery.deliveryFee), // Convert to float and ensure 2 decimal places
      })
    } else {
      // Remove zone
      updatedZones = selectedDeliveryZones.filter(
        (zone) => zone.name !== selectedDelivery.optionName
      )
    }

    setValue('selectedDeliveryZones', updatedZones)
  }

  const onSubmit = async (data) => {
    try {
      const deliveryDurationData = (data.time.end - data.time.start) * 3600

      if (deliveryDurationData < 0) {
        return addToast('Invalid delivery time duration', {
          appearance: 'error',
          autoDismiss: true,
        })
      }

      const deliveryTimeLocal = `${moment(data.startDate).format('DD-MM-YYYY')} ${
        data.time.start
      }:00`
      const deliveryTimeGMT0 = convertToGMT0(deliveryTimeLocal)

      const postData = {
        deliveryDuration: dataModal ? dataModal.deliveryDuration : deliveryDurationData,
        deliveryTime: deliveryTimeGMT0,
        deliveryZones: data.selectedDeliveryZones.map((z) => ({
          ...z,
          price: parseFloat(z.price),
        })),
        shopId: selectedShop.id,
      }

      if (!dataModal) {
        await dispatch(createDeliveryZones(postData))
      } else {
        await dispatch(updateDeliveryZones(postData, dataModal.id))
      }

      await dispatch(
        updateRememberedDeliveryZones({
          rememberedDeliveryZones: data.selectedDeliveryZones.map((z) => ({
            ...z,
            price: parseFloat(z.price),
          })),
          shopId: selectedShop.id,
        })
      )

      const message = dataModal
        ? 'Delivery zones updated successfully'
        : 'Delivery zones created successfully'

      addToast(message, { appearance: 'success', autoDismiss: true })

      if (typeof setDataModal === 'function') {
        setDataModal(null)
      }
      toggle()

      history.push(`/admin-console/${selectedShop?.shopSlug}/manage-delivery-zones`)
    } catch (error) {
      addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
      reset({}, { keepValues: true, keepIsSubmitted: false, keepIsSubmitting: false })
    }
  }

  const onDeleteDeliveryZone = async () => {
    CustomSwalFire.delete({
      title: 'Are you sure?',
      text: 'You will not be able to recover this delivery zone!',
      icon: 'warning',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
      onConfirm: async () => {
        try {
          await dispatch(deleteDeliveryZone(dataModal.id))
          addToast('Delivery zone deleted successfully', {
            appearance: 'success',
            autoDismiss: true,
          })
          setDataModal(null)
        } catch (error) {
          addToast(error.message, { appearance: 'error', autoDismiss: true })
        } finally {
          toggle()
        }
      },
      onError: (error) => {
        addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
      },
    })
  }

  const onUpdateStatusDelivery = async () => {
    try {
      const r = confirm('Are you sure?')
      if (r === true) {
        const data = { deliveryStatus: isOpenOrder ? DELIVERY_CLOSED : DELIVERY_OPENED }
        await dispatch(updateDeliveryZones(data, dataModal.id))
        setIsOpenOrder(!isOpenOrder)

        const message = isOpenOrder
          ? 'Delivery zone closed successfully'
          : 'Delivery zone opened successfully'

        addToast(message, { appearance: 'success', autoDismiss: true })
      }
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const STATUS_MAP = {
    [DELIVERY_HOST_CANCELLED]: 'Cancelled',
    [DELIVERY_HOST_UNPAID]: 'Not paid',
    [DELIVERY_HOST_PAID]: 'Paid',
  }

  const BOOKER_STATUS_MAP = {
    [DELIVERY_BUYER_CANCELLED]: 'Cancelled',
    [DELIVERY_BUYER_REFUND]: 'Refund',
    [DELIVERY_BUYER_UNPAID]: 'Not paid',
    [DELIVERY_BUYER_PAID]: 'Paid',
  }

  const HEADERS = [
    'Zone name',
    'Zone price',
    'Address',
    'Buyer name',
    'Buyer phone',
    'Buyer email',
    'Status',
    'Booker Status',
    'Comment',
  ]

  const getFileName = (_deliveryTime, _deliveryDuration) =>
    normalizeName(
      `Delivery on ${convertTimeStringWithDuration(_deliveryTime, _deliveryDuration)}`
    ).replace(/-+/g, '-')

  const onExportOrder = () => {
    if (!listOrderDeliveryEvent || listOrderDeliveryEvent.length === 0) {
      addToast('No data to export', { appearance: 'warning', autoDismiss: true })
      return
    }

    const rowsOfData = listOrderDeliveryEvent.map(
      ({ dzName, dzPrice, address, uName, uPhone, uEmail, status, comment, bookerStatus }) => [
        dzName,
        `$${dzPrice}`,
        address,
        uName,
        uPhone,
        uEmail,
        STATUS_MAP[status] || '',
        BOOKER_STATUS_MAP[bookerStatus] || '',
        comment,
      ]
    )

    const rows = [HEADERS, ...rowsOfData]
    const fileName = getFileName(deliveryTime, dataModal.deliveryDuration)

    exportToCsv(`${fileName}.csv`, rows)
  }

  return {
    control,
    setValue,
    watch,
    handleSubmit,
    reset,
    register,
    getValues,
    errors,
    isSubmitting,
    selectedDeliveryZones,
    time,
    onChangeDate,
    onChangeTime,
    onSelectDeliveryZone,
    onSubmit,
    onDeleteDeliveryZone,
    onUpdateStatusDelivery,
    onExportOrder,
    isOpenOrder,
  }
}

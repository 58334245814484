import classNames from 'classnames'
import { isObject } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { BiTimeFive } from 'react-icons/bi'
import { IoIosArrowDown } from 'react-icons/io'
import './style.scss'

const CustomSelectBox = ({
  options,
  value,
  onChange,
  placeholder = 'Select an option',
  isDisabled,
  className,
  isTimer = false,
  isFulfillment = false,
  isShopSelect = false,
  isStatusSelect = false,
  isTimeZone = false,
  isCurrency = false,
  isTimerOrder = false,
  style,
  renderOptions,
  isOptionDisabled,
  isReversedDropdown = false, // Add the new prop with a default value
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const selectRef = useRef(null)

  const disabledClass = isDisabled ? 'disabled' : undefined

  const handleOptionClick = (option) => {
    if (isOptionDisabled && isOptionDisabled(option)) {
      return
    }
    setSelectedOption(option)
    setIsOpen(false)
    onChange && onChange(option)
  }

  const renderIcon = () => {
    if (isTimer) {
      return <BiTimeFive size={16} />
    }
    return <IoIosArrowDown size={16} className={isOpen ? 'open' : ''} />
  }

  const formatSelectedOptionLabel = (label) => {
    if (isTimerOrder) {
      return label.split(',')[1]
    }

    return label
  }

  // Update selectedOption when value or options change
  useEffect(() => {
    let matchingOption = null

    if (value !== undefined && value !== null) {
      const numValue = Number(value)

      try {
        if (isTimer) {
          const parsedValue = JSON.parse(value)

          matchingOption = options.find((option) => option.value === parsedValue.value)
        } else if (isFulfillment) {
          const parsedValue = JSON.parse(value)
          matchingOption = options.find((option) => {
            const parsedOption = JSON.parse(option.value)
            return parsedOption.id === parsedValue.id
          })
        } else if (isShopSelect) {
          matchingOption = options.find((option) => option.value === value.id)
        } else if (isStatusSelect) {
          if (isObject(value)) {
            matchingOption = options.find((option) => option.value === value.value)
          } else {
            matchingOption = options.find((option) => Number(option.value) === numValue)
          }
        } else if (isTimeZone) {
          matchingOption = options.find((option) => option.value === numValue)
        } else if (isCurrency) {
          matchingOption = options.find((option) => {
            const optionValue = String(option.value).trim()
            const currentValue = String(value).trim()
            return optionValue === currentValue
          })
        } else {
          matchingOption = options.find((option) => option.value === value)
        }
      } catch (e) {
        // If parsing fails, try direct comparison
        matchingOption = options.find((option) => Number(option.value) === numValue)
      }
    }

    setSelectedOption(matchingOption || null)
  }, [value, JSON.stringify(options)])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  return (
    <div
      className={classNames('custom-select', className, disabledClass)}
      ref={selectRef}
      style={style}
    >
      <div className={`select-header ${isOpen ? 'open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
        <span className='selected-value'>
          {selectedOption ? formatSelectedOptionLabel(selectedOption.label) : placeholder}
        </span>
        <span className={classNames('arrow', { 'no-rotate': isTimer, open: isOpen })}>
          {renderIcon()}
        </span>
      </div>
      {isOpen && (
        <div className={`options-container ${isReversedDropdown ? 'reversed' : ''}`}>
          {renderOptions
            ? renderOptions({
                options,
                selectedOption,
                onOptionClick: handleOptionClick,
              })
            : options.map((option) => (
                <div
                  key={option.value}
                  className={`option ${
                    selectedOption && selectedOption.value === option.value ? 'selected' : ''
                  }`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.label}
                </div>
              ))}
        </div>
      )}
    </div>
  )
}

export default CustomSelectBox

import Dashboard from '../Dashboard'
import Delivery from '../Delivery'
import Events from '../Events'
import EventCreatedConfirm from '../Events/Confirm'
import CreateEvent from '../Events/Create'
import EventCreateItem from '../Events/CreateItem'
import ManageDeliveryZones from '../ManageDeliveryZones'
import ManageProducts from '../ManageProducts'
import OrderRecord from '../OrderRecord'
import ReverseOrderDetail from '../OrderRecord/Lazada/ReverseOrderDetail'
import Setting from '../Setting'
import ShopFront from '../ShopFront'
import Subscription from '../Subscription'

export const generateRoutes = (path, shopSlug) => [
  {
    path: `${path}/${shopSlug}/dashboard`,
    exact: true,
    component: Dashboard,
    key: 'dashboard',
  },
  {
    path: `${path}/${shopSlug}/products`,
    exact: true,
    component: ManageProducts,
    key: 'products',
  },
  {
    path: `${path}/${shopSlug}/order-records`,
    exact: true,
    component: OrderRecord,
    key: 'order-records',
  },
  {
    path: `${path}/${shopSlug}/delivery`,
    exact: true,
    component: Delivery,
    key: 'delivery',
  },
  {
    path: `${path}/${shopSlug}/setting/general`,
    exact: true,
    component: (props) => <Setting {...props} activeTab="general" />,
    key: 'setting-general',
  },
  {
    path: `${path}/${shopSlug}/setting/fulfillment`,
    exact: true,
    component: (props) => <Setting {...props} activeTab="fulfillment" />,
    key: 'setting-fulfillment',
  },
  {
    path: `${path}/${shopSlug}/setting/advance`,
    exact: true,
    component: (props) => <Setting {...props} activeTab="advance" />,
    key: 'setting-advance',
  },
  {
    path: `${path}/${shopSlug}/setting/payment`,
    exact: true,
    component: (props) => <Setting {...props} activeTab="payment" />,
    key: 'setting-payment',
  },
  {
    path: `${path}/setting/account`,
    component: (props) => <Setting {...props} activeTab="account" />,
    exact: true,
    key: 'setting-account',
  },
  {
    path: `${path}/setting/account/my-shops`,
    exact: true,
    component: Setting,
    key: 'setting-account-my-shops',
  },
  {
    path: `${path}/setting/account/change-password`,
    exact: true,
    component: Setting,
    key: 'setting-account-change-password',
  },
  {
    path: `${path}/setting/account/update`,
    exact: true,
    component: Setting,
    key: 'setting-account-update',
  },
  {
    path: `${path}/setting/account/profile`,
    exact: true,
    component: Setting,
    key: 'setting-account-profile',
  },
  {
    path: `${path}/${shopSlug}/subscription`,
    exact: true,
    component: Subscription,
    key: 'subscription',
  },
  {
    path: `${path}/${shopSlug}/shop-front`,
    exact: true,
    component: ShopFront,
    key: 'shop-front',
  },
  {
    path: `${path}/${shopSlug}/order-records/:orderId`,
    exact: true,
    component: ReverseOrderDetail,
    key: 'order-records-orderId',
  },
  {
    path: `${path}/${shopSlug}/event`,
    exact: true,
    component: Events,
    key: 'event',
  },
  {
    path: `${path}/${shopSlug}/event/update`,
    exact: true,
    component: CreateEvent,
    key: 'event-update',
  },
  {
    path: `${path}/${shopSlug}/event/create`,
    exact: true,
    component: CreateEvent,
    key: 'event-create',
  },
  {
    path: `${path}/${shopSlug}/event/create-items`,
    exact: true,
    component: EventCreateItem,
    key: 'event-create-items',
  },
  {
    path: `${path}/${shopSlug}/event/update-items`,
    exact: true,
    component: EventCreateItem,
    key: 'event-update-items',
  },
  {
    path: `${path}/${shopSlug}/event/created-confirm`,
    exact: true,
    component: EventCreatedConfirm,
    key: 'event-created-confirm',
  },
  {
    path: `${path}/${shopSlug}/event/update-confirm`,
    exact: true,
    component: EventCreatedConfirm,
    key: 'event-update-confirm',
  },
  {
    path: `${path}/${shopSlug}/manage-delivery-zones`,
    exact: true,
    component: ManageDeliveryZones,
    key: 'manage-delivery-zones',
  },
]

import classNames from 'classnames'
import { useEffect } from 'react'
import CurrencyFormat from 'react-currency-format'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form' // Add Controller import
import { FaTrashAlt } from 'react-icons/fa'
import { IoMdAdd } from 'react-icons/io'
import { MdOutlineAttachMoney } from 'react-icons/md'
import { useSelector } from 'react-redux'
import CustomButton from '../CustomButton'
import InputField from '../InputField'

const styleDisabled = {
  pointerEvents: 'none',
  opacity: 0.5,
}

const DeliveryZoneRow = ({ index, zone, onDelete, register, errors, isDisabled, onAddZone }) => {
  const { control } = useFormContext() // Get control instead of setValue

  return (
    <div className='delivery-zone-row' style={isDisabled ? styleDisabled : {}}>
      <div className='delivery-zone-row__content row g-3 align-items-start mb-3'>
        <div className='delivery-zone-row__name col-7'>
          <div className='delivery-zone-row__name-input d-flex align-items-start'>
            <span className='delivery-zone-row__number me-2 mt-2'>{`${index + 1}.`}</span>
            <InputField
              register={register(`selectedDeliveryZones.${index}.name`)}
              defaultValue={zone.name}
              error={errors?.selectedDeliveryZones?.[index]?.name?.message}
              className={classNames(
                'delivery-zone-row__input',
                errors?.selectedDeliveryZones?.[index]?.name ? 'is-invalid' : ''
              )}
              placeholder={'Zone name'}
              width='100%'
              type='text'
            />
          </div>
        </div>
        <div className='delivery-zone-row__price col-5'>
          <div className='delivery-zone-row__price-input d-flex align-items-start'>
            <div className='flex-grow-1'>
              <div className='delivery-zone-row__wrap-input-icon'>
                <MdOutlineAttachMoney className='icon-money' />
                <Controller
                  name={`selectedDeliveryZones.${index}.price`}
                  control={control}
                  defaultValue={zone.price}
                  render={({ field: { onChange, value } }) => (
                    <CurrencyFormat
                      value={value}
                      displayType={'input'}
                      thousandSeparator={true}
                      className={classNames('form-control custom-input ps-3', {
                        'is-invalid': errors?.selectedDeliveryZones?.[index]?.price,
                      })}
                      decimalScale={2}
                      placeholder='Price'
                      onValueChange={(values) => {
                        onChange(values.value)
                      }}
                      allowNegative={false}
                    />
                  )}
                />
              </div>
              {errors?.selectedDeliveryZones?.[index]?.price?.message && (
                <div className='invalid-feedback d-block'>
                  {errors?.selectedDeliveryZones?.[index]?.price?.message}
                </div>
              )}
            </div>

            <CustomButton
              variant='outlined'
              color='slate'
              icon={<IoMdAdd />}
              className='delivery-zone-row__add-button mt-0 ms-2 w-auto border-0 px-2'
              onClick={onAddZone}
            />

            <CustomButton
              variant='outlined'
              color='slate'
              icon={<FaTrashAlt />}
              onClick={() => onDelete(zone.id)}
              className='delivery-zone-row__delete-button mt-0 w-auto border-0 px-2'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const DeliveryFee = ({ name }) => {
  const { loadingGetRememberedDeliveryZones } = useSelector((state) => state.deliveryEvent)
  const { control, reset, register, errors } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'selectedDeliveryZones',
  })

  useEffect(() => {
    if (fields.length === 0) {
      append({ name: '', price: 0 })
    }
  }, [fields, append])

  const onAddZone = () => {
    append({ name: '', price: 0 })
  }

  const onDeleteZone = (id) => {
    if (fields.length === 1) {
      reset({ [name]: [{ name: '', price: 0 }] })
    } else {
      const index = fields.findIndex((zone) => zone.id === id)
      remove(index)
    }
  }

  return (
    <div className='delivery-fee-section'>
      <label className='form-label mb-2'>Edit delivery zones:</label>
      <div className='delivery-fee-wrapper'>
        {fields.length > 0 && (
          <div className='delivery-zones-list'>
            {fields.map((zone, index) => (
              <DeliveryZoneRow
                key={zone.id}
                index={index}
                zone={zone}
                onDelete={onDeleteZone}
                register={register}
                errors={errors}
                isDisabled={loadingGetRememberedDeliveryZones}
                onAddZone={onAddZone}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default DeliveryFee

import classNames from 'classnames'
import React, { forwardRef } from 'react'
import './style.scss'

const CustomButton = forwardRef(
  (
    {
      isHovered = false,
      isDisabled = false,
      colorTheme = null,
      onClick = () => {},
      text = '',
      icon = null,
      className = '',
      color = 'orange',
      style = null,
      type = 'button',
      variant = 'outlined',
      isActive = false,
    },
    ref
  ) => {
    const styleThemeButton = {
      backgroundColor: isHovered ? colorTheme?.primary : 'transparent',
      borderColor: colorTheme?.primary,
      color: isHovered ? '#121212' : colorTheme?.primary,
    }

    const classNameIsActive = isActive ? 'active' : ''

    return (
      <button
        ref={ref}
        type={type}
        className={classNames('custom-button', className, color, variant, classNameIsActive)}
        onClick={onClick}
        disabled={isDisabled}
        style={{
          ...(style || {}),
          ...(colorTheme ? styleThemeButton : {}),
        }}
      >
        {icon &&
          React.cloneElement(icon, { className: `custom-button-icon ${text ? 'me-1' : ''}` })}
        {text && text}
      </button>
    )
  }
)

CustomButton.displayName = 'CustomButton'

export default CustomButton

import CustomButton from '_components/CustomButton'
import CustomSwalFire from '_components/CustomSwalFire'
import MyGallery from '_components/MyGallery'
import { deleteMyShop } from '_redux/modules/shop'
import { removeSelectedShopLocalStorage } from '_utils/localData'
import React from 'react'
import { FaTrash } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

function MyShopItem({ item, loading }) {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const { selectedShop, myShops } = useSelector((state) => state.shop)

  const onDelete = async (shop) => {
    if (myShops.length === 1) {
      return addToast('You must have at least one shop', { appearance: 'error', autoDismiss: true })
    }

    CustomSwalFire.delete({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      confirmButtonText: 'Yes, delete it!',
      onConfirm: async () => {
        try {
          await dispatch(deleteMyShop(shop.id))

          if (selectedShop?.id === shop.id) {
            removeSelectedShopLocalStorage()
          }

          addToast('Shop deleted successfully', { appearance: 'success', autoDismiss: true })
        } catch (error) {
          addToast(error.message, { appearance: 'error', autoDismiss: true })
        }
      },
      onError: (error) => {
        CustomSwalFire.error({
          title: 'Error!',
          text: error.message,
        })
      },
    })
  }

  return (
    <div className='shop-card'>
      <div className='shop-card__banner'>
        <Link to={`/${item.shopSlug}`}>
          {item.logo.length > 0 ? (
            <MyGallery image={item.logo[0]} />
          ) : (
            <div className='shop-card__banner__placeholder'>
              <span>{item.shopName[0]}</span>
            </div>
          )}
        </Link>
      </div>

      <div className='shop-card__content'>
        <div className='shop-card__header'>
          <Link to={`/${item.shopSlug}`}>
            <h3 className='shop-card__title'>{item.shopName}</h3>
          </Link>
          <CustomButton
            icon={<FaTrash />}
            onClick={() => onDelete(item)}
            disabled={loading}
            color='danger'
            variant='text'
            className='shop-card__delete button-delete'
            style={{
              width: '32px',
              height: '32px',
              marginTop: 0,
            }}
          />
        </div>

        <div className='shop-card__description'>{item.headline}</div>
      </div>
    </div>
  )
}

export default MyShopItem

import { yupResolver } from '@hookform/resolvers/yup'
import authApi from '_api/auth'
import reportApi from '_api/report'
import userApi from '_api/user'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import { Form } from 'reactstrap'
import * as Yup from 'yup'

import InputField from '_components/InputField'
import { FACEBOOK, GOOGLE } from '_utils/constant'
import {
  getCartCheckout,
  getCheckPaymentSubscription,
  getCreateShopData,
  getSocialToken,
  getUserInfo,
  removeSocialToken,
  setUserInfo,
  setUserToken,
} from '_utils/localData'

const validationSchema = Yup.object().shape({
  phone: Yup.string('Invalid phone number')
    .typeError('Invalid phone number')
    .required('Invalid phone number')
    .nullable(),
  // postalCode: Yup.string().max(6).nullable().notRequired(),
})

const FormConnect = () => {
  const history = useHistory()
  const [userInfoState, setUserInfoState] = useState(getUserInfo)
  const { addToast } = useToasts()

  const fetchUser = async () => {
    try {
      const { msgResp } = await userApi.getUser()
      setUserInfoState(msgResp)
      setUserInfo(msgResp)
    } catch (error) {
      addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = async (data) => {
    try {
      const postData = {
        accessToken: getSocialToken(),
        email: userInfoState.email,
        name: userInfoState.name,
        phone: data.phone,
        photoUrl: userInfoState.photoUrl,
      }
      const socialType = userInfoState.social
      if (socialType === GOOGLE) {
        postData.linkedGoogleAccount = userInfoState.id
      } else if (socialType === FACEBOOK) {
        postData.linkedFBAccount = userInfoState.id
      }
      const res = await authApi.register(postData)
      setUserToken(res.msgResp.token)
      removeSocialToken()

      if (getCartCheckout()) {
        await fetchUser()
        history.push('/virtual-shop/checkout')
      } else if (getCheckPaymentSubscription()) {
        userApi
          .getUser()
          .then(({ msgResp }) => {
            setUserInfo(msgResp)
            const linkPaymentSubscription = getCheckPaymentSubscription()
            history.push(linkPaymentSubscription)
          })
          .catch(({ msgResp, msgCode, message }) => {
            if (msgCode) {
              reportApi.report({ message: `[SignUp]${msgCode} - ${msgResp}` })
            } else {
              reportApi.report({ message: `[SignUp - client]${message}` })
            }
          })
      } else if (getCreateShopData()) {
        await fetchUser()

        history.push('/virtual-shop/confirm-shop')
      } else {
        await fetchUser()
        history.push('/virtual-shop/list')
      }
    } catch ({ msgResp }) {
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  useEffect(() => {
    setUserInfoState(getUserInfo)
  }, [localStorage])

  return (
    <div className='main-content pb-3'>
      <Form className='container c-form--connect' onSubmit={handleSubmit(onSubmit)}>
        <h2 className='c-ttl c-ttl--01'>Create User Profile</h2>
        <div className='c-form c-form--email'>
          <div className='upload-image'>
            <img
              id='userAvatarImg'
              className='card-img-top'
              style={{ width: 107, height: 107, borderRadius: '50%' }}
              src={userInfoState?.imageUrl}
              alt=''
            />
          </div>
          <div className='form-group'>
            <InputField
              id='phone'
              placeholder='Mobile Number'
              name='phone'
              register={register('phone')}
              type='text'
            />
            {errors?.phone && <small className='errorMessage'>{errors.phone.message}</small>}
          </div>
          {/* <div className='form-group'>
            <InputField
              className='form-control'
              id='postalCode'
              placeholder='Postal Code'
              name='postalCode'
              type='text'
              register={register('postalCode')}
            />
            {errors?.postalCode && (
              <small className='errorMessage'>{errors.postalCode.message}</small>
            )}
          </div> */}
          <div className='form-group'>
            <button className='btn btn--main' type='submit'>
              NEXT
            </button>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default FormConnect

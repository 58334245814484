import CustomButton from '_components/CustomButton'
import NotFoundDataComponent from '_components/NotFoundDataComponent'
import { clearShopAndShopDetail, restoreMyShop } from '_redux/modules/shop'
import { getSelectedShopLocalStorage, removeSelectedShopLocalStorage } from '_utils/localData'
import React from 'react'
import { GoInbox } from 'react-icons/go'
import { MdSettingsBackupRestore } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

function ListShopsDeleted() {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { myShopsDeleted } = useSelector((state) => state.shop)
  const selectedShopLocalStorage = getSelectedShopLocalStorage()

  const onRestore = async (shopId) => {
    try {
      await dispatch(restoreMyShop(shopId))

      if (selectedShopLocalStorage?.id) {
        dispatch(clearShopAndShopDetail(null))
        removeSelectedShopLocalStorage()
      }
      addToast('Restored shop successfully', { appearance: 'success', autoDismiss: true })
    } catch (e) {
      addToast(e.message || e.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  return (
    <>
      {!myShopsDeleted.length ? (
        <div
          className='text-secondary fw-semibold mt-4 bg-light rounded-3 d-flex flex-column align-items-center justify-content-center gap-3'
          style={{
            height: 300,
            backgroundColor: '#f8f9fa',
          }}
        >
          <NotFoundDataComponent
            icon={<GoInbox />}
            title={'No deleted shops found'}
            subtitle={'You have no deleted shops.'}
          />
        </div>
      ) : (
        <div className='my-shops__list mt-4'>
          {myShopsDeleted.map((item) => (
            <div key={item.id} className='shop-card'>
              <div className='shop-card__banner'>
                {item.logo && item.logo.length > 0 ? (
                  <img src={item.logo[0]} alt={item.shopName} />
                ) : (
                  <div className='shop-card__banner__placeholder'>
                    <span>{item.shopName[0]}</span>
                  </div>
                )}
              </div>

              <div className='shop-card__content'>
                <div className='shop-card__header mb-0'>
                  <h3 className='shop-card__title'>{item.shopName}</h3>
                  <CustomButton
                    icon={<MdSettingsBackupRestore />}
                    onClick={() => onRestore(item.id)}
                    variant='text'
                    color='slate'
                    className='shop-card__delete restore'
                    style={{
                      marginTop: 0,
                      height: 'auto',
                      width: 'auto',
                    }}
                  />
                </div>

                <div className='shop-card__description'>{item.headline}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default ListShopsDeleted

import InputField from '_components/InputField'
import classNames from 'classnames'
import React from 'react'

function OpeningHours({ datesOfWeek, handleCheckHoliday, index, checkedHoliday, handleClickDate }) {
  return (
    <div className='row opening-hours'>
      <div style={{ fontSize: 14 }} className='col-12 d-flex align-items-center mb-1 opening-hours__date'>
        <span>Opening Hours</span>
        <div className='date-wrapper'>
          <span className='date-open position-relative'></span>
          <span className='date-close position-relative'></span>
        </div>
      </div>
      <div className='col-12 d-flex align-items-center'>
        {datesOfWeek?.map((date, i) => (
          <label
            htmlFor={`date-delivery-${index}-${i}`}
            key={i}
            className={classNames(
              'date-of-week cursor-pointer flex-fill d-flex align-items-center justify-content-center',
              i !== 0 && 'border-left-0'
            )}
            title={date.name?.name}
            style={date.isClose ? { backgroundColor: 'gray' } : { backgroundColor: 'lightblue' }}
          >
            <input
              type='checkbox'
              id={`date-delivery-${index}-${i}`}
              checked={date.isClose}
              onChange={(e) => handleClickDate(e, i)}
              hidden
            />
            <span>{date.title[0]}</span>
          </label>
        ))}
      </div>
      <div className='col-12 opening-hours__holiday'>
        <InputField
          type='checkbox'
          id={`holiday-delivery-${index}`}
          checked={checkedHoliday}
          onChange={handleCheckHoliday}
          label='We are close during Public Holiday'
        />
      </div>
    </div>
  )
}

export default OpeningHours

/* eslint-disable no-shadow */
import Avatar from '_components/Avatar'
import bgAvatar from '_images/bg_avatar.png'
import ChangePassword from '_pages/User/ChangePassword'
import MyShops from '_pages/User/MyShops'
import NotificationsPage from '_pages/User/Notifications'
import UpdateProfilePage from '_pages/User/UpdateProfile'
import { fetchListInvoiceSubscription } from '_redux/modules/subscription'
import { getNotifications } from '_redux/modules/user'
import { getUserInfo } from '_utils/localData'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import AccountSkeleton from '../components/Skeletons/AccountSkeleton'
import './style.scss'

function Account() {
  const dispatch = useDispatch()
  const user = getUserInfo()
  const { pathname } = useLocation()
  const lastPath = pathname.split('/')[pathname.split('/').length - 1]

  const routes = [
    {
      id: 0,
      link: '/admin-console/setting/account/profile',
      label: 'Notification',
      icon: 'icon-noti',
    },
    {
      id: 1,
      link: '/admin-console/setting/account/update',
      label: 'Edit Profile',
      icon: 'icon-profile',
    },
    {
      id: 2,
      link: '/admin-console/setting/account/change-password',
      label: 'Change Password',
      icon: 'icon-pin',
    },
    {
      id: 3,
      link: '/admin-console/setting/account/my-shops',
      label: 'My Shops',
      icon: 'icon-shop',
    },
  ]

  const avatarUrl = user?.photoUrl || process.env.REACT_APP_AVATAR_URL + user?.name

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        // dispatch(fetchListInvoiceLazada()),
        dispatch(fetchListInvoiceSubscription()),
        dispatch(getNotifications),
      ])
    }

    if (user) {
      fetchData().then(() => {})
    }
  }, [JSON.stringify(user)])

  const renderChildrenRoute = () => {
    switch (lastPath) {
      case 'my-shops':
        return <MyShops />
      case 'change-password':
        return <ChangePassword />
      case 'update':
        return <UpdateProfilePage />
      case 'profile':
        return <NotificationsPage />
      default:
        break
    }
  }

  if (!user) return <AccountSkeleton />

  return (
    <div className='container'>
      <div className='profile-page mt-0 pt-0'>
        <div className='profile-avatar-bg'>
          <img src={bgAvatar} alt='avatar' />
        </div>
        <div className='c-form c-form--profile'>
          <Avatar photoUrl={avatarUrl} />
          <div className='upload-name mt-2'>{user?.name}</div>
          <div className='nav-profile'>
            <ul className='justify-content-center align-items-center'>
              {routes.map((route, index) => (
                <li
                  key={route.id}
                  style={{
                    maxWidth: '22%',
                  }}
                >
                  <Link to={route.link}>
                    <i className={route.icon}></i>
                    <span style={index === 2 ? { marginTop: 6 } : null}>{route.label}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          {renderChildrenRoute()}
        </div>
      </div>
    </div>
  )
}

export default Account

/* eslint-disable no-confusing-arrow */
/* eslint-disable function-paren-newline */

// Reorganized external imports
import moment from 'moment'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { AiFillGift } from 'react-icons/ai'
import { MdLocationOn } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

// Reorganized internal imports
import deliveryApi from '_api/delivery'
import deliveryEventApi from '_api/deliveryEvent'
import orderEventApi from '_api/orderEvent'
import CustomSelectBox from '_components/CustomSelectBox'
import TruncatedText from '_components/TruncatedText'
import { DeliveryContext } from '_contexts/DeliveryContext'
import {
  BUYER_ORDER_CANCELED,
  BUYER_ORDER_PAID,
  BUYER_ORDER_UNPAID,
  DELIVERY_BUYER_PAID,
  DELIVERY_BUYER_REFUND,
  DELIVERY_BUYER_UNPAID,
  DELIVERY_CANCEL_STATUS,
  DELIVERY_DELIVERED_STATUS,
  DELIVERY_EVENT_HOST_CANCELLED,
  DELIVERY_EVENT_HOST_PAID,
  DELIVERY_EVENT_HOST_UNPAID,
  DELIVERY_PENDING_STATUS,
  DELIVERY_UNPAID_STATUS,
} from '_utils/constant'
import { convertToCurrentGMT } from '_utils/functions/converter'
import { formatPhoneNumber } from '_utils/functions/formatter'

// Extracted styles
const disabledStyle = {
  pointerEvents: 'none',
  opacity: 0.5,
  filter: 'grayscale(100%)',
}

// Update ActionButton component
const ActionButton = ({ onClick, children }) => (
  <div className='action-button cursor-pointer' onClick={onClick}>
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24'>
      {children}
    </svg>
  </div>
)

const StatusIndicator = ({ status, onClick, combinedPaymentRef, disabled }) => {
  if (status === DELIVERY_BUYER_UNPAID) {
    return <span className='status unpaid'>Not Paid</span>
  }
  if (status === DELIVERY_BUYER_PAID) {
    return (
      <a
        href='#'
        className={combinedPaymentRef ? 'status paid-combined' : 'status paid'}
        data-toggle='modal'
        data-target='#modal-paid'
        onClick={onClick}
        style={disabled ? disabledStyle : {}}
      >
        Paid
      </a>
    )
  }
  if (status === DELIVERY_EVENT_HOST_CANCELLED) {
    return <span className='status cancelled'>Cancelled</span>
  }
  if (status === DELIVERY_BUYER_REFUND) {
    return <span className='status refunded'>Refunded</span>
  }
  return null
}

// Component
function DeliveryItem({ fulfillment, fulfillmentIndex, maxLengthFulfillment, isReversedDropdown }) {
  // Context and State
  const {
    swapUpFulfillment,
    swapDownFulfillment,
    updateStatusFulfillment,
    deliveryIdParam,
    openPaymentScreenDeliveryEvent,
  } = useContext(DeliveryContext)

  const { addToast } = useToasts()

  const { loadingGetListOrderDeliveryCombinedPayment } = useSelector((state) => state.deliveryEvent)

  const isDeliveryZones = !!fulfillment?.deliveryZones

  // Add states for tooltips
  const [tooltips, setTooltips] = useState({
    dzName: false,
    pickupOption: false,
    address: false,
  })

  // Refs
  const dzNameRef = useRef(null)
  const pickupOptionRef = useRef(null)
  const addressRef = useRef(null)

  const toggle = useCallback((tooltipId) => {
    setTooltips((prev) => ({
      ...prev,
      [tooltipId]: !prev[tooltipId],
    }))
  }, [])

  // Utility Functions
  const updateFulfillmentStatusAPI = async (_ref, _data, typeFulfillment) => {
    if (typeFulfillment === 'delivery') {
      try {
        let message = ''
        updateStatusFulfillment(_ref, _data.status, _data.bookerStatus, isDeliveryZones)

        if (isDeliveryZones) {
          if (!fulfillment?.combinedPaymentRef) {
            const { msgResp } = await deliveryEventApi.updateDeliveryBookingStatus(_ref, _data)
            message = msgResp
          } else {
            const postData = {
              combinedPaymentRef: _ref,
              placerStatus: _data.bookerStatus,
              status: _data.status,
            }
            const { msgResp } = await orderEventApi.updateInvoicesStatus(postData)
            message = msgResp
          }
        } else {
          const { msgResp } = await deliveryApi.updateDeliveryStatus(_ref, _data)

          message = msgResp
        }
        addToast(message, { appearance: 'success', autoDismiss: true })
      } catch (error) {
        const { msgResp } = error
        addToast(msgResp || error.message, { appearance: 'error', autoDismiss: true })
      }
    } else {
      try {
        updateStatusFulfillment(_ref, _data.status, _data.bookerStatus)
        const { msgResp } = await deliveryApi.updatePickupStatus(_ref, {
          status: _data.status,
          bookerStatus: _data.bookerStatus,
        })
        addToast(msgResp, { appearance: 'success', autoDismiss: true })
      } catch (error) {
        const { msgResp } = error
        addToast(msgResp || error.message, { appearance: 'error', autoDismiss: true })
      }
    }
  }

  const onChangeSelect = async (e, _fulfillment) => {
    try {
      const { ref, bookerStatus, combinedPaymentRef } = _fulfillment

      const statusSelected = Number(e.target.value)
      const hasCombinedPaymentRef = _fulfillment?.combinedPaymentRef

      const statusShopMap = {
        [BUYER_ORDER_UNPAID]: {
          [DELIVERY_DELIVERED_STATUS]: BUYER_ORDER_PAID,
          [DELIVERY_CANCEL_STATUS]: BUYER_ORDER_CANCELED,
          [DELIVERY_PENDING_STATUS]: DELIVERY_PENDING_STATUS,
          [DELIVERY_UNPAID_STATUS]: BUYER_ORDER_UNPAID,
        },
        [BUYER_ORDER_PAID]: {
          [DELIVERY_CANCEL_STATUS]: BUYER_ORDER_CANCELED,
          [DELIVERY_UNPAID_STATUS]: BUYER_ORDER_UNPAID,
          [DELIVERY_DELIVERED_STATUS]: BUYER_ORDER_PAID,
          [DELIVERY_PENDING_STATUS]: DELIVERY_PENDING_STATUS,
        },
        [BUYER_ORDER_CANCELED]: {
          [DELIVERY_CANCEL_STATUS]: BUYER_ORDER_CANCELED,
          [DELIVERY_UNPAID_STATUS]: BUYER_ORDER_UNPAID,
          [DELIVERY_DELIVERED_STATUS]: BUYER_ORDER_PAID,
          [DELIVERY_PENDING_STATUS]: DELIVERY_PENDING_STATUS,
        },
      }

      const statusDeliveryEventMap = {
        [DELIVERY_BUYER_PAID]: {
          [DELIVERY_EVENT_HOST_PAID]: DELIVERY_BUYER_PAID,
          [DELIVERY_EVENT_HOST_CANCELLED]: DELIVERY_EVENT_HOST_CANCELLED,
          [DELIVERY_EVENT_HOST_UNPAID]: !hasCombinedPaymentRef
            ? DELIVERY_EVENT_HOST_UNPAID
            : DELIVERY_BUYER_PAID,
        },
        [DELIVERY_BUYER_UNPAID]: {
          [DELIVERY_EVENT_HOST_CANCELLED]: DELIVERY_EVENT_HOST_CANCELLED,
          [DELIVERY_EVENT_HOST_UNPAID]: DELIVERY_BUYER_UNPAID,
          [DELIVERY_EVENT_HOST_PAID]: DELIVERY_BUYER_PAID,
        },
        [DELIVERY_EVENT_HOST_CANCELLED]: {
          [DELIVERY_EVENT_HOST_CANCELLED]: DELIVERY_EVENT_HOST_CANCELLED,
          [DELIVERY_EVENT_HOST_UNPAID]: DELIVERY_BUYER_UNPAID,
          [DELIVERY_EVENT_HOST_PAID]: DELIVERY_BUYER_PAID,
        },
      }

      const mappedBookerStatus = !isDeliveryZones
        ? statusShopMap[bookerStatus]
        : statusDeliveryEventMap[bookerStatus]

      const data = {
        status: statusSelected,
        bookerStatus: mappedBookerStatus ? mappedBookerStatus[statusSelected] : bookerStatus,
      }

      const typeFulfillment = _fulfillment.pickupBookingTimestamp ? 'pickup' : 'delivery'

      await updateFulfillmentStatusAPI(combinedPaymentRef || ref, data, typeFulfillment)
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp || error.message, { appearance: 'error', autoDismiss: true })
    }
  }

  const renderTimeFulfillment = () => {
    if (fulfillment?.pickupBookingTime) {
      const convertTimeToArray = fulfillment?.pickupBookingTime?.split('-')
      const newTimeCurrentGMT = convertTimeToArray
        ?.map((time) => {
          const currentTime = convertToCurrentGMT(
            `${fulfillment?.pickupBookingDate?.date} ${time.trim()}`
          )
          return moment(currentTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
        })
        ?.join(' - ')

      return newTimeCurrentGMT
    }

    if (fulfillment?.deliveryBookingTime) {
      const convertTimeToArray = fulfillment?.deliveryBookingTime?.split('-')
      const newTimeCurrentGMT = convertTimeToArray
        ?.map((time) => {
          const currentTime = convertToCurrentGMT(
            `${fulfillment?.deliveryBookingDate?.date} ${time.trim()}`
          )
          return moment(currentTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
        })
        ?.join(' - ')

      return newTimeCurrentGMT
    }
    return moment.unix(fulfillment?.createdAt).format('HH:mm')
  }

  const getOptionValue = (defaultValue, eventHostValue) =>
    !isDeliveryZones ? defaultValue : eventHostValue

  const optionValues = {
    cancelled: getOptionValue(DELIVERY_CANCEL_STATUS, DELIVERY_EVENT_HOST_CANCELLED),
    unpaid: getOptionValue(DELIVERY_UNPAID_STATUS, DELIVERY_EVENT_HOST_UNPAID),
    pending: DELIVERY_PENDING_STATUS,
    delivered: getOptionValue(DELIVERY_DELIVERED_STATUS, DELIVERY_EVENT_HOST_PAID),
  }

  const optionLabels = {
    delivered: !isDeliveryZones ? 'Delivered' : 'Verified',
  }

  const getStatusOptions = () => [
    { value: Number(optionValues.cancelled), label: 'Cancelled', className: 'cancel-status' },
    { value: Number(optionValues.unpaid), label: 'Unpaid', className: 'unpaid-status' },
    ...(!isDeliveryZones
      ? [{ value: Number(optionValues.pending), label: 'Pending', className: 'verified-status' }]
      : []),
    {
      value: Number(optionValues.delivered),
      label: optionLabels.delivered,
      className: 'fulfilled-status',
    },
  ]

  // Event Handlers
  const handleSwapUp = (_delivery) => {
    swapUpFulfillment(_delivery)
  }

  const handleStatusChange = (option) => {
    onChangeSelect({ target: { value: option.value } }, fulfillment)
  }

  useEffect(() => {
    if (dzNameRef.current) {
      const isTruncated = dzNameRef.current.scrollWidth > dzNameRef.current.clientWidth
      if (isTruncated && !tooltips.dzName) toggle('dzName')
    }
  }, [fulfillment.dzName, tooltips.dzName, toggle])

  useEffect(() => {
    if (pickupOptionRef.current) {
      const isTruncated = pickupOptionRef.current.scrollWidth > pickupOptionRef.current.clientWidth
      if (isTruncated && !tooltips.pickupOption) toggle('pickupOption')
    }
  }, [fulfillment.pickupOptionName, fulfillment.deliveryOptionName, tooltips.pickupOption, toggle])

  useEffect(() => {
    if (addressRef.current) {
      const isTruncated = addressRef.current.scrollWidth > addressRef.current.clientWidth
      if (isTruncated && !tooltips.address) toggle('address')
    }
  }, [fulfillment, tooltips.address, toggle])

  // Render
  return (
    <>
      <td className='col-order-no'>
        <div className='order-number-container'>
          <p className='mb-0 fw-bold order-number'>{fulfillmentIndex + 1}</p>
          <div className='action-buttons'>
            {fulfillmentIndex !== 0 && (
              <ActionButton onClick={() => handleSwapUp(fulfillment)}>
                <path d='M12 3l12 18h-24z' />
              </ActionButton>
            )}
            {fulfillmentIndex !== maxLengthFulfillment && (
              <ActionButton onClick={() => swapDownFulfillment(fulfillment)}>
                <path d='M12 21l-12-18h24z' />
              </ActionButton>
            )}
          </div>
        </div>
      </td>
      <td className='col-customer-name'>
        {fulfillment?.giftRecipientInfo ? (
          <div className='d-flex align-items-center'>
            <span>{fulfillment.giftRecipientInfo?.name}</span>
            <AiFillGift color='red' size={18} className='ms-2' />
          </div>
        ) : (
          <span>{fulfillment.uName}</span>
        )}
      </td>
      <td className='col-customer-phone'>
        {fulfillment?.giftRecipientInfo ? (
          <span>{formatPhoneNumber(fulfillment.giftRecipientInfo?.phone)}</span>
        ) : (
          <span>{formatPhoneNumber(fulfillment.uPhone)}</span>
        )}
      </td>
      <td className='col-order'>
        {fulfillment?.listOrder?.map((item) => (
          <React.Fragment key={item.id}>
            <TruncatedText text={`${item.pName} (${item.pQuantity})`} id={`listOrder-${item.id}`} />
            {/* {`${item.pName} (${item.pQuantity})`} */}
          </React.Fragment>
        ))}

        {fulfillment?.deliveryZones && (
          <>
            {fulfillment?.dzName && (
              <>
                {/* <TruncatedText text={fulfillment.dzName} id={`dzName-${fulfillment.ref}`} /> */}
                {fulfillment.dzName}
              </>
            )}
            {fulfillment?.dzPrice && (
              <CurrencyFormat
                value={fulfillment.dzPrice}
                displayType='text'
                thousandSeparator
                prefix='$'
                renderText={(value) => <span className='ms-1 fw-bold'>({value})</span>}
              />
            )}
          </>
        )}
      </td>
      <td className='col-fulfillment'>
        <div className='d-flex align-items-start gap-2 flex-column'>
          <TruncatedText
            text={fulfillment?.pickupOptionName || fulfillment.deliveryOptionName}
            id={`pickupOption-${fulfillment.ref}`}
            style={{ maxWidth: '100px' }}
            className='fw-bold'
          />

          <div className='d-flex align-items-center gap-1'>
            <MdLocationOn color='red' />

            <TruncatedText
              text={
                fulfillment?.giftRecipientInfo
                  ? fulfillment.giftRecipientInfo?.address
                  : fulfillment?.pickupAddress || fulfillment?.uAddress || fulfillment?.address
              }
              id={`address-${fulfillment.ref}`}
              style={{ maxWidth: '200px' }}
            />
          </div>
        </div>
      </td>
      <td className='col-fulfill-date'>
        <div className='mt-1 d-flex align-items-center'>
          <span className='fw-bold text-orange me-2'>
            {fulfillment?.pickupBookingDate?.date ||
              fulfillment?.deliveryBookingDate?.date ||
              fulfillment?.createdDate}
          </span>
          <span className='fw-bold text-orange'>{renderTimeFulfillment()}</span>
        </div>
      </td>
      {deliveryIdParam && (
        <td className='col-booker-status'>
          <StatusIndicator
            status={fulfillment.bookerStatus}
            onClick={(e) => {
              if (loadingGetListOrderDeliveryCombinedPayment) return
              openPaymentScreenDeliveryEvent(e, fulfillment)
            }}
            combinedPaymentRef={fulfillment?.combinedPaymentRef}
            disabled={loadingGetListOrderDeliveryCombinedPayment}
          />
        </td>
      )}

      <td className='col-status'>
        <CustomSelectBox
          options={getStatusOptions()}
          value={Number(fulfillment.status)} // Add Number() here
          onChange={handleStatusChange}
          className={`select-status ${
            getStatusOptions().find((e) => e.value === Number(fulfillment.status))?.className
          }`}
          isStatusSelect
          isReversedDropdown={isReversedDropdown}
        />
      </td>
    </>
  )
}

export default DeliveryItem

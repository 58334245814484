import CustomSelectBox from '_components/CustomSelectBox'
import { filterEventStatus } from '_redux/modules/event'
import { TURN_OFF, TURN_ON } from '_utils/constant'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default function EventFilterStatus() {
  const dispatch = useDispatch()
  const { selectedShop } = useSelector((state) => state.shop)
  const [selectedStatus, setSelectedStatus] = useState({ label: 'All', value: 'all' })

  const options = [
    { label: 'All', value: 'all' },
    { label: 'Opening', value: TURN_ON },
    { label: 'Closing', value: TURN_OFF },
  ]

  const handleChange = (option) => {
    setSelectedStatus(option)
    dispatch(filterEventStatus(option.value, selectedShop?.id))
  }

  return (
    <CustomSelectBox
      options={options}
      value={options.find((opt) => opt.value === selectedStatus.value)}
      onChange={handleChange}
      placeholder='Filter by status'
      className='w-auto'
      isStatusSelect
    />
  )
}

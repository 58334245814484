/* eslint-disable arrow-body-style */
import CustomButton from '_components/CustomButton'
import CustomSwalFire from '_components/CustomSwalFire'
import InputField from '_components/InputField'
import { setOpenSubItem } from '_redux/modules/product'
import { generateIdWithLength } from '_utils/functions/generator'
import { isNull, isUndefined } from 'lodash'
import React from 'react'
import CurrencyFormat from 'react-currency-format'
import { AiOutlinePlus } from 'react-icons/ai'
import { FaCopy, FaTrashAlt } from 'react-icons/fa'
import { IoIosAddCircleOutline } from 'react-icons/io'
import { MdOutlineAttachMoney } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

function SubItem({
  subItems,
  product,
  register,
  append,
  insert,
  remove,
  getValues,
  setValue,
  watch,
}) {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const onCopySubItem = (subItemId) => {
    if (subItems.length >= 2) {
      addToast(`Maximum number of sub-items: ${2}`, {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }
    try {
      const index = subItems.findIndex((item) => item.id === subItemId)

      const subItemData = getValues(`subItems.${index}`)
      const newSubItem = {
        ...subItemData,
        id: generateIdWithLength(20), // Tạo ID mới cho subItem sao chép
        list: subItemData.list.map((option) => ({
          ...option,
          id: generateIdWithLength(20), // Tạo ID mới cho mỗi option
        })),
      }
      insert(index + 1, newSubItem)
      // dispatch(copySubItem(product.id, subItemId))
    } catch (error) {
      addToast(error.message || error?.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const onAddSubItem = () => {
    if (subItems.length >= 2) {
      addToast(`Maximum number of sub-items: ${2}`, {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }
    try {
      append({
        list: [
          {
            price: 0,
            name: '',
            id: generateIdWithLength(20),
          },
        ],
        required: 1, // 1: required, 0: optional
        type: '',
        id: generateIdWithLength(20),
      })
      dispatch(setOpenSubItem(true, product.id, product.index))
    } catch (error) {
      addToast(error.message || error?.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const onAddOption = (subItemIndex) => {
    const newOption = {
      price: 0,
      name: '',
      id: generateIdWithLength(20),
    }

    const currentList = getValues(`subItems.${subItemIndex}.list`)

    setValue(`subItems.${subItemIndex}.list`, [...currentList, newOption])
  }

  const onDeleteOption = (subItemIndex, optionIndex) => {
    const listOptions = watch(`subItems.${subItemIndex}.list`)

    if (listOptions.length <= 1) return

    CustomSwalFire.delete({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      confirmButtonText: 'Yes, delete it!',

      onConfirm: async () => {
        const currentList = getValues(`subItems.${subItemIndex}.list`)
        const newList = currentList.filter((_, index) => index !== optionIndex)
        setValue(`subItems.${subItemIndex}.list`, newList)
      },
      onError: (error) => {
        CustomSwalFire.error({
          title: 'Error!',
          text: error.message || error?.msgResp,
        })
      },
    })
  }

  if (isUndefined(subItems) || isNull(subItems)) return []

  return (
    <>
      {subItems?.map((subItem, subIndex) => (
        <div className='sub-item c-form is-active' key={subItem?.id || generateIdWithLength(20)}>
          <div className='sub-item-div'>
            <div className='row mb-3'>
              <div className='col col-2 align-self-center col-index fw-semibold px-0'>
                Sub item {subIndex + 1}:
              </div>
              <div className='col col-10'>
                <InputField
                  type='text'
                  defaultValue={subItem?.type}
                  placeholder='Enter sub item name'
                  register={register(`subItems.${subIndex}.type`)}
                />
              </div>
            </div>
            {subItem?.list?.map((item, optionIndex) => (
              <div
                className='row sub-item-option align-items-center justify-content-start'
                key={`${subItem.id}-${optionIndex}`}
              >
                <span className='stt col col-2'>Option Name:</span>
                <div className='col-5'>
                  <InputField
                    type='text'
                    placeholder='Enter option name'
                    defaultValue={item?.name}
                    register={register(`subItems.${subIndex}.list.${optionIndex}.name`)}
                  />
                </div>

                {/* <CurrencyFormat
                  value={item?.price === 0 ? '' : item?.price}
                  displayType='input'
                  thousandSeparator={true}
                  decimalSeparator='.'
                  className='custom-input form-control col col-3 ms-auto me-4'
                  onValueChange={(values) => {
                    const { value } = values
                    setValue(
                      `subItems.${subIndex}.list.${optionIndex}.price`,
                      value === '' ? 0 : value
                    )
                  }}
                  prefix='$'
                  placeholder='Enter price'
                  decimalScale={2}
                  allowEmptyFormatting={false}
                /> */}

                <InputField
                  value={item?.price === 0 ? '' : item?.price}
                  as={CurrencyFormat}
                  onValueChange={(values) => {
                    const { value } = values
                    setValue(
                      `subItems.${subIndex}.list.${optionIndex}.price`,
                      value === '' ? 0 : value
                    )
                  }}
                  decimalScale={2}
                  allowNegative={false} // Changed from negativeAllowed to allowNegative
                  thousandSeparator={true}
                  placeholder='Enter price'
                  className='col col-auto flex-grow-1'
                  icon={<MdOutlineAttachMoney />}
                />

                <div className='col col-auto ms-auto'>
                  <div className='d-flex align-items-center justify-content-end w-100'>
                    <CustomButton
                      icon={<AiOutlinePlus size={20} />}
                      className='border-0 mt-0 w-auto px-2 me-2'
                      color='slate'
                      onClick={() => onAddOption(subIndex)}
                    />
                    <CustomButton
                      className='button-delete border-0 mt-0 w-auto px-2'
                      color='slate'
                      onClick={() => onDeleteOption(subIndex, optionIndex)}
                      icon={<FaTrashAlt />}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='d-flex w-100 align-items-center justify-content-end'>
            <CustomButton
              icon={<IoIosAddCircleOutline size={20} />}
              className='border-0 w-auto px-2 me-2'
              color='slate'
              onClick={onAddSubItem}
            />
            <CustomButton
              icon={<FaCopy size={20} />}
              className='border-0 w-auto px-2 me-2'
              color='slate'
              onClick={() => onCopySubItem(subItem.id)}
            />
            <CustomButton
              icon={<FaTrashAlt size={20} />}
              className='button-delete border-0 w-auto px-2'
              color='slate'
              onClick={() => remove(subIndex)}
            />
          </div>
        </div>
      ))}
    </>
  )
}

export default SubItem

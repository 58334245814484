import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const CreateEventSkeleton = () => (
  <div className='px-3'>
    {/* Header */}
    <Skeleton width={100} height={30} className='mb-2' />
    <Skeleton width={80} height={20} className='mb-4' />

    <div className='container'>
      <div className='row-top create-event-page'>
        <div className='c-form c-form--line'>
          {/* Title */}
          <div className='form-group mb-4'>
            <Skeleton width={100} height={20} className='mb-2' />
            <Skeleton height={38} />
          </div>

          {/* Description */}
          <div className='form-group mb-4'>
            <Skeleton width={120} height={20} className='mb-2' />
            <Skeleton height={100} />
          </div>

          {/* Pickup Location */}
          <div className='form-group mb-4'>
            <Skeleton width={130} height={20} className='mb-2' />
            <Skeleton height={38} />
          </div>

          {/* Dates */}
          <div className='row mt-3'>
            <div className='col-6'>
              <div className='form-group mb-4'>
                <Skeleton width={110} height={20} className='mb-2' />
                <Skeleton height={38} />
              </div>
            </div>
            <div className='col-6'>
              <div className='form-group mb-4'>
                <Skeleton width={120} height={20} className='mb-2' />
                <Skeleton height={38} />
              </div>
              <div className='form-group'>
                <Skeleton width={110} height={20} className='mb-2' />
                <div className='d-flex gap-2'>
                  <Skeleton width={120} height={38} />
                  <Skeleton width={20} height={38} />
                  <Skeleton width={120} height={38} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Payment Options */}
        <div className='mt-4'>
          <Skeleton height={200} />
        </div>
      </div>

      {/* Bottom Buttons */}
      <div className='row mt-4'>
        <div className='col-6'>
          <Skeleton width={100} height={38} />
        </div>
        <div className='col-6'>
          <Skeleton width={100} height={38} />
        </div>
      </div>
    </div>
  </div>
)

export default CreateEventSkeleton

import InputField from '_components/InputField'
import React from 'react'

function Search({ searchString, onSearch, placeholder }) {
  return (
    <InputField
      type='text'
      isSearch={true}
      placeholder={placeholder}
      value={searchString}
      onChange={(e) => onSearch(e.target.value)}
      className={'admin-search-input'}
    />
  )
}

export default Search

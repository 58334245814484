import React from 'react'
import { FaFileExport, FaTruck } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import CustomButton from '../CustomButton'

const disabledStyle = {
  pointerEvents: 'none',
  opacity: 0.5,
  filter: 'grayscale(100%)',
}

const OrderActions = ({ toggleOrder, exportOrders, isOpenOrder }) => {
  const { loadingCurrentDeliveryAndBooking } = useSelector((state) => state.deliveryEvent)

  return (
    <div className='pb-3 order-actions'>
      <p className='mb-2'>Order actions:</p>
      <div className='d-flex justify-content-between gap-3'>
        <CustomButton
          onClick={toggleOrder}
          text={isOpenOrder ? 'Close Delivery' : 'Re-open Delivery'}
          icon={<FaTruck />}
          color='aqua'
          variant='contained'
        />
        <CustomButton
          onClick={exportOrders}
          text='Export Orders'
          icon={<FaFileExport />}
          color='aqua'
          variant='contained'
          isDisabled={loadingCurrentDeliveryAndBooking}
          style={loadingCurrentDeliveryAndBooking ? disabledStyle : {}}
        />
      </div>
    </div>
  )
}

export default OrderActions

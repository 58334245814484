import { setViewDefaultForShop } from '_redux/modules/shop'
import { VIEW_GRID, VIEW_LIST } from '_utils/constant'
import classNames from 'classnames'
import React from 'react'
import { BsGrid, BsList } from 'react-icons/bs'
import { useDispatch } from 'react-redux'

function SelectViewProduct({ viewDefault, renderClearCart }) {
  const dispatch = useDispatch()

  return (
    <div className='view-selector d-none'>
      <button
        className={classNames('view-selector__btn', 'view-selector__btn--list', {
          'view-selector__btn--active': viewDefault === VIEW_LIST,
        })}
        onClick={() => dispatch(setViewDefaultForShop(VIEW_LIST))}
      >
        <BsList className='view-selector__icon' />
        <span className='view-selector__text'>List</span>
      </button>

      <button
        className={classNames('view-selector__btn', 'view-selector__btn--grid', {
          'view-selector__btn--active': viewDefault === VIEW_GRID,
        })}
        onClick={() => dispatch(setViewDefaultForShop(VIEW_GRID))}
      >
        <BsGrid className='view-selector__icon' />
        <span className='view-selector__text'>Tile</span>
      </button>
      {typeof renderClearCart === 'function' && renderClearCart()}
    </div>
  )
}

export default SelectViewProduct

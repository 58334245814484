/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unescaped-entities */
import MyLogo from '_images/about-us-landing-page.png'
import React from 'react'

class AboutUs extends React.Component {
  render() {
    return (
      <div className='about-us row container mx-auto py-3' id='about-us'>
        <div className='col-12 col-lg-4'>
          <div className='d-flex h-100 w-100 align-items-center'>
            <img src={MyLogo} alt='Logo' className='w-100 object-fit-cover' />
          </div>
        </div>
        <div className='col-12 col-lg-8 px-3 px-lg-0'>
          <h4 className='text-center'>About Us</h4>
          <div className='content f-16' style={{ textAlign: 'justify' }}>
            <p>Welcome to Co-hoot!</p>
            <p>
              At Co-hoot, we are dedicated to fostering a vibrant ecosystem that celebrates local
              businesses and empowers our community here in the bustling city-state of Singapore. We
              firmly believe that in our compact but dynamic nation, commerce thrives when
              businesses and communities come together in symbiotic harmony.
            </p>
            <p>
              Our mission is simple yet powerful: to bridge the gap between businesses and the
              community, facilitating direct engagement and mutual benefit. By uniting business
              owners and consumers, we aim to create a seamless marketplace where both parties can
              connect, collaborate, and flourish.
            </p>
            <p>
              Co-bee serves as the cornerstone of our platform, offering an interactive space for
              group-buying activities and community events. Through Co-bee, individuals can come
              together to discover exciting deals, share experiences, and build meaningful
              connections within their local area.
            </p>
            <p>
              Complementing Co-bee is Co-hoot, a dedicated space designed to empower business owners
              with a streamlined, no-frills shopfront. With Co-hoot, businesses gain access to a
              wider audience while enjoying the convenience of our integrated platform, thus
              reducing costs and enhancing efficiency.
            </p>
            <p>
              Join us as we embark on this journey to reshape the way commerce is conducted in
              Singapore. Together, let's create a thriving ecosystem where businesses thrive,
              communities flourish, and connections abound.
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default AboutUs

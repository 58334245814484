import PropTypes from 'prop-types'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md' // Import icons mặc định
import ImageGallery from 'react-image-gallery'

function MySlider({
  listImage,
  className,
  // Thêm props cho custom buttons
  showNavButtons = true,
  prevIcon = <MdKeyboardArrowLeft size={24} />,
  nextIcon = <MdKeyboardArrowRight size={24} />,
  navButtonClassName = '',
}) {
  if (listImage.length === 0) {
    return <div className='slider__no-image' />
  }

  if (listImage.length === 1) {
    return (
      <div className={`slider__single ${className}`}>
        <img src={listImage[0]} alt={listImage[0]} className='slider__image' />
      </div>
    )
  }

  // Custom render cho navigation buttons
  const renderLeftNav = (onClick, disabled) => {
    if (!showNavButtons) return null

    return (
      <button
        className={`slider__nav slider__nav--prev ${navButtonClassName}`}
        onClick={onClick}
        disabled={disabled}
        aria-label='Previous'
      >
        {prevIcon}
      </button>
    )
  }

  const renderRightNav = (onClick, disabled) => {
    if (!showNavButtons) return null

    return (
      <button
        className={`slider__nav slider__nav--next ${navButtonClassName}`}
        onClick={onClick}
        disabled={disabled}
        aria-label='Next'
      >
        {nextIcon}
      </button>
    )
  }

  const imagesMapped = listImage.map((img) => ({
    original: img,
    originalClass: 'slider__image',
  }))

  return (
    <div className={`slider__container ${className}`}>
      <ImageGallery
        items={imagesMapped}
        autoPlay={false}
        showPlayButton={false}
        showFullscreenButton={false}
        additionalClass='slider__gallery'
        renderLeftNav={renderLeftNav}
        renderRightNav={renderRightNav}
        showNav={showNavButtons}
      />
    </div>
  )
}

MySlider.propTypes = {
  listImage: PropTypes.array.isRequired,
  className: PropTypes.string,
  showNavButtons: PropTypes.bool,
  prevIcon: PropTypes.node,
  nextIcon: PropTypes.node,
  navButtonClassName: PropTypes.string,
}

export default MySlider

import InputField from '_components/InputField'
import React from 'react'

function PickupName({ optionName, register, errors, index }) {
  return (
    <div className='mb-3 row'>
      <div className='col-12 d-flex flex-column'>
        <InputField
          id={`pickup-name-${index}`}
          type='text'
          label='Option Name'
          labelStyle={{ fontSize: 14, fontWeight: 400 }}
          placeholder='Pickup'
          register={register('optionPickupName')}
          defaultValue={optionName}
          error={errors?.optionPickupName?.message}
        />
      </div>
    </div>
  )
}

export default PickupName

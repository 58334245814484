import moment from 'moment'
import React, { useState } from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Spinner } from 'reactstrap'
import { getAllShopsOpening } from '_redux/modules/shop'
import { getCreateShopData, removeCreateShopData } from '_utils/localData'
import ModalCreatePickup from './components/ModalCreatePickup'
import ModalInfoCreatePickup from './components/ModalInfoCreatePickup'

import './style.scss'

function GeneratedDomain() {
  const history = useHistory()
  const dispatch = useDispatch()
  const dataCreateShop = getCreateShopData()
  const futureMonth = moment().add(1, 'M')
  const [isLoading, setIsLoading] = useState(false)

  const handleOnClick = () => {
    dispatch(getAllShopsOpening)
    setIsLoading(true)
    removeCreateShopData()
    setIsLoading(false)
    history.push(`/${dataCreateShop?.shopSlug}`)
  }

  const [isOpenModalShowInfo, setIsOpenModalShowInfo] = useState(true)
  const [isOpenModalCreatePickup, setIsOpenModalCreatePickup] = useState(false)

  const toggleModalShowInfo = () => {
    setIsOpenModalShowInfo(!isOpenModalShowInfo)
  }

  const toggleModalCreatePickup = () => {
    setIsOpenModalCreatePickup(!isOpenModalCreatePickup)
  }

  return (
    <>
      <div className='generated-domain'>
        <div className='generated-domain-box'>
          <div className='generated-domain-check-icon'>
            <FaCheckCircle />
          </div>
          <div className='generated-domain-name'>
            <span>Congratulation! Your virtual shop is launched.</span>
            <p>URL link:</p>
            <a href='' onClick={handleOnClick}>{dataCreateShop?.shopLink}</a>
          </div>
        </div>
        <div className='generated-domain-mess'>
          <p>
            Your 30-day Trail is <b> 100% free </b>
            and last until <b>{futureMonth.format('MMMM DD, YYYY')}. </b>Cancel any time
          </p>
        </div>

        <div className='generated-domain-actions'>
          <span>To help you managed your shop, following are resource for you</span>

          <button onClick={handleOnClick}>
            {isLoading ? <Spinner color='info' size='sm' /> : 'Quick Guide to manage your shop'}
          </button>
          {/* <button>Advertise on Co-bee to make your shop know</button> */}
        </div>
      </div>
      <ModalInfoCreatePickup
        modal={isOpenModalShowInfo}
        toggle={toggleModalShowInfo}
        toggleAddPickup={toggleModalCreatePickup}
      />
      <ModalCreatePickup modal={isOpenModalCreatePickup} toggle={toggleModalCreatePickup} />
    </>
  )
}

export default GeneratedDomain

import classNames from 'classnames'
import PropTypes from 'prop-types'
import CurrencyFormat from 'react-currency-format'
import { IoIosClose } from 'react-icons/io'
import AddToCartButton from './AddToCartButton'
import CheckBoxSubItem from './CheckBoxSubItem'
import ProductActions from './ProductAddAnother'
import QuantityControl from './QuantityControl'

const ProductContent = ({
  name,
  description,
  viewMode = 'grid',
  className,
  product,
  findProduct,
  changeProductQuantityInput,
  colorTheme,
  handleChangeSubItem,
  getValueSelect,
  addAnotherOrder,
  deleteItemOrder,
  isHovered,
  isSoldOut,
  onAddToCart,
}) => (
  <div
    className={classNames('product-card__content', `product-card__content--${viewMode}`, className)}
  >
    {product?.isCopy && (
      <div
        className='product-card__delete-btn'
        onClick={() => deleteItemOrder(product.id, product.index)}
      >
        <IoIosClose size={20} />
      </div>
    )}

    <div className='product-card__content-header'>
      <div className='product-card__content-title'>{name}</div>
      <p className='product-card__content-price' style={{ color: colorTheme?.primary }}>
        <CurrencyFormat
          value={product.price}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
        />{' '}
        SGD
      </p>
    </div>

    {description && (
      <div className='product-card__content-body'>
        <div className='product-card__content-description text-sm'>{description}</div>
      </div>
    )}

    <CheckBoxSubItem
      product={product}
      handleChangeSubItem={handleChangeSubItem}
      getValueSelect={getValueSelect}
      viewMode={viewMode}
      addAnotherOrder={addAnotherOrder}
      deleteItemOrder={deleteItemOrder}
    />

    <div
      className={classNames('product-card__actions', {
        'product-card__actions--list': viewMode === 'list',
        'product-card__actions--grid': viewMode === 'grid',
      })}
    >
      <div className='product-card__action-group'>
        {product.quantityInput > 0 ? (
          <QuantityControl
            product={product}
            findProduct={findProduct}
            changeProductQuantityInput={changeProductQuantityInput}
            colorTheme={colorTheme}
            viewMode={viewMode}
          />
        ) : (
          <AddToCartButton
            isHovered={isHovered}
            isSoldOut={isSoldOut}
            colorTheme={colorTheme}
            onAddToCart={onAddToCart}
            product={product}
            viewMode={viewMode}
          />
        )}
      </div>

      <div className='product-card__action-group'>
        <ProductActions
          viewMode={viewMode}
          product={product}
          onAddAnotherOrder={addAnotherOrder}
          onDeleteOrder={deleteItemOrder}
        />
      </div>
    </div>
  </div>
)

ProductContent.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  viewMode: PropTypes.oneOf(['grid', 'list']),
  colorTheme: PropTypes.shape({
    primary: PropTypes.string,
  }),
  className: PropTypes.string,
}

export default ProductContent

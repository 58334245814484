import CustomButton from '_components/CustomButton'
import React from 'react'
import { CiBoxList, CiGrid41 } from 'react-icons/ci'

export default function EventView({ view, handleViewChange }) {
  return (
    <div className='event-view d-flex align-items-center justify-content-end gap-1 ms-auto'>
      <CustomButton
        onClick={() => handleViewChange('list')}
        isActive={view === 'list'}
        icon={<CiBoxList />}
        className='mt-0 w-auto slate'
        style={{ height: 32 }}
      />
      <CustomButton
        onClick={() => handleViewChange('grid')}
        isActive={view === 'grid'}
        icon={<CiGrid41 />}
        className='mt-0 w-auto slate'
        style={{ height: 32 }}
      />
    </div>
  )
}

const GlobalStyle = {
  primaryColor: '#15cdca',
  secondaryColor: '#F06A0F',
  blackColor: '#121212',

  image: {
    backgroundColor: '#f8fafc',
    iconColor: '#94a3b8',
    textColor: '#64748b',
    subtextColor: '#94a3b8',
  },
}

export default GlobalStyle

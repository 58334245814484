import InputField from '_components/InputField'
import React, { useEffect, useState } from 'react'
import { AddDeliveryOption } from './AddDeliveryOption'
import EventExternalURL from './EventExternalURL'
import EventNote from './EventNote'

const Note = ({ register, hiddenNote: defaultVal, setValue, watch, control, getValues }) => {
  const [checked, setChecked] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const toggleModal = () => {
    setOpenModal(!openModal)
  }

  const handleChange = (e) => {
    const isCheck = e.target.checked
    setChecked(isCheck)
    if (isCheck) {
      setOpenModal(true)
    } else {
      setValue('termConds', [])
    }
  }

  useEffect(() => {
    setChecked(defaultVal)
  }, [defaultVal])

  return (
    <React.Fragment>
      <div className='havebg' data-testid='event-note'>
        <div className='row'>
          <div className='col-12'>
            <div className='custom-control custom-checkbox'>
              <input
                id='customCheck3'
                type='checkbox'
                onChange={(e) => handleChange(e)}
                className='custom-control-input'
                checked={checked}
              />
              <label className='custom-control-label' htmlFor='customCheck3'>
                Add important notes to buyers
              </label>
            </div>
          </div>
        </div>
      </div>
      {openModal && (
        <EventNote
          modal={openModal}
          toggle={toggleModal}
          register={register}
          watch={watch}
          control={control}
          getValues={getValues}
          setValue={setValue}
        />
      )}
    </React.Fragment>
  )
}

const ExternalURLs = ({ externalUrl, hiddenExternalURL: defaultVal, setExternalUrl }) => {
  const [checked, setChecked] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const toggleModal = () => {
    setOpenModal(!openModal)
  }

  const handleChange = (e) => {
    const isCheck = e.target.checked
    setChecked(isCheck)
    if (isCheck) {
      setOpenModal(true)
    } else {
      setExternalUrl(null)
    }
  }

  useEffect(() => {
    setChecked(defaultVal)
  }, [defaultVal])

  return (
    <React.Fragment>
      <div className='havebg' data-testid='event-external-url'>
        <div className='row'>
          <div className='col-12'>
            <div className='custom-control custom-checkbox'>
              <input
                id='extraFieldCheckBox'
                type='checkbox'
                onChange={(e) => handleChange(e)}
                className='custom-control-input'
                checked={checked}
              />
              <label className='custom-control-label' htmlFor='extraFieldCheckBox'>
                Add external URL
              </label>
            </div>
            <small className='error'></small>
          </div>
        </div>
      </div>
      <EventExternalURL
        modal={openModal}
        toggle={toggleModal}
        value={externalUrl}
        onChange={setExternalUrl}
      />
    </React.Fragment>
  )
}
const PaymentOption = (props) => {
  const { register, paynow, setPaynow, payTogether, setPayTogether, eid } = props

  const [checked, setChecked] = useState(false)
  const [checkedPayTogether, setCheckedPayTogether] = useState(true)

  useEffect(() => {
    setChecked(paynow)
    setCheckedPayTogether(payTogether)
  }, [paynow, payTogether])

  return (
    <div className='payment'>
      <h4 className='c-txt-12 mb-2'>
        <strong style={{ fontWeight: 500 }}>Payment Options</strong>
      </h4>
      <div className='c-form'>
        <div className='havebg'>
          <div
            className='custom-control custom-checkbox'
            data-testid='event-pay-after-closing-date'
          >
            <input
              name='paynow'
              type='checkbox'
              className='custom-control-input'
              id='customCheck1'
              value={paynow}
              onChange={() => setPaynow(!paynow)}
              checked={checked}
            />
            <label className='custom-control-label' htmlFor='customCheck1'>
              Payment to be made after closing date
            </label>
            <p className='text-small'>
              This option calls for buyers to pay later. This option allow host to distribute the
              cost of delivery cost and consolidate discount at the end of the event. Delivery
              charges and discount amount are to be entered at &gt; My Event &gt; Host Dashboard
            </p>
          </div>
        </div>
        <div className='havebg'>
          <div className='custom-control custom-checkbox' data-testid='event-pay-together'>
            <input
              name='payTogether'
              type='checkbox'
              className='custom-control-input'
              id='payTogetherCheckbox'
              value={payTogether}
              onChange={() => setPayTogether(!payTogether)}
              checked={checkedPayTogether}
            />
            <label className='custom-control-label' htmlFor='payTogetherCheckbox'>
              Pay together
            </label>
            <p className='text-small'>
              This option allows buyers to pay for multiple events enabled with Pay together
            </p>
          </div>
        </div>
        <div className='havebg'>
          <div className='row' data-testid='event-admin-fees'>
            <div className='col-8'>
              <div className='custom-control custom-checkbox'>
                <label className='custom-control-label'>
                  <small>Admin fees to be added to each order</small>
                </label>
              </div>
            </div>
            <div className='col-4 text-right'>
              <span className='txt-s'>$</span>
              <InputField
                name='adminCost'
                type='number'
                step='0.01'
                register={register('adminCost')}
                defaultValue='0.0'
                onWheel={(event) => {
                  event.target.blur()
                }}
              />
            </div>
          </div>
        </div>
        <Note {...props} />
        <ExternalURLs {...props} />
        {!eid ? <AddDeliveryOption {...props} /> : null}
      </div>
    </div>
  )
}

export default PaymentOption

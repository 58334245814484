import Tele1 from '_images/tele_1.png'
import Tele2 from '_images/tele_2.png'
import Tele3 from '_images/tele_3.png'
import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const modalTitleStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

function ModalTelegram({ modal, toggle, ...args }) {
  return (
    <Modal isOpen={modal} toggle={toggle} {...args}>
      <ModalHeader toggle={toggle}>
        <span style={modalTitleStyle}>Integrate Guide</span>
      </ModalHeader>
      <ModalBody>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mb-2'>
              <div className='card border-0'>
                <label htmlFor='step_1'>Step 1:</label>
                <img src={Tele1} className='card-img-top' alt='Step 1' id='step_1' />
              </div>
            </div>
            <div className='col-12 mb-2'>
              <div className='card border-0'>
                <label htmlFor='step_2'>Step 2:</label>
                <img src={Tele2} className='card-img-top' alt='Step 2' id='step_2' />
              </div>
            </div>
            <div className='col-12 mb-2'>
              <div className='card border-0'>
                <label htmlFor='step_3'>Step 3:</label>
                <img src={Tele3} className='card-img-top' alt='Step 3' id='step_3' />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalTelegram

import CustomButton from '_components/CustomButton'
import InputField from '_components/InputField'
import { truncate } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { IoSave } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalHeader, Tooltip } from 'reactstrap'

function ModalProductCheckbox(props) {
  const [tooltipOpen, setTooltipOpen] = useState({})

  const {
    isOpenModalProductCheckboxOpen,
    toggleModalProductCheckbox,
    listProductSelected,
    setListProductSelected,
  } = props

  const { listProduct } = useSelector((state) => state.product)

  const sortedListProduct = [...listProduct].sort((a, b) =>
    moment(a.createdAt).diff(moment(b.createdAt))
  )

  const [tempSelectedProducts, setTempSelectedProducts] = useState(listProductSelected)

  useEffect(() => {
    setTempSelectedProducts(listProductSelected)
  }, [listProductSelected])

  const toggleTooltip = (productId) => {
    setTooltipOpen((prev) => ({
      ...prev,
      [productId]: !prev[productId],
    }))
  }

  const isProductSelected = (productId) =>
    tempSelectedProducts.some((selectedProduct) => selectedProduct.id === productId)

  const handleSelectProduct = (selectedProduct) => {
    if (isProductSelected(selectedProduct.id)) {
      setTempSelectedProducts(
        tempSelectedProducts.filter((product) => product.id !== selectedProduct.id)
      )
    } else {
      setTempSelectedProducts([...tempSelectedProducts, selectedProduct])
    }
  }

  const handleSyncData = () => {
    setListProductSelected(tempSelectedProducts)
    toggleModalProductCheckbox()
  }

  return (
    <Modal
      isOpen={isOpenModalProductCheckboxOpen}
      toggle={toggleModalProductCheckbox}
      centered
      className='modal-list-prod-checkbox f-16'
    >
      <ModalHeader toggle={toggleModalProductCheckbox}>
        <span className='c-txt-gra sync-data-title'>Select Fulfill Product</span>
      </ModalHeader>
      <ModalBody>
        <ul className='list-unstyled d-flex align-items-center flex-wrap gap-3 mb-0'>
          {sortedListProduct.map((product) => (
            <li key={product.id}>
              <label htmlFor={product.id} className='cursor-pointer d-flex align-items-center'>
                <InputField
                  type='checkbox'
                  id={product.id}
                  checked={isProductSelected(product.id)}
                  onChange={() => handleSelectProduct(product)}
                  labelStyle={{ marginBottom: 0 }}
                />
                <span id={`tooltip-${product.id}`}>{truncate(product.name, { length: 20 })}</span>
                {product.name.length > 20 && (
                  <Tooltip
                    isOpen={tooltipOpen[product.id]}
                    target={`tooltip-${product.id}`}
                    toggle={() => toggleTooltip(product.id)}
                    className='custom-tooltip'
                    style={{ fontSize: 14 }}
                  >
                    {product.name}
                  </Tooltip>
                )}
              </label>
            </li>
          ))}
        </ul>
        {tempSelectedProducts.length === 0 && (
          <div className='text-danger mb-3' role='alert'>
            Select at least 1 product
          </div>
        )}
        <CustomButton
          onClick={handleSyncData}
          variant='contained'
          color='aqua'
          text='Sync data'
          icon={<IoSave />}
          isDisabled={tempSelectedProducts.length === 0}
        />
      </ModalBody>
    </Modal>
  )
}

export default ModalProductCheckbox

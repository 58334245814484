/* eslint-disable no-self-assign */
/* eslint-disable operator-assignment */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-confusing-arrow */
// import LoadingOverlay from '_components/LoadingOverlay'
import { useWindowWidth } from '@react-hook/window-size'
import CustomModal from '_components/CustomModal'
import CustomTable from '_components/CustomTable' // Add this import
import ErrorBoundary from '_components/ErrorBoundary'
import RenderSubItems from '_components/RenderSubItems'
import { OrderRecordContext } from '_contexts/OrderRecordContext'
import NoImage from '_images/noimage02.svg'
import { updateOrderStatus } from '_redux/modules/order'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import ModalInfoGiftRecipient from '../Common/ModalInfoGiftRecipient'
import ModalPayment from '../Common/ModalPayment'
import ListOrderRecordSkeleton from '../Skeletons/ListOrderRecordSkeleton'
import OrderRecordItem from './OrderRecordItem'

// Add this new component at the top of the file
const FeesSummary = ({ order, totalPriceEachOrder }) => (
  <div className='fees-summary'>
    <div className='fees-summary__content'>
      <div className='fees-row'>
        <span>Subtotal</span>
        <CurrencyFormat
          value={order?.totalFee || 0}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
          decimalScale={2}
          fixedDecimalScale={true}
        />
      </div>

      {order?.transactionFees > 0 && (
        <div className='fees-row'>
          <span>Transaction Fee</span>
          <CurrencyFormat
            value={order?.transactionFees || 0}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </div>
      )}

      {order?.deliveryDetail?.deliveryFee > 0 && (
        <div className='fees-row'>
          <span>Delivery Fee</span>
          <CurrencyFormat
            value={order?.deliveryDetail?.deliveryFee || 0}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </div>
      )}

      {order?.pickupDetail?.pickupFee > 0 && (
        <div className='fees-row'>
          <span>Pickup Fee</span>
          <CurrencyFormat
            value={order?.pickupDetail?.pickupFee || 0}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </div>
      )}

      <div className='fees-row total'>
        <span>Total</span>
        <CurrencyFormat
          value={totalPriceEachOrder(order) || 0}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
          decimalScale={2}
          fixedDecimalScale={true}
          className='total-amount'
        />
      </div>
    </div>
  </div>
)

function ListOrderRecord() {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const width = useWindowWidth()
  const isMobile = width <= 768

  const [dataModal, setDataModal] = useState({})
  const [isModalGift, setIsModalGift] = useState(false)
  const [infoPersonGift, setInfoPersonGift] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isProductDetailsModalOpen, setIsProductDetailsModalOpen] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)

  const { isLoading, sortListOrders } = useContext(OrderRecordContext)

  const toggleModal = () => setIsModalOpen(!isModalOpen)

  // Update toggle function to be more explicit
  const toggleProductDetailsModal = (order) => {
    setSelectedOrder(order)
    setIsProductDetailsModalOpen((prev) => !prev)
  }

  // Make sure modal state is properly reset when closing
  const handleCloseProductDetails = () => {
    setSelectedOrder(null)
    setIsProductDetailsModalOpen(false)
  }

  const totalPriceEachOrder = (item) => {
    let deliveryFee = 0
    let pickUpFee = 0
    const transactionFees = item?.transactionFees || 0

    if (item.cashOnDeliveryPickup === 1) deliveryFee = 0
    if (item.hasOwnProperty('deliveryDetail')) deliveryFee = item.deliveryDetail?.deliveryFee
    if (item.hasOwnProperty('pickupDetail')) pickUpFee = item.pickupDetail?.pickupFee

    const subTotal = item.totalFee

    const calculateDeliveryFee = isNaN(deliveryFee) ? 0 : deliveryFee
    const calculatePickUpFee = isNaN(pickUpFee) ? 0 : pickUpFee

    return subTotal + calculateDeliveryFee + calculatePickUpFee + transactionFees
  }

  const handleInvalidPayment = async (ref, _status) => {
    const r = confirm('Are you sure this is invalid payment?')
    if (r === true) {
      try {
        const postStatus = {
          buyerStatus: _status,
          status: _status,
        }
        await dispatch(updateOrderStatus(postStatus, ref))

        addToast('Updated successfully!', { appearance: 'success', autoDismiss: true })
      } catch (error) {
        addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
      } finally {
        toggleModal()
      }
    }
  }

  const tableHeaders = [
    {
      label: 'No',
      className: 'col-order-no',
      minWidth: '60px',
      nowrap: true,
    },
    {
      label: 'Customer Name',
      className: 'col-customer-name',
      minWidth: '200px',
      nowrap: true,
    },
    {
      label: 'Customer Email',
      className: 'col-customer-email',
      minWidth: '250px',
    },
    {
      label: 'Phone',
      className: 'col-phone',
      minWidth: '180px',
      nowrap: true,
    },
    {
      label: 'Fulfillment',
      className: 'col-fulfillment',
      minWidth: '300px',
    },
    {
      label: 'Order Date',
      className: 'col-date',
      minWidth: '190px',
      nowrap: true,
    },
    {
      label: 'Fulfillment Date',
      className: 'col-fulfillment-date',
      minWidth: '210px',
      nowrap: true,
    },
    {
      label: 'Payment Status',
      className: 'col-payment-status',
      minWidth: '200px',
      nowrap: true,
    },
    {
      label: 'Status',
      className: 'col-status',
      minWidth: '160px',
      nowrap: true,
    },
    {
      label: '',
      className: 'col-view-details',
      minWidth: '100px',
      nowrap: true,
    },
  ]

  const renderRow = (item, index, getStickyClass) => (
    <OrderRecordItem
      item={item}
      index={index}
      isMobile={isMobile}
      setInfoPersonGift={setInfoPersonGift}
      setIsModalGift={setIsModalGift}
      isModalGift={isModalGift}
      totalPriceEachOrder={totalPriceEachOrder}
      toggleModal={toggleModal}
      setDataModal={setDataModal}
      toggleProductDetailsModal={toggleProductDetailsModal}
      getStickyClass={getStickyClass}
      isReversedDropdown={sortListOrders.length >= 3 && index >= sortListOrders.length - 2} // Updated condition
    />
  )

  const calculateTotalPrice = (product) => {
    const subItemsTotal = product.subItems.reduce((sum, item) => sum + (item.price || 0), 0)
    return product.pPriceNoSubItems + subItemsTotal
  }

  const productDetailsHeaders = [
    { label: 'Image', className: 'product-details__header--image' },
    { label: 'Name', className: 'product-details__header--name' },
    { label: 'Price', className: 'product-details__header--price' },
    { label: 'Quantity', className: 'product-details__header--quantity' },
    { label: 'Total', className: 'product-details__header--total' },
  ]

  const renderProductDetailsRow = (product) => (
    <>
      <td className='product-details__cell product-details__cell--image'>
        <img
          src={product.photoUrl || NoImage}
          alt={product.name}
          className='product-details__image'
        />
      </td>
      <td className='product-details__cell product-details__cell--name'>
        <div className='d-flex flex-column'>
          {product.name}
          <RenderSubItems subItems={product.subItems} />
        </div>
      </td>
      <td className='product-details__cell product-details__cell--price'>
        <CurrencyFormat
          value={calculateTotalPrice(product)}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
          decimalScale={2}
          fixedDecimalScale={true}
        />
      </td>
      <td className='product-details__cell product-details__cell--quantity'>{product.quantity}</td>
      <td className='product-details__cell product-details__cell--total'>
        <CurrencyFormat
          value={calculateTotalPrice(product) * product.quantity}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
          decimalScale={2}
          fixedDecimalScale={true}
          className='fw-bold'
        />
      </td>
    </>
  )

  const renderProductDetailsTable = (order) => (
    <CustomTable
      headers={productDetailsHeaders}
      data={order?.allProductPerOrder || []}
      renderRow={renderProductDetailsRow}
      style={{ maxHeight: '380px' }}
    />
  )

  return (
    <ErrorBoundary>
      {isLoading ? (
        <ListOrderRecordSkeleton />
      ) : (
        <div className='list-order-record'>
          <CustomTable
            headers={tableHeaders}
            data={sortListOrders}
            renderRow={renderRow}
            stickyColumns={['No', 'Customer Name']}
          />

          {!isEmpty(dataModal) && (
            <>
              <ModalPayment
                toggleModal={toggleModal}
                isModalOpen={isModalOpen}
                order={dataModal}
                price={totalPriceEachOrder(dataModal)}
                handleInvalidPayment={handleInvalidPayment}
              />
            </>
          )}

          {!isEmpty(infoPersonGift) && (
            <ModalInfoGiftRecipient
              isModalGift={isModalGift}
              setIsModalGift={setIsModalGift}
              infoPersonGift={infoPersonGift}
            />
          )}

          {selectedOrder && (
            <CustomModal
              isOpen={isProductDetailsModalOpen}
              toggle={handleCloseProductDetails}
              title='Order Summary'
              className='product-details-modal'
              footer={
                <FeesSummary order={selectedOrder} totalPriceEachOrder={totalPriceEachOrder} />
              }
            >
              <div className='wrapper-table' style={{ maxHeight: '380px' }}>
                {renderProductDetailsTable(selectedOrder)}
              </div>
            </CustomModal>
          )}
        </div>
      )}
    </ErrorBoundary>
  )
}

ListOrderRecord.propTypes = {
  listOrders: PropTypes.array,
  setSorting: PropTypes.func,
  setTimeRender: PropTypes.func,
  isModalOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
}

export default ListOrderRecord

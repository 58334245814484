import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { Tooltip } from 'reactstrap'

const TruncatedText = ({ text, id, className = '', style = {} }) => {
  const [isOverflowing, setIsOverflowing] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const textRef = useRef(null)

  const safeId = `tooltip-${
    id
      ?.toString()
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '') || Math.random().toString(36).substr(2, 9)
  }`

  useEffect(() => {
    const checkOverflow = () => {
      const element = textRef.current
      if (element) {
        const isTextOverflowing = element.offsetWidth < element.scrollWidth
        setIsOverflowing(isTextOverflowing)
      }
    }

    checkOverflow()
    const timer = setTimeout(checkOverflow, 100)
    window.addEventListener('resize', checkOverflow)

    return () => {
      window.removeEventListener('resize', checkOverflow)
      clearTimeout(timer)
    }
  }, [text])

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen)
  }

  return (
    <div style={{ position: 'relative', width: '100%', ...style }}>
      <span
        className={classNames('truncate', className)}
        ref={textRef}
        id={safeId}
        onMouseEnter={() => isOverflowing && setTooltipOpen(true)}
        onMouseLeave={() => setTooltipOpen(false)}
      >
        {text}
      </span>
      {isOverflowing && (
        <Tooltip
          isOpen={tooltipOpen}
          target={safeId}
          toggle={toggleTooltip}
          placement='top'
          className='custom-tooltip'
          container='body'
          fade={false}
          delay={{ show: 0, hide: 100 }}
          modifiers={{
            offset: {
              offset: '0, 5px',
            },
          }}
        >
          {text}
        </Tooltip>
      )}
    </div>
  )
}

TruncatedText.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default TruncatedText

import React from 'react'
import Skeleton from 'react-loading-skeleton'

const LoadingTableSkeleton = ({ columns, rows = 5 }) =>
  [...Array(rows)].map((_, rowIndex) => (
    <tr key={rowIndex}>
      {columns.map((col, colIndex) => (
        <td key={colIndex}>
          <Skeleton height={20} />
        </td>
      ))}
    </tr>
  ))

export default LoadingTableSkeleton

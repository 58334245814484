import React from 'react'
import Skeleton from 'react-loading-skeleton'

const ShopDetailSkeleton = () => (
  <div className='shop-detail-skeleton'>
    <div className='container'>
      <div className='bg-white shop-detail-body position-relative p-0'>
        {/* Banner Skeleton */}
        <div className='shop-detail-banner mb-4'>
          <div className='shop-detail-banner__header mb-3'>
            <Skeleton height={32} width='50%' />
          </div>
          <Skeleton height={300} className='mb-3' />
          <Skeleton count={2} className='mb-2' />
        </div>

        {/* View Selector Skeleton */}
        <div className='d-flex justify-content-end mb-4'>
          <Skeleton width={100} height={40} className='me-2' />
          <Skeleton width={100} height={40} />
        </div>

        {/* Products Grid Skeleton */}
        <div className='product-container--grid'>
          {Array(8)
            .fill()
            .map((_, idx) => (
              <div key={idx} className='product-card'>
                <Skeleton height={200} className='mb-3' />
                <div className='px-3 pb-3'>
                  <Skeleton height={20} className='mb-2' />
                  <Skeleton height={16} width='70%' className='mb-2' />
                  <Skeleton height={24} width='40%' className='mb-3' />
                  <Skeleton height={40} />
                </div>
              </div>
            ))}
        </div>

        {/* Checkout Button Skeleton */}
        <div className='d-flex justify-content-center mt-4'>
          <Skeleton width='50%' height={50} />
        </div>
      </div>
    </div>
  </div>
)

export default ShopDetailSkeleton

import classNames from 'classnames'
import React from 'react'
import './style.scss'

const NotFoundDataComponent = ({ icon, title, subtitle, style, className }) => (
  <div className={classNames('no-orders-message', className)} style={style || undefined}>
    {React.cloneElement(icon, { className: 'icon' })}
    {title && <div className='title'>{title}</div>}
    <div className='subtitle'>{subtitle}</div>
  </div>
)

export default NotFoundDataComponent

/* eslint-disable no-confusing-arrow */
/* eslint-enable function-paren-newline */
import { useWindowWidth } from '@react-hook/window-size'
import SpecialDealsIcon from '_images/special-deals.png'
import { fetchListPackageByType } from '_redux/modules/package'
import { selectSubscriptionPackage, setCurrentPackage } from '_redux/modules/subscription'
import { LIST_PACKAGE_SUBSCRIPTION_TYPE, PACKAGE_SUBSCRIPTION } from '_utils/constant'
import { getUserInfo } from '_utils/localData'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import ButtonVariation from './components/ButtonVariation'
import ModalCreateSubscriptionPaymentRequest from './components/ModalCreateSubscriptionPaymentRequest'
import SkeletonPackage from './components/SkeletonPackage'
import './style.scss'

const Subscription = () => {
  const dispatch = useDispatch()
  const [nextStep, setNextStep] = useState(1)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [loading, setLoading] = useState(true)

  const userInfo = getUserInfo()
  const history = useHistory()
  const width = useWindowWidth()
  const { pathname } = useLocation()

  const { myShops } = useSelector((state) => state.shop)
  const { subscriptionPackageSelected } = useSelector((state) => state.subscription)
  const { listPackageSubscription } = useSelector((state) => state.package)

  useEffect(() => {
    const getPackage = async () => {
      await dispatch(fetchListPackageByType(PACKAGE_SUBSCRIPTION))
      setLoading(false)
    }

    getPackage()
  }, [])

  useEffect(() => {
    if (pathname === '/') {
      dispatch(setCurrentPackage(null))
    }
  }, [pathname])

  useEffect(() => {
    if (isOpenModal) {
      setNextStep(1)
    }
  }, [isOpenModal])

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal)
  }

  const onSelectPackage = (subscriptionPackage) => {
    if (isEmpty(userInfo)) {
      history.push('/virtual-shop/create')
    } else {
      const listShopActive = myShops.filter((shop) => shop.active)

      if (listShopActive.length === 0) {
        history.push('/virtual-shop/list')
        return
      }

      if (isEmpty(subscriptionPackageSelected) && pathname !== '/') {
        dispatch(selectSubscriptionPackage(subscriptionPackage))
      }

      if (pathname === `/admin-console/${listShopActive[0].shopSlug}/subscription`) {
        toggleModal()
      }

      history.push(`/admin-console/${listShopActive[0].shopSlug}/subscription`)
    }
  }

  const renderFeatureList = (listFeatures) =>
    listFeatures.map((item, index) => <li key={index}>{item}</li>)

  const checkRegistrationLimitReached = (item) => {
    if (item.limit > 0) {
      return item.registerSuccessQty < item.limit
    }

    return item
  }

  const renderSkeletons = () => (
    <>
      <SkeletonPackage />
      <SkeletonPackage />
      <SkeletonPackage />
    </>
  )

  return (
    <div
      className={classNames('subscription h-100 text-left', {
        'subscription--mobile': width <= 768,
        'subscription--desktop': width > 768,
      })}
    >
      <h3
        className={'subscription__title text-center'}
        style={location.pathname === '/' ? { color: '#64748b', fontWeight: 600 } : {}}
      >
        {location.pathname === '/' ? 'Pricing' : 'Subscriptions'}
      </h3>
      <p className={'subscription__subtitle f-16 text-secondary text-center'}>
        Manage subscriptions at any time on your growth journey.
      </p>
      <div className='subscription__packages row f-14 align-items-stretch'>
        {loading ? (
          renderSkeletons()
        ) : listPackageSubscription.length &&
          listPackageSubscription.filter(
            (item) => item.active && checkRegistrationLimitReached(item)
          ).length ? (
          listPackageSubscription
            .filter((item) => item.active && checkRegistrationLimitReached(item))
            .sort((a, b) => a.subscriptionPackageType - b.subscriptionPackageType)
            .map((packageItem, index) => (
              <div className='subscription__package col-12 col-md-4 mb-5 mb-md-0' key={index}>
                <div className='subscription__package-content bg-white rounded shadow-sm p-3 h-100 position-relative d-flex flex-column'>
                  {packageItem.name.toLowerCase() === 'founder' && (
                    <img
                      src={SpecialDealsIcon}
                      alt='special-deals'
                      width={75}
                      className='subscription__special-deals-icon'
                    />
                  )}
                  <p className={'subscription__package-name f-20 mb-0 fw-bold'}>
                    {packageItem.name}
                    {packageItem.limit ? (
                      <span
                        className={'subscription__package-limit ms-2'}
                      >{`(First ${packageItem.limit} Sign-up)`}</span>
                    ) : null}
                  </p>
                  <p
                    className={'subscription__package-description text-secondary mb-0'}
                    style={{ minHeight: 75 }}
                  >
                    {packageItem.description}
                  </p>

                  {packageItem.variations.map((variant, _index) => (
                    <ButtonVariation
                      key={_index}
                      variant={variant}
                      packageItem={packageItem}
                      onSelectPackage={onSelectPackage}
                    />
                  ))}

                  <div className='subscription__package-footer card-footer p-0 bg-transparent border-0 mt-auto'>
                    <p className={'subscription__package-footer-title fw-bold mb-1'}>
                      All you need to get started
                    </p>
                    <ul
                      className={'subscription__package-features features-content text-secondary'}
                    >
                      {renderFeatureList(
                        LIST_PACKAGE_SUBSCRIPTION_TYPE.find(
                          (packItem) => packItem.type === packageItem.subscriptionPackageType
                        ).features
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            ))
        ) : (
          <div className='subscription__no-package'>
            <div className='col-12'>
              <p
                className={
                  'subscription__no-package-message f-20 mb-0 fw-bold text-center text-uppercase text-danger'
                }
              >
                No Subscription Package
              </p>
            </div>
          </div>
        )}
      </div>

      <ModalCreateSubscriptionPaymentRequest
        isOpenModal={isOpenModal}
        toggleModal={toggleModal}
        nextStep={nextStep}
        setNextStep={setNextStep}
      />
    </div>
  )
}

export default Subscription

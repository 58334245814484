import CustomButton from '_components/CustomButton'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const ProductAddAnother = ({ viewMode, product, onAddAnotherOrder }) => (
  <CustomButton
    className={classNames('product-card__add-another', {
      'product-card__add-another--list': viewMode === 'list',
      'product-card__add-another--grid': viewMode === 'grid',
    })}
    text='Add Another Order'
    color='slate'
    onClick={() => onAddAnotherOrder(product.id, product.index)}
  />
)

ProductAddAnother.propTypes = {
  viewMode: PropTypes.oneOf(['list', 'grid']).isRequired,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    isCopy: PropTypes.bool,
  }).isRequired,
  onAddAnotherOrder: PropTypes.func.isRequired,
  onDeleteOrder: PropTypes.func.isRequired,
}

export default ProductAddAnother

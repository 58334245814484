import React from 'react'
import Skeleton from 'react-loading-skeleton'

function OrderRecordItemSkeleton() {
  return (
    <div className='row align-items-center p-3 row-item order-record-item-skeleton'>
      <div className='col'>
        <Skeleton width={40} />
      </div>
      <div className='col'>
        <Skeleton width={120} />
      </div>
      <div className='col'>
        <Skeleton width={100} />
      </div>
      <div className='col-2'>
        <Skeleton count={2} />
      </div>
      <div className='col-2'>
        <Skeleton width={120} height={40} />
      </div>
      <div className='col'>
        <Skeleton width={100} />
      </div>
      <div className='col'>
        <Skeleton width={100} />
      </div>
      <div className='col text-center'>
        <Skeleton width={80} />
      </div>
      <div className='col'>
        <Skeleton width={120} height={35} />
      </div>
    </div>
  )
}

export default OrderRecordItemSkeleton

import SocialButton from '_components/SocialButton'
import { getUserToken } from '_utils/localData'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FACEBOOK, GOOGLE } from '../../utils/constant'

const SignUpOption = ({ setIsSignUpWithPassword, setIsShowFormConnect }) => {
  const [checked, setChecked] = useState(false)
  const userToken = getUserToken()
  const text = !userToken
    ? 'Before launching your site, please setup a login account to manage your shop'
    : 'Account Sign Up'

  return (
    <div className='main-content'>
      <div className='container'>
        <h2
          className={classNames('c-ttl', text ? 'text-center' : '')}
          style={!userToken ? { color: '#0c7a77' } : null}
        >
          {text}
        </h2>
        <div className='c-form c-form--signup'>
          <div className='row'>
            <div className='col-12'>
              <SocialButton
                isLogin={false}
                social={FACEBOOK}
                setIsShowFormConnect={setIsShowFormConnect}
              />
            </div>
            <div className='col-12'>
              <SocialButton
                isLogin={false}
                social={GOOGLE}
                setIsShowFormConnect={setIsShowFormConnect}
              />
            </div>
            <div className='col-12'>
              <button
                className='btn btn--graylight btn--bold'
                href='/sign-up'
                onClick={() => setIsSignUpWithPassword(true)}
              >
                <i className='icon-phone'></i>Sign-Up with Mobile / Email
              </button>
            </div>
          </div>
          <div className='already-account'>
            <p className='text-center mt-3 mb-0' style={{ color: '#51aaaa' }}>
              Already have an account?
              <Link
                to={!userToken ? '/virtual-shop/sign-in' : '/sign-in'}
                className='p-0 bg-transparent shadow-none border-0 text-decoration-underline ms-1'
                style={{ color: '#f06a0f' }}
              >
                Login here
              </Link>
            </p>
          </div>
          <div className='custom-control custom-checkbox'>
            <input
              type='checkbox'
              className='custom-control-input'
              id='customCheck1'
              value={checked}
              onChange={(e) => setChecked(e.target.value)}
              defaultChecked
            />
            <label className='custom-control-label' htmlFor='customCheck1'>
              I want to receive account summary information, special offers and marketing
              communication from Co-Hoot through email or SMS.
            </label>
            <p
              style={{
                fontSize: '14px',
              }}
            >
              By signing up to Co-Hoot, I agree {`${''}Co-Hoot's`}{' '}
              <a href='/policy'>Terms of Service</a> and <a href='/policy'>Privacy Policy</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUpOption

SignUpOption.propTypes = {
  setIsSignUpWithPassword: PropTypes.func,
  setIsShowFormConnect: PropTypes.func,
}

import subscriptionApi from '_api/subscription'
import { setLazadaPackage } from '_redux/modules/lazada'
import { submitPaymentRequestSuccess } from '_redux/modules/package'
import { submitRequestPayment } from '_redux/modules/subscription'
import { HITPAY_PAYMENT_RECURRING_BILLING, PAYMENT_HITPAY } from '_utils/constant'
import { getDataShopDetail } from '_utils/localData'
import { isEmpty } from 'lodash'
import { useState } from 'react'
import { MdPayment } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import CustomButton from '../../CustomButton'
import InputField from '../../InputField'

export function FormHitpay({ toggleModal }) {
  const dispatch = useDispatch()
  const shopDetail = getDataShopDetail()

  const [isChecked, setIsChecked] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { addToast } = useToasts()
  const { subscriptionPackageSelected, paymentMethod, isSubmittedPayment } = useSelector(
    (state) => state.subscription
  )
  const { selectedPackage: lazadaPackageSelected } = useSelector((state) => state.package)

  const handlePaymentRequest = async () => {
    const postData = {
      subId: subscriptionPackageSelected?.id,
      variationId: subscriptionPackageSelected?.variationId,
      paymentType: PAYMENT_HITPAY,
      price: subscriptionPackageSelected?.discountPrice
        ? subscriptionPackageSelected?.discountPrice
        : subscriptionPackageSelected?.variantPrice,
      isRecurringBilling: paymentMethod === HITPAY_PAYMENT_RECURRING_BILLING,
    }

    if (!isChecked) {
      setErrorMessage("You haven't accepted the terms")
      return
    }

    try {
      dispatch(submitRequestPayment(true))
      const { msgResp } = await subscriptionApi.createSubscriptionPaymentRequest(postData)

      if (msgResp.paymentLink) {
        window.open(msgResp.paymentLink, '_blank')
      }
    } catch (error) {
      addToast(error.message || error.msgResp, {
        appearance: 'error',
        autoDismiss: true,
      })
    } finally {
      dispatch(submitRequestPayment(false))
      toggleModal()
    }
  }

  const handleLazadaPaymentRequest = async () => {
    if (!isChecked) {
      setErrorMessage("You haven't accepted the terms")
      return
    }

    try {
      setIsSubmitting(true)
      const postData = {
        lazadaPackageId: lazadaPackageSelected?.id,
        price: lazadaPackageSelected?.price,
        paymentType: PAYMENT_HITPAY,
      }

      const { msgResp } = await subscriptionApi.createLazadaPaymentRequest(shopDetail?.id, postData)
      dispatch(setLazadaPackage(msgResp))

      dispatch(submitPaymentRequestSuccess())

      if (msgResp?.paymentLink) {
        window.open(msgResp.paymentLink, '_blank')
      }
    } catch (error) {
      addToast(error.message || error.msgResp, {
        appearance: 'error',
        autoDismiss: true,
      })
    } finally {
      setIsSubmitting(false)
      toggleModal()
    }
  }

  const onMakePayment = async () => {
    if (!isEmpty(lazadaPackageSelected)) {
      await handleLazadaPaymentRequest()
    } else {
      await handlePaymentRequest()
    }
  }

  const isDisabledBtn = isSubmittedPayment || isSubmitting

  return (
    <div className={'form-hitpay f-16'}>
      <p className={'text-info mb-1'}>
        Please note that once you make a payment using Hitpay, it cannot be reversed.
      </p>
      <div className='form-check mb-3 f-14 ps-0'>
        <InputField
          type='checkbox'
          id='flexCheckDefault'
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
          label='I agree to the terms and conditions'
          error={errorMessage}
        />
      </div>
      <CustomButton
        text={isDisabledBtn ? 'Processing payment ...' : 'Make Payment'}
        onClick={onMakePayment}
        isDisabled={isDisabledBtn}
        variant='contained'
        color='aqua'
        icon={<MdPayment />}
      />
    </div>
  )
}

import { clearLoadedStates, fetchListShopDeleted, fetchMyShops } from '_redux/modules/shop'
import { LIST_SHOP, LIST_SHOP_DELETED } from '_utils/constant'
import React, { useEffect, useMemo, useState } from 'react'
import { FaStore, FaStoreSlash } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import ListShops from './components/ListShops'
import ListShopsDeleted from './components/ListShopsDeleted'
import LoadingSkeletonMyShops from './components/LoadingSkeletonMyShops'
import './style.scss'

function MyShops() {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(LIST_SHOP)

  const { myShops, hasLoadedMyShops, hasLoadedDeletedShops } = useSelector((state) => state.shop)

  useEffect(() => {
    let isMounted = true

    // Clear loaded states when component mounts (page refresh)
    dispatch(clearLoadedStates())

    const fetchData = async () => {
      if (loading) return
      if (hasLoadedMyShops && hasLoadedDeletedShops) return

      try {
        setLoading(true)
        await Promise.all([dispatch(fetchMyShops()), dispatch(fetchListShopDeleted())])
      } catch (error) {
        if (isMounted) {
          addToast(error.message || error.msgResp, { appearance: 'error', autoDismiss: true })
        }
      } finally {
        if (isMounted) {
          setLoading(false)
        }
      }
    }

    fetchData()

    return () => {
      isMounted = false
    }
  }, [])

  const myShopsSorted = useMemo(
    () =>
      myShops.sort((a, b) => {
        const aDate = new Date(a.createdAt).getTime()
        const bDate = new Date(b.createdAt).getTime()
        return bDate - aDate
      }),
    [JSON.stringify(myShops)]
  )

  const tabs = [
    {
      id: LIST_SHOP,
      label: 'List Shops Active',
      icon: FaStore,
      content: (
        <Row>
          <Col sm='12'>
            <ListShops myShops={myShopsSorted} />
          </Col>
        </Row>
      ),
    },
    {
      id: LIST_SHOP_DELETED,
      label: 'List Shops Deleted',
      icon: FaStoreSlash,
      content: (
        <Row>
          <Col sm='12'>
            <ListShopsDeleted />
          </Col>
        </Row>
      ),
    },
  ]

  if (loading) return <LoadingSkeletonMyShops />

  return (
    <div className='my-shops'>
      <Nav tabs className='border-0 column-gap-2 flex-nowrap'>
        {tabs.map((tab) => (
          <NavItem key={tab.id} className='border-0'>
            <NavLink
              className={`my-shop-nav-link cursor-pointer custom-tab border-0 rounded-2 d-flex align-items-center justify-content-center w-100 ${
                activeTab === tab.id ? 'active' : ''
              }`}
              onClick={() => setActiveTab(tab.id)}
            >
              <span>{tab.label}</span>
              <tab.icon className='ms-1 nav-icon' size={16} />
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {tabs.map((tab) => (
          <TabPane key={tab.id} tabId={tab.id}>
            {tab.content}
          </TabPane>
        ))}
      </TabContent>
    </div>
  )
}

export default MyShops

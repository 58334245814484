/* eslint-disable function-paren-newline */
// Import thêm các dependencies cần thiết
import { yupResolver } from '@hookform/resolvers/yup'
import {
  getListFulfillmentByShopId,
  getListTopProductByShopId,
  getListTotalAverageOrderPerDatByShopId,
  getListTotalOrdersPerDatByShopId,
  getListTotalSalesPerDatByShopId,
  setDateWhenSyncData,
} from '_redux/modules/dashboard'
import { updateNewListProduct } from '_redux/modules/product'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import 'react-datepicker/dist/react-datepicker.css'
import { useToasts } from 'react-toast-notifications'
import FulfillChart from './components/FulfillChart'
import ModalProductCheckbox from './components/ModalProductCheckbox'
import ModalSyncDate from './components/ModalSyncDate'
import OrderChart from './components/OrderChart'
import SaleChart from './components/SaleChart'
import TimeFilterSelect from './components/TimeFilterSelect'
import TopProductChart from './components/TopProductChart'
import './style.scss'

// Thay đổi schema validation thành đơn giản hơn, chỉ bắt buộc chọn ngày
const schema = yup.object().shape({
  startDate: yup.date().nullable().required('Start date is required'),
  endDate: yup.date().nullable().required('End date is required'),
})

const nameChart1 = 'sales'
const nameChart2 = 'orders'
// const nameChart3 = 'averageOrder'

function Dashboard() {
  const dispatch = useDispatch()
  const [listTotalSales, setListTotalSales] = useState([])

  const [listTotalOrders, setListTotalOrders] = useState([])
  // const [listTotalAverageOrder, setListTotalAverageOrder] = useState([])
  const [listTopProduct, setListTopProduct] = useState([])

  const { selectedShop } = useSelector((state) => state.shop)
  const {
    listFulfillmentByShopId,
    listTotalSalesPerDatByShopId,
    listTotalOrdersPerDatByShopId,
    listTopProductByShopId,
    dateWhenSyncData,
  } = useSelector((state) => state.dashBoard)
  const { listProduct } = useSelector((state) => state.product)

  const { addToast } = useToasts()
  const [show, setShow] = useState(false)
  const [isMounted, setIsMounted] = useState(false)
  const [typeShowData, SetTypeShowData] = useState('weeks')

  const [listTotalSaleAfterSort, setListTotalSaleAfterSort] = useState()
  const [listTotalOrdersAfterSort, setListTotalOrdersAfterSort] = useState()
  // const [listTotalAverageOrdersAfterSort, setListTotalAverageOrdersAfterSort] = useState()

  const [listProductSelected, setListProductSelected] = useState([])
  const [isOpenModalProductCheckboxOpen, setIsOpenModalProductCheckboxOpen] = useState(false)
  const [loadingFulfill, setLoadingFulfill] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      startDate: null,
      endDate: null,
    },
    mode: 'all',
  })

  useEffect(() => {
    if (selectedShop?.id && selectedShop?.id !== '') {
      dispatch(updateNewListProduct(selectedShop.products))
    }
  }, [JSON.stringify(selectedShop)])

  useEffect(() => {
    if (listProduct.length) {
      const sortedListProduct = [...listProduct].sort((a, b) =>
        moment(a.createdAt).diff(moment(b.createdAt))
      )
      setListProductSelected(sortedListProduct.slice(0, 3))
    }
  }, [JSON.stringify(listProduct)])

  const toggleModalProductCheckbox = () => {
    setIsOpenModalProductCheckboxOpen(!isOpenModalProductCheckboxOpen)
  }

  const toggleModal = () => {
    setShow(!show)
  }

  const handleCheckInDate = (date) => {
    setValue('startDate', date)
  }

  const handleCheckOutDate = (date) => {
    setValue('endDate', date)
  }

  const handleClearDuplicateInArray = (data, duplicateItem) => {
    if (duplicateItem === 'weeks') {
      const uniqueIDate = []
      const unique = data.filter((element) => {
        const isDuplicate = uniqueIDate.includes(element.weekInYear)

        if (!isDuplicate) {
          uniqueIDate.push(element.weekInYear)
          return true
        }

        return false
      })
      return unique
    }
    if (duplicateItem === 'months') {
      const uniqueIDate = []
      const unique = data.filter((element) => {
        const isDuplicate = uniqueIDate.includes(element.monthInhYear)

        if (!isDuplicate) {
          uniqueIDate.push(element.monthInhYear)
          return true
        }

        return false
      })
      return unique
    }
    const uniqueIDate = []
    const unique = data.filter((element) => {
      const isDuplicate = uniqueIDate.includes(element.year)

      if (!isDuplicate) {
        uniqueIDate.push(element.year)
        return true
      }

      return false
    })
    return unique
  }

  const handleSyncDate = handleSubmit(async (data) => {
    try {
      const startDate = moment(data.startDate).format('DD-MM-YYYY 00:00')
      const endDate = moment(data.endDate).format('DD-MM-YYYY 00:00')

      const requestData = {
        startDate,
        endDate,
        shopId: selectedShop?.id,
      }

      dispatch(
        setDateWhenSyncData({
          startDate: moment(data.startDate).format('DD-MM-YYYY'),
          endDate: moment(data.endDate).format('DD-MM-YYYY'),
          shopId: selectedShop?.id,
        })
      )

      dispatch(getListTotalSalesPerDatByShopId(requestData))
      dispatch(getListTotalOrdersPerDatByShopId(requestData))
      dispatch(getListTotalAverageOrderPerDatByShopId(requestData))
      dispatch(getListTopProductByShopId(requestData))

      setShow(false)
    } catch (error) {
      addToast(error.message || 'Something went wrong', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  })

  const handleSortDataByWeek = (data, setData, dataReset, nameChart) => {
    const dataAfterFilter = []
    const dataByWeek = []
    for (let i = 0; i < data.length; i++) {
      dataAfterFilter.push(dataReset?.filter((item) => data[i]?.weekInYear === item?.weekInYear))
    }
    for (let k = 0; k < dataAfterFilter.length; k++) {
      let totalValue
      if (nameChart === nameChart1) {
        totalValue = dataAfterFilter[k].flat().reduce((a, b) => a + b.totalPrice, 0)
      } else if (nameChart === nameChart2) {
        totalValue = dataAfterFilter[k].flat().reduce((a, b) => a + b.totalOrders, 0)
      } else {
        totalValue = dataAfterFilter[k].flat().reduce((a, b) => a + b.averageOrderSize, 0)
      }
      dataByWeek.push({
        date: dataAfterFilter[k].flat()[0]?.date,
        weekInYear: dataAfterFilter[k].flat()[0]?.weekInYear,
        totalValue,
        typeShowData: 'weeks',
      })
    }
    setData(dataByWeek)
  }

  const handleSortDataByMonth = (data, setData, dataReset, nameChart) => {
    const dataAfterFilter = []
    const dataByMonth = []
    for (let i = 0; i < data.length; i++) {
      dataAfterFilter.push(dataReset.filter((item) => data[i].monthInhYear === item.monthInhYear))
    }
    for (let k = 0; k < dataAfterFilter.length; k++) {
      let totalValue
      if (nameChart === nameChart1) {
        totalValue = dataAfterFilter[k].flat().reduce((a, b) => a + b.totalPrice, 0)
      } else if (nameChart === nameChart2) {
        totalValue = dataAfterFilter[k].flat().reduce((a, b) => a + b.totalOrders, 0)
      } else {
        totalValue = dataAfterFilter[k].flat().reduce((a, b) => a + b.averageOrderSize, 0)
      }
      dataByMonth.push({
        date: dataAfterFilter[k].flat()[0]?.date,
        monthInhYear: dataAfterFilter[k].flat()[0].monthInhYear,
        totalValue,
        typeShowData: 'months',
      })
    }
    setData(dataByMonth)
  }

  const handleSortDataByYear = (data, setData, dataReset, nameChart) => {
    const dataAfterFilter = []
    const dataByMonth = []
    for (let i = 0; i < data.length; i++) {
      dataAfterFilter.push(dataReset.filter((item) => data[i].year === item.year))
    }
    for (let k = 0; k < dataAfterFilter.length; k++) {
      let totalValue
      if (nameChart === nameChart1) {
        totalValue = dataAfterFilter[k].flat().reduce((a, b) => a + b.totalPrice, 0)
      } else if (nameChart === nameChart2) {
        totalValue = dataAfterFilter[k].flat().reduce((a, b) => a + b.totalOrders, 0)
      } else {
        totalValue = dataAfterFilter[k].flat().reduce((a, b) => a + b.averageOrderSize, 0)
      }
      dataByMonth.push({
        date: dataAfterFilter[k].flat()[0]?.date,
        year: dataAfterFilter[k].flat()[0].year,
        totalValue,
        typeShowData: 'years',
      })
    }
    setData(dataByMonth)
  }

  const handleOnChangeSelect = (e) => {
    SetTypeShowData(e.target.value)
    if (
      listTotalSales.length > 0 &&
      listTotalOrders.length > 0
      // &&      listTotalAverageOrder.length > 0
    ) {
      switch (e.target.value) {
        case 'weeks':
          handleSortDataByWeek(
            handleClearDuplicateInArray(listTotalSales, e.target.value),
            setListTotalSaleAfterSort,
            listTotalSales,
            nameChart1
          )
          handleSortDataByWeek(
            handleClearDuplicateInArray(listTotalOrders, e.target.value),
            setListTotalOrdersAfterSort,
            listTotalOrders,
            nameChart2
          )
          // handleSortDataByWeek(
          //   handleClearDuplicateInArray(listTotalAverageOrder, e.target.value),
          //   setListTotalAverageOrdersAfterSort,
          //   listTotalAverageOrder,
          //   nameChart3
          // )
          break
        case 'months':
          handleSortDataByMonth(
            handleClearDuplicateInArray(listTotalSales, e.target.value),
            setListTotalSaleAfterSort,
            listTotalSales,
            nameChart1
          )
          handleSortDataByMonth(
            handleClearDuplicateInArray(listTotalOrders, e.target.value),
            setListTotalOrdersAfterSort,
            listTotalOrders,
            nameChart2
          )
          // handleSortDataByMonth(
          //   handleClearDuplicateInArray(listTotalAverageOrder, e.target.value),
          //   setListTotalAverageOrdersAfterSort,
          //   listTotalAverageOrder,
          //   nameChart3
          // )
          break
        case 'years':
          handleSortDataByYear(
            handleClearDuplicateInArray(listTotalSales, e.target.value),
            setListTotalSaleAfterSort,
            listTotalSales,
            nameChart1
          )
          handleSortDataByYear(
            handleClearDuplicateInArray(listTotalOrders, e.target.value),
            setListTotalOrdersAfterSort,
            listTotalOrders,
            nameChart2
          )
          // handleSortDataByYear(
          //   handleClearDuplicateInArray(listTotalAverageOrder, e.target.value),
          //   setListTotalAverageOrdersAfterSort,
          //   listTotalAverageOrder,
          //   nameChart3
          // )
          break
        default:
          break
      }
    }
  }

  useEffect(() => {
    if (selectedShop?.id) {
      const fetchData = async () => {
        try {
          setLoadingFulfill(true)
          await dispatch(getListFulfillmentByShopId(selectedShop?.id))
        } catch (error) {
          addToast(error.message || error.msgResp, { appearance: 'error', autoDismiss: true })
        } finally {
          setLoadingFulfill(false)
        }
      }

      fetchData()
    }
  }, [selectedShop?.id])

  useEffect(() => {
    if (dateWhenSyncData) {
      setValue('startDate', moment(dateWhenSyncData.startDate, 'DD-MM-YYYY').toDate())
      setValue('endDate', moment(dateWhenSyncData.endDate, 'DD-MM-YYYY').toDate())
    }
  }, [JSON.stringify(dateWhenSyncData), selectedShop?.shopSlug])

  useEffect(() => {
    setIsMounted(true)
    if (isMounted) {
      const dataAfterReset = []
      if (listTotalSalesPerDatByShopId?.length) {
        listTotalSalesPerDatByShopId?.map((item) =>
          dataAfterReset.push({
            ...item,
            monthInhYear: item.date.slice(3, 10),
            weekInYear: `${moment(item.date, 'DD-MM-YYYY').week()}-${item.date.slice(6, 10)}`,
            year: item.date.slice(6, 10),
          })
        )
        setListTotalSales(dataAfterReset)
      }

      if (dataAfterReset?.length > 0) {
        if (typeShowData === 'weeks') {
          handleSortDataByWeek(
            handleClearDuplicateInArray(dataAfterReset, typeShowData),
            setListTotalSaleAfterSort,
            dataAfterReset,
            nameChart1
          )
        } else if (typeShowData === 'months') {
          handleSortDataByMonth(
            handleClearDuplicateInArray(dataAfterReset, typeShowData),
            setListTotalSaleAfterSort,
            dataAfterReset,
            nameChart1
          )
        } else if (typeShowData === 'years') {
          handleSortDataByYear(
            handleClearDuplicateInArray(dataAfterReset, typeShowData),
            setListTotalSaleAfterSort,
            dataAfterReset,
            nameChart1
          )
        } else {
          handleSortDataByWeek(
            handleClearDuplicateInArray(dataAfterReset, 'weeks'),
            setListTotalSaleAfterSort,
            dataAfterReset,
            nameChart1
          )
        }
      }
    }

    // Clean up function
    return () => {
      setIsMounted(false)
      setListTotalSales([])
      setListTotalSaleAfterSort([])
    }
  }, [JSON.stringify(listTotalSalesPerDatByShopId), selectedShop?.shopSlug, isMounted])

  useEffect(() => {
    setIsMounted(true)
    if (isMounted) {
      const dataAfterReset = []
      if (listTotalOrdersPerDatByShopId?.length) {
        listTotalOrdersPerDatByShopId?.map((item) =>
          dataAfterReset.push({
            ...item,
            monthInhYear: item.date.slice(3, 10),
            weekInYear: `${moment(item.date, 'DD-MM-YYYY').week()}-${item.date.slice(6, 10)}`,
            year: item.date.slice(6, 10),
          })
        )
        setListTotalOrders(dataAfterReset)
      }

      if (dataAfterReset?.length > 0) {
        if (typeShowData === 'weeks') {
          handleSortDataByWeek(
            handleClearDuplicateInArray(dataAfterReset, typeShowData),
            setListTotalOrdersAfterSort,
            dataAfterReset,
            nameChart2
          )
        } else if (typeShowData === 'months') {
          handleSortDataByMonth(
            handleClearDuplicateInArray(dataAfterReset, typeShowData),
            setListTotalOrdersAfterSort,
            dataAfterReset,
            nameChart2
          )
        } else if (typeShowData === 'years') {
          handleSortDataByYear(
            handleClearDuplicateInArray(dataAfterReset, typeShowData),
            setListTotalOrdersAfterSort,
            dataAfterReset,
            nameChart2
          )
        } else {
          handleSortDataByWeek(
            handleClearDuplicateInArray(dataAfterReset, 'weeks'),
            setListTotalOrdersAfterSort,
            dataAfterReset,
            nameChart2
          )
        }
      }
    }
    // Clean up function
    return () => {
      setIsMounted(false)
      setListTotalOrders([])
      setListTotalOrdersAfterSort([])
    }
  }, [JSON.stringify(listTotalOrdersPerDatByShopId), selectedShop?.shopSlug, isMounted])

  // useEffect(() => {
  //   setIsMounted(true)
  //   if (isMounted) {
  //     const dataAfterReset = []
  //     if (listTotalAverageOrderPerDatByShopId?.length) {
  //       listTotalAverageOrderPerDatByShopId?.map((item) =>
  //         dataAfterReset.push({
  //           ...item,
  //           monthInhYear: item.date.slice(3, 10),
  //           weekInYear: `${moment(item.date, 'DD-MM-YYYY').week()}-${item.date.slice(6, 10)}`,
  //           year: item.date.slice(6, 10),
  //         })
  //       )
  //       setListTotalAverageOrder(dataAfterReset)
  //     }
  //     if (dataAfterReset?.length > 0) {
  //       if (typeShowData === 'weeks') {
  //         handleSortDataByWeek(
  //           handleClearDuplicateInArray(dataAfterReset, typeShowData),
  //           setListTotalAverageOrdersAfterSort,
  //           dataAfterReset,
  //           nameChart3
  //         )
  //       } else if (typeShowData === 'months') {
  //         handleSortDataByMonth(
  //           handleClearDuplicateInArray(dataAfterReset, typeShowData),
  //           setListTotalAverageOrdersAfterSort,
  //           dataAfterReset,
  //           nameChart3
  //         )
  //       } else if (typeShowData === 'years') {
  //         handleSortDataByYear(
  //           handleClearDuplicateInArray(dataAfterReset, typeShowData),
  //           setListTotalAverageOrdersAfterSort,
  //           dataAfterReset,
  //           nameChart3
  //         )
  //       } else {
  //         handleSortDataByWeek(
  //           handleClearDuplicateInArray(dataAfterReset, 'weeks'),
  //           setListTotalAverageOrdersAfterSort,
  //           dataAfterReset,
  //           nameChart3
  //         )
  //       }
  //     }
  //   }
  //   // Clean up function
  //   return () => {
  //     setIsMounted(false)
  //     setListTotalAverageOrder([])
  //     setListTotalAverageOrdersAfterSort([])
  //   }
  // }, [JSON.stringify(listTotalAverageOrderPerDatByShopId), selectedShop?.shopSlug, isMounted])

  useEffect(() => {
    setIsMounted(true)
    if (isMounted) {
      setListTopProduct(listTopProductByShopId)
    }
    // Clean up function
    return () => {
      setIsMounted(false)
      setListTopProduct([])
    }
  }, [listTopProductByShopId?.length, selectedShop?.shopSlug, isMounted])

  useEffect(() => {
    setIsMounted(true)
    if (isMounted && selectedShop?.shopSlug) {
      const autoCallApiWhenComponentBeMount = () => {
        const lastMonth = moment()
          .subtract(1, 'months')
          .startOf('month')
          .format('DD-MM-YYYY 00:00')
          .replace('01', moment().format('DD'))
        const currentDate = moment().format('DD-MM-YYYY 23:59')

        const data = {
          startDate: lastMonth,
          endDate: currentDate,
          shopId: selectedShop?.id,
        }

        dispatch(
          setDateWhenSyncData({
            startDate: moment()
              .subtract(1, 'months')
              .startOf('month')
              .format('DD-MM-YYYY')
              .replace('01', moment().format('DD')),
            endDate: moment().format('DD-MM-YYYY'),
            shopId: selectedShop?.id,
          })
        )
        dispatch(getListTotalSalesPerDatByShopId(data))
        dispatch(getListTotalOrdersPerDatByShopId(data))
        dispatch(getListTotalAverageOrderPerDatByShopId(data))
        dispatch(getListTopProductByShopId(data))
      }
      autoCallApiWhenComponentBeMount()
    }
    // Clean up function
    return () => {
      setIsMounted(false)
      setListTotalSales([])
      setListTotalOrders([])
      // setListTotalAverageOrder([])
      setListTopProduct([])

      setListTotalSaleAfterSort([])
      setListTotalOrdersAfterSort([])
      // setListTotalAverageOrdersAfterSort([])

      dispatch(setDateWhenSyncData(null))

      setShow(false)

      setValue('startDate', null)
      setValue('endDate', null)
      SetTypeShowData('weeks')
    }
  }, [
    selectedShop?.shopSlug,
    isMounted,
    dispatch,
    // listTotalSalesPerDatByShopId?.length, --> đoạn này làm thay đổi ngày chọn
  ])

  return (
    <>
      <div className='dashboard__desktop'>
        <div className='dashboard__desktop-head'>
          <h3 className='dashboard__desktop-head-title'>Dashboard</h3>
          <div className='dashboard__desktop-head-sync'>
            <div className='dashboard__desktop-head-sync-date' onClick={toggleModal}>
              <span className='dashboard__desktop-head-sync-date-start'>
                {dateWhenSyncData?.startDate}
              </span>{' '}
              -
              <span className='dashboard__desktop-head-sync-date-end'>
                {dateWhenSyncData?.endDate}
              </span>
            </div>
            <div className='dashboard__desktop-head-sync-week shadow-none'>
              <TimeFilterSelect value={typeShowData} onChange={(e) => handleOnChangeSelect(e)} />
            </div>
          </div>
        </div>
        <div className='dashboard__desktop-chart'>
          <div className='row chart-layout'>
            <div className='dashboard__desktop-sale-chart shadow-sm'>
              <p>How are my sales ?</p>
              <SaleChart data={listTotalSaleAfterSort} />
            </div>
            <div className='dashboard__desktop-order-chart shadow-sm'>
              <p>How many orders ?</p>
              <OrderChart data={listTotalOrdersAfterSort} />
            </div>
          </div>
          <div className='row chart-layout'>
            <div className='dashboard__desktop-average-chart shadow-sm'>
              <p>Fulfillments Required for the next 7 days</p>
              {/* <AverageChart data={listTotalAverageOrdersAfterSort} /> */}
              <FulfillChart
                listFulfillmentByShopId={listFulfillmentByShopId}
                toggleModalProductCheckbox={toggleModalProductCheckbox}
                listProductSelected={listProductSelected}
                loadingFulfill={loadingFulfill}
              />
            </div>
            <div className='dashboard__desktop-topProduct-chart shadow-sm'>
              <p>What are my top products ?</p>
              <TopProductChart data={listTopProduct} />
            </div>
          </div>
        </div>
      </div>

      <ModalSyncDate
        show={show}
        toggleModal={toggleModal}
        handleSyncDate={handleSyncDate}
        handleCheckInDate={handleCheckInDate}
        handleCheckOutDate={handleCheckOutDate}
        control={control}
        errors={errors}
      />

      <ModalProductCheckbox
        isOpenModalProductCheckboxOpen={isOpenModalProductCheckboxOpen}
        toggleModalProductCheckbox={toggleModalProductCheckbox}
        listProductSelected={listProductSelected}
        setListProductSelected={setListProductSelected}
      />
    </>
  )
}

export default Dashboard

import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

function CustomModal({
  isOpen,
  toggle,
  title,
  children,
  footer,
  className = '',
  hideFooter = false,
  ...props
}) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className={className} {...props}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>

      {!hideFooter && <ModalFooter>{footer}</ModalFooter>}
    </Modal>
  )
}

export default CustomModal

/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
import InputField from '_components/InputField'
import { DEFAULT_OPTION, DEFAULT_SUB_ITEM } from '_utils/constant'
import React from 'react'
import CurrencyFormat from 'react-currency-format'
import { useFieldArray } from 'react-hook-form'
import { FaCog, FaCopy, FaTrash } from 'react-icons/fa'
import { MdOutlineAttachMoney } from 'react-icons/md'

const SubItem = (props) => {
  const {
    control,
    register,
    nestIndex,
    productIndex,
    removeSubItem,
    insertSubItem,
    subItem,
    appendSubItem,
    watch,
    errors,
    setValue,
  } = props
  const {
    fields,
    remove: removeOption,
    append: appendOption,
  } = useFieldArray({
    control,
    name: `products.${productIndex}.subItems.${nestIndex}.list`,
  })
  const isError = !!errors?.products?.[productIndex]?.subItems?.[nestIndex]?.list
  const watchFieldArray = watch(`products.${productIndex}.subItems.${nestIndex}.list`)
  const controlledFields =
    watchFieldArray?.length > 0
      ? fields.map((field, _index) => ({
          ...field,
          ...watchFieldArray[_index],
        }))
      : []

  return (
    <div className='sub-item c-form is-active'>
      <div className='sub-item-div'>
        <div className='row'>
          <div className='col-3 align-self-center col-index'>Sub item {nestIndex + 1}</div>
          <div className='col-9'>
            <InputField
              type='text'
              placeholder='Add Category'
              register={register(`products.${productIndex}.subItems.${nestIndex}.type`)}
              error={errors?.products?.[productIndex]?.subItems?.[nestIndex]?.type?.message}
            />
          </div>
        </div>
        {controlledFields?.map((_item, index) => (
          <div
            className={`row sub-item-option align-items-${isError ? 'start' : 'center'}`}
            key={index}
          >
            <div className='sub-item-info col-6 ps-4'>
              <span className='sub-txt'>{index + 1}. </span>
              <InputField
                type='text'
                placeholder='Name'
                register={register(
                  `products.${productIndex}.subItems.${nestIndex}.list.${index}.name`
                )}
                error={
                  errors?.products?.[productIndex]?.subItems?.[nestIndex]?.list?.[index]?.name
                    ?.message
                }
              />
            </div>
            <div className='sub-item-info col-5'>
              <InputField
                icon={<MdOutlineAttachMoney />}
                placeholder='Price'
                as={CurrencyFormat}
                // register={register(
                //   `products.${productIndex}.subItems.${nestIndex}.list.${index}.price`
                // )}
                value={controlledFields?.[index]?.price}
                thousandSeparator
                decimalScale={2}
                onValueChange={({ value }) =>
                  setValue(
                    `products.${productIndex}.subItems.${nestIndex}.list.${index}.price`,
                    value
                  )
                }
                error={
                  errors?.products?.[productIndex]?.subItems?.[nestIndex]?.list?.[index]?.price
                    ?.message
                }
              />
            </div>
            <div className='col-1'>
              <FaTrash
                className='sub-item__action-btn product-item__action-btn--remove'
                onClick={() => removeOption(index)}
              />
            </div>
          </div>
        ))}
        <div className='row'>
          <div className='sub-item-info col-12 ps-0'>
            <a onClick={() => appendOption({ ...DEFAULT_OPTION })}>Add Option</a>
          </div>
        </div>
      </div>
      <div className='sub-item-div sub-item-div-last'>
        <div className='row'>
          <div className='col-6 ps-0'>
            <InputField
              label='Required'
              isSwitch
              register={register(`products.${productIndex}.subItems.${nestIndex}.required`)}
              labelStyle={{ marginBottom: 0, marginRight: '0.25rem' }}
              className='d-flex align-items-center'
            />
          </div>
          <div className='col-6'>
            <div className='d-flex justify-content-end'>
              <FaCog
                className='sub-item__action-btn product-item__action-btn--add'
                onClick={() => appendSubItem(JSON.parse(JSON.stringify({ ...DEFAULT_SUB_ITEM })))}
              />
              <FaCopy
                className='sub-item__action-btn product-item__action-btn--copy'
                onClick={() =>
                  insertSubItem(
                    parseInt(nestIndex + 1, 10),
                    JSON.parse(JSON.stringify({ ...subItem }))
                  )
                }
              />
              <FaTrash
                className='sub-item__action-btn product-item__action-btn--remove'
                onClick={() => removeSubItem(nestIndex)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubItem

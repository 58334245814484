import CustomButton from '_components/CustomButton'
import DatePicker from 'react-datepicker'
import { Controller } from 'react-hook-form'
import { FaRegCalendarAlt } from 'react-icons/fa'
import { IoSync } from 'react-icons/io5'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const ModalSyncDate = ({
  show,
  toggleModal,
  handleSyncDate,
  handleCheckInDate,
  handleCheckOutDate,
  control,
  errors,
}) => (
  <Modal isOpen={show} toggle={toggleModal} centered className='modal-sync-data'>
    <ModalHeader toggle={toggleModal}>
      <span className='c-txt-gra sync-data-title'>Sync Data</span>
    </ModalHeader>
    <ModalBody>
      <form onSubmit={handleSyncDate}>
        <div className='datePicker'>
          <div className='datePicker__startDay'>
            <div>
              <b>Start Day</b>
            </div>
            <div className='datepicker-container'>
              <div className='datepicker-input-container'>
                <Controller
                  control={control}
                  name='startDate'
                  render={({ field }) => (
                    <DatePicker
                      selected={field.value}
                      onChange={(date) => {
                        field.onChange(date)
                        handleCheckInDate(date)
                      }}
                      onBlur={field.onBlur}
                      placeholderText='DD-MM-YYYY'
                      className={`form-control-sm w-100 border ${
                        errors.startDate ? 'is-invalid' : ''
                      }`}
                      dateFormat='dd/MM/yyyy'
                    />
                  )}
                />
                <div className='calendar-icon-wrapper'>
                  <FaRegCalendarAlt className='calendar-icon' />
                </div>
              </div>
              {errors.startDate && (
                <div className='invalid-feedback d-block'>{errors.startDate.message}</div>
              )}
            </div>
          </div>

          <div className='datePicker__endDay'>
            <div>
              <b>End Day</b>
            </div>
            <div className='datepicker-container'>
              <div className='datepicker-input-container'>
                <Controller
                  control={control}
                  name='endDate'
                  render={({ field }) => (
                    <DatePicker
                      selected={field.value}
                      onChange={(date) => {
                        field.onChange(date)
                        handleCheckOutDate(date)
                      }}
                      onBlur={field.onBlur}
                      className={`form-control-sm w-100 border ${
                        errors.endDate ? 'is-invalid' : ''
                      }`}
                      placeholderText='DD-MM-YYYY'
                      dateFormat='dd/MM/yyyy'
                    />
                  )}
                />
                <div className='calendar-icon-wrapper'>
                  <FaRegCalendarAlt className='calendar-icon' />
                </div>
              </div>
              {errors.endDate && (
                <div className='invalid-feedback d-block'>{errors.endDate.message}</div>
              )}
            </div>
          </div>
        </div>
        <div className='sync-data-btn'>
          <CustomButton
            text='Sync data'
            color='aqua'
            icon={<IoSync />}
            className='w-100'
            type='submit'
            variant='contained'
          />
        </div>
      </form>
    </ModalBody>
  </Modal>
)

export default ModalSyncDate

/* eslint-disable no-confusing-arrow */
import CustomButton from '_components/CustomButton'
import {
  BUYER_ORDER_CANCELED,
  BUYER_ORDER_PAID,
  BUYER_ORDER_UNPAID,
  DELIVERY_OPTION,
  HOST_ORDER_FULFILLED,
  HOST_ORDER_VERIFIED,
  NO_FULFILLMENT,
  SELF_PICK_UP,
  VISIBLE,
} from '_utils/constant'
import { maskString } from '_utils/function'
import { getUserInfo, setCartOrderDetailsByRef } from '_utils/localData'
import moment from 'moment'
import React from 'react'
import { AiFillGift } from 'react-icons/ai'
import { IoMdExit } from 'react-icons/io'
import { TiCancel } from 'react-icons/ti'
import { useHistory } from 'react-router-dom'

const grayStyle = {
  filter: 'grayscale(100%)',
}

const statusColorMap = {
  [BUYER_ORDER_CANCELED]: {
    background: 'rgba(255, 0, 0, 0.1)',
    color: 'red',
    border: '1px solid red'
  },
  [BUYER_ORDER_UNPAID]: {
    background: 'rgba(255, 193, 7, 0.1)',
    color: '#ffc107',
    border: '1px solid #ffc107'
  },
  [BUYER_ORDER_PAID]: {
    background: 'rgba(0, 128, 0, 0.1)',
    color: 'green',
    border: '1px solid green'
  },
  [HOST_ORDER_VERIFIED]: {
    background: 'rgba(78, 151, 246, 0.1)',
    color: 'rgb(78, 151, 246)',
    border: '1px solid rgb(78, 151, 246)'
  },
  [HOST_ORDER_FULFILLED]: {
    background: 'rgba(143, 71, 244, 0.1)',
    color: 'rgb(143, 71, 244)',
    border: '1px solid rgb(143, 71, 244)'
  },
}

const fulfillmentTypeMap = {
  [DELIVERY_OPTION]: 'Delivery',
  [SELF_PICK_UP]: 'Self pickup',
  [NO_FULFILLMENT]: 'No fulfillment',
}

function PurchaseItem({ item, cancelPurchase }) {
  const history = useHistory()
  const userInfo = getUserInfo()

  const directToPayment = (_item) => {
    setCartOrderDetailsByRef({ ref: _item.ref, email: _item.uEmail })
    history.push(`/virtual-shop/payment-code/${_item.ref}`, { from: 'my-purchase' })
  }

  const handleCardClick = (e) => {
    // Prevent click if target is a button
    if (e.target.closest('.button-group')) return
    directToPayment(item)
  }

  return (
    <>
      {(item.buyerStatus === BUYER_ORDER_UNPAID || item.visible === VISIBLE) && (
        <div
          className='purchase-item'
          style={{
            ...(item.status === BUYER_ORDER_CANCELED ? grayStyle : {}),
            cursor: 'pointer',
          }}
          onClick={handleCardClick}
        >
          <div className='item-header'>
            <div className='d-flex justify-content-between align-items-start'>
              <div className='shop-name'>{item.shopName.slice(0, 100)}</div>
              <div className='badge-container'>
                {item.cashOnDeliveryPickup && (
                  <span className='badge fulfillment-badge'>
                    {item.fulfilmentType === DELIVERY_OPTION ? 'COD' : 'COP'}
                  </span>
                )}
                <span
                  className={'badge status-badge'}
                  style={{
                    backgroundColor: statusColorMap[item?.status]?.background,
                    color: statusColorMap[item?.status]?.color,
                    border: statusColorMap[item?.status]?.border
                  }}
                >
                  {item?.status === BUYER_ORDER_CANCELED && 'Cancelled'}
                  {item?.status === BUYER_ORDER_UNPAID && 'Pending'}
                  {item?.status === BUYER_ORDER_PAID && 'Paid'}
                  {item?.status === HOST_ORDER_VERIFIED && 'Verified'}
                  {item?.status === HOST_ORDER_FULFILLED && 'Completed'}
                </span>
                <span className='badge fulfillment-badge'>
                  {fulfillmentTypeMap[item.fulfilmentType]}
                </span>
                {item?.deliveryDetail?.giftRecipientInfo && (
                  <AiFillGift className='gift-icon text-danger' />
                )}
              </div>
            </div>
          </div>

          <div className='order-info'>
            <span className='order-number'>
              Order: {maskString(item.ref, 6).replace(/\*/g, '')}
            </span>
          </div>

          <div className='item-footer'>
            <div className='date-order'>
              Created: {moment(item.createdAt * 1000).format('DD/MM/YYYY, h:mm:ss a')}
            </div>
            <div className='button-group'>
              {userInfo?.id && item?.status === BUYER_ORDER_UNPAID && (
                <CustomButton
                  icon={<TiCancel />}
                  text='Cancel'
                  onClick={() => cancelPurchase(item)}
                  variant='outlined'
                  color='slate'
                  className='mt-0'
                />
              )}
              {item?.status === BUYER_ORDER_UNPAID && (
                <CustomButton
                  icon={<IoMdExit />}
                  text={item?.cashOnDeliveryPickup ? 'Edit' : 'Confirm'}
                  onClick={() => directToPayment(item)}
                  variant='contained'
                  color='aqua'
                  className='mt-0'
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PurchaseItem

import { clearCart, clearOrderDetailsByRef } from '_redux/modules/order'
import { removeCreateShopData } from '_utils/localData'
import React, { useState } from 'react'
import { AiFillShop } from 'react-icons/ai'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useToasts } from 'react-toast-notifications'
import { Tooltip } from 'reactstrap'
import { clearDelivery, clearPickup } from '../../redux/modules/fulfillment'
import { clearShopAndShopDetail } from '../../redux/modules/shop'
import { getUserInfo } from '../../utils/localData'
import CustomSwalFire from '../CustomSwalFire'
import './style.scss'

function ButtonAdminConsole({ style, loading }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const userInfo = getUserInfo()
  const currentPath = location.pathname

  const { addToast } = useToasts()
  const { cart, orderDetailsByRef } = useSelector((state) => state.order)
  const myShops = JSON.parse(localStorage.getItem('myShops') || '[]')

  const [tooltipOpen, setTooltipOpen] = useState(false)

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen)

  const onClearCart = () => {
    dispatch(clearOrderDetailsByRef())
    dispatch(clearCart())
    dispatch(clearPickup)
    dispatch(clearDelivery)
    dispatch(clearShopAndShopDetail())
  }

  const extractShopSlugs = (shopLinks) =>
    shopLinks
      .map((link) => {
        try {
          const url = new URL(link)
          const { pathname } = url
          const parts = pathname.split('/')
          return parts[parts.length - 1]
        } catch (error) {
          console.error(`Invalid URL: ${link}`)
          return null
        }
      })
      .filter((slug) => slug !== null)

  const onDirect = () => {
    const fromMyPurchasePath = location.state?.from === 'my-purchase'
    const isProcessingCreateShop = ['/virtual-shop/confirm-shop', '/virtual-shop/add-items'].some(
      (path) => location.pathname.includes(path)
    )
    const myFirstShop = myShops.length > 0 ? myShops[0] : null
    const userShopSlugs = extractShopSlugs(userInfo.shopLinks)
    const sanitizedCurrentPath = currentPath.replace(/\//g, '')
    const matchedShop = userShopSlugs.includes(sanitizedCurrentPath)
      ? myShops.find((shop) => {
          const isMatch = `${shop.shopSlug}` === sanitizedCurrentPath
          return isMatch
        })
      : myFirstShop
    if (isProcessingCreateShop) {
      CustomSwalFire.delete({
        title: 'Confirm to leave',
        text: 'Do you want to leave? If you leave, the data will be deleted',
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        colorCancelButton: 'slate',
        colorConfirmButton: 'aqua',
        onConfirm: () => {
          removeCreateShopData()
          if (matchedShop?.shopSlug) {
            history.push(`/admin-console/${matchedShop?.shopSlug}/setting/general`)
          }
        },
      })
    } else if ((cart?.orderList?.length > 0 || orderDetailsByRef?.ref) && !fromMyPurchasePath) {
      CustomSwalFire.delete({
        title: 'Are you sure you want to leave this page?',
        text: 'Currently, you have an order in progress. Do you want to remove the order information?',
        confirmButtonText: 'Yes, delete it!',
        onConfirm: async () => {
          onClearCart()
          setTimeout(() => {
            onClearCart()
            if (matchedShop?.shopSlug) {
              history.push(`/admin-console/${matchedShop?.shopSlug}/setting/general`)
            }
          }, 500)
        },
        onError: (error) => {
          addToast(error.message, { appearance: 'error', autoDismiss: true })
        },
      })
    } else {
      if (fromMyPurchasePath) {
        onClearCart()
      }

      localStorage.setItem('isAdminConsole', true)
      if (matchedShop?.shopSlug) {
        history.push(`/admin-console/${matchedShop?.shopSlug}/setting/general`)
      }
    }
  }

  if (loading) {
    return (
      <div className='float-button btn-admin-console skeleton-wrapper'>
        <Skeleton circle width={40} height={40} baseColor='#3498db' highlightColor='#2980b9' />
      </div>
    )
  }

  return (
    <>
      <button
        className='float-button btn-admin-console'
        style={style}
        onClick={onDirect}
        id='adminConsoleBtn'
      >
        <AiFillShop size={18} />
      </button>
      <Tooltip
        placement='left'
        isOpen={tooltipOpen}
        target='adminConsoleBtn'
        toggle={toggleTooltip}
        className='custom-tooltip admin-console'
        style={{ fontSize: '15px' }}
      >
        Admin Console
      </Tooltip>
    </>
  )
}

export default ButtonAdminConsole

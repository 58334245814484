import CustomButton from '_components/CustomButton'
import { getCreateEventData, setCreateEventData } from '_utils/localData'
import React, { useEffect, useState } from 'react'
import { IoMdCheckmark } from 'react-icons/io'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const EventModal = (props) => {
  const { modal, toggle, setValue } = props

  const eventData = getCreateEventData() || {}
  const [termConds, setTermConds] = useState(eventData?.termConds || [''])
  const [error, setError] = useState(null)
  const handleOnClickOk = () => {
    let data = termConds.filter((item) => item)
    data = data.map((item) => item.replaceAll('\n', '<br/>'))
    setCreateEventData({ ...eventData, data })
    setValue('termConds', data)
    toggle()
  }

  const handleOnRemove = (index) => {
    if (modal && termConds.length === 1) {
      setTermConds([''])
      return
    }
    termConds.splice(index, 1)
    setTermConds([...termConds])
  }

  const handleAddNote = () => {
    if (termConds?.length <= 4) {
      setTermConds([...termConds, ''])
    } else {
      setError('Maximum number of notes is 5!')
    }
  }
  useEffect(() => {
    if (termConds.length < 5) {
      setError('')
    }
  }, [termConds])

  useEffect(() => {
    setTermConds(termConds.map((item) => item.replaceAll('<br/>', '\n')))
  }, [])

  return (
    <Modal isOpen={modal} toggle={toggle} centered className='modal-event-note'>
      <ModalHeader toggle={toggle}>
        <p className='mb-0 text-orange'>Notes</p>
      </ModalHeader>
      <ModalBody>
        <div className='container text-center'>
          <div className='form-group'>
            <div className='note-list'>
              {termConds?.map((_, index) => (
                <div key={index} className='note-item mb-3'>
                  <div className='d-flex align-items-center'>
                    <p className='mb-0'>Note {index + 1}</p>
                    <div className='btn-action ml-1'>
                      <a className='btn-remove' onClick={() => handleOnRemove(index)} />
                    </div>
                  </div>
                  <textarea
                    className='form-control'
                    value={termConds[index]}
                    onChange={(e) => {
                      termConds[index] = e.target.value
                      setTermConds([...termConds])
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className='add-more'>
          <span className='error'>{error}</span>
          <span className='btn-add-more' onClick={handleAddNote}>
            Add more
          </span>
        </div>

        <div className='row mt-3'>
          <CustomButton
            text='OK'
            className='mt-0'
            onClick={handleOnClickOk}
            color='aqua'
            variant='contained'
            icon={<IoMdCheckmark />}
          />
        </div>
      </ModalBody>
    </Modal>
  )
}

export default EventModal

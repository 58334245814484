import CustomButton from '_components/CustomButton'
import { DEFAULT_PRODUCT_EVENT } from '_utils/constant'
import React from 'react'
import { useFieldArray } from 'react-hook-form'
import { IoAdd } from 'react-icons/io5'
import ProductItem from './ProductItem'

const ProductList = (props) => {
  const { control, register, setValue, errors, watch, getValues } = props
  const {
    fields,
    append: appendProduct,
    insert: insertProduct,
    remove: removeProduct,
  } = useFieldArray({
    control,
    name: 'products',
  })

  const watchFieldArray = watch('products')
  const controlledFields = fields.map((field, _index) => ({
    ...field,
    ...watchFieldArray[_index],
  }))

  const swapProduct = (oldIndex, newIndex) => {
    const a = controlledFields[oldIndex]
    const b = controlledFields[newIndex]
    const subItemA = a.subItems
    const subItemB = b.subItems

    setValue(`products.${oldIndex}`, b)
    setValue(`products.${newIndex}`, a)
    setValue(`products.${oldIndex}.subItems`, subItemB)
    setValue(`products.${newIndex}.subItems`, subItemA)
  }

  return (
    <div className='detail-list-news'>
      {controlledFields.map((item, index) => (
        <ProductItem
          key={item.id}
          productIndex={index}
          totalListProduct={controlledFields?.length}
          {...{
            control,
            register,
            appendProduct,
            insertProduct,
            removeProduct,
            swapProduct,
            watch,
            setValue,
            errors,
            getValues,
          }}
          product={item}
        />
      ))}
      <CustomButton
        text='Add Item'
        color='aqua'
        onClick={() => appendProduct({ ...DEFAULT_PRODUCT_EVENT })}
        className='border-dash'
        icon={<IoAdd />}
      />
    </div>
  )
}

export default ProductList

import { FormGroup } from 'reactstrap'

const InputProduct = (props) => {
  const { register, type, label, placeholder, disabled, className, error, ...rest } = props
  const { name } = register

  return (
    <FormGroup className='mt-0'>
      <input
        className={className}
        id={name}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        {...register}
        {...rest}
        onWheel={(event) => {
          event.target.blur()
        }}
      />
      <small className='error-message text-danger'>{error}</small>
    </FormGroup>
  )
}

export default InputProduct

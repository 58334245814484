// InputTime.jsx
import React, { useEffect, useRef, useState } from 'react'
import { BiTime } from 'react-icons/bi'
import '../styles/input-time.scss'

export default function InputTime({ value, onChange }) {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedHour, setSelectedHour] = useState('12')
  const [selectedMinute, setSelectedMinute] = useState('00')
  const [selectedPeriod, setSelectedPeriod] = useState('AM')
  const wrapperRef = useRef(null)

  const hours = Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0'))
  const minutes = ['00', '30']

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const handleTimeSelect = (hour, minute, period) => {
    const formattedTime = `${hour}:${minute} ${period}`
    onChange(formattedTime)
    setSelectedHour(hour)
    setSelectedMinute(minute)
    setSelectedPeriod(period)
  }

  return (
    <div className='time-picker' ref={wrapperRef}>
      <div className='form-group'>
        <div className='wrapper-input' onClick={() => setIsOpen(!isOpen)}>
          <input
            className='form-control custom-input bg-white'
            type='text'
            value={value}
            readOnly
            placeholder='Select time'
          />
          <BiTime className='time-icon' />
        </div>
      </div>

      {isOpen && (
        <div className='time-picker-popup'>
          <div className='time-columns'>
            <div className='time-column hours'>
              <div className='column-header'>Hour</div>
              <div className='column-items'>
                {hours.map((hour) => (
                  <div
                    key={hour}
                    className={`time-item ${selectedHour === hour ? 'selected' : ''}`}
                    onClick={() => handleTimeSelect(hour, selectedMinute, selectedPeriod)}
                  >
                    {hour}
                  </div>
                ))}
              </div>
            </div>

            <div className='time-column minutes'>
              <div className='column-header'>Minute</div>
              <div className='column-items'>
                {minutes.map((minute) => (
                  <div
                    key={minute}
                    className={`time-item ${selectedMinute === minute ? 'selected' : ''}`}
                    onClick={() => handleTimeSelect(selectedHour, minute, selectedPeriod)}
                  >
                    {minute}
                  </div>
                ))}
              </div>
            </div>

            <div className='time-column period'>
              <div className='column-header'>Period</div>
              <div className='column-items'>
                {['AM', 'PM'].map((period) => (
                  <div
                    key={period}
                    className={`time-item ${selectedPeriod === period ? 'selected' : ''}`}
                    onClick={() => handleTimeSelect(selectedHour, selectedMinute, period)}
                  >
                    {period}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

import { clearCart, clearOrderDetailsByRef } from '_redux/modules/order'
import React, { useState } from 'react'
import { BiSolidPurchaseTag } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { Tooltip } from 'reactstrap'
import { clearDelivery, clearPickup } from '../../redux/modules/fulfillment'
import { clearShopAndShopDetail } from '../../redux/modules/shop'
import CustomSwalFire from '../CustomSwalFire'
import './style.scss'

function ButtonToMyPurchase({ style }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { cart, orderDetailsByRef } = useSelector((state) => state.order)

  const [tooltipOpen, setTooltipOpen] = useState(false)

  const fromMyPurchasePath = location.state?.from === 'my-purchase'

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen)

  const onClearCart = () => {
    dispatch(clearOrderDetailsByRef())
    dispatch(clearCart())
    dispatch(clearPickup)
    dispatch(clearDelivery)
    dispatch(clearShopAndShopDetail())
  }

  const onRedirect = () => {
    const orderSuccess = localStorage.getItem('ORDER_SUCCESS') === 'true'

    if (fromMyPurchasePath) {
      onClearCart()
      history.push('/virtual-shop/my-purchase')
    } else if (
      !fromMyPurchasePath &&
      !orderSuccess &&
      (cart?.orderList?.length > 0 || orderDetailsByRef?.ref)
    ) {
      CustomSwalFire.delete({
        title: 'Are you sure you want to leave this page?',
        text: 'Currently, you have an order in progress. Do you want to remove the order information?',
        confirmButtonText: 'Yes, delete it!',
        onConfirm: async () => {
          onClearCart()
          setTimeout(() => {
            history.push('/virtual-shop/my-purchase')
          }, 500)
        },
        onError: (error) => {
          console.error('Error:', error)
        },
      })
    } else {
      history.push('/virtual-shop/my-purchase')
    }

    localStorage.removeItem('ORDER_SUCCESS')
  }

  return (
    <>
      <button
        className='float-button btn-my-purchase'
        style={style}
        onClick={onRedirect}
        id='myPurchaseBtn'
      >
        <BiSolidPurchaseTag size={18} />
      </button>
      <Tooltip
        placement='left'
        isOpen={tooltipOpen}
        target='myPurchaseBtn'
        toggle={toggleTooltip}
        className='custom-tooltip my-purchase'
        style={{ fontSize: '15px' }}
      >
        My Purchase
      </Tooltip>
    </>
  )
}

export default ButtonToMyPurchase

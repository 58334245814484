/* eslint-disable no-confusing-arrow */
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import CurrencyFormat from 'react-currency-format'
import { FormGroup } from 'reactstrap'
import './style.scss'

const InputField = ({
  register,
  type = 'text',
  label,
  placeholder,
  disabled,
  className,
  id,
  onChange,
  as = 'input',
  defaultValue,
  isRequired,
  isSearch,
  value,
  offset = {},
  width,
  labelStyle,
  error,
  isSwitch,
  isRadio,
  icon,
  ...rest
}) => {
  const formatLabelToId = (labelText) =>
    labelText ? labelText.toLowerCase().replace(/\s+/g, '') : ''

  const inputId = id || (label && formatLabelToId(label))

  const renderInput = () => {
    const commonProps = {
      className: classNames('form-control custom-input', {
        'input-invalid': error,
      }),
      id: inputId,
      name: inputId,
      disabled,
      placeholder,
      onChange: (e) => {
        if (register && register.onChange) {
          register.onChange(e)
        }
        if (onChange) {
          onChange(e)
        }
      },
      defaultValue,
      value,
      ...(register && { ...register }),
      ...rest,
    }

    if (isSwitch) {
      return (
        <>
          {label && (
            <label htmlFor={inputId} style={labelStyle} className={classNames({ disabled })}>
              {label}
              {isRequired && <span style={{ color: 'red' }}>*</span>}
            </label>
          )}
          <label className={classNames('switch', { disabled })} style={labelStyle}>
            <input
              type='checkbox'
              {...commonProps}
              className='h-auto p-0'
              onChange={(e) => {
                if (register && register.onChange) {
                  register.onChange(e)
                }
                if (onChange) {
                  onChange(e)
                }
              }}
            />
            <span className='slider'></span>
          </label>
        </>
      )
    }

    if (isRadio) {
      return (
        <label className={classNames('radio', { disabled })} style={labelStyle}>
          <input type='radio' {...commonProps} />
          <span className='radio-mark'></span>
          {label && <span className='radio-label ms-1'>{label}</span>}
        </label>
      )
    }

    if (type === 'checkbox') {
      return (
        <label className={classNames('checkbox', { disabled })} style={labelStyle}>
          <input type='checkbox' {...commonProps} />
          <span className='checkbox-mark'></span>
          {label && <span className='checkbox-label'>{label}</span>}
        </label>
      )
    }

    if (as === CurrencyFormat) {
      const currencyFormatProps = {
        ...commonProps,
        thousandSeparator: true,
        allowNegative: false,
        ...rest,
      }
      return (
        <div className={classNames('wrapper-input', { 'has-icon': icon })}>
          {icon && (
            <span
              className='input-icon'
              style={{
                left: 5,
                transform: 'translateY(-55%)',
              }}
            >
              {icon}
            </span>
          )}
          <CurrencyFormat {...currencyFormatProps} style={icon ? { paddingLeft: 24 } : {}} />
        </div>
      )
    }

    return (
      <>
        {label && (
          <label htmlFor={inputId} style={labelStyle} className={classNames({ disabled })}>
            {label}
            {isRequired && <span style={{ color: 'red' }}>*</span>}
          </label>
        )}
        <div className={classNames('wrapper-input', { 'has-icon': icon, 'icon-search': isSearch })}>
          {(isSearch || icon) && (
            <span
              className='input-icon'
              style={
                !isSearch && icon
                  ? {
                      left: 8,
                      transform: 'translateY(-55%)',
                    }
                  : {}
              }
            >
              {isSearch ? <i className='icon-search'></i> : icon}
            </span>
          )}
          {as === 'textarea' ? (
            <textarea {...commonProps} />
          ) : as === CurrencyFormat ? (
            <CurrencyFormat {...commonProps} thousandSeparator={true} />
          ) : (
            <input
              type={type}
              style={!isSearch && icon ? { paddingLeft: 30 } : {}}
              {...commonProps}
            />
          )}
        </div>
      </>
    )
  }

  const formatOffset = (v) => v ?? (typeof v === 'number' ? `${v}px` : v)

  const formGroupStyle = {
    marginLeft: formatOffset(offset.left),
    marginTop: formatOffset(offset.top),
    marginRight: formatOffset(offset.right),
    marginBottom: formatOffset(offset.bottom),
    width,
  }

  return (
    <FormGroup style={formGroupStyle} className={className}>
      {renderInput()}
      {error && <span className='text-danger'>{error}</span>}
    </FormGroup>
  )
}

export default InputField

InputField.defaultProps = {
  type: 'text',
}

InputField.propTypes = {
  register: PropTypes.object,
  type: (props, propName, componentName) => {
    // Only validate type if as !== 'textarea'
    if (props.as === 'textarea') return null

    const validTypes = ['text', 'password', 'email', 'number', 'checkbox', 'radio', 'time']
    if (!validTypes.includes(props[propName])) {
      return new Error(
        `Invalid prop '${propName}' supplied to '${componentName}'.
        Expected one of ${validTypes.join(', ')}`
      )
    }
  },
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  as: PropTypes.oneOfType([PropTypes.oneOf(['input', 'textarea']), PropTypes.elementType]),
  labelStyle: PropTypes.object,
  isSwitch: PropTypes.bool,
  isRadio: PropTypes.bool,
  // Add any additional CurrencyFormat specific props
  thousandSeparator: PropTypes.bool,
  allowNegative: PropTypes.bool,
  decimalScale: PropTypes.number,
  onValueChange: PropTypes.func,
  showMaxLength: PropTypes.bool,
  maxLength: PropTypes.number,
}

/* eslint-disable no-confusing-arrow */

import classNames from 'classnames'
import ReactDatePicker from 'react-datepicker'
import ReactDOM from 'react-dom'
import { Controller, useFormContext } from 'react-hook-form'
import { FaRegCalendar } from 'react-icons/fa'
import { FormGroup } from 'reactstrap'

const DatePicker = ({ onChangeDate, popperContainerRef, ...props }) => {
  const methods = useFormContext()

  const errorMessageStyle = {
    color: '#d63031',
    fontSize: '12px',
    marginTop: '4px',
    display: 'block',
  }

  const popperContainer = ({ children }) => {
    const currPopperContainer = popperContainerRef?.current

    if (currPopperContainer) {
      return ReactDOM.createPortal(children, popperContainerRef.current)
    }

    if (children) {
      return children
    }

    return null
  }

  return (
    <FormGroup className='mb-3'>
      <div className='d-flex align-items-center justify-content-between'>
        <label htmlFor='datePicker' className='form-label' style={{ minWidth: 100 }}>
          Choose date:
        </label>

        <Controller
          control={methods?.control}
          name='startDate'
          render={({ field, fieldState: { error } }) => (
            <div className='position-relative'>
              <ReactDatePicker
                {...props}
                id='date-picker'
                className={classNames(
                  'form-control custom-input date-picker text-black w-auto ms-auto',
                  {
                    'input-invalid': error,
                  }
                )}
                dateFormat={'dd/MM/yyyy'}
                minDate={new Date()}
                selected={field.value}
                onChange={(date) => {
                  field.onChange(date)
                  onChangeDate(date)
                }}
                showIcon
                icon={<FaRegCalendar />}
                popperContainer={popperContainer}
              />
              {error && <span style={errorMessageStyle}>{error.message}</span>}
            </div>
          )}
        />
      </div>
    </FormGroup>
  )
}

DatePicker.displayName = 'DatePicker'

export default DatePicker

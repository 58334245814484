import uploadApi from '_api/upload'
import noImage from '_images/noimage02.svg'
import { swapProduct, updateNewListProductLazada } from '_redux/modules/product'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

function ProductImage({ product, setValue, loadingProduct, setLoadingProduct }) {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { listProductLazada } = useSelector((state) => state.product)
  const { shopDetail } = useSelector((state) => state.shop)

  const onUploadImage = async (e, prodId) => {
    try {
      setLoadingProduct(prodId)
      const { files } = e.target
      const { msgResp } = await uploadApi.uploadPhotoLazada(shopDetail?.id, files)
      const findProduct = listProductLazada.findIndex((item) => item.id === prodId)
      const updatedList = [...listProductLazada]
      updatedList[findProduct].photoUrls = msgResp.url
      dispatch(updateNewListProductLazada(updatedList))
      setValue('photoUrls', msgResp.url)
    } catch (error) {
      addToast(error.message || error.msgResp?.message || error.msgResp, {
        appearance: 'error',
        autoDismiss: true,
      })
    } finally {
      setLoadingProduct('')
    }
  }

  return (
    <div className='d-flex align-items-center'>
      <div className='wrap-arrow me-2 cursor-pointer'>
        <div className='arrow-up' onClick={() => dispatch(swapProduct(product, 'up'))}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='currentColor'
          >
            <path d='M12 3l12 18h-24z' />
          </svg>
        </div>
        <div className='arrow-down' onClick={() => dispatch(swapProduct(product, 'down'))}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='currentColor'
          >
            <path d='M12 21l-12-18h24z' />
          </svg>
        </div>
      </div>
      <div className='upload-image flex-column cursor-pointer position-relative'>
        {product?.id === loadingProduct ? (
          <div
            className='loading d-flex align-items-center justify-content-center bg-gray-300 rounded'
            style={{ width: 90, height: 80 }}
          >
            <div className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <img
            src={product?.photoUrls || product?.photoUrl || noImage}
            className={`${product?.photoUrl ? 'img-upload' : ''} image img-fluid rounded-0`}
            alt='img'
          />
        )}

        <div className='u-file-upload__item position-absolute bottom-0 start-0 h-100 w-100'>
          <div className='u-file-upload__wrap h-100'>
            <label
              htmlFor={`formFile${product?.id}`}
              className='c-upload preview-images-zone w-100 text-center h-100 mb-0'
              style={{ cursor: 'pointer' }}
            >
              <input
                type='file'
                accept='image/*'
                multiple
                id={`formFile${product?.id}`}
                className='inputFile form-control'
                onChange={(e) => onUploadImage(e, product?.id)}
                style={{ display: 'none' }}
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductImage

import deliveryApi from '_api/delivery'
import dashBoardShopApi from '_api/deskTopDashBoard'
import moment from 'moment'

const GET_LIST_TOTAL_SALES_PER_DAY_BY_HOST = 'GET_LIST_TOTAL_SALES_PER_DAY_BY_HOST'
const GET_LIST_TOTAL_ORDERS_PER_DAY_BY_HOST = 'GET_LIST_TOTAL_ORDERS_PER_DAY_BY_HOST'
const GET_LIST_TOTAL_AVERAGE_ORDER_PER_DAY_BY_HOST = 'GET_LIST_TOTAL_AVERAGE_ORDER_PER_DAY_BY_HOST'
const GET_TOP_PRODUCT_BY_HOST = 'GET_TOP_PRODUCT_BY_HOST'
const SET_DATE_WHEN_SYNC_DATA = 'SET_DATE_WHEN_SYNC_DATA'
const GET_LIST_FULFILLMENT_BY_SHOP_ID = 'GET_LIST_FULFILLMENT_BY_SHOP_ID'

const initialState = {
  dateWhenSyncData: null,
  listTotalSalesPerDatByShopId: null,
  listTotalOrdersPerDatByShopId: null,
  listTotalAverageOrderPerDatByShopId: null,
  listTopProductByShopId: null,
  listFulfillmentByShopId: [],
}

export const setDateWhenSyncData = (data) => (dispatch) => {
  if (data) {
    const dateWhenSyncData = {
      startDate: data.startDate,
      endDate: data.endDate,
      shopId: data?.shopId,
    }
    dispatch({
      type: SET_DATE_WHEN_SYNC_DATA,
      payload: { dateWhenSyncData },
    })
  }
}

export const getListTotalSalesPerDatByShopId = (data) => async (dispatch) => {
  let listTotalSalesPerDatByShopId = []
  try {
    const response = await dashBoardShopApi.getListTotalSalesShop(data)
    if (response.msgResp) {
      listTotalSalesPerDatByShopId = response.msgResp
    }
    dispatch({
      type: GET_LIST_TOTAL_SALES_PER_DAY_BY_HOST,
      payload: { listTotalSalesPerDatByShopId },
    })
  } catch (err) {}
}

export const getListTotalOrdersPerDatByShopId = (data) => async (dispatch) => {
  let listTotalOrdersPerDatByShopId = []
  try {
    const response = await dashBoardShopApi.getListTotalOrdersShop(data)
    if (response.msgResp) {
      listTotalOrdersPerDatByShopId = response.msgResp
    }
    dispatch({
      type: GET_LIST_TOTAL_ORDERS_PER_DAY_BY_HOST,
      payload: { listTotalOrdersPerDatByShopId },
    })
  } catch (err) {}
}

export const getListTotalAverageOrderPerDatByShopId = (data) => async (dispatch) => {
  let listTotalAverageOrderPerDatByShopId = []
  try {
    const response = await dashBoardShopApi.getListTotalAverageOrderShop(data)
    if (response.msgResp) {
      listTotalAverageOrderPerDatByShopId = response.msgResp
    }
    dispatch({
      type: GET_LIST_TOTAL_AVERAGE_ORDER_PER_DAY_BY_HOST,
      payload: { listTotalAverageOrderPerDatByShopId },
    })
  } catch (err) {}
}

export const getListTopProductByShopId = (data) => async (dispatch) => {
  let listTopProductByShopId = []
  try {
    const response = await dashBoardShopApi.getTopProductByHostShop(data)
    if (response.msgResp) {
      listTopProductByShopId = response.msgResp
    }
    dispatch({
      type: GET_TOP_PRODUCT_BY_HOST,
      payload: { listTopProductByShopId },
    })
  } catch (err) {}
}

export const getListFulfillmentByShopId = (shopId) => async (dispatch) => {
  try {
    const postData = {
      startDate: moment().startOf('day').unix(),
      endDate: moment().add(7, 'days').endOf('day').unix(),
      shopId,
    }
    const [listShopDelivery, listShopPickup] = await Promise.all([
      deliveryApi.getListShopDeliveryByHost(postData),
      deliveryApi.getListShopPickupByHost(postData),
    ])
    const listFulfillmentByShopId = [...listShopDelivery.msgResp, ...listShopPickup.msgResp]

    dispatch({
      type: GET_LIST_FULFILLMENT_BY_SHOP_ID,
      payload: { listFulfillmentByShopId },
    })
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

const dashBoardShopReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_FULFILLMENT_BY_SHOP_ID:
    case GET_LIST_TOTAL_SALES_PER_DAY_BY_HOST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_LIST_TOTAL_ORDERS_PER_DAY_BY_HOST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_LIST_TOTAL_AVERAGE_ORDER_PER_DAY_BY_HOST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_TOP_PRODUCT_BY_HOST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case SET_DATE_WHEN_SYNC_DATA: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}

export default dashBoardShopReducer

/* eslint-disable radix */
/* eslint-disable no-eval */
import moment from 'moment'
import { PROD_DOMAIN, STG_DOMAIN, STG_DOMAIN_SECOND, URL_SCHEME } from '../constant'

export const generateDateRange = (startDate, endDate) => {
  const dates = []
  let currentDate = moment.unix(startDate)
  const lastDate = moment.unix(endDate)

  while (currentDate <= lastDate) {
    dates.push(currentDate.format('DD-MM-YYYY'))
    currentDate = currentDate.add(1, 'days')
  }

  return dates
}

export const getTimeZoneOffset = () => {
  const date = new Date()
  const timeZone = date.toLocaleTimeString('en', { timeZoneName: 'short' }).split(' ')[2]

  // Lấy offset từ timeZone (VD: 'GMT+7', 'GMT+8')
  const offset = parseInt(timeZone.replace('GMT', ''))

  return offset
}

// inputData is sub-item of product
export const generateVariations = (listSubItems, index = 0, current = {}, cache = {}) => {
  const cacheKey = JSON.stringify({ listSubItems, current })
  if (cache[cacheKey]) {
    return cache[cacheKey]
  }

  const listVariations = []
  const subItemType = listSubItems[index].type
  const currentListOptions = listSubItems[index].list

  if (index === listSubItems.length - 1) {
    for (const option of currentListOptions) {
      const updatedCurrent = { ...current, [subItemType]: option.name }
      delete updatedCurrent.price

      if (listSubItems.length === 1) {
        listVariations.push({
          saleProp: updatedCurrent,
          price: option.price,
          codeProd: option.codeProd,
        })
      } else {
        const codeProdList = Object.keys(updatedCurrent)
          .map((key) => {
            if (key === '' || updatedCurrent[key] === '') {
              return option.codeProd
            }
            const matchedOption = listSubItems
              ?.find((ctrl) => ctrl.type === key)
              ?.list.find((o) => o.name === updatedCurrent[key])
            return matchedOption ? matchedOption.codeProd : ''
          })
          .filter((codeProd) => codeProd !== '')

        const finalCodeProd = codeProdList.join('-')

        listVariations.push({
          saleProp: updatedCurrent,
          price: option.price,
          codeProd: finalCodeProd,
        })
      }
    }
  } else {
    for (const option of currentListOptions) {
      const updatedCurrent = {
        ...current,
        [subItemType]: option.name,
        price: option.price,
      }
      const combinations = generateVariations(listSubItems, index + 1, updatedCurrent, cache)
      listVariations.push(...combinations)
    }
  }

  cache[cacheKey] = listVariations
  return listVariations
}

export const generateSecretCode = () => {
  let magic = 0
  const timestamp = moment().unix()
  try {
    const code =
      "({a1:function(a) { for (var b = [], c = 0; c < a.length; c += 2) b.push(parseInt(a.substr(c, 2), 16)); return b; }, a2:function(a) { var b = this.a1(a); var c = ''; for (let i = 0; i < b.length; i++) { c += String.fromCharCode(b[i] ^ 222); } return eval(c); }})-----a8bfacfebffee3fee7e7e7e7e7e7e7e7e7e7e5fea8bfacfebcfee3feefecedeaebeaedecefe5fef6f6aaa7aebbb1b8fea9b7b0bab1a9fee3e3e3fef9b1bcb4bbbdaaf9f7f4bff7fbbce5"
    const codes = code.split('-----')
    magic = eval(codes[0]).a2(codes[1])
    return `${timestamp}, ${timestamp % magic}`
  } catch (e) {}
}

export const generateIdWithLength = (length) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const idGenerator = () => `_${Math.random().toString(36).substr(2, 9)}`

export const domainGenerator = () => {
  let baseUrl = `http://localhost:${process.env.REACT_APP_PORT}/`
  const host = window.location.hostname
  if (host.includes(STG_DOMAIN)) {
    baseUrl = `${URL_SCHEME}${STG_DOMAIN}/`
  } else if (host.includes(STG_DOMAIN_SECOND)) {
    baseUrl = `${URL_SCHEME}${STG_DOMAIN_SECOND}/`
  } else if (host.includes(PROD_DOMAIN)) {
    baseUrl = `${URL_SCHEME}${PROD_DOMAIN}/`
  }
  return baseUrl
}

export const telegramLinkGenerator = () => {
  const host = window.location.hostname
  let telegramLink = 'https://t.me/CoHootLocalDev_Bot'
  if (host.includes(STG_DOMAIN)) {
    telegramLink = 'https://t.me/CoHootDevStgbot'
  }
  //  else if (host.includes(PROD_DOMAIN)) {
  //   telegramLink = 'https://t.me/'
  // }

  return telegramLink
}

// AuthNavigationBar.jsx
import PropTypes from 'prop-types'
import React from 'react'
import { GoArrowLeft } from 'react-icons/go'
import { Link } from 'react-router-dom'

const AuthNavigationBar = ({ onHandleBack }) => (
  <div className='d-flex align-items-center justify-content-between w-100 mb-3'>
    <button
      type='button'
      className='d-flex align-items-center bg-transparent border-0 animated-underline btn-back'
      onClick={onHandleBack}
    >
      <GoArrowLeft />
      <span>Back</span>
    </button>

    <Link to='/virtual-shop/sign-in' className='shop-detail-banner__sign-in animated-underline'>
      Sign-in / Sign-up
    </Link>
  </div>
)

AuthNavigationBar.propTypes = {
  onHandleBack: PropTypes.func.isRequired,
}

export default AuthNavigationBar

import Skeleton from 'react-loading-skeleton'

const DeliveryListSkeleton = () => (
  <div className='row delivery-list-skeleton'>
    <div className='col-12 col-lg-3'>
      <Skeleton height={600} />
    </div>
    <div className='col-12 col-lg-9'>
      <div className='list-delivery d-flex h-100 flex-column'>
        <div className='table-header'>
          <div className='row p-3 bg-light'>
            {Array(7)
              .fill(0)
              .map((_, index) => (
                <div key={index} className='col'>
                  <Skeleton width={80} />
                </div>
              ))}
          </div>
        </div>
        <div className='table-body flex-grow-1'>
          {Array(5)
            .fill(0)
            .map((_, index) => (
              <div
                key={index}
                className={`row align-items-center p-3 ${index % 2 !== 0 ? 'bg-light' : ''}`}
              >
                {Array(7)
                  .fill(0)
                  .map((__, colIndex) => (
                    <div key={colIndex} className='col'>
                      <Skeleton count={2} />
                    </div>
                  ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  </div>
)

export default DeliveryListSkeleton

import React from 'react'

function LoadingSkeletonMyPurchase() {
  return (
    <div className="loading-skeleton">
      {[1, 2, 3].map((item) => (
        <div key={item} className="purchase-item skeleton-item">
          <div className="item-header">
            <div className="d-flex justify-content-between align-items-start">
              <div className="skeleton-text w-25"></div>
              <div className="badge-container">
                <div className="skeleton-badge"></div>
                <div className="skeleton-badge"></div>
              </div>
            </div>
          </div>

          <div className="order-info">
            <div className="skeleton-text w-50"></div>
          </div>

          <div className="item-footer">
            <div className="skeleton-text w-25"></div>
            <div className="button-group">
              <div className="skeleton-button"></div>
              <div className="skeleton-button"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default LoadingSkeletonMyPurchase

import Skeleton from 'react-loading-skeleton'

const DeliveryActionsSkeleton = () => (
  <div className='d-flex align-items-center justify-content-between'>
    <div style={{ width: '75%' }}>
      <Skeleton height={38} />
    </div>
    <div className='d-flex'>
      <div className='me-3'>
        <Skeleton width={120} height={38} />
      </div>
      <div className='me-3'>
        <Skeleton width={120} height={38} />
      </div>
      <div className='me-3'>
        <Skeleton width={120} height={38} />
      </div>
      <div>
        <Skeleton width={120} height={38} />
      </div>
    </div>
  </div>
)

export default DeliveryActionsSkeleton

/* eslint-disable react/display-name */
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useRef, useEffect } from 'react'

function SearchShop({ setSearch, styles, setLoadingDebounce, placeholder, onSubmitSearch, autofocus }) {
  const inputRef = useRef(null)

  useEffect(() => {
    if (autofocus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [autofocus])

  const printValue = useCallback(
    debounce((value) => {
      setSearch(value)
      setLoadingDebounce(false)
    }, 500),
    []
  )

  const handleChange = ({ target }) => {
    printValue(target.value)
    setLoadingDebounce(true)
  }

  const handleSubmit = (e) => {
    onSubmitSearch(e)
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  return (
    <div className='form-group search-container' style={styles}>
      <div className='icon-wrapper' onClick={handleSubmit}>
        <i className='icon-search'></i>
      </div>
      <input
        ref={inputRef}
        className='form-control search-input'
        placeholder={placeholder}
        type='text'
        onChange={handleChange}
        data-cy='search-shop'
      />
    </div>
  )
}

SearchShop.propTypes = {
  setSearch: PropTypes.func.isRequired,
  styles: PropTypes.object,
  autofocus: PropTypes.bool
}

export default SearchShop

import CustomSelectBox from '_components/CustomSelectBox'
import { LIST_TIME } from '_utils/constant'
import React from 'react'

const TimePicker = ({ time, onChangeTime }) => {
  const timeOptions = LIST_TIME.map((option) => ({
    value: option.value,
    label: option.text,
  }))

  const handleTimeChange = (type) => (option) => {
    onChangeTime(type, option.value)
  }

  return (
    <div className='mb-3 d-flex align-items-center justify-content-between'>
      <label className='form-label'>Choose time slot:</label>
      <div className='d-flex align-items-center justify-content-between'>
        <div className='me-2'>
          <CustomSelectBox
            options={timeOptions}
            value={JSON.stringify(timeOptions.find((opt) => opt.value === time.start))}
            onChange={handleTimeChange('start')}
            placeholder='Select start time'
            isTimer={true}
          />
        </div>
        <span className='mx-2'>-</span>
        <div>
          <CustomSelectBox
            options={timeOptions}
            value={JSON.stringify(timeOptions.find((opt) => opt.value === time.end))}
            onChange={handleTimeChange('end')}
            placeholder='Select end time'
            isTimer={true}
          />
        </div>
      </div>
    </div>
  )
}

export default TimePicker

import React from 'react'
import Skeleton from 'react-loading-skeleton'

function ProductItemSkeleton() {
  return (
    <tr>
      <td style={{ width: 150 }}>
        <Skeleton height={80} width={90} />
      </td>
      <td style={{ width: '15%' }}>
        <Skeleton count={2} />
      </td>
      <td>
        <Skeleton width={60} />
      </td>
      <td style={{ width: 100 }}>
        <Skeleton width={50} />
      </td>
      <td>
        <Skeleton width={50} />
      </td>
      <td style={{ width: '15%' }}>
        <Skeleton count={2} />
      </td>
      <td>
        <Skeleton width={40} height={20} />
      </td>
      <td className='text-center'>
        <Skeleton width={30} height={30} circle />
      </td>
    </tr>
  )
}

export default ProductItemSkeleton

import { MdOutlineUpgrade } from 'react-icons/md'
import { TiCancel } from 'react-icons/ti'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import CustomButton from './CustomButton'

const DialogBoxUpgradeSubscription = ({ modal, toggle, ...args }) => {
  const history = useHistory()
  const { selectedShop } = useSelector((state) => state.shop)

  return (
    <Modal isOpen={modal} toggle={toggle} {...args} className={'modal-upgrade-subscription'}>
      <ModalBody className={'f-16'}>
        <button type='button' className='close' aria-label='Close' onClick={toggle}>
          <span aria-hidden='true'>×</span>
        </button>
        <p className='mt-3 mb-0'>
          Your existing subscription allows a maximum of 5 products. To add additional products,
          please upgrade your subscription.
        </p>
      </ModalBody>
      <ModalFooter className={'f-16 pb-2'}>
        <div className={'d-flex align-items-center justify-content-between w-100 column-gap-5'}>
          <CustomButton
            onClick={() => history.push(`/admin-console/${selectedShop?.shopSlug}/subscription`)}
            className={'mt-0 '}
            text='Upgrade'
            color='aqua'
            variant='contained'
            icon={<MdOutlineUpgrade />}
          />
          <CustomButton
            onClick={toggle}
            className={'mt-0 '}
            text='Cancel'
            color='slate'
            icon={<TiCancel />}
          />
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default DialogBoxUpgradeSubscription

import CustomButton from '_components/CustomButton'
import React, { useEffect, useState } from 'react'
import { IoMdCheckmark } from 'react-icons/io'
import { TiCancel } from 'react-icons/ti'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

export default function ModalWarning({ data, title, modalWarning, toggleWarning, handleCancel }) {
  const modalTitleStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }

  const [titleModal, setTitleModal] = useState(<span style={modalTitleStyle}>{title}</span>)

  useEffect(() => {
    setTitleModal(<span style={modalTitleStyle}>{title}</span>)
  }, [title])

  return (
    <div>
      <Modal isOpen={modalWarning} toggle={toggleWarning} centered className='modal-warning'>
        <ModalHeader toggle={toggleWarning} style={modalTitleStyle} />
        <ModalBody style={{ textAlign: 'center', padding: 0 }}>
          Warning!
          <br />
          Please ensure that all refunds are processed before cancelling {titleModal}.
          <br />
          <br />
          Any dispute, host will need to take responsibility on refund and cancellation of{' '}
          {titleModal}s.
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
          <div className='col-6 px-0 m-0' style={{ flexBasis: '48%' }}>
            <CustomButton
              text='CANCEL'
              variant='outlined'
              color='slate'
              icon={<TiCancel />}
              onClick={toggleWarning}
            />
          </div>
          <div className='col-6 px-0 m-0' style={{ flexBasis: '48%' }}>
            <CustomButton
              text='OK'
              variant='contained'
              color='aqua'
              icon={<IoMdCheckmark />}
              onClick={(e) =>
                handleCancel(
                  e,
                  data.ref,
                  data.status,
                  data?.buyerStatus || data.bookerStatus,
                  data.combinedPaymentRef
                )
              }
            />
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}

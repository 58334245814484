import useLogout from '_hooks/useLogout'
import React from 'react'
import { AiFillShop, AiOutlineHistory } from 'react-icons/ai'
import { BiLogOut, BiSolidPurchaseTag } from 'react-icons/bi'
import { IoClose } from 'react-icons/io5'
import { MdAccountCircle } from 'react-icons/md'
import { Link } from 'react-router-dom'
import './style.scss'

const MobileDrawer = ({ isOpen, onClose, user, handleReadNotification }) => (
  <>
    <div className={`mobile-drawer ${isOpen ? 'open' : ''}`}>
      <div className='mobile-drawer__header'>
        <button className='close-button' onClick={onClose}>
          <IoClose size={24} />
        </button>
      </div>
      <div className='mobile-drawer__content'>
        <div className='mobile-drawer__user'>
          <div className='avatar-top__img'>
            <img src={user?.photoUrl || process.env.REACT_APP_AVATAR_URL + user?.name} alt='' />
          </div>
          <span className='user-name'>Hello, {user?.name}</span>
        </div>
        <div className='mobile-drawer__menu'>
          <Link to='/admin-console/setting/account/profile' onClick={handleReadNotification}>
            <MdAccountCircle className='me-2' />
            <span>Account</span>
          </Link>
          <Link to='/virtual-shop/list'>
            <AiFillShop className='me-2' />
            <span>Shop List</span>
          </Link>
          <Link to='/virtual-shop/my-purchase'>
            <BiSolidPurchaseTag className='me-2' />
            <span>My Purchase</span>
          </Link>
          <Link to='/virtual-shop/payment-history'>
            <AiOutlineHistory className='me-2' />
            <span>Payment History</span>
          </Link>
          <div className="logout-section">
            <div onClick={useLogout} className='logout-button'>
              <BiLogOut className='me-2' />
              <span>Log out</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    {isOpen && <div className='mobile-drawer-overlay' onClick={onClose} />}
  </>
)

export default MobileDrawer

import React from 'react'
import Skeleton from 'react-loading-skeleton'

const NotificationSkeleton = () => (
  <div className='noti-list-item'>
    <div className='noti-avatar'>
      <Skeleton circle width={40} height={40} />
    </div>
    <div className='noti-content'>
      <div className='notification-header'>
        <Skeleton circle width={20} height={20} style={{ marginRight: 8 }} />
        <Skeleton width={150} />
        <Skeleton width={80} style={{ marginLeft: 'auto' }} />
      </div>
      <div style={{ marginTop: '8px' }}>
        <Skeleton count={2} />
      </div>
    </div>
  </div>
)

export default NotificationSkeleton

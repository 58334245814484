/* eslint-disable no-async-promise-executor */
// components/CustomSwal/index.jsx
import CustomButton from '_components/CustomButton'
import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { IoMdCheckmark } from 'react-icons/io'
import { TiCancel } from 'react-icons/ti'
import Swal from 'sweetalert2'
import './style.scss'

const CustomSwalFire = {
  delete: ({
    title = 'Are you sure?',
    text = "You won't be able to revert this!",
    icon = 'warning',
    confirmButtonText = 'Yes, delete it!',
    cancelButtonText = 'Cancel',
    onConfirm,
    onError,
    confirmIcon = <IoMdCheckmark />,
    cancelIcon = <TiCancel />,
    colorCancelButton = 'slate',
    colorConfirmButton = 'aqua',
  }) =>
    Swal.fire({
      title,
      text,
      icon,
      showConfirmButton: false,
      showCancelButton: false,
      customClass: {
        container: 'custom-swal-container',
        title: 'custom-swal-title',
        htmlContainer: 'custom-swal-text',
        icon: 'custom-swal-icon',
      },
      html: ReactDOMServer.renderToString(
        <div className='custom-swal-content'>
          <p>{text}</p>
          <div className='d-flex align-items-center justify-content-center gap-3 mt-3'>
            <CustomButton
              icon={cancelIcon}
              text={cancelButtonText}
              color={colorCancelButton}
              variant='outlined'
              className='custom-cancel-button'
            />
            <CustomButton
              icon={confirmIcon}
              text={confirmButtonText}
              color={colorConfirmButton}
              variant='contained'
              className='custom-confirm-button'
            />
          </div>
        </div>
      ),
      didOpen: () => {
        document.querySelector('.custom-cancel-button').addEventListener('click', () => {
          Swal.close()
        })

        document.querySelector('.custom-confirm-button').addEventListener('click', async () => {
          try {
            await onConfirm()
            Swal.close()
          } catch (error) {
            onError?.(error)
          }
        })
      },
      allowOutsideClick: false,
      preConfirm: () =>
        new Promise(async (resolve) => {
          try {
            await onConfirm()
            resolve(true)
          } catch (error) {
            onError?.(error)
            resolve(false)
          }
        }),
    }),

  success: ({
    title,
    text,
    onConfirm,
    onError,
    confirmButtonText = 'OK',
    cancelButtonText,
    showCancelButton = false,
    onCancel,
  }) =>
    Swal.fire({
      title,
      text,
      icon: 'success',
      showConfirmButton: false,
      showCancelButton,
      customClass: {
        container: 'custom-swal-container',
        title: 'custom-swal-title',
        htmlContainer: 'custom-swal-text',
        icon: 'custom-swal-icon',
      },
      html: ReactDOMServer.renderToString(
        <div className='custom-swal-content'>
          <p>{text}</p>
          <div className='d-flex align-items-center justify-content-center gap-3 mt-3'>
            {cancelButtonText && (
              <CustomButton
                text={cancelButtonText}
                color='slate'
                variant='outlined'
                icon={<TiCancel />}
                className='custom-cancel-button'
              />
            )}
            <CustomButton
              text={confirmButtonText}
              color='aqua'
              variant='contained'
              icon={<IoMdCheckmark />}
              className='custom-confirm-button'
            />
          </div>
        </div>
      ),
      didOpen: () => {
        if (cancelButtonText) {
          document.querySelector('.custom-cancel-button').addEventListener('click', () => {
            Swal.close()
            onCancel?.()
          })
        }
        document.querySelector('.custom-confirm-button').addEventListener('click', async () => {
          try {
            await onConfirm()
            Swal.close()
          } catch (error) {
            onError?.(error)
          }
        })
      },
      preConfirm: () =>
        new Promise(async (resolve) => {
          try {
            await onConfirm()
            resolve(true)
          } catch (error) {
            onError?.(error)
            resolve(false)
          }
        }),
    }),

  error: ({ title, text, onConfirm, onError }) =>
    Swal.fire({
      title,
      text,
      icon: 'error',
      showConfirmButton: false,
      customClass: {
        container: 'custom-swal-container',
        title: 'custom-swal-title',
        htmlContainer: 'custom-swal-text',
        icon: 'custom-swal-icon',
      },
      html: ReactDOMServer.renderToString(
        <div className='custom-swal-content'>
          <p>{text}</p>
          <div className='d-flex align-items-center justify-content-center gap-3 mt-3'>
            <CustomButton
              text='OK'
              color='danger'
              variant='contained'
              icon={<IoMdCheckmark />}
              className='custom-confirm-button'
            />
          </div>
        </div>
      ),
      didOpen: () => {
        document.querySelector('.custom-confirm-button').addEventListener('click', async () => {
          try {
            await onConfirm?.()
            Swal.close()
          } catch (error) {
            onError?.(error)
          }
        })
      },
      preConfirm: () =>
        new Promise(async (resolve) => {
          try {
            await onConfirm?.()
            resolve(true)
          } catch (error) {
            onError?.(error)
            resolve(false)
          }
        }),
    }),

  warning: ({ title, text, onConfirm, onError }) =>
    Swal.fire({
      title,
      text,
      icon: 'warning',
      showConfirmButton: false,
      customClass: {
        container: 'custom-swal-container',
        title: 'custom-swal-title',
        htmlContainer: 'custom-swal-text',
        icon: 'custom-swal-icon',
      },
      html: ReactDOMServer.renderToString(
        <div className='custom-swal-content'>
          <p>{text}</p>
          <div className='d-flex align-items-center justify-content-center gap-3 mt-3'>
            <CustomButton
              text='OK'
              color='warning'
              variant='contained'
              icon={<IoMdCheckmark />}
              className='custom-confirm-button'
            />
          </div>
        </div>
      ),
      didOpen: () => {
        document.querySelector('.custom-confirm-button').addEventListener('click', async () => {
          try {
            await onConfirm?.()
            Swal.close()
          } catch (error) {
            onError?.(error)
          }
        })
      },
      preConfirm: () =>
        new Promise(async (resolve) => {
          try {
            await onConfirm?.()
            resolve(true)
          } catch (error) {
            onError?.(error)
            resolve(false)
          }
        }),
    }),
}

export default CustomSwalFire

import { useWindowWidth } from '@react-hook/window-size'
import classNames from 'classnames'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

const AdminConsoleSkeleton = () => {
  const width = useWindowWidth()
  const isMobile = width < 768
  return (
    <div className='p-4'>
      {/* Header section */}
      <div className='mb-4'>
        {isMobile ? (
          <div className='flex-grow-1'>
            <Skeleton height={40} />
          </div>
        ) : (
          <Skeleton height={40} width={300} />
        )}
      </div>

      {/* Stats cards */}
      <div className='row mb-3'>
        {[1, 2, 3, 4].map((item) => (
          <div key={item} className='col-md-3'>
            <div
              className={classNames('card', {
                'mb-3': isMobile,
                'mb-0': !isMobile,
              })}
            >
              <div className='card-body'>
                <Skeleton height={20} width={100} className='mb-2' />
                <Skeleton height={30} width={150} />
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Table section */}
      <div className='card'>
        <div className='card-body'>
          <Skeleton height={30} width={200} className='mb-4' />
          <div className='mb-3'>
            {[1, 2, 3, 4, 5].map((item) => (
              <div key={item} className='mb-3'>
                <Skeleton height={40} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminConsoleSkeleton

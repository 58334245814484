import ButtonAdminConsole from '_components/FloatButton/ButtonAdminConsole'
import ProtectedRoute from '_components/ProtectedRoute'
import UserPage from '_pages/User'
import VirtualShop from '_pages/VirtualShop'
import ShopDetail from '_pages/VirtualShop/ShopDetail'
import { getUserInfo, getUserToken } from '_utils/localData'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import CustomSwalFire from '_components/CustomSwalFire'
import ButtonToMyPurchase from '_components/FloatButton/ButtonToMyPurchase'
import useLogout from '_hooks/useLogout'
import { useWindowSize } from '_hooks/useWindowSize'
import { getCurrentLazadaPackage } from '_redux/modules/lazada'
import { readNotification } from '_redux/modules/notification'
import { clearCart, clearOrderDetailsByRef } from '_redux/modules/order'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useToasts } from 'react-toast-notifications'
import UserProfileDropdown from '../components/UserProfileDropdown'
import { clearDelivery, clearPickup } from '../redux/modules/fulfillment'
import { clearShopAndShopDetail } from '../redux/modules/shop'

function Main() {
  const dispatch = useDispatch()
  const userToken = getUserToken()
  const userInfo = getUserInfo()
  const history = useHistory()
  const location = useLocation()

  const { addToast } = useToasts()
  const { width } = useWindowSize()
  const { cart, orderDetailsByRef } = useSelector((state) => state.order)
  const { selectedShop, myShops } = useSelector((state) => state.shop)
  const { listNotification } = useSelector((state) => state.notification)

  const [isLoadingShops, setIsLoadingShops] = useState(true)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const selectedTabProduct = localStorage.getItem('selectedTabProduct')

  useEffect(() => {
    let isMounted = true
    const fetchData = async () => {
      if (!isMounted) return
      setIsLoadingShops(true)
      try {
        await Promise.all([dispatch(getCurrentLazadaPackage())])
      } finally {
        if (isMounted) {
          setIsLoadingShops(false)
        }
      }
    }

    if (userToken) {
      fetchData()
    }

    return () => {
      isMounted = false
    }
  }, [userToken])

  useEffect(() => {
    if (selectedTabProduct) {
      const cleanup = () => {
        localStorage.removeItem('selectedTabProduct')
      }
      cleanup()
      return cleanup
    }
  }, [selectedTabProduct])

  const toggle = () => setDropdownOpen((prevState) => !prevState)

  const isShopSlug = (url) => {
    const predefinedPaths = ['/user', '/virtual-shop'] // Add other predefined paths here
    const isPredefinedPath = predefinedPaths.some((path) => url.startsWith(path))
    return !url.includes('/', 1) && !isPredefinedPath
  }

  async function handleReadNotification() {
    if (listNotification.some((item) => item.new)) {
      await dispatch(readNotification())
    }
  }

  const onClearCart = () => {
    dispatch(clearOrderDetailsByRef())
    dispatch(clearCart())
    dispatch(clearShopAndShopDetail())
    dispatch(clearPickup)
    dispatch(clearDelivery)
  }

  const onConfirmExitPageHasOrderDetailsByRef = (path) => {
    CustomSwalFire.delete({
      title: 'Are you sure you want to leave this page?',
      text: 'Currently, you have an order in progress. Do you want to remove the order information?',
      icon: 'warning',
      confirmButtonText: 'Yes, delete it!',
      onConfirm: async () => {
        await onClearCart()
        if (path === '/user/profile') {
          await handleReadNotification()
        }
        CustomSwalFire.success({
          title: 'Removed!',
          text: 'Your order has been removed.',
        }).then(() => {
          onClearCart()
          history.push(path)
        })
      },
      onError: (error) => {
        CustomSwalFire.error({
          title: 'Error!',
          text: error.message,
        })
      },
    })
  }

  const onRedirectPage = async (path) => {
    const fromMyPurchasePath = location.state?.from === 'my-purchase'
    const orderSuccess = localStorage.getItem('ORDER_SUCCESS') === 'true'

    if (fromMyPurchasePath) {
      await onClearCart()
      if (path === '/user/profile') {
        handleReadNotification()
      }
      history.push(path)
    } else if (
      !fromMyPurchasePath &&
      !orderSuccess &&
      (cart?.orderList?.length > 0 || orderDetailsByRef?.ref)
    ) {
      onConfirmExitPageHasOrderDetailsByRef(path)
    } else {
      history.push(path)
    }

    localStorage.removeItem('ORDER_SUCCESS')
  }

  // Separate path calculation
  const adminConsolePath = useMemo(() => {
    if (width <= 768) {
      return '#'
    }

    const userMatchShop = userInfo?.shopLinks?.some((shop) => {
      const arrShopLink = shop?.split('/')
      const shopSlugItem = arrShopLink[arrShopLink?.length - 1]
      return shopSlugItem === selectedShop?.shopSlug
    })

    const myFirstShop = myShops.length > 0 ? myShops[0] : null
    const shopToUse = userMatchShop ? selectedShop : myFirstShop
    return `/admin-console/${shopToUse?.shopSlug}/setting/general`
  }, [width, userInfo, selectedShop, myShops])

  // Separate navigation handler
  const handleAdminConsoleNavigation = useCallback(() => {
    if (cart?.orderList?.length > 0 || orderDetailsByRef?.ref) {
      CustomSwalFire.delete({
        title: 'Are you sure you want to leave this page?',
        text: 'Currently, you have an order in progress. Do you want to remove the order information?',
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        onConfirm: async () => {
          await onClearCart()
          CustomSwalFire.success({
            title: 'Removed!',
            text: 'Your order has been removed.',
          }).then(() => {
            localStorage.setItem('isAdminConsole', true)
            history.push(adminConsolePath)
          })
        },
        onError: (error) => {
          CustomSwalFire.error({
            title: 'Error!',
            text: error.message,
          })
        },
      })
    } else {
      localStorage.setItem('isAdminConsole', true)
      history.push(adminConsolePath)
    }
  }, [adminConsolePath, cart, orderDetailsByRef, width, addToast, history, onClearCart])

  const preDefinedPaths = [
    '/virtual-shop/sign-in',
    '/virtual-shop/sign-up',
    '/virtual-shop/create',
    '/virtual-shop/add-items',
    '/virtual-shop/confirm-shop',
    '/virtual-shop/checkout',
  ]

  const hasShops = userInfo?.shopLinks?.length > 0

  // Update shouldShowAdminConsole to include hasShops check
  const shouldShowAdminConsole =
    hasShops &&
    !isShopSlug(window.location.pathname) &&
    !preDefinedPaths.includes(window.location.pathname)

  const shouldShowMyPurchase =
    !preDefinedPaths.includes(window.location.pathname) && !isShopSlug(window.location.pathname)

  // const myPurchaseTransform = shouldShowAdminConsole ? 'translateY(-60px)' : 'translateY(25px)'

  return (
    <React.Fragment>
      {/* Header */}
      {userToken ? (
        <UserProfileDropdown
          userInfo={userInfo}
          dropdownOpen={dropdownOpen}
          toggle={toggle}
          listNotification={listNotification}
          onRedirectPage={onRedirectPage}
          adminConsolePath={adminConsolePath}
          onAdminConsoleClick={handleAdminConsoleNavigation}
          useLogout={useLogout}
          shouldShowAdminConsole={shouldShowAdminConsole}
        />
      ) : null}

      <section className='section-content--top main-screen h-100' id='checkHeight'>
        <Switch>
          <ProtectedRoute path='/user/:info' exact component={UserPage} />
          <Route path='/:shopSlug' exact component={ShopDetail} />
          <Route path='/virtual-shop' component={VirtualShop} />
        </Switch>
      </section>

      <div className={`float-buttons-wrapper ${shouldShowAdminConsole ? 'has-admin-console' : ''}`}>
        {shouldShowMyPurchase && <ButtonToMyPurchase />}
        {hasShops && ( // Replace shouldShowAdminConsole with hasShops
          <ButtonAdminConsole loading={isLoadingShops} />
        )}
      </div>
    </React.Fragment>
  )
}

export default Main

import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import { useTour } from '@reactour/tour'
import { getCreateShopData } from '_utils/localData'
import { useSelector } from 'react-redux'
import './style.scss'

const Footer = ({ isOpenModal }) => {
  const { setIsOpen } = useTour()
  const { addToast } = useToasts()
  const dataCreateShop = getCreateShopData()
  const { shopDetail } = useSelector((state) => state.shop)

  useEffect(() => {
    if (dataCreateShop?.isShowGuide && !isOpenModal) setIsOpen(true)
  }, [isOpenModal])

  const handleOnclick = () => {
    if (screen.width >= 1203) {
      const newTabUrl = `/admin-console/${shopDetail?.shopSlug}/setting/general`
      localStorage.setItem('isAdminConsole', true)
      window.open(newTabUrl, '_blank')
    } else {
      addToast('This feature only use on desktop', { appearance: 'error', autoDismiss: true })
    }
  }

  return (
    <React.Fragment>
      <footer
        className={`footer footer-admin cursor-pointer ${
          window.location.pathname.includes('admin-console') ? 'd-none' : ''
        }`}
      >
        <div className='container pb-0' style={{ width: 130 }}>
          <ul className='menu btn-manage-shop' style={{ margin: '0 auto' }}>
            <li onClick={handleOnclick} className='cursor-pointer'>
              <a>
                <i className='icon-host-event'></i>
                <span style={{ whiteSpace: 'nowrap' }}>Admin Console</span>
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </React.Fragment>
  )
}

export default withRouter(Footer)

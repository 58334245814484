import CollapseProvider from '_contexts/CollapseMenuContext'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { AiFillCalendar, AiFillShop } from 'react-icons/ai'
import { FaFileInvoiceDollar, FaTruckMoving } from 'react-icons/fa'
import { IoIosNotifications, IoMdSettings } from 'react-icons/io'
import { MdAttachMoney } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import ErrorBoundary from '../components/ErrorBoundary'
import { OrderRecordContext, OrderRecordProvider } from '../contexts/OrderRecordContext'
import { getEventInShop } from '../redux/modules/event'
import { clearCart, setListDateIsPicked } from '../redux/modules/order'
import { fetchMyShops, selectShopAndSelectShopDetail } from '../redux/modules/shop'
import { fetchListInvoiceSubscription } from '../redux/modules/subscription'
import { getNotifications } from '../redux/modules/user'
import {
  ORDER_DELIVERY_PAGE,
  ORDERS_PAGE,
  PACKAGE_BASIC,
  PLATFORM_CO_KII,
  SUBSCRIPTION_STATUS_UNPAID,
} from '../utils/constant'
import { getEventsByShop } from '../utils/localData'
import MainDigistallDesktop from './Main'
import Header from './components/Header'
import { generateRoutes } from './routes'

// const MemoizedMainDigistallDesktop = React.memo(MainDigistallDesktop)

function DigistallDesktop() {
  const dispatch = useDispatch()
  const history = useHistory()
  const eventsByShop = getEventsByShop()

  const { pathname } = useLocation()

  const [loading, setLoading] = useState(true)
  const [listMenuItem, setListMenuItem] = useState([])
  const [typePage, setTypePage] = useState(null)
  const [isShowFulfillmentTag, setIsShowFulfillmentTag] = useState(false)
  // const [listRoute, setListRoute] = useState([])

  const { myShops, selectedShop, hasLoadedMyShops } = useSelector((state) => state.shop)
  const { listInvoiceSubscription } = useSelector((state) => state.subscription)
  const { cart } = useSelector((state) => state.order)

  const { onSelectTabOrderPlatform, onSelectTabEvent } = useContext(OrderRecordContext)

  const userShopIds = myShops.map((shop) => shop.id)

  const listRoute = useMemo(
    () => generateRoutes('/admin-console', selectedShop?.shopSlug),
    [selectedShop?.shopSlug]
  )

  const getPathManageOrder = useCallback(() => {
    if (pathname.includes('order-records')) {
      return `/admin-console/${selectedShop?.shopSlug}/order-records`
    }

    if (pathname.includes('delivery')) {
      return `/admin-console/${selectedShop?.shopSlug}/delivery`
    }
  }, [pathname, selectedShop?.id])

  const handlePathChange = useCallback(
    (_pathname) => {
      if (_pathname === getPathManageOrder()) {
        setIsShowFulfillmentTag(true)
        setTypePage(_pathname.includes('order-records') ? ORDERS_PAGE : ORDER_DELIVERY_PAGE)
        dispatch(setListDateIsPicked([]))
      } else {
        setIsShowFulfillmentTag(false)
      }
    },
    [dispatch, getPathManageOrder]
  )

  const onChangeShop = useCallback(
    (option) => {
      const newSelectedShop = myShops.find((shop) => shop.id === option.value)
      if (!newSelectedShop) return

      const userMatchShop = userShopIds?.find((item) => item === newSelectedShop.id)

      if (userMatchShop) {
        const newPathname = pathname.replace(selectedShop?.shopSlug, newSelectedShop.shopSlug)
        if (pathname !== newPathname) {
          history.push(newPathname)
        }

        if (cart?.orderList?.length > 0) {
          dispatch(clearCart())
        }
        dispatch(selectShopAndSelectShopDetail(newSelectedShop))

        if (
          typeof onSelectTabOrderPlatform === 'function' &&
          typeof onSelectTabEvent === 'function'
        ) {
          onSelectTabOrderPlatform(PLATFORM_CO_KII)
          onSelectTabEvent(null)
        }
      }
    },
    [cart?.orderList?.length, dispatch, selectedShop?.id, userShopIds]
  )

  useEffect(() => {
    let isMounted = true

    const fetchData = async () => {
      try {
        await Promise.all([
          dispatch(fetchMyShops()),
          dispatch(fetchListInvoiceSubscription()),
          dispatch(getNotifications),
          // dispatch(getCurrentLazadaPackage()),
        ])
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        if (isMounted) {
          setLoading(false)
        }
      }
    }

    fetchData()

    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    const shopSlugUrl = pathname
      .split('/')
      .find(
        (item) =>
          item &&
          ![
            'admin-console',
            'setting',
            'general',
            'my-shops',
            'account',
            'change-password',
            'update',
            'profile',
          ].includes(item)
      )
    const userShopLinks = myShops.map((shop) => shop.shopLink)
    const isMatchShopSlug = userShopLinks.some((link) => link.includes(shopSlugUrl))
    const pathAccount = pathname.includes('account')

    if (!selectedShop?.shopSlug && myShops.length > 0) {
      const matchedShop = myShops.find((shop) => shop.shopSlug === shopSlugUrl)
      if (matchedShop) {
        dispatch(selectShopAndSelectShopDetail(matchedShop))
      } else {
        dispatch(selectShopAndSelectShopDetail(myShops[0]))
      }
    }

    if (
      hasLoadedMyShops &&
      (myShops.length === 0 || (myShops.length > 0 && !isMatchShopSlug)) &&
      !pathAccount
    ) {
      history.push('/virtual-shop/list')
    }
  }, [selectedShop?.shopSlug, myShops, dispatch, hasLoadedMyShops])

  useEffect(() => {
    let isMounted = true
    if (selectedShop?.id && !eventsByShop && isMounted) {
      dispatch(getEventInShop(selectedShop?.id))
    }

    return () => {
      isMounted = false
    }
  }, [selectedShop?.id, JSON.stringify(eventsByShop), dispatch])

  useEffect(() => {
    if (selectedShop?.id) {
      const shouldDisplayEvents = () => {
        if ((listInvoiceSubscription || []).length === 1) {
          return listInvoiceSubscription[0].subscriptionPackageType !== PACKAGE_BASIC
        }
        if ((listInvoiceSubscription || []).length > 1) {
          return listInvoiceSubscription.some(
            (subscription) =>
              subscription.subscriptionPackageType !== PACKAGE_BASIC &&
              subscription.status !== SUBSCRIPTION_STATUS_UNPAID
          )
        }
        return false
      }

      const MENU_ITEMS = [
        {
          title: 'Shop Front',
          icon: <AiFillShop />,
          path: `/admin-console/${selectedShop?.shopSlug}/shop-front`,
          display: true,
        },
        {
          title: 'Dashboard',
          icon: <IoIosNotifications />,
          path: `/admin-console/${selectedShop?.shopSlug}/dashboard`,
          display: true,
        },
        {
          title: 'Products',
          icon: <AiFillCalendar />,
          path: `/admin-console/${selectedShop?.shopSlug}/products`,
          display: true,
        },
        {
          title: 'Orders',
          icon: <MdAttachMoney />,
          path: `/admin-console/${selectedShop?.shopSlug}/order-records`,
          display: true,
        },
        {
          title: 'Delivery & Fulfillment',
          icon: <FaTruckMoving />,
          path: `/admin-console/${selectedShop?.shopSlug}/delivery`,
          display: true,
        },
        shouldDisplayEvents() && {
          title: 'Events',
          icon: <IoMdSettings />,
          path: `/admin-console/${selectedShop?.shopSlug}/event`,
          display: true,
        },
        shouldDisplayEvents() && {
          title: 'Manage Delivery Zones',
          icon: <FaTruckMoving />,
          path: `/admin-console/${selectedShop?.shopSlug}/manage-delivery-zones`,
          display: true,
        },
        {
          title: 'Setting',
          icon: <IoMdSettings />,
          path: `/admin-console/${selectedShop?.shopSlug}/setting/general`,
          display: true,
        },
        {
          title: 'Subscription',
          icon: <FaFileInvoiceDollar />,
          path: `/admin-console/${selectedShop?.shopSlug}/subscription`,
          display: true,
        },
        {
          title: 'Account',
          path: '/admin-console/setting/account',
          display: false,
        },
      ].filter(Boolean)

      setListMenuItem(MENU_ITEMS)
    }
  }, [selectedShop?.shopSlug, listInvoiceSubscription?.length])

  // useEffect(() => {
  //   if (selectedShop?.id) {
  //     handlePathChange(pathname)
  //   }
  // }, [pathname, selectedShop?.id, handlePathChange])

  return (
    <ErrorBoundary>
      <Header
        loading={loading}
        listMenuItem={listMenuItem}
        onChangeShop={onChangeShop}
        typePage={typePage}
        isShowFulfillmentTag={isShowFulfillmentTag}
        handlePathChange={handlePathChange}
      />
      <section className='section-content section-content--top mains-screen pb-0' id='checkHeight'>
        <MainDigistallDesktop
          loading={loading}
          listRoute={listRoute}
          listMenuItem={listMenuItem}
          onChangeShop={onChangeShop}
          typePage={typePage}
          isShowFulfillmentTag={isShowFulfillmentTag}
          handlePathChange={handlePathChange}
        />
      </section>
    </ErrorBoundary>
  )
}

function DigistallDesktopWrapper() {
  return (
    <ErrorBoundary>
      <OrderRecordProvider>
        <CollapseProvider>
          <DigistallDesktop />
        </CollapseProvider>
      </OrderRecordProvider>
    </ErrorBoundary>
  )
}

export default React.memo(DigistallDesktopWrapper)

import React from 'react'
import Skeleton from 'react-loading-skeleton'

export const SelectBoxSkeleton = () => (
  <div className='select-box-skeleton mt-3'>
    <div
      className='d-flex justify-content-between align-items-center'
      style={{
        padding: '0 8px',
        border: '1px solid rgba(255,255,255,0.1)',
        borderRadius: '4px',
      }}
    >
      <div className='flex-fill'>
        <Skeleton height={32} />
      </div>
    </div>
  </div>
)

export const MenuItemSkeleton = ({ isCollapse, loading }) => (
  <li className={`d-flex align-items-end mb-2 ${loading ? '' : 'menu-item'}`}>
    <div className='d-flex align-items-center' style={{ width: isCollapse ? '50px' : '230px' }}>
      <Skeleton circle width={38} height={38} className='me-2' />
      {!isCollapse && (
        <div className='flex-grow-1'>
          <Skeleton height={28} />
        </div>
      )}
    </div>
  </li>
)

export const CalendarSkeleton = () =>
  [1, 2, 3, 4].map((_, index) => (
    <div key={index} className='calendar-skeleton mb-1'>
      <Skeleton height={32} />
    </div>
  ))

export default {
  SelectBoxSkeleton,
  MenuItemSkeleton,
  CalendarSkeleton,
}

import React from 'react'

function HeaderAlert({ tag, children, className, onClose, ...otherProps }) {
  const TagName = tag

  return (
    <TagName className={className} {...otherProps}>
      {children}
      <button type='button' onClick={onClose} className='btn-close' aria-label='Close'></button>
    </TagName>
  )
}

export default HeaderAlert

import InputField from '_components/InputField'
import { DeliveryContext } from '_contexts/DeliveryContext'
import { useContext } from 'react'

const DeliveryPickup = () => {
  const { setCheckedIncludePickup, checkedIncludePickup } = useContext(DeliveryContext)
  return (
    <div className='delivery-pickup' style={{ maxWidth: 150 }}>
      <InputField
        type='checkbox'
        id='includePickup'
        label='Include Pickup'
        value={checkedIncludePickup}
        onChange={(e) => setCheckedIncludePickup(e.target.checked)}
      />
    </div>
  )
}

export default DeliveryPickup

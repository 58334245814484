import CustomButton from '_components/CustomButton'
import InputField from '_components/InputField'
import { OrderRecordContext } from '_contexts/OrderRecordContext'
import {
  PACKAGE_BASIC,
  PLATFORM_CO_KII,
  PLATFORM_LAZADA,
  SUBSCRIPTION_STATUS_UNPAID,
} from '_utils/constant'
import { isEmpty } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { AiOutlineFilter } from 'react-icons/ai'
import { FaStore } from 'react-icons/fa'
import { PiExportBold } from 'react-icons/pi'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getEventInShop } from '../../../redux/modules/event'
import RenderSelectingOrderDate from './RenderSelectingOrderDate'
import SelectFilter from './SelectFilter'
import TabEvent from './TabEvent'
import TabsECommerce from './TabsECommerce'

const groupEvents = (events, groupSize) => {
  const groups = []
  for (let i = 0; i < events.length; i += groupSize) {
    groups.push(events.slice(i, i + groupSize))
  }
  return groups
}

function OrderManagementHeader() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { selectedShop } = useSelector((state) => state.shop)
  const { lazadaPackage } = useSelector((state) => state.lazada)
  const { listInvoiceSubscription } = useSelector((state) => state.subscription)

  const [selectedEventName, setSelectedEventName] = useState('Event')
  // const [loading, setLoading] = useState(true)

  const {
    clearFilter,
    handleExport,
    sortListOrders,
    tabOrderPlatform,
    setSelectFilterOrder,
    selectFilterOrder,
    searchString,
    onChangeSearch,
    onSelectTabEvent,
    tabOrderEvent,
    onSelectTabOrderPlatform,
    listOrdersEventFiltered,
  } = useContext(OrderRecordContext)

  const { eventsByShop } = useSelector((state) => state.event)

  // const eventsByShop = getEventsByShop() || {}
  const listEventByShop = !isEmpty(eventsByShop) ? eventsByShop[selectedShop?.id] || [] : []

  const eidParam = new URLSearchParams(history.location.search).get('eid')
  const groupedEvents = groupEvents(listEventByShop, 5)
  const isLazadaValid = lazadaPackage?.shopId === selectedShop?.id
  const foundEvent = listEventByShop?.find((event) => event.id === eidParam)

  useEffect(() => {
    if (selectedShop?.id) {
      dispatch(getEventInShop(selectedShop.id))
    }
  }, [selectedShop?.id])

  useEffect(() => {
    if (tabOrderEvent === null) {
      setSelectedEventName('Event')
    }
  }, [tabOrderEvent])

  useEffect(() => {
    if (eidParam && foundEvent) {
      onSelectTabOrderPlatform(null)
      onSelectTabEvent(eidParam)

      setSelectedEventName(`Event: ${foundEvent.title}`)
    }
  }, [selectedShop?.id, eidParam, tabOrderPlatform, JSON.stringify(foundEvent)])

  const handleSelectEvent = (eventId, eventName) => {
    onSelectTabEvent(eventId)
    setSelectedEventName(`Event: ${eventName}`)
    onSelectTabOrderPlatform(null)
    history.push(`?eid=${eventId}`)
  }

  const onSelectTab = (tab) => {
    onSelectTabOrderPlatform(tab)
    onSelectTabEvent(null)
    setSelectedEventName('Event')
    history.replace(history.location.pathname)
  }

  const onExportOrder = () => {
    if (tabOrderPlatform === PLATFORM_CO_KII) {
      handleExport(sortListOrders, PLATFORM_CO_KII)
    }

    if (tabOrderEvent) {
      handleExport(listOrdersEventFiltered, tabOrderEvent)
    }
  }

  const shouldDisplayEvents = () => {
    if (listInvoiceSubscription.length === 1) {
      return listInvoiceSubscription[0].subscriptionPackageType !== PACKAGE_BASIC
    }
    if (listInvoiceSubscription.length > 1) {
      return listInvoiceSubscription.some(
        (subscription) =>
          subscription.subscriptionPackageType !== PACKAGE_BASIC &&
          subscription.status !== SUBSCRIPTION_STATUS_UNPAID
      )
    }
    return false
  }

  return (
    <div className='order-management-header'>
      <h4 className='order-management-header__title mb-3'>
        {selectFilterOrder === 'reverse-order' && 'Reverse'} Order Records
      </h4>
      <div className='order-management-header__tabs d-flex align-items-center mb-3 flex-wrap'>
        <TabsECommerce
          onSelectTab={onSelectTab}
          tabOrderPlatform={tabOrderPlatform}
          lazadaPackage={lazadaPackage}
          isLazadaValid={isLazadaValid}
        />

        {shouldDisplayEvents() && (
          <TabEvent
            groupedEvents={groupedEvents}
            handleSelectEvent={handleSelectEvent}
            selectedEventName={selectedEventName}
            tabOrderEvent={tabOrderEvent}
          />
        )}
      </div>

      <>
        <div className='order-management-header__search d-flex align-items-center justify-content-between mb-3'>
          <InputField
            value={searchString}
            onChange={onChangeSearch}
            isSearch={true}
            placeholder={'Search Order'}
            className='order-management-header__search-input'
            type='text'
          />

          <div className='order-management-header__buttons'>
            <CustomButton
              icon={<AiOutlineFilter />}
              text='Clear'
              className='order-management-header__button mt-0'
              style={{ width: '120px' }}
              onClick={clearFilter}
            />

            <CustomButton
              icon={<PiExportBold />}
              text='Export'
              onClick={onExportOrder}
              style={{ width: '112px' }}
              className='order-management-header__button mt-0'
            />

            {tabOrderEvent === null && (
              <CustomButton
                text='Create'
                onClick={() => history.push(`/${selectedShop?.shopSlug}`)}
                className='order-management-header__create mt-0'
                variant='contained'
                icon={<FaStore />}
              />
            )}
          </div>
        </div>

        <div className='order-management-header__filters d-flex align-items-center'>
          <div className='order-management-header__filters-container'>
            {tabOrderPlatform === PLATFORM_LAZADA ? (
              <select
                name='filter-order'
                id='filter-order'
                className='order-management-header__select form-select border rounded-0 w-auto text-secondary f-13 me-0'
                defaultValue={selectFilterOrder}
                onChange={(e) => setSelectFilterOrder(e.target.value)}
              >
                <option value='order'>Order</option>
              </select>
            ) : (
              <>
                <SelectFilter />
              </>
            )}
          </div>
        </div>

        {tabOrderPlatform !== PLATFORM_LAZADA ? (
          <div className='order-management-header__details'>
            <div className='order-management-header__render-selecting'>
              <RenderSelectingOrderDate />
            </div>
          </div>
        ) : null}
      </>
    </div>
  )
}

export default OrderManagementHeader

import React, { createContext, useState } from 'react'

export const ModalContext = createContext()

export default function ModalProvider({ children }) {
  const [isModal, setIsModal] = useState(false)

  const toggleModal = () => {
    setIsModal(!isModal)
  }

  const values = {
    isModal,
    toggleModal,
  }

  return <ModalContext.Provider value={values}>{children}</ModalContext.Provider>
}
